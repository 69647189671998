import React from 'react';
import './CustomCell.css';

export default function ReferenceCustomCell(props) {
  const { cellValue } = props;

  return (
    <>
      <div style={{ whiteSpace: 'normal' }}>{cellValue.reference || ''}</div>
      <div className="frc__reference-cell-notes" style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
        {cellValue.notes || ''}
      </div>
    </>
  );
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'components';
import { Error } from 'components';

const OptionCheckbox = ({
  option,
  context,
  index,
  optionParentIndex,
  groupIndex,
  errorId,
  serviceIndex,
  config,
  CONST,
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(false);

  const toggle = () => {
    setSelected(prevSelected => !prevSelected);

    let stateObject = {};
    let additionalService = {};

    stateObject = context.state.additionalServices;
    additionalService = JSON.parse(JSON.stringify(stateObject[serviceIndex]));

    const additionalServiceParentOption = additionalService.groups[groupIndex].options[optionParentIndex];
    const additionalServiceOption = additionalServiceParentOption[index];
    additionalServiceOption.selected = !selected;
    additionalServiceOption.error = false;

    stateObject[serviceIndex] = additionalService;
    context.updateState({ additionalServices: stateObject });
  };

  const getErrorMessage = () => {
    let errorMessage = '';

    if (option.error) {
      errorMessage = t('general|errors|Please fill this field');
    }

    return <Error name={errorId ? errorId : 'OptionCheckbox'} className="frc__input--error" message={errorMessage} />;
  };

  return (
    <>
      <div className="l-grid--w-100pc-s frc__as-option--wrapper">
        <Checkbox
          label={t(`additionalServices|` + option.optionCode)}
          name={option.optionCode}
          context={context}
          config={config}
          CONST={CONST}
          checked={option.selected}
          isRequired={option.mandatory}
          disabled={option.disabled || false}
          updateOnParent={toggle}
        />

        {option.error && option.errorMessage ? getErrorMessage() : ''}
      </div>
    </>
  );
};

export default OptionCheckbox;

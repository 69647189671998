import { globalConfig, GLOBAL_CONST } from '../data-config-global';

const utilConfig = {};

const UTILS_CONST = {
  STATUS_OK: 200,
  BAD_REQUEST_ERROR: 400,
};

export const config = Object.assign({}, globalConfig, utilConfig);
export const CONST = Object.assign({}, GLOBAL_CONST, UTILS_CONST);

import { GlobalStyles as GlobalStylesMui } from '@mui/material';

export const GlobalStyles = (
  <GlobalStylesMui
    styles={theme => ({
      ':root': {
        '--toastify-toast-width': '100%',
      },

      li: {
        listStyle: 'square',
        '&::marker': { color: theme.palette.primary.main },
      },
      a: {
        color: theme.palette.primary.main,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      /**
       * TODO: jm - to be removed in the future when we decide to use a better modal system
       * Directly related to this component and MUI labels:
       * src/components/MessageModal/MessageModal.js
       */
      '.ReactModalPortal': {
        position: 'relative',
        zIndex: 1,
      },
      '.Toastify__toast-container': {
        maxWidth: '768px',
        '.Toastify__toast': {
          alignItems: 'center',
          boxShadow: '0px 0px 10px #8b8b8b',
        },
        '.MuiAlert-filledError': {
          '.MuiLink-root': {
            color: theme.palette.common.white,
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'none',
            },
          },
        },
      },
    })}
  />
);

import { globalConfig, GLOBAL_CONST } from 'globals/data-config-global';
import { ROUTES } from 'router';

const componentConfig = {
  defaultHomePage: ROUTES.portal,
  emailPlaceholder: 'mail@domain.com',
  uuidCookie: 'uuid',
};

const COMPONENT_CONST = {
  API_DOMAIN: 'portalLogin',
  PORTAL_LOGIN: 'PL',
};

export const config = Object.assign({}, globalConfig, componentConfig);
export const CONST = Object.assign({}, GLOBAL_CONST, COMPONENT_CONST);

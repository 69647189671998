import { apiLinks } from 'config/api-config';
import { debounce } from 'globals/utils/debounce';
import { apiRequest } from 'globals/utils/requests';
import { postalCodeValidation } from '../../globals/helpers/pickup-helpers';
import { config, CONST } from '../data-config';

const applyGPriorityServicesToChosenProductIfMatch = (result, context) => {
  let priorityServiceDisabled = false;
  let GOptionsForCurrentProduct;
  let G7Matches, G10Matches, G12Matches;
  let noGServiceAtAll;

  if (context.state.selectedProducts.length === 1 && Array.isArray(result.data)) {
    GOptionsForCurrentProduct = result.data.find(optionsObj => {
      return optionsObj.product.split(' ').join('') === context.state.selectedProducts[0].toUpperCase();
    });
  }

  if (GOptionsForCurrentProduct) {
    GOptionsForCurrentProduct = GOptionsForCurrentProduct.options;
  } else {
    return;
  }

  G7Matches = GOptionsForCurrentProduct.indexOf('G7') !== -1;
  G10Matches = GOptionsForCurrentProduct.indexOf('G10') !== -1;
  G12Matches = GOptionsForCurrentProduct.indexOf('G12') !== -1;
  noGServiceAtAll = !G7Matches && !G10Matches && !G12Matches;

  if (noGServiceAtAll) priorityServiceDisabled = true;

  return { G7Matches, G10Matches, G12Matches, priorityServiceDisabled };
};

const doCaseInsensitiveValuesMatch = (val1, val2) => {
  return val1 && val2 && val1.toLowerCase() === val2.toLowerCase();
};

export const getPostalCodesValidationErrors = (data, state) => {
  const sourceCityError = data.source ? !doCaseInsensitiveValuesMatch(data.source.city, state.pickupCity.value) : true;
  const sourcePostalCodeError = data.source
    ? !doCaseInsensitiveValuesMatch(data.source.postalCode, state.pickupPostalCode.value)
    : true;
  const destinationCityError = data.destination
    ? !doCaseInsensitiveValuesMatch(data.destination.city, state.deliveryCity.value)
    : true;
  const destinationPostalCodeError = data.destination
    ? !doCaseInsensitiveValuesMatch(data.destination.postalCode, state.deliveryPostalCode.value)
    : true;

  return {
    sourceCityError,
    sourcePostalCodeError,
    destinationCityError,
    destinationPostalCodeError,
  };
};

const postalCodesHaveDeviation = result => {
  let sourceDeviating;
  let destinationDeviating;

  if (result) {
    sourceDeviating = result.source && result.source.deviating !== '0' && result.source.deviating !== false;
    destinationDeviating =
      result.destination && result.destination.deviating !== '0' && result.destination.deviating !== false;
  }

  return sourceDeviating || destinationDeviating;
};

const deselectPriorityServices = context => {
  const additionalServices = JSON.parse(JSON.stringify(context.state.additionalServices));
  let result = [];

  if (additionalServices.length > 0) {
    result = additionalServices.map(service => {
      if (service.code === 'priorityService') {
        service.selectedStatus = false;
      } else if (service.code === 'temperatureControlled') {
        service.disabled = false;
      }
      return service;
    });
  }

  return result;
};

export const runPostalCodesValidationForGServices = debounce(async context => {
  const bothPostalCodesHaveRequiredLength =
    context.state.pickupPostalCode.value.length >= CONST.MIN_POSTALCODE_NUMBER &&
    context.state.deliveryPostalCode.value.length >= CONST.MIN_POSTALCODE_NUMBER;
  let postalCodesValidationAPI;

  if (bothPostalCodesHaveRequiredLength) {
    postalCodesValidationAPI = await postalCodeValidation(context.state);

    if (postalCodesValidationAPI.status === CONST.STATUS_OK) {
      const deviationInPostalCodes = postalCodesHaveDeviation(postalCodesValidationAPI.data);
      const stateToSet = {};

      if (!deviationInPostalCodes) {
        stateToSet.priorityServiceDisabled = false;

        const params = {
          body: {
            countryCode: context.state.pickupCountry.value,
            postalCode: context.state.pickupPostalCode.value,
            deliveryCountryCode: context.state.deliveryCountry.value,
            deliveryPostalCode: context.state.deliveryPostalCode.value,
            earliestSent: true,
            dateTime: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
          },
        };

        apiRequest(apiLinks.postTimeTable, 'POST', params).then(result => {
          let checkIfApply;

          if (result.status === CONST.STATUS_OK) {
            checkIfApply = applyGPriorityServicesToChosenProductIfMatch(result, context);
          }

          Object.assign(stateToSet, checkIfApply);
          context.updateState(stateToSet); // TODO: Are these 3 setState()s below supposed to be context.updateState? Check if code works as is
        });
      } else {
        context.updateState({ priorityServiceDisabled: true, additionalServices: deselectPriorityServices(context) });
      }
    } else {
      context.updateState({ priorityServiceDisabled: true, additionalServices: deselectPriorityServices(context) });
    }
  }
}, config.debounceDelay);

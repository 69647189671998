import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

export const RegistrationDialog = ({ handleClose, handleConfirm }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open onClose={handleClose} aria-labelledby="registration-exit-dialog">
        <DialogTitle id="registration-exit-dialog">{t('registration|exitDialogTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <Box sx={{ textAlign: 'center' }}>{t('registration|exitDialogDescription')}</Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} variant="outlined" data-testid="cancelBtn">
            {t('general|labels|buttons|Cancel')}
          </Button>
          <Button
            onClick={() => {
              handleClose();
              handleConfirm();
            }}
            autoFocus
            variant="contained"
            data-testid="exitDialogLeavePageButtonBtn"
          >
            {t('registration|exitDialogLeavePageButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

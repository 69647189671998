import {
  CONSIGNEE_CUSTOMER_TYPE,
  CONSIGNOR_CUSTOMER_TYPE,
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE,
} from '../../../globals/constants';

export const formPartyData = (routeState, deliveryOptionsState) => {
  const parties = [];
  let accountNumber;
  const shipmentTypesImpOrExp = routeState.shipmentTypesImpOrExp.value;

  if (routeState.productsRangeOne.value) accountNumber = routeState.accountNumber.value;
  else accountNumber = deliveryOptionsState.selectedProduct.account;

  parties.push(formData(routeState, 'pickup', CONSIGNOR_CUSTOMER_TYPE));
  parties.push(formData(routeState, 'delivery', CONSIGNEE_CUSTOMER_TYPE));

  if (accountNumber) {
    // for domestic shipmentTypesImpOrExp will be ""
    if (shipmentTypesImpOrExp === EXPORT_SHIPMENT_TYPE || shipmentTypesImpOrExp === '') parties[0].id = accountNumber;
    else if (shipmentTypesImpOrExp === IMPORT_SHIPMENT_TYPE) parties[1].id = accountNumber;
  }

  return parties;
};

const formData = (routeState, type, customerType) => {
  const partyObj = {};

  partyObj.address = {
    countryCode: routeState[type + 'Country']?.value || null,
    postalCode: routeState[type + 'PostalCode']?.value || null,
  };

  partyObj.type = customerType;

  return partyObj;
};

export const ConfirmationEmail = routeState => {
  return routeState.pickupAddressResidential.value && routeState.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE;
};

import { apiRequest } from 'globals/utils/requests';
import { formatDate, formatDateTime } from 'globals/utils/formatting';
import { CONST, config } from '../data-config';
import { apiLinks } from 'config/api-config';

export const trackShipment = (t, shipmentNumber) => {
  let trackingUrl = t('pageLinks|trackShipment');
  trackingUrl = trackingUrl.replace('{shipmentNumber}', shipmentNumber);

  window.open(trackingUrl);
};

const setOptimalTemplateType = filteredStatuses => {
  let templateTypes = [];

  if (filteredStatuses.length > 0) {
    filteredStatuses.forEach(status => {
      if (status === CONST.IN_COMPLETE) {
        templateTypes.push(1);
      } else {
        templateTypes.push(4);
      }
    });
  } else {
    templateTypes = [1, 4];
  }

  return templateTypes;
};

export const formRequestBodyForShipmentCall = state => {
  const filteredAccounts = state.filterByUserTMSAccount
    .filter(account => account.value)
    .map(filteredAccount => filteredAccount.accountNumber);

  const filteredStatuses = state.filterByShipmentStatus
    .filter(shipmentStatus => shipmentStatus.value)
    .map(filteredStatus => filteredStatus.name);

  return {
    fromDate: formatDateTime(state.filterShipmentFromDate.value),
    toDate: formatDateTime(state.filterShipmentToDate.value),
    page: state.pagination.page,
    pageSize: state.pagination.pageSize,
    searchTerm: state.searchTerm.value,
    senderAccountFilter: filteredAccounts.length > 0 ? filteredAccounts : [],
    seeAllShipments: true, // activate the "see all shipments" feature unlike the Dashboard page
    sortOrder: 'createdAt',
    statusFilter: filteredStatuses.length > 0 ? filteredStatuses : [],
    templateTypes: setOptimalTemplateType(filteredStatuses),
  };
};

export const resetPagination = () => {
  return {
    page: 1,
    total: 0,
    pageSize: config.paginationInitialNumberOfItems,
    lastPage: 0,
  };
};

const shipmentListBackendCall = async state => {
  const resultMap = {};

  await apiRequest(apiLinks.savedShipmentData, 'POST', {
    body: formRequestBodyForShipmentCall(state),
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

const formatSavedShipmentData = savedShipments => {
  const modifiedSavedShipments = savedShipments.map((shipment, rowTableIndex) => {
    shipment.rowTableIndex = rowTableIndex;

    shipment.sender = shipment.savedShipmentParties
      ? shipment.savedShipmentParties.find(party => party.type.toLowerCase() === 'sender')
      : {};

    shipment.receiver = shipment.savedShipmentParties
      ? shipment.savedShipmentParties.find(party => party.type.toLowerCase() === 'receiver')
      : {};

    shipment.senderName = shipment.sender ? shipment.sender.companyName : '';
    shipment.receiverName = shipment.receiver ? shipment.receiver.companyName : '';

    shipment.formattedDate = shipment.createdAt ? formatDate(shipment.createdAt) : '';

    if (shipment.sender) {
      shipment.sender.country = shipment.sender.countryCode || '';
    }

    if (shipment.receiver) {
      shipment.receiver.country = shipment.receiver.countryCode || '';
    }

    return shipment;
  });

  return modifiedSavedShipments;
};

export const fetchSavedShipmentsList = async context => {
  let calculatePagination;
  let formattedSavedShipmentData;
  let shipmentResult = await shipmentListBackendCall(context.state);

  if (!shipmentResult.error) {
    // set pagination
    formattedSavedShipmentData = formatSavedShipmentData(shipmentResult.data.savedShipments);
    // formattedSavedShipmentData = formattedSavedShipmentData.sort((a, b) => b.createdAt - a.createdAt);

    calculatePagination = {
      page: shipmentResult.data.page,
      total: shipmentResult.data.total,
      lastPage: shipmentResult.data.lastPage,
    };

    calculatePagination.pageSize = context.state.pagination.pageSize;

    context.updateState({
      pagination: calculatePagination,
      savedShipments: formattedSavedShipmentData,
      savedShipmentsMaster: formattedSavedShipmentData,
      apiError: false,
      loader: false,
      manualSearch: false,
      remoteSearchTerm: context.state.searchTerm.value,
    });
  } else {
    context.updateState({
      apiError: true,
      loader: false,
      pagination: resetPagination(),
      savedShipments: [],
      savedShipmentsMaster: [],
      manualSearch: false,
      remoteSearchTerm: context.state.searchTerm.value,
    });
  }
};

export const onSavedShipmentSearch = (context, { pageSize = false, page = false } = false) => {
  const stateToSet = { loader: true };

  if (pageSize || page) {
    stateToSet.manualSearch = false;
    stateToSet.pagination = JSON.parse(JSON.stringify(context.state.pagination));

    if (pageSize) {
      stateToSet.pagination.pageSize = pageSize;
      stateToSet.pagination.page = 1;
    }
    if (page) stateToSet.pagination.page = page;
  } else {
    stateToSet.manualSearch = false;
  }

  context.extendedSetState(stateToSet, { afterUpdate: fetchSavedShipmentsList });
};

export const createReturnShipmentAction = async id => {
  let returnShipment = null;

  await apiRequest(apiLinks.createReturnShipment, 'POST', {
    body: {
      shipment: id,
    },
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        returnShipment = result.data;
      } else {
        returnShipment = null;
      }
    })
    .catch(() => {
      returnShipment = null;
    });

  return returnShipment;
};

import {
  shouldDisableLoadingMeter,
  handleNonStackableUpdate,
  shouldForceNonStackable,
  updateShipmentRow,
  volumeDimensionsFilled,
  calculateVolume,
  handleLoadingMeterUpdate,
  volumeDimensionsEmpty,
  calculateTotalLdm,
} from './shipment';

import { config } from '../utils/data-config';

import { PRODUCTS_RANGE } from '../constants';

export const afterUpdateNonStackable = (context, { value: checked }, shipmentRow, rowIndex) => {
  const newState = structuredClone(context.state);

  const loadingMeterDisabled = !!shipmentRow?.loadingMeter?.disabled;
  if (loadingMeterDisabled) return;

  const updatedRow = handleNonStackableUpdate({
    shipmentRow: shipmentRow,
    dimensions: context.state.dimensions,
    checked,
  });

  newState.shipmentDetailsRows[rowIndex] = updatedRow;
  newState.totals = calculateTotals(newState.shipmentDetailsRows, context.state.totals).totals;
  context.updateState(newState);
};

export const afterUpdateHeight = ({ context, params, shipmentRow, rowIndex, skipTotals = false }) => {
  const newState = structuredClone(context.state);
  newState.shipmentDetailsRows[rowIndex] = checkAndCalculateRowLdmVolume({
    dimensions: context.state.dimensions,
    shipmentRow,
  });

  if (!skipTotals) {
    newState.totals = calculateTotals(newState.shipmentDetailsRows, context.state.totals).totals;
  }

  context.updateState(newState);
};

export const checkAndCalculateRowLdmVolume = ({ dimensions, shipmentRow }) => {
  const forceNonStackable = shouldForceNonStackable({
    height: shipmentRow.height.value,
    dimensions: dimensions,
  });
  const disableLoadingMeter = shouldDisableLoadingMeter({
    height: shipmentRow.height.value,
    dimensions: dimensions,
  });

  let updatedRow = shipmentRow;

  // ---------- NON STACKABLE -----------------

  if (forceNonStackable) {
    updatedRow = updateShipmentRow({
      shipmentRow: updatedRow,
      propertyName: 'nonStackable',
      updatedValues: { value: true, disabled: true },
    });

    updatedRow = handleNonStackableUpdate({
      shipmentRow: updatedRow,
      dimensions: dimensions,
      checked: forceNonStackable,
    });
  } else {
    // if height is below limit make it editable again but leave checked as is
    updatedRow = updateShipmentRow({
      shipmentRow: updatedRow,
      propertyName: 'nonStackable',
      updatedValues: { disabled: false },
    });
  }

  // ---------- END -----------------

  // ---------- LDM-----------------
  if (disableLoadingMeter) {
    updatedRow = updateShipmentRow({
      shipmentRow: updatedRow,
      propertyName: 'loadingMeter',
      updatedValues: { disabled: true, value: '', error: false, required: false },
    });
  } else {
    updatedRow = updateShipmentRow({
      shipmentRow: updatedRow,
      propertyName: 'loadingMeter',
      updatedValues: { disabled: false, required: !!updatedRow.nonStackable.value },
    });
  }

  // ---------- LDM update -----------------
  updatedRow = handleLoadingMeterUpdate({
    shipmentRow: updatedRow,
    dimensions: dimensions,
  });

  // volume
  const canCalculateVolume = volumeDimensionsFilled({ shipmentRow: updatedRow });
  const skipVolumeUpdate = volumeDimensionsEmpty({ shipmentRow: updatedRow });

  if (!skipVolumeUpdate) {
    updatedRow = updateShipmentRow({
      shipmentRow: updatedRow,
      propertyName: 'volume',
      updatedValues: {
        value: canCalculateVolume
          ? calculateVolume({
              height: updatedRow.height.value,
              width: updatedRow.width.value,
              length: updatedRow.length.value,
            })
          : '',
      },
    });
  }

  return updatedRow;
};

export const checkAndCalculateLdmVolumeAllRows = ({ dimensions, shipmentDetailsRows }) => {
  if (!shipmentDetailsRows || !shipmentDetailsRows?.length) return [];

  return shipmentDetailsRows.map(shipmentRow =>
    checkAndCalculateRowLdmVolume({
      dimensions,
      shipmentRow,
    }),
  );
};

export const afterUpdateLengthWidth = ({ context, shipmentRow, rowIndex }) => {
  const newState = structuredClone(context.state);

  // ---------- LDM update -----------------------
  let updatedRow = shipmentRow;
  updatedRow = handleLoadingMeterUpdate({
    shipmentRow: updatedRow,
    dimensions: context.state.dimensions,
  });
  // ---------------------------------------------

  // ---------- VOLUME update -----------------
  const canCalculateVolume = volumeDimensionsFilled({ shipmentRow: updatedRow });
  const skipVolumeUpdate = volumeDimensionsEmpty({ shipmentRow: updatedRow });

  if (!skipVolumeUpdate) {
    updatedRow = updateShipmentRow({
      shipmentRow: updatedRow,
      propertyName: 'volume',
      updatedValues: {
        value: canCalculateVolume
          ? calculateVolume({
              height: updatedRow.height.value,
              width: updatedRow.width.value,
              length: updatedRow.length.value,
            })
          : '',
      },
    });
  }
  // ---------------------------------------------

  newState.shipmentDetailsRows[rowIndex] = updatedRow;
  newState.totals = calculateTotals(newState.shipmentDetailsRows, context.state.totals).totals;
  context.updateState(newState);
};

export const getShipmentTypesPerProductRange = (
  productRange,
  products,
  accountNumber,
  accountsWithProducts,
  filteredSortedColliTypes,
) => {
  let shipmentTypes = [];

  if (productRange !== PRODUCTS_RANGE.ACCOUNT && productRange !== PRODUCTS_RANGE.COUNTRY) {
    const productDetails = products.find(product => product.code?.toLowerCase() === productRange?.toLowerCase());
    if (productDetails && productDetails.packageTypes) {
      const packageTypesCodes = productDetails.packageTypes.map(type => type.code?.toLowerCase());
      shipmentTypes = filteredSortedColliTypes.filter(shipmentType =>
        packageTypesCodes.includes(shipmentType.name?.toLowerCase()),
      );
    }
  } else if (productRange === PRODUCTS_RANGE.ACCOUNT || productRange === PRODUCTS_RANGE.COUNTRY) {
    const accountWithProduct = accountsWithProducts.find(account => account.accountNumber === accountNumber);
    if (accountWithProduct) {
      const enabledProductOptions = accountWithProduct.productRangeOptions
        .filter(productOption => !productOption.disabled)
        .map(productOption => productOption.value?.toLowerCase());
      const enabledProducts = accountWithProduct.products.filter(product =>
        enabledProductOptions.includes(product.productCode?.toLowerCase()),
      );
      const uniquePackageTypes = new Set();
      for (const product of enabledProducts) {
        const packageTypes = product.productSpecification.packageTypes;
        packageTypes.forEach(packageType => uniquePackageTypes.add(packageType.code?.toLowerCase()));
      }
      const uniquePackageTypesArray = Array.from(uniquePackageTypes);
      shipmentTypes = filteredSortedColliTypes.filter(shipmentType =>
        uniquePackageTypesArray.includes(shipmentType.name?.toLowerCase()),
      );
    }
  }
  return shipmentTypes;
};

export const calculateTotals = (callbackState, contextTotals) => {
  let totals = {};
  const groupName = 'shipmentDetailsRows';

  if (callbackState && callbackState.length > 0) {
    totals = JSON.parse(JSON.stringify(contextTotals));

    let quantity = 0;
    let volume = 0;
    let weight = 0;

    Array.from(callbackState).forEach((element, index) => {
      let qty = element.quantity.value * 1;
      let rowVolume = element.volume.value.toString().replace(',', '.');
      let rowWeight = element.weight.value.toString().replace(',', '.');

      rowVolume = rowVolume === '.' ? 0 : rowVolume;
      rowWeight = rowWeight === '.' ? 0 : rowWeight;

      quantity += qty;
      volume += rowVolume * qty;
      weight += rowWeight * qty;
    });

    totals[groupName].quantity.value = quantity;
    totals[groupName].volume.value = volume.toFixed(config.shipmentInputs.volume.maxDecimals);
    totals[groupName].weight.value = weight.toFixed(config.shipmentInputs.weight.maxDecimals);

    const totalLdm = calculateTotalLdm({ shipmentDetailsRows: callbackState });
    totals[groupName].loadingMeter.value = Number.isNaN(totalLdm) ? '' : totalLdm;
  }

  return { totals: { ...totals } };
};

export const resetLWHLoadingMeterAndCalculateTotals = (context, params) => {
  const callbackState = {};
  let totals = {};
  const group = JSON.parse(JSON.stringify(context.state[params.groupName]));

  totals = calculateTotals(group, context.state.totals);

  if (context.state.totals[params.groupName].volume.value * 1 !== totals.totals[params.groupName].volume.value * 1) {
    group[params.index].length.value = '';
    group[params.index].width.value = '';
    group[params.index].height.value = '';
    group[params.index].loadingMeter.value = '';
    group[params.index].length.error = false;
    group[params.index].width.error = false;
    group[params.index].height.error = false;
    group[params.index].loadingMeter.error = false;
    group[params.index].loadingMeter.disabled = false;
    group[params.index].loadingMeter.required = Boolean(group[params.index].nonStackable.value);
    callbackState[params.groupName] = group;
  }

  if (typeof totals === 'object' && totals !== null) {
    totals = JSON.parse(JSON.stringify(totals));
    Object.assign(callbackState, totals);
  }

  return callbackState;
};

export const tailLiftServiceFilter = ({
  additionalServices,
  serviceName,
  tailLiftConfig,
  totalShipmentWeight,
  shipmentRows,
}) => {
  let filteredServices = structuredClone(additionalServices);

  const minShipmentWeight = Number(tailLiftConfig?.find(el => el.name === 'minShipmentWeight')?.value);
  const maxPieceWeight = Number(tailLiftConfig?.find(el => el.name === 'maxPieceWeight')?.value);

  if (Number.isNaN(minShipmentWeight) || Number.isNaN(maxPieceWeight) || Number.isNaN(totalShipmentWeight)) {
    return filteredServices;
  }

  if (Number(totalShipmentWeight) < minShipmentWeight) {
    filteredServices = filteredServices.filter(as => as.code !== serviceName);
  }

  if (shipmentRows.some(piece => Number(piece.weight.value) > maxPieceWeight)) {
    filteredServices = filteredServices.filter(as => as.code !== serviceName);
  }

  return filteredServices;
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <div className="frc__generic-section--wrapper">
      <p className="margin-bottom-1">
        {t('general|If you would like to learn more about DHL Freight')}{' '}
        <a href={t('pageLinks|terms-and-conditions')} target="_blank" rel="noopener noreferrer">
          <strong>{t('general|Terms and Conditions')}</strong>
        </a>
      </p>
      <p className="margin-bottom-2">
        {t('general|If you would like to learn more about how DHL uses your personal data, please read our')}{' '}
        <Link to={'../privacy-policy'} target="_blank">
          <strong>{t('general|Privacy Notice')}</strong>
        </Link>
      </p>
    </div>
  );
};

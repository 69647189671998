import { Box, Grid } from '@mui/material';
import { Button } from '../Button';
import { Edit } from '@mui/icons-material';
import { PROFILE_SECTIONS } from './EditProfile';
import { useTranslation } from 'react-i18next';

export const ContactDetails = ({ userData, setReadOnly }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Box data-testid="user-name">{userData.user.name}</Box>
          <Box data-testid="user-company-name">{userData.user.companyName}</Box>
          <Box data-testid="user-primary-phone-number">{userData.user.phoneNumbers[0]?.number}</Box>
          <Box data-testid="user-vat-number">{userData.user.vatNumber}</Box>
        </Grid>
        <Grid item xs={8}>
          <Box data-testid="user-street">{userData.user.street}</Box>
          <Box>
            <span data-testid="user-postal-code">{userData.user.postalCode}</span>{' '}
            <span data-testid="user-city">{userData.user.city}</span>
          </Box>
          <Box data-testid="user-country">{userData.user.country}</Box>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Edit />}
        sx={{
          mt: '16px',
        }}
        onClick={() => {
          setReadOnly({
            section: PROFILE_SECTIONS.contactDetails,
            readOnly: false,
          });
        }}
        dataTestId="editContactButton"
      >
        {t('general|editContactInformation')}
      </Button>
    </Box>
  );
};

/**
 * Customer Types
 * */
export const ACCESS_POINT_CUSTOMER_TYPE = 'AccessPoint';
export const ACCESS_POINT_CUSTOMER_TYPE_LONG = 'Access Point'; //TODO: merge it with by ACCESS_POINT_CUSTOMER_TYPE after check
export const CONSIGNOR_CUSTOMER_TYPE = 'Consignor';
export const CONSIGNEE_CUSTOMER_TYPE = 'Consignee';

/**
 * Shipment Ranges
 * */
export const INTERNATIONAL_SHIPMENT_RANGE = 'International';
export const DOMESTIC_SHIPMENT_RANGE = 'Domestic';

/**
 * Shipment Types
 * */
export const EXPORT_SHIPMENT_TYPE = 'Export';
export const IMPORT_SHIPMENT_TYPE = 'Import';

export const IMPORT_SHIPMENT_TYPE_CODE = 'I'; //TODO: replace it by IMPORT_SHIPMENT_TYPE type and align it with BE services
export const EXPORT_SHIPMENT_TYPE_CODE = 'E'; //TODO: replace it by EXPORT_SHIPMENT_TYPE type and align it with BE services

/**
Payers
 */
export const RECEIVER_PAYER = 'Receiver';
export const SENDER_PAYER = 'Sender';
export const THIRD_PARTY_PAYER = 'Third Party';

/**
Quote products range
 */
export const PRODUCTS_RANGE_ALL = 'productsRangeAll';
export const PRODUCTS_RANGE_ONE = 'productsRangeOne';
export const PRODUCTS_RANGE_COOKIE = 'quoteProductsRange';

/**
Portal order products range
 */
export const PRODUCTS_RANGE = {
  ACCOUNT: 'accountProducts',
  COUNTRY: 'countryProducts',
};

/* Countries */
export const POLAND_CODE = 'pl';
export const HUNGARY_CODE = 'hu';

/**
Products
 */
export const SERVICE_POINT_PRODUCT_CODE = '103';
export const HEMLEVERANS_PRODUCT_CODE = '118';

import React, { useContext } from 'react';
import { config, CONST } from '../../../data-config';
import { useTranslation } from 'react-i18next';
import { MyContext } from '../../../context/context';
import { resetAllPayerNumbers } from './PayingAccountNumber-helpers';
import { callVASOnCountryOrPayerChange, beforeCheck } from '../ServiceDetails-helpers';
import Payer from './Payer';
import { Select } from 'components';
import { Error } from 'components';

import './PayingAccountNumber.css';

//TO DO: Refactor - only inherited context to be used
const PayingAccountNumber = ({ context: inheritedContext }) => {
  const { t } = useTranslation();
  const context = useContext(MyContext) || inheritedContext;

  const handleSelect = e => {
    const stateToSet = {
      payer: { value: e.value || context.state.payer.value, error: false },
    };

    [stateToSet.receiverNumber, stateToSet.thirdPartyNumber] = resetAllPayerNumbers(context);

    context.extendedSetState(stateToSet, {
      beforeUpdate: beforeCheck,
      afterUpdate: callVASOnCountryOrPayerChange,
    });
  };

  return (
    <>
      <div>
        <div className="l-grid l-grid--middle-s frc__paying-account-number has-top-margin-1">
          <Select
            selectClass="l-grid--w-100pc-s"
            label={t(`general|Account Number`)}
            name="accountNumber"
            isRequired={true}
            disabled={context.state.accounts.length === 0}
            values={context.state.accounts}
            value={context.state.accountNumber.value}
            context={context}
            config={config}
            CONST={CONST}
            skipTranslation={true}
            defaultOption={{ name: t('general|Select Account Number'), value: '' }}
          />
        </div>
        {context.state.accountNumber.error ? (
          <Error className="frc__input--error" message={t(`portalPickup|Please Select Account Number`)} />
        ) : (
          ''
        )}
      </div>
      {context.state.dhlParti.value && (
        <div>
          <div className="l-grid--w-100pc-s l-grid--w-40pc-m l-grid--middle-s frc__paying-account-number has-top-margin-1">
            <Select
              context={context}
              config={config}
              CONST={{ API_DOMAIN: 'general' }}
              label={t('general|Who will pay for this shipment?')}
              name={'payer'}
              value={context.state.payer.value}
              values={config.payers}
              disableonBlur={true}
              updateOnParent={e => handleSelect(e)}
            />
          </div>
          <Payer />
        </div>
      )}
    </>
  );
};

export default PayingAccountNumber;

import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';
import { INTERNATIONAL_SHIPMENT_RANGE } from '../../../../globals/constants';
import { config } from '../../../data-config';
import { getTotalLoadingMeterFields, validateLoadingMeter } from '../../../../globals/helpers/shipment';

export const shipmentDetailsValidation = (state, stateToSet, hasErrors, atLeastOneFieldFilled) => {
  const dimensions = state.dimensions;
  const shipmentDetailsRows = JSON.parse(JSON.stringify(state.shipmentDetailsRows));
  const { hasTotalError } = shipmentDetailsTotalsValidations(state);

  shipmentDetailsRows.forEach((shipmentRow, index) => {
    if (shipmentRow.quantity && !shipmentRow.quantity.hidden && !shipmentRow.quantity.disabled) {
      shipmentRow.quantity.error = validation(
        shipmentRow.quantity,
        dimensions.min_item_quantity,
        dimensions.max_item_quantity,
        config.regEx.float,
      );
    }

    if (shipmentRow.weight && !shipmentRow.weight.hidden && !shipmentRow.weight.disabled) {
      shipmentRow.weight.error = validation(
        shipmentRow.weight,
        dimensions.min_item_weight,
        dimensions.max_item_weight,
        config.regEx.float,
      );
    }

    if (shipmentRow.volume && !shipmentRow.volume.hidden && !shipmentRow.volume.disabled) {
      shipmentRow.volume.error = validation(
        shipmentRow.volume,
        dimensions.min_item_volume,
        dimensions.max_item_volume,
        config.regEx.float,
      );
    }

    if (shipmentRow.loadingMeter && !shipmentRow.loadingMeter.hidden && !shipmentRow.loadingMeter.disabled) {
      const { error } = validateLoadingMeter({
        loadingMeter: shipmentRow.loadingMeter.value,
        dimensions,
        required: false,
        nonStackable: shipmentRow.nonStackable.value,
        width: shipmentRow.width.value,
        length: shipmentRow.length.value,
        height: shipmentRow.height.value,
      });
      shipmentRow.loadingMeter.error = error;
    }

    if (shipmentRow.length && !shipmentRow.length.hidden && !shipmentRow.length.disabled) {
      shipmentRow.length.error = validation(
        shipmentRow.length,
        dimensions.min_item_length,
        dimensions.max_item_length,
        config.regEx.float,
      );
    }

    if (shipmentRow.width && !shipmentRow.width.hidden && !shipmentRow.width.disabled) {
      shipmentRow.width.error = validation(
        shipmentRow.width,
        dimensions.min_item_width,
        dimensions.max_item_width,
        config.regEx.float,
      );
    }

    if (shipmentRow.height && !shipmentRow.height.hidden && !shipmentRow.height.disabled) {
      shipmentRow.height.error = validation(
        shipmentRow.height,
        dimensions.min_item_height,
        dimensions.max_item_height,
        config.regEx.float,
      );
    }

    if (shipmentRow.nonStackable && !shipmentRow.nonStackable.hidden && !shipmentRow.nonStackable.disabled) {
      shipmentRow.nonStackable.error =
        shipmentRow.nonStackable.required && !shipmentRow.nonStackable.value ? true : false;
    }

    if (shipmentRow.longShipment && !shipmentRow.longShipment.hidden && !shipmentRow.longShipment.disabled) {
      shipmentRow.longShipment.error =
        shipmentRow.longShipment.required && !shipmentRow.longShipment.value ? true : false;
    }

    if (
      shipmentRow.goodsDescription &&
      !shipmentRow.goodsDescription.hidden &&
      !shipmentRow.goodsDescription.disabled
    ) {
      let descriptionError = checkFieldAndReturnState({
        name: 'goodsDescription',
        value: shipmentRow.goodsDescription.value,
        regEx: config.regEx.everything,
        lengthCheck: [RegExp(`[\\r\\n]{0,${config.maxDescriptionLength}}$`)],
        isRequired: false,
      });
      shipmentRow.goodsDescription.error = descriptionError.error;
    }

    // Check validation only if Interational
    if (state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) {
      if (shipmentRow.shippingMark && !shipmentRow.shippingMark.hidden && !shipmentRow.shippingMark.disabled) {
        let shippingMarkValidation = checkFieldAndReturnState({
          name: 'shippingMark',
          value: shipmentRow.shippingMark.value,
          regEx: config.regEx.everything,
          lengthCheck: [RegExp(`^.{0,${config.maxShippingMarkLength}}$`)],
          isRequired: false,
        });
        shipmentRow.shippingMark.error = shippingMarkValidation.error;
      }
    }
  });

  stateToSet.shipmentDetailsRows = shipmentDetailsRows;

  hasErrors =
    hasErrors ||
    checkError(shipmentDetailsRows) ||
    shipmentDetailsRows.length > state.shipmentRowsNumberMax * 1 ||
    hasTotalError;

  atLeastOneFieldFilled = atLeastOneFieldFilled || checkIfAtleastOneShipmentFieldFilled(shipmentDetailsRows);

  return {
    stateToSet,
    hasErrors,
    atLeastOneFieldFilled,
  };
};

export const validation = (item, min, max, regex) => {
  const validationV = checkFieldAndReturnState({
    value: item.value,
    isRequired: false,
    regEx: regex,
    min: min,
    max: max,
  });

  return validationV.error;
};

const checkIfAtleastOneShipmentFieldFilled = shipmentDetailsRows => {
  let atLeastOneFieldFilled = false;
  const keysToBeFiltered = ['quantity', 'shipmentType'];

  shipmentDetailsRows.forEach(shipment => {
    let keys = Object.keys(shipment);
    keys = keys.filter(key => keysToBeFiltered.indexOf(key) === -1);

    keys.forEach(key => {
      if (shipment[key].value) {
        atLeastOneFieldFilled = atLeastOneFieldFilled || true;
      }
    });

    if (shipment.dangerousGoods.value) {
      const dangerousGoodsKeys = Object.keys(shipment.dangerousGoodGroup);
      dangerousGoodsKeys.forEach(dangerousGoodsKey => {
        if (shipment.dangerousGoodGroup[dangerousGoodsKey].value) {
          atLeastOneFieldFilled = atLeastOneFieldFilled || true;
        }
      });
    }
  });

  return atLeastOneFieldFilled;
};

const checkError = shipmentDetailsRows => {
  let error = false;

  shipmentDetailsRows.forEach(shipment => {
    const keys = Object.keys(shipment);
    keys.forEach(key => {
      if (shipment[key].error) error = true;
    });

    if (shipment.dangerousGoods.value) {
      const dangerousGoodsKeys = Object.keys(shipment.dangerousGoodGroup);
      dangerousGoodsKeys.forEach(dangerousGoodsKey => {
        if (shipment.dangerousGoodGroup[dangerousGoodsKey].error) error = true;
      });
    }
  });

  return error;
};

export const shipmentDetailsTotalsValidations = state => {
  const totals = state.totals.shipmentDetailsRows;
  const dimensions = state.dimensions || {};

  const { loadingMeterError } = getTotalLoadingMeterFields({ state });

  const quantityError =
    (totals.quantity.value * 1 < dimensions.min_total_quantity && totals.weight.value * 1 > 0) ||
    totals.quantity.value * 1 > dimensions.max_total_quantity;
  const weightError =
    (totals.weight.value * 1 < dimensions.min_shipment_weight && totals.weight.value * 1 > 0) ||
    totals.weight.value * 1 > dimensions.max_shipment_weight;
  const volumeError =
    totals.volume.value * 1 < dimensions.min_total_volume || totals.volume.value * 1 > dimensions.max_total_volume;

  let chargeableWeightError;
  if (totals.chargeableWeight.value !== null) {
    chargeableWeightError =
      totals.chargeableWeight.value * 1 < dimensions.min_total_chargeableWeight ||
      totals.chargeableWeight.value * 1 > dimensions.max_total_chargeableWeight;
  }

  return {
    totals,
    dimensions,
    quantityError,
    weightError,
    volumeError,
    loadingMeterError,
    chargeableWeightError,
    hasTotalError:
      quantityError ||
      weightError ||
      volumeError ||
      loadingMeterError ||
      chargeableWeightError ||
      false,
  };
};

import { globalConfig } from '../data-config-global';
import { caseInsensitiveEquals } from '../utils/strings';
import { CONST } from '../../portal-order/data-config';
import { apiLinks } from '../../config/api-config';
import { apiRequest } from '../utils/requests';

/**
 * Calculate whether city can be fetched via Farm API
 * for the combination of country code and postal code
 *
 * FarmAPI is not consistent therefore countries have
 * to be allowed individually in config for now
 *
 * @param {string} accountCountryCode - Account country code
 * @param {string} countryCode - Country code
 * @param {string} postalCode - Postal code
 * @returns {boolean} - Can proceed to farmapi city fetch call
 */
export const canFetchCityForPostalcode = ({ accountCountryCode, countryCode, postalCode, skipRegexValidation }) => {
  const countryCodeUpper = countryCode?.toUpperCase();
  if (globalConfig.no_visible_city_for_postal_code_countries.includes(accountCountryCode?.toUpperCase())) {
    return false;
  }
  const isEnabledCityBasedOnCountry = !globalConfig.disableCityFetchCountryList.includes(countryCodeUpper);
  if (!countryCodeUpper || !postalCode || !isEnabledCityBasedOnCountry) {
    return false;
  }

  // country has only numeric postalCodes
  if (globalConfig.numberPostalCodeCountries.includes(countryCodeUpper)) {
    if (skipRegexValidation) {
      return true;
    }
    return /^[0-9]*$/.test(postalCode);
  }

  if (globalConfig.alphaNumericPostalCodeCountries.includes(countryCodeUpper)) {
    return true;
  }

  return false;
};

/**
 * Get regex for postalcode validation
 *
 * Final validation on form submit should use the
 * strict variant
 *
 * Strict false is for the input field to allow
 * continous typing
 *
 * @param {string} accountCountryCode - Account country code
 * @param {string} countryCode - Country code
 * @param {boolean} strict - Return strict variant
 * @returns {RegExp} - regexp expression
 */
export const getPostalCodeRegex = ({ countryCode, accountCountryCode, strict = true }) => {
  if (!caseInsensitiveEquals(accountCountryCode, 'SE')) {
    // standardized regex for non Swedish accounts
    return strict ? globalConfig.regEx.postalCodeStrict : globalConfig.regEx.postalCodeSoft;
  }

  // old logic used for swedish accounts only
  const numberOnlyPostalcode = globalConfig.numberPostalCodeCountries.includes(countryCode?.toUpperCase());
  if (numberOnlyPostalcode) {
    return strict ? globalConfig.regEx.postalCodeStrictSE : globalConfig.regEx.postalCodeSoftSE;
  }
  // maybe could use "text" always
  return strict ? globalConfig.regEx.text : globalConfig.regEx.everything;
};

export const getCityName = async ({
  countryCode,
  postalCode,
  cityName = false,
  useCustomCountryCode = false,
  headerCountryCode,
}) => {
  const resultMap = {};

  let url = apiLinks.fetchCityName;
  url = url.replace('{countryCode}', countryCode);
  url = url.replace('{postalCode}', postalCode);

  if (cityName) {
    url = `${url}/${cityName}`;
  }
  const params = {
    headers: {
      'Country-Code': headerCountryCode?.toLowerCase() || countryCode?.toLowerCase(),
    },
  };

  await apiRequest(url, 'GET', useCustomCountryCode ? params : undefined)
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });
  return resultMap;
};

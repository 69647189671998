import { config } from '../../../data-config';
import { partyNameLengthLimit } from 'globals/utils/formatting';
import {
  ACCESS_POINT_CUSTOMER_TYPE,
  CONSIGNEE_CUSTOMER_TYPE,
  CONSIGNOR_CUSTOMER_TYPE,
  RECEIVER_PAYER,
  SENDER_PAYER,
} from '../../../../globals/constants';

export const formPartyData = (state, payerCode, collectionAtTerminal = false) => {
  const parties = [];
  const accountNumber = state.accountNumber.value && state.receiverNumber.value ? undefined : state.accountNumber.value;

  parties.push(formData(state, 'pickup', 'SenderReference', SENDER_PAYER, CONSIGNOR_CUSTOMER_TYPE, accountNumber));
  parties.push(formData(state, 'delivery', 'ReceiverReference', RECEIVER_PAYER, CONSIGNEE_CUSTOMER_TYPE));

  if (state.isb2cProduct) {
    const servicePointData = servicePointPartyData(state);

    if (servicePointData) parties.push(servicePointData);
  }
  if (state.partyData && state.partyData.party && state.partyData.party.id) {
    parties.push(formData(state.partyData.party, '', '', '', ACCESS_POINT_CUSTOMER_TYPE));
  }
  if (state.pickupFromDifferentAddress.value && !state.pickupAddressResidential.value)
    parties.push(formData(state, 'differentPickup', 'SenderReference', SENDER_PAYER, 'Pickup'));

  if (payerCode === '4') parties.push({ type: 'FreightPayer', id: state.thirdPartyNumber.value });

  if (collectionAtTerminal && collectionAtTerminal.terminal && collectionAtTerminal.terminal.address) {
    parties.push(collectionAtTerminalPartyData(state, collectionAtTerminal));
  } else if (state.deliverToDifferentAddress.value && !state.deliveryAddressResidential.value) {
    parties.push(formData(state, 'differentDelivery', 'ReceiverReference', RECEIVER_PAYER, 'Delivery'));
  }

  return partyNameLengthLimit(parties, config);
};

const servicePointPartyData = state => {
  let servicePointData = null;

  if (
    state.servicePointUseResidentialAddress.isProductServicePointDelivery ||
    !state.servicePointUseResidentialAddress.value
  ) {
    if (state.servicePointUseResidentialAddress.isProductServicePointDelivery) {
      const selectedType = state.notificationsForReceiver.find(rs => rs.value);
      const type = selectedType && selectedType.type ? selectedType.type : null;

      if (type) {
        state.selectedServicePoint.email = type === 'email' ? state.deliveryEmail.value : null;
        state.selectedServicePoint.phone =
          type === 'sms' && state['deliveryPhoneNumbers'].length > 0
            ? state['deliveryPhoneNumbers'][0].code + '-' + state['deliveryPhoneNumbers'][0].number
            : null;
      }
    }

    const servicePoint = JSON.parse(JSON.stringify(state.selectedServicePoint));
    servicePointData = formData(servicePoint, '', '', '', ACCESS_POINT_CUSTOMER_TYPE);
  }

  return servicePointData;
};

const collectionAtTerminalPartyData = (state, collectionAtTerminal) => {
  const collectionAtTerminalMap = collectionAtTerminal.terminal.address;

  collectionAtTerminalMap.name = collectionAtTerminal.terminal.name;
  collectionAtTerminalMap.countryCode = state['deliveryCountry'].value;
  collectionAtTerminalMap.id = collectionAtTerminal.terminal.id;
  collectionAtTerminalMap.phone = collectionAtTerminal.terminal.phone;

  return formData(collectionAtTerminalMap, '', '', '', 'Delivery');
};

const formData = (state, type, reference, name, customerType, accountNumber) => {
  if (state[type + 'City'] !== undefined) {
    return {
      address: {
        cityName: state[type + 'City']?.value || null,
        countryCode: state[type + 'Country']?.value || null,
        postalCode: state[type + 'PostalCode']?.value || null,
        street: state[type + 'Street']?.value || null,
      },
      confirmationEmail: state[type + 'Email']?.value || null,
      contactName: state[type + 'Name']?.value || null,
      email: state[type + 'Email']?.value || null,
      emailConfirmation: state[type + 'EmailConfirmation']?.value || null,
      id: accountNumber !== undefined ? accountNumber : '',
      name: state[type + name].value || null,
      phone: state[type + 'PhoneNumbers'][0].code + '-' + state[type + 'PhoneNumbers'][0].number,
      references: [state[type + reference] ? state[type + reference].value : null],
      type: customerType,
      subtype: customerType === ACCESS_POINT_CUSTOMER_TYPE ? state.locationType : null,
    };
  } else {
    return {
      address: {
        cityName: state.cityName || null,
        shopName: state.shopName || null,
        countryCode: state.countryCode || config.defaultCountry,
        postalCode: state.postalCode || null,
        street: state.street || state.address1 || null,
        locationType: state.locationType || null,
      },
      confirmationEmail: null,
      contactName: null,
      email: state.email || null,
      emailConfirmation: state.emailConfirmation || null,
      id: state.id || null,
      name: state.partyName || state.name || null,
      phone: state.phone || null,
      references: [null],
      type: customerType,
      subtype: customerType === ACCESS_POINT_CUSTOMER_TYPE ? state.locationType : null,
    };
  }
};

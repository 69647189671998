import { config } from '../../../data-config';

export const tailLiftServiceFilter = ({
  additionalServices,
  serviceName,
  tailLiftConfig,
  totalShipmentWeight,
  shipmentRows,
}) => {
  let filteredServices = structuredClone(additionalServices);

  const minShipmentWeight = Number(tailLiftConfig?.find(el => el.name === 'minShipmentWeight')?.value);
  const maxPieceWeight = Number(tailLiftConfig?.find(el => el.name === 'maxPieceWeight')?.value);

  if (Number.isNaN(minShipmentWeight) || Number.isNaN(maxPieceWeight) || Number.isNaN(totalShipmentWeight)) {
    return filteredServices;
  }

  if (Number(totalShipmentWeight) < minShipmentWeight) {
    filteredServices = filteredServices.filter(as => as.code !== serviceName);
  }

  if (shipmentRows.some(piece => Number(piece.weight.value) > maxPieceWeight)) {
    filteredServices = filteredServices.filter(as => as.code !== serviceName);
  }

  return filteredServices;
};

export const payerCodeBasedFiltering = (
  stateObject,
  { payerCode, productCode, shipmentRange, shipmentTypesImpOrExp },
) => {
  let additionalServices = JSON.parse(JSON.stringify(stateObject.additionalServices));

  additionalServices = additionalServices.filter(as => {
    let available = true;

    if (config.additionalServicesFilteredByPayerCode && config.additionalServicesFilteredByPayerCode[as.code]) {
      const VASconfig = config.additionalServicesFilteredByPayerCode[as.code];

      // option1: Filter by payerCode not allowed
      if (VASconfig.payerCodeNotAllowed) available = VASconfig.payerCodeNotAllowed.indexOf(payerCode) === -1;

      // option2: Availability by specific products
      if (
        VASconfig.payerCodeAllowedByProduct &&
        VASconfig.payerCodeAllowedByProduct[productCode] &&
        VASconfig.payerCodeAllowedByProduct[productCode][shipmentRange]
      ) {
        let VASconfigMatch = VASconfig.payerCodeAllowedByProduct[productCode][shipmentRange];

        if (VASconfigMatch[shipmentTypesImpOrExp]) VASconfigMatch = VASconfigMatch[shipmentTypesImpOrExp];
        available = VASconfigMatch.indexOf(payerCode) !== -1;
      }
    }

    return available;
  });

  return additionalServices;
};

import React, { Component } from 'react';
import { toCamelCase } from 'globals/utils/formatting';
// import { MyContext } from "../../../context/context";
// import { matchRegEx } from 'globals/utils/fieldValidations";

import './Checkbox.css';

const embedInlineTooltip = (label, tooltip, CONST) => {
  const tooltipMarkup = `
    <span class="has-tooltip-label">
      <div class="frc__tooltip-title-container has-icon icon-information color-DHL-red c-fcp-quote--info-container frc__tooltip_title">
        <div class="frc__tooltip-container-flyout">${tooltip}</div>
      </div>
    </span>`;

  return label.replace(CONST.INLINE_TOOLTIP_PLACEHOLDER, tooltipMarkup);
};

export default class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.className = props.className || 'l-grid c-form-step--item c-freightcalc--quotation-form-checkbox-input';
    this.labelClass = props.labelClass || 'frc__label frc__label--checkbox';
  }

  handleChange = (value, context) => {
    const params = {
      name: this.props.name,
      value: !value,
      selectedStatus: !value,
      isRequired: this.props.isRequired,
      index: this.props.index,
      disabled: this.props.disabled,
      skipError: this.props.skipError,
      deselect: this.props.deselect,
      checkGroupIndex: this.props.checkGroupIndex,
      beforeUpdate: this.props.beforeUpdate,
      afterUpdate: this.props.afterUpdate,
      callbackFunction: this.props.callback,
    };

    if (this.props.updateOnParent) {
      this.props.updateOnParent(params, context);
    } else {
      context.updateField(params);
    }
  };

  render() {
    const label = this.props.inlineTooltip
      ? embedInlineTooltip(this.props.label, this.props.inlineTooltip, this.props.CONST)
      : this.props.label;
    const id = toCamelCase(this.props.id || this.props.name) + 'ChkBx';

    return (
      <>
        <div
          className={this.className + (this.props.disabled ? ' disabled' : '')}
          data-testid={`${this.props.name}CheckboxId`}
        >
          <div className="l-grid c-form-step--item c-freightcalc--item-checkbox">
            <input
              id={id}
              className={
                this.props.inputClassName
                  ? this.props.inputClassName
                  : ' c-form--element-base c-form--element-input c-form--element-checkbox'
              }
              type="checkbox"
              onChange={e => this.handleChange(!e.target.checked, this.props.context)}
              disabled={this.props.disabled}
              checked={this.props.checked ? true : false}
            />
            <label
              htmlFor={id}
              className={
                this.props.labelClassName
                  ? this.props.labelClassName
                  : 'c-form--element-base c-form--element-label c-form--element-label-checkbox c-freightcalc--checkbox c-fcp--checkbox-label'
              }
              style={{ overflow: 'visible' }}
              dangerouslySetInnerHTML={{
                __html:
                  (label ? label : '') +
                  (this.props.isRequired && !this.props.hideAsterisk ? '*' : '') +
                  (this.props.labelAfter ? this.props.labelAfter : '') +
                  (this.props.secondayText
                    ? `<span 
                          class="frc__tooltip-container 
                        c-fcp-freightcalc-additional-service-checkbox-price"
                        >
                          ${this.props.secondayText}
                        </span>`
                    : '') +
                  (this.props.tooltip
                    ? `<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container">
                          <div class="frc__tooltip-container-flyout">${this.props.tooltip}</div>
                        </div>`
                    : ''),
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

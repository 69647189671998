import { config } from '../../../data-config';
import { checkFieldAndReturnState, checkGroupOfFieldsAndReturnState } from 'globals/utils/fieldValidations';
import { SENDER_PAYER } from '../../../../globals/constants';

export const pickupRouteValidation = (state, stateToSet, hasErrors, atLeastOneFieldFilled, prefix) => {
  let getState = {};

  let postalCodeNumber = config.numberPostalCodeCountries.indexOf(state.pickupCountry.value) !== -1 ? true : false;

  // Sender check
  getState = checkFieldAndReturnState({
    name: prefix + SENDER_PAYER,
    value: state[prefix + SENDER_PAYER].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{0,${config.maxDefaultInputLength}}$`)],
    isRequired: false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: false };
  atLeastOneFieldFilled = atLeastOneFieldFilled || getState.value ? true : false;

  // Street check

  getState = checkFieldAndReturnState({
    name: prefix + 'Street',
    value: state[prefix + 'Street'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{0,${config.maxDefaultInputLength}}$`)],
    isRequired: false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = {
    value: getState.value,
    error: getState.error,
    isRequired: false,
  };
  atLeastOneFieldFilled = atLeastOneFieldFilled || getState.value ? true : false;

  // Postalcode check
  getState = checkFieldAndReturnState({
    name: prefix + 'PostalCode',
    value: state[prefix + 'PostalCode'].value,
    regEx: postalCodeNumber ? config.regEx.number : config.regEx.text,
    lengthCheck: [RegExp(`^.{0,${config.maxPostalCode}}$`)],
    isRequired: false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = {
    value: getState.value ? getState.value.toUpperCase() : '',
    error: getState.error,
    isRequired: false,
  };
  atLeastOneFieldFilled = atLeastOneFieldFilled || getState.value ? true : false;

  // City check
  getState = checkFieldAndReturnState({
    name: prefix + 'City',
    value: state[prefix + 'City'].value.trim(),
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{0,${config.maxDefaultInputLength}}$`)],
    isRequired: false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = {
    value: getState.value.trim(),
    error: getState.error,
    isRequired: false,
  };
  atLeastOneFieldFilled = atLeastOneFieldFilled || getState.value ? true : false;

  // Name check
  getState = checkFieldAndReturnState({
    name: prefix + 'Name',
    value: state[prefix + 'Name'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{0,${config.maxDefaultInputLength}}$`)],
    isRequired: false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: false };
  atLeastOneFieldFilled = atLeastOneFieldFilled || getState.value ? true : false;

  // E-mail check
  getState = checkFieldAndReturnState({
    name: prefix + 'Email',
    value: state[prefix + 'Email'].value,
    regEx: config.regEx.email,
    lengthCheck: [RegExp(`^.{0,${config.maxEmailLength}}$`)],
    isRequired: false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: false };
  atLeastOneFieldFilled = atLeastOneFieldFilled || getState.value ? true : false;

  // Phone Numbers check
  getState = checkGroupOfFieldsAndReturnState({
    groupName: prefix + 'PhoneNumbers',
    values: state[prefix + 'PhoneNumbers'],
    fields: [
      {
        name: 'code',
        isRequired: false,
      },
      {
        name: 'number',
        regEx: config.regEx.number,
        lengthCheck: [RegExp(`^.{1,${config.maxPhoneNumberDigits}}$`)],
        isRequired: false,
        calculatePhoneLengthCheck: true,
      },
    ],
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.groupName] = [...getState.values];
  atLeastOneFieldFilled = atLeastOneFieldFilled || (getState.values[0] && getState.values[0].number) ? true : false;

  // Sender Reference check'
  if (!(prefix === 'differentPickup')) {
    getState = checkFieldAndReturnState({
      name: prefix + 'SenderReference',
      value: state[prefix + 'SenderReference'].value,
      regEx: config.regEx.everything,
      isRequired: false,
    });
    hasErrors = getState.error || hasErrors;
    stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: false };
    atLeastOneFieldFilled = atLeastOneFieldFilled || getState.value ? true : false;
  }

  return {
    stateToSet,
    hasErrors,
    atLeastOneFieldFilled,
  };
};

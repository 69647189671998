import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Loader } from '../../../loader.svg';

export const CityBox = ({ value, loading = false }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: 'relative',
        mt: '0.4rem',
        p: '1rem',
      }}
    >
      <Box>{t('general|labels|inputs|City')}</Box>
      <TextField variant="outlined" disabled value={value} inputProps={{ sx: { p: '1.27rem' } }} />
      {loading ? (
        <Box
          sx={{ position: 'absolute', top: 'calc(50% - 22px)', left: 'calc(50% - 24px)', '& svg': { width: '48px' } }}
        >
          <Loader />
        </Box>
      ) : null}
    </Box>
  );
};

import React, { Component } from 'react';
import { MyProvider, MyContext } from '../../context/context';
import { Typography } from '@mui/material';

import EnterPickupLocation from '../EnterPickupLocation/EnterPickupLocation';
import ServiceDetails from '../ServiceDetails/ServiceDetails';
import BookingConfirmation from '../BookingConfirmation/BookingConfirmation';
import TermsAndSubmit from '../TermsAndSubmit/TermsAndSubmit';
import ThankYouPage from '../ThankYouPage/ThankYouPage';

import './../../css/global/_variables.css';
// import './../../css/global/_icons.css';
// import './../../css/base/iconfont.css';

import './AppPalletPickup.css';
import 'globals/utils/debugHelpers';
import { GeneralError } from 'components';
import cssVars from 'css-vars-ponyfill';
import { Translation } from 'react-i18next';

const ENV_DEV = process.env.NODE_ENV === 'development';

export default class AppPalletPickup extends Component {
  componentDidMount() {
    cssVars();
  }

  render() {
    return (
      <Translation>
        {t => (
          <div className={ENV_DEV ? 'l-view' : ''}>
            <MyProvider>
              <div className="c-page-headline component-small has-rte component-margin frc__heading">
                <div className="c-page-headline--wrapper">
                  <Typography variant="h1" mt={{ xs: 0, sm: 2 }}>
                    {t('general|pageTitles|palletPickup')}
                  </Typography>
                </div>
              </div>
              <div className="frc__app">
                <MyContext.Consumer>
                  {context => (
                    <>
                      <GeneralError context={context} message={t('general|errors|General error')} />
                      {context.state.pickupOrderSubmited || context.state.redThankYouPage ? (
                        <ThankYouPage
                          pickupOrderResult={context.state.pickupOrderResult}
                          isRedThankYouPage={context.state.redThankYouPage}
                          redThankYouPageErrorMessage={context.state.redThankYouErrorMessage}
                          responseStatus={context.state.responseStatus}
                          pickupDate={context.state.pickupOrderResult.pickupDateTime}
                          pickupNumber={context.state.pickupOrderResult.bookingNumber}
                          pickupOrderMovedToNextDay={context.state.pickupOrderMovedToNextDay}
                        />
                      ) : (
                        <div className="l-grid">
                          <div className="l-grid--w-100pc-s l-grid--w-100pc-s">
                            <ServiceDetails />
                            <EnterPickupLocation />
                            <BookingConfirmation />
                            <TermsAndSubmit />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </MyContext.Consumer>
              </div>
            </MyProvider>
          </div>
        )}
      </Translation>
    );
  }
}

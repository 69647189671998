import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';

import { Checkbox } from 'components';
import { Error } from 'components';
import { ROUTES, withRouter } from 'router';

// import { checkSubmitDetails, prepareUserData } from "./TermsAndSubmit-helpers";
// import { apiRequest } from "../../../../../globals/utils/requests";

// const ENV_DEV = process.env.NODE_ENV === "development";

class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationError: false,
      isLoading: false,
      apiError: false,
      transactionId: '',
    };
  }

  disableOtherTabs = () => {
    this.props.disableOtherTabs();
  };

  render() {
    return (
      <>
        <div className="frc__generic-section--wrapper">
          <Translation>
            {t => (
              <>
                <div className="margin-bottom-1">
                  <Checkbox
                    regEx={config.regEx.number}
                    label={
                      t('general|I accept the DHL Freight') +
                      ' <strong><a href="' +
                      t('pageLinks|terms-and-conditions') +
                      '/' +
                      ROUTES.termsOfUse +
                      '" target="_blank" rel="noopener noreferrer" >' +
                      t('general|Terms and Conditions') +
                      '.</a></strong>'
                    }
                    name="termsAndConditions"
                    isRequired={true}
                    checked={this.props.context.state.termsAndConditions.value}
                    context={this.props.context}
                    config={config}
                    CONST={CONST}
                  />
                  {this.props.context.state.termsAndConditions.error ? (
                    <Error
                      name="termsAndConditions"
                      className="frc__input--error"
                      message={t('general|errors|Terms and Conditions error')}
                    />
                  ) : (
                    ''
                  )}
                </div>

                <p
                  className="margin-bottom-2"
                  dangerouslySetInnerHTML={{
                    __html:
                      t(
                        'general|If you would like to learn more about how DHL uses your personal data, please read our',
                      ) +
                      ' <strong><a href="' +
                      this.props.linkTo(ROUTES.privacyPolicy) +
                      '" target="_blank" rel="noopener noreferrer">' +
                      t('general|Privacy Notice') +
                      '.</a></strong>',
                  }}
                />
              </>
            )}
          </Translation>
        </div>
      </>
    );
  }
}

export default withRouter(TermsAndConditions);

import { config } from '../../../data-config';
import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';
import {
  checkIfSelectedProductIsHomeOrStycke,
  dhlPallEURHalfPalletDimensions,
} from '../../EnterShipmentDetails/ShipmentDetailsRow/ShipmentDetailsRow-helpers';

export const shipmentDetailsTotalsValidations = context => {
  const totals = context.state.totals.shipmentDetailsRows;
  const dimensions = context.state.dimensions || {};
  const quantityError =
    totals.quantity.value * 1 < dimensions.min_total_quantity ||
    totals.quantity.value * 1 > dimensions.max_total_quantity;
  const weightError =
    (totals.weight.value * 1 < dimensions.min_shipment_weight && totals.weight.value * 1 > 0) ||
    totals.weight.value * 1 > dimensions.max_shipment_weight;
  const volumeError =
    totals.volume.value * 1 < dimensions.min_total_volume || totals.volume.value * 1 > dimensions.max_total_volume;
  const loadingMeterError =
    totals.loadingMeter.value * 1 < dimensions.min_total_loading_meter ||
    totals.loadingMeter.value * 1 > dimensions.max_total_loading_meter;
  const palletPlaceError =
    totals.palletPlace.value * 1 < dimensions.min_total_palletPlace ||
    totals.palletPlace.value * 1 > dimensions.max_total_palletPlace;
  let chargeableWeightError;

  if (totals.chargeableWeight.value !== null) {
    chargeableWeightError =
      totals.chargeableWeight.value * 1 < dimensions.min_total_chargeableWeight ||
      totals.chargeableWeight.value * 1 > dimensions.max_total_chargeableWeight;
  }

  return {
    totals,
    dimensions,
    quantityError,
    weightError,
    volumeError,
    loadingMeterError,
    palletPlaceError,
    chargeableWeightError,
    hasTotalError:
      quantityError ||
      weightError ||
      volumeError ||
      loadingMeterError ||
      palletPlaceError ||
      // chargeableWeightError ||
      false,
  };
};

export const enterShipmentDetailsValidations = (context, stateToSet, hasErrors) => {
  let getState = {};
  const state = context.state;
  const { hasTotalError } = shipmentDetailsTotalsValidations(context);
  const isHomeOrStycke = checkIfSelectedProductIsHomeOrStycke(state);

  stateToSet.shipmentDetailsRows = JSON.parse(JSON.stringify(state.shipmentDetailsRows));

  if (state.shipmentDetailsRows.length > 0) {
    state.shipmentDetailsRows.forEach((row, index) => {
      const lengthMax =
        (row.shipmentType.value === 'pallet' || row.shipmentType.value === 'unspecified') && row.weight.value >= 50;
      const lengthMin =
        row.longShipment.value && (row.shipmentType.value === 'pallet' || row.shipmentType.value === 'unspecified');
      if (!row.height.hidden) {
        const { maxHeight } = dhlPallEURHalfPalletDimensions(state, row);

        getState = checkFieldAndReturnState({
          name: 'height',
          value: row.height.value,
          isRequired:
            config.shipmentTypesFields[row.shipmentType.value]['height'].required ||
            ((row.shipmentType.value === 'pallet' || row.shipmentType.value === 'unspecified') &&
              row.longShipment.value) ||
            false,
          min: state.dimensions.min_item_height,
          max: maxHeight,
        });
        hasErrors = getState.error || hasErrors;
        stateToSet.shipmentDetailsRows[index][getState.name].error = getState.error;
      }

      if (!row.length.hidden) {
        getState = checkFieldAndReturnState({
          name: 'length',
          value: row.length.value,
          isRequired:
            config.shipmentTypesFields[row.shipmentType.value]['length'].required ||
            ((row.shipmentType.value === 'pallet' || row.shipmentType.value === 'unspecified') &&
              row.longShipment.value) ||
            false,
          min:
            isHomeOrStycke && lengthMin
              ? config.dimensions.length_with_weight_less_than_50
              : state.dimensions.min_item_length,
          max:
            isHomeOrStycke && lengthMax
              ? config.dimensions.length_with_weight_less_than_50
              : state.dimensions.max_item_length,
        });
        hasErrors = getState.error || hasErrors;
        stateToSet.shipmentDetailsRows[index][getState.name].error = getState.error;
      }

      if (!row.loadingMeter.hidden) {
        getState = checkFieldAndReturnState({
          name: 'loadingMeter',
          value: row.loadingMeter.value,
          isRequired: row.loadingMeter.required || false,
          min: state.dimensions.min_item_loading_meter,
          max: state.dimensions.max_item_loading_meter,
        });
        hasErrors = getState.error || hasErrors;
        stateToSet.shipmentDetailsRows[index][getState.name].error = getState.error;
      }

      if (!row.palletPlace.hidden) {
        getState = checkFieldAndReturnState({
          name: 'palletPlace',
          value: row.palletPlace.value,
          isRequired: row.palletPlace.required || false,
          min: state.dimensions.min_item_palletPlace,
          max: state.dimensions.max_item_palletPlace,
        });
        hasErrors = getState.error || hasErrors;
        stateToSet.shipmentDetailsRows[index][getState.name].error = getState.error;
      }

      if (!row.quantity.hidden) {
        getState = checkFieldAndReturnState({
          name: 'quantity',
          value: row.quantity.value,
          isRequired: row.quantity.required || false,
          min: state.dimensions.min_item_quantity,
          max: state.dimensions.max_item_quantity,
        });
        hasErrors = getState.error || hasErrors;
        stateToSet.shipmentDetailsRows[index][getState.name].error = getState.error;
      }

      if (!row.shipmentType.hidden) {
        getState = checkFieldAndReturnState({
          name: 'shipmentType',
          value: row.shipmentType.value,
          isRequired: row.shipmentType.required || false,
        });
        hasErrors = getState.error || hasErrors;
        stateToSet.shipmentDetailsRows[index][getState.name].error = getState.error;
      }

      if (!row.volume.hidden) {
        getState = checkFieldAndReturnState({
          name: 'volume',
          value: row.volume.value,
          isRequired: row.volume.required || false,
          min: state.dimensions.min_item_volume,
          max: state.dimensions.max_item_volume,
        });
        hasErrors = getState.error || hasErrors;
        stateToSet.shipmentDetailsRows[index][getState.name].error = getState.error;
      }

      if (!row.weight.hidden) {
        const { maxWeight } = dhlPallEURHalfPalletDimensions(state, row);

        getState = checkFieldAndReturnState({
          name: 'weight',
          value: row.weight.value,
          isRequired: row.weight.required || false,
          min: state.dimensions.min_item_weight,
          max: maxWeight,
        });
        hasErrors = getState.error || hasErrors;
        stateToSet.shipmentDetailsRows[index][getState.name].error = getState.error;
      }

      if (!row.width.hidden) {
        getState = checkFieldAndReturnState({
          name: 'width',
          value: row.width.value,
          isRequired:
            config.shipmentTypesFields[row.shipmentType.value]['width'].required ||
            ((row.shipmentType.value === 'pallet' || row.shipmentType.value === 'unspecified') &&
              row.longShipment.value) ||
            false,
          min: state.dimensions.min_item_width,
          max: state.dimensions.max_item_width,
        });
        hasErrors = getState.error || hasErrors;
        stateToSet.shipmentDetailsRows[index][getState.name].error = getState.error;
      }
    });

    // Global error variable final update
    hasErrors = hasErrors || hasTotalError;
  }

  return {
    stateToSet,
    hasErrors,
  };
};

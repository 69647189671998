import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AdditionalServiceRow from './AdditionalServiceRow/AdditionalServiceRow';
import { config, CONST } from '../../../../data-config';

import './AdditionalServices.css';

export default function AdditionalServices(props) {
  const { t } = useTranslation();

  const specialCase = useRef();
  const expand = useRef();
  const additionalServicesArrWithoutDlMontrg = [];
  const additionalServicesArrWithDlMontrg = [];

  const expandDeliveryMonitoring = () => {
    expand.current.classList.toggle('expand');
    specialCase.current.classList.toggle('frc__special_case');
  };

  const closeDeliveryMonitoring = () => {
    if (expand.current) {
      expand.current.classList.remove('expand');
    }

    if (specialCase.current) {
      specialCase.current.classList.add('frc__special_case');
    }
  };

  // if delivery options present on edit
  useEffect(() => {
    const isDeliveryMonitoringAvailable = props.context.state.additionalServices
      .filter(additionalServiceRow => additionalServiceRow.code.indexOf('deliveryMonitoring') !== -1)
      .some(deliveryMoniteringVas => deliveryMoniteringVas.value);

    if (isDeliveryMonitoringAvailable) {
      expandDeliveryMonitoring();
    }

    return () => {
      closeDeliveryMonitoring();
    };

    // eslint-disable-next-line
  }, [props.context.state.additionalServices]);

  props.context.state.additionalServices.forEach((additionalServiceRow, ind) => {
    if (additionalServiceRow.code.indexOf('deliveryMonitoring') === -1) {
      additionalServicesArrWithoutDlMontrg.push(
        <AdditionalServiceRow
          key={ind}
          getQuoteForPriceWithAd={props.getQuoteForPriceWithAd}
          className="l-grid--w-100pc-s l-grid--w-50pc-m"
          routeContext={props.routeContext}
          shipmentContext={props.shipmentContext}
          index={ind}
          additionalServiceRow={additionalServiceRow}
          error={additionalServiceRow.error}
          context={props.context}
          config={config}
          CONST={CONST}
        />,
      );
    } else {
      additionalServicesArrWithDlMontrg.push(
        <AdditionalServiceRow
          key={ind}
          getQuoteForPriceWithAd={props.getQuoteForPriceWithAd}
          deliveryMontoringClass="frc__generic--field-wrapper"
          className="l-grid--w-100pc-s l-grid--w-100pc-m"
          routeContext={props.routeContext}
          shipmentContext={props.shipmentContext}
          specialCase="frc_deliveryMonitoring"
          index={ind}
          additionalServiceRow={additionalServiceRow}
          error={additionalServiceRow.error}
          context={props.context}
          config={config}
          CONST={CONST}
        />,
      );
    }
  });

  return (
    <>
      {props.context.state.additionalServices.length > 0 ? (
        <div className="frc__additional-services--wrapper  ">
          <h5
            className="margin-bottom-1 frc__push-bottom frc__red-lined-title "
            dangerouslySetInnerHTML={{
              __html: t('general|Select any optional <strong>Additional Services</strong> that apply'),
            }}
          />

          <div className="l-grid l-grid--w-100pc-s">
            {additionalServicesArrWithoutDlMontrg}
            {additionalServicesArrWithDlMontrg.length > 0 && (
              <div
                id="frc_additional-services_delivery_monitoring"
                ref={expand}
                className="frc_additional-services--checkbxes-sub-group 
                          l-grid--w-100pc-s l-grid--w-50pc-m l-grid--has-gutter "
              >
                <div
                  className="frc_additional-services--checkbxes-sub-group-title "
                  onClick={() => expandDeliveryMonitoring()}
                >
                  {t(`general|Delivery monitoring`)}
                </div>
                <div id="frc__special_case" ref={specialCase} className="frc__special_case">
                  {additionalServicesArrWithDlMontrg}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}

import { useRouting } from './useRouting';

export const withRouter = Component => {
  const Wrapper = props => {
    const { navigate, linkTo, navigateRouter } = useRouting();

    return <Component navigate={navigate} linkTo={linkTo} navigateRouter={navigateRouter} {...props} />;
  };

  return Wrapper;
};

import { config } from '../../../data-config';
import { checkFieldAndReturnState, checkGroupOfFieldsAndReturnState } from 'globals/utils/fieldValidations';

export const enterDeliveryLocationValidations = (context, stateToSet, hasErrors) => {
  const state = context.state;
  const startPhoneLengthFromZero = !context.state.dhlParti.value;
  let getState = {};

  // Delivery receiver check
  getState = checkFieldAndReturnState({
    name: 'deliveryReceiver',
    value: state['deliveryReceiver'].value,
    regEx: config.regEx.everything,
    isRequired: context.state.dhlParti.value || context.state.deliveryMandatoryByPriorityService,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Street check
  getState = checkFieldAndReturnState({
    name: 'deliveryStreet',
    value: state['deliveryStreet'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{0,${config.maxDefaultInputLength}}$`)],
    isRequired: context.state.dhlParti.value || context.state.deliveryMandatoryByPriorityService,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Postalcode check
  getState = checkFieldAndReturnState({
    name: 'deliveryPostalCode',
    value: state['deliveryPostalCode'].value,
    regEx: context.state.internationalProductsSelected ? config.regEx.textNumber : config.regEx.number,
    lengthCheck: [RegExp(`^.{0,${config.maxPostalCode}}$`)],
    isRequired: !context.state.shippingToMultipleAddresses.value || context.state.dhlParti.value,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // City check
  getState = checkFieldAndReturnState({
    name: 'deliveryCity',
    value: state['deliveryCity'].value.trim(),
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{0,${config.maxDefaultInputLength}}$`)],
    isRequired:
      !context.state.shippingToMultipleAddresses.value ||
      context.state.dhlParti.value ||
      context.state.deliveryMandatoryByPriorityService,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value.trim(), error: getState.error };

  // Country check
  getState = checkFieldAndReturnState({
    name: 'deliveryCountry',
    value: state['deliveryCountry'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`$^|^.{${config.minCountryLength},${config.maxCountryLength}}$`)],
    isRequired: !context.state.shippingToMultipleAddresses.value || context.state.dhlParti.value,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Name check
  getState = checkFieldAndReturnState({
    name: 'deliveryName',
    value: state['deliveryName'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{0,${config.maxDefaultInputLength}}$`)],
    isRequired: context.state.dhlParti.value,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // E-mail check
  getState = checkFieldAndReturnState({
    name: 'deliveryEmail',
    value: state['deliveryEmail'].value,
    regEx: config.regEx.email,
    lengthCheck: [RegExp(`^.{0,${config.maxEmailLength}}$`)],
    isRequired: false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Phone Numbers check
  getState = checkGroupOfFieldsAndReturnState({
    groupName: 'deliveryPhoneNumbers',
    values: state['deliveryPhoneNumbers'],
    fields: [
      {
        name: 'code',
        isRequired: context.state.dhlParti.value,
      },
      {
        name: 'number',
        regEx: config.regEx.number,
        lengthCheck: [RegExp(`^.{1,${config.maxPhoneNumberDigits}}$`)],
        isRequired: context.state.dhlParti.value,
        calculatePhoneLengthCheck: true,
        startPhoneLengthFromZero: startPhoneLengthFromZero,
      },
    ],
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.groupName] = [...getState.values];

  // Sender Reference check
  getState = checkFieldAndReturnState({
    name: 'deliveryReceiverReference',
    value: state['deliveryReceiverReference'].value,
    regEx: config.regEx.everything,
    isRequired: false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  return {
    stateToSet,
    hasErrors,
  };
};

import React from 'react';
import { Translation } from 'react-i18next';
import { Checkbox } from 'components';
import { config, CONST } from '../../data-config';

export default function ReturnShipment({ context, disabled }) {
  return (
    <div className="frc__generic-section--wrapper">
      <Translation>
        {t => (
          <>
            <h4 className="margin-bottom-0">{t(`portalOrder|Return shipment`)}</h4>

            <div className="l-grid--w-100pc-s l-grid--w-30pc-m l-grid--w-50pc-m-s frc__generic-row--wrapper ">
              <Checkbox
                label={t(`portalOrder|Is return shipment requested`) + '?'}
                name={'returnShipmentRequested'}
                context={context}
                checked={context.state.returnShipmentRequested.value}
                isRequired={false}
                config={config}
                CONST={CONST}
                disabled={disabled}
                tooltip={t('general|Return Label can be printed by the Parcel Shop at drop off by the customer')}
              />
            </div>
          </>
        )}
      </Translation>
    </div>
  );
}

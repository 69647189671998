import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';

import { MyContext } from '../../context/context';
import { Input } from 'components';
import { Select } from 'components';
import { Error } from 'components';

import './ServiceDetails.css';

export default class ServiceDetails extends Component {
  render() {
    return (
      <>
        <div className="frc__generic-section--wrapper c-calculator--tabcontent">
          <Translation>
            {t => (
              <MyContext.Consumer>
                {context => (
                  <>
                    <h4 className="margin-bottom-1">{t('general|Service Details')}</h4>
                    <div className="frc__service-details-section--wrapper">
                      <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper l-grid--between-s">
                        <div className=" l-grid--w-100pc-s l-grid--w-48pc-m">
                          <Select
                            context={context}
                            config={config}
                            CONST={CONST}
                            selectClass="l-grid--w-100pc-s"
                            label={t(`general|Customer Account`)}
                            name="tmsAccountNumber"
                            isRequired={true}
                            values={context.state.tmsAccounts}
                            value={context.state.tmsAccountNumber.value}
                            skipTranslation={true}
                          />
                          {context.state.tmsAccountNumber.error ? (
                            <Error
                              name="tmsAccountNumber"
                              className="frc__input--error"
                              message={t(`general|Customer Account Error`)}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        <div className=" l-grid--w-100pc-s l-grid--w-48pc-m">
                          <div>
                            <Select
                              context={context}
                              config={config}
                              CONST={CONST}
                              selectClass="l-grid--w-100pc-s"
                              label={t('palletPickup|Pallet Account')}
                              name="palletAccountNumber"
                              isRequired={true}
                              values={context.state.palletAccounts}
                              value={context.state.palletAccountNumber.value}
                              skipTranslation={true}
                            />
                            {context.state.palletAccountNumber.error ? (
                              <Error
                                name="palletAccountNumber"
                                className="frc__input--error"
                                message={t('general|errors|Pallet Account Error')}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="l-grid--w-100pc-s frc__generic-row--wrapper frc__realtive">
                            <Input
                              context={context}
                              regEx={config.regEx.numberOfPallets}
                              label={t(`palletPickup|Number of Pallets`)}
                              name="numberOfPallets"
                              isRequired={true}
                              value={context.state.numberOfPallets.value}
                              min={config.minNumberOfPallets}
                              max={config.maxNumberOfPallets}
                              inputType={'text'}
                              tooltip={t(CONST.API_DOMAIN + '|Number of Pallets tooltip', {
                                max: config.maxNumberOfPallets,
                              })}
                              lengthCheck={[RegExp(`^.{1,${config.maxPalletDigits}}$`)]}
                              cutTextLimit={config.maxPalletDigits}
                            />
                            {context.state.numberOfPallets.error ? (
                              <Error
                                name="numberOfPallets"
                                className="frc__input--error"
                                message={t(`palletPickup|Number of Pallets Error`, {
                                  min: config.minNumberOfPallets,
                                  max: config.maxNumberOfPallets,
                                })}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </MyContext.Consumer>
            )}
          </Translation>
        </div>
      </>
    );
  }
}

import { useTranslation } from 'react-i18next';
import { ErrorPageRedirections } from './ErrorPageRedirections';

export function ErrorPageContent500() {
  const { t } = useTranslation();

  return (
    <>
      <div className="c-page-headline component-small has-rte component-margin">
        <p className="has-rte">
          {t(
            'errorPage|The server encountered an internal error and was unable to complete your request. Please try again later.',
          )}
        </p>
      </div>
      <ErrorPageRedirections />
    </>
  );
}

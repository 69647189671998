import React, { Component } from 'react';
import { toCamelCase } from 'globals/utils/formatting';
// import { MyContext } from "../../../context/context";
// import { matchRegEx } from 'globals/utils/fieldValidations";

import './Radio.css';

export default class Radio extends Component {
  constructor(props) {
    super(props);

    this.className = props.className || 'l-grid frc__radio--wrapper';
    this.labelClass = props.labelClass || 'frc__label';
  }

  handleChange = (value, context) => {
    const params = {
      name: this.props.name,
      value: this.props.isBooleanValueRequired ? value : !value,
      isRequired: this.props.isRequired,
      index: this.props.index,
      disabled: this.props.disabled,
      skipError: this.props.skipError,
      deselect: this.props.deselect,
      checkGroupIndex: this.props.checkGroupIndex,
      beforeUpdate: this.props.beforeUpdate,
      afterUpdate: this.props.afterUpdate,
      callbackFunction: this.props.callback,
    };

    if (this.props.checkBox && this.props.compareValue) {
      params.value = value === this.props.compareValue ? '' : value;
    }

    if (this.props.name === 'pickupOption') params.value = value;
    if (this.props.updateOnParent) {
      this.props.updateOnParent(params, context);
    } else {
      context.updateField(params);
    }
  };

  render() {
    const id = toCamelCase(this.props.id || this.props.name) + 'RdBtn';

    return (
      <>
        <div className={this.className + (this.props.disabled ? ' disabled' : '')}>
          <input
            id={id}
            className={'frc__radio-button'}
            type={this.props.checkBox ? 'checkbox' : 'radio'}
            ref={this.props.radioRef}
            name={this.props.name}
            onChange={e => this.handleChange(this.props.value, this.props.context)}
            disabled={this.props.disabled}
            checked={this.props.checked}
            data-testid={this.props['data-testid']}
          />
          <label
            htmlFor={id}
            className="frc__radio-label"
            dangerouslySetInnerHTML={{
              __html: this.props.label + (this.props.isRequired && !this.props.hideAsterisk ? '*' : ''),
            }}
          />
          {this.props.tooltip ? (
            <div className="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container">
              <div className="frc__tooltip-container-flyout">{this.props.tooltip}</div>
            </div>
          ) : (
            ''
          )}
        </div>
      </>
    );
  }
}

import React, { Component } from 'react';
import { MyProvider, MyContext } from '../../context/context';
import { Typography } from '@mui/material';

import './../../css/global/_variables.css';
// import './../../css/global/_icons.css';
// import './../../css/base/iconfont.css';

import './AppPalletTransactions.css';
import 'globals/utils/debugHelpers';
import { GeneralError } from 'components';
import cssVars from 'css-vars-ponyfill';
import TransactionsTable from '../TransactionsTable/TransactionsTable';
import { config, CONST } from '../../data-config';
import { Translation } from 'react-i18next';

const ENV_DEV = process.env.NODE_ENV === 'development';

export default class AppPalletTransactions extends Component {
  componentDidMount() {
    cssVars();
  }

  render() {
    return (
      <Translation>
        {t => (
          <div className={ENV_DEV && 'l-view'}>
            <div className="c-page-headline component-small has-rte component-margin frc__heading">
              <div className="c-page-headline--wrapper">
                <Typography variant="h1" mt={{ xs: 0, sm: 2 }}>
                  {t(`palletTransactions|PALLET ACCOUNT TRANSACTIONS`)}
                </Typography>
              </div>
            </div>
            <MyProvider>
              <div className="frc__app frc__overflowX--auto">
                <MyContext.Consumer>
                  {context => (
                    <>
                      {t => <GeneralError context={context} message={t('general|errors|General error')} />}
                      {context.state.isLoaded ? (
                        <TransactionsTable
                          context={context}
                          config={config}
                          CONST={CONST}
                          data={context.state.data}
                          accountNumber={context.state.accountNumber}
                          updateData={context.updateData}
                          maxNoOfRows={context.state.maxNoOfRows}
                          displayValues={context.displayValues}
                          setCSVError={context.setCSVError}
                          CSVError={context.state.CSVError}
                        />
                      ) : !context.state.generalError ? (
                        <div className="is-loading" />
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </MyContext.Consumer>
              </div>
            </MyProvider>
          </div>
        )}
      </Translation>
    );
  }
}

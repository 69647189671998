import { IconButton, Stack, Box } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ClearIcon from '@mui/icons-material/Clear';

export const ShipmentRowControls = ({ onDelete, onClone, showDeleteButton, showCloneButton }) => {
  return (
    <Stack bgcolor="white" alignItems="center" justifyContent="space-between">
      {!!showDeleteButton ? (
        <IconButton color="primary" onClick={() => onDelete()}>
          <ClearIcon fontSize="large" />
        </IconButton>
      ) : (
        <PlaceholderElement />
      )}
      {!!showCloneButton && (
        <IconButton color="primary" size="large" onClick={() => onClone()}>
          <ContentCopyIcon fontSize="30px" />
        </IconButton>
      )}
      <PlaceholderElement />
    </Stack>
  );
};

// used to force flexbox to center the clone button
const PlaceholderElement = () => <Box sx={{ height: '38px', width: '38px' }}></Box>;

import React, { Component } from 'react';
import { config } from '../../../data-config';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'globals/utils/debounce';
import { Translation } from 'react-i18next';
import { fillAddress } from '../AddressPickup/AddressPickup-helpers';
import { addressSearchBackendCall } from './CompanyNameSearch-helpers';

import './CompanyNameSearch.css';

export default class CompanyNameSearch extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      page: 1,
      pageSize: 5,
      total: 0,
      loading: false,
      addressBookList: [],
    };
    this.autoCompleteCloseRef = React.createRef();
  }

  fetchMoreData = () => {
    if (this.state.page !== this.state.lastPage) {
      this.setState(
        prevState => {
          return { page: prevState.page + 1, loading: true };
        },
        () => {
          this.onAddressSearch(true);
        },
      );
    }
  };

  onAddressSearch = debounce(async (fetchMore = false) => {
    if (this._isMounted) {
      const stateObj = {};
      const resultMap = await addressSearchBackendCall(
        this.props.context.state,
        this.state,
        this.props.searchTerm,
        this.props.party,
      );

      if (this._isMounted) {
        if (!resultMap.error && JSON.stringify(resultMap.data) !== '{}') {
          stateObj.page = resultMap.data.page;
          stateObj.pageSize = resultMap.data.pageSize;
          stateObj.total = resultMap.data.total;
          stateObj.lastPage = resultMap.data.lastPage;
          stateObj.loading = false;

          if (resultMap.data.addressBookList) {
            stateObj.addressBookList = fetchMore
              ? this.state.addressBookList.concat(resultMap.data.addressBookList)
              : resultMap.data.addressBookList;

            stateObj.items = fetchMore
              ? this.state.items.concat(this.getItems(resultMap.data.addressBookList))
              : this.getItems(resultMap.data.addressBookList);
          } else {
            stateObj.addressBookList = [];
            stateObj.items = [];
          }

          stateObj.noResultFound = stateObj.addressBookList.length === 0;

          this.setState(stateObj);
        } else {
          this.setState(this.resetState());
        }
      }
    }
  }, config.companySearchDebounceTimeout);

  onCompanyNameSelect = addressId => {
    const address = this.state.addressBookList.find(addressA => addressA.id === addressId);
    fillAddress(this.props.context, address, this.props.party, this.props.checkAppropriateCity);
  };

  getItems(addressBookList) {
    return addressBookList.map(listItem => (
      <li
        className="frc__address-search-padding frc__address-result"
        key={listItem.id}
        onClick={() => this.onCompanyNameSelect(listItem.id, this.props.context.state.showCompanySearchMainSender)}
      >
        {listItem.companyName}
        <span key={listItem.id} className="frc__address-color">
          {' '}
          <ol className="frc__address-street-building">{listItem.street} </ol>
        </span>
      </li>
    ));
  }

  resetState = () => {
    return {
      items: [],
      page: 1,
      pageSize: 5,
      total: 0,
      loading: true,
      addressBookList: [],
    };
  };

  updateStateAndCallAddressSearch = async () => {
    this.setState(this.resetState(), async () => {
      await this.onAddressSearch();
    });
  };

  closeCompanySearch = () => {
    if (this.autoCompleteCloseRef.current) {
      this.props.context.updateState({
        showCompanySearchMainSender: false,
        showCompanySearchMainReceiver: false,
        showCompanySearchDifferentSender: false,
        showCompanySearchDifferentReceiver: false,
      });
    }
  };

  componentDidMount = async () => {
    this._isMounted = true;

    this.setState(this.resetState(), async () => {
      await this.onAddressSearch();
    });

    document.addEventListener('click', this.closeCompanySearch);
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.closeCompanySearch);
    this._isMounted = false;
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      JSON.stringify(nextProps.searchTerm) !== JSON.stringify(this.props.searchTerm) ||
      JSON.stringify(nextState.addressBookList) !== JSON.stringify(this.state.addressBookList)
    );
  };

  componentDidUpdate = async prevProps => {
    if (this._isMounted && prevProps.searchTerm !== this.props.searchTerm) {
      await this.updateStateAndCallAddressSearch();
    }
  };

  render() {
    const hasMore = this.state.page !== this.state.lastPage || this.state.loading;

    return (
      <div ref={this.autoCompleteCloseRef}>
        {this.state.addressBookList.length > 0 && (
          <Translation>
            {t => (
              <div id="frc__parent-company-search-id" className=" frc__input--wrapper frc__display-scroll">
                <InfiniteScroll
                  dataLength={this.state.addressBookList.length}
                  next={this.fetchMoreData}
                  hasMore={hasMore}
                  scrollableTarget="frc__parent-company-search-id"
                >
                  <ul key="address-search">{this.state.items}</ul>
                </InfiniteScroll>
              </div>
            )}
          </Translation>
        )}
      </div>
    );
  }
}

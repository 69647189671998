export const analytics = (eventName, eventType) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: {
        name: eventName,
        type: eventType,
      },
    });
  }
};

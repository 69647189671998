import { useLocalStorage } from './useLocalStorage';
import { createContext, useContext, useMemo } from 'react';
import { AUTHENTICATED } from '../Login/constants';

let AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // let [authenticated, setAuthenticated] = useState(null);
  let [authenticated, setAuthenticated] = useLocalStorage(AUTHENTICATED, null);

  let authenticate = (authenticated, callback) => {
    setAuthenticated(authenticated);
    callback && callback();
  };

  let signout = callback => {
    setAuthenticated(false);
    callback();
  };

  // eslint-disable-next-line
  let value = useMemo(() => ({ authenticated, authenticate, signout }), [authenticated]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  return useContext(AuthContext);
}

import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ChatWithUs = () => {
  const { t } = useTranslation();
  return (
    <strong>
      <Link href={t('pageLinks|chat')} target="_blank" rel="noopener noreferrer">
        {t(`general|Chat with us`)}
      </Link>
    </strong>
  );
};

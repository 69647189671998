import React from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../data-config';

export default function ServicePointCard(props) {
  const { t } = useTranslation();

  const selectServicePoint = () => {
    props.selectServicePoint(props);
  };

  const markServicePoint = e => {
    props.markServicePoint(props);
  };

  return (
    <>
      <div className={'frc__card-list-card-wrapper'} onClick={markServicePoint}>
        <div className="l-grid l-grid--between-s">
          <div className=" l-grid--w-70pc-s frc__card-list-title">{props.servicePoint.name}</div>
          <div className="frc__card-list-subtitle">{`${
            props.servicePoint.routeDistance || props.servicePoint.distance.toFixed(config.distance.maxDecimals)
          } ${t('general|km')}`}</div>
        </div>
        {props.servicePoint.partyName ? (
          <div className="frc__card-list-street frc__uppercase">{props.servicePoint.partyName}</div>
        ) : (
          ''
        )}
        <div className="frc__card-list-street">{props.servicePoint.street}</div>

        <div className="l-grid l-grid--between-s">
          <div className="frc__card-list-city">
            {props.servicePoint.countryCode ? props.servicePoint.countryCode + '-' : ''}
            {props.servicePoint.postalCode} {props.servicePoint.cityName}
          </div>
          <div>
            <button
              className="base-button base-button--white base-button--wide frc__narrow-button"
              onClick={selectServicePoint}
            >
              <span>{t('general|labels|inputs|Select')}</span>
            </button>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}

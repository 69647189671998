import { PrintOutlined } from '@mui/icons-material';
import { Autocomplete, Box, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Section } from 'components';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { apiLinks } from '../../../config/api-config';
import { apiRequest } from '../../../globals';
import { userQueryKey } from '../../../hooks/api/useGetUser';
import { PRINTER_CONSTANTS } from '../edit-profile/data-config';
import { PROFILE_FIELD_WIDTH, ProfileFieldWrap, SectionTitle } from './RegistrationForm';

export const printOptions = [
  { value: PRINTER_CONSTANTS.LABEL_PRINTER_VALUE, label: 'editProfile|Label printer' },
  {
    value: PRINTER_CONSTANTS.LABEL_COMPACT_PRINTER_VALUE,
    label: 'editProfile|labelCompact',
    printTopMargin: 0,
    printLeftMargin: 0,
    printPadding: 0,
  },
  {
    value: 'Label2xPortraitA4',
    label: 'editProfile|A4 – Vertical, 2 labels/page',
    printTopMargin: 50,
    printLeftMargin: 6,
    printPadding: 5,
  },
  {
    value: 'Label3xLandscapeA4',
    label: 'editProfile|A4 – Horizontal, 3 labels/page',
    printTopMargin: 5,
    printLeftMargin: 2,
    printPadding: 2,
  },
];

export const PrintSettingsForm = ({ messageModal, userData }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      printDefaultPrinter:
        printOptions.find(item => item.value === userData?.user?.printDefaultPrinter) ??
        printOptions.find(item => item.value === PRINTER_CONSTANTS.LABEL_PRINTER_VALUE),
      printTopMargin: userData?.user?.printTopMargin ?? '',
      printLeftMargin: userData?.user?.printLeftMargin ?? '',
      printPadding: userData?.user?.printPadding ?? '',
      advancedSettings: false,
    },
  });

  const watchAdvancedSettings = watch('advancedSettings');
  const watchPrintDefaultPrinter = watch('printDefaultPrinter');

  const onSubmit = data => {
    const submitData = async () => {
      const handleError = () => {
        const message =
          t('editProfile|Update failed. Try again or call technical support') +
          ' ' +
          t('general|or') +
          ' ' +
          `<strong><a href="${t('pageLinks|chat')}" 
                                      class ="frc__chat-with-us"
                                      target="_blank" rel="noopener noreferrer">${t(
                                        `general|Chat with us`,
                                      )}</a></strong>`;
        messageModal.current.openModal(message, 'color-DHL-red');
      };

      try {
        const params = {
          body:
            data.printDefaultPrinter?.value === PRINTER_CONSTANTS.LABEL_PRINTER_VALUE
              ? {
                  defaulPrinter: data.printDefaultPrinter?.value,
                }
              : {
                  defaulPrinter: data.printDefaultPrinter?.value ?? '',
                  marginTop: data.printTopMargin,
                  marginLeft: data.printLeftMargin,
                  padding: data.printPadding,
                },
        };

        const result = await apiRequest(apiLinks.updateUserPrintSettings, 'PUT', params);

        if (result.status === 200) {
          const message = t(`editProfile|Print settings updated`);
          messageModal.current.openModal(message, 'color-secondary-green-1');
          queryClient.invalidateQueries(userQueryKey);
        } else {
          handleError();
        }
        if (!data.printDefaultPrinter?.value) {
          setValue(
            'printDefaultPrinter',
            printOptions.find(item => item.value === PRINTER_CONSTANTS.LABEL_PRINTER_VALUE),
          );
        }
      } catch (error) {
        console.error('Error during updating printer', error);
        handleError();
      }
    };
    submitData();
  };

  useEffect(() => {
    if (watchPrintDefaultPrinter?.value === userData?.user?.printDefaultPrinter && userData?.user) {
      setValue('printTopMargin', userData?.user?.printTopMargin ?? '');
      setValue('printLeftMargin', userData?.user?.printLeftMargin ?? '');
      setValue('printPadding', userData?.user?.printPadding ?? '');
    } else {
      const option = printOptions.find(item => item?.value === watchPrintDefaultPrinter?.value);
      if (option) {
        setValue('printTopMargin', option.printTopMargin ?? '');
        setValue('printLeftMargin', option.printLeftMargin ?? '');
        setValue('printPadding', option.printPadding ?? '');
      } else {
        setValue('printTopMargin', '');
        setValue('printLeftMargin', '');
        setValue('printPadding', '');
      }
    }

    if (watchPrintDefaultPrinter?.value === PRINTER_CONSTANTS.LABEL_PRINTER_VALUE || !watchPrintDefaultPrinter?.value) {
      setValue('advancedSettings', false);
    }
  }, [userData, watchPrintDefaultPrinter, setValue]);

  return (
    <div>
      <form
        onSubmit={handleSubmit(data => onSubmit(data))}
        noValidate // disable scroll to field onSubmit validation error
      >
        <Section sx={{ my: '32px' }}>
          <SectionTitle icon={PrintOutlined}>{t('editProfile|Print Settings')}</SectionTitle>

          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              maxWidth: PROFILE_FIELD_WIDTH,
            }}
          >
            <Controller
              name="printDefaultPrinter"
              control={control}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={printOptions}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                      setValue(
                        'printDefaultPrinter',
                        printOptions.find(item => item.value === newValue?.value) ?? null,
                      );
                    }}
                    clearText=""
                    disableClearable
                    getOptionLabel={option => t(`${option.label}`)}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    sx={{ width: '100%', maxWidth: PROFILE_FIELD_WIDTH, my: '16px' }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={t('editProfile|Default printer')}
                        error={!!errors.printDefaultPrinter}
                        placeholder={t('editProfile|selectDefaultPrinterPlaceholder')}
                        helperText={errors.printDefaultPrinter && t('general|labels|inputs|required')}
                      />
                    )}
                  />
                );
              }}
            />
          </Box>

          <Box>
            <Controller
              name="advancedSettings"
              control={control}
              render={({ field }) => {
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      '& > *': {
                        flex: '1 0 250px',
                      },
                    }}
                  >
                    <FormControlLabel
                      key="advancedSettings"
                      label={t(`editProfile|Advanced Settings`)}
                      control={
                        <Checkbox
                          sx={{ '& .MuiSvgIcon-root': { fontSize: '24px' } }}
                          value={field.value}
                          checked={field.value}
                          onChange={(event, checked) => {
                            field.onChange(checked);
                          }}
                          disabled={
                            [
                              PRINTER_CONSTANTS.LABEL_PRINTER_VALUE,
                              PRINTER_CONSTANTS.LABEL_COMPACT_PRINTER_VALUE,
                            ].includes(watchPrintDefaultPrinter?.value) || !watchPrintDefaultPrinter?.value
                          }
                        />
                      }
                    />
                  </Box>
                );
              }}
            />
          </Box>
          {watchAdvancedSettings ? (
            <>
              <ProfileFieldWrap>
                <Controller
                  name="printTopMargin"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('editProfile|Top Margin')}
                      sx={{
                        width: '100%',
                        maxWidth: PROFILE_FIELD_WIDTH,
                      }}
                    />
                  )}
                />
              </ProfileFieldWrap>
              <ProfileFieldWrap>
                <Controller
                  name="printLeftMargin"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('editProfile|Left Margin')}
                      sx={{
                        width: '100%',
                        maxWidth: PROFILE_FIELD_WIDTH,
                      }}
                    />
                  )}
                />
              </ProfileFieldWrap>
              <ProfileFieldWrap>
                <Controller
                  name="printPadding"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('editProfile|Padding')}
                      sx={{
                        width: '100%',
                        maxWidth: PROFILE_FIELD_WIDTH,
                      }}
                    />
                  )}
                />
              </ProfileFieldWrap>
            </>
          ) : null}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: '32px',
              mt: '32px',
            }}
          >
            <Button
              variant="contained"
              type="submit"
              color="primary"
              size="large"
              data-tracking="fcp-edit-print-settings-submit"
              data-testid="fcp-edit-print-settings-submit"
            >
              {t('editProfile|Save print settings')}
            </Button>
          </Box>
        </Section>
      </form>
    </div>
  );
};

import { apiRequest } from 'globals/utils/requests';
import { config, CONST } from '../data-config';
import { apiLinks } from 'config/api-config';

export const downloadCSV = async (url, filename) => {
  let error = false;

  const params = {
    headers: {
      accept: 'text/csv',
      contentType: 'text/csv',
    },
  };

  await apiRequest(url, 'GET', params).then(result => {
    if (result.status === 200) {
      let blob = new Blob([result.data], { type: 'text/csv; charset=utf-8;' });

      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        let link = document.createElement('a');

        if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);

          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style = 'visibility:hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } else {
      error = true;
    }
  });

  return error;
};

export const saveOrUpdateContact = async (state, isSaveNew) => {
  const resultMap = {};
  let url = apiLinks.saveContact;
  let method = 'POST';

  if (!isSaveNew) {
    url = apiLinks.updateContact.replace('{id}', state.id);
    method = 'PUT';
  }

  await apiRequest(url, method, {
    body: prepareContactData(state, isSaveNew),
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.error = false;
      } else if (result.status === CONST.TOO_MANY_REQUESTS_ERROR) {
        resultMap.maxLimitReachedError = true;
      } else if (result.status === CONST.CONFLICT_ERROR) {
        resultMap.conflictError = true;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

const prepareContactData = (state, isSaveNew) => {
  const contact = {
    addressId: state.addressId?.value || null,
    city: state.city.value,
    companyName: state.companyName.value,
    countryCode: state.countryCode.value,
    countryName: state.countryCode.value,
    deliveryInstructions: state.deliveryInstructions?.value || null,
    email: state.email?.value || null,
    name: state.name?.value || null,
    notes: state.notes?.value || null,
    postalCode: state.postalCode.value,
    reference: state.reference?.value || null,
    street: state.street.value,
    residentialAddress: state.residentialAddress.value,
  };

  if (state.phoneNumbers[0].number) {
    contact.phone = state.phoneNumbers[0].code + '-' + state.phoneNumbers[0].number;
  }

  if (!isSaveNew && state.id) {
    contact.id = state.id;
  }

  return contact;
};

export const resetContact = ({ countryData }) => {
  return {
    id: '',
    addressId: { value: '', error: false, isRequired: false },
    notes: { value: '', error: false, isRequired: false },
    phoneNumbers: [{ code: countryData.countryPhoneCode, number: '', error: false, isRequired: false }],
    email: { value: '', error: false, isRequired: false },
    name: { value: '', error: false, isRequired: false },
    companyName: { value: '', error: false, isRequired: true },
    countryCode: { value: countryData.urlCountry?.toUpperCase(), error: false, isRequired: true },
    street: { value: '', error: false, isRequired: true },
    postalCode: { value: '', error: false, isRequired: true },
    city: { value: '', error: false, isRequired: true },
    deliveryInstructions: { value: '', error: false, isRequired: false },
    reference: { value: '', error: false, isRequired: false },
  };
};

export const deleteContactList = async ids => {
  let error = false;

  await apiRequest(apiLinks.deleteContact, 'DELETE', {
    body: ids,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        error = false;
      } else {
        error = true;
      }
    })
    .catch(() => {
      error = true;
    });

  return error;
};

export const resetPagination = () => {
  return {
    page: 1,
    total: 0,
    pageSize: config.paginationInitialNumberOfItems,
    lastPage: 0,
  };
};

export const getCountriesForFilterBackendCall = async () => {
  const resultMap = {};

  await apiRequest(apiLinks.countriesForFilter, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const getCountriesForFilter = async t => {
  const countryResult = await getCountriesForFilterBackendCall();

  if (!countryResult.error) {
    return {
      filterCountryOptionsList: formatCountry(countryResult.data, t),
      countryAPIError: false,
    };
  } else {
    return {
      countryAPIError: true,
    };
  }
};

const formatCountry = (countryList, t) => {
  const formattedCountryList = [];

  countryList.forEach(countryKey => {
    formattedCountryList.push({
      value: countryKey,
    });
  });

  return formattedCountryList;
};

export const translateFilterCountryOptionsListLabels = (filterCountryOptionsList, t) => {
  const listWithLabels = [];
  filterCountryOptionsList.forEach(filterCountryOption => {
    listWithLabels.push({
      label: t(`countries|${filterCountryOption.value}`),
      value: filterCountryOption.value,
    });
  });
  return listWithLabels;
};

export const resetCountryPaginationCriteriasAndSearchAddressCall = async (context, selectedCountry = false, t) => {
  let stateToSet = { loader: true };

  //set country
  if (selectedCountry) {
    stateToSet.filteredCountries = selectedCountry;
  } else {
    stateToSet = { ...stateToSet, ...(await getCountriesForFilter(t)) };
  }
  // reset pagination and deleteList
  stateToSet.pagination = resetPagination();
  stateToSet.deleteList = [];

  context.extendedSetState(stateToSet, { afterUpdate: searchAddress });
};

export const onAddressSearch = (context, { pageSize = false, page = false } = false) => {
  const stateToSet = { loader: true };

  if (pageSize || page) {
    stateToSet.manualSearch = false;
    stateToSet.pagination = JSON.parse(JSON.stringify(context.state.pagination));

    if (pageSize) {
      stateToSet.pagination.pageSize = pageSize;
      stateToSet.pagination.page = 1;
    }
    if (page) stateToSet.pagination.page = page;
  } else {
    stateToSet.manualSearch = false;
    stateToSet.pagination = resetPagination();
  }

  stateToSet.deleteList = [];

  context.extendedSetState(stateToSet, { afterUpdate: searchAddress });
};

const formRequestBodyForSearchAddressCall = state => {
  let filteredCountryList = [];
  let shouldCountryParameterExist = false;

  filteredCountryList = state.filteredCountries.map(country => country.value);
  shouldCountryParameterExist =
    filteredCountryList.length > 0 && !(filteredCountryList.length === state.filterCountryOptionsList.length);

  return {
    countryCriteria: shouldCountryParameterExist ? 'INCLUDE' : null,
    criteriaCountries: shouldCountryParameterExist ? filteredCountryList : null,
    page: state.pagination.page,
    pageSize: state.pagination.pageSize,
    searchTerm: state.searchTerm.value,
  };
};

const searchAddressBackendCall = async context => {
  const resultMap = {};

  await apiRequest(apiLinks.addressSearch, 'POST', {
    body: formRequestBodyForSearchAddressCall(context.state),
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const searchAddress = async context => {
  let calculatePagination;
  let formatAddressList;

  const searchResult = await searchAddressBackendCall(context);

  if (!searchResult.error) {
    formatAddressList = searchResult.data.addressBookList;
    // set pagination
    calculatePagination = {
      page: searchResult.data.page,
      total: searchResult.data.total,
      lastPage: searchResult.data.lastPage,
    };

    calculatePagination.pageSize = context.state.pagination.pageSize;

    context.updateState({
      pagination: calculatePagination,
      addressBookList: formatAddressList,
      addressBookListMaster: formatAddressList,
      apiError: false,
      loader: false,
      manualSearch: false,
      remoteSearchTerm: context.state.searchTerm.value,
    });
  } else {
    context.updateState({
      apiError: true,
      loader: false,
      pagination: resetPagination(),
      addressBookList: [],
      addressBookListMaster: [],
      manualSearch: false,
      remoteSearchTerm: context.state.searchTerm.value,
    });
  }
};

import React, { Component } from 'react';
import { config } from '../../../../data-config';
import { Checkbox } from 'components';
import { euroconnectSelectedWithNoDeliveryDate } from '../../../ServiceDetails/ServiceDetails';
import AdditionalServicesOptions from '../AdditionalServicesOptions/AdditionalServicesOptions';
import './AdditionalServiceRow.css';
import { combineWithFunctionality } from 'globals/utils/compareAndUpdate';
import { Translation } from 'react-i18next';
export default class AdditionalServiceRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
    };
  }

  update = (params, context) => {
    params.groupName = 'additionalServices';
    context.updateGroup(params);
  };

  disableServiceIfLTCPresentedAndNoDates = () => {
    const groups = this.props.additionalServiceRow.groups;

    if (groups && groups.length > 0) {
      groups.forEach(group => {
        group.options.forEach(option => {
          const isLTCPresent = option.type === 'LTCdate';

          if (isLTCPresent) {
            const { selectedProduct } = this.props.context.state;

            if (
              (selectedProduct && selectedProduct.LTCDates && selectedProduct.LTCDates.length === 0) ||
              this.props.ltcFailed
            ) {
              this.setState({ disabled: true });
            } else {
              this.setState({ disabled: false });
            }
          }
        });
      });
    }
  };

  componentDidMount = () => {
    this.disableServiceIfLTCPresentedAndNoDates();
  };

  componentDidUpdate(prevProps) {
    const groups = this.props.additionalServiceRow.groups;

    if (groups && groups.length > 0) {
      groups.forEach(group => {
        group.options.forEach(option => {
          const isLTCPresent = option.type === 'LTCdate';

          if (
            isLTCPresent &&
            JSON.stringify(this.props.context.state.matchedProducts) !==
              JSON.stringify(prevProps.context.state.matchedProducts)
          ) {
            this.disableServiceIfLTCPresentedAndNoDates();
          }
        });
      });
    }
  }

  render() {
    const { state } = this.props.context;

    const disabled =
      euroconnectSelectedWithNoDeliveryDate(state.matchedProducts) &&
      config.vasExcludedForFailedLTC.indexOf(this.props.additionalServiceRow.code) !== -1;

    const VASAfterUpdate = async (contextFn, params) => {
      combineWithFunctionality(contextFn, params, config);
    };

    let show = true;

    return (
      show && (
        <>
          <Translation>
            {t => (
              <div className={this.props.className}>
                <div className="frc__additional-services-row--wrapper frc__column-wrapper l-grid--w-100pc-s">
                  <Checkbox
                    label={t(`additionalServices|${this.props.additionalServiceRow.code}|name`)}
                    name={this.props.additionalServiceRow.code}
                    deselect={this.props.additionalServiceRow.notCompatibleWith}
                    index={this.props.index}
                    isRequired={false}
                    disabled={this.props.additionalServiceRow.disabled || this.state.disabled || disabled || false}
                    checked={this.props.additionalServiceRow.value}
                    config={this.props.config}
                    CONST={this.props.CONST}
                    context={this.props.context}
                    tooltip={t(`additionalServices|${this.props.additionalServiceRow.code}|information`)}
                    updateOnParent={(params, context) => this.update(params, context)}
                    afterUpdate={(contextFn, params) => VASAfterUpdate(contextFn, params)}
                  />
                </div>
                {this.props.additionalServiceRow.value &&
                  this.props.additionalServiceRow.optionNotToHide &&
                  this.props.additionalServiceRow.groups &&
                  this.props.additionalServiceRow.groups.length > 0 && (
                    <AdditionalServicesOptions
                      deliveryMontoringClass={this.props.deliveryMontoringClass}
                      serviceIndex={this.props.index}
                      groups={this.props.additionalServiceRow.groups}
                      context={this.props.context}
                      errors={this.props.additionalServiceRow.errors}
                      config={this.props.config}
                      CONST={this.props.CONST}
                      code={this.props.additionalServiceRow.code}
                      loaderRef={this.props.loaderRef}
                    />
                  )}
              </div>
            )}
          </Translation>
        </>
      )
    );
  }
}

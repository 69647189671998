import React from 'react';
import { CONST, config } from '../../../data-config';
import { setCookie } from 'globals/utils/requests';
import ActionCustomCell from './ActionCustomCell';

import './CustomCell.css';
import { ROUTES } from 'router';

export default function SummaryCustomCell(props) {
  const { cellValue, t } = props;

  const navigateToOrder = () => {
    if (cellValue.id) {
      setCookie(config.portalOrderCookie, props.cellValue.id, CONST.SECONDS_PER_MINUTE, 's');
      window.location = `${props.baseUrl}/${ROUTES.portalOrder}`;
    }
  };

  return (
    <div className="l-grid">
      <div className="l-grid--w-100pc-s l-grid--w-60pc-m">
        <div className={'frc__summary--row l-grid--left-s'}>
          <div>{t('products|common|productLabel')}: </div>
          <div>{cellValue.productID ? t(`products|${cellValue.productID}|name`) : '---'}</div>
        </div>

        <div className={'frc__summary--row l-grid--left-s'}>
          <div>{t('general|labels|metrics|quantity')}: </div>
          <div>{cellValue.totalQuantity}</div>
        </div>

        <div className={'frc__summary--row l-grid--left-s'}>
          <div>{t('general|labels|metrics|Weight')}: </div>
          <div>{cellValue.totalWeight ? cellValue.totalWeight + ` ${t('general|kg')}` : '---'}</div>
        </div>
      </div>
      <div className="l-grid l-grid--w-100pc-s l-grid--w-40pc-m has-top-margin-1">
        <div className="frc__edit-action-btn-left">
          <button id="bookNowBtn" className="base-button" onClick={() => navigateToOrder()}>
            {t('general|labels|buttons|Book now')}
          </button>
        </div>
      </div>

      <ActionCustomCell
        onShowSuccessToaster={props.onShowSuccessToaster}
        cellValue={cellValue}
        context={props.context}
        action={props.action}
      />
    </div>
  );
}

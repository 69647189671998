import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';

import ShipmentDetailsRow from './ShipmentDetailsRow/ShipmentDetailsRow';
import ShipmentRowsSummary from './ShipmentRowsSummary/ShipmentRowsSummary';
import {
  calculateInputs,
  getMultipleProductSelectedOrMultipleReceiverFlag,
} from './ShipmentDetailsRow/ShipmentDetailsRow-helpers';
import { resetShipmentType } from '../EnterShipmentDetails/EnterShipmentDetails-helpers';
import { Error } from 'components';
import './EnterShipmentDetails.css';
import { Stack } from '@mui/material';

export default class EnterShipmentDetails extends Component {
  addShipmentDetailsRow = () => {
    const params = {};
    const maxAllowedShipmentDetailsRows = this.props.context.state.shipmentRowsNumberMax
      ? this.props.context.state.shipmentRowsNumberMax
      : config.maxAllowedShipmentDetailsRows;
    params.groupName = 'shipmentDetailsRows';
    const isMorethanTwoProductsSelectedOrMultipleReceiver =
      this.props.context.state.selectedProducts.length > 1 ||
      this.props.context.state.shippingToMultipleAddresses.value;

    if (
      this.props.context.state.shipmentDetailsRows &&
      this.props.context.state.shipmentDetailsRows.length < maxAllowedShipmentDetailsRows
    ) {
      params.item = resetShipmentType(
        this.props.context.state.selectedProducts,
        isMorethanTwoProductsSelectedOrMultipleReceiver,
      );
      params.afterUpdate = calculateInputs;
      this.props.context.addAnotherGroupField(params);
    }
  };

  componentDidMount = () => {
    const stateObject = {};

    if (this.props.context.state.selectedProducts && this.props.context.state.selectedProducts.length === 0) {
      this.addShipmentDetailsRow();
    }

    if (!this.props.context.state.shipmentRowsNumberMax) {
      stateObject.shipmentRowsNumberMax = config.maxAllowedShipmentDetailsRows;
    }

    // Reset dimensions to shipment detials inputs
    if (this.props.context.state.minMaxDimensions) {
      stateObject.dimensions = JSON.parse(JSON.stringify(this.props.context.state.minMaxDimensions));
    } else {
      stateObject.dimensions = JSON.parse(JSON.stringify(config.dimensions));
    }
    this.props.context.updateState(stateObject);
  };

  removeShipmentDetailsRow = (index, context) => {
    const params = {};

    if (context.state.shipmentDetailsRows && context.state.shipmentDetailsRows.length > 1) {
      params.groupName = 'shipmentDetailsRows';
      params.index = index;
      params.afterUpdate = calculateInputs;
      context.removeGroupField(params);
    }
  };

  update = (params, context) => {
    params.groupName = 'shipmentDetailsRows';
    context.updateGroupOfFields(params);
  };

  render() {
    const isMorethanTwoProductsSelectedOrMultipleReceiver = getMultipleProductSelectedOrMultipleReceiverFlag(
      this.props.context,
    );

    return (
      <Translation>
        {t => (
          <>
            <div className="frc__enter-shipment-details--wrapper frc__generic-section--wrapper">
              {isMorethanTwoProductsSelectedOrMultipleReceiver ? (
                <h4 className="margin-bottom-1">
                  {t(`general|Enter Shipment Details in total for the Pickup`)}
                  <span className="has-tooltip-label">
                    <div className="frc__tooltip-title-container has-icon icon-information color-DHL-red c-fcp-quote--info-container frc__tooltip_title">
                      <div className="frc__tooltip-container-flyout">
                        {t(
                          'general|You can enter either the total shipment detail for the pickup in one line or use more lines to specify  individual shipment details',
                        )}
                      </div>
                    </div>
                  </span>
                </h4>
              ) : (
                <h4 className="margin-bottom-1">
                  {t(`general|Enter Shipment Details`)}
                  <span className="has-tooltip-label">
                    <div className="frc__tooltip-title-container has-icon icon-information color-DHL-red c-fcp-quote--info-container frc__tooltip_title">
                      <div className="frc__tooltip-container-flyout">
                        {t(`general|Enter Shipment Details Tooltip`)} <br /> <br />
                        {t(`general|Enter Shipment Details Tooltip second line`)}
                      </div>
                    </div>
                  </span>
                </h4>
              )}
              <div
                className={`frc__enter-shipment-details-rows--wrapper ${
                  this.props.context.state.shipmentDetailsRows.length >= 5
                    ? 'frc__enter-shipment-details-rows--wrapper-fixed-height'
                    : ''
                }`}
              >
                <Stack spacing={1} id="shipmentDetailsRowsContainer">
                  {this.props.context.state.shipmentDetailsRows.map((shipmentRow, ind) => (
                    <ShipmentDetailsRow
                      key={ind}
                      index={ind}
                      shipmentRow={shipmentRow}
                      removeOnParent={index => this.removeShipmentDetailsRow(index, this.props.context)}
                      error={shipmentRow.error}
                      isMorethanTwoProductsSelectedOrMultipleReceiver={isMorethanTwoProductsSelectedOrMultipleReceiver}
                      context={this.props.context}
                      config={config}
                      CONST={CONST}
                    />
                  ))}
                </Stack>
                <div
                  className={
                    'frc__generic--loader-overlay' +
                    (this.props.context.state.loaders.shipmentDetails.value ? ' is-loading ' : '')
                  }
                />
              </div>

              <div
                className={
                  'frc__generic--field-wrapper ' +
                  (this.props.context.state.loaders.shipmentDetails.value ? ' l-invisible ' : '')
                }
              >
                {this.props.context.state.shipmentDetailsRows &&
                this.props.context.state.shipmentDetailsRows.length < this.props.context.state.shipmentRowsNumberMax ? (
                  <button
                    id="addShipmentDetailsRowBtn"
                    onClick={() =>
                      this.props.context.state.shipmentDetailsRows &&
                      this.props.context.state.shipmentDetailsRows.length <
                        this.props.context.state.shipmentRowsNumberMax
                        ? this.addShipmentDetailsRow()
                        : ''
                    }
                    className={
                      'base-button base-button--white base-button--wide frc__generic-button frc__add-button ' +
                      (this.props.context.state.shipmentDetailsRows &&
                      this.props.context.state.shipmentDetailsRows.length <
                        this.props.context.state.shipmentRowsNumberMax
                        ? ''
                        : ' disabled ')
                    }
                  >
                    <span className="has-icon icon-plus frc__button-icon--align">
                      {isMorethanTwoProductsSelectedOrMultipleReceiver
                        ? t(`general|Add another shipment pickup detail`)
                        : t(`general|Add another item of different size`)}
                    </span>
                  </button>
                ) : (
                  this.props.context.state.shipmentRowsNumberMax !== 1 && (
                    <Error message={t('general|You have reached the limit of rows')} />
                  )
                )}
              </div>
            </div>
            <ShipmentRowsSummary context={this.props.context} />
          </>
        )}
      </Translation>
    );
  }
}

import { returnProperValueOrNull } from 'globals/utils/fieldValidations';
import { config } from '../../../data-config';

export const formPieces = shipmentState => {
  const shipmentDetails = shipmentState.shipmentDetailsRows;
  let shipmentList = [];

  shipmentDetails.forEach(shipment => {
    shipmentList.push(shipmentData(shipment));
  });

  // Shipment rows with dangerousGoods are sorted to be before others
  return shipmentList.sort((a, b) => (a.dangerousGoods ? -1 : 1));
};

const shipmentData = shipmentRow => {
  const shipmentDataObj = {
    id: [''],
    height: shipmentRow.height?.value || null,
    length: shipmentRow.length?.value || null,
    loadingMeters: returnProperValueOrNull(shipmentRow.loadingMeter.value),
    loadingMetres: returnProperValueOrNull(shipmentRow.loadingMeter.value),
    numberOfPieces: shipmentRow.quantity?.value || null,
    packageType: config.packageType[shipmentRow.shipmentType.value],
    stackable: !shipmentRow.nonStackable.value,
    volume: returnProperValueOrNull(shipmentRow.volume.value),
    weight: returnProperValueOrNull(shipmentRow.weight.value),
    width: shipmentRow.width?.value || null,
    goodsType: shipmentRow.goodsDescription?.value || null,
    shippingMark: shipmentRow.shippingMark?.value || null,
    marksAndNumbers: shipmentRow.shippingMark?.value || null,
  };

  if (shipmentRow.dangerousGoods.value) {
    shipmentDataObj.dangerousGoods = shipmentRow.dangerousGoodGroup.map(item => {
      return {
        adrClass: item.adrClass.value,
        unNumber: item.unNumber.value,
        // caution: GUI field name differs from Farm API
        flashpointValue: item.flamePoint.value,
        // caution: GUI field name differs from Farm API
        packageGroup: item.packagingGroup.value,
        // caution: GUI field name differs from Farm API
        tunnelCode: item.tunnelDescriptionCode.value,
        properShippingName: item.properShippingName.value,
        // caution: GUI field name differs from Farm API
        officialNameTechDescription: item.technicalDescription.value,
        limitedQuantity: item.limitedQuantity.value,
        grossWeight: Number(item.grossWeight.value),
        // caution: GUI field name differs from Farm API
        quantityMeasurementValue: item.netQuantity.value ? Number(item.netQuantity.value) : null,
        // caution: GUI field name differs from Farm API
        numberOfPieces: Number(item.numberOfUnits.value),
        // caution: GUI field name differs from Farm API
        packageType: item.packageCode.value,
        quantityMeasurementUnitQualifier: item.quantityMeasurementUnitQualifier.value,
        environmentHazardous: item.environmentHazardous.value,
        marinePollutant: item.marinePollutant.value,
        marinePollutantName: item.marinePollutantName.value,
        exceptedQuantity: item.exceptedQuantity.value,
        emptyContainer: item.emptyContainer.value,
        waste: item.waste.value,
        dgmId: item.dgmId.value ? Number(item.dgmId.value) : null,
      };
    });
  }

  return shipmentDataObj;
};

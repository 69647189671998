export const cutDecimalAndShowError = (params, blur) => {
  const result = { ...params };
  let digit = params.value.toString();

  let dotIndex = digit.indexOf('.');
  let commaIndex = digit.indexOf(',');
  let indexToSplit = -1;

  if (dotIndex !== -1) {
    indexToSplit = dotIndex;
  } else if (commaIndex !== -1) {
    indexToSplit = commaIndex;
  }
  if (indexToSplit >= 0) {
    let valueAfterDecimal = digit.substring(indexToSplit + 1, digit.length);
    if (valueAfterDecimal.length > params.decimalCutoff) {
      let value = digit.substr(0, indexToSplit) + digit.substr(indexToSplit, params.decimalCutoff + 1);
      result.value = commaIndex !== -1 ? value.replace('.', ',') : value;
      const newValue = parseFloat(result.value);
      const isInRange = () => {
        if (newValue >= params.min && newValue <= params.max) {
          return true;
        }
        return false;
      };
      const inRange = isInRange(newValue);
      if (!inRange) {
        result.error = true;
      }
    }
  }
  if (blur && (digit.endsWith('.') || digit.endsWith(','))) {
    result.value = digit.substring(0, digit.length - 1);
    result.error = false;
  }
  return result;
};

export const cutIntegerAndShowError = params => {
  const result = { ...params };
  let valueString = params.value.toString();
  let dotIndex = valueString.indexOf('.');
  let commaIndex = valueString.indexOf(',');
  let indexToSplit = dotIndex !== -1 ? dotIndex : commaIndex;

  let valueBeforeDecimal = indexToSplit !== -1 ? valueString.substring(0, indexToSplit) : valueString;

  if (valueBeforeDecimal.length > params.integerCutoff) {
    let value = valueString.substr(0, params.integerCutoff);

    if (indexToSplit !== -1) {
      value += valueString.substr(indexToSplit, params.decimalCutoff + 1);
    }

    result.value = commaIndex !== -1 ? value.replace('.', ',') : value;
    result.error = true;
  }

  return result;
};

export const cutTextAndShowError = params => {
  const result = { ...params };

  if (params.value && params.value.length > params.cutTextLimit) {
    result.value = result.value.substring(0, params.cutTextLimit);
    result.error = true;
  }
  return result;
};

export const prepareFieldInStateForUpdate = (state, name, value, error, comparisonObj) => {
  const _stateObject = {};
  _stateObject[name] = {};

  if (comparisonObj && comparisonObj.field) {
    _stateObject[comparisonObj.field] = {};
    _stateObject[comparisonObj.field] = JSON.parse(JSON.stringify(state[comparisonObj.field]));
  }

  if (state[name]) {
    _stateObject[name] = JSON.parse(JSON.stringify(state[name]));
  }
  _stateObject[name].value = value;

  if (typeof error !== 'undefined') {
    _stateObject[name].error = error;
  }

  if (typeof comparisonError !== 'undefined' && comparisonObj && comparisonObj.error && !error) {
    _stateObject[name].comparisonError = true;
  } else if (typeof comparisonError !== 'undefined' && comparisonObj && !comparisonObj.error && !error) {
    _stateObject[name].comparisonError = false;
    _stateObject[comparisonObj.field].comparisonError = false;
  } else {
    _stateObject[name].comparisonError = false;
  }

  return _stateObject;
};

export const prepareDeselectGroupForUpdate = (group, deselect, selectedStatus, name) => {
  group.forEach(item => {
    if (deselect.indexOf(item.code) !== -1) {
      item.value = false;
      item.disabled = selectedStatus;
      item.selectedStatus = false;
    } else if (item.code === name) {
      item.value = selectedStatus;
      item.disabled = false;
      item.selectedStatus = selectedStatus;
    }
  });

  if (!selectedStatus) {
    let deselectList = [];
    group.forEach(item => {
      if (item.value) {
        deselectList = deselectList.concat(item.notCompatibleWith);
      }
    });
    deselectList.forEach(deSelectitem => {
      group.forEach(item => {
        if (item.code === deSelectitem) {
          item.value = false;
          item.selectedStatus = false;
          item.disabled = true;
        }
      });
    });
  }
  return group;
};

export const prepareGroupForUpdate = (group, selectedStatus, name) => {
  group.forEach(item => {
    if (selectedStatus === undefined) {
      if (item.name === name) {
        item.value = true;
        item.selectedStatus = true;
      } else {
        item.value = false;
        item.selectedStatus = false;
      }
    } else {
      if (item.code === name) item.value = selectedStatus;
    }
  });
  return group;
};

import { config } from '../../../../data-config';

export const combineADMainAndSecondaryList = (mainASList, secondaryASList) => {
  const mainList = [];
  const mainOnlyCodes = [];
  const specificVASForSecondaryList = [];
  const secondaryOnlyCodes = [];

  // Gets secondary list items only and their codes
  if (secondaryASList && secondaryASList.length > 0) {
    secondaryASList.forEach(itemSecondary => {
      const search = mainASList.find(itemMain => itemMain['code'] === itemSecondary['code']);
      if (!search) {
        specificVASForSecondaryList.push(JSON.parse(JSON.stringify(itemSecondary)));
        secondaryOnlyCodes.push(itemSecondary['code']);
      } else {
        // if vas present in both (401 and 501) or (402 and 502), secondary code will be given more priority.
        search.productCode = itemSecondary.productCode;
      }
    });
  }

  // Gets main list items, main list only codes and adds secondary list codes only to non compatibility to main list only items
  if (mainASList && mainASList.length > 0) {
    mainASList.forEach(itemMain => {
      const mainListItem = JSON.parse(JSON.stringify(itemMain));
      const search = secondaryASList.find(itemSecondary => itemSecondary['code'] === mainListItem['code']);

      if (!search) {
        if (!mainListItem.notCompatibleWith) mainListItem.notCompatibleWith = [];
        mainListItem.notCompatibleWith = mainListItem.notCompatibleWith.concat(secondaryOnlyCodes);
        mainOnlyCodes.push(mainListItem['code']);
      }

      mainList.push(mainListItem);
    });
  }

  // Puts main list only codes to non compatibility to secondary list only items
  specificVASForSecondaryList.forEach((itemSecondary, i) => {
    if (!itemSecondary.notCompatibleWith) itemSecondary.notCompatibleWith = [];
    specificVASForSecondaryList[i].notCompatibleWith = itemSecondary.notCompatibleWith.concat(mainOnlyCodes);
  });

  // Adds secondary only items to the main list
  return mainList.concat(specificVASForSecondaryList);
};

export const checkAndTranslateOptionSchemas = additionalServices => {
  const newVAS = additionalServices.map(additionalService => {
    let optionNotToHide = false;

    // Initialization of errors property for the conditional groups
    additionalService.errors = additionalService.errors || {};

    if (additionalService.groups) {
      additionalService.groups = additionalService.groups.map(group => {
        group.options = group.options.map(optionList => {
          optionList = optionList.map(option => {
            if (option.min === '0' && option.max === '0') {
              option.min = null;
              option.max = null;
            }

            if (option.type !== config.OptionFieldTypes.Hidden) {
              optionNotToHide = true;
            } else {
              optionNotToHide = false;
            }

            if (option.type === config.OptionFieldTypes.Telephone) {
              if (option.value) {
                const code = option.value.split('-')[0];
                const number = option.value.split('-')[1];
                option.value = [{ code: code, number: number, error: false }];
              } else {
                option.value = [{ code: config.defaultCountryPhoneCode, number: '', error: false }];
              }
            } else if (option.type === config.OptionFieldTypes.DateTime) {
              option.value = new Date();
            } else if (option.type === config.OptionFieldTypes.LtcDate) {
              option.values = [];
            }

            if (option.conditionalGroup && option.conditionalGroup.length > 0) {
              let groupType = 1;
              let groupDelimiter = ',';

              config.conditionalGroupOptionsTypes.forEach(t => {
                if (option.conditionalGroup.indexOf(t.delimiter) > -1) {
                  groupType = t.type;
                  groupDelimiter = t.delimiter;
                }
              });

              option.conditionalGroupType = groupType;
              option.conditionalGroupDelimiter = groupDelimiter;
              option.conditionalGroup = option.conditionalGroup.split(groupDelimiter);
            }
            return option;
          });

          return optionList;
        });

        return group;
      });
    }
    additionalService.optionNotToHide = optionNotToHide;

    return additionalService;
  });

  return newVAS;
};

export const applyOptionSchemaResultToVAS = (ASlist, optionsResults) => {
  ASlist = ASlist.map(AS => {
    const optionsToApply = optionsResults.find(osVAS => {
      return AS.code === osVAS.code;
    });

    if (optionsToApply && optionsToApply.groups) {
      AS.groups = transformOptionsToArrayOfOptions(optionsToApply.groups);
    }

    return AS;
  });

  return ASlist;
};

export const transformOptionsToArrayOfOptions = groups => {
  const newGroups = groups.map(group => {
    if (group.options) {
      group.options = [group.options];
    }

    return group;
  });

  return newGroups;
};

export const removeAdditionalServiceCustoms = additionalServices => {
  additionalServices = additionalServices.filter(AD => {
    if (AD.code.indexOf('customs') === -1) {
      return true;
    } else return false;
  });

  return additionalServices;
};

export const filterGServices = (product, additionalServices) => {
  let priorityService = [];
  const newAd = [];

  if (product && product.hasOwnProperty('priorityServices')) {
    priorityService = product.priorityServices.replace(new RegExp('G', 'g'), 'priorityServiceG').split(' ');
  }

  additionalServices.forEach(AD => {
    if (AD.code.indexOf('priorityServiceG') !== -1) {
      if (priorityService.indexOf(AD.code) !== -1) newAd.push(AD);
    } else {
      newAd.push(AD);
    }
  });
  return newAd;
};
/**
 * filterServicesForGivenProduct
 * initial filtering from the static config
 */
export const filterServicesForGivenProduct = (productCode, allAS, disabledVas) => {
  let filteredAS;
  const ASToFilterOut = disabledVas[productCode];

  if (!ASToFilterOut) {
    return allAS;
  }

  filteredAS = allAS.filter(additionalServices => {
    let AStoStay = true;

    ASToFilterOut.forEach(ASnameToRemove => {
      if (ASnameToRemove === additionalServices.code) {
        AStoStay = false;
      }
    });
    return AStoStay;
  });

  return filteredAS;
};

/**
 * applyValidationRulesToASlist
 * apply data from the API call for secondary filtering
 */
export const applyValidationRulesToASlist = (ASlist, validationResults) => {
  // apply notCompatible with list
  ASlist = ASlist.map(AS => {
    let validationResultsToApply = validationResults.find(ASwithValidationResults => {
      return ASwithValidationResults.additionalService === AS.code;
    });

    AS.notCompatibleWith = [];

    if (validationResultsToApply && validationResultsToApply.validationErrors.length > 0) {
      validationResultsToApply.validationErrors.forEach(validation => {
        if (validation.message && validation.message.indexOf('Not compatible') !== -1 && validation.fields) {
          AS.notCompatibleWith = AS.notCompatibleWith.concat(validation.fields);
        }
      });
    }

    return AS;
  });

  return ASlist;
};

import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config } from '../../../data-config';

import ShipmentDetailsRow from './ShipmentDetailsRow/ShipmentDetailsRow';
import { ShipmentRowsSummary } from '../../../../components/ShipmentRowsSummary/ShipmentRowsSummary';
import { calculateInputs } from './ShipmentDetailsRow/ShipmentDetailsRow-helpers';
import { resetShipmentType, getUpdatedDimensions } from '../EnterShipmentDetails/EnterShipmentDetails-helpers';
import { toggleActiveTab } from '../Template-helpers';
import { Error } from 'components';
import './EnterShipmentDetails.css';
import { withQueryClient } from '../../../../utils/withQueryClient';
import { userQueryKey } from '../../../../hooks/api/useGetUser';
import { fetchUserAccountsAndProductSpecifications } from '../../../../globals/helpers/data-from-api';
import { Stack } from '@mui/material';
import { checkAndCalculateLdmVolumeAllRows } from '../../../../globals/helpers/shipment-details-row';

class EnterShipmentDetails extends Component {
  constructor(props) {
    super(props);

    this.currentTab = React.createRef();
    this.state = {
      isLoading: true,
    };
  }

  addShipmentDetailsRow = (isSweden, favouriteColliTypes) => {
    const params = {};
    const maxAllowedShipmentDetailsRows = this.props.context.state.shipmentRowsNumberMax
      ? this.props.context.state.shipmentRowsNumberMax
      : config.maxAllowedShipmentDetailsRows;
    params.groupName = 'shipmentDetailsRows';

    if (
      this.props.context.state.shipmentDetailsRows &&
      this.props.context.state.shipmentDetailsRows.length < maxAllowedShipmentDetailsRows
    ) {
      params.item = resetShipmentType(this.props.context.state.selectedProducts, isSweden, favouriteColliTypes);
      params.afterUpdate = calculateInputs;
      this.props.context.addAnotherGroupField(params);
    }
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    await fetchUserAccountsAndProductSpecifications(this.props.context);
    const favouriteColliTypes = this.props.queryClient.getQueryData([userQueryKey])?.colliTypes;
    const isSweden = localStorage.getItem('country').toLowerCase() === 'se';

    let partialContextUpdate = { ...getUpdatedDimensions(this.props.context) };
    if (!this.props.context.state.shipmentRowsNumberMax) {
      partialContextUpdate.shipmentRowsNumberMax = config.maxAllowedShipmentDetailsRows;
    }
    partialContextUpdate = {
      ...partialContextUpdate,
      shipmentDetailsRows: checkAndCalculateLdmVolumeAllRows({
        dimensions: partialContextUpdate?.dimensions,
        shipmentDetailsRows: this.props.context.state.shipmentDetailsRows,
      }),
    };

    this.props.context.updateState(partialContextUpdate, () => {
      if (this.props.context.state.shipmentDetailsRows.length === 0) {
        this.addShipmentDetailsRow(isSweden, favouriteColliTypes);
      }
      // We must wait for state update to finish, there should be no logic after this line!
      this.setState({ isLoading: false });
    });
  };

  removeShipmentDetailsRow = (index, context) => {
    const params = {};

    if (context.state.shipmentDetailsRows && context.state.shipmentDetailsRows.length > 1) {
      params.groupName = 'shipmentDetailsRows';
      params.index = index;
      params.afterUpdate = calculateInputs;
      context.removeGroupField(params);
    }
  };

  update = (params, context) => {
    params.groupName = 'shipmentDetailsRows';
    context.updateGroupOfFields(params);
  };

  shouldComponentUpdate(nextProps) {
    return (
      JSON.stringify(nextProps.context.state.shipmentDetailsRows) !==
        JSON.stringify(this.props.context.state.shipmentDetailsRows) ||
      JSON.stringify(nextProps.context.state.totals) !== JSON.stringify(this.props.context.state.totals) ||
      JSON.stringify(nextProps.context.state.loaders.shipmentDetails) !==
        JSON.stringify(this.props.context.state.loaders.shipmentDetails) ||
      JSON.stringify(nextProps.context.state.shipmentRowsNumberMax) !==
        JSON.stringify(this.props.context.state.shipmentRowsNumberMax) ||
      JSON.stringify(nextProps.context.state.shipmentRange.value) !==
        JSON.stringify(this.props.context.state.shipmentRange)
    );
  }

  render() {
    if (this.state.isLoading) {
      return <div id="fcp-shipment-details-loader" className="frc__generic--loader-overlay is-loading" />;
    }

    return (
      <div
        className="l-grid--w-100pc-s"
        onMouseLeave={() => toggleActiveTab(this.currentTab, false)}
        onMouseEnter={() => toggleActiveTab(this.currentTab, true)}
      >
        <Translation>
          {t => (
            <>
              <div className="frc__app-tab " ref={this.currentTab}>
                <h3 className="frc__app-tab__title frc__app-margin-bottom-0">
                  {t('general|Shipment Details')}
                  <span className="has-tooltip-label text-left">
                    <div className="frc__tooltip-title-container has-icon icon-information color-DHL-red c-fcp-quote--info-container frc__tooltip_title">
                      <div className="frc__tooltip-container-flyout">
                        {t(`general|Enter Shipment Details Tooltip`)} <br /> <br />
                        {t(`general|Enter Shipment Details Tooltip second line`)}
                      </div>
                    </div>
                  </span>
                </h3>
              </div>

              <div className="margin-bottom-1 l-grid--w-100pc-s frc__enter-shipment-details--wrapper frc__generic-section--wrapper">
                <div
                  className={`frc__enter-shipment-details-rows--wrapper ${
                    this.props.context.state.shipmentDetailsRows.length >= 5
                      ? 'frc__enter-shipment-details-rows--wrapper-fixed-height'
                      : ''
                  }`}
                >
                  <Stack spacing={1} id="shipmentDetailsRowsContainer">
                    {this.props.context.state.shipmentDetailsRows.map((shipmentRow, ind) => (
                      <ShipmentDetailsRow
                        key={ind}
                        index={ind}
                        shipmentRow={shipmentRow}
                        removeOnParent={index => this.removeShipmentDetailsRow(index, this.props.context)}
                        error={shipmentRow.error}
                        context={this.props.context}
                      />
                    ))}
                  </Stack>
                  <div
                    className={
                      'frc__generic--loader-overlay' +
                      (this.props.context.state.loaders.shipmentDetails.value ? ' is-loading ' : '')
                    }
                  />
                </div>

                <div
                  className={
                    'frc__generic--field-wrapper ' +
                    (this.props.context.state.loaders.shipmentDetails.value ? ' l-invisible ' : '')
                  }
                >
                  {this.props.context.state.shipmentDetailsRows &&
                  this.props.context.state.shipmentDetailsRows.length <
                    this.props.context.state.shipmentRowsNumberMax * 1 ? (
                    <button
                      id="addShipmentDetailsRowBtn"
                      onClick={() =>
                        this.props.context.state.shipmentDetailsRows &&
                        this.props.context.state.shipmentDetailsRows.length <
                          this.props.context.state.shipmentRowsNumberMax
                          ? this.addShipmentDetailsRow()
                          : ''
                      }
                      className={
                        'base-button base-button--white base-button--wide frc__generic-button frc__add-button ' +
                        (this.props.context.state.shipmentDetailsRows &&
                        this.props.context.state.shipmentDetailsRows.length <
                          this.props.context.state.shipmentRowsNumberMax * 1
                          ? ''
                          : ' disabled ')
                      }
                    >
                      <span className="has-icon icon-plus frc__button-icon--align">
                        {t(`general|Add another item of different size`)}
                      </span>
                    </button>
                  ) : (
                    <Error
                      message={t(`portalTemplates|Shipment Row Max Error`, {
                        shipmentRowMax: this.props.context.state.shipmentRowsNumberMax,
                      })}
                    />
                  )}
                </div>
              </div>
              <ShipmentRowsSummary context={this.props.context} />
            </>
          )}
        </Translation>
      </div>
    );
  }
}

export default withQueryClient(EnterShipmentDetails);

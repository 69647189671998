import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Error } from 'components';
import { shipmentDetailsTotalsValidations } from '../../TermsAndSubmit/Validations/EnterShipmentDetails-validations';
import './ShipmentRowsSummary.css';
import { Box } from '@mui/material';

export default class ShipmentRowsSummary extends Component {
  render() {
    const {
      totals,
      dimensions,
      quantityError,
      weightError,
      volumeError,
      loadingMeterError,
      palletPlaceError,
      chargeableWeightError,
    } = shipmentDetailsTotalsValidations(this.props.context);

    return (
      <Translation>
        {t => (
          <>
            <Box
              sx={{
                bgcolor: theme => theme.palette.background.block,
              }}
            >
              <div className="l-grid--w-100pc-s l-grid--w-50pc-m frc__total-errors--wrapper">
                {quantityError ? (
                  <Error
                    name="totalQuantityError"
                    className="frc__input--error"
                    message={t('general|errors|invalidTotalQuantity', {
                      min: dimensions.min_total_quantity,
                      max: dimensions.max_total_quantity,
                    })}
                  />
                ) : (
                  ''
                )}

                {volumeError ? (
                  <Error
                    name="totalVolumeError"
                    className="frc__input--error"
                    message={t('general|errors|invalidValue', {
                      name: t('general|labels|metrics|Total Volume'),
                      min: dimensions.min_total_volume,
                      max: dimensions.max_total_volume,
                    })}
                  />
                ) : (
                  ''
                )}

                {weightError ? (
                  <Error
                    name="totalWeightError"
                    className="frc__input--error"
                    message={t('general|errors|invalidValue', {
                      name: t('general|labels|metrics|Total Weight'),
                      min: dimensions.min_shipment_weight,
                      max: dimensions.max_shipment_weight,
                    })}
                  />
                ) : (
                  ''
                )}

                {loadingMeterError ? (
                  <Error
                    name="totalLoadingMeterError"
                    className="frc__input--error"
                    message={t('general|errors|invalidValue', {
                      name: t('general|labels|metrics|Total Loading Meter'),
                      min: dimensions.min_total_loading_meter,
                      max: dimensions.max_total_loading_meter,
                    })}
                  />
                ) : (
                  ''
                )}

                {palletPlaceError ? (
                  <Error
                    name="totalPalletPlaceError"
                    className="frc__input--error"
                    message={t('general|errors|invalidValue', {
                      name: t('general|labels|metrics|Total Pallet Place'),
                      min: dimensions.min_total_palletPlace,
                      max: dimensions.max_total_palletPlace,
                    })}
                  />
                ) : (
                  ''
                )}

                {chargeableWeightError ? (
                  <Error
                    name="totalChargeableWeightError"
                    className="frc__input--error"
                    message={
                      t('general|errors|invalidValue', {
                        name: t(`general|Chargeable weight`),
                        min: dimensions.min_total_chargeableWeight,
                        max: dimensions.max_total_chargeableWeight,
                      }) +
                      '. ' +
                      t('general|errors|Please try a different product or contact us.')
                    }
                  />
                ) : (
                  ''
                )}
              </div>

              <div>
                <Box
                  sx={{
                    width: '100%',
                    maxWidth: '250px',
                    ml: 'auto',
                    mr: '11px',
                    mt: '8px',
                  }}
                >
                  <div className={'frc__shipment-summary--row' + (quantityError ? ' color-dhl-red ' : '')}>
                    <div>{t('general|labels|metrics|Total Quantity')}: </div>
                    <div id="miniSummaryTotalQuantity">{totals.quantity.value}</div>
                  </div>
                  <div className={'frc__shipment-summary--row' + (volumeError ? ' color-dhl-red ' : '')}>
                    <div>{t('general|labels|metrics|Total Volume')}: </div>
                    <div>
                      <span id="miniSummaryTotalVolume">{totals.volume.value}</span> {t('general|m')}
                      <sup>3</sup>
                    </div>
                  </div>
                  <div className={'frc__shipment-summary--row' + (weightError ? ' color-dhl-red ' : '')}>
                    <div>{t('general|labels|metrics|Total Weight')}: </div>
                    <div>
                      <span id="miniSummaryTotalWeight">{totals.weight.value}</span> {t('general|kg')}
                    </div>
                  </div>
                  <div className={'frc__shipment-summary--row' + (loadingMeterError ? ' color-dhl-red ' : '')}>
                    <div>{t('general|labels|metrics|Total Loading Meter')}: </div>
                    <div id="miniSummaryTotalLoadingMeter">{totals.loadingMeter.value}</div>
                  </div>
                </Box>
              </div>
            </Box>
          </>
        )}
      </Translation>
    );
  }
}

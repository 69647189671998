import React, { useEffect, useState } from 'react';
import { config, CONST } from '../../../data-config';
import { apiLinks } from 'config/api-config';
import { useTranslation } from 'react-i18next';
import { apiRequest } from 'globals/utils/requests';
import getBaseUrl from 'globals/utils/getUrlBase';
import { debounce } from 'globals/utils/debounce';
import { captchaTokenVisible, Input } from 'components';
import { Error } from 'components';

import './Captcha.css';

// const ENV_DEV = process.env.NODE_ENV === 'development';

export default function Captcha({ context }) {
  const { t } = useTranslation();
  const captchaTokenEnv = captchaTokenVisible() ? context.state?.captcha?.token : null;

  const [img, setImg] = useState('');

  const getCaptcha = () => {
    const params = {
      headers: {
        Referer: getBaseUrl(),
      },
    };

    const cacheBreak = new Date().getTime().toString();

    return apiRequest(apiLinks.getCaptcha + '?&_=' + cacheBreak, 'GET', params).then(result => {
      if (result.status === CONST.STATUS_OK) {
        setImg(result.data.image);
        context.updateState({ captcha: { token: result.data.token, value: '', error: false } });
      }
    });
  };

  const getCaptchaOnClick = debounce(getCaptcha, config.debounceDelay);

  Captcha.getCaptcha = getCaptchaOnClick;

  useEffect(() => {
    getCaptcha();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="l-grid l-grid--w-100pc-s frc__captcha margin-bottom-2">
      <h4 className="l-grid--left-s l-grid--w-100pc-s text-center">
        {t('general|To make sure you are not a robot please do the following')}
      </h4>
      <div className="l-grid--w-100pc-s">
        <h4 className="text-center component-wide">{t('general|Rewrite the code')}</h4>
        <div className="l-grid l-grid--center-s">
          <div className="l-grid l-grid--center-s l-grid--w-30pc-m l-grid--w-100pc-s">
            <div className="l-grid--w-100pc-s">
              <img
                src={'data:image/png;base64, ' + img}
                alt="Captcha"
                className="margin-bottom-1"
                data-testid={captchaTokenEnv}
              />
            </div>

            <button
              id="reloadCaptchaBtn"
              onClick={getCaptchaOnClick}
              href="#"
              className="base-button base-button--white"
            >
              <span className="color-DHL-red" /> <span>{t('general|labels|buttons|Reload')}</span>
            </button>

            <Input
              context={context}
              config={config}
              CONST={CONST}
              regEx={config.regEx.everything}
              name="captcha"
              isRequired={true}
              value={context.state.captcha.value}
              label={t(`general|Captcha code`)}
              className="frc__input--wrapper frc__generic--field-wrapper l-grid--w-100pc-s"
              lengthCheck={[RegExp(`^.{${config.maxCaptcha},${config.maxCaptcha}}$`)]}
              cutTextLimit={config.maxCaptcha}
              hideAutoComplete={true}
            />

            {context.state.captcha.error ? (
              <Error
                name="captcha"
                className="frc__input--error"
                message={t('general|errors|Please enter captcha code')}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

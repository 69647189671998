import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRouting, ROUTES } from 'router';
import { isValidCountryLanguage } from '../../utils/urls';

export function ErrorPageContent401() {
  const { lang } = useParams();
  const { t } = useTranslation();
  const { linkTo } = useRouting();

  if (!isValidCountryLanguage(lang)) {
    return null;
  }

  return (
    <>
      <div className="c-page-headline component-small has-rte component-margin">
        <p className="has-rte">{t('errorPage|Your session has expired, please login again.')}</p>
      </div>
      <div className="c-cta component-margin  component-wide l-grid l-grid--center-s">
        <Link
          to={'/' + lang}
          className="base-button base-button--white base-button--wide"
          data-analytics='{"trackedInteractions":"basic","interactionType":"dhl_utf_contentInteraction","content":{"name":"Login","interaction":"Click","context":"CTA Container","attributes":{"component":"fcp/components/elements/cta-element"},"type":"Button"}}'
        >
          <span>{t(`mainMenu|Login`)}</span>
        </Link>

        <Link
          to={linkTo(ROUTES.registration)}
          className="base-button  base-button--wide"
          data-analytics='{"trackedInteractions":"basic","interactionType":"dhl_utf_contentInteraction","content":{"name":"Not registered? Create Login","interaction":"Click","context":"CTA Container","attributes":{"component":"fcp/components/elements/cta-element"},"type":"Button"}}'
        >
          <span>{t('errorPage|Not registered? Create Login')}</span>
        </Link>
      </div>
    </>
  );
}

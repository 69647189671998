import React, { Component } from 'react';
import { toCamelCase } from 'globals/utils/formatting';
import { matchRegEx } from 'globals/utils/fieldValidations';

import './TextArea.css';

export default class TextArea extends Component {
  constructor(props) {
    super(props);

    this.className = props.className || 'frc__textarea--wrapper frc__generic--field-wrapper';
    this.labelClass = props.labelClass || 'frc__label';
  }

  handleChange = (value, context) => {
    const params = {
      name: this.props.name,
      value: typeof value === 'string' ? value.replace(/^\s+/g, '') : value,
      isRequired: this.props.isRequired,
      min: this.props.min,
      max: this.props.max,
      index: this.props.index,
      disabled: this.props.disabled,
      skipError: this.props.skipError,
      lengthCheck: this.props.lengthCheck,
      cutTextLimit: this.props.cutTextLimit,
      callbackFunction: this.props.callback,
    };

    if (matchRegEx(params.value, this.props.regEx)) {
      if (this.props.updateOnParent) {
        this.props.updateOnParent(params, context);
      } else {
        context.updateField(params);
      }
    }
  };

  render() {
    return (
      <>
        <span className={this.className} data-testid={`${this.props.name}TextareaId`}>
          <label
            className={this.labelClass}
            dangerouslySetInnerHTML={{
              __html: this.props.label + (this.props.isRequired && !this.props.hideAsterisk ? '*' : ''),
            }}
          />
          {this.props.smallLabel ? (
            <div
              dangerouslySetInnerHTML={{
                __html: '<small>' + this.props.smallLabel + '</small>',
              }}
            />
          ) : (
            ''
          )}
          <textarea
            id={toCamelCase(this.props.id || this.props.name) + 'TxtAr'}
            type="text"
            onBlur={() => this.handleChange(this.props.value, this.props.context)}
            onChange={e => this.handleChange(e.target.value, this.props.context)}
            placeholder={this.props.placeholder}
            className={
              'c-form--element-base c-form--element-textarea ' +
              (this.props.disabled ? ' disabled ' : '') +
              (this.props.textAreaClassName ? this.props.textAreaClassName : ' frc__textarea--small ')
            }
            disabled={this.props.disabled}
            value={this.props.value}
          />
        </span>
      </>
    );
  }
}

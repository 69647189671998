// To Truncate the calculated result
// MK-test
// eslint-disable-next-line
Number.prototype.toFixedDown = function (digits) {
  var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
    m = this.toString().match(re);
  return m ? parseFloat(m[1]) : this.valueOf();
};

// To Round always up the calculated result
// MK-test
// eslint-disable-next-line
Number.prototype.toFixedUp = function (digits) {
  var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
    m = this.toString().match(re);
  return m ? parseFloat(Number(m[1] + '9').toFixed(digits)) : this.valueOf();
};

// Precise floating point multiplication (no numerical errors)
export const multiplyPrecisely = (a, b) => {
  let res;
  const numA = String(a).replace(',', '.');
  const numB = String(b).replace(',', '.');
  const aDecimals = numA.indexOf('.') === -1 ? 0 : numA.length - numA.indexOf('.') - 1;
  const bDecimals = numB.indexOf('.') === -1 ? 0 : numB.length - numB.indexOf('.') - 1;

  if (aDecimals !== 0 || bDecimals !== 0) {
    res = (numA * Math.pow(10, aDecimals) * (numB * Math.pow(10, bDecimals))) / Math.pow(10, aDecimals + bDecimals);
  } else {
    res = a * b;
  }

  return res;
};

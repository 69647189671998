// import React, { useContext } from "react";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../data-config';
import SelectService from './SelectService/SelectService';
import SelectServiceCarousel from './SelectServiceCarousel/SelectServiceCarousel';
import './SelectServices.css';

import { useGetCountryConfig } from '../../../../hooks/api/useGetCountryConfig';

export default function SelectServices(props) {
  const { t } = useTranslation();
  const { data: countryConfig } = useGetCountryConfig();

  const oneProductSelected =
    props.routeContext.state.productsRangeOne?.value === true && props.routeContext.state.selectedProduct?.value;

  const productsToDisplay = oneProductSelected
    ? props.context.state.matchedProducts.filter(
        product => product.code.toLowerCase() === props.routeContext.state.selectedProduct.value.toLowerCase(),
      )
    : props.context.state.matchedProducts;
  const productDisabledVas = countryConfig?.disabledVass;

  useEffect(() => {
    if (oneProductSelected && props.context.state.matchedProducts.filter(product => product.value).length > 1) {
      props.context.updateState({
        matchedProducts: props.context.state.matchedProducts.map(product => {
          const value = props.routeContext.state.selectedProduct.value.toLowerCase() === product.code.toLowerCase();
          return { ...product, value };
        }),
      });
    }
  }, [
    oneProductSelected,
    countryConfig,
    productDisabledVas,
    props.context,
    props.routeContext,
    props.shipmentContext,
    props.context.state.updateState,
  ]);

  const totalLength = productsToDisplay.length;
  const selectServices = [];
  let startIndex = 0;
  let endIndex = 0;
  let slideIndexLocal = 1;
  const allowedServicesPerPage = config.maxServicesPerPage;
  const selectProductError = props.context.state.selectProductError;

  const selectService = (product, ind) => {
    return (
      <SelectService
        shipmentContext={props.shipmentContext}
        context={props.context}
        eurapidLtcError={props.eurapidLtcError}
        routeContext={props.routeContext}
        product={product}
        key={ind}
        index={slideIndexLocal}
      />
    );
  };

  for (let i = 0; i <= totalLength; ) {
    let newArray = [];

    endIndex = i + allowedServicesPerPage;
    newArray = productsToDisplay.slice(startIndex, endIndex).map((product, ind) => {
      return selectService(product, ind);
    });

    slideIndexLocal += 1;
    startIndex = i + allowedServicesPerPage;
    i += allowedServicesPerPage;
    selectServices.push(<>{newArray}</>);
  }

  return (
    <div className="margin-bottom-0 frc__push-bottom">
      <h4 className="margin-bottom-1 frc__red-lined-title">{t('general|Select a Service')}</h4>
      <div className="c-producttable c-swe-freightcalc--producttable">
        <div className="c-swe-freightcalc--products-overlay" />
        <div
          id="fcp-product-container"
          className="c-producttablecontainer component-wide l-grid--w-100pc-s l-grid--center-m fade-in visible"
        >
          <div className="c-productcontainer">
            <div className="c-productcontainer--inner">
              {selectServices.map((product, ind) => (
                <React.Fragment key={ind}> {product} </React.Fragment>
              ))}
            </div>
            {totalLength > allowedServicesPerPage ? (
              <SelectServiceCarousel slideLength={Math.ceil(totalLength / allowedServicesPerPage)} />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {selectProductError ? (
        <p className="l-grid--center-m has-error-message">{t('general|errors|Please Select one product')}</p>
      ) : (
        ''
      )}
    </div>
  );
}

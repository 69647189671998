import { config } from './data-config';
import { HEMLEVERANS_PRODUCT_CODE, SENDER_PAYER } from '../globals/constants';

export const state = {
  // Pickup state
  pickupSender: { value: '', error: false },
  pickupEmail: { value: '', error: false },
  pickupCountry: { value: config.defaultCountryPortalPickup, error: false },
  pickupCompany: { value: '', error: false },
  pickupVatNumber: { value: '', error: false },
  pickupName: { value: '', error: false },
  pickupStreet: { value: '', error: false },
  pickupPostalCode: { value: '', error: false },
  sourcePostalCodeError: false,
  sourceCityError: false,
  pickupCity: { value: '', error: false },
  pickupPhoneNumbers: [{ code: '', number: '', error: '' }],
  pickupSenderReference: { value: '', error: false },
  pickupDate: { value: new Date().setHours(0, 0, 0), error: false },

  // Delivery state
  deliveryMandatoryByPriorityService: false,
  deliveryReceiver: { value: '', error: false },
  deliveryEmail: { value: '', error: false },
  deliveryEmailConfirmation: { value: '', error: false },
  deliveryCountry: { value: config.defaultCountryPortalPickup, error: false },
  deliveryVatNumber: { value: '', error: false },
  deliveryName: { value: '', error: false },
  deliveryStreet: { value: '', error: false },
  deliveryPostalCode: { value: '', error: false },
  destinationPostalCodeError: false,
  destinationCityError: false,
  deliveryCity: { value: '', error: false },
  deliveryPhoneNumbers: [{ code: '', number: '', error: '' }],
  deliveryReceiverReference: { value: '', error: false },

  // Products
  dhlParti: {
    value: false,
    allowed: false,
    code: '212',
    description: 'DHL Parti Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
    domestic: true,
    international: false,
  },
  dhlPall: {
    value: false,
    allowed: false,
    code: '210',
    name: 'DHL Pall',
    description: 'DHL Pall Tooltip',
    packageTypes: [
      { code: '701', name: 'DHL Helpall' },
      { code: '702', name: 'DHL Halvpall' },
    ],
    domestic: true,
    international: false,
  },
  dhlStycke: {
    value: false,
    allowed: false,
    code: '211',
    description: 'DHL Stycke Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
    domestic: true,
    international: false,
  },
  dhlPaket: {
    value: false,
    allowed: false,
    code: '102',
    description: 'DHL Paket Tooltip',
    packageTypes: [{ code: 'PK', name: 'Package' }],
    domestic: true,
    international: false,
  },
  dhlParcelConnectPlus: {
    value: false,
    allowed: false,
    code: '112',
    description: 'DHL Parcel Connect Plus Tooltip',
    packageTypes: [{ code: 'PK', name: 'Package' }],
    domestic: false,
    international: true,
  },
  dhlServicePoint: {
    value: false,
    allowed: false,
    code: '103',
    description: 'DHL Service Point Tooltip',
    packageTypes: [{ code: 'PK', name: 'Package' }],
    domestic: true,
    international: true,
  },
  dhlHemleverans: {
    value: false,
    allowed: false,
    code: HEMLEVERANS_PRODUCT_CODE,
    description: 'DHL Hemleverans Paket',
    packageTypes: [{ code: 'PK', name: 'Package' }],
    domestic: true,
    international: false,
  },
  dhlServicePointRetur: {
    value: false,
    allowed: false,
    code: '104',
    description: 'DHL Service Point Retur Tooltip',
    packageTypes: [
      { code: 'CLL', name: 'Unspecified Colli' },
      { code: 'PK', name: 'Package' },
    ],
    domestic: true,
    international: true,
  },
  dhlParcelConnect: {
    value: false,
    allowed: false,
    code: '109',
    description: 'DHL Parcel Connect Tooltip',
    packageTypes: [{ code: 'PK', name: 'Package' }],
    domestic: false,
    international: true,
  },
  dhlParcelReturnConnect: {
    value: false,
    allowed: false,
    code: '107',
    description: 'DHL Parcel Return Connect Tooltip',
    packageTypes: [
      { code: 'CLL', name: 'Unspecified Colli' },
      { code: 'PK', name: 'Package' },
    ],
    domestic: false,
    international: true,
  },
  dhlHomeDelivery: {
    value: false,
    allowed: false,
    code: '401',
    description: 'DHL Home Delivery Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
    domestic: true,
    international: false,
  },
  dhlHomeDeliveryReturn: {
    value: false,
    allowed: false,
    code: '402',
    description: 'DHL Home Delivery Return Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
    domestic: true,
    international: false,
  },
  dhlEuroconnect: {
    value: false,
    allowed: false,
    code: '202',
    description: 'DHL Euroconnect Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
    domestic: false,
    international: true,
  },
  dhlEuroconnectPlus: {
    value: false,
    allowed: false,
    code: '232',
    description: 'DHL Euroconnect Plus Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
    domestic: false,
    international: true,
  },
  dhlEurapid: {
    value: false,
    allowed: false,
    code: '233',
    description: 'DHL Eurapid Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
    domestic: false,
    international: true,
  },
  products: [],
  allowedShipmentTypes: [],

  // Shipment details
  payer: { value: SENDER_PAYER, error: false },
  accountNumber: { value: '', error: false, isRequired: true },
  receiverNumber: { value: '', error: false },
  thirdPartyNumber: { value: '', error: false },

  accounts: [],
  originalAccounts: [],
  shipmentNumber: { value: '', error: false, isRequired: true },

  selectedProducts: [],
  shipmentDetailsRows: [],
  numberOfPallets: { value: 0, error: false },
  shippingToMultipleAddresses: { value: false, error: false },

  totals: {
    shipmentDetailsRows: {
      quantity: { value: 1 },
      volume: { value: 0 },
      weight: { value: 0 },
      loadingMeter: { value: 0 },
      palletPlace: { value: 0 },
      chargeableWeight: { value: null, error: false },
    },
  },

  // Additional services
  additionalServices: [],
  priorityServiceDisabled: true,
  G7Matches: false,
  G10Matches: false,
  G12Matches: false,

  // Customs countries
  toCountries: [],
  toCountryMap: {},

  // Form state
  loaders: {
    products: { value: false },
    additionalServices: { value: false },
    shipmentDetails: { value: false },
    locationDetails: { value: false },
  },

  confirmationEmail: { value: '', error: false, isRequired: true },
  captcha: { value: '', token: '', error: false },
  instructions: { value: '', error: false },
  languageCode: config.locale.defaultLanguage,
  publicPickupBookingSubmited: false,
  productSelectionError: false,
  generalError: false,

  // Address book
  addToAddressBookSender: { value: false, error: false },
  addToAddressBookReceiver: { value: false, error: false },
  updateInAddressBookSender: { value: false, error: false },
  updateInAddressBookReceiver: { value: false, error: false },

  senderPickedFromAddressBook: false,
  receiverPickedFromAddressBook: false,
  senderAddressID: { value: '', error: false },
  receiverAddressID: { value: '', error: false },
  showCompanySearchSender: false,
  showCompanySearchReceiver: false,
  searchTerm: { value: '', error: false },
  cityValidationError: {},
};

import React, { useState } from 'react';
import { config, CONST } from '../../../data-config';
import { useTranslation } from 'react-i18next';
import { resetAllPayerNumbers } from './PayingAccountNumber-helpers';
import { callVASOnCountryOrPayerChange, beforeCheck } from '../ServiceDetails-helpers';
import Payer from './Payer';

import { Input } from 'components';
import { Select } from 'components';
import { Error } from 'components';

import './PayingAccountNumber.css';

const PayingAccountNumber = ({ context }) => {
  const { t } = useTranslation();

  const id = 'checkbox_IDontHaveAccountNumber';

  const [hidden, setHidden] = useState(false);

  const toggle = () => {
    const stateToSet = {
      accountNumber: {
        value: '',
        error: false,
      },
      noAccountCheckbox: !context.state.noAccountCheckbox,
    };
    setHidden(prevHidden => !prevHidden);
    context.updateState(stateToSet);
  };

  const handleSelect = e => {
    const stateToSet = {
      payer: { value: e.value || context.state.payer.value, error: false },
    };

    [stateToSet.receiverNumber, stateToSet.thirdPartyNumber] = resetAllPayerNumbers(context);

    context.extendedSetState(stateToSet, {
      beforeUpdate: beforeCheck,
      afterUpdate: callVASOnCountryOrPayerChange,
    });
  };

  return (
    <>
      {!context.state.dhlParti.value ? (
        !hidden ? (
          <>
            <div>
              <div className="l-grid l-grid--middle-s frc__paying-account-number has-top-margin-1">
                <Input
                  context={context}
                  config={config}
                  CONST={CONST}
                  regEx={config.regEx.number}
                  name="accountNumber"
                  isRequired={!hidden}
                  label={t('general|Paying account number')}
                  className="margin-bottom-0"
                  value={context.state.accountNumber.value}
                  lengthCheck={[RegExp(`^.{${config.defaultAccountNumberLength}}$`)]}
                  cutTextLimit={config.defaultAccountNumberLength}
                />

                <input
                  className={'checkbox-pallet c-form--element-base c-form--element-input c-form--element-checkbox'}
                  type="checkbox"
                  onChange={toggle}
                  id={id}
                  checked={context.state.noAccountCheckbox}
                  disabled={context.state.dhlParti.value}
                />

                <label
                  htmlFor={id}
                  className="c-form--element-base c-form--element-label c-form--element-label-checkbox c-freightcalc--checkbox c-fcp--checkbox-label l-grid--o-1u-s frc__paying-account-number--checkbox"
                >
                  {t(`general|I don't have the account number at this time`)}
                </label>
              </div>
              {context.state.accountNumber.error ? (
                <Error
                  name="PayingAccountNumberError"
                  className="frc__input--error"
                  message={t(`general|Account Number Error`)}
                />
              ) : (
                ''
              )}
            </div>
          </>
        ) : (
          <>
            <input
              className={'checkbox-pallet c-form--element-base c-form--element-input c-form--element-checkbox'}
              type="checkbox"
              onChange={toggle}
              id={id}
              checked={context.state.noAccountCheckbox}
            />
            <label
              htmlFor={id}
              className="c-form--element-base c-form--element-label c-form--element-label-checkbox c-freightcalc--checkbox c-fcp--checkbox-label has-top-margin-1"
            >
              {t(`general|I don't have the account number at this time`)}
            </label>
          </>
        )
      ) : (
        <>
          <div>
            <div className="l-grid--w-100pc-s l-grid--w-40pc-m l-grid--middle-s frc__paying-account-number has-top-margin-1">
              <Select
                context={context}
                config={config}
                CONST={{ API_DOMAIN: 'general' }}
                label={t('general|Who will pay for this shipment?')}
                name={'payer'}
                value={context.state.payer.value}
                values={config.payers}
                disableonBlur={true}
                updateOnParent={e => handleSelect(e)}
              />
            </div>
            <Payer />
          </div>
        </>
      )}
    </>
  );
};

export default PayingAccountNumber;

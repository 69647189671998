import React, { useEffect } from 'react';
import { MyContext, MyProvider } from '../../context/context';

import ForgotPassword from '../ForgotPassword/ForgotPassword';
import { DEFAULT_EN_LANGUAGE } from '../../../locale/config';
import { ROUTES, useRouting } from '../../../router';
import { useCountryLanguage } from '../../../hooks/useCountryLanguage';

const ENV_DEV = process.env.NODE_ENV === 'development';

export default function AppForgotPassword() {
  const { navigate } = useRouting();
  const countryLanguage = useCountryLanguage();

  useEffect(() => {
    const lang = countryLanguage.language;
    const country = countryLanguage.country;

    if (lang === 'en' && country !== 'se') {
      // forgot password Page could not be in English in other countries, SE-EN only
      if (process.env.REACT_APP_VERBOSE_LOGS?.toLowerCase() === 'true' && ENV_DEV) {
        console.log('redirecting to English default forgot password page');
      }
      navigate('../../' + DEFAULT_EN_LANGUAGE + '/' + ROUTES.forgotPassword, { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <MyProvider>
      <MyContext.Consumer>{context => <ForgotPassword context={context} />}</MyContext.Consumer>
    </MyProvider>
  );
}

import { v4 as uuidv4 } from 'uuid';
import { config } from '../../data-config';
import {
  contactEmailMandatoryForInternational,
  findCustomBasedCountry,
  getCountryForCustoms,
  getPayerCode,
  payerCode,
  readState,
  setAccountInfo,
} from './EnterPickupLocation-helpers';

import { hidePricesDueToPayerType } from 'globals/utils/filtering';
import { formatDate } from 'globals/utils/formatting';
import {
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  SENDER_PAYER,
} from '../../../globals/constants';
import {
  getEkaerNumber,
  getSentCarrierKey,
  getSentReference,
  isEkaerFree,
} from '../../../globals/helpers/additionalInformation';
import {
  getProductBasedPrice,
  getProductCodeForHDBasedOnVAS,
  getResidentialServicePoint,
  isFreeVas,
} from '../DeliveryOptions/DeliveryOptions-helpers';
import { filterAccountsBasedOnShipmentRange } from '../EnterPickupLocation/ImportExportSection/ImportExportSection-helpers';
import { formMissingVasString, getAdditionalServicesForOneSelectedProduct } from '../ServiceDetails/ServiceDetails';
import {
  checkIfUseResidentialAddressCanBeDisabled,
  isDomesticReturnProduct,
} from '../ServiceDetails/ServiceDetails-helpers/ServiceDetails-helpers';
import { preparePhoneNumber } from 'hooks/usePhoneNumber';
import { GLOBAL_CONST } from '../../../globals/data-config-global';

const setParty = (newState, templateData, name, type, customerType) => {
  const stateToSet = JSON.parse(JSON.stringify(newState));
  const party = templateData.savedShipmentParties
    ? templateData.savedShipmentParties.find(partyI => partyI.type === customerType)
    : false;

  if (party) {
    stateToSet[`${type}${name}`].value = party.companyName || '';
    stateToSet[`${type}Email`].value = party.email || '';
    stateToSet[`${type}EmailConfirmation`].value = party.emailConfirmation || false;
    stateToSet[`${type}Country`].value = party.countryCode;
    stateToSet[`${type}Name`].value = party.name || '';
    stateToSet[`${type}Street`].value = party.street || '';
    stateToSet[`${type}PostalCode`].value = party.postalCode || '';
    stateToSet[`${type}City`].value = party.city || '';

    if (party.phone) {
      const { code, number } = preparePhoneNumber(party.phone);
      stateToSet[`${type}PhoneNumbers`] = [
        {
          code,
          error: false,
          isRequired: true,
          number,
        },
      ];
    }
  }

  return stateToSet;
};

const setShipmentData = async (templateDataShipmentDetails, templateTotalLdm) => {
  const stateObj = {};

  stateObj.shipmentDetailsRows = [];

  let quantity = 0;
  let volume = 0;
  let loadingMeter = templateTotalLdm || '';
  let weight = 0;

  templateDataShipmentDetails.forEach(templateShipmentRow => {
    const shipmentRow = JSON.parse(JSON.stringify(config.shipmentTypesFields[templateShipmentRow.type]));

    shipmentRow.height.value = templateShipmentRow.height || '';
    shipmentRow.length.value = templateShipmentRow.length || '';
    shipmentRow.loadingMeter.value = templateShipmentRow.loadMeter || '';
    shipmentRow.quantity.value = templateShipmentRow.quantity || '';
    shipmentRow.shipmentType.value = templateShipmentRow.type;
    shipmentRow.nonStackable.value = templateShipmentRow.nonStackable;
    shipmentRow.volume.value = templateShipmentRow.volume || '';
    shipmentRow.weight.value = templateShipmentRow.weight || '';
    shipmentRow.width.value = templateShipmentRow.width || '';

    // international specific - dangerous goods & goods desc & shipment mark
    shipmentRow.goodsDescription.value = templateShipmentRow.goodsDescription || '';
    shipmentRow.shippingMark.value = templateShipmentRow.shipmentMark || '';
    shipmentRow.dangerousGoodGroup = JSON.parse(JSON.stringify(config.dangerousGoodGroup));

    // set disable
    shipmentRow.nonStackable.disabled = false;

    if (templateShipmentRow.dangerousGoods && shipmentRow.dangerousGoods) {
      if (templateShipmentRow.dangerousGoods.length) {
        shipmentRow.dangerousGoods.value = true;
      }

      shipmentRow.dangerousGoodGroup = templateShipmentRow.dangerousGoods.map(item => {
        return setDangerousGoods(item);
      });
    }

    quantity += shipmentRow.quantity.value;
    volume += shipmentRow.volume.value * shipmentRow.quantity.value;
    weight += shipmentRow.weight.value * shipmentRow.quantity.value;

    stateObj.shipmentDetailsRows.push(shipmentRow);
  });

  stateObj.totals = {
    shipmentDetailsRows: {
      quantity: { value: quantity },
      volume: { value: volume ? volume.toFixed(config.shipmentInputs.volume.maxDecimals) : volume },
      weight: { value: weight ? weight.toFixed(config.shipmentInputs.weight.maxDecimals) : weight },
      loadingMeter: {
        value: loadingMeter ? loadingMeter.toFixed(config.shipmentInputs.loadingMeter.maxDecimals) : loadingMeter,
      },
      chargeableWeight: { value: null, error: false },
    },
  };

  return [stateObj.shipmentDetailsRows, stateObj.totals];
};

const setDangerousGoods = data => {
  let dangerousGoodGroup = {
    adrClass: {},
    unNumber: {},
    flamePoint: {},
    packagingGroup: {},
    tunnelDescriptionCode: {},
    properShippingName: {},
    technicalDescription: {},
    limitedQuantity: {},
    grossWeight: {},
    netQuantity: {},
    numberOfUnits: {},
    packageCode: {},
    quantityMeasurementUnitQualifier: {},
    environmentHazardous: {},
    marinePollutant: {},
    marinePollutantName: {},
    exceptedQuantity: {},
    emptyContainer: {},
    waste: {},
    dgmId: {},
  };
  dangerousGoodGroup.adrClass.value = data.adrClass || '';
  dangerousGoodGroup.unNumber.value = data.unNumber || '';
  dangerousGoodGroup.flamePoint.value = data.flamePoint || '';
  dangerousGoodGroup.packagingGroup.value = data.packagingGroup || '';
  dangerousGoodGroup.tunnelDescriptionCode.value = data.tunnelDescriptionCode || '';
  dangerousGoodGroup.properShippingName.value = data.properShippingName || '';
  dangerousGoodGroup.technicalDescription.value = data.technicalDescription || '';
  dangerousGoodGroup.limitedQuantity.value = data.limitedQuantity || false;
  dangerousGoodGroup.grossWeight.value = data.grossWeight || '';
  dangerousGoodGroup.netQuantity.value = data.netQuantity || '';
  dangerousGoodGroup.numberOfUnits.value = data.numberOfUnits || '';
  dangerousGoodGroup.packageCode.value = data.packageCode || '';
  dangerousGoodGroup.quantityMeasurementUnitQualifier.value = data.quantityMeasurementUnitQualifier || '';
  dangerousGoodGroup.environmentHazardous.value = data.environmentHazardous || false;
  dangerousGoodGroup.marinePollutant.value = data.marinePollutant || false;
  dangerousGoodGroup.marinePollutantName.value = data.marinePollutantName || '';
  dangerousGoodGroup.exceptedQuantity.value = data.exceptedQuantity || false;
  dangerousGoodGroup.emptyContainer.value = data.emptyContainer || false;
  dangerousGoodGroup.waste.value = data.waste || false;
  dangerousGoodGroup.dgmId.value = data.dgmId || '';
  dangerousGoodGroup.id = uuidv4();

  return dangerousGoodGroup;
};

const optionsSchemaTimeRelatedValuesFix = (additionalServices, pickupDate) => {
  const newPickupDate = pickupDate.value;
  let loadingDate = '';
  let unloadingDate = '';

  additionalServices.forEach((additionalService, i) => {
    if (additionalService.groups && additionalService.groups.length > 0) {
      additionalService.groups.forEach((group, groupIndex) => {
        if (group.options && group.options.length > 0) {
          group.options.forEach((optionList, parentOptionIndex) => {
            optionList.forEach((option, ind) => {
              // loading date
              if (config.vasSpecialCaseForPickupDate.indexOf(additionalService.code) !== -1) {
                loadingDate = additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value;

                if (new Date(newPickupDate).getTime() > new Date(loadingDate).getTime()) {
                  loadingDate = new Date(newPickupDate);
                  additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value = loadingDate;
                }
              }

              // unloading date
              if (config.vasSpecialCaseForDeliveryDate.indexOf(additionalService.code) !== -1) {
                unloadingDate = additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value;

                // if loading date greater than current unloading date; set loadingDate to unloading
                if (new Date(loadingDate).getTime() > new Date(unloadingDate).getTime()) {
                  additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value = loadingDate;
                }
              }
            });
          });
        }
      });
    }
  });

  return additionalServices;
};

const addOptions = (selectedVasWithOptionsSchema, options, groupIndex) => {
  const newOptions = JSON.parse(JSON.stringify(options));

  const maxOptionSchemaValue = selectedVasWithOptionsSchema.reduce(
    (vas1, vas2) => {
      return vas2.groupIndex === groupIndex && vas2.optionIndex >= vas1.optionIndex ? vas2 : vas1;
    },
    {
      optionIndex: 0,
    },
  );
  const maxRepeat = maxOptionSchemaValue.optionIndex;

  if (maxRepeat > 0) {
    const firstOption = options[0];
    let repeat = 0;

    do {
      newOptions.push(firstOption);
      repeat++;
    } while (repeat < maxRepeat);
  }

  return newOptions;
};

const returnProperDate = date => {
  return typeof date === 'string' && date.indexOf('T') === -1 ? new Date(date * 1) : new Date(date);
};

const setAdditionalServices = async (state, templateData, countryConfig) => {
  const stateToSet = JSON.parse(JSON.stringify(state));

  stateToSet.customs =
    config.productsWithUploadCustomsDocumentsForAllCountries.indexOf(stateToSet.selectedProduct.name) !== -1
      ? true
      : stateToSet.countryBasedCustoms;
  stateToSet.selectedProduct.error = false;
  stateToSet.preSelectedTemplateNotAvailable = '';
  stateToSet.preSelectedProductId = '';
  stateToSet.preSelectedVasNotAvailable = '';
  stateToSet.missingVASArrNames = [];

  // make vas api and add it in state
  Object.assign(
    stateToSet,
    await getAdditionalServicesForOneSelectedProduct(
      stateToSet,
      false,
      false,
      stateToSet.selectedProduct,
      countryConfig,
    ),
  );

  if (!stateToSet.error && templateData.savedShipmentVass && templateData.savedShipmentVass.length > 0) {
    // Get missing vas to display warning message
    const missingVasFromTemplate = templateData.savedShipmentVass.filter(
      templateVas => !stateToSet.additionalServices.some(vas => vas.code === templateVas.vas),
    );

    // Warning message formed
    if (missingVasFromTemplate) {
      stateToSet.missingVASArrNames = formAdditionalServiceWhichAreNotAvailable(missingVasFromTemplate, stateToSet);
      stateToSet.preSelectedVasNotAvailable = formMissingVasString(stateToSet.missingVASArrNames);
    }

    // iterate additionalServices
    stateToSet.additionalServices.map(additionalService => {
      // each selected vas in template date
      const selectedVasWithOptionsSchema = templateData.savedShipmentVass.filter(
        templateVas => templateVas.vas === additionalService.code,
      );

      // check if stateObj vas is true
      if (selectedVasWithOptionsSchema.length > 0) {
        additionalService.value = true;

        // check if options schema is present for stateobj vas
        if (additionalService.groups && additionalService.groups.length > 0) {
          // vasField means options schema

          additionalService.groups.forEach((group, groupIndex) => {
            if (group && group.options) {
              group.options = addOptions(selectedVasWithOptionsSchema, group.options, groupIndex);

              group.options.forEach((optionList, optionIndex) => {
                optionList.forEach(option => {
                  // selected vas field
                  const selectedVas = selectedVasWithOptionsSchema.find(
                    vas =>
                      option.optionCode === vas.vasField &&
                      groupIndex === vas.groupIndex &&
                      optionIndex === vas.optionIndex,
                  );
                  // find if options schema is present
                  if (selectedVas) {
                    // set options schema value
                    option.value =
                      config.OptionFieldTypes.Telephone === option.type
                        ? [
                            {
                              code: selectedVas.vasFieldValue ? selectedVas.vasFieldValue.split('-')[0] : '',
                              error: false,
                              isRequired: true,
                              number: selectedVas.vasFieldValue ? selectedVas.vasFieldValue.split('-')[1] : '',
                            },
                          ]
                        : config.OptionFieldTypes.DateTime === option.type
                        ? returnProperDate(selectedVas.vasFieldValue)
                        : selectedVas.vasFieldValue
                        ? selectedVas.vasFieldValue
                        : option.value;

                    option.id = selectedVas.id;
                  }
                });
              });
            }
          });
        } else {
          additionalService.id = selectedVasWithOptionsSchema[0].id;
        }
      }

      return additionalService;
    });
  }

  stateToSet.additionalServices = disableNotCompatibleVAS(stateToSet.additionalServices);
  const checkIfTimeRelatedOptionsSchemaIsPresent = stateToSet.additionalServices.some(
    AD =>
      config.vasSpecialCaseForPickupDate.indexOf(AD.code) !== -1 ||
      config.vasSpecialCaseForDeliveryDate.indexOf(AD.code) !== -1,
  );

  if (checkIfTimeRelatedOptionsSchemaIsPresent) {
    stateToSet.additionalServices = optionsSchemaTimeRelatedValuesFix(
      stateToSet.additionalServices,
      stateToSet.pickupDate,
    );
  }

  // set price quote
  if (!hidePricesDueToPayerType(stateToSet) && !templateData.servicePoint) {
    await setQuotePrice(stateToSet, countryConfig);
  }

  if (deliveryMonitoringExpanded(stateToSet.additionalServices)) {
    stateToSet.deliveryMonitoringExpanded = true;
  } else {
    stateToSet.deliveryMonitoringExpanded = false;
  }

  return stateToSet;
};

const disableNotCompatibleVAS = oldAdditionalServices => {
  const additionalServices = JSON.parse(JSON.stringify(oldAdditionalServices));
  const deSelectVasList = [];
  // iterate each vas
  additionalServices.forEach(additionalService => {
    if (additionalService.value && additionalService.notCompatibleWith) {
      deSelectVasList.push(...additionalService.notCompatibleWith);
    }
  });

  // remove duplicates
  const newDeselectList = deSelectVasList.filter((item, index) => deSelectVasList.indexOf(item) === index);
  additionalServices.forEach(additionalService => {
    if (newDeselectList.indexOf(additionalService.code) !== -1) {
      additionalService.value = false;
      additionalService.disabled = true;
      additionalService.selectedStatus = false;
    }
  });

  return additionalServices;
};

const deliveryMonitoringExpanded = additionalServices => {
  return additionalServices.some(
    additionalService => additionalService.value && additionalService.code.indexOf('deliveryMonitoring') !== -1,
  );
};

const formAdditionalServiceWhichAreNotAvailable = (templateVas, { vasNameMap, languageCode }) => {
  const vasArrNames = [];

  templateVas.forEach(tVas => {
    const vasCodeToBeChecked =
      vasNameMap[tVas.vas] || (tVas.translations[languageCode] && tVas.translations[languageCode].name);

    if (vasArrNames.indexOf(vasCodeToBeChecked) === -1) {
      vasArrNames.push(vasCodeToBeChecked);
    }
  });

  return vasArrNames;
};

const setQuotePrice = async (stateToSet, countryConfig) => {
  let productCode = stateToSet.selectedProduct.code;

  if (config.homeDeliveryMapping[productCode]) {
    productCode = getProductCodeForHDBasedOnVAS(stateToSet);
  }
  stateToSet.selectedProduct.code = productCode;
  const quotePriceCall = await getProductBasedPrice(stateToSet);

  if (!quotePriceCall.quotePriceError) {
    const newAdditionalServices = JSON.parse(JSON.stringify(stateToSet.additionalServices));

    newAdditionalServices.forEach(AD => {
      if (AD.value) {
        if (quotePriceCall.quotePriceMap[AD.code]) {
          AD.calculatedResult = quotePriceCall.quotePriceMap[AD.code].calculatedResult;
        } else {
          const freeVas = isFreeVas({
            freeVas: countryConfig?.freeVass,
            vasCode: AD.code,
            selectedProductCode: productCode,
          });
          if (freeVas) {
            AD.calculatedResult = GLOBAL_CONST.PRICE_FREE_OF_CHARGE;
          } else {
            AD.calculatedResult = GLOBAL_CONST.PRICE_NOT_AVAILABLE;
          }

          AD.translationWhenPriceNotAvailable = true;
        }
      }
    });

    stateToSet.additionalServices = newAdditionalServices;
    stateToSet.quotePriceMap = quotePriceCall.quotePriceMap;
  } else {
    stateToSet.error = true;
  }
};

const setPercentageCompletion = (context, stateToSet) => {
  context.updateState(stateToSet);
};

export const closePercentageBar = context => {
  setTimeout(() => {
    context.updateState({
      percentageText: '',
      percentage: 100,
    });
  }, 200);
};

export const setPickupDateIfValid = (state, { pickupDate }) => {
  if (pickupDate) {
    const newPickupDate = new Date(pickupDate);
    const currentDate = new Date(formatDate(new Date()));

    if (newPickupDate.getTime() >= currentDate.getTime()) {
      state.pickupDate.value = newPickupDate;
      state.pickupMinDate = new Date();
    }
  }
};

export const fillTemplateData = async (context, templateData) => {
  // assign existing state and reset required fields
  let state = Object.assign({}, context.state, { templateData });
  // Total Completion 100% - route, shipment - 50%
  const percentageOfEachTab = 50;
  // secondary HD codes [501, 502] if present get primary codes
  if (templateData.productID && config.homeDeliverySecondaryCodes.indexOf(templateData.productID) !== -1) {
    templateData.productID = config.homeDeliveryMapping[templateData.productID].primaryCode;
  }

  // reset template search value
  state.templateSearch = { value: '' };

  state.id = templateData.id;
  state.percentage = 0;
  setPickupDateIfValid(state, templateData);

  setPercentageCompletion(context, state);

  // set constants dimensions
  state.dimensions = JSON.parse(JSON.stringify(config.dimensions));

  state.isSavedTemplate = templateData.templateType === 3;
  state.percentageText = state.isSavedTemplate ? 'Templates' : 'Saved Shipments';

  // Confirmation Email
  state.confirmationEmail.value = templateData.shipmentConfirmationEmail || '';

  //ROUTE - 1st Tab
  state.pickupFromDifferentAddress.value = templateData.savedShipmentParties
    ? templateData.savedShipmentParties.some(party => party.type === 'differentPickup')
    : false;

  state.deliverToDifferentAddress.value = templateData.savedShipmentParties
    ? templateData.savedShipmentParties.some(party => party.type === 'differentDelivery')
    : false;

  const isSenderPayer = templateData.whoPay === SENDER_PAYER;
  const isReceiverPayer = templateData.whoPay === RECEIVER_PAYER;
  // diff pickup address and residential case
  if (templateData.sdifferent) {
    // diff pickup address is true <-> pickup residential is false
    state.pickupFromDifferentAddress.value = true;
    state.pickupAddressResidential.value = false;
    // since diff pickup address is true; residential value is set to diff pickup residential
    state.differentPickupAddressResidential.value = isSenderPayer ? false : templateData.sresidential;
  } else {
    // diff pickup  address is false, hence diff pickup residential also false
    state.pickupFromDifferentAddress.value = false;
    state.differentPickupAddressResidential.value = false;
    // residential add set to pickup residential
    state.pickupAddressResidential.value = isSenderPayer ? false : templateData.sresidential;
  }

  // diff delivery address and residential case
  if (templateData.rdifferent) {
    // diff delivery address is true <-> delivery residential is false
    state.deliverToDifferentAddress.value = true;
    state.deliveryAddressResidential.value = false;
    // since diff delivery address is true; residential value is set to diff delivery residential
    state.differentDeliveryAddressResidential.value = isReceiverPayer ? false : templateData.rresidential;
  } else {
    // diff delivery  address is false, hence diff delivery residential also false
    state.deliverToDifferentAddress.value = false;
    state.differentDeliveryAddressResidential.value = false;
    // residential add set to delivery residential
    state.deliveryAddressResidential.value = isReceiverPayer ? false : templateData.rresidential;
  }

  Object.assign(state, setParty(state, templateData, SENDER_PAYER, 'pickup', 'sender'));
  Object.assign(state, setParty(state, templateData, RECEIVER_PAYER, 'delivery', 'receiver'));

  if (state.pickupFromDifferentAddress.value) {
    Object.assign(state, setParty(state, templateData, SENDER_PAYER, 'differentPickup', 'differentSender'));
  } else {
    state.differentPickupCountry.value = state.pickupCountry.value;
  }

  if (state.deliverToDifferentAddress.value) {
    Object.assign(state, setParty(state, templateData, RECEIVER_PAYER, 'differentDelivery', 'differentReceiver'));
  } else {
    state.differentDeliveryCountry.value = state.deliveryCountry.value;
  }

  state.termsOfDelivery.value = templateData.termsOfDelivery || '';
  state.pickupSenderReference.value = templateData.sreference || '';
  state.deliveryReceiverReference.value = templateData.rreference || '';

  // account
  Object.assign(state, setImportSectionData(state, templateData));

  // special case for quote tool - international - B2C Products - residential checkbox disable
  if (
    state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
    state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE &&
    state.shipmentPayer.value === RECEIVER_PAYER &&
    templateData.rresidential
  ) {
    state.deliveryAddressResidential.value = '';
    state.differentDeliveryAddressResidential.value = '';
  }

  // set payer code
  state.payerCode = templateData.termsOfDelivery || getPayerCode(state);
  // completed Route 1st tab (set completion percentage)
  state.percentage += percentageOfEachTab;
  setPercentageCompletion(context, state);

  // SHIPMENT DETAILS - 2nd Tab
  if (templateData.savedShipmentPieces) {
    [state.shipmentDetailsRows, state.totals] = await setShipmentData(
      templateData.savedShipmentPieces,
      templateData?.totalLDM,
    );
    // completed Shipment Details 2nd tab (set completion percentage)
    state.percentage += percentageOfEachTab;

    setPercentageCompletion(context, state);
  }

  // tab 4 - display info only when no error in 3rd screen && return product should be false
  if (!state.error && !state.returnProdStatus) {
    //Schedule Pickup
    state.pickupOption.value = templateData.schedulePickup
      ? 'Schedule Pickup'
      : templateData.dropAtDHL
      ? 'Drop off a package at a DHL location'
      : templateData.useExistingPickup
      ? 'Use an already scheduled pickup at my location'
      : '';
  }

  // instructions
  state.deliveryInstructions.value = templateData.deliveryInstruction || '';
  state.pickupInstructions.value = templateData.pickupInstruction || '';

  state.addressFilledFromCookie = true;

  state.sentReference = { value: getSentReference(templateData), error: false };
  state.sentCarrierKey = { value: getSentCarrierKey(templateData), error: false };
  state.ekaerFree = { value: isEkaerFree(templateData), error: false };
  state.ekaerNumber = { value: getEkaerNumber(templateData), error: false };

  // email validation
  const emailObject = {};
  contactEmailMandatoryForInternational(emailObject, { state: state });
  state = { ...state, ...emailObject };

  context.updateState(state);
  closePercentageBar(context);
};

export const fillDeliveryOptionsData = async (context, countryConfig) => {
  const { state } = context;
  const { templateData } = state;
  const stateToSet = JSON.parse(JSON.stringify(state));
  let templateProductAvailable = false;

  // template contains pre selected product
  if (templateData.productID) {
    stateToSet.selectedProduct = state.matchedProducts.find(product => product.code === templateData.productID) ?? {
      code: '',
    };

    // Flag to display the warning message
    templateProductAvailable = !!(stateToSet.selectedProduct && stateToSet.selectedProduct.code);
  }

  if (!stateToSet.selectedProduct && state.matchedProducts.length === 1) {
    // if available prods length === 1, then pre select
    stateToSet.selectedProduct = state.matchedProducts[0] ?? { code: '' };
  }

  if (stateToSet.selectedProduct && stateToSet.selectedProduct.code) {
    Object.assign(stateToSet, await setAdditionalServices(stateToSet, templateData, countryConfig));

    stateToSet.isb2cProduct = config.b2cProducts.indexOf(stateToSet.selectedProduct.code) !== -1;

    if (templateData.notifyByEmail) {
      const notifyEmail = stateToSet.notificationsForReceiver.find(notification => notification.type === 'email');
      notifyEmail.value = true;
    } else if (templateData.notifyBySMS) {
      const notifySms = stateToSet.notificationsForReceiver.find(notification => notification.type === 'sms');
      notifySms.value = true;
    }
  } else if (templateData.productID) {
    // set product not available warning message
    stateToSet.selectedProduct = { code: '' };
    stateToSet.preSelectedProductId = templateData.productID || '';
    stateToSet.missingVASArrNames = [];
    stateToSet.preSelectedVasNotAvailable = '';
  }

  stateToSet.preSelectedTemplateNotAvailable =
    templateData.productID && !templateProductAvailable ? "'" + stateToSet[templateData.productID].name + "'" : '';

  return stateToSet;
};

export const servicePtResAddress = stateToSet => {
  const { templateData } = stateToSet;
  // service point - START
  if (stateToSet.isb2cProduct) {
    // use residential is checked ? 'Delivery address' : 'Service Point Id'
    stateToSet.servicePointId = templateData.useResidential ? RECEIVER_PAYER : templateData.servicePoint;
  }
  stateToSet.servicePointUseResidentialAddress.isProductServicePointDelivery = isDomesticReturnProduct(
    templateData.productID,
  );
  stateToSet.servicePointUseResidentialAddress.disabled = checkIfUseResidentialAddressCanBeDisabled(
    templateData.productID,
    stateToSet,
  );
  stateToSet.servicePointUseResidentialAddress.value = !stateToSet.servicePointUseResidentialAddress.disabled
    ? templateData.useResidential || false
    : false;

  if (stateToSet.servicePointId === RECEIVER_PAYER) {
    stateToSet.selectedServicePoint = getResidentialServicePoint(stateToSet);
  }
  // service point - END
};

const setImportSectionData = (previousCtxState, templateData) => {
  const stateToSet = JSON.parse(JSON.stringify(previousCtxState));

  stateToSet.shipmentRange.value = templateData.route;
  stateToSet.shipmentTypesImpOrExp.value = templateData.importExport;
  stateToSet.prevShipmentTypesImpOrExp.value = templateData.importExport;
  stateToSet.shipmentPayer.value = templateData.whoPay;

  stateToSet.accounts = filterAccountsBasedOnShipmentRange(stateToSet.originalAccounts, stateToSet.shipmentRange.value);

  // Fix for International Import
  // Note: international import doesn't allow sender to pay
  if (
    stateToSet.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
    stateToSet.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE &&
    stateToSet.shipmentPayer.value === SENDER_PAYER
  ) {
    stateToSet.shipmentPayer.value = RECEIVER_PAYER;
  }

  const opts = readState(stateToSet);
  const casePayerCode = payerCode(opts);

  if (casePayerCode !== 'case9') {
    Object.assign(previousCtxState, stateToSet);
    setAccountInfo(casePayerCode, previousCtxState, stateToSet);
  }

  const senderAccount = stateToSet.accounts.some(account => account.code === templateData.senderAccount)
    ? templateData.senderAccount
    : stateToSet?.accountNumber?.value ?? '';

  if (stateToSet.accountNumber.display && stateToSet.receiverNumber.display) {
    // sender account from account list
    if (senderAccount) {
      stateToSet.accountNumber.value = senderAccount;
      stateToSet.receiverNumber.value = templateData.otherAccount;
    } else {
      // receiver account from account list
      stateToSet.accountNumber.value = templateData.senderAccount;
      stateToSet.receiverNumber.value = templateData.otherAccount;
    }

    stateToSet.accountNumber.isSelect = templateData.isSenderAccSelect || false;
    stateToSet.receiverNumber.isSelect = templateData.isOtherAccSelect || false;
  } else if (stateToSet.accountNumber.display) {
    stateToSet.accountNumber.value = senderAccount;
    stateToSet.accountNumber.isSelect = templateData.isSenderAccSelect || false;
  } else if (stateToSet.receiverNumber.display) {
    stateToSet.receiverNumber.value = senderAccount;
    stateToSet.receiverNumber.isSelect = templateData.isOtherAccSelect || false;
  }

  if (stateToSet.thirdPartyNumber.display) {
    stateToSet.thirdPartyNumber.value = templateData.otherAccount;
    stateToSet.thirdPartyNumber.isSelect = templateData.isOtherAccSelect || false;
  }

  stateToSet.accountNumber.value = stateToSet.accountNumber.value || '';
  stateToSet.receiverNumber.value = stateToSet.receiverNumber.value || '';
  stateToSet.thirdPartyNumber.value = stateToSet.thirdPartyNumber.value || '';

  setCustomsFlagForTemplateData(stateToSet);

  return stateToSet;
};

const setCustomsFlagForTemplateData = stateToSet => {
  if (stateToSet.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) {
    const selectedCountry = getCountryForCustoms(stateToSet);
    const customsCountry = findCustomBasedCountry(stateToSet, selectedCountry);
    stateToSet['countryBasedCustoms'] = customsCountry && customsCountry.customs;
  }
};

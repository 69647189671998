import { config } from '../../../data-config';
import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';

export const schedulePickupValidations = (state, stateToSet, hasErrors, atLeastOneFieldFilled) => {
  let getState = {};

  if (!state.pickupOption.value) {
    atLeastOneFieldFilled = atLeastOneFieldFilled || false;
  } else {
    atLeastOneFieldFilled = atLeastOneFieldFilled || true;
  }

  if (state.pickupOption.value === 'schedulePickup') {
    // Pickup Instructions
    getState = checkFieldAndReturnState({
      name: 'pickupInstructions',
      value: state.pickupInstructions.value,
      regEx: config.regEx.everything,
      lengthCheck: [RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)],
      isRequired: false,
    });

    hasErrors = getState.error || hasErrors;
    stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: false };
  }

  getState = checkFieldAndReturnState({
    name: 'deliveryInstructions',
    value: state.deliveryInstructions.value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)],
    isRequired: false,
  });

  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: false };
  atLeastOneFieldFilled = atLeastOneFieldFilled || getState.value ? true : false;

  return {
    stateToSet,
    hasErrors,
    atLeastOneFieldFilled,
  };
};

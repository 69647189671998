import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiLinks } from '../../../config/api-config';
import { config } from '../../../dashboard/data-config';
import { CONST, downloadApiRequest } from '../../../globals';
import { Loader } from '../Loader';

export const SelectAll = ({ selectAll, onSelectAll, selectedShipments }) => {
  const { t } = useTranslation();
  const [selectedAction, setSelectedAction] = useState('');
  const [actionLoading, setActionLoading] = useState(false);
  const [actionError, setActionError] = useState();

  const availableActions = useMemo(() => {
    if (selectedShipments.some(shipment => shipment.status === CONST.DELIVERED)) return [];
    /**
     * The following filter here is only for reference because business
     * wanted to include all actions even if there are no relevant shipments.
     *   return config.printDocumentTypes.filter(documentType =>
     *     selectedShipments.every(shipment =>
     *       shipment.documents.some(document => document.filename.includes(documentType.type)),
     *     ),
     *   );
     */
    return config.printDocumentTypes;
  }, [selectedShipments]);

  useEffect(() => {
    if (!selectedShipments.length && selectedAction) {
      setSelectedAction('');
    }
  }, [selectedShipments, selectedAction]);

  const resolveAction = async docType => {
    setActionLoading(true);
    setActionError(undefined);
    const filename = `${docType}.pdf`;
    const url = apiLinks.downloadDocuments;

    const shipmentsToPrint = selectedShipments.map(item => {
      let documents = [...item.documents];

      // filter out doc types if doctype presented
      if (docType) {
        documents = documents.filter(docItem => docItem.filename.includes(docType));
      }

      return {
        id: item.id,
        shipmentNumber: item.shipmentId,
        printDocumentDTOS: documents,
        uniqueCode: item.uniqueCode,
      };
    });

    const payload = {
      category: config.printDocumentsCategory.shipmentDocuments,
      docType: docType,
      data: shipmentsToPrint,
    };

    await downloadApiRequest(url.toString(), 'POST', {
      body: payload,
      headers: { 'content-Type': 'application/json' },
    }).then(blob => {
      if (!blob.message || blob.status < 400) {
        const file = new Blob([blob], {
          type: blob.type,
        });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file);
          return;
        }

        const urlU = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = urlU;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        if (blob.status === 422) {
          setActionError(t(`portalSavedShipments|No reports available to be printed`));
        } else {
          setActionError(t(`general|errors|General error`));
        }
      }
      setActionLoading(false);
      setSelectedAction('');
    });
  };

  const handleActionChange = event => {
    const action = event.target.value;
    setSelectedAction(action);
    resolveAction(action);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        ml: '16px',
        mb: '16px',
      }}
    >
      <FormControlLabel
        control={<Checkbox checked={selectAll} onChange={onSelectAll} />}
        label={t('portalAddressBook|Select All')}
        sx={{
          flexShrink: 0,
          mr: 0,
        }}
      />
      <FormControl
        fullWidth
        size="small"
        sx={{
          maxWidth: '250px',
        }}
        disabled={!selectedShipments.length || actionLoading || !availableActions.length}
      >
        <InputLabel id="action-selector-label">{t('general|selectAction')}</InputLabel>
        <Select
          labelId="action-selector-label"
          id="action-select"
          value={selectedAction}
          label={t('general|selectAction')}
          onChange={handleActionChange}
          IconComponent={
            selectedAction
              ? () => (
                  <IconButton
                    size="small"
                    onClick={e => {
                      setSelectedAction('');
                    }}
                    sx={{
                      mr: '2px',
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )
              : undefined
          }
        >
          {availableActions.map(item => {
            return (
              <MenuItem key={item.type} value={item.type}>
                {t(`general|${item.label}`)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {actionLoading ? (
        <Loader
          sx={{
            '& svg': {
              width: '40px',
              height: '40px',
            },
          }}
        />
      ) : null}
      {actionError ? (
        <Alert variant="standard" severity="error" sx={{ py: '2px' }}>
          {actionError}
        </Alert>
      ) : null}
    </Box>
  );
};

export const checkIfAtLeastOneAdditionalServicesIsSelected = (context, codes) => {
  let result = false;

  if (codes) {
    codes.forEach(code => {
      const additionalServicePresent = context.state.additionalServices.find(additionalService => {
        return additionalService.code === code;
      });

      if (additionalServicePresent && !!additionalServicePresent.value) {
        result = true;
      }
    });
  }

  return result;
};

export const checkIfAllAdditionalServicesCodesMatch = (context, codes) => {
  let result = true;

  if (codes) {
    codes.forEach(code => {
      const additionalServicePresent = context.state.additionalServices.find(additionalService => {
        return additionalService.code === code;
      });

      if (!additionalServicePresent || !additionalServicePresent.value) {
        result = false;
      }
    });
  }

  return result;
};

export const afterSelectAdditionalServices = (
  context,
  additionalServicesToBeSelected,
  additionalServicesToBeUnSelected,
  checkBoxStatus,
) => {
  const additionalServices = [...context.state.additionalServices];

  additionalServices.forEach((additionalService, ind) => {
    const VASToBeChecked =
      additionalServicesToBeSelected &&
      additionalServicesToBeSelected.some(code => {
        return code === additionalService.code;
      });

    if (VASToBeChecked) {
      additionalServices[ind].disabled = checkBoxStatus;
      if (checkBoxStatus) additionalServices[ind].value = true;
    }
  });

  !checkBoxStatus &&
    additionalServices.forEach((additionalService, ind) => {
      const VASToBeUnChecked =
        additionalServicesToBeUnSelected &&
        additionalServicesToBeUnSelected.some(code => {
          return code === additionalService.code;
        });

      if (VASToBeUnChecked) {
        additionalServices[ind].disabled = false;
        additionalServices[ind].value = false;
      }
    });

  context.updateState(additionalServices);
};

export const combineWithFunctionality = (contextFn, params, config) => {
  // TODO: It is better in the future, this data to come from API validation services not manually coded

  if (params.name) {
    // console.log(config.mustBeCombinedWithVASRules);
    const rules = config.mustBeCombinedWithVASRules.find(vasRules => {
      return vasRules.code === params.name;
    });

    if (rules) {
      // Case 0: mandatory VASes to be selectedProduct
      const mandatoryCodes =
        rules.mandatoryToBeSelected && rules.mandatoryToBeSelected.length > 0 ? rules.mandatoryToBeSelected : false;
      if (mandatoryCodes) afterSelectAdditionalServices(contextFn, rules.mandatoryToBeSelected, null, params.value);

      // Case 1: at least one additional VAS is selected
      const atLeastOneCode =
        rules.atLeastOneCode && rules.atLeastOneCode.codes
          ? checkIfAtLeastOneAdditionalServicesIsSelected(contextFn, rules.atLeastOneCode.codes)
          : false;
      if (atLeastOneCode)
        afterSelectAdditionalServices(contextFn, rules.atLeastOneCode.toBeSelected, null, params.value);

      // Case 2: all additional VASes must be selected
      const allCodes =
        rules.allCodes && rules.allCodes.codes
          ? checkIfAllAdditionalServicesCodesMatch(contextFn, rules.allCodes.codes)
          : false;
      if (allCodes) afterSelectAdditionalServices(contextFn, rules.allCodes.toBeSelected, null, params.value);

      // Case 3: to unselected
      const unselectCodes =
        rules.mandatoryToUnselect && rules.mandatoryToUnselect.length > 0 ? rules.mandatoryToUnselect : false;
      if (unselectCodes) afterSelectAdditionalServices(contextFn, null, rules.mandatoryToUnselect, params.value);
    }
  }
};

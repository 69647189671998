import { Box } from '@mui/material';
import { Checkbox, Error, Radio, Select } from 'components';
import { sortCountriesAlpha } from 'globals/data-sorted-countries';
import { analytics } from 'globals/utils/analytics';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { POSTALCODE_ERRORS, PostalCodeInput } from '../../../components/fcp-components/PostalCodeInput';
import {
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
} from '../../../globals/constants';
import { userQueryKey } from '../../../hooks/api/useGetUser';
import { withQueryClient } from '../../../utils/withQueryClient';
import { CONST, config } from '../../data-config';
import { resetDeliveryOptions, resetShipmentDetails } from '../../helpers/resetState';
import { withQuote } from '../../useQuote';
import { CityBox } from './CityBox';
import {
  afterUpdateForSelectCountry,
  afterUpdateMarketingAvailAbilityAndSelectReset,
  afterUpdateProductRange,
  checkSubmit,
  domesticPostalCodeValidation,
  getCookieCountryAndPostalCode,
  getPickupAndDeliveryDateForProducts,
  getQuoteProductsRangeCookie,
  getUsersAccountsAndProductsData,
  internationalPostalCodeValidation,
  populateProductsPerAccountSelect,
  setAccountWithProductsIfOnlyOne,
  setCookieCountryAndPostalCode,
  setQuoteProductsRangeCookie,
  updatePayerCodeAndResetProducts,
} from './EnterPickupLocation-helpers';
import './EnterPickupLocation.css';

class EnterPickupLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userDataIsLoading: false,
      validationError: false,
      apiError: false,
      nextBtnLoader: false,
      sourcePostalCodeError: false,
      destinationPostalCodeError: false,
      userAPIError: false,
      eurapidLtcError: false,
      productNotSupportedError: false,
      loader: true,
    };
  }

  getUsersData = async () => {
    await getUsersAccountsAndProductsData(
      this.props.context,
      newLocalState => this.setState(newLocalState),
      this.props.useQuoteData.enabledAccounts,
    );
  };

  componentDidMount = async () => {
    // Initial user accounts data load
    await this.getUsersData();
    // country from is the account country FCPG-77
    const userAccountCountry = this.props.queryClient.getQueryData([userQueryKey])?.user?.accountCountryCode;
    this.props.context.updateState({
      userAccountCountry,
    });
    await getCookieCountryAndPostalCode(this.props.context, userAccountCountry);

    if (!this.props.context.state.productsRangeAll.disabled) {
      // check cookie only when productsRangeAll is enabled
      await getQuoteProductsRangeCookie({
        context: this.props.context,
        userEmail: this.props.queryClient.getQueryData([userQueryKey])?.user?.email,
      });
    }

    if (!this.props.context.state.pickupCountry.value) {
      this.props.context.updateState(
        {
          pickupCountry: {
            value: userAccountCountry?.toUpperCase(),
            error: false,
            isRequired: true,
          },
        },
        () =>
          afterUpdateForSelectCountry(
            this.props.context,
            {
              name: 'pickupCountry',
              value: userAccountCountry?.toUpperCase(),
              isRequired: true,
              compare: false,
              relatedField: 'pickupPostalCode',
            },
            userAccountCountry,
          ),
      );
    }
    if (!this.props.context.state.deliveryCountry.value) {
      this.props.context.updateState(
        {
          deliveryCountry: {
            value: userAccountCountry?.toUpperCase(),
            error: false,
            isRequired: true,
          },
        },
        () =>
          afterUpdateForSelectCountry(
            this.props.context,
            {
              name: 'deliveryCountry',
              value: userAccountCountry?.toUpperCase(),
              isRequired: true,
              compare: false,
              relatedField: 'deliveryPostalCode',
            },
            userAccountCountry,
          ),
      );
    }

    // Resets next steps
    resetShipmentDetails(this.props.shipmentContext);
    resetDeliveryOptions(this.props.deliveryContext);
    this.setState({ loader: false });

    setAccountWithProductsIfOnlyOne(this.props.context, this.props.context.state);
  };

  onGoClick = async context => {
    const { state } = context;
    const stateToSet = {
      apiError: false,
      goNextError: true,
      sourcePostalCodeError: false,
      destinationPostalCodeError: false,
      validationError: false,
      deviating: false,
      eurapidLtcError: false,
      productNotSupportedError: false,
    };

    // Initial validation check
    stateToSet.validationError = checkSubmit(context);

    this.setState({ nextBtnLoader: true, validationError: stateToSet.validationError }, async () => {
      stateToSet.goNextError = false;
      stateToSet.productNotSupportedError = !this.checkProductsAvailable(
        context.state?.productSpecifications,
        context.state.shipmentRange?.value,
      );

      // Validates zip codes
      if (state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE) {
        await domesticPostalCodeValidation(state, stateToSet, context);
      } else {
        context.updateState({ deviating: false }, async () => {
          //Moved validation out of callback to await properly
        });
        await internationalPostalCodeValidation(state, stateToSet);
      }

      // Distinguish all other errors from the api error
      stateToSet.generalError =
        stateToSet.validationError || stateToSet.sourcePostalCodeError || stateToSet.destinationPostalCodeError;
      stateToSet.generalError = stateToSet.apiError ? false : stateToSet.generalError;

      // Put proper payer code in the context
      await updatePayerCodeAndResetProducts(state, context);

      // Get Pickup and Delivery dates (if not deviating in case of domestic)
      if (!stateToSet.generalError && !stateToSet.deviating && !stateToSet.apiError) {
        await getPickupAndDeliveryDateForProducts(state, stateToSet, context);
      }

      // Clear loader and set local state
      stateToSet.nextBtnLoader = false;
      this.setState(stateToSet);

      // Go to #2 screen if no error
      if (
        !stateToSet.goNextError &&
        !stateToSet.generalError &&
        !stateToSet.apiError &&
        !stateToSet.eurapidLtcError &&
        !stateToSet.productNotSupportedError
      ) {
        // Update statistics
        analytics('step 1', CONST.ANALYTICS);

        // Stores the input data for the sender
        setCookieCountryAndPostalCode(context);
        setQuoteProductsRangeCookie({
          context,
          userEmail: this.props.queryClient.getQueryData([userQueryKey])?.user?.email,
        });

        // Switch to #2 screen
        this.props.nextTab(config.tabs.tab2);
      }
    });
  };

  // check if there are some products available for selected
  // shipment range
  checkProductsAvailable = (productSpecifications, shipmentRange) => {
    const isInternational = shipmentRange === INTERNATIONAL_SHIPMENT_RANGE;
    const isDomestic = shipmentRange === DOMESTIC_SHIPMENT_RANGE;
    let allDomestic = true;
    let allInternational = true;

    if (!productSpecifications || Object.keys(productSpecifications)?.length === 0) return false;

    for (const product of Object.values(productSpecifications)) {
      if (product.isDomestic === true) {
        allInternational = false;
      }
      if (product.isDomestic === false) {
        allDomestic = false;
      }
    }

    return (!allDomestic && !allInternational) || (allInternational && isInternational) || (allDomestic && isDomestic);
  };

  render() {
    const { context, enableResidentialAddress, enableResidentialAddressInitially, enableResidentialToAddress } =
      this.props;

    if (this.state.loader) {
      return <div className={`frc__generic--loader-overlay is-loading`} />;
    }

    return (
      <>
        <div className="frc__generic-section--wrapper">
          <Translation>
            {t => {
              const products = context.state.productsPerAccount?.map(item => {
                return {
                  ...item,
                  name: t(`products|${item.code}|name`),
                };
              });
              return (
                <>
                  <div className="l-grid l-grid--between-s frc__generic--field-wrapper ">
                    <div className="l-grid--w-100pc-s l-grid--w-48pc-m margin-bottom-1">
                      <h4 className="margin-bottom-1 frc__red-lined-title">{t('general|From')}</h4>
                      <div className="frc__generic--background-white frc__generic-section--wrapper">
                        <Box
                          sx={{
                            display: 'flex',
                            '& > *': {
                              flex: '1 1 0',
                            },
                          }}
                        >
                          <Box>
                            <Select
                              selectClass="l-grid--w-100pc-s"
                              label={t(`general|Country`)}
                              name="pickupCountry"
                              isRequired={context.state.pickupCountry.isRequired}
                              values={
                                context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE ||
                                context.state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE
                                  ? sortCountriesAlpha({ ignoreSelectCountry: true })
                                  : sortCountriesAlpha({ ignoreSelectCountry: true }).filter(
                                      c => c.code?.toUpperCase() === context.state.userAccountCountry?.toUpperCase(),
                                    )
                              }
                              value={context.state.pickupCountry.value?.toUpperCase()}
                              context={context}
                              config={config}
                              CONST={{
                                API_DOMAIN: 'countries',
                              }}
                              afterUpdate={afterUpdateForSelectCountry}
                              relatedField={'pickupPostalCode'}
                            />
                            {context.state.pickupCountry.error ? (
                              <Error
                                name="pickupCountry"
                                className="frc__input--error"
                                message={t(`general|Country Error`)}
                              />
                            ) : (
                              ''
                            )}
                            {context.state.pickupCountry.comparisonError ? (
                              <Error
                                name="pickupCountrycomparisonError"
                                className="frc__input--error"
                                message={t(`general|To From Country Error`)}
                              />
                            ) : (
                              ''
                            )}
                          </Box>
                          <PostalCodeInput
                            value={context.state.pickupPostalCode.value}
                            name="pickupPostalCode"
                            placeholder={`${t('quoteTool|e.g.')} ${context.state.pickupPostalCode.placeholder}`}
                            isRequired={context.state.pickupPostalCode.isRequired}
                            countryCode={context.state.pickupCountry.value?.toUpperCase()}
                            error={
                              context.state.pickupPostalCode.error
                                ? POSTALCODE_ERRORS.validationError
                                : this.state.sourcePostalCodeError
                                ? POSTALCODE_ERRORS.apiError
                                : null
                            }
                            afterChangeOnBlur={async ({ props, handleChange }) => {
                              handleChange(props.value, props.context);
                              this.setState({ sourcePostalCodeError: false });
                              this.props.loadCityFrom({
                                postalCode: props.value,
                                countryCode: context.state.pickupCountry.value?.toUpperCase(),
                              });
                            }}
                            context={context}
                            config={config}
                            CONST={CONST}
                          />
                          {context.state.userAccountCountry?.toUpperCase() === 'SE' &&
                            context.state.pickupCountry.value?.toUpperCase() !== 'IE' && (
                              <CityBox
                                value={
                                  context.state.pickupCountry.value?.toUpperCase() !== 'BE'
                                    ? this.props.cityFrom.value
                                    : ''
                                }
                                loading={this.props.cityFrom.loading}
                              />
                            )}
                        </Box>
                        <div className="frc__generic--field-wrapper">
                          <Checkbox
                            labelAfter={
                              '&nbsp;<span class="has-tooltip"> ' +
                              '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                              t('general|Pickup residential address tooltip') +
                              '</div></div>' +
                              '</span>'
                            }
                            label={t('general|This is a residential address')}
                            name="pickupAddressResidential"
                            isRequired={false}
                            checked={context.state.pickupAddressResidential.value}
                            context={context}
                            config={config}
                            CONST={CONST}
                            deselect={['deliveryAddressResidential']}
                            afterUpdate={afterUpdateMarketingAvailAbilityAndSelectReset}
                            disabled={!enableResidentialAddress && !enableResidentialAddressInitially}
                          />
                        </div>
                      </div>
                      <p className="frc__contact-details-label" />
                    </div>

                    <div className="l-grid--w-100pc-s l-grid--w-48pc-m">
                      <h4 className="margin-bottom-1 frc__red-lined-title">{t('general|To')}</h4>

                      <div className="frc__generic--background-white frc__generic-section--wrapper">
                        <Box
                          sx={{
                            display: 'flex',
                            '& > *': {
                              flex: '1 1 0',
                            },
                          }}
                        >
                          <Box>
                            <Select
                              selectClass="l-grid--w-100pc-s"
                              label={t(`general|Country`)}
                              name="deliveryCountry"
                              isRequired={context.state.deliveryCountry.isRequired}
                              values={
                                context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE ||
                                context.state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE
                                  ? sortCountriesAlpha({ ignoreSelectCountry: true })
                                  : sortCountriesAlpha({ ignoreSelectCountry: true }).filter(
                                      c => c.code?.toUpperCase() === context.state.userAccountCountry?.toUpperCase(),
                                    )
                              }
                              value={context.state.deliveryCountry.value?.toUpperCase()}
                              context={context}
                              config={config}
                              CONST={{
                                API_DOMAIN: 'countries',
                              }}
                              afterUpdate={afterUpdateForSelectCountry}
                              relatedField={'deliveryPostalCode'}
                            />
                            {context.state.deliveryCountry.error ? (
                              <Error
                                name="deliveryCountry"
                                className="frc__input--error"
                                message={t(`general|Country Error`)}
                              />
                            ) : (
                              ''
                            )}
                            {context.state.deliveryCountry.comparisonError ? (
                              <Error
                                name="deliveryCountryComparisonError"
                                className="frc__input--error"
                                message={t(`general|To From Country Error`)}
                              />
                            ) : (
                              ''
                            )}
                          </Box>
                          <PostalCodeInput
                            value={context.state.deliveryPostalCode.value}
                            name="deliveryPostalCode"
                            placeholder={`${t('quoteTool|e.g.')} ${context.state.deliveryPostalCode.placeholder}`}
                            isRequired={context.state.deliveryPostalCode.isRequired}
                            countryCode={context.state.deliveryCountry.value?.toUpperCase()}
                            error={
                              context.state.deliveryPostalCode.error
                                ? POSTALCODE_ERRORS.validationError
                                : this.state.destinationPostalCodeError
                                ? POSTALCODE_ERRORS.apiError
                                : null
                            }
                            afterChangeOnBlur={async ({ props, handleChange }) => {
                              handleChange(props.value, props.context);
                              this.setState({ destinationPostalCodeError: false });
                              this.props.loadCityTo({
                                postalCode: props.value,
                                countryCode: context.state.deliveryCountry.value?.toUpperCase(),
                              });
                            }}
                            context={context}
                            config={config}
                            CONST={CONST}
                          />
                          {context.state.userAccountCountry?.toUpperCase() === 'SE' &&
                            context.state.deliveryCountry.value?.toUpperCase() !== 'IE' && (
                              <CityBox
                                value={
                                  context.state.deliveryCountry.value?.toUpperCase() !== 'BE'
                                    ? this.props.cityTo.value
                                    : ''
                                }
                                loading={this.props.cityTo.loading}
                              />
                            )}
                        </Box>

                        <div className="frc__generic--field-wrapper">
                          <Checkbox
                            labelAfter={
                              '&nbsp;<span class="has-tooltip"> ' +
                              '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                              t(`general|Delivery residential address tooltip`) +
                              '</div></div>' +
                              '</span>'
                            }
                            label={t('general|This is a residential address')}
                            name="deliveryAddressResidential"
                            isRequired={false}
                            checked={context.state.deliveryAddressResidential.value}
                            context={context}
                            config={config}
                            CONST={CONST}
                            deselect={['pickupAddressResidential']}
                            afterUpdate={afterUpdateMarketingAvailAbilityAndSelectReset}
                            disabled={
                              (!enableResidentialAddress && !enableResidentialAddressInitially) ||
                              !enableResidentialToAddress
                            }
                          />
                        </div>
                      </div>
                      <p className="frc__contact-details-label" />
                    </div>

                    <div className=" l-grid--w-100pc-s frc__generic--field-wrapper ">
                      <h4 className="margin-bottom-0 frc__red-lined-title">
                        {t(`quoteTool|How do you want to get your quote?`)}
                      </h4>

                      {this.state.userDataIsLoading ? (
                        <div className="is-loading--left mini-loading-height"></div>
                      ) : !this.state.userAPIError ? (
                        <>
                          <div className="frc__generic--field-wrapper">
                            <Radio
                              config={config}
                              CONST={CONST}
                              context={context}
                              label={t(`quoteTool|Show me all available options`)}
                              tooltip={
                                !context.state.productsRangeAll.disabled
                                  ? t(`quoteTool|Select all products tooltip`)
                                  : t(`quoteTool|Select all products tooltip disabled`)
                              }
                              name="productsRangeAll"
                              isRequired={false}
                              value={context.state.productsRangeAll.value}
                              checked={context.state.productsRangeAll.value}
                              deselect={['productsRangeOne']}
                              disabled={context.state.productsRangeAll.disabled}
                              // updateOnParent={(params, context) => this.update(params, context)}
                              afterUpdate={(context, params) => afterUpdateProductRange(context, params)}
                            />
                          </div>
                          <div className="frc__generic--field-wrapper">
                            <Radio
                              config={config}
                              CONST={CONST}
                              context={context}
                              label={t(`quoteTool|Let me select a specific product from my account`)}
                              tooltip={t(`quoteTool|Select one product tooltip`)}
                              name="productsRangeOne"
                              isRequired={false}
                              value={context.state.productsRangeOne.value}
                              checked={context.state.productsRangeOne.value}
                              deselect={['productsRangeAll']}
                              // updateOnParent={(params, context) => this.update(params, context)}
                              afterUpdate={(context, params) => afterUpdateProductRange(context, params)}
                            />
                          </div>

                          {this.props.context.state.productsRangeOne.value && (
                            <div className="l-grid l-grid--w-100pc-s l-grid l-grid--w-50pc-m frc__generic--field-wrapper">
                              <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
                                <Select
                                  selectClass="l-grid--w-100pc-s"
                                  label={t(`general|Account Number`)}
                                  name="accountNumber"
                                  isRequired={true}
                                  values={context.state.accounts}
                                  value={context.state.accountNumber.value}
                                  skipTranslation={true}
                                  context={context}
                                  config={config}
                                  CONST={CONST}
                                  afterUpdate={(context, params) =>
                                    populateProductsPerAccountSelect(context, context.state)
                                  }
                                  defaultOption={
                                    context.state.accounts?.length === 1
                                      ? null
                                      : { name: t('general|Select Account Number'), value: '' }
                                  }
                                  disabled={false}
                                />
                                {context.state.accountNumber.error ? (
                                  <Error
                                    name="accountNumber"
                                    className="frc__input--error"
                                    message={t(`quoteTool|Select an account number`)}
                                  />
                                ) : (
                                  ''
                                )}
                              </div>

                              {context.state.accountNumber.value && (
                                <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
                                  <Select
                                    selectClass="l-grid--w-100pc-s"
                                    label={t(`products|common|productLabel`)}
                                    name="selectedProduct"
                                    isRequired={true}
                                    values={products}
                                    value={context.state.selectedProduct.value}
                                    context={context}
                                    config={config}
                                    skipTranslation
                                    defaultOption={{ name: t(`products|common|dropdownLabel`), value: '' }}
                                  />
                                  {context.state.selectedProduct.error ? (
                                    <Error
                                      name="selectedProduct"
                                      className="frc__input--error"
                                      message={t(`products|common|dropdownLabel`)}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="frc__generic--field-wrapper">
                          <button
                            id="fetchAccountsDataBtn"
                            className={`base-button base-button--white l-grid--w-25pc-w`}
                            onClick={() => this.getUsersData()}
                          >
                            <span>{t(`quoteTool|Click here to fetch accounts data`)}</span>
                          </button>
                        </div>
                      )}

                      <div className="frc__generic--field-wrapper">
                        {context.state.productsRangeError.error ? (
                          <Error
                            name="productRangeError"
                            className="frc__input--error"
                            message={t(`quoteTool|Select one of the options above`)}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className="l-grid l-grid--w-100pc-s l-grid--right-s">
                      <div className="l-grid--w-100pc-s l-grid--w-48pc-m l-grid--left-s">
                        <div className="margin-bottom-1"></div>
                      </div>
                    </div>

                    <div className="l-grid l-grid--w-100pc-s">
                      {!this.state.userAPIError && (
                        <div className="l-grid--w-100pc-s frc__push-bottom">
                          <button
                            id="goToShipmentDetailsBtn"
                            data-testid="next-button"
                            className={
                              'base-button base-button--wide base-button--pull-right l-grid--w-25pc-w' +
                              (this.state.nextBtnLoader ? ' is-loading--right ' : '')
                            }
                            onClick={() => this.onGoClick(context, this.props)}
                            disabled={this.state.nextBtnLoader}
                          >
                            <span>{t('general|labels|buttons|Next Step')}</span>
                          </button>
                        </div>
                      )}

                      <div className="l-grid--w-100pc-s l-grid--right-s">
                        {this.state.productNotSupportedError && (
                          <div className="frc__proceed-to-order-error">
                            <Error
                              name="disableProceedToBookingError"
                              message={t('quoteTool|Proceed to Booking Error')}
                            />
                          </div>
                        )}
                        {this.state.apiError || this.state.generalError || this.state.userAPIError ? (
                          <Error
                            name="RouteMultipleError"
                            message={
                              this.state.generalError
                                ? t('general|errors|Please fill properly all necessary fields')
                                : this.state.apiError || this.state.userAPIError
                                ? t('general|errors|General error')
                                : ''
                            }
                          />
                        ) : (
                          this.state.eurapidLtcError && (
                            <Error
                              name="eurapidLtcError"
                              message={t(`quoteTool|For selected postal codes product is not available`)}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          </Translation>
        </div>
      </>
    );
  }
}

export default withQuote(withQueryClient(EnterPickupLocation));

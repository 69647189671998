import { config } from '../../../data-config';
import { setShipmentDimensionsForProductShowAll } from '../../../../globals/helpers/farmapi-validations';

export const resetShipmentType = (selectedProducts, isSweden, favouriteColliTypes) => {
  let shipmentType = {};

  if (selectedProducts && selectedProducts[0] === 'dhlPall') {
    shipmentType = JSON.parse(JSON.stringify(config.shipmentTypesFields['full pallet']));
  } else if (isSweden) {
    shipmentType = JSON.parse(JSON.stringify(config.shipmentTypesFields['parcel']));
  } else if (Array.isArray(favouriteColliTypes) && favouriteColliTypes.length) {
    const favouriteShipmentName = config.shipmentTypes.find(colli => colli.dataRel === favouriteColliTypes[0])?.value;
    shipmentType = JSON.parse(
      JSON.stringify(config.shipmentTypesFields[favouriteShipmentName] || config.shipmentTypesFields['carton/package']),
    );
  } else {
    shipmentType = JSON.parse(JSON.stringify(config.shipmentTypesFields['carton/package']));
  }

  shipmentType.dangerousGoodGroup = JSON.parse(JSON.stringify(config.dangerousGoodGroup));

  return shipmentType;
};

export const getUpdatedDimensions = context => {
  const stateObject = {};
  stateObject.dimensions = JSON.parse(JSON.stringify(config.dimensions)); //structure def
  [stateObject.dimensions, stateObject.shipmentRowsNumberMax] = setShipmentDimensionsForProductShowAll(
    context.state,
    config,
    context.state.productSpecifications ? Object.values(context.state.productSpecifications) : [],
  );

  return stateObject;
};

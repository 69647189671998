import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../Template-Address.css';

export default function TemplateCard({ context, template, handleClick, isReceiver, showFavorites }) {
  const { t } = useTranslation();
  const party = isReceiver ? template.receiver : template.sender;

  return (
    <div
      key={template.id}
      className={`frc__addresspickup-card ${
        showFavorites ? 'frc__neweset-templates-width' : 'frc__addresspickup-card-width'
      }`}
      onClick={() => handleClick(template.id, context)}
    >
      {template.templateName && template.templateName.length > 0 ? (
        <>
          <div>
            <strong>{template.templateName}</strong>
          </div>
          <div>{party.companyName || ''}</div>
        </>
      ) : (
        <strong>{party.companyName || ''}</strong>
      )}

      <div>{party.name || ''}</div>
      <div>{party.street || ''}</div>
      <div>
        {party.city || ''} {party.city && party.country ? ', ' : ''}
        {party.country ? t(`countries|${party.country}`) : ''}
      </div>
    </div>
  );
}

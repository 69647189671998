import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { config } from '../../globals';
import { shouldUseCommitedDelivery } from '../../globals/helpers/product';
import { parseVasDataForDeliveryDate } from '../../globals/helpers/side-summary-helpers';

export const ProductCardDeliveryDate = ({
  productCode,
  deliveryDate,
  additionalServices,
  selectedProductCode,
  selectedPickupDate,
  productPickupDates,
}) => {
  const { t } = useTranslation();

  const { selectedFixedDeliveryDate, fixedDeliveryDate } = parseVasDataForDeliveryDate(additionalServices);
  const isCommitedDelivery = shouldUseCommitedDelivery(productCode);
  const title = isCommitedDelivery ? t('general|committedDeliveryBy') : t('general|Estimated Delivery By');

  const pickupDateNotSupportedByProduct =
    productPickupDates && selectedPickupDate && productPickupDates[productCode]
      ? !productPickupDates[productCode].includes(selectedPickupDate)
      : undefined;

  const getDeliveryDateValue = (productCode, deliveryDate) => {
    if (pickupDateNotSupportedByProduct) {
      return t('general|pickupDateNotAvailableForProduct');
    }

    if (selectedFixedDeliveryDate && fixedDeliveryDate && productCode === selectedProductCode) {
      return t('general|fixedDeliveryDateUsed');
    }
    if (config.estimatedDeliveryNotVisibleForProducts.includes(productCode)) {
      return t('general|Delivery date unavailable');
    }
    if (deliveryDate === 'ltc_error') {
      return t('general|LTC Delivery date unavailable');
    }
    if (deliveryDate) {
      return deliveryDate;
    }
    return t('general|Delivery date unavailable');
  };

  return (
    <>
      <span>{`${title}:`}</span>
      <Typography variant="body2" color="primary" fontWeight="bold" id={`${productCode}_deliveryDate`}>
        {getDeliveryDateValue(productCode, deliveryDate)}
      </Typography>
    </>
  );
};

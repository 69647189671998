import { Outlet, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Footer from '../Footer/Footer';
import { useAuth } from '../../hooks/useAuth';
import { useCountryLanguage } from '../../hooks/useCountryLanguage';
import PortalMarketingBanner from '../PortalMarketingBanner/PortalMarketingBanner';
import ConfigService from '../../globals/utils/configService';
import { globalConfig } from '../../globals/data-config-global';

import { envConfig } from '../../lib/envConfig/envConfig';
import { GlobalError, GlobalLoader, TranslationsError } from 'components';
import { TRANSLATION_FAILED_KEY } from '../../locale/i18n.js';
import { ROUTES, useRouting } from 'router';

import { CookieDisclaimer } from './CookieDisclaimer';
import { Header } from './Header';
import { PlannedMaintenance } from './PlannedMaintenance';

const ENV_DEV = process.env.NODE_ENV === 'development';

export const Layout = ({ ready }) => {
  const auth = useAuth();
  const { navigate } = useRouting();
  const { errorNumber } = useParams();
  const [underMaintenance, setUnderMaintenance] = useState(false);
  const [isTranslationError, setIsTranslationError] = useState(false);

  const { countryLanguage } = useCountryLanguage();

  useEffect(() => {
    const sessionStorageError = sessionStorage.getItem(TRANSLATION_FAILED_KEY) === 'true';
    setIsTranslationError(sessionStorageError);
  }, [ready]);

  useEffect(() => {
    if (isTranslationError) {
      sessionStorage.removeItem(TRANSLATION_FAILED_KEY);
    }
  }, [isTranslationError]);

  const checkMaintenance = async () => {
    const maintenanceActive = await ConfigService.getSystemInMaintenance();
    const result = maintenanceActive?.toLowerCase() === 'true' || envConfig().REACT_APP_UNDER_MAINTENANCE === 'true';
    setUnderMaintenance(result);
  };

  useEffect(() => {
    checkMaintenance();
    setInterval(() => {
      checkMaintenance();
    }, globalConfig.checkApplicationMaintenanceInterval);
  }, []);

  useEffect(() => {
    if (ready && !isTranslationError && underMaintenance) {
      if (ENV_DEV) {
        console.log('**** redirecting because of under maintenance');
      }
      navigate(`../${countryLanguage}/${ROUTES.underMaintenance}`);
    }
  }, [underMaintenance, ready, navigate, isTranslationError, countryLanguage]);

  if (isTranslationError) {
    return (
      <GlobalError>
        <TranslationsError />
      </GlobalError>
    );
  }

  if (underMaintenance) {
    return null;
  }

  if (!ready) {
    return (
      <div className="l-view has-no-stage">
        <GlobalLoader />;
      </div>
    );
  }

  return (
    <>
      <PlannedMaintenance />
      <CookieDisclaimer />
      <Header />
      <div className="l-view has-no-stage">
        <>
          <main className="c-mf-container component-margin">
            <Outlet />
            {auth.authenticated && !errorNumber && <PortalMarketingBanner />}
          </main>
          <Footer />
        </>
      </div>
    </>
  );
};

import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import { ChevronRightIcon } from '../../assets/icons';

import { useNavigate } from 'react-router-dom';

export const DashboardTile = ({ linkTo, title, subtitle, Icon, dense, ...rest }) => {
  const navigate = useNavigate();

  return (
    <Grid item xs={15} sm={dense ? 15 : 5} md={dense ? 3 : 5} zeroMinWidth>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'flex-start',
          px: { xs: '20px', lg: '32px' },
          pt: { xs: '20px', lg: '32px' },
          pb: dense ? { xs: '20px', md: '10px', lg: '5px' } : { xs: '20px', lg: '32px' },
          overflow: 'clip',
          height: '100%',
          ':hover': {
            boxShadow: 5,
            cursor: 'pointer',
            ' * h4': {
              color: 'text.secondary',
              textDecorationColor: theme => theme.palette.primary.main,
            },
          },
        }}
        elevation={3}
        component="button"
        onClick={() => navigate(linkTo)}
        {...rest}
      >
        <Icon
          color="primary"
          sx={{
            fontSize: 34,
            marginBottom: dense ? { xs: '20px', md: '32px', lg: '48px' } : { xs: '32px', md: '48px' },
          }}
        />
        <Stack>
          <Box display="flex" alignItems="center" gap={1} marginBottom="12px">
            <Typography
              variant="h4"
              align="left"
              sx={{
                textDecoration: 'underline rgba(0, 0, 0, 0)',
                transition: 'color 400ms  ease-in-out, text-decoration-color 300ms  ease-in-out',
                wordBreak: 'break-word',
              }}
            >
              {title}
            </Typography>
            <ChevronRightIcon color="primary" sx={{ fontSize: 15 }} />
          </Box>
          <Typography variant="body2" align="left" paragraph>
            {subtitle}
          </Typography>
        </Stack>
      </Paper>
    </Grid>
  );
};

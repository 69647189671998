import React, { Component } from 'react';
import { state } from '../state/route-state';
import { checkEmptyOrMinMaxOrLength, compareValues } from 'globals/utils/fieldValidations';
import { config } from '../data-config';
import { prepareFieldInStateForUpdate, cutTextAndShowError } from 'globals/context/context-helpers';

export const RouteContext = React.createContext();

export class RouteProvider extends Component {
  state = JSON.parse(JSON.stringify(state));

  componentDidMount = () => {
    let language = localStorage.getItem(config.locale.localStorageVariable) || config.locale.defaultLanguage;
    this.setState({
      languageCode: language,
    });
  };

  extendedSetState = (stateObject, params) => {
    let beforeUpdate = {};
    let afterUpdate = {};

    // Executes additional logic before update the state
    if (params !== undefined) {
      if (params.beforeUpdate && typeof params.beforeUpdate === 'function') {
        beforeUpdate = params.beforeUpdate(this, params, stateObject);
        if (typeof beforeUpdate === 'object' && beforeUpdate !== null) {
          beforeUpdate = JSON.parse(JSON.stringify(beforeUpdate));
          Object.assign(stateObject, beforeUpdate);
        }
      }
    }
    // Setting the new state and performs additional tasks on callback (if any)
    this.setState(stateObject, () => {
      const callbackState = {};

      // Executes additional logic after update the state
      if (params !== undefined) {
        if (params.afterUpdate && typeof params.afterUpdate === 'function') {
          afterUpdate = params.afterUpdate(this, params);
          if (typeof afterUpdate === 'object' && afterUpdate !== null) {
            afterUpdate = JSON.parse(JSON.stringify(afterUpdate));
            Object.assign(callbackState, afterUpdate);
          }
        }
      }
      if (Object.keys(callbackState).length > 0 && callbackState.constructor === Object) {
        this.setState(callbackState);
      }
    });
  };

  updateField = params => {
    let error = checkEmptyOrMinMaxOrLength(params);
    error = error === undefined ? false : error;
    let stateObject = {};

    // Text cut
    if (params.cutTextLimit && !isNaN(params.cutTextLimit) && params.cutTextLimit > 0) {
      params = cutTextAndShowError(params);
    }
    error = params.error === true ? true : error;

    let comparisonError = false;
    if (params.compare && params.compareValue !== undefined && params.compareValue !== '')
      comparisonError = compareValues(params.value, params.compareValue);

    let comparisonObj = {
      error: comparisonError,
      field: params.compareParameter,
    };

    // Update the current field
    Object.assign(
      stateObject,
      prepareFieldInStateForUpdate(
        this.state,
        params.name,
        params.value,
        error,
        params.compare ? comparisonObj : undefined,
      ),
    );

    // Overides the value of related field
    if (params.override && this.state[params.override.name]) {
      Object.assign(
        stateObject,
        prepareFieldInStateForUpdate(
          this.state,
          params.override.name,
          params.value,
          error,
          params.compare ? comparisonObj : undefined,
        ),
      );
    }

    // Deselecting related fields
    if (params.deselect) {
      params.deselect.forEach(deselect => {
        Object.assign(
          stateObject,
          prepareFieldInStateForUpdate(
            this.state,
            deselect,
            false,
            undefined,
            params.compare ? comparisonObj : undefined,
          ),
        );
      });
    }

    // Set state
    this.extendedSetState(stateObject, params);
  };

  updateGroupOfFields = params => {
    let error = checkEmptyOrMinMaxOrLength(params);
    const duplicationError = false;
    const group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    let stateObject = {};

    // Text cut
    if (params.cutTextLimit && !isNaN(params.cutTextLimit) && params.cutTextLimit > 0) {
      params = cutTextAndShowError(params);
    }
    error = params.error === true ? true : error;

    if (group[params.index].code && group[params.index].code === params.name) {
      group[params.index].selectedStatus = params.value;
    } else if (typeof group[params.index][params.name] === 'object') {
      group[params.index][params.name].value = params.value;
      group[params.index][params.name].error = false;

      if (!params.skipError) {
        group[params.index][params.name].error = error;
      }
    } else {
      group[params.index][params.name] = params.value;

      if (!params.skipError) {
        group[params.index].error = error;
        group[params.index].duplicationError = duplicationError;
      }
    }

    stateObject = {
      [params.groupName]: group,
    };

    // Set state
    this.extendedSetState(stateObject, params);
  };

  addAnotherGroupField = params => {
    const group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    let stateObject = {};

    group.push(params.item);
    stateObject[params.groupName] = group;

    // Set state
    this.extendedSetState(stateObject, params);
  };

  removeGroupField = params => {
    const group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    let stateObject = {};

    group.splice(params.index, 1);
    stateObject[params.groupName] = group;

    // Set state
    this.extendedSetState(stateObject, params);
  };

  updateState = (newState, callback) => {
    this.setState(
      {
        ...newState,
      },
      callback,
    );
  };

  render() {
    return (
      <RouteContext.Provider
        value={{
          state: this.state,
          extendedSetState: this.extendedSetState,
          updateField: this.updateField,
          updateGroupOfFields: this.updateGroupOfFields,
          addAnotherGroupField: this.addAnotherGroupField,
          removeGroupField: this.removeGroupField,
          updateState: this.updateState,
        }}
        displayName="Portal Quote Route Context"
      >
        {this.props.children}
      </RouteContext.Provider>
    );
  }
}

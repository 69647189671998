import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { toCamelCase } from 'globals/utils/formatting';
// import { CONST } from "../../../data-config";
// import { MyContext } from "../../../context/context";

import './Select.css';

export default class Select extends Component {
  constructor(props) {
    super(props);
    this.CONST = props.CONST;
    this.className = props.className || 'frc-select--wrapper frc__generic--field-wrapper';
  }

  handleChange = (value, context) => {
    const params = {
      name: this.props.name,
      value: value,
      isRequired: this.props.isRequired,
      index: this.props.index,
      reset: this.props.reset ? this.props.reset : undefined,
      compare: this.props.compare ? true : false,
      compareParameter: this.props.camparisonValue ? this.props.camparisonValue.field : undefined,
      compareValue: this.props.camparisonValue ? this.props.camparisonValue.value : undefined,
      relatedField: this.props.relatedField,
      beforeUpdate: this.props.beforeUpdate,
      afterUpdate: this.props.afterUpdate,
      callbackFunction: this.props.callback,
    };

    if (this.props.updateOnParent) {
      this.props.updateOnParent(params, context);
    } else {
      context.updateField(params);
    }
  };

  render() {
    // const { name, label, isRequired, hideAsterisk, values } = this.state;
    return (
      <>
        <Translation>
          {t => (
            <span className={this.className}>
              <label>
                {this.props.label}
                {this.props.isRequired && !this.props.hideAsterisk ? '*' : ''}
              </label>
              <select
                id={toCamelCase(this.props.id || this.props.name) + 'Slt'}
                value={this.props.value}
                name={this.props.name}
                onBlur={this.props.disableonBlur ? null : () => this.handleChange(this.props.value, this.props.context)}
                onChange={e => this.handleChange(e.target.value, this.props.context)}
                className={this.props.selectClassName || 'c-form--element-base c-form--element-select'}
                disabled={this.props.specialCase ? (this.props.values.length > 1 ? false : true) : this.props.disabled}
              >
                {this.props.defaultOption ? (
                  <option key={0} value={this.props.defaultOption.value}>
                    {this.props.skipTranslation
                      ? this.props.defaultOption.name
                      : t(`${this.CONST.API_DOMAIN}|` + this.props.defaultOption.name)}
                  </option>
                ) : (
                  ''
                )}

                {this.props.values &&
                  this.props.values.map((value, index) =>
                    value.name !== undefined ? (
                      <option
                        key={index + 1}
                        value={value.code ? value.code : value.value}
                        data-rel={value.dataRel ? value.dataRel : ''}
                        disabled={value.disabled ? true : false}
                        style={{ fontWeight: value.bold && !value.disabled && 'bold' }}
                      >
                        {this.props.skipTranslation || value.skipTranslation
                          ? value.name
                          : t(`${this.CONST.API_DOMAIN}|` + value.name)}
                      </option>
                    ) : (
                      <option key={index + 1} value={value}>
                        {this.props.skipTranslation ? value : t(`${this.CONST.API_DOMAIN}|` + value)}
                      </option>
                    ),
                  )}
              </select>
            </span>
          )}
        </Translation>
      </>
    );
  }
}

import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { CONST, config } from '../../data-config';

import EnterPickupLocation from '../EnterPickupLocation/EnterPickupLocation';
import EnterShipmentDetails from '../EnterShipmentDetails/EnterShipmentDetails';
import DeliveryOptions from '../DeliveryOptions/DeliveryOptions';
import SchedulePickup from '../SchedulePickup/SchedulePickup';
import ReviewAndBook from '../ReviewAndBook/ReviewAndBook';
import ThankYouPage from '../ThankYouPage/ThankYouPage';

import './AppTabs.css';
import SideSummaryPanel from '../../../globals/components/order/SideSummaryPanel/SideSummaryPanel';
import { resetPortalOrderSlice } from '../../../store/portalOrderSlice';
import { connect } from 'react-redux';

class AppTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab1: { active: true, enabled: true, disabled: false, completed: true },
      tab2: { active: false, enabled: true, disabled: false, completed: false },
      tab3: { active: false, enabled: true, disabled: false, completed: false },
      tab4: { active: false, enabled: true, disabled: false, completed: false },
      tab5: { active: false, enabled: true, disabled: false, completed: false },
      hideSchedulePickupTab: false,
      currentTabIndex: 1,
      displayThankYouPage: false,
      shipmentStatusData: {},
    };
  }

  componentDidMount() {
    this.props.resetPortalOrderSlice();
  }

  deActiveAllTabs(hideSchedulePickupTabCondition, disabled = false, exclude = []) {
    const stateObject = {};
    const tab1 = this.state.tab1;
    let hideSchedulePickupTab = false;

    tab1.active = false;
    tab1.disabled = disabled;
    stateObject.tab1 = tab1;

    if (hideSchedulePickupTabCondition !== undefined && hideSchedulePickupTabCondition) {
      hideSchedulePickupTab = true;
    }

    stateObject.hideSchedulePickupTab = hideSchedulePickupTab;

    if (exclude.indexOf(2) === -1) {
      const tab2 = this.state.tab2;
      tab2.active = false;
      tab2.disabled = disabled;
      stateObject.tab2 = tab2;
    }

    if (exclude.indexOf(3) === -1) {
      const tab3 = this.state.tab3;
      tab3.active = false;
      tab3.disabled = disabled;
      stateObject.tab3 = tab3;
    }

    if (exclude.indexOf(4) === -1) {
      const tab4 = this.state.tab4;
      tab4.active = false;
      tab4.disabled = disabled;
      stateObject.tab4 = tab4;
    }

    if (exclude.indexOf(5) === -1) {
      const tab5 = this.state.tab5;
      tab5.active = false;
      tab5.disabled = disabled;
      stateObject.tab5 = tab5;
    }

    this.setState(stateObject);
  }

  enableAndActiveTab = tabName => {
    const tabToEnable = this.state[tabName];
    tabToEnable.enabled = true;
    this.setState({ [tabName]: tabToEnable });

    this.activateTab(tabName);
  };

  getTabClassName = (tabName, currentIndex, completedIndex) => {
    let className = 'frc__app-tab';

    if (this.state[tabName].active) {
      className += ' frc__app-tab--active';
    }
    if (this.state[tabName].completed || currentIndex <= completedIndex) {
      className += ' frc__app-tab--completed';
    }

    return className;
  };

  activateTab = tabName => {
    if (!this.state[tabName].enabled) {
      return;
    }

    this.deActiveAllTabs();

    const tabToActivate = this.state[tabName];
    tabToActivate.active = true;
    this.setState({ [tabName]: tabToActivate });
  };

  previousTab = (tabName, index) => {
    if (!this.state[tabName].enabled) {
      return;
    }

    const stateObj = JSON.parse(JSON.stringify(this.state));

    for (let i = 1; i <= 5; i++) {
      if (index < i) {
        stateObj['tab' + i].completed = false;
        stateObj['tab' + i].active = false;
      }
    }

    stateObj[tabName].active = true;
    stateObj[tabName].completed = true;
    stateObj.currentTabIndex = index;

    this.setState(stateObj);
  };

  currentTab = tabName => {
    if (!this.state[tabName].enabled) {
      return;
    }

    this.deActiveAllTabs();

    const tabToActivate = this.state[tabName];
    tabToActivate.active = true;
    tabToActivate.completed = true;
    const currentTabIndex = parseInt(tabName.split('tab')[1]);
    this.setState({ [tabName]: tabToActivate, currentTabIndex });
  };

  currentTabActive = tabName => {
    const tabToActivate = this.state[tabName];
    tabToActivate.active = true;
    const currentTabIndex = parseInt(tabName.split('tab')[1]);
    this.setState({ [tabName]: tabToActivate, currentTabIndex });
  };

  enableAllTabs = () => {
    const stateObj = JSON.parse(JSON.stringify(this.state));

    for (let i = 1; i <= 5; i++) {
      stateObj['tab' + i].disabled = false;
    }

    this.setState(stateObj);
  };

  onThankYouPageDisplay = (status, result = false) => {
    if (result && status) {
      this.setState({
        displayThankYouPage: true,
        shipmentStatusData: result,
      });
    } else {
      this.setState({
        displayThankYouPage: false,
        shipmentStatusData: result,
      });
    }
  };

  hideTab4OnReturnProduct = (returnProdStatus, tabName) => {
    let hideSchedulePickupTab = false;

    if (returnProdStatus !== undefined && returnProdStatus) {
      tabName = config.tabs.tab5;
      hideSchedulePickupTab = true;
    }

    if (!this.state[tabName].enabled) {
      return;
    }

    this.deActiveAllTabs(hideSchedulePickupTab);
    this.currentTabActive(tabName);
  };

  disableAllOtherTabs = (status, exclude, activeTab) => {
    this.deActiveAllTabs(this.state.hideTab4OnReturnProduct, status, exclude);
    this.currentTabActive(activeTab);
  };

  displayTabs = () => {
    const tabs = ['Route', 'Shipment Details', 'Delivery Options', 'Schedule Pickup', 'Review & Book'];
    const displayTabs = [];

    tabs.forEach((tab, index) => {
      let newIndex = index + 1;
      let currentTabIndex = this.state.currentTabIndex;

      displayTabs.push(
        <div
          key={newIndex}
          id={'fcp__app-title-' + tab}
          className={
            newIndex === 4 && this.state.hideSchedulePickupTab
              ? 'frc__app-tab'
              : this.getTabClassName('tab' + newIndex, newIndex, currentTabIndex)
          }
          onClick={
            this.state['tab' + newIndex].disabled
              ? null
              : currentTabIndex >= newIndex
              ? newIndex === 4 && this.state.hideSchedulePickupTab
                ? null
                : () => {
                    this.previousTab('tab' + newIndex, newIndex);
                  }
              : null
          }
        >
          <h3 className="frc__app-tab__title">
            <Translation>
              {t => (
                <>
                  {newIndex} &nbsp;{t(`general|${tab}`)}
                </>
              )}
            </Translation>
          </h3>
        </div>,
      );
    });

    return displayTabs;
  };

  render() {
    const { context } = this.props;

    return (
      <>
        {!this.state.displayThankYouPage ? (
          <div className="l-grid">
            <div className="l-grid--w-100pc-s l-grid--w-75pc-m">
              <div className="frc__app-tabs">{this.displayTabs()}</div>
              {this.state.tab1.active && (
                <EnterPickupLocation context={context} nextTab={tab => this.currentTab(tab)} />
              )}
              {this.state.tab2.active && (
                <EnterShipmentDetails
                  context={context}
                  previousTab={(tab, index) => this.previousTab(tab, index)}
                  disableAllOtherTabs={this.disableAllOtherTabs}
                  nextTab={tab => this.currentTab(tab)}
                />
              )}

              {this.state.tab3.active && (
                <DeliveryOptions
                  context={context}
                  previousTab={(tab, index) => this.previousTab(tab, index)}
                  disableAllOtherTabs={this.disableAllOtherTabs}
                  nextTab={(returnProdStatus, tab) => this.hideTab4OnReturnProduct(returnProdStatus, tab)}
                />
              )}

              {(this.state.tab4.active || this.state.tab4.completed) && (
                <section className={!this.state.tab4.active && this.state.tab4.completed ? 'frc__hide' : ''}>
                  {this.state.tab4.active && (
                    <SchedulePickup
                      context={context}
                      activateTab={this.enableAndActiveTab}
                      previousTab={(tab, index) => this.previousTab(tab, index)}
                    />
                  )}
                </section>
              )}

              {(this.state.tab5.active || this.state.tab5.completed) && (
                <section className={!this.state.tab5.active && this.state.tab5.completed ? 'frc__hide' : ''}>
                  {this.state.tab5.active && (
                    <ReviewAndBook
                      context={context}
                      activateTab={this.enableAndActiveTab}
                      previousTab={(tabName, tabIndex) => this.previousTab(tabName, tabIndex)}
                      disableAllOtherTabs={this.disableAllOtherTabs}
                      thankYouPage={shipmentData => this.onThankYouPageDisplay(true, shipmentData)}
                    />
                  )}
                </section>
              )}
            </div>
            <div className="l-grid--w-100pc-s l-grid--w-25pc-m frc__hide-summary-panel">
              <SideSummaryPanel context={context} />
            </div>
          </div>
        ) : (
          <ThankYouPage
            shipmentStatusData={this.state.shipmentStatusData}
            config={config}
            CONST={CONST}
            context={context}
            enableOtherTabs={() => this.enableAllTabs()}
            thankYouPage={() => this.onThankYouPageDisplay(false)}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = {
  resetPortalOrderSlice,
};

export default connect(null, mapDispatchToProps)(AppTabs);

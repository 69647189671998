import { Box, Container, Stack } from '@mui/material';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { DOMESTIC_SHIPMENT_RANGE } from '../../../../globals/constants';
import { getArrayFromTranslation } from '../../../../globals/utils/translations';
import { CONST, config } from '../../../data-config';
import './UploadDocuments.css';

const isIE = navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true;
export default class UploadDocuments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentList: [],
      fileList: [],
      deleteList: [],
      sizeError: false,
      disabled: false,
    };
    this._isMounted = false;
  }

  componentDidMount = () => {
    this._isMounted = true;

    this.setState({
      documentList: [this.getFileInputType(0), this.getFileInputType(1), this.getFileInputType(2)],
      fileList: ['file', 'file', 'file'],
    });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getFileInputType = index => {
    return (
      <input
        id={'frc__input_file-' + index}
        type="file"
        accept=".pdf, .xls, .xlsx, .csv, .doc, .docx"
        className="frc__input_file"
        onChange={event => this.uploadFile(event)}
      />
    );
  };

  uploadedFilesSizeError(fileList, deleteList) {
    let size = 0;

    fileList.forEach((file, index) => {
      if (deleteList.indexOf(index) === -1) {
        if (file?.size) size += file.size;
      }
    });

    return this.formatBytes(size) > config.fileSizeMaxLimitInMB;
  }

  formatBytes(size) {
    return (size / (1024 * 1024)).toFixed(3) * 1;
  }

  uploadFile = event => {
    const files = event.target.files;
    const file = files[0];
    const index = event.target.id.split('frc__input_file-')[1];
    const fileList = [...this.state.fileList];

    fileList[index] = file ? file : 'file';
    const sizeError = this.uploadedFilesSizeError(fileList, this.state.deleteList);

    this.setState({
      fileList,
      sizeError,
      disabled: false,
    });

    const findEmptyFileIndex = this.state.fileList.findIndex(fileI => fileI === 'file');
    document
      .getElementById('frc__input_file-parent-' + (findEmptyFileIndex > -1 ? findEmptyFileIndex : 0))
      .classList.remove('has-error');
  };

  addNewDocument() {
    const documentList = [...this.state.documentList];
    const fileList = [...this.state.fileList];

    if (this.state.fileList.length === documentList.length && !this.state.fileList.some(file => file === 'file')) {
      documentList.push(this.getFileInputType(documentList.length));
      fileList.push('file');

      this.setState({
        documentList,
        disabled: true,
        fileList,
      });
    } else {
      const findEmptyFileIndex = this.state.fileList.findIndex(fileI => fileI === 'file');
      document
        .getElementById('frc__input_file-parent-' + (findEmptyFileIndex > -1 ? findEmptyFileIndex : 0))
        .classList.add('has-error');

      this.setState({
        disabled: true,
        fileList,
      });
    }
  }

  async uploadToServer(captcha) {
    const finalFiles = [];
    const sizeError = this.uploadedFilesSizeError(this.state.fileList, this.state.deleteList);
    const uploadResult = {};

    if (!sizeError) {
      this.state.fileList.forEach((_file, index) => {
        if (this.state.deleteList.indexOf(index) === -1 && typeof _file === 'object') finalFiles.push(_file);
      });

      const transactionId = this.props.context.state.transactionId;
      const data = new FormData();

      finalFiles.forEach(file => {
        if (isIE) {
          data.append('files', file, file.name);
        } else {
          data.append('files', file);
        }
      });
      if (finalFiles.length > 0) {
        data.append('product', this.props.context.state.selectedProduct.product);
        data.append('productCode', this.props.context.state.selectedProduct.code);
        data.append('accountNumber', this.props.context.state.accountNumber.value);
        data.append('shipmentRange', this.props.context.state.shipmentRange.value);
        const headers = {
          file: true,
          transactionId: transactionId,
          answer: captcha.answer,
          token: captcha.token,
        };

        try {
          await apiRequest(apiLinks.uploadCustomDocumentsPublic, 'POST', {
            body: data,
            headers: headers,
          })
            .then(result => {
              if (this._isMounted) {
                if (result.status === CONST.STATUS_OK) {
                  uploadResult.result = true;
                  uploadResult.uploadId = result.data.uploadId;
                } else if (result.status === 403) uploadResult.captchaError = true;
                else uploadResult.result = false;
              }
            })
            .catch(() => {
              uploadResult.result = false;
            });
        } catch {
          uploadResult.result = false;
        }

        return uploadResult;
      } else {
        return { result: true };
      }
    } else {
      this.setState({ sizeError: true });
      return { result: false };
    }
  }

  updateDelete = index => {
    const map = {};

    // delete list
    const deleteList = JSON.parse(JSON.stringify(this.state.deleteList));
    deleteList.push(index);
    map.deleteList = deleteList;

    // size error
    const sizeError = this.uploadedFilesSizeError(this.state.fileList, deleteList);
    if (sizeError) map.sizeError = true;
    else map.sizeError = false;

    // adding delete index
    const { fileList } = this.state;
    if (this.state.fileList[index] === 'file') {
      fileList[index] = 'deleteFile';
    }
    map.fileList = fileList;
    map.disabled = false;

    this.setState(map);
  };

  checkCrossMark = () => {
    const checkFiles = [];

    this.state.fileList.forEach((_file, index) => {
      if (this.state.deleteList.indexOf(index) === -1) checkFiles.push(_file);
    });

    return checkFiles.length > 1;
  };

  onClear = index => {
    document.getElementById('frc__input_file-' + index).value = '';
    const documentList = [...this.state.documentList];
    const fileList = [...this.state.fileList];

    documentList[index] = this.getFileInputType(index);
    fileList[index] = 'file';
    const sizeError = this.uploadedFilesSizeError(fileList, this.state.deleteList);
    this.setState({ documentList, fileList, sizeError });
  };

  onDelete = index => {
    document.getElementById('frc__input_file-parent-' + index).style.display = 'none';
    document.getElementById('frc__input_file-' + index).value = '';

    if (this.state.fileList.length > 1) {
      this.updateDelete(index * 1);
    }
  };

  render() {
    const crossField = this.checkCrossMark();
    const bulletPoints = getArrayFromTranslation(
      this.props.context.state?.shipmentRange?.value === DOMESTIC_SHIPMENT_RANGE
        ? 'general|uploadDocuments|domesticBulletPoints'
        : 'general|uploadDocuments|internationalBulletPoints',
    );

    return (
      <div className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <>
              <h4 className="margin-bottom-0 frc__red-lined-title">{t('general|Upload Documents')}</h4>
              <div className="margin-bottom-0">
                <small>
                  {t(
                    this.props.context.state?.shipmentRange?.value === DOMESTIC_SHIPMENT_RANGE
                      ? 'general|uploadDocuments|domesticFieldCaption'
                      : 'general|uploadDocuments|internationalFieldCaption',
                  )}
                </small>
              </div>
              <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={{ xs: 2, sm: 6 }}>
                <Stack flex={{ xs: '1 1 auto', sm: '1 1 350px' }} maxWidth="450px">
                  {this.state.documentList.map((document, ind) => (
                    <div
                      key={ind}
                      id={'frc__input_file-parent-' + ind}
                      className="l-grid--w-100pc-s
                      frc__upload_document"
                    >
                      {document}

                      <button className="frc__remove-button" onClick={() => this.onClear(ind)}>
                        {t(`general|Clear`)}
                      </button>

                      {crossField && (
                        <button className="frc__remove-button" onClick={() => this.onDelete(ind)}>
                          X
                        </button>
                      )}
                    </div>
                  ))}
                </Stack>
                <Stack flex="0 3 auto">
                  <p>
                    {t(
                      this.props.context.state?.shipmentRange?.value === DOMESTIC_SHIPMENT_RANGE
                        ? 'general|uploadDocuments|domesticHeadline'
                        : 'general|uploadDocuments|internationalHeadline',
                    )}
                  </p>
                  <Container>
                    <ul>
                      {bulletPoints.map(text => (
                        <li key={text}>{text}</li>
                      ))}
                    </ul>
                  </Container>
                </Stack>
              </Box>
              <button
                className={`base-button base-button--white base-button--wide frc__generic-button frc__add-button has-top-margin-1
                    ${this.state.disabled || this.state.sizeError ? 'disabled' : ''}`}
                onClick={() => (this.state.disabled || this.state.sizeError ? '' : this.addNewDocument())}
              >
                <i className="has-icon icon-plus frc__dhlicon-add frc__padding-right"></i>
                {t(`general|Add another custom document`)}
              </button>
              {this.state.sizeError ? (
                <p className=" has-error-message">{t('general|Size should not be greater than 10MB')}</p>
              ) : (
                ''
              )}
            </>
          )}
        </Translation>
      </div>
    );
  }
}

import { cutTextAndShowError, prepareFieldInStateForUpdate } from 'globals/context/context-helpers';
import { checkEmptyOrMinMaxOrLength } from 'globals/utils/fieldValidations';
import React, { Component } from 'react';
import { userQueryKey } from '../../hooks/api/useGetUser';
import { withCountryData } from '../../hooks/useConfigCoutries';
import { config } from '../data-config';

export const AddressContext = React.createContext();

class AddressProviderComponent extends Component {
  state = {
    //  address search
    searchTerm: { value: '', error: false, isRequired: true },
    remoteSearchTerm: '',
    pagination: {
      page: 1,
      total: 0,
      pageSize: config.paginationInitialNumberOfItems,
      lastPage: 0,
    },
    loader: false,
    countryAPIError: false,
    filteredCountries: [],
    filterCountryOptionsList: [],
    addressBookList: [],
    addressBookListMaster: [],
    manualSearch: false,

    // contact
    id: '',
    addressId: { value: '', error: false, isRequired: false },
    notes: { value: '', error: false, isRequired: false },
    phoneNumbers: [{ code: this.props.countryData.countryPhoneCode, number: '', error: false, isRequired: false }],
    email: { value: '', error: false, isRequired: false },
    name: { value: '', error: false, isRequired: false },
    companyName: { value: '', error: false, isRequired: true },
    countryCode: { value: this.props.countryData.urlCountry?.toUpperCase(), error: false, isRequired: true },
    street: { value: '', error: false, isRequired: true },
    postalCode: { value: '', error: false, isRequired: true },
    city: { value: '', error: false, isRequired: true },
    deliveryInstructions: { value: '', error: false, isRequired: false },
    reference: { value: '', error: false, isRequired: false },
    residentialAddress: { value: false, error: false, isRequired: false },

    //transactionId
    transactionId: '',
    apiError: false,

    //actions
    deleteList: [],
  };

  componentDidMount = () => {
    let language = localStorage.getItem(config.locale.localStorageVariable) || config.locale.defaultLanguage;

    this.setState({
      languageCode: language,
      reactQueryUser: this.props.queryClient.getQueryData([userQueryKey]),
    });
  };

  extendedSetState = (stateObject, params) => {
    let beforeUpdate = {};
    let afterUpdate = {};

    // Executes additional logic before update the state
    if (params.beforeUpdate && typeof params.beforeUpdate === 'function') {
      beforeUpdate = params.beforeUpdate(this, params, stateObject);
      if (typeof beforeUpdate === 'object' && beforeUpdate !== null) {
        beforeUpdate = JSON.parse(JSON.stringify(beforeUpdate));
        Object.assign(stateObject, beforeUpdate);
      }
    }

    // Setting the new state and performs additional tasks on callback (if any)
    this.setState(stateObject, () => {
      const callbackState = {};

      // Executes additional logic after update the state
      if (params.afterUpdate && typeof params.afterUpdate === 'function') {
        afterUpdate = params.afterUpdate(this, params);
        if (typeof afterUpdate === 'object' && afterUpdate !== null) {
          afterUpdate = JSON.parse(JSON.stringify(afterUpdate));
          Object.assign(callbackState, afterUpdate);
        }
      }

      if (Object.keys(callbackState).length > 0 && callbackState.constructor === Object) {
        this.setState(callbackState);
      }
    });
  };

  updateField = params => {
    let error = checkEmptyOrMinMaxOrLength(params);
    let stateObject = {};

    // Text cut
    if (params.cutTextLimit && !isNaN(params.cutTextLimit) && params.cutTextLimit > 0) {
      params = cutTextAndShowError(params);
      error = params.error === true ? true : error;
    }

    // Update the current field
    Object.assign(stateObject, prepareFieldInStateForUpdate(this.state, params.name, params.value, error));

    // Overides the value of related field
    if (params.override && this.state[params.override.name]) {
      Object.assign(stateObject, prepareFieldInStateForUpdate(this.state, params.override.name, params.value, error));
    }

    // Deselecting related fields
    if (params.deselect) {
      params.deselect.forEach(deselect => {
        Object.assign(stateObject, prepareFieldInStateForUpdate(this.state, deselect, false));
      });
    }

    // Set state
    this.extendedSetState(stateObject, params);
  };

  summGroupColumn = (group, column) => {
    let total = 0;
    if (group.length > 0) {
      group.forEach((elm, ind) => {
        if (elm[column] && elm[column].value && !isNaN(elm[column].value)) {
          total += elm[column].value * 1;
        } else if (!isNaN(elm[column])) {
          total += elm[column] * 1;
        }
      });
    }
    return total;
  };

  updateGroupOfFields = params => {
    let error = checkEmptyOrMinMaxOrLength(params);
    const duplicationError = false;
    const group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    let stateObject = {};

    // Text cut
    if (params.cutTextLimit && !isNaN(params.cutTextLimit) && params.cutTextLimit > 0) {
      params = cutTextAndShowError(params);
      error = params.error === true ? true : error;
    }

    if (group[params.index].code && group[params.index].code === params.name) {
      group[params.index].selectedStatus = params.value;
    } else if (typeof group[params.index][params.name] === 'object') {
      group[params.index][params.name].value = params.value;
      group[params.index][params.name].error = false;

      if (!params.skipError) {
        group[params.index][params.name].error = error;
      }
    } else {
      group[params.index][params.name] = params.value;

      if (!params.skipError) {
        group[params.index].error = error;
        group[params.index].duplicationError = duplicationError;
      }
    }

    stateObject = {
      [params.groupName]: group,
    };

    // Set state
    this.extendedSetState(stateObject, params);
  };

  addAnotherGroupField = params => {
    const group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    let stateObject = {};

    group.push(params.item);
    stateObject[params.groupName] = group;

    // Set state
    this.extendedSetState(stateObject, params);
  };

  removeGroupField = params => {
    const group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    let stateObject = {};

    group.splice(params.index, 1);
    stateObject[params.groupName] = group;

    // Set state
    this.extendedSetState(stateObject, params);
  };

  updateState = (newState, callback) => {
    this.setState(
      {
        ...newState,
      },
      callback,
    );
  };

  render() {
    return (
      <AddressContext.Provider
        value={{
          state: this.state,
          updateField: this.updateField,
          updateGroupOfFields: this.updateGroupOfFields,
          addAnotherGroupField: this.addAnotherGroupField,
          removeGroupField: this.removeGroupField,
          updateState: this.updateState,
          extendedSetState: this.extendedSetState,
          reactQueryClient: this.props.queryClient,
        }}
      >
        {this.props.children}
      </AddressContext.Provider>
    );
  }
}

export const AddressProvider = withCountryData(AddressProviderComponent);

import React from 'react';
import { Translation } from 'react-i18next';

import { globalConfig } from '../../../data-config-global';
import { allCountries } from '../../../data-countries';
import { fromDateComponent, sidePanelDatePresenter, toDateComponent } from '../../../helpers/side-summary-helpers';
import './SideSummaryPanel.css';

export default function SideSummaryPanel(props) {
  const { state } = props.context;

  let vasList = [];
  let skipPickupOptionsOnReturnProducts = false;
  let pickupOption = '';
  let deliveryInstructions = '';
  let pickupInstructions = '';
  let fromCountry = '';
  let toCountry = '';
  let sender = '';
  let receiver = '';
  let pickupStreet = '';
  let deliveryStreet = '';
  let pickupPostalCode = '';
  let deliveryPostalCode = '';
  let pickupCity = '';
  let deliveryCity = '';

  if (
    state.pickupFromDifferentAddress.value &&
    !state.pickupAddressResidential.value &&
    state.deliverToDifferentAddress.value &&
    !state.deliveryAddressResidential.value
  ) {
    sender = state.differentPickupSender.value;
    receiver = state.differentDeliveryReceiver.value;
    fromCountry = allCountries.find(country => country.code === state.differentPickupCountry.value);

    toCountry = allCountries.find(country => country.code === state.differentDeliveryCountry.value);

    pickupStreet = state.differentPickupStreet.value;
    deliveryStreet = state.differentDeliveryStreet.value;
    pickupPostalCode = state.differentPickupPostalCode.value;
    deliveryPostalCode = state.differentDeliveryPostalCode.value;
    pickupCity = state.differentPickupCity.value;
    deliveryCity = state.differentDeliveryCity.value;
  } else if (state.pickupFromDifferentAddress.value && !state.pickupAddressResidential.value) {
    sender = state.differentPickupSender.value;
    receiver = state.deliveryReceiver.value;

    fromCountry = allCountries.find(country => country.code === state.differentPickupCountry.value);

    toCountry = allCountries.find(country => country.code === state.deliveryCountry.value);

    pickupStreet = state.differentPickupStreet.value;

    deliveryStreet = state.deliveryStreet.value;

    pickupPostalCode = state.differentPickupPostalCode.value;
    deliveryPostalCode = state.deliveryPostalCode.value;

    pickupCity = state.differentPickupCity.value;
    deliveryCity = state.deliveryCity.value;
  } else if (state.deliverToDifferentAddress.value && !state.deliveryAddressResidential.value) {
    sender = state.pickupSender.value;
    receiver = state.differentDeliveryReceiver.value;

    fromCountry = allCountries.find(country => country.code === state.pickupCountry.value);

    toCountry = allCountries.find(country => country.code === state.differentDeliveryCountry.value);

    pickupStreet = state.pickupStreet.value;

    deliveryStreet = state.differentDeliveryStreet.value;

    pickupPostalCode = state.pickupPostalCode.value;
    deliveryPostalCode = state.differentDeliveryPostalCode.value;

    pickupCity = state.pickupCity.value;
    deliveryCity = state.differentDeliveryCity.value;
  } else {
    sender = state.pickupSender.value;
    receiver = state.deliveryReceiver.value;
    fromCountry = allCountries.find(country => country.code === state.pickupCountry.value);

    toCountry = allCountries.find(country => country.code === state.deliveryCountry.value);

    pickupStreet = state.pickupStreet.value;
    deliveryStreet = state.deliveryStreet.value;
    pickupPostalCode = state.pickupPostalCode.value;
    deliveryPostalCode = state.deliveryPostalCode.value;
    pickupCity = state.pickupCity.value;
    deliveryCity = state.deliveryCity.value;
  }

  const pickupLocationEntered = sender || pickupStreet || pickupPostalCode || pickupCity;
  const deliveryLocationEntered = receiver || deliveryStreet || deliveryPostalCode || deliveryCity;

  if (
    state.pickupFromDifferentAddress.value &&
    !state.pickupAddressResidential.value &&
    state.deliverToDifferentAddress.value &&
    !state.deliveryAddressResidential.value
  ) {
    fromCountry = allCountries.find(country => country.code === state.differentPickupCountry.value);
    toCountry = allCountries.find(country => country.code === state.differentDeliveryCountry.value);
  } else if (state.pickupFromDifferentAddress.value && !state.pickupAddressResidential.value) {
    fromCountry = allCountries.find(country => country.code === state.differentPickupCountry.value);
    toCountry = allCountries.find(country => country.code === state.deliveryCountry.value);
  } else if (state.deliverToDifferentAddress.value && !state.deliveryAddressResidential.value) {
    fromCountry = allCountries.find(country => country.code === state.pickupCountry.value);
    toCountry = allCountries.find(country => country.code === state.differentDeliveryCountry.value);
  } else {
    fromCountry = allCountries.find(country => country.code === state.pickupCountry.value);
    toCountry = allCountries.find(country => country.code === state.deliveryCountry.value);
  }

  state.additionalServices.forEach(additionalService => {
    if (additionalService.value) {
      vasList.push({ code: additionalService.code, value: additionalService.translatedName });
    }
  });

  if (state.selectedProduct) {
    let returnProductsSkipPickupOptions = globalConfig.returnProducts.find(
      product => state.selectedProduct?.code === product,
    );

    if (returnProductsSkipPickupOptions) {
      skipPickupOptionsOnReturnProducts = true;
    } else {
      pickupOption = state.pickupOption.value;
      deliveryInstructions = state.deliveryInstructions.value;
      pickupInstructions = state.pickupInstructions.value;
    }
  }

  const totals = state.totals.shipmentDetailsRows;

  return (
    <Translation>
      {t => (
        <div className="frc__side-summary-panel--wrapper" translate="no">
          <div className="frc__side-summary-panel">
            <h3>{t('general|Shipment Summary')}</h3>

            <div className="frc__generic-row--wrapper">
              <h4 className="margin-bottom-0">{t('general|Payment Account')}</h4>
              <div className="frc__pickup-location">
                {!(state.accountNumber.value || state.receiverNumber.value || state.thirdPartyNumber.value) && (
                  <em>
                    <span id="accountNumberSlt_sidePanel">{t('general|No account number selected')}</span>
                  </em>
                )}
                {state.accountNumber.value && (
                  <div>
                    {t('general|labels|inputs|Sender account number')} {': '}
                    <span id="senderAccountNumber_sidePanel">{state.accountNumber.value}</span>{' '}
                  </div>
                )}
                {state.receiverNumber.value && (
                  <div>
                    {t('general|labels|inputs|Receiver Account Number')} {': '}
                    <span id="receiverAccountNumber_sidePanel">{state.receiverNumber.value}</span>{' '}
                  </div>
                )}
                {state.thirdPartyNumber.value && (
                  <div>
                    {t('general|labels|inputs|Third Party Account Number')} {': '}
                    <span id="thirdPartyNumber_sidePanel">{state.thirdPartyNumber.value}</span>{' '}
                  </div>
                )}
              </div>
            </div>

            <div className="frc__generic-row--wrapper">
              <h4 className="margin-bottom-0">{t('general|Pickup from')}</h4>
              <p className="frc__pickup-location" id="pickupSenderInp_sidePanel">
                {sender || ''}
              </p>
              <p className="frc__pickup-location" id="pickupStreetInp_sidePanel">
                {pickupStreet || ''}
              </p>
              <p className="frc__pickup-location">
                <span id="pickupPostalCodeInp_sidePanel">{pickupPostalCode || ''}</span>{' '}
                <span id="pickupCityInp_sidePanel">{pickupCity || ''}</span>{' '}
              </p>
              <p className="frc__pickup-location">
                <em id="pickupCountrySlt_sidePanel">
                  {pickupLocationEntered && fromCountry
                    ? t(`countries|${fromCountry.name}`)
                    : t('general|Sender Details not entered')}
                </em>
              </p>
            </div>

            <div className="frc__generic-row--wrapper">
              <h4 className="margin-bottom-0">{t(`general|Delivery to`)}</h4>
              <p className="frc__pickup-location" id="deliveryReceiverInp_sidePanel">
                {receiver || ''}
              </p>
              <p className="frc__pickup-location" id="deliveryStreetInp_sidePanel">
                {deliveryStreet || ''}
              </p>
              <p className="frc__pickup-location">
                <span id="deliveryPostalCodeInp_sidePanel">{deliveryPostalCode || ''}</span>{' '}
                <span id="deliveryCityInp_sidePanel">{deliveryCity || ''}</span>
              </p>
              <p className="frc__pickup-location">
                <em id="deliveryCountrySlt_sidePanel">
                  {deliveryLocationEntered && toCountry
                    ? t(`countries|${toCountry.name}`)
                    : t('general|Receiver Details not entered')}
                </em>
              </p>
            </div>

            <div className="frc__generic-row--wrapper">
              <h4 className="margin-bottom-0">{t('general|Shipment Details')}</h4>
              {totals.weight.value === '' || totals.weight.value === 0 ? (
                <em className="frc__shipment-details">{t('general|Select Shipment Details')}</em>
              ) : (
                <div>
                  <div className={'frc__shipment-summary--row l-grid--left-s'}>
                    <div>{t('general|labels|metrics|Total Quantity')}: </div>
                    <div id="quantityInp_sidePanel">{totals.quantity.value}</div>
                  </div>
                  <div className={'frc__shipment-summary--row l-grid--left-s'}>
                    <div>{t('general|labels|metrics|Total Volume')}: </div>
                    <div>
                      <span id="volumeInp_sidePanel">{totals.volume.value}</span> {t('general|m')}
                      <sup>3</sup>
                    </div>
                  </div>
                  <div className={'frc__shipment-summary--row l-grid--left-s'}>
                    <div>{t('general|labels|metrics|Total Weight')}: </div>
                    <div>
                      <span id="weightInp_sidePanel">{totals.weight.value}</span> {t('general|kg')}
                    </div>
                  </div>
                  <div className={'frc__shipment-summary--row l-grid--left-s'}>
                    <div>{t('general|labels|metrics|Total Loading Meter')}: </div>
                    <div id="loadingMeterInp_sidePanel">{totals.loadingMeter.value}</div>
                  </div>
                </div>
              )}
            </div>

            {!skipPickupOptionsOnReturnProducts && (
              <div className="frc__generic-row--wrapper">
                <h4 className="margin-bottom-0">{t('general|Pickup Options')}</h4>
                <div>
                  <div className="frc__bold">{pickupOption ? t(`general|${pickupOption}`) : ''}</div>
                  <div id="shippingOrPickupDate_sidePanel">{fromDateComponent(state, sidePanelDatePresenter)}</div>
                  {pickupInstructions !== '' && (
                    <div className="frc__word-wrap">
                      {t('portalOrder|Pickup Instructions')} {':'}{' '}
                      <span id="pickupInstructionsTxtAr_sidePanel">{pickupInstructions}</span>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="frc__generic-row--wrapper">
              <h4 className="margin-bottom-0">{t(`general|Delivery Options`)}</h4>
              <div>
                <em>
                  {state.selectedProduct && state.selectedProduct.product ? (
                    <>
                      <div className="frc__bold" id={`${state.selectedProduct.code}SelectRdBtn_sidePanel`}>
                        {state.selectedProduct.product}
                      </div>
                      {vasList &&
                        vasList
                          .filter(j => !globalConfig.VASfixedDateOption.includes(j.code))
                          .map((item, index) => {
                            return (
                              <div id={`${item.code}ChkBx_sidePanel`} key={index}>
                                {item.value}
                              </div>
                            );
                          })}
                      <div id={`${state.selectedProduct.code}_deliveryDate_sidePanel`}>
                        {toDateComponent(state, sidePanelDatePresenter)}
                      </div>
                      {deliveryInstructions !== '' && (
                        <div className="frc__word-wrap">
                          {t(`general|Delivery Instructions`)} {':'}{' '}
                          <span id="deliveryInstructionsTxtAr_sidePanel">{deliveryInstructions}</span>
                        </div>
                      )}
                    </>
                  ) : (
                    t('general|Select Delivery Options')
                  )}{' '}
                </em>
              </div>
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
}

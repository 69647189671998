import { allCountries } from 'globals/data-countries';
import React from 'react';
import { Translation } from 'react-i18next';
import { sidePanelDatePresenter, toDateComponent } from '../../../globals/helpers/side-summary-helpers';
import { config } from '../../data-config';
import './SideSummaryPanel.css';

export default function SideSummaryPanel(props) {
  let routeState = props.routeContext.state;
  let shipmentState = props.shipmentContext.state;
  let deliveryOptionState = props.deliveryContext.state;
  let vasNameList = [];
  let fromCountry = '';
  let toCountry = '';
  let pickupPostalCode = '';
  let deliveryPostalCode = '';
  let productName = '';

  fromCountry = allCountries.find(
    country => country.code?.toLowerCase() === routeState.pickupCountry.value?.toLowerCase(),
  );

  toCountry = allCountries.find(
    country => country.code?.toLowerCase() === routeState.deliveryCountry.value?.toLowerCase(),
  );

  pickupPostalCode = routeState.pickupPostalCode.value;
  deliveryPostalCode = routeState.deliveryPostalCode.value;

  let selectedProduct =
    deliveryOptionState.matchedProducts && deliveryOptionState.matchedProducts.find(product => product.value);

  deliveryOptionState.additionalServices.forEach(vas => {
    if (vas.value) {
      vasNameList.push(vas);
    }
  });

  if (selectedProduct) {
    productName = config.serviceDescriptions[selectedProduct.code].header;
  }

  const totals = shipmentState.totals.shipmentDetailsRows;

  return (
    <>
      <Translation>
        {t => (
          <>
            <div className="frc__side-summary-panel--wrapper" translate="no">
              <div className="frc__side-summary-panel">
                <h3>{t(`quoteTool|Quote Summary`)}</h3>

                <div className="frc__generic-row--wrapper fcp__summary-panel-title">
                  <h4
                    className="c--fcp-summary-subtitle c--fcp-link js--fcp-summary-subtitle"
                    data-toggle="js--fcp-tab-country-selector"
                  >
                    <div className="frc__summary_panel_title">{t(`quoteTool|Quote Type`)}</div>
                  </h4>

                  {routeState.productsRangeOne.value ? (
                    <>
                      {t(`quoteTool|Quote for`)}{' '}
                      <span id="accountNumberSlt_sidePanel">
                        {routeState.accountNumber.value !== '' ? routeState.accountNumber.value : '---'}{' '}
                      </span>
                      {t(`quoteTool|with`)}{' '}
                      <span id="selectedProductSlt_sidePanel">
                        {routeState.selectedProduct.value !== ''
                          ? config.serviceDescriptions[routeState.selectedProduct.value].header
                          : '---'}
                      </span>
                    </>
                  ) : (
                    <span id="productsRangeAllRdBtn_sidePanel">
                      {routeState.productsRangeAll.value
                        ? t(`quoteTool|All Available Products`)
                        : t(`quoteTool|Select a Quote Type`)}
                    </span>
                  )}
                </div>

                <div className="frc__generic-row--wrapper fcp__summary-panel-title">
                  <h4
                    className="c--fcp-summary-subtitle c--fcp-link js--fcp-summary-subtitle"
                    data-toggle="js--fcp-tab-country-selector"
                  >
                    <div className="frc__summary_panel_title">{t('general|Pickup from')}</div>
                  </h4>

                  <div className="frc__pickup-location">
                    <em>
                      <div>
                        <span id="pickupPostalCodeInp_sidePanel">{pickupPostalCode || ''}</span>
                      </div>
                      <div id="pickupCountrySlt_sidePanel">
                        {fromCountry ? t(`countries|${fromCountry.name}`) : '---'}
                      </div>
                    </em>
                  </div>
                </div>

                <div className="frc__generic-row--wrapper fcp__summary-panel-title">
                  <h4
                    className="c--fcp-summary-subtitle c--fcp-link js--fcp-summary-subtitle"
                    data-toggle="js--fcp-tab-country-selector"
                  >
                    <div className="frc__summary_panel_title">{t(`general|Delivery to`)}</div>
                  </h4>

                  <div className="frc__pickup-location">
                    <em>
                      <div id="deliveryPostalCodeInp_sidePanel">{deliveryPostalCode || ''}</div>
                      <div id="deliveryCountrySlt_sidePanel">
                        {toCountry ? t(`countries|${toCountry.name}`) : '---'}{' '}
                      </div>
                    </em>
                  </div>
                </div>

                <div className="frc__generic-row--wrapper fcp__summary-panel-title">
                  <h4
                    className="c--fcp-summary-subtitle c--fcp-link js--fcp-summary-subtitle"
                    data-toggle="js--fcp-tab-country-selector"
                  >
                    <div className="frc__summary_panel_title">{t('general|Shipment Details')}</div>
                  </h4>
                  {totals.weight.value === '' || totals.weight.value === 0 ? (
                    <em className="frc__shipment-details">{t('general|Select Shipment Details')}</em>
                  ) : (
                    <div>
                      <div className={'frc__shipment-summary--row l-grid--left-s'}>
                        <div>{t('general|labels|metrics|Total Quantity')}: </div>
                        <div id="quantityInp_sidePanel">{totals.quantity.value}</div>
                      </div>
                      <div className={'frc__shipment-summary--row l-grid--left-s'}>
                        <div>{t('general|labels|metrics|Total Volume')}: </div>
                        <div>
                          <span id="volumeInp_sidePanel">{totals.volume.value}</span> {t('general|m')}
                          <sup>3</sup>
                        </div>
                      </div>
                      <div className={'frc__shipment-summary--row l-grid--left-s'}>
                        <div>{t('general|labels|metrics|Total Weight')}: </div>
                        <div>
                          <span id="weightInp_sidePanel">{totals.weight.value}</span> {t('general|kg')}
                        </div>
                      </div>
                      <div className={'frc__shipment-summary--row l-grid--left-s'}>
                        <div>{t('general|labels|metrics|Total Loading Meter')}: </div>
                        <div id="loadingMeterInp_sidePanel">{totals.loadingMeter.value}</div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="frc__generic-row--wrapper fcp__summary-panel-title">
                  <h4
                    className="c--fcp-summary-subtitle c--fcp-link js--fcp-summary-subtitle"
                    data-toggle="js--fcp-tab-country-selector"
                  >
                    <div className="frc__summary_panel_title">{t(`general|Delivery Options`)}</div>
                  </h4>
                  <div>
                    <em>
                      {selectedProduct ? (
                        <>
                          <div className="frc__bold" id={`${selectedProduct.code}SelectRdBtn_sidePanel`}>
                            {productName}
                          </div>
                          {vasNameList &&
                            vasNameList.map((vas, index) => {
                              return (
                                <div id={`${vas.code}ChkBx_sidePanel`} key={index}>
                                  {vas.translatedName}
                                </div>
                              );
                            })}
                          {toDateComponent(deliveryOptionState, sidePanelDatePresenter)}
                        </>
                      ) : (
                        t('general|Select Delivery Options')
                      )}{' '}
                    </em>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Translation>
    </>
  );
}

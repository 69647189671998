import React, { Component } from 'react';
import { MyProvider, MyContext } from '../../context/context';
import { Typography } from '@mui/material';

import './../../css/global/_variables.css';
// import './../../css/global/_icons.css';
// import './../../css/base/iconfont.css';
// import './../../css/base/react-datepicker.css';
// import './../../css/base/fonts.css';

import 'globals/utils/debugHelpers';
import GeneralError from '../GeneralError/GeneralError';
import cssVars from 'css-vars-ponyfill';
import PortalPickup from './PortalPickup';
import { Translation } from 'react-i18next';

const ENV_DEV = process.env.NODE_ENV === 'development';

export default class AppPortalPickup extends Component {
  componentDidMount() {
    cssVars();
  }

  render() {
    return (
      <Translation>
        {t => (
          <div className={ENV_DEV ? 'l-view' : ''}>
            <MyProvider>
              <div className="c-page-headline component-small has-rte component-margin frc__heading">
                <div className="c-page-headline--wrapper">
                  <Typography variant="h1" mt={{ xs: 0, sm: 2 }}>
                    {t('general|pageTitles|schedulePickup')}
                  </Typography>
                </div>
                <p className="has-rte">
                  {t('portalPickup|Only pickup, no documents will be produced using this menu.')}
                </p>
              </div>
              <div className="frc__app transparent-bg">
                <GeneralError />
                <MyContext.Consumer>{context => <PortalPickup context={context} />}</MyContext.Consumer>
              </div>
            </MyProvider>
          </div>
        )}
      </Translation>
    );
  }
}

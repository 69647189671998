import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';
import { getPostalCodeRegex } from '../../../../globals/helpers/postalcode';
import { config } from '../../../data-config';

const checkIfProductRangeIsSelected = state => {
  return {
    name: 'productsRangeError',
    value: !(state.productsRangeAll.value || state.productsRangeOne.value),
    error: !(state.productsRangeAll.value || state.productsRangeOne.value),
  };
};

export const productsRangeValidation = (state, stateToSet, hasErrors) => {
  let getState = {};

  // Check product range - All or One
  getState = checkIfProductRangeIsSelected(state);
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = {
    value: getState.value,
    error: getState.error,
  };

  return {
    stateToSet,
    hasErrors,
  };
};

export const postalCodeValidation = (state, stateToSet, hasErrors, prefix, accountCountryCode) => {
  let getState = {};

  getState = checkFieldAndReturnState({
    name: prefix + 'PostalCode',
    value: state[prefix + 'PostalCode'].value,
    regEx: getPostalCodeRegex({ countryCode: state[prefix + 'Country'].value, accountCountryCode, strict: true }),
    lengthCheck: [RegExp(`^.{1,${config.maxPostalCode}}$`)],
    isRequired: state[prefix + 'PostalCode'].isRequired ? true : false,
  });

  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = {
    value: getState.value ? getState.value.toUpperCase() : '',
    error: getState.error,
    isRequired: state[prefix + 'PostalCode'].isRequired,
    placeholder: state[prefix + 'PostalCode'].placeholder,
  };

  return {
    stateToSet,
    hasErrors,
  };
};

export const accountAndProductValidation = (state, stateToSet, hasErrors) => {
  let getState = {};
  let fieldName = '';

  // Check only if specific product option is selected
  if (state.productsRangeOne.value) {
    // Check Account number

    fieldName = 'accountNumber';
    getState = checkFieldAndReturnState({
      name: fieldName,
      value: state[fieldName].value,
      regEx: config.regEx.alphaNumericWithDot,
      lengthCheck: state[fieldName].isRequired ? [/^.{5,15}$/] : [/(^$)|(^.{5,15}$)/],
      isRequired: state[fieldName].isRequired,
    });
    hasErrors = getState.error || hasErrors;
    stateToSet[getState.name] = {
      value: getState.value,
      error: getState.error,
      isRequired: getState.isRequired,
    };

    fieldName = 'selectedProduct';
    getState = checkFieldAndReturnState({
      name: fieldName,
      value: state[fieldName].value,
      regEx: config.regEx.text,
      isRequired: state[fieldName].isRequired,
    });
    hasErrors = getState.error || hasErrors;
    stateToSet[getState.name] = {
      value: getState.value,
      error: getState.error,
      isRequired: getState.isRequired,
    };
  }

  return {
    stateToSet,
    hasErrors,
  };
};

// export const EnterPickupLocationValidations = (state, stateToSet, hasErrors, prefix,readState) => {
//   let getState = {};

//   let postalCodeNumber= config.numberPostalCodeCountries.indexOf(state.pickupCountry.value) !== -1 ? true: false

//   // Sender check
//   getState = checkFieldAndReturnState({
//     name: prefix + "Sender",
//     value: state[prefix + "Sender"].value,
//     regEx: config.regEx.everything,
//     lengthCheck: [/^.{1,35}$/],
//     isRequired: state[prefix + "Sender"].isRequired ? true : false
//   });
//   hasErrors = getState.error || hasErrors;
//   stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

//   // Street check

//   getState = checkFieldAndReturnState({
//     name: prefix + "Street",
//     value: state[prefix + "Street"].value,
//     regEx: config.regEx.everything,
//     lengthCheck:   [/^.{1,35}$/],
//     isRequired: state[prefix + "Street"].isRequired ? true : false
//   });
//   hasErrors = getState.error || hasErrors;
//   stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: state[prefix + "Street"].isRequired };

//   // Postalcode check
//   getState = checkFieldAndReturnState({
//     name: prefix + "PostalCode",
//     value:  state[prefix + "PostalCode"].value ,
//     regEx:  postalCodeNumber ? config.regEx.number: config.regEx.text,
//     lengthCheck:  [/^.{1,9}$/],
//     isRequired: (state[prefix + "PostalCode"].isRequired ? true : false)
//   });
//   hasErrors = getState.error || hasErrors;
//   stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired:state[prefix + "PostalCode"].isRequired };

//   // City check
//   getState = checkFieldAndReturnState({
//     name: prefix + "City",
//     value:  state[prefix + "City"].value,
//     regEx: config.regEx.everything,
//     lengthCheck:   [/^.{1,35}$/],
//     isRequired: state[prefix + "City"].isRequired ? true : false
//   });
//   hasErrors = getState.error || hasErrors;
//   stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: state[prefix + "City"].isRequired };

//   // Country check
//   getState = checkFieldAndReturnState({
//     name: prefix + "Country",
//     value: state[prefix + "Country"].value,
//     regEx: config.regEx.everything,
//     lengthCheck: [/^.{2,3}$/],
//     isRequired: state[prefix + "Country"].isRequired ? true : false
//   });
//   hasErrors = getState.error || hasErrors;
//   stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

//   // Name check
//   getState = checkFieldAndReturnState({
//     name: prefix + "Name",
//     value: state[prefix + "Name"].value,
//     regEx: config.regEx.everything,
//     lengthCheck: [/^.{1,35}$/],
//     isRequired: state[prefix + "Name"].isRequired ? true : false
//   });
//   hasErrors = getState.error || hasErrors;
//   stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

//   // E-mail check
//   getState = checkFieldAndReturnState({
//     name: prefix + "Email",
//     value: state[prefix + "Email"].value,
//     regEx: config.regEx.email,
//     lengthCheck: [/^.{0,64}$/],
//     isRequired: state[prefix + "Email"].isRequired ? true : false
//   });
//   hasErrors = getState.error || hasErrors;
//   stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

//   // Phone Numbers check
//   getState = checkGroupOfFieldsAndReturnState({
//     groupName: prefix + "PhoneNumbers",
//     values: state[prefix + "PhoneNumbers"],
//     fields: [
//       {
//         name: "code",
//         isRequired: true
//       },
//       {
//         name: "number",
//         regEx: config.regEx.number,
//         lengthCheck: [/^.{1,17}$/],
//         isRequired: true,
//         calculatePhoneLengthCheck: true
//       }
//     ]
//   });
//   hasErrors = getState.error || hasErrors;
//   stateToSet[getState.groupName] = [...getState.values];

//   // Sender Reference check'
//   if(!(prefix === 'differentPickup')){
//   getState = checkFieldAndReturnState({
//     name: prefix + "SenderReference",
//     value: state[prefix + "SenderReference"].value,
//     regEx: config.regEx.everything,
//     isRequired: false
//   });
//   hasErrors = getState.error || hasErrors;
//   stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };
//   }
//   return {
//     stateToSet,
//     hasErrors
//   };
// };

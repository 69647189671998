import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Button} from '../Button';
import {Close, Save} from '@mui/icons-material';
import {apiRequest} from '../../../globals';
import {apiLinks} from '../../../config/api-config';
import {toast} from 'react-toastify';

import {printOptions} from '../registration/PrintSettingsForm';

import {useQueryClient} from '@tanstack/react-query';
import {userQueryKey} from '../../../hooks/api/useGetUser';
import {ChatWithUs} from '../ChatWithUs';
import {useEffect, useMemo} from 'react';
import {config, PRINTER_CONSTANTS} from './data-config';
import UserService from "../../../globals/utils/userService";

export const PrintSettingsForm = ({ userData, setReadOnly }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const switchToReadOnly = () => {
    setReadOnly(prev => ({
      ...prev,
      printSettings: {
        ...prev.printSettings,
        readOnly: true,
      },
    }));
  };

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      printDefaultPrinter:
        printOptions.find(item => item.value === userData?.user?.printDefaultPrinter)?.value ??
        printOptions.find(item => item.value === PRINTER_CONSTANTS.LABEL_PRINTER_VALUE)?.value,
      printTopMargin: userData?.user?.printTopMargin ?? 0,
      printLeftMargin: userData?.user?.printLeftMargin ?? 0,
      printPadding: userData?.user?.printPadding ?? 0,
      advancedSettings: false,
    },
  });

  const watchValues = watch();
  const watchPrintDefaultPrinter = watch('printDefaultPrinter');

  const submitForm = data => {
    const submitData = async () => {
      const handleError = () => {
        toast(
          <Alert severity="error" variant="standard">
            {t('editProfile|Update failed. Try again or call technical support')} {t('general|or')} <ChatWithUs />
          </Alert>,
        );
      };

      try {
        const params = {
          body:
            data.printDefaultPrinter === PRINTER_CONSTANTS.LABEL_PRINTER_VALUE
              ? {
                  defaulPrinter: data.printDefaultPrinter,
                }
              : {
                  defaulPrinter: data.printDefaultPrinter ?? '',
                  marginTop: data.printTopMargin,
                  marginLeft: data.printLeftMargin,
                  padding: data.printPadding,
                },
        };

        const result = await apiRequest(apiLinks.updateUserPrintSettings, 'PUT', params);

        if (result.status === 200) {
          await queryClient.invalidateQueries(userQueryKey);
          toast(
            <Alert severity="success" variant="standard">
              {t(`editProfile|Print settings updated`)}
            </Alert>,
          );
          switchToReadOnly();
        } else {
          handleError();
        }
        if (!data.printDefaultPrinter?.value) {
          setValue(
            'printDefaultPrinter',
            printOptions.find(item => item.value === PRINTER_CONSTANTS.LABEL_PRINTER_VALUE).value,
          );
        }
      } catch (error) {
        console.error('Error during updating printer', error);
        handleError();
      }
    };
    return submitData();
  };

  const enableAdvancedSettings = useMemo(() => {
    if (
      [PRINTER_CONSTANTS.LABEL_PRINTER_VALUE, PRINTER_CONSTANTS.LABEL_COMPACT_PRINTER_VALUE].includes(
        watchValues.printDefaultPrinter,
      ) ||
      !watchValues.printDefaultPrinter
    ) {
      return false;
    }
    return true;
  }, [watchValues]);

  useEffect(() => {
    if (
      (userData.user.printLeftMargin || userData.user.printPadding || userData.user.printTopMargin) &&
      enableAdvancedSettings
    ) {
      setValue('advancedSettings', true);
    }
  }, [userData, setValue, enableAdvancedSettings]);

  useEffect(() => {
    if (!enableAdvancedSettings) {
      setValue('advancedSettings', false);
    }
  }, [enableAdvancedSettings, setValue]);

  useEffect(() => {
    (async function () {
      let printSettings;
      try {
        printSettings = await UserService.getUserPrintSettings();
      } catch (e) {
        console.error('User print settings: ', e);
      }
    if (printSettings && watchPrintDefaultPrinter?.toLowerCase() !== printSettings.printDefaultPrinter?.toLowerCase()) {
      const printerConfig = config.printerSettingsOptions.find(
        item => item.value.toLowerCase() === watchPrintDefaultPrinter?.toLowerCase(),
      );
      if(printSettings) {
        setValue('printTopMargin', printSettings.printerTopMargin);
        setValue('printLeftMargin', printSettings.printerLeftMargin);
        setValue('printPadding', printSettings.printerPadding);
      } else if (printerConfig) {
        setValue('printTopMargin', printerConfig.printerTopMargin);
        setValue('printLeftMargin', printerConfig.printerLeftMargin);
        setValue('printPadding', printerConfig.printerPadding);
      }
    } else {
      setValue('printTopMargin', printSettings.printerTopMargin);
      setValue('printLeftMargin', printSettings.printerLeftMargin);
      setValue('printPadding', printSettings.printerPadding);
    }
      })();
  }, [watchPrintDefaultPrinter, setValue]);

  return (
    <form
      onSubmit={handleSubmit(data => {
        return submitForm(data);
      })}
    >
      <Box
        sx={{
          '& .MuiFormControl-root': {
            mb: '16px',
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                sx={{
                  fontSize: '12px',
                  '&.Mui-focused': {
                    color: '#000',
                  },
                }}
              >
                {t('editProfile|pdfLayoutPreference')}
              </FormLabel>
              <Controller
                rules={{ required: true }}
                control={control}
                name="printDefaultPrinter"
                render={({ field }) => {
                  return (
                    <RadioGroup row {...field}>
                      {printOptions.map(item => {
                        return (
                          <FormControlLabel
                            key={item.label}
                            value={item.value}
                            control={
                              <Radio
                                key={item.label}
                                color="primary"
                                sx={{
                                  '& .MuiSvgIcon-root': {
                                    fontSize: '20px',
                                  },
                                }}
                              />
                            }
                            label={t(item.label)}
                          />
                        );
                      })}
                    </RadioGroup>
                  );
                }}
              />
            </FormControl>

            <Controller
              name="advancedSettings"
              control={control}
              render={({ field }) => {
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      '& > *': {
                        flex: '1 0 250px',
                      },
                    }}
                  >
                    <FormControlLabel
                      key="advancedSettings"
                      label={t(`editProfile|Advanced Settings`)}
                      control={
                        <Checkbox
                          sx={{ '& .MuiSvgIcon-root': { fontSize: '24px' } }}
                          value={field.value}
                          checked={field.value}
                          onChange={(event, checked) => {
                            field.onChange(checked);
                          }}
                          disabled={!enableAdvancedSettings}
                        />
                      }
                    />
                  </Box>
                );
              }}
            />

            {watchValues.advancedSettings ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: '16px',
                  maxWidth: '700px',
                  mt: '16px',
                }}
              >
                <Controller
                  name="printTopMargin"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('editProfile|Top Margin')}
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />

                <Controller
                  name="printLeftMargin"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('editProfile|Left Margin')}
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />

                <Controller
                  name="printPadding"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('editProfile|Padding')}
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
              </Box>
            ) : null}
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: '16px',
            gap: '16px',
          }}
        >
          <Button type="submit" loading={isSubmitting} variant="contained" color="primary" startIcon={<Save />} dataTestId="saveBtn">
            {t('general|labels|buttons|Save')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              switchToReadOnly();
            }}
            startIcon={<Close />}
            dataTestId="closeBtn"
          >
            {t('general|labels|buttons|Cancel')}
          </Button>
          {Object.keys(errors).length > 0 ? (
            <Alert severity="error" variant="standard" sx={{ p: '0 12px' }}>
              {t('registration|invalidData')}
            </Alert>
          ) : null}
        </Box>
      </Box>
    </form>
  );
};

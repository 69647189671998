import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCountryLanguage } from '../hooks/useCountryLanguage';
import { getPageContent } from './Pages-helpers';

const ENV_DEV = process.env.NODE_ENV === 'development';

export default function GeneralPage() {
  const { lang, slug } = useParams();
  const countryLanguage = useCountryLanguage();
  const [pageData, setPageData] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const preview = searchParams.get('preview');
    getPageContent(slug, countryLanguage.language, countryLanguage.country, preview).then(cmsData => {
      const cmsSlugData = cmsData?.find(data => data.code === slug);
      if (cmsSlugData) {
        setPageData(cmsSlugData);
      } else {
        ENV_DEV && console.error('failed to fetch CMS data');
        navigate('../error/404');
      }
    });
  }, [slug, countryLanguage.language, countryLanguage.country, searchParams, navigate]);

  if (!lang || !slug) {
    ENV_DEV && console.log('GeneralPage error: ', lang, slug);
  }

  return <div>{pageData?.translationValue && parse(pageData.translationValue)}</div>;
}

import React, { Component } from 'react';
import { state } from '../data-state';
import { checkEmptyOrMinMaxOrLength, compareValues } from 'globals/utils/fieldValidations';
import { config } from '../data-config';
import {
  prepareFieldInStateForUpdate,
  prepareDeselectGroupForUpdate,
  prepareGroupForUpdate,
  cutTextAndShowError,
  cutDecimalAndShowError,
  cutIntegerAndShowError,
} from 'globals/context/context-helpers';
import { updatePostalCode } from '../components/EnterPickupLocation/EnterPickupLocation-helpers';
import { resetOptionsSchemaOnVasUnCheck } from '../components/DeliveryOptions/DeliveryOptions-helpers';
import { RECEIVER_PAYER, SENDER_PAYER } from '../../globals/constants';
import { updateCountryValue } from '../../globals/helpers/pickup-helpers';

export const PublicOrderContext = React.createContext();

export class PublicOrderProvider extends Component {
  state = state;
  // eslint-disable-next-line
  state = JSON.parse(JSON.stringify(state));

  componentDidMount = () => {
    let language = localStorage.getItem(config.locale.localStorageVariable) || config.locale.defaultLanguage;

    this.setState({
      languageCode: language,
    });
  };

  extendedSetState = (stateObject, params) => {
    let beforeUpdate = {};
    let afterUpdate = {};

    // Executes additional logic before update the state
    if (params !== undefined) {
      if (params.beforeUpdate && typeof params.beforeUpdate === 'function') {
        beforeUpdate = params.beforeUpdate(this, params, stateObject);
        if (typeof beforeUpdate === 'object' && beforeUpdate !== null) {
          beforeUpdate = JSON.parse(JSON.stringify(beforeUpdate));
          Object.assign(stateObject, beforeUpdate);
        }
      }
    }
    // Setting the new state and performs additional tasks on callback (if any)
    this.setState(stateObject, () => {
      const callbackState = {};

      // Executes additional logic after update the state
      if (params !== undefined) {
        if (params.afterUpdate && typeof params.afterUpdate === 'function') {
          afterUpdate = params.afterUpdate(this, params);
          if (typeof afterUpdate === 'object' && afterUpdate !== null) {
            afterUpdate = JSON.parse(JSON.stringify(afterUpdate));
            Object.assign(callbackState, afterUpdate);
          }
        }
      }
      if (Object.keys(callbackState).length > 0 && callbackState.constructor === Object) {
        this.setState(callbackState);
      }
    });
  };

  updateField = params => {
    let error = checkEmptyOrMinMaxOrLength(params);
    error = error === undefined ? false : error;
    let stateObject = {};

    // Text cut
    if (params.cutTextLimit && !isNaN(params.cutTextLimit) && params.cutTextLimit > 0) {
      params = cutTextAndShowError(params);
    }
    error = params.error === true ? true : error;

    let comparisonError = false;
    if (params.compare && params.compareValue !== undefined && params.compareValue !== '')
      comparisonError = compareValues(params.value, params.compareValue);

    let comparisonObj = {
      error: comparisonError,
      field: params.compareParameter,
    };

    if (params.name === 'shipmentRange') {
      updateCountryValue(this.state, params.value, this.state.shipmentTypesImpOrExp.value, stateObject);
    } else if (params.name === 'shipmentTypesImpOrExp') {
      updateCountryValue(this.state, this.state.shipmentRange.value, params.value, stateObject);
    }
    if (params.name === 'shipmentRange' || params.name === 'shipmentTypesImpOrExp') {
      updatePostalCode(this.state, stateObject.pickupCountry.value, stateObject.deliveryCountry.value, stateObject);
    }
    if (
      params.name === 'shipmentPayer' &&
      this.state.shipmentPayer.value !== params.value &&
      (params.value === RECEIVER_PAYER || params.value === SENDER_PAYER)
    ) {
      stateObject.pickupAddressResidential = {};
      stateObject.deliveryAddressResidential = {};
      stateObject.pickupAddressResidential.value = false;
      stateObject.deliveryAddressResidential.value = false;
    }

    // Update the current field
    Object.assign(
      stateObject,
      prepareFieldInStateForUpdate(
        this.state,
        params.name,
        params.value,
        error,
        params.compare ? comparisonObj : undefined,
      ),
    );

    // Overides the value of related field
    if (params.override && this.state[params.override.name]) {
      Object.assign(
        stateObject,
        prepareFieldInStateForUpdate(
          this.state,
          params.override.name,
          params.value,
          error,
          params.compare ? comparisonObj : undefined,
        ),
      );
    }

    // Deselecting related fields
    if (params.deselect) {
      params.deselect.forEach(deselect => {
        Object.assign(
          stateObject,
          prepareFieldInStateForUpdate(
            this.state,
            deselect,
            false,
            undefined,
            params.compare ? comparisonObj : undefined,
          ),
        );
      });
    }

    if (params.secondParam) {
      const secondParamKeys = Object.keys(params.secondParam);
      secondParamKeys.forEach(key => {
        stateObject[key] = params.secondParam[key];
      });
    }

    // Set state
    this.extendedSetState(stateObject, params);
  };

  updateGroupOfFields = params => {
    let error = checkEmptyOrMinMaxOrLength(params);
    const duplicationError = false;
    const group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    let stateObject = {};

    // Integer cutoff
    if (params.integerCutoff && !isNaN(params.integerCutoff) && params.integerCutoff > 0) {
      params = cutIntegerAndShowError(params);
    }
    error = params.error === true ? true : error;

    // Decimal cutoff
    if (params.decimalCutoff && !isNaN(params.decimalCutoff) && params.decimalCutoff > 0) {
      params = cutDecimalAndShowError(params, false);
    }
    error = params.error === true ? true : error;

    // Text cut
    if (params.cutTextLimit && !isNaN(params.cutTextLimit) && params.cutTextLimit > 0) {
      params = cutTextAndShowError(params);
    }
    error = params.error === true ? true : error;

    if (params.subGroupName) {
      if (typeof group[params.index][params.subGroupName][params.name] === 'object') {
        group[params.index][params.subGroupName][params.name].value = params.value;
        group[params.index][params.subGroupName][params.name].error = false;

        if (!params.skipError) {
          group[params.index][params.subGroupName][params.name].error = error;
        }
      } else {
        group[params.index][params.subGroupName][params.name] = params.value;
      }
    } else {
      if (group[params.index].code && group[params.index].code === params.name) {
        group[params.index].selectedStatus = params.value;
      } else if (typeof group[params.index][params.name] === 'object') {
        group[params.index][params.name].value = params.value;
        group[params.index][params.name].error = false;

        if (!params.skipError) {
          group[params.index][params.name].error = error;
        }
      } else {
        group[params.index][params.name] = params.value;

        if (!params.skipError) {
          group[params.index].error = error;
          group[params.index].duplicationError = duplicationError;
        }
      }
    }

    stateObject = {
      [params.groupName]: group,
    };

    if (params.groupName === 'shipmentDetailsRows') {
      stateObject.dimensions = JSON.parse(JSON.stringify(this.state.dimensions));

      // Update min loading meter value based on whether `non stackable` is ticked
      if (group[params.index].nonStackable.value) {
        stateObject.dimensions.min_item_loading_meter = config.min_nonstackable_loading_meter;
      } else {
        stateObject.dimensions.min_item_loading_meter = 0;
      }
    }

    // Set state
    this.extendedSetState(stateObject, params);
  };

  addAnotherGroupField = params => {
    const group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    let stateObject = {};

    group.push(params.item);
    stateObject[params.groupName] = group;

    // Set state
    this.extendedSetState(stateObject, params);
  };

  updateGroup = params => {
    let group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    let stateObject = {};

    if (params.deselect !== undefined) {
      group = prepareDeselectGroupForUpdate(group, params.deselect, params.selectedStatus, params.name);
    } else {
      group = prepareGroupForUpdate(group, params.selectedStatus, params.name);
    }

    if (params.groupName === 'additionalServices') {
      group[params.index].calculatedResult = null;

      // reset options schema on vas unchecked
      if (!params.value && group[params.index].groups) {
        group[params.index].errors = {};
        group[params.index].groups = resetOptionsSchemaOnVasUnCheck(
          JSON.parse(JSON.stringify(group[params.index].groups)),
        );
      }
    }

    stateObject = {
      [params.groupName]: group,
    };

    if (params.secondGroupName) stateObject[params.secondGroupName] = params.secondGroupValue;

    this.extendedSetState(stateObject, params);
  };

  removeGroupField = params => {
    const group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    let stateObject = {};

    group.splice(params.index, 1);
    stateObject[params.groupName] = group;

    // Set state
    this.extendedSetState(stateObject, params);
  };

  updateState = (newState, callback) => {
    this.setState(
      {
        ...newState,
      },
      callback,
    );
  };

  render() {
    return (
      <PublicOrderContext.Provider
        value={{
          state: this.state,
          updateGroup: this.updateGroup,
          extendedSetState: this.extendedSetState,
          updateField: this.updateField,
          updateGroupOfFields: this.updateGroupOfFields,
          addAnotherGroupField: this.addAnotherGroupField,
          removeGroupField: this.removeGroupField,
          updateState: this.updateState,
        }}
      >
        {this.props.children}
      </PublicOrderContext.Provider>
    );
  }
}

import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Input } from 'components';
import { Error } from 'components';
import DownloadShipmentIcon from '../../svgIcons/downloadShipmentIcon';
import { CONST, config } from '../../../data-config';
import { downloadShipmentHistoryCSV } from '../MyOnlineShipments-helpers';
import { resetPagination, fetchSavedShipmentsList } from '../../ActionUtils';

import './ShipmentSearch.css';

export default class ShipmentSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      downloadError: false,
      message: '',
    };
  }

  searchShipments = e => {
    e.preventDefault();

    setTimeout(() => {
      this.props.context.extendedSetState(
        { loader: true, pagination: resetPagination() },
        { afterUpdate: fetchSavedShipmentsList(this.props.context) },
      );
    }, 200);
  };

  onShipmentHistoryDownload = async () => {
    const downloadResult = await downloadShipmentHistoryCSV(this.props.context.state);

    if (!downloadResult.error) {
      this.props.onShowSuccessToaster('Shipment History Downloaded Successfully');
    } else {
      setTimeout(() => {
        this.setState({
          downloadError: downloadResult.error || false,
        });
      }, 5000);
    }
  };

  render() {
    const { context } = this.props;
    const disableShipmentHistory = context.state.savedShipments.length === 0;
    const disableShipmentCall = context.state.filterShipmentToDate.error || context.state.filterShipmentFromDate.error;

    return (
      <Translation>
        {t => (
          <div className=" frc__generic-row--wrapper">
            <div className="l-grid">
              <div className=" l-grid--w-100pc-s l-grid--w-70pc-m-m l-grid--w-70pc-m-s">
                <form
                  onSubmit={disableShipmentCall ? e => e.preventDefault() : this.searchShipments}
                  className="l-grid l-grid--center-s l-grid--center-m-s l-grid--left-m-m l-grid--bottom-s l-grid--w-100pc-s l-grid--w-100pc-m l-grid--w-100pc-m-s"
                >
                  <div className="l-grid--w-100pc-s l-grid--w-70pc-m l-grid--w-70pc-m-s  l-grid--left-s margin-bottom-1">
                    <Input
                      context={context}
                      disableonBlur={true}
                      config={config}
                      CONST={CONST}
                      tooltip={t(
                        `portalSavedShipments|Search by Company Name, Country, Reference, Product, Status or Shipment Number`,
                      )}
                      regEx={config.regEx.everything}
                      name="searchTerm"
                      data-testid="searchShipmentInp"
                      isRequired={context.state.searchTerm.isRequired}
                      label={t('general|labels|Search')}
                      className="frc__input--wrapper frc__generic--field-wrapper"
                      value={context.state.searchTerm.value}
                      hideAutoComplete={true}
                      lengthCheck={[RegExp(`^.{${0},${config.maxSearchTermDigits}}$`)]}
                      cutTextLimit={config.maxSearchTermDigits}
                      placeholder={t(`portalSavedShipments|Search by Company Name, Current Status or Shipment Number`)}
                    />
                  </div>
                  <div className="l-grid--w-100pc-s l-grid--w-20pc-m frc__shipment-search--button ">
                    <button className={`base-button  ${disableShipmentCall ? 'disabled' : ''} `} id="searchShipmentBtn">
                      <span>{t('general|labels|Search')}</span>
                    </button>
                  </div>
                </form>
              </div>

              <div
                className="
              l-grid--w-100pc-s l-grid--w-50pc-m-s l-grid--w-30pc-m frc__shipment-search--button frc__shipment--history "
              >
                <button
                  className={`base-button 
                    ${disableShipmentCall || disableShipmentHistory ? 'disabled' : ''}
                    `}
                  id="downloadShipmentHistoryBtn"
                  disabled={disableShipmentCall || disableShipmentHistory}
                  onClick={
                    disableShipmentCall || disableShipmentHistory ? null : () => this.onShipmentHistoryDownload()
                  }
                >
                  <span>
                    <DownloadShipmentIcon />
                    {t(`portalSavedShipments|Download Shipment History`)}
                  </span>
                </button>
              </div>
            </div>
            <div
              className="
              l-grid--w-100pc-s l-grid--w-40pc-s-l l-grid--w-50pc-m-s l-grid--w-30pc-m frc__shipment--history "
            >
              {this.state.downloadError ? (
                <Error
                  name={'addressSearchError'}
                  className="frc__input--error"
                  message={t('general|errors|General error')}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

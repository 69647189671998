import { config, CONST } from '../../../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest, getPropperAPIData } from 'globals/utils/requests';

export const loadCollectionAtTerminalValueModification = collectionAtTerminalValues => {
  const values = [];
  let value = {};
  const optionMap = {};

  collectionAtTerminalValues.forEach(terminalVal => {
    const option = {
      value: terminalVal.id,
      name:
        terminalVal.address && terminalVal.name
          ? terminalVal.name +
            '/' +
            terminalVal.address.street +
            '/' +
            terminalVal.address.postalCode +
            ' ' +
            terminalVal.address.cityName
          : '',
      skipTranslation: true,
      terminal: terminalVal,
    };

    value = terminalVal.defaultTerminal && option ? option.value : value;

    if (option) values.push(option);
  });

  optionMap.values = values;
  optionMap.value = value;

  return optionMap;
};

export const loadCollectionAtTerminal = async (stateObject, collectionAtTerminalIndex, routeState) => {
  const propperRouteState = getPropperAPIData(routeState);

  if (propperRouteState.deliveryCountry.value && propperRouteState.deliveryPostalCode.value) {
    const collectionAtTerminalUrl =
      apiLinks.collectionAtTerminal +
      '?countryCode=' +
      propperRouteState.deliveryCountry.value +
      '&postalCode=' +
      propperRouteState.deliveryPostalCode.value;

    await apiRequest(collectionAtTerminalUrl, 'GET').then(result => {
      if (result.status === CONST.STATUS_OK) {
        stateObject.additionalServices[collectionAtTerminalIndex].groups[0].options.forEach(options => {
          const terminalValues = loadCollectionAtTerminalValueModification(
            result.data.terminals ? result.data.terminals : [],
          );

          if (options[0].optionCode === config.OptionFieldTypes.ListCOT) {
            options[0].values = terminalValues.values;
            options[0].value = terminalValues.value;
            options[0].defaultValueAvailable = true;
          }
        });
      }
    });
  }
};

import { GLOBAL_CONST, globalConfig } from 'globals/data-config-global';

const componentConfig = {
  maxAllowedPhoneNumbers: 1,
  maxPhoneNumberDigits: 17,
  maxAllowedAccountNumbers: 10,
  maxAllowedPalletAccountNumbers: 99999,
  defaultNumberOfPallets: 2,
  minNumberOfPallets: 2,
  maxNumberOfPallets: 270,
  palletPickupProductCode: 900,
  maxInstructionsLength: 140,
  domesticCountriesPalletPickup: [
    {
      value: 'SE',
      example: '26234',
    },
  ],
};

const COMPONENT_CONST = {
  API_DOMAIN: 'palletPickup',
  ANALYTICS: 'empty pallet pickup',
  ADDRESS_ERROR_CODE: 422,
  PALLET_PICKUP_PREFIX: 'PPR',
};

componentConfig.domesticCountriesPalletPickup.forEach(country => (country.name = country.value));

export const config = Object.assign({}, globalConfig, componentConfig);
export const CONST = Object.assign({}, GLOBAL_CONST, COMPONENT_CONST);

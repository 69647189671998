/**
 * Converts QuoteSummary to a structure which is more suitable for backend and DB.
 */
export const quoteSummaryToPriceBreakdown = (quoteSummary: QuoteSummary): PriceBreakdown => {
  const additionalServiceToItem = (as: AdditionalService): BreakdownItem => ({
    kind: 'ADDITIONAL_SERVICE',
    code: as.code,
    price: as.price,
  });

  const surchargeToItem = (surcharge: Surcharge): BreakdownItem => ({
    kind: 'SURCHARGE',
    code: surcharge.label,
    price: surcharge.calculatedResult,
  });

  return {
    totalPriceNoVatNoMarkup: quoteSummary.TotalPriceNoVATNOMarkup,
    freightCost: quoteSummary.FreightCost?.value
        ? quoteSummary.FreightCost?.calculatedResult
        : undefined,
    vat: quoteSummary.VAT?.calculatedResult,
    totalPriceIncVat: quoteSummary.TotalPriceIncVAT?.calculatedResult,
    items: [
      ...(quoteSummary.additionalServices || []).map(as => additionalServiceToItem(as)),
      ...(quoteSummary.surcharges || []).map(surcharge => surchargeToItem(surcharge)),
    ],
  };
};

/**
 * Converts PriceBreakdown stored in the DB back to the QuoteSummary structure.
 */
export const priceBreakdownToQuoteSummary = (priceBreakdown: PriceBreakdown): QuoteSummary => {
  return {
    TotalPriceNoVATNOMarkup: priceBreakdown.totalPriceNoVatNoMarkup,
    FreightCost: {
      calculatedResult: priceBreakdown.freightCost,
      value: priceBreakdown.freightCost,
    },
    VAT: { calculatedResult: priceBreakdown.vat },
    TotalPriceIncVAT: { calculatedResult: priceBreakdown.totalPriceIncVat },
    additionalServices: priceBreakdown.items
      .filter(item => item.kind === 'ADDITIONAL_SERVICE')
      .map(item => ({ code: item.code, price: item.price })),
    surcharges: priceBreakdown.items
        .filter(item => item.kind === 'SURCHARGE')
        .map(item => ({ label: item.code, calculatedResult: item.price }))
    ,
  };
};

interface QuoteSummary {
  TotalPriceNoVATNOMarkup?: string;
  FreightCost: {
    calculatedResult?: string,
    value?: string, // this is needed, because it is being checked by function "noPriceAvailable"
  };
  VAT: { calculatedResult?: string };
  TotalPriceIncVAT: { calculatedResult?: string };
  additionalServices: AdditionalService[];
  surcharges: Surcharge[];
}

interface AdditionalService {
  code: string;
  price: string;
}

interface Surcharge {
  label: string;
  calculatedResult: string;
}

interface PriceBreakdown {
  totalPriceNoVatNoMarkup?: string;
  freightCost?: string;
  vat?: string;
  totalPriceIncVat?: string;
  items: BreakdownItem[];
}

type QuoteSummaryItemKind = 'ADDITIONAL_SERVICE' | 'SURCHARGE';

type BreakdownItem = {
  kind: QuoteSummaryItemKind;
  code: string;
  price: string;
};

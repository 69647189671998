import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import {
  CONSIGNEE_CUSTOMER_TYPE,
  CONSIGNOR_CUSTOMER_TYPE,
  EXPORT_SHIPMENT_TYPE_CODE,
  IMPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE_CODE,
} from '../../../globals/constants';
import { CONST } from '../../data-config';

const prepareRequestJSON = (state, productCode) => {
  let params = {};

  params.body = {
    productCode: productCode,
    weight: state.totals.shipmentDetailsRows.weight.value,
    volume: state.totals.shipmentDetailsRows.volume.value,
    loadingMetres: state.totals.shipmentDetailsRows.loadingMeter.value,
    importExport:
      state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE
        ? IMPORT_SHIPMENT_TYPE_CODE
        : EXPORT_SHIPMENT_TYPE_CODE,
  };
  params.body.pieces = [];

  state.shipmentDetailsRows.forEach(row => {
    if (row.volume.value) row.volume.value = row.volume.value.toString().replace(',', '.');
    if (row.weight.value) row.weight.value = row.weight.value.toString().replace(',', '.');
    if (row.loadingMeter.value) row.loadingMeter.value = row.loadingMeter.value.toString().replace(',', '.');

    let pieces = {
      length: row.length?.value || null,
      width: row.width?.value || null,
      height: row.height?.value || null,
      volume: row.volume?.value || null,
      weight: row.weight?.value || null,
      loadingMetres: row.loadingMeter?.value || null,
      numberOfPieces: row.quantity.value,
      stackable: !row.nonStackable.value,
    };

    params.body.pieces.push(pieces);
  });

  params.body.parties = [];

  let Consignor = {
    type: CONSIGNOR_CUSTOMER_TYPE,
    address: {
      postalCode: state.pickupPostalCode.value,
      countryCode: state.pickupCountry.value,
    },
    id: state.accountNumber.value,
  };
  params.body.parties.push(Consignor);

  let Consignee = {
    type: CONSIGNEE_CUSTOMER_TYPE,
    address: {
      postalCode: state.deliveryPostalCode?.value || null,
      countryCode: state.deliveryCountry?.value || null,
    },
  };
  params.body.parties.push(Consignee);

  return params.body;
};

export const chargeableWeight = async (state, selectedProduct = '', loading = false) => {
  if (selectedProduct || (state.selectedProduct && state.selectedProduct.name)) {
    if (!loading) {
      document.getElementById('fcp-delivery-options-loader').classList.add('is-loading');
    }

    const productData = selectedProduct ? state[selectedProduct.code] : state[state.selectedProduct.code];
    const url = apiLinks.getChargeableWeight;

    let params = {};
    let stateToSet = {};

    if (productData) {
      params.body = prepareRequestJSON(state, productData.code);

      await apiRequest(url, 'POST', params)
        .then(result => {
          if (result.status === CONST.STATUS_OK) {
            stateToSet.value = result.data.chargeableWeight;
            stateToSet.error = false;
          } else {
            stateToSet.value = '';
            stateToSet.error = true;
          }
          if (!loading) document.getElementById('fcp-delivery-options-loader').classList.remove('is-loading');
        })
        .catch(() => {
          stateToSet.value = '';
          stateToSet.error = true;
          if (!loading) document.getElementById('fcp-delivery-options-loader').classList.remove('is-loading');
        });

      return stateToSet;
    }
  }
};

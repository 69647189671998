// ----------------- CONTEXT ADAPTERS -----------------
// - to enable partial migration from context
export const parseDgFormErrors = ({ errors, contextDangerousGoodGroup }) => {
  if (!errors || !contextDangerousGoodGroup) return contextDangerousGoodGroup;
  const errorFields = Object.keys(errors);
  const updatedDangerousGoods = structuredClone(contextDangerousGoodGroup);

  for (const key of Object.keys(updatedDangerousGoods)) {
    const value = updatedDangerousGoods[key];
    if (typeof value !== 'string') {
      updatedDangerousGoods[key] = { ...value, error: errorFields?.includes(key) };
    }
  }
  return updatedDangerousGoods;
};

export const parseDgFormData = ({ formData, contextDangerousGoodGroup, clearErrors = false }) => {
  if (!formData || !contextDangerousGoodGroup) return contextDangerousGoodGroup;
  const updatedDangerousGoods = structuredClone(contextDangerousGoodGroup);

  for (const key of Object.keys(formData)) {
    updatedDangerousGoods[key] = {
      ...updatedDangerousGoods[key],
      // packageCode is autcomplete input which has object options as values so we have to select the property here
      value: key === 'packageCode' ? formData[key]?.unCode || null : formData[key],
      ...(clearErrors && { error: false }),
    };
  }

  return updatedDangerousGoods;
};

export const contextStoreDgErrors = ({ context, errors, shipmentRowIndex, id, values }) => {
  const newState = structuredClone(context.state);
  const updatedDangerousGoods = parseDgFormErrors({
    errors,
    contextDangerousGoodGroup: context.state.shipmentDetailsRows[shipmentRowIndex].dangerousGoodGroup.find(
      item => item.id === id,
    ),
  });

  const groupIndex = newState.shipmentDetailsRows[shipmentRowIndex].dangerousGoodGroup.findIndex(
    item => item.id === id,
  );
  newState.shipmentDetailsRows[shipmentRowIndex].dangerousGoodGroup[groupIndex] = updatedDangerousGoods;
  context.updateState(newState);
};

export const contextStoreDgData = ({ context, formData, shipmentRowIndex, id, clearErrors = true }) => {
  const newState = structuredClone(context.state);
  const updatedDangerousGoods = parseDgFormData({
    formData,
    contextDangerousGoodGroup: context.state.shipmentDetailsRows[shipmentRowIndex].dangerousGoodGroup.find(
      item => item.id === id,
    ),
    clearErrors,
  });

  const groupIndex = newState.shipmentDetailsRows[shipmentRowIndex].dangerousGoodGroup.findIndex(
    item => item.id === id,
  );
  newState.shipmentDetailsRows[shipmentRowIndex].dangerousGoodGroup[groupIndex] = updatedDangerousGoods;
  context.updateState(newState);
};

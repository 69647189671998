import React, { Component } from 'react';
import { Typography } from '@mui/material';
import { Translation } from 'react-i18next';

import './../../css/global/_variables.css';
// import './../../css/global/_icons.css';
// import './../../css/base/iconfont.css';
// import './../../css/base/react-datepicker.css';
import 'globals/utils/debugHelpers';
import cssVars from 'css-vars-ponyfill';

import { SavedShipmentsProvider, SavedShipmentsContext } from '../../context/context';
import getBaseUrl from 'globals/utils/getUrlBase';
import MyOnlineShipments from '../MyOnlineShipments/MyOnlineShipments';
import { SuccessMessage } from 'components';
import { CONST, config } from '../../data-config';

import '../../css/styles.css';
import './AppSavedShipments.css';
import { ROUTES } from 'router';

const ENV_DEV = process.env.NODE_ENV === 'development';

export default class AppSavedShipments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewShipments: false,
      succesMessage: '',
    };
  }

  componentDidMount() {
    cssVars();
  }

  showSuccessToaster = message => {
    this.setState({
      message,
    });
    setTimeout(() => {
      this.setState({
        message: '',
      });
    }, config.toasterTimeoutInMilliSeconds);
  };

  render() {
    return (
      <Translation>
        {t => (
          <div className={ENV_DEV ? 'l-view' : ''}>
            <div className="c-page-headline component-small has-rte component-margin frc__heading">
              <div className="c-page-headline--wrapper">
                <Typography variant="h1" mt={{ xs: 0, sm: 2 }}>
                  {t('general|pageTitles|savedShipments')}
                </Typography>
              </div>
            </div>
            <SavedShipmentsProvider>
              <div className="frc__app">
                <SavedShipmentsContext.Consumer>
                  {context => (
                    <Translation>
                      {t => (
                        <>
                          <MyOnlineShipments
                            context={context}
                            onShowSuccessToaster={this.showSuccessToaster}
                            baseUrl={getBaseUrl(ROUTES.portal)}
                          />

                          {this.state.message && <SuccessMessage CONST={CONST} t={t} message={this.state.message} />}
                        </>
                      )}
                    </Translation>
                  )}
                </SavedShipmentsContext.Consumer>
              </div>
            </SavedShipmentsProvider>
          </div>
        )}
      </Translation>
    );
  }
}

import React, { Component } from 'react';
import { MyProvider, MyContext } from '../../context/context';

import ResetPassword from '../ResetPassword/ResetPassword';

export default class AppResetPassword extends Component {
  componentDidMount() {
    this.setState({ languageCode: localStorage.getItem('i18nextLng') });
  }

  render() {
    return (
      <MyProvider>
        <MyContext.Consumer>{context => <ResetPassword context={context} />}</MyContext.Consumer>
      </MyProvider>
    );
  }
}

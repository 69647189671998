import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import BookingConfirmation from '../BookingConfirmation/BookingConfirmation';
import TermsAndConditions from '../TermsAndSubmit/TermsAndConditions';
import './ReviewAndBook.css';
import UploadDocuments from './UploadDocuments/UploadDocuments';
import { apiRequest } from 'globals/utils/requests';
import { formatDate } from 'globals/utils/formatting';
import { formShipmentQuoteData } from './FormSubmitData/FormSubmitData';
import { Error } from 'components';
import Captcha from '../TermsAndSubmit/Captcha/Captcha';
import { analytics } from 'globals/utils/analytics';
import { disableOtherTabs } from '../AppTabs/AppTabs-helpers';
import {
  resetTermsAndConditions,
  getCaptcha,
  validateAndSubmit,
  setAddressCookie,
  getThankYouStatus,
} from './ReviewAndBook-helpers';
import {
  fromDateComponent,
  reviewAndBookDatePresenter,
  toDateComponent,
} from '../../../globals/helpers/side-summary-helpers';
const ENV_DEV = process.env.NODE_ENV === 'development';

class ReviewAndBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookShipment: false,
      generalError: false,
      fixedDate: false,
      timeDefiniteUnloading: false,
      uploadError: false,
    };
    this.UploadDocumentsRef = React.createRef();
    this._isMounted = false;
  }

  setInitialState = () => {
    resetTermsAndConditions(this.props);
    getCaptcha(this._isMounted, this.props);
    this.setFixedDeliveryDate();
  };

  setFixedDeliveryDate = () => {
    // set fixed delivery date if vas present
    const fixedDateVas = this.props.context.state.additionalServices.find(
      vas => vas.code === 'fixedDeliveryDate' && vas.value,
    );

    if (fixedDateVas && fixedDateVas.groups && fixedDateVas.groups.length > 0) {
      fixedDateVas.groups.forEach(group => {
        group.options.forEach(option => {
          const FDDOption = option.find(option => option.type === 'LTCdate');

          if (FDDOption && FDDOption.value) {
            this.setState({ fixedDate: formatDate(FDDOption.value) });
          }
        });
      });
    }

    // set time definite unloading
    const timeDefiniteUnloading = this.props.context.state.additionalServices.find(
      vas => vas.code === 'timeDefiniteUnloading' && vas.value,
    );

    if (timeDefiniteUnloading && timeDefiniteUnloading.groups && timeDefiniteUnloading.groups.length > 0) {
      timeDefiniteUnloading.groups.forEach(group => {
        group.options.forEach(option => {
          const timeDefiniteUnloadingOption = option.find(option => option.type === 'DateTime');

          if (timeDefiniteUnloadingOption && timeDefiniteUnloadingOption.value) {
            this.setState({ timeDefiniteUnloading: formatDate(timeDefiniteUnloadingOption.value) });
          }
        });
      });
    }
  };

  componentDidMount = () => {
    this._isMounted = true;
    this.setInitialState();
    this.props.context.updateState({ tab4OrTab5Touched: true });
    if (process.env.NODE_ENV !== 'test') {
      window.scrollTo(0, 0);
    }
  };

  submitOrder = async uploadDocresult => {
    let submitShipment = {};
    const shipmentData = formShipmentQuoteData(this.props.context);
    const shouldPickupRequestCondition = shipmentData.shipment.isPickupRequired;
    const headerParams = {
      transactionId: this.props.context.state.transactionId,
      ...(uploadDocresult?.result && { UniqueCode: uploadDocresult.uploadId }),
    };

    setAddressCookie(shipmentData);

    const shipmentRequest = apiRequest(apiLinks.postPublicOrder, 'POST', {
      body: shipmentData,
      headers: headerParams,
    });

    await shipmentRequest.then(result => {
      if (this._isMounted) {
        submitShipment = result;
      }
    });
    if (this._isMounted) {
      if (submitShipment.status === CONST.STATUS_OK) {
        ENV_DEV && console.success('API Request successful ...');
        analytics('order', CONST.ANALYTICS);

        this.setState(
          {
            bookShipment: false,
          },
          () => {
            getThankYouStatus(
              submitShipment.data,
              shouldPickupRequestCondition,
              shipmentData.shipment.shippingDate,
              this.props,
              this.state,
            );
          },
        );
      } else if (submitShipment.status === 403) {
        this.captchaReload();
      } else {
        this.setShipmentAndGeneralError(false, false);
        this.props.context.updateState({ bookShipmentAPIError: true });
      }
    }
  };

  captchaReload = () => {
    if (this._isMounted) {
      this.setState({
        bookShipment: false,
        generalError: true,
      });
      const captacha = JSON.parse(JSON.stringify(this.props.context.state));
      captacha.error = true;
      this.props.context.updateState({
        captcha: captacha,
      });
      getCaptcha(this._isMounted, this.props);
    }
  };

  onGoClick = async () => {
    this.setState({
      bookShipment: true,
      generalError: false,
    });
    this.props.context.updateState({ bookShipmentAPIError: false });

    if (!validateAndSubmit(this.props)) {
      this.setState({
        generalError: false,
      });

      let uploadDocresult = {};

      disableOtherTabs(this.props, true, [5], config.tabs.tab5);

      uploadDocresult = await this.UploadDocumentsRef.current.uploadToServer({
        answer: this.props.context.state.captcha.value,
        token: this.props.context.state.captcha.token,
      });

      if (uploadDocresult && uploadDocresult.result) {
        await this.submitOrder(uploadDocresult);
      } else if (uploadDocresult && uploadDocresult.captchaError) {
        this.captchaReload();
      } else if (uploadDocresult && !uploadDocresult.result) {
        this.setState({ bookShipment: false, uploadError: true });
      } else {
        this.setShipmentAndGeneralError(false, false);
        this.props.context.updateState({ bookShipmentAPIError: true });
      }
    } else {
      this.setShipmentAndGeneralError(false, true);
    }
    if (this._isMounted) {
      disableOtherTabs(this.props, false, [5], config.tabs.tab5);
    }
  };

  setShipmentAndGeneralError = (bookShipment, generalError) => {
    this.setState({
      bookShipment: bookShipment,
      generalError: generalError,
    });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    let weight = null;
    const { state } = this.props.context;
    if (
      this.props.context.state.totals.shipmentDetailsRows &&
      this.props.context.state.totals.shipmentDetailsRows.weight.value
    ) {
      weight = this.props.context.state.totals.shipmentDetailsRows.weight.value;
    }
    const { selectedProduct } = state;

    return (
      <div className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <>
              <section className="frc__review-summary">
                <h4 className="frc__review-summary__title">{selectedProduct.product}</h4>

                <div className="frc__review-summary__box" id="summaryBox1">
                  <span>{t(`general|Chargeable weight`)}:</span>
                  <strong>
                    {this.props.context.state.chargeableWeight
                      ? this.props.context.state.chargeableWeight.toFixed(config.chargeableWeight.maxDecimals)
                      : weight}
                    {' ' + t('general|kg')}
                  </strong>
                </div>

                <div className="frc__review-summary__box" id="summaryBox2">
                  {fromDateComponent(state, reviewAndBookDatePresenter)}
                </div>

                <div className="frc__review-summary__box" id="summaryBox3">
                  {toDateComponent(state, reviewAndBookDatePresenter)}
                </div>
              </section>
              <UploadDocuments context={this.props.context} ref={this.UploadDocumentsRef} />
              <BookingConfirmation context={this.props.context} />
              <TermsAndConditions context={this.props.context} />
              <Captcha getCaptchaOnClick={() => getCaptcha(this._isMounted, this.props)} context={this.props.context} />

              <section className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper l-grid--between-s">
                <button
                  id="backToSchedulePickup"
                  className={`base-button base-button--white l-grid--w-25pc-w  ${
                    this.state.bookShipment ? 'disabled' : ''
                  } `}
                  onClick={() =>
                    this.state.bookShipment
                      ? null
                      : this.props.context.state.returnProdStatus
                      ? this.props.previousTab(config.tabs.tab3, 3)
                      : this.props.previousTab(config.tabs.tab4, 4)
                  }
                >
                  {this.props.context.state.returnProdStatus ? (
                    <span>{t(`general|Back to Delivery Options`)}</span>
                  ) : (
                    <span>{t(`general|Back to Schedule Pickup`)}</span>
                  )}
                </button>

                <button
                  id="bookShipmentBtn"
                  className={
                    'base-button base-button--wide l-grid--w-25pc-w ' +
                    (this.state.bookShipment ? ' is-loading--right ' : '')
                  }
                  onClick={() => this.onGoClick()}
                  disabled={this.state.bookShipment}
                >
                  <span>{t(`general|Book Shipment`)}</span>
                </button>
              </section>
              <div className="l-grid--right-s">
                {
                  <Error
                    name="multipleUploadFileError"
                    message={
                      this.state.generalError
                        ? t('general|errors|Please fill properly all necessary fields')
                        : this.props.context.state.bookShipmentAPIError
                        ? t('general|errors|General error') +
                          '. <br> ' +
                          t('general|Try again or call technical support.') +
                          ' ' +
                          t('general|or') +
                          ' ' +
                          `<strong><a href="${t('pageLinks|chat')}"
                                              class ="frc__chat-with-us"
                                              target="_blank" rel="noopener noreferrer">${t(
                                                `general|Chat with us`,
                                              )}</a></strong>` +
                          ' <br> ' +
                          t('general|ID') +
                          ': ' +
                          this.props.context.state.transactionId
                        : this.state.uploadError
                        ? t('general|Unsupported file format. Please use one of following') +
                          ': ' +
                          t('portalOrder|pdf, doc, xls, docx, xlsx, csv')
                        : ''
                    }
                  />
                }
              </div>
            </>
          )}
        </Translation>
      </div>
    );
  }
}

export default ReviewAndBook;

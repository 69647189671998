import { returnProperValueOrNull } from 'globals/utils/fieldValidations';

export const formPieces = shipmentState => {
  let shipmentDetails = shipmentState.shipmentDetailsRows;
  const shipmentList = [];

  shipmentDetails.forEach(shipment => {
    shipmentList.push(shipmentData(shipment));
  });

  return shipmentList;
};

const shipmentData = shipmentRow => {
  return {
    height: shipmentRow.height?.value || null,
    length: shipmentRow.length?.value || null,
    loadingMeters: returnProperValueOrNull(shipmentRow.loadingMeter.value),
    loadingMetres: returnProperValueOrNull(shipmentRow.loadingMeter.value),
    numberOfPieces: shipmentRow.quantity?.value || null,
    stackable: !shipmentRow.nonStackable.value,
    volume: returnProperValueOrNull(shipmentRow.volume.value, 3) || null,
    weight: returnProperValueOrNull(shipmentRow.weight.value, 1) || null,
    width: shipmentRow.width?.value || null,
  };
};

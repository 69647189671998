import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { Input, Error, Button } from 'components';
import 'globals/utils/debugHelpers';
import cssVars from 'css-vars-ponyfill';
import { Typography } from '@mui/material';

import './../../css/global/_variables.css';
import './ForgotPassword.css';

import { apiRequest } from 'globals/utils/requests';
import getBaseUrl from 'globals/utils/getUrlBase';
import { resetErrors, canProceedToApiCall } from './ForgotPassword-helpers';

const ENV_DEV = process.env.NODE_ENV === 'development';
export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestCompleted: false,
      technicalError: false,
      isLoadingForgotPassword: false,
      formError: false,
      buttonDisabled: false,
    };
  }

  componentDidMount() {
    cssVars();
  }

  checkAndSubmit = async () => {
    const { context } = this.props;
    const { email } = context.state;
    const { stateToSet, contextStateToSet } = resetErrors(context);
    const canProceed = canProceedToApiCall(email.value);

    stateToSet.buttonDisabled = canProceed;
    stateToSet.isLoadingForgotPassword = canProceed;
    stateToSet.formError = !canProceed;

    this.setState(stateToSet, () => {
      if (canProceed) {
        const url = apiLinks.postForgotPassword;
        const params = {
          headers: {
            Referer: getBaseUrl(),
            accept: 'text/csv',
          },
          body: {
            email: email.value,
          },
        };

        ENV_DEV && console.message('API request started');
        apiRequest(url, 'POST', params)
          .then(result => {
            if (result.status === CONST.STATUS_ACCEPTED) {
              ENV_DEV && console.message('API request successful');
            } else {
              ENV_DEV && console.error('API request failed');
              stateToSet.technicalError = true;
            }
          })
          .catch(err => {
            ENV_DEV && console.error('error: ', err);
            stateToSet.technicalError = true;
          });

        stateToSet.requestCompleted = true;
        stateToSet.isLoadingForgotPassword = false;
        this.setState(stateToSet);
      } else {
        contextStateToSet.email = {
          ...context.state.email,
          error: true,
        };
      }

      context.updateState(contextStateToSet);
    });
  };

  render() {
    const { context } = this.props;
    const { email } = context.state;
    const { requestCompleted, technicalError, formError } = this.state;

    return (
      <div className={ENV_DEV ? 'l-view' : ''}>
        <Translation>
          {t => (
            <div className="c--fcp-header-spacer">
              <div className="c-page-headline component-small has-rte component-margin frc__heading">
                <div className="c-page-headline--wrapper">
                  <Typography variant="h1" mt={{ xs: 0, sm: 2 }}>
                    {t(`forgotPassword|FORGOT YOUR PASSWORD?`)}
                  </Typography>
                </div>
                <p className="has-rte">
                  {t(
                    `forgotPassword|Please enter your e-mail address below and you will receive a link to reset password shortly!`,
                  )}
                </p>
              </div>
              <div className="l-grid l-grid--center-s">
                <div className="l-grid--w-100pc-s l-grid--w-80pc-m-s l-grid--w-50pc-m frc__forgot-password">
                  <div className="l-grid--left-s">
                    <div className="l-grid--w-100pc-s" data-form-item-id="item-email">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        placeholder={CONST.PLACEHOLDER_EMAIL}
                        label={t(`general|E-mail`) + ':'}
                        name="email"
                        isRequired={true}
                        value={email.value}
                        cutTextLimit={config.maxEmailLength}
                        updateOnParent={(params, context) => {
                          this.setState({
                            buttonDisabled: false,
                            technicalError: false,
                            requestCompleted: false,
                          });
                          context.updateField(params);
                        }}
                      />
                      {email.error && (
                        <Error
                          name="email"
                          className="frc__input--error frc__forgot-password-message--error"
                          message={t(`forgotPassword|Please enter an email address in the format`)}
                        />
                      )}
                    </div>

                    <div className="l-grid--w-40pc-m frc__forgot-password-submit-item has-button-submit c-calculator">
                      <div className="l-grid--w-100pc-s l-grid--w-40pc-s-l l-grid--w-40pc-m">
                        <div
                          className={' l-grid--w-100pc-s ' + (this.state.isLoadingForgotPassword ? ' is-loading ' : '')}
                        />
                      </div>
                      <Button
                        id="resetPasswordBtn"
                        dataTestId="resetPasswordBtn"
                        label={t('general|labels|buttons|Reset password')}
                        submit={this.checkAndSubmit}
                        disabled={this.state.buttonDisabled}
                        className={
                          'l-grid--w-100pc-s base-button' +
                          (this.state.isLoadingForgotPassword ? ' hidden ' : '') +
                          (this.state.buttonDisabled ? ' is-disabled ' : '')
                        }
                      />
                    </div>
                    {!email.error && !technicalError && requestCompleted && !formError && (
                      <div>
                        <p className="frc__forgot-password-message--success" id="password_reset_success_text">
                          {t(
                            `forgotPassword|Email with reset password has been sent to your email. Please check your inbox`,
                          )}
                        </p>
                      </div>
                    )}
                    {formError && (
                      <Error
                        name="formError"
                        className="frc__reset-password-message frc__change-password-message--error"
                        message={t('general|errors|Please fill properly all necessary fields')}
                      />
                    )}
                    {technicalError && (
                      <Error
                        name="technicalError"
                        className="frc__input--error frc__forgot-password-message--error"
                        message={t(`general|errors|Due to a technical error, the password cannot be changed`)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Translation>
      </div>
    );
  }
}

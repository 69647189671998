import { config, CONST } from '../../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import { formatDate } from 'globals/utils/formatting';

export const resetPagination = () => {
  return {
    page: 1,
    total: 0,
    pageSize: config.paginationInitialNumberOfItems,
    lastPage: 1,
  };
};

export const formRequestBodyForTemplateCall = (templateSearchTerm, pagination) => {
  return {
    page: pagination.page,
    pageSize: pagination.pageSize,
    searchTerm: templateSearchTerm,
    senderAccountFilter: [],
    sortOrder: 'createdAt',
    statusFilter: [],
    templateTypes: [3],
  };
};

const templateListBackendCall = async (templateSearchTerm, pagination) => {
  const resultMap = {};

  await apiRequest(apiLinks.fetchTemplateList, 'POST', {
    body: formRequestBodyForTemplateCall(templateSearchTerm, pagination),
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const fetchFavoriteTemplate = async () => {
  const resultMap = {};

  await apiRequest(apiLinks.favoritesShipment, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.newestTemplates = result.data.newestTemplates
          ? formatSavedShipmentData(result.data.newestTemplates)
          : [];
        resultMap.popularTemplates = result.data.popularTemplates
          ? formatSavedShipmentData(result.data.popularTemplates)
          : [];
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const fetchTemplateList = async (templateSearchTerm, pagination) => {
  let calculatePagination;
  let formattedTemplateData;
  const templateResult = await templateListBackendCall(templateSearchTerm, pagination);

  if (!templateResult.error) {
    // set pagination
    formattedTemplateData = formatSavedShipmentData(templateResult.data.savedShipments);
    formattedTemplateData = formattedTemplateData.sort((a, b) => b.createdAt - a.createdAt);

    calculatePagination = {
      page: templateResult.data.page,
      total: templateResult.data.total,
      lastPage: templateResult.data.lastPage,
    };

    calculatePagination.pageSize = pagination.pageSize;

    return {
      pagination: calculatePagination,
      paginationMaster: calculatePagination,
      searchResults: formattedTemplateData,
      searchResultsMaster: formattedTemplateData,
      apiError: false,
      loader: false,
      manualSearch: false,
      remoteSearchTerm: templateSearchTerm,
    };
  } else {
    return {
      apiError: templateResult.error || false,
      loader: false,
      pagination: resetPagination(),
      paginationMaster: resetPagination(),
      searchResults: [],
      searchResultsMaster: [],
      manualSearch: false,
      remoteSearchTerm: templateSearchTerm,
    };
  }
};

const formatSavedShipmentData = savedShipments => {
  const modifiedSavedShipments = savedShipments.map((shipment, rowTableIndex) => {
    shipment.rowTableIndex = rowTableIndex;

    shipment.sender = shipment.savedShipmentParties
      ? shipment.savedShipmentParties.find(party => party.type.toLowerCase() === 'sender')
      : {};

    shipment.receiver = shipment.savedShipmentParties
      ? shipment.savedShipmentParties.find(party => party.type.toLowerCase() === 'receiver')
      : {};

    shipment.totalQuantity =
      shipment.savedShipmentPieces && shipment.savedShipmentPieces.length > 0
        ? shipment.savedShipmentPieces.reduce((a, b) => a + (b.quantity * 1 || 0), 0)
        : 0;
    shipment.totalWeight =
      shipment.savedShipmentPieces && shipment.savedShipmentPieces.length > 0
        ? shipment.savedShipmentPieces.reduce((a, b) => a + (b.weight * 1 || 0), 0)
        : 0;

    shipment.formattedDate = shipment.createdAt ? formatDate(shipment.createdAt) : '';
    shipment.sender.country = shipment.sender ? shipment.sender.countryCode : '';
    shipment.receiver.country = shipment.receiver ? shipment.receiver.countryCode : '';

    return shipment;
  });

  return modifiedSavedShipments;
};

import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Alert, Skeleton } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiRequest } from '../../../globals';
import { apiLinks } from '../../../config/api-config';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { userQueryKey } from '../../../hooks/api/useGetUser';
import { Close, Save } from '@mui/icons-material';
import { PROFILE_SECTIONS } from './EditProfile';
import { Button } from '../Button';

/**
 * Also known as Collitypes
 */
export const ShipmentTypesForm = ({ userData, setReadOnly }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const switchToReadOnly = () => {
    setReadOnly(prev => ({
      ...prev,
      [PROFILE_SECTIONS.shipmentTypes]: {
        ...prev.shipmentTypes,
        readOnly: true,
      },
    }));
  };

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      shipmentTypes: userData?.colliTypes,
    },
  });

  const { isFetching, data: shipmentTypeOptions } = useQuery({
    queryKey: ['SHIPMENT_TYPES_QUERY'],
    queryFn: async () => {
      const result = await apiRequest(apiLinks.getCollitypes, 'GET');
      if (result.data) {
        const options = result.data.map(item => ({ label: item, value: item }));
        return options;
      }
    },
  });

  const { mutate } = useMutation({
    mutationFn: async values => {
      try {
        const params = {
          body: {
            colliTypes: values.shipmentTypes,
          },
        };

        await apiRequest(apiLinks.getCollitypes, 'PUT', params);

        toast(
          <Alert severity="success" variant="standard">
            {t('editProfile|shipmentTypesUpdateSuccess')}
          </Alert>,
        );
        queryClient.invalidateQueries(userQueryKey);
        switchToReadOnly();
      } catch (error) {
        console.error('Error while updating collitypes');
        toast(
          <Alert severity="error" variant="standard">
            {t('editProfile|shipmentTypesUpdateError')}
          </Alert>,
        );
      }
    },
  });

  const onSubmit = values => {
    mutate(values);
  };

  if (isFetching) {
    return <Skeleton variant="rounded" width="100%" height={375} />;
  }

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 20,
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          <Controller
            name="shipmentTypes"
            control={control}
            render={({ field }) => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > *': {
                      flex: '1 0 250px',
                    },
                  }}
                >
                  {shipmentTypeOptions.map(item => (
                    <FormControlLabel
                      key={item.value}
                      label={t(`general|labels|shipmentType|${item.value}`)}
                      control={
                        <Checkbox
                          sx={{ '& .MuiSvgIcon-root': { fontSize: '24px' } }}
                          value={item.value}
                          // For some reason codesandbox doesn't support `field.value.includes(...)`
                          checked={field.value.some(existingValue => existingValue === item.value)}
                          onChange={(event, checked) => {
                            if (checked) {
                              field.onChange([...field.value, event.target.value]);
                            } else {
                              field.onChange(field.value.filter(value => value !== event.target.value));
                            }
                          }}
                        />
                      }
                    />
                  ))}
                </Box>
              );
            }}
          />
        </FormGroup>
      </FormControl>

      <Box sx={{ display: 'flex', gap: '32px', mt: '32px', height: '51px', alignItems: 'center' }}>
        <Button type="submit" loading={isSubmitting} variant="contained" color="primary" startIcon={<Save />} dataTestId="saveBtn">
          {t('editProfile|saveShipmentTypes')}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            switchToReadOnly();
          }}
          startIcon={<Close />}
          dataTestId="closeBtn"
        >
          {t('general|labels|buttons|Cancel')}
        </Button>
        {Object.keys(errors).length > 0 ? (
          <Alert severity="error" variant="standard" sx={{ p: '0 12px' }}>
            {t('registration|invalidData')}
          </Alert>
        ) : null}
      </Box>
    </form>
  );
};

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { DATE_FORMAT } from 'globals/utils/formatting';
import { widgetLocale } from './widgetLocale';
import { config } from '../../../../../data-config';
import { fetchSavedShipmentsList } from '../../../../ActionUtils';

import getLanguage from 'globals/utils/getLanguage';

export default function DateWidget({ label, name, compareTo, filterDate, context }) {
  const [selectedDate, setSelectedDate] = useState();

  const handleChange = (date, e, context) => {
    e.preventDefault();

    const stateToSet = {};
    let dateError = false;
    const filterShipmentObject = JSON.parse(JSON.stringify(context.state.filterByShipmentDates));
    filterShipmentObject.value = 'customDateRange';

    stateToSet.value = date || '';
    stateToSet.error = !date;

    if (date) {
      switch (compareTo) {
        case 'ToDate':
          stateToSet.value =
            date > context.state.filterShipmentToDate.value
              ? context.state.filterShipmentFromDate.value
              : date.setHours(0, 0, 0, 0);

          dateError = date > context.state.filterShipmentToDate.value;
          break;
        case 'FromDate':
          stateToSet.value =
            date < context.state.filterShipmentFromDate.value
              ? context.state.filterShipmentToDate.value
              : date.setHours(23, 59, 59, 999);
          dateError = date < context.state.filterShipmentFromDate.value;
          break;

        default:
          break;
      }
    }

    context.extendedSetState(
      { [name]: stateToSet, filterByShipmentDates: filterShipmentObject },
      { afterUpdate: !stateToSet.error && !dateError ? fetchSavedShipmentsList : null },
    );

    setSelectedDate(stateToSet.value);
  };

  useEffect(() => {
    setSelectedDate(filterDate.value ? new Date(filterDate.value) : '');
  }, [filterDate]);

  const language = getLanguage(config.regEx.urlValidation);

  const minDate = new Date().setMonth(new Date().getMonth() - config.fromNoOfMonth);

  const maxDate = new Date();

  return (
    <div className="margin-bottom-0">
      <div>{label}</div>
      <DatePicker
        id={name}
        autoComplete="off"
        disabled={false}
        minDate={minDate}
        maxDate={maxDate}
        selected={selectedDate}
        className="c-form--element-base c-form--element-input c-calculator--input-field"
        onChange={(date, e) => handleChange(date, e, context)}
        locale={widgetLocale[language]}
        dateFormat={DATE_FORMAT}
      />
    </div>
  );
}

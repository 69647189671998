import { returnProperValueOrNull } from 'globals/utils/fieldValidations';
import { formatDate, formatDateTime } from 'globals/utils/formatting';
import {
  CONSIGNEE_CUSTOMER_TYPE,
  CONSIGNOR_CUSTOMER_TYPE,
  RECEIVER_PAYER,
  THIRD_PARTY_PAYER,
} from '../../../globals/constants';
import { config, CONST } from '../../data-config';
import { additionalServicesValidations } from './Validations/AdditionalServices-validations';
import { bookingConfirmationValidations } from './Validations/BookingConfirmation-validations';
import { captchaValidations } from './Validations/Captcha-validations';
import { chargeableWeightValidations } from './Validations/ChargeableWeight-validations';
import { enterDeliveryLocationValidations } from './Validations/EnterDeliveryLocation-validations';
import { enterPickupLocationValidations } from './Validations/EnterPickupLocation-validations';
import { enterShipmentDetailsValidations } from './Validations/EnterShipmentDetails-validations';
import { serviceDetailsValidations } from './Validations/ServiceDetails-validations';
import { instructionsValidation, termsValidation } from './Validations/Terms-validations';

export const checkSubmitDetails = async context => {
  let validate = {
    stateToSet: {},
    hasErrors: false,
  };

  // Sections validations
  validate = serviceDetailsValidations(context, validate.stateToSet, validate.hasErrors);
  validate = enterPickupLocationValidations(context, validate.stateToSet, validate.hasErrors);
  validate = enterDeliveryLocationValidations(context, validate.stateToSet, validate.hasErrors);
  validate = enterShipmentDetailsValidations(context, validate.stateToSet, validate.hasErrors);
  validate = additionalServicesValidations(context, validate.stateToSet, validate.hasErrors);
  validate = bookingConfirmationValidations(context, validate.stateToSet, validate.hasErrors);
  validate = captchaValidations(context, validate.stateToSet, validate.hasErrors);
  validate = termsValidation(context, validate.stateToSet, validate.hasErrors);
  validate = instructionsValidation(context, validate.stateToSet, validate.hasErrors);
  validate = await chargeableWeightValidations(context, validate.stateToSet, validate.hasErrors);

  return {
    stateToSet: validate.stateToSet,
    hasErrors: validate.hasErrors,
  };
};

export const getPayerCode = data => {
  let payerCode = '1';

  // For Future implementation in case of new rules

  return payerCode;
};

export const getShipmentType = shipmentType => {
  let packType;

  packType =
    config.shipmentTypes.find(packageType => {
      return shipmentType === packageType.value;
    }) || '';

  return packType.dataRel;
};

export const getAdditionalServices = additionalServices => {
  const selectedAdditionalServices = {};

  if (additionalServices && additionalServices.length > 0) {
    additionalServices.forEach(additionalService => {
      if (additionalService.value) {
        if (additionalService.groups && additionalService.groups.length > 0) {
          const isVASOptionAnArr =
            !config.optionSchemaToBeSentAsObject ||
            config.optionsSchemaExpectedAsArray.indexOf(additionalService.code) !== -1;
          const groupsMainArr = [];

          additionalService.groups.forEach(group => {
            let groupEachArr = [];
            let serviceMap;

            if (group.options) {
              group.options.forEach(optionList => {
                serviceMap = {};
                optionList.forEach(option => {
                  // option starts here
                  if (additionalService.optionNotToHide) {
                    switch (option.type) {
                      case config.OptionFieldTypes.Telephone:
                        serviceMap[option.optionCode] = option.value[0].code + '-' + option.value[0].number;
                        break;
                      case config.OptionFieldTypes.DateTime:
                        serviceMap[option.optionCode] = formatDateTime(option.value);
                        break;
                      case config.OptionFieldTypes.Date:
                        serviceMap[option.optionCode] = formatDate(option.value);
                        break;
                      case config.OptionFieldTypes.TickBox:
                        serviceMap[option.optionCode] = option.selected;
                        break;

                      default:
                        serviceMap[option.optionCode] = option.value ? option.value.replace(',', '.') : null;
                    }
                  } else if (!additionalService.optionNotToHide) {
                    serviceMap[option.optionCode] = option.value;
                  }
                });

                if (isVASOptionAnArr) {
                  groupEachArr.push(serviceMap);
                }
              });
            }

            if (groupEachArr && groupEachArr.length > 0) {
              groupsMainArr.push(groupEachArr);
            }

            if (additionalService.code !== config.OptionFieldTypes.collectionAtTerminal)
              selectedAdditionalServices[additionalService.code] =
                groupsMainArr && groupsMainArr.length > 0 ? groupsMainArr : serviceMap;
          });
        } else {
          selectedAdditionalServices[additionalService.code] = true;
        }
      }
    });
  }

  return selectedAdditionalServices;
};

export const prepareUserData = data => {
  let pieces = [];
  let productCode = null;
  let pickupDate = new Date(data.pickupDate.value);
  let cityName = data.deliveryCity.value;
  let pickupSenderReference = [];
  let deliveryReceiverReference = [];

  pickupSenderReference.push(data.pickupSenderReference.value);
  deliveryReceiverReference.push(data.deliveryReceiverReference.value);

  data.shipmentDetailsRows.forEach(row => {
    const obj = {
      id: [''],
      goodsType: getShipmentType(row.shipmentType.value) || null,
      packageType: getShipmentType(row.shipmentType.value) || null,
      marksAndNumbers: null,
      numberOfPieces: row.quantity.value || null,
      weight: returnProperValueOrNull(row.weight.value),
      volume: returnProperValueOrNull(row.volume.value),
      loadingMeters: returnProperValueOrNull(row.loadingMeter.value),
      palletPlaces: returnProperValueOrNull(row.palletPlace.value),
      width: row.width.value || null,
      height: row.height.value || null,
      length: row.length.value || null,
      dangerousGoods: null,
      stackable: !row.nonStackable.value,
      longShipment: row.longShipment.value || null,
    };

    pieces.push(obj);
  });

  if (data.selectedProducts && data.selectedProducts.length === 1) {
    config.allowedProducts.map(product =>
      product.mapName === data.selectedProducts[0] ? (productCode = product.code) : '',
    );
  } else if (data.selectedProducts && data.selectedProducts.length > 1) {
    productCode = '211';
  }

  if (data.shippingToMultipleAddresses.value) {
    cityName = CONST.CITY_MULTIPLE_ADDRESS;
  }

  const requestData = {
    captcha: {
      answer: data.captcha.value,
      token: data.captcha.token,
    },
    confirmationEmail: data.confirmationEmail.value,
    pickupRequestInputData: {
      id: null, // Shipment ID for login area only
      payerCode: {
        code: getPayerCode(data),
      },
      parties: [
        {
          id: data.accountNumber.value || null,
          type: CONSIGNOR_CUSTOMER_TYPE,
          name: data.pickupSender.value || null,
          contactName: data.pickupName.value || null,
          references: pickupSenderReference || null,
          address: {
            street: data.pickupStreet.value || null,
            additionalAddressInfo: null,
            cityName: data.pickupCity.value || null,
            postalCode: data.pickupPostalCode.value || null,
            countryCode: data.pickupCountry.value || null,
          },
          phone: data.pickupPhoneNumbers[0].code + '-' + data.pickupPhoneNumbers[0].number || null,
          email: data.pickupEmail.value || null,
        },
        {
          id: data.receiverNumber.value || data.thirdPartyNumber.value || null,
          type: CONSIGNEE_CUSTOMER_TYPE,
          name: data.deliveryReceiver.value || null,
          contactName: data.deliveryName.value || null,
          references: deliveryReceiverReference || null,
          address: {
            street: data.deliveryStreet.value || null,
            additionalAddressInfo: '' || null,
            cityName: cityName || null,
            postalCode: data.deliveryPostalCode.value || null,
            countryCode: data.deliveryCountry.value || null,
          },
          phone: data.deliveryPhoneNumbers[0].code + '-' + data.deliveryPhoneNumbers[0].number || null,
          email: data.deliveryEmail.value || null,
        },
      ],
      pieces: pieces,
      additionalServices: getAdditionalServices(data.additionalServices) || null,
      totalWeight: data.totals.shipmentDetailsRows.weight.value || null,
      productCode: productCode || null,
      totalVolume: data.totals.shipmentDetailsRows.volume.value || null,
      pickupDate: formatDateTime(pickupDate),
      deliveryInstruction: null,
      pickupInstruction: data.instructions.value || null,
      totalNumberOfPieces: data.totals.shipmentDetailsRows.quantity.value || null,
      totalLoadingMeters: data.totals.shipmentDetailsRows.loadingMeter.value || null,
      totalPalletPlaces: data.totals.shipmentDetailsRows.palletPlace.value || null,
    },
  };

  specialCaseForDHLParti(data, requestData);

  return requestData;
};

const specialCaseForDHLParti = (data, requestData) => {
  if (data.selectedProducts && data.selectedProducts.length === 1 && data.selectedProducts[0] === 'dhlParti') {
    let payerCode;

    switch (data.payer.value) {
      case RECEIVER_PAYER:
        payerCode = '3';
        break;
      case THIRD_PARTY_PAYER:
        payerCode = '4';
        break;

      default:
        payerCode = '1';
        break;
    }
    // set correct payer code
    requestData.pickupRequestInputData.payerCode = { code: payerCode };
    // id in account fix  -- delete all ids first
    delete requestData.pickupRequestInputData.parties[0].id;
    delete requestData.pickupRequestInputData.parties[1].id;

    switch (payerCode) {
      case '3':
        requestData.pickupRequestInputData.parties[0].id = data.accountNumber.value;
        requestData.pickupRequestInputData.parties[1].id = data.receiverNumber.value;
        break;
      case '4':
        requestData.pickupRequestInputData.parties[0].id = data.accountNumber.value;
        requestData.pickupRequestInputData.parties.push({ type: 'FreightPayer', id: data.thirdPartyNumber.value });
        break;

      default:
        requestData.pickupRequestInputData.parties[0].id = data.accountNumber.value;
        break;
    }
  }
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import './ProgressBar.css';

export default function ProgressBar({ percentage, percentageText }) {
  const { t } = useTranslation();

  return (
    <div className="frc__dialog">
      <div className="frc__dialog-overlay" />
      <div className="frc__dialog-wrapper l-grid--w-100pc-s l-grid--w-80pc-m">
        <div className="frc__dialog-container l-grid--w-100pc-s l-grid--w-60pc-m ">
          {t(`portalOrderPercentage Text`, {
            text: t(`portalOrder|${percentageText}`),
          })}

          <div className="frc__progress-bar">
            <span className="frc__progress-bar-fill" style={{ width: `${percentage}%` }}>
              <span className="frc__completion-text"> {parseInt(percentage) + '%'}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

import { config } from './data-config';

export const checkIfEmpty = (value, isRequired) => {
  if (value === '' && isRequired) {
    return true;
  }
  return false;
};

export const checkMinMax = (value, min, max) => {
  if (value !== '') {
    value =
      typeof value === 'number' ? value : typeof value === 'string' ? Number(value.replace(',', '.')) : Number(value);
    min = typeof min === 'number' ? min : typeof min === 'string' ? Number(min.replace(',', '.')) : Number(min);
    max = typeof max === 'number' ? max : typeof max === 'string' ? Number(max.replace(',', '.')) : Number(max);

    if ((min && value < min) || (max && value > max)) return true;
    return false;
  }
};

export const checkLength = (value, ranges) => {
  let result = false;

  if (ranges && Array.isArray(ranges) && ranges.length > 0) {
    ranges.forEach(regEx => {
      result = regEx.test(value) || result;
    });
  } else {
    result = true;
  }

  return !result;
};

export const compareValues = (value1, value2) => !(value1 === value2);

export const checkEmptyOrMinMaxOrLength = params => {
  return (
    checkIfEmpty(params.value, params.isRequired) ||
    checkMinMax(params.value, params.min, params.max) ||
    (params.lengthCheck && checkLength(params.value, params.lengthCheck))
  );
};

export const matchRegEx = (value, regEx) => {
  return regEx ? String(value).match(regEx) : true;
};

export const testRegEx = (value, regEx) => {
  return regEx ? regEx.test(value) : true;
};

export const returnProperValueOrNull = value => {
  if (value) {
    const newValue = value.toString().replace(',', '.');
    return newValue.replace(/\.$/, '');
  }

  return null;
};

export const checkFieldAndReturnState = params => {
  // trim all strings first
  const value = typeof params.value === 'string' ? params.value.trim() : params.value;

  const error =
    (!testRegEx(value, params.regEx) && value.length > 0) ||
    checkIfEmpty(value, params.isRequired) ||
    checkMinMax(value, params.min, params.max) ||
    checkLength(value, params.lengthCheck);

  return {
    value: value,
    name: params.name,
    error: error,
    isRequired: params.isRequired ? params.isRequired : false,
  };
};

export const checkGroupOfFieldsAndReturnState = params => {
  const values = [];
  let hasErrors = false;
  let cutLengthError = false;
  let calculatedLength;

  if (params.values && params.values.length > 0 && params.fields && params.fields.length > 0) {
    params.values.forEach((item, ind) => {
      let newItem = { error: false };

      if ((params.index && params.index === ind) || !params.index) {
        params.fields.forEach(field => {
          let error;

          if (field.calculatePhoneLengthCheck) {
            calculatedLength = config.maxPhoneNumberDigits - 1 - item['code'].length;

            //FIXME: avoid this condition and make only one universal line
            if (field.startPhoneLengthFromZero) {
              field.lengthCheck = [
                RegExp('^.{' + (field.startPhoneLengthFromZero === true ? 0 : 1) + ',' + calculatedLength + '}$'),
              ];
            } else {
              field.lengthCheck = [RegExp('^.{0,' + calculatedLength + '}$')];
            }
            cutLengthError = checkLength(item[field.name], field.lengthCheck);
            item[field.name] = item[field.name] && item[field.name].substring(0, calculatedLength);
          }

          error =
            !testRegEx(item[field.name], field.regEx) ||
            checkIfEmpty(item[field.name], field.isRequired) ||
            checkMinMax(item[field.name], field.min, field.max) ||
            checkLength(item[field.name], field.lengthCheck) ||
            cutLengthError;

          newItem.error = error || newItem.error;
          newItem.isRequired = field.isRequired || false;
          newItem[field.name] = item[field.name];
        });
      } else {
        newItem = { ...item };
      }

      hasErrors = newItem.error || hasErrors;
      values.push(newItem);
    });
  }

  return {
    values: values,
    groupName: params.groupName,
    error: hasErrors,
  };
};

export const checkForDuplicityAndReturnState = params => {
  let hasErrors = false,
    updatedGroupValues = [],
    currentItem;

  if (
    params &&
    params.values &&
    params.fields &&
    params.groupNames &&
    params.originGroup &&
    (typeof params.index !== 'undefined' || params.index !== null)
  ) {
    currentItem = { ...params.values[params.originGroup][params.index] };

    params.groupNames.forEach(group => {
      const values = [];

      params.values[group].forEach((item, index) => {
        const newItem = { ...item };

        if (group !== params.originGroup || (group === params.originGroup && index !== params.index))
          params.fields.forEach(field => {
            // MK-test
            // eslint-disable-next-line
            if (item[field] == currentItem[field]) {
              currentItem.duplicationError = true;
              hasErrors = true;
            }
          });

        if (group === params.originGroup) {
          values.push(newItem);
        }
      });

      if (group === params.originGroup) {
        values[params.index].duplicationError = currentItem.duplicationError;
        updatedGroupValues = [...values];
      }
    });
  }

  return {
    values: updatedGroupValues,
    groupName: params.originGroup,
    error: hasErrors,
  };
};

export const checkForDuplicityInOneGroupAndReturnState = params => {
  let hasErrors = false,
    updatedGroupValues = [],
    currentItem,
    currentIndex;

  if (params && params.values && params.fields && params.group) {
    params.values[params.group].forEach((item, index) => {
      currentItem = { ...item };
      currentItem.duplicationError = false;
      currentIndex = index;

      params.values[params.group].forEach((itemP, indexP) => {
        if (indexP !== currentIndex) {
          params.fields.forEach(field => {
            // MK-test
            // eslint-disable-next-line
            if (itemP[field] == currentItem[field]) {
              currentItem.duplicationError = true;
              hasErrors = true;
            }
          });
        }
      });
      updatedGroupValues.push(currentItem);
    });
  }

  return {
    values: updatedGroupValues,
    groupName: params.group,
    error: hasErrors,
  };
};

export const comparePasswords = params => {
  let hasErrors = false;

  if (params.password !== params.confirmPassword) {
    hasErrors = true;
  }

  return {
    value: params.confirmPassword,
    error: hasErrors,
  };
};

export const objectIsEmpty = obj => {
  return Object.keys(obj).length === 0;
};

/**
 * Create an array of the intersection of all date string arrays from the provided object,
 */
export const getIntersectionOfDates = dateObj => {
  const dateArrays = Object.values(dateObj);
  if (dateArrays.length === 0) {
    return [];
  }
  let intersection = dateArrays[0];
  for (let i = 1; i < dateArrays.length; i++) {
    intersection = intersection.filter(date => dateArrays[i].includes(date));
  }
  return intersection;
};

/**
 * Takes an object containing arrays of date strings and returns the first date that is common across all the arrays.
 * If no common date exists, it returns undefined.
 */
export const findFirstCommonDate = dates => {
  const dateArrays = Object.values(dates);

  if (dateArrays.length === 0) {
    return undefined;
  }

  const commonDates = dateArrays.reduce((common, currentArray) => {
    return common.filter(date => currentArray.includes(date));
  }, dateArrays[0]);

  return commonDates.length > 0 ? commonDates[0] : undefined;
};

import { config } from '../data-config';

export const state = {
  languageCode: config.locale.defaultLanguage,
  totals: {
    shipmentDetailsRows: {
      quantity: { value: 1 },
      volume: { value: 0 },
      weight: { value: 0 },
      loadingMeter: { value: '' },
      chargeableWeight: { value: null, error: false },
    },
  },
  isProductWithSpecialInputValidation: false,
  allowedShipmentTypes: [],
  loaders: {
    shipmentDetails: { value: false },
  },
  shipmentDetailsRows: [],
  shipmentRowsNumberMax: config.maxAllowedShipmentDetailsRows,
};

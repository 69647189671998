import React, { useState } from 'react';
import { CONST, config } from './../../../data-config';
import { saveShipmentCall } from './../../../helpers/savedShipments';

import { Input } from 'components';
import { Error } from 'components';
import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';
import './SaveAsTemplate.css';

export default function SaveAsTemplate(props) {
  const { t, context } = props;
  const [saveTemplateClicked, setSaveTemplateClicked] = useState(false);
  const [saveTemplateCallCompleted, setSaveTemplateCallCompleted] = useState(false);
  const [saveTemplateCallSucceded, setSaveTemplateCallSucceded] = useState(null);
  const [saveTemplateLimitReachedError, setSaveTemplateLimitReachedError] = useState(false);

  const submitTemplateRequest = async () => {
    setSaveTemplateCallSucceded(null);
    setSaveTemplateCallCompleted(false);
    // template name validation
    const templateValidationError = validateTemplateName(context.state);

    if (!templateValidationError) {
      setSaveTemplateClicked(true);
      const res = await saveShipmentCall(context, 3);

      if (res.error && res.status === CONST.TOO_MANY_REQUESTS_ERROR) {
        setSaveTemplateLimitReachedError(true);
      } else if (res.error) {
        setSaveTemplateCallSucceded(false);
      } else {
        setSaveTemplateCallSucceded(true);
      }

      setSaveTemplateCallCompleted(true);
    } else {
      const templateName = JSON.parse(JSON.stringify(context.state.templateName));
      templateName.error = true;

      context.updateState({ templateName });
    }
  };

  const validateTemplateName = state => {
    const getState = checkFieldAndReturnState({
      name: 'templateName',
      value: state.templateName.value,
      regEx: config.regEx.everything,
      lengthCheck: [RegExp(`^.{0,${config.maxDefaultInputLength}}$`)],
      isRequired: true,
    });

    return getState.error;
  };

  const getButtonLabel = () => {
    let label = t('general|labels|buttons|Save');

    if (saveTemplateCallCompleted && saveTemplateCallSucceded) {
      label = (
        <div className="frc__template-saved">
          {t(`portalOrder|Saved`)}
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="check"
            role="img"
            viewBox="0 0 512 512"
            className="frc-thank-you__icon frc__saved-thank-you-icon"
          >
            <path
              fill="currentColor"
              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
            />
          </svg>
        </div>
      );
    }

    if (saveTemplateCallCompleted && saveTemplateLimitReachedError) {
      label = (
        <div className="frc__template-saved">
          {t(`portalOrder|Limit 50 templates reached, save failed`)}
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="times"
            role="img"
            viewBox="0 0 352 512"
            className="frc-thank-you__icon frc-thank-you__icon--red"
          >
            <path
              fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
            />
          </svg>{' '}
        </div>
      );
    }

    return label;
  };

  const generalError = saveTemplateClicked && saveTemplateCallSucceded !== null && !saveTemplateCallSucceded;
  return (
    <div className=" l-grid--w-100pc-s l-grid--w-80pc-m frc__shipment-details-row has-top-margin-2 margin-bottom-1 frc__save-as-template-container">
      <h4 className="margin-bottom-1">{t(`portalOrder|Save as template`)}</h4>
      <p>{t(`portalOrder|Please note that future service updates may impact your template`)}</p>
      <div className="l-grid l-grid--p-05u-s">
        <div className="l-grid--w-50pc-s">
          <div className="frc__template-details">
            <h5 className="margin-bottom-0">{t('general|Sender')}</h5>
            <p>{context.state.pickupSender.value}</p>
            <p>{context.state.pickupStreet.value}</p>
            <p>
              {context.state.pickupPostalCode.value} {context.state.pickupCity.value}
            </p>
            <p>{context.state.pickupCountry.value}</p>
          </div>
          <h5 className="margin-bottom-0">{t('general|Shipment Details')}</h5>
          <p>
            {t('general|labels|metrics|quantity')}:{' '}
            <strong>{context.state.totals.shipmentDetailsRows.quantity.value}</strong>,{' '}
            {t('general|labels|metrics|Weight')}:{' '}
            <strong>{context.state.totals.shipmentDetailsRows.weight.value}kg</strong>
          </p>
        </div>
        <div className="l-grid--w-50pc-s">
          <div className="frc__template-details">
            <h5 className="margin-bottom-0">{t('general|Receiver')}</h5>
            <p>{context.state.deliveryReceiver.value}</p>
            <p>{context.state.deliveryStreet.value}</p>
            <p>
              {context.state.deliveryPostalCode.value} {context.state.deliveryCity.value}
            </p>
            <p>{context.state.deliveryCountry.value}</p>
          </div>
          <h5 className="margin-bottom-0">{t('products|common|productLabel')}</h5>
          <p>{context.state.selectedProduct.product}</p>
        </div>
      </div>

      <div>
        <div className="l-grid">
          {!saveTemplateClicked ? (
            <div className="l-grid--w-100pc-s l-grid--w-60pc-m">
              <Input
                context={context}
                config={config}
                CONST={CONST}
                regEx={config.regEx.everything}
                label={t('general|Template Name')}
                name="templateName"
                dataTestId="templateNameInp"
                inputClassName="frc__addressbook-searchinput"
                value={context.state.templateName.value}
                lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                cutTextLimit={config.maxDefaultInputLength}
                isRequired={true}
                placeholder={t('general|labels|inputs|Name')}
              />

              {context.state.templateName.error ? (
                <Error
                  name="templateName"
                  className="frc__input--error"
                  message={t(`portalOrderTemplate Error`, {
                    max: config.maxDefaultInputLength,
                  })}
                />
              ) : (
                ''
              )}
            </div>
          ) : (
            <div className="l-grid--w-100pc-s l-grid--w-60pc-m frc__templatename-text">
              <div>{context.state.templateName.value}</div>
            </div>
          )}

          <div
            className={`frc__search-btn-center  frc__saved-btn-trim
            ${
              !generalError && saveTemplateCallCompleted
                ? `l-grid--w-100pc-s
                    ${
                      saveTemplateCallSucceded
                        ? ' frc__address-search--button l-grid--w-25pc-m '
                        : ' frc__save-btn-top l-grid--w-100pc-m '
                    }`
                : 'frc__address-search--button l-grid--w-25pc-m l-grid--w-100pc-s'
            } `}
          >
            <button
              id="saveAsTemplateBtn"
              className={
                'base-button base-button--white frc__search-button l-grid--o-1u-s l-grid--w-50pc-s-s l-grid--w-100pc-m ' +
                (saveTemplateClicked && !saveTemplateCallCompleted ? ' is-loading--right' : '')
              }
              disabled={generalError ? false : saveTemplateCallCompleted}
              onClick={submitTemplateRequest}
            >
              {getButtonLabel()}
            </button>
          </div>
          {generalError && (
            <Error
              name="saveAsTemplateAPIError"
              message={
                t('general|errors|General error') + '. <br> ' + t('general|Try again or call technical support.')
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

import { config, CONST } from '../../data-config';
import { eraseCookie, setCookie } from 'globals/utils/requests';
import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';
import { formatDate } from 'globals/utils/formatting';
import { analytics } from 'globals/utils/analytics';
import { EXPORT_SHIPMENT_TYPE } from '../../../globals/constants';

export const getPickupDate = context => {
  return !context.returnProdStatus && context.state.pickupOption.value === 'Schedule Pickup'
    ? formatDate(context.state.pickupDate.value)
    : null;
};

export const resetTermsAndConditions = context => {
  const stateToSet = {
    bookShipmentAPIError: false,
    reviewBookPickupDate: getPickupDate(context),
  };

  context.updateState(stateToSet);
};

export const setAddressCookie = shipmentData => {
  if (!shipmentData?.shipment?.importExport) return;
  const cookieContent =
    shipmentData.shipment.importExport === EXPORT_SHIPMENT_TYPE
      ? shipmentData.shipment.parties[0]
      : shipmentData.shipment.parties[1];
  try {
    setCookie(
      'addressOrder',
      Buffer.from(encodeURIComponent(JSON.stringify(cookieContent), CONST.HOURS_PER_MONTH, 'h')).toString('base64'),
    );
  } catch {
    console.error("Failed to set cookie 'addressOrder'");
  }
};

export const setReturnShipmentCookie = shipmentData => {
  if (shipmentData.shipment.returnShipmentRequested === true) {
    setCookie('returnShipmentRequested', shipmentData.shipment.returnShipmentRequested, CONST.HOURS_PER_MONTH, 'h');
  } else {
    eraseCookie('returnShipmentRequested');
  }
};

export const setSendNotificationsCookie = context => {
  const sendNotificationObject = {
    deliveryEmailConfirmation: context.state.deliveryEmailConfirmation,
    differentDeliveryEmailConfirmation: context.state.differentDeliveryEmailConfirmation,
    pickupEmailConfirmation: context.state.pickupEmailConfirmation,
    differentPickupEmailConfirmation: context.state.differentPickupEmailConfirmation,
  };
  setCookie('sendNotification', encodeURIComponent(JSON.stringify(sendNotificationObject), CONST.HOURS_PER_MONTH, 'h'));
};

export const getThankYouStatus = (
  publicBookingOrderResult,
  shouldPickupRequestCondition,
  deliveryDate,
  props,
  state,
) => {
  const customs = props.context.state.customs;

  if (publicBookingOrderResult.status === 0 || publicBookingOrderResult.status === 2) {
    if (publicBookingOrderResult.status === 2) {
      props.thankYouPage({
        bookingOrderResult: publicBookingOrderResult,
        pickupOrderMovedToNextDay: true,
        deliveryDate,
        fixedDate: state.fixedDate,
        customs,
        shouldPickupRequestCondition,
      });
      analytics('Thank You Moved', CONST.ANALYTICS);
    } else {
      props.thankYouPage({
        bookingOrderResult: publicBookingOrderResult,
        deliveryDate,
        fixedDate: state.fixedDate,
        customs,
        shouldPickupRequestCondition,
      });
      analytics('Thank You Accepted', CONST.ANALYTICS);
    }
  } else if (publicBookingOrderResult.status === 1) {
    props.thankYouPage({
      redThankYouPage: true,
      redThankYouErrorMessage: publicBookingOrderResult.message,
      priorityServiceError: publicBookingOrderResult.message.indexOf('\nG') > -1 ? true : false,
      bookingOrderResult: publicBookingOrderResult,
      deliveryDate,
      fixedDate: state.fixedDate,
      customs,
    });
    analytics('Thank You Rejected', CONST.ANALYTICS);
  } else {
    analytics('Thank You UNKNOWN STATUS', CONST.ANALYTICS);
  }
};

export const validateAndSubmit = props => {
  let validate = {
    stateToSet: {},
    hasErrors: false,
  };

  validate = scheduleReviewValidations(props.context, validate.stateToSet, validate.hasErrors);

  return validate;
};

export const scheduleReviewValidations = (context, stateToSet, hasErrors) => {
  let getState = {};
  const state = JSON.parse(JSON.stringify(context.state));

  getState = checkFieldAndReturnState({
    name: 'confirmationEmail',
    value: state.confirmationEmail.value,
    regEx: config.regEx.email,
    isRequired: false,
  });
  hasErrors = getState.error || hasErrors;

  stateToSet[getState.name] = {
    value: getState.value,
    error: getState.error,
  };

  hasErrors = getState.error || hasErrors;

  if (getState.error) {
    stateToSet.captcha = {
      value: '',
      error: true,
      token: state.captcha.token,
    };
  }

  context.extendedSetState(stateToSet);

  return hasErrors;
};

import { useQuery } from '@tanstack/react-query';
import { apiRequest } from '../../globals';
import { apiLinks } from '../../config/api-config';
import { AUTHORIZATION } from '../../Login/constants';

export const userQueryKey = 'user';

const fetchUser = async () => {
  const res = await apiRequest(apiLinks.getUserData, 'GET', null, null, false);
  if (res.status !== 200) {
    throw new Error(`Status: ${res.status} Message: ${res.status}`);
  }
  return res.data;
};

export const useGetUser = ({ select, options, enabled = true } = {}) => {
  const authenticated = localStorage.getItem('authenticated');
  const authorizationToken = localStorage.getItem(AUTHORIZATION);

  return useQuery({
    queryKey: [userQueryKey],
    queryFn: fetchUser,
    select: select,
    enabled: Boolean(authenticated) && Boolean(authorizationToken) && enabled,
    ...options,
  });
};

import { Info } from '@mui/icons-material';
import { Alert, Box, Tooltip, useTheme } from '@mui/material';
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CONST, formatDate } from '../../../globals';
import {
  getEkaerNumber,
  getSentCarrierKey,
  getSentReference,
  isEkaerFree,
} from '../../../globals/helpers/additionalInformation';
import { useGetActiveTracing } from '../../../hooks/api/useGetActiveTracing';
import { Loader } from '../Loader';
import ActionsCell from './ActionsCell';
import { SelectAll } from './SelectAll';
import { AdressCell, ShipmentIdCell, StatusCell, SummaryCell } from './TableCells';

export const prepareShipments = (shipments, activeTracingStatuses) => {
  if (!shipments) {
    return [];
  }
  return shipments.map(shipment => {
    const activeTracingFreshStatus = Array.isArray(activeTracingStatuses)
      ? activeTracingStatuses.find(res => res.id === shipment.id)?.status
      : null;

    return {
      id: shipment.id,
      status: activeTracingFreshStatus || shipment.status,
      shipmentId: shipment.shipmentNumber,
      uniqueCode: shipment.uniqueCode,
      date: shipment.createdAt,
      sender: { ...shipment.sender, reference: shipment.sreference },
      receiver: { ...shipment.receiver, reference: shipment.rreference },
      summary: {
        productId: shipment.productID,
        price: shipment.calculatedPrice,
        currency: shipment.calculatedPriceCurrency,
        quantity: shipment.totalNumberOfPieces,
        weight: shipment.totalWeight,
        volume: shipment.totalVolume,
        loadingMeter: shipment.totalLDM,
        sentReference: getSentReference(shipment),
        sentCarrierKey: getSentCarrierKey(shipment),
        ekaerFree: isEkaerFree(shipment),
        ekaerNumber: getEkaerNumber(shipment),
      },
      actions: shipment,
      documents: shipment.printDocumentDTOS,
      userEmail: shipment?.userEmail,
    };
  });
};

export const ShipmentsTable = ({
  shipments,
  loading,
  error,
  tableProps,
  context,
  showUserEmail,
  summaryMinWidth = 175,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [selectedShipments, setSelectedShipments] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const {
    data: activeTracingStatuses,
    isLoading: activeTracingLoading,
    isError: activeTracingError,
  } = useGetActiveTracing({
    savedShipmentIds: shipments.map(shipment => shipment.id),
  });

  const parsedShipments = useMemo(
    () => prepareShipments(shipments, activeTracingStatuses?.savedShipments),
    [shipments, activeTracingStatuses],
  );

  const handleSelectAll = event => {
    const checked = event.target.checked;
    setSelectAll(checked);
    if (checked) {
      setSelectedShipments(
        parsedShipments.filter(
          shipment => shipment.status !== CONST.IN_COMPLETE && shipment.status !== CONST.DELIVERED,
        ),
      );
    } else {
      setSelectedShipments([]);
    }
  };

  useEffect(() => {
    if (
      selectedShipments.length ===
      parsedShipments.filter(shipment => shipment.status !== CONST.IN_COMPLETE && shipment.status !== CONST.DELIVERED)
        .length
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [parsedShipments, selectedShipments]);

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderHeader: () => null,
      renderCell: column =>
        column.row.status === CONST.IN_COMPLETE || column.row.status === CONST.DELIVERED
          ? null
          : GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(column),
    },
    {
      field: 'status',
      renderCell: column => <StatusCell status={column.value} loading={activeTracingLoading && !activeTracingError} />,
      renderHeader: () => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            {t('general|Status')}
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: t('general|StatusHeaderInfo') }} />}
              placement="top"
            >
              <Info htmlColor={theme.palette.primary.main} />
            </Tooltip>
          </Box>
        );
      },
      minWidth: 110,
      flex: 1,
    },
    {
      field: 'shipmentId',
      minWidth: 145,
      renderHeader: () => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            {t('general|Shipment ID')}
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: t('general|Shipment ID tooltip') }} />}
              placement="top"
            >
              <Info htmlColor={theme.palette.primary.main} />
            </Tooltip>
          </Box>
        );
      },
      flex: 1,
      renderCell: column => {
        return column.value ? (
          <ShipmentIdCell
            shipmentNumber={column.value}
            id={column.id}
            userEmail={showUserEmail ? column.row?.userEmail : undefined}
          />
        ) : null;
      },
    },
    {
      field: 'date',
      headerName: t(`general|date`),
      flex: 1,
      minWidth: 100,
      valueGetter: column => {
        return formatDate(column.value);
      },
    },
    {
      field: 'sender',
      headerName: t('general|Sender'),
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: column => <AdressCell {...column.value} />,
    },
    {
      field: 'receiver',
      headerName: t('general|Receiver'),
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: column => <AdressCell {...column.value} />,
    },
    {
      field: 'summary',
      headerName: t('general|Summary'),
      minWidth: summaryMinWidth,
      sortable: false,
      renderCell: column => <SummaryCell {...column.value} />,
    },
    {
      field: 'actions',
      headerName: t('general|actions'),
      width: 200,
      sortable: false,
      renderCell: column => (
        <ActionsCell
          cellValue={column.value}
          context={context}
          onShowSuccessToaster={message =>
            toast(
              <Alert severity="success" variant="standard">
                {message}
              </Alert>,
            )
          }
        />
      ),
    },
  ];

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Alert variant="standard" severity="error" sx={{ my: '16px' }}>
        {t('general|errors|General error')}
      </Alert>
    );
  }

  if (parsedShipments.length === 0) {
    return (
      <Box sx={{ my: '16px' }}>
        <span>{t('general|No matching online shipments found')}.</span>
      </Box>
    );
  }

  return (
    <Box sx={{ my: '32px' }}>
      <SelectAll selectAll={selectAll} onSelectAll={handleSelectAll} selectedShipments={selectedShipments} />
      <Box
        sx={{
          overflow: 'auto',
        }}
      >
        <DataGrid
          onRowSelectionModelChange={selectedIds => {
            setSelectedShipments(() => parsedShipments.filter(shipment => selectedIds.includes(shipment.id)));
          }}
          rowSelectionModel={selectedShipments.map(shipment => shipment.id)}
          disableRowSelectionOnClick
          disableColumnMenu
          rows={parsedShipments}
          columns={columns}
          getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          checkboxSelection
          getRowHeight={() => 'auto'}
          paginationModel={
            context?.state?.pagination
              ? {
                  pageSize: context.state.pagination.pageSize,
                  page: context.state.pagination.page - 1,
                }
              : undefined
          }
          initialState={
            !context?.state?.pagination
              ? {
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }
              : undefined
          }
          sx={GridSx}
          {...tableProps}
        />
      </Box>
    </Box>
  );
};

const GridSx = {
  width: '100%',
  minWidth: '1051.7px',
  border: 'none',
  '&, & .MuiDataGrid-withBorderColor': {
    borderColor: '#CCCCCC',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected.Mui-hovered, & .MuiDataGrid-row.Mui-hovered': {
    bgcolor: 'transparent',
  },
  '& .MuiDataGrid-row.odd, & .MuiDataGrid-row.odd.Mui-selected': {
    bgcolor: '#F9F9F9',
  },
  '& .MuiDataGrid-cellCheckbox .MuiCheckbox-root': {
    mt: '-10px',
  },
  '& .MuiDataGrid-columnHeaderTitle, & .MuiDataGrid-columnHeaderTitleContainer': {
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-columnHeaders': {
    bgcolor: '#333',
    color: '#fff',
    '&:hover .MuiDataGrid-columnSeparator': {
      color: '#fff',
    },
  },
  '& .MuiDataGrid-iconButtonContainer .MuiIconButton-root, & .MuiDataGrid-menuIcon .MuiIconButton-root': {
    color: '#fff',
  },
  '& .MuiDataGrid-cell': {
    display: 'block',
    py: 2,
  },
  '& p': {
    mb: 0,
  },
  '& .MuiTablePagination-select': {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'hidden',
  },
};

import i18n from 'i18next';
/**
 * Returns array of bullet points parsed
 * from resolved translation or empty array
 *
 * @param {string} translationKey - translation key
 * @returns {Array<string>} - Array of bullet points for product description.
 */
export const getArrayFromTranslation = translationKey => {
  if (!i18n.exists(translationKey)) {
    return [];
  }

  const translation = i18n.t(translationKey);
  if (!translation || translation === '' || translation === translationKey) {
    return [];
  }
  return translation.split(';');
};

/**
 * Returns resolved translation if exists or null
 *
 * @param {string} translationKey - translation key
 * @returns {string | null} - Resolved translation
 */
export const getOptionalTranslation = translationKey => {
  if (!i18n.exists(translationKey)) {
    return null;
  }

  const translation = i18n.t(translationKey);
  if (translation === '' || translation === translationKey) {
    return null;
  }
  return translation;
};

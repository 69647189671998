import React, { Component } from 'react';

import { DashboardContext, DashboardProvider } from '../../context/context';
import Dashboard from './Dashboard';

export default class AppDashboard extends Component {
  render() {
    return (
      <DashboardProvider>
        <DashboardContext.Consumer>
          {context => <Dashboard context={context} url={window.location.pathname} />}
        </DashboardContext.Consumer>
      </DashboardProvider>
    );
  }
}

import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';
import { config } from '../../data-config';

export const loginFormValidation = (state, stateToSet, hasErrors) => {
  let getState = {};

  // Email check
  getState = checkFieldAndReturnState({
    name: 'email',
    value: state.email.value,
    regEx: config.regEx.email,
    isRequired: state.email.isRequired ? true : false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

  // Password check
  getState = checkFieldAndReturnState({
    name: 'password',
    value: state.password.value,
    regEx: config.regEx.everything,
    isRequired: state.password.isRequired ? true : false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

  return {
    stateToSet,
    hasErrors,
  };
};

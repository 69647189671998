import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Input } from 'components';
import { Error } from 'components';
import { MyContext } from '../../context/context';
import { config, CONST } from '../../data-config';

export default class BookingConfirmation extends Component {
  render() {
    return (
      <div className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <MyContext.Consumer>
              {context => (
                <>
                  <h4 className="margin-bottom-0" id="heading">
                    {t(`general|Booking Confirmation`)}
                  </h4>

                  <div className="frc__service-details-section--wrapper">
                    <div className="l-grid--w-100pc-s l-grid--w-30pc-m l-grid--w-50pc-m-s frc__generic-row--wrapper ">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t('general|Send email confirmation to') + ' :'}
                        name="confirmationEmail"
                        isRequired={true}
                        value={context.state.confirmationEmail.value}
                        lengthCheck={[RegExp(`^.{1,${config.maxEmailLength}}$`)]}
                        cutTextLimit={config.maxEmailLength}
                      />
                      {context.state.confirmationEmail.error ? (
                        <Error
                          className="frc__input--error"
                          message={t('general|errors|E-mail Error', { chars: config.maxEmailLength })}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </>
              )}
            </MyContext.Consumer>
          )}
        </Translation>
      </div>
    );
  }
}

import React, { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CONST, config } from '../../../data-config';
import { toggleActiveTab } from '../Template-helpers';
import { Input } from 'components';
import { Error } from 'components';

function TemplateName(props) {
  const { t } = useTranslation();
  const currentTab = useRef(null);
  const { context } = props;

  return (
    <div
      className="l-grid--w-100pc-s"
      onMouseLeave={() => toggleActiveTab(currentTab, false)}
      onMouseEnter={() => toggleActiveTab(currentTab, true)}
    >
      <div className="l-grid">
        <div className="frc__app-tab" ref={currentTab}>
          <h3 className="frc__app-tab__title frc__app-margin-bottom-0">{t('general|Template Name')}</h3>
        </div>
      </div>
      <div className="l-grid--w-100pc-s frc__generic-section--wrapper margin-bottom-1 ">
        <div className="l-grid--w-100pc-s">
          <Input
            context={context}
            className="l-grid--w-50pc-m l-grid--w-100pc-s-s frc__input--wrapper frc__generic--field-wrapper"
            config={config}
            CONST={CONST}
            regEx={config.regEx.everything}
            label={t('general|labels|inputs|Name')}
            name="templateName"
            dataTestId="templateNameInp"
            isRequired={context.state.templateName.isRequired}
            value={context.state.templateName.value}
            lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
            cutTextLimit={config.maxDefaultInputLength}
          />
          {context.state.templateName.error ? (
            <Error
              className="frc__input--error"
              message={t('general|errors|Invalid Length Error', {
                name: t('general|Template Name'),
                max: config.maxDefaultInputLength,
              })}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

const areFunctionPropsEqual = (prevProps, nextProps) => {
  return JSON.stringify(prevProps.context.state.templateName) === JSON.stringify(nextProps.context.state.templateName);
};

export default memo(TemplateName, areFunctionPropsEqual);

import { config } from '../../../data-config';
import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';

export const bookingConfirmationValidations = (context, stateToSet, hasErrors) => {
  const state = context.state;
  const getState = checkFieldAndReturnState({
    name: 'confirmationEmail',
    value: state['confirmationEmail'].value,
    regEx: config.regEx.email,
    lengthCheck: [RegExp(`^.{1,${config.maxEmailLength}}$`)],
    isRequired: true,
  });

  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  return {
    stateToSet,
    hasErrors,
  };
};

import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';
import { Input } from 'components';
import { FilterBanner } from 'components';
import { filterMatchedTemplateResults } from './TemplatesSearch-helpers';
import { fetchTemplateOnSearchOrPageChangeOrReset } from '../CommonUtils';

import './TemplatesSearch.css';

export default class TemplatesSearch extends Component {
  searchTemplate = e => {
    e.preventDefault();

    setTimeout(async () => {
      await fetchTemplateOnSearchOrPageChangeOrReset(this.props.context);
    }, 200);
  };

  render() {
    const { context } = this.props;

    const showAddTemplateBtn =
      context.state.accounts &&
      context.state.accounts.length > 0 &&
      JSON.stringify(context.state.userData) !== '{}' &&
      context.state.products.length > 0;

    return (
      <Translation>
        {t => (
          <>
            <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
              <div className=" l-grid--w-100pc-s l-grid--w-55pc-m l-grid--w-100pc-m-s">
                <form
                  onSubmit={this.searchTemplate}
                  className="l-grid l-grid--center-s l-grid--center-m-s l-grid--left-m-m l-grid--bottom-s l-grid--w-100pc-s "
                >
                  <div className="l-grid--w-100pc-s l-grid--w-70pc-m l-grid--left-s margin-bottom-1">
                    <Input
                      context={context}
                      disableonBlur={true}
                      config={config}
                      CONST={CONST}
                      tooltip={t(
                        `portalTemplates|Search by Template, Company, Contact, Street, City, Country, Account number, Product`,
                      )}
                      regEx={config.regEx.everything}
                      name="searchTerm"
                      dataTestId="searchTemplateInp"
                      isRequired={context.state.searchTerm.isRequired}
                      label={t('general|labels|Search')}
                      className="frc__input--wrapper frc__generic--field-wrapper"
                      value={context.state.searchTerm.value}
                      lengthCheck={[RegExp(`^.{${0},${config.maxSearchTermDigits}}$`)]}
                      cutTextLimit={config.maxSearchTermDigits}
                      afterUpdate={filterMatchedTemplateResults}
                      placeholder={t(
                        `portalTemplates|Search by Template, Company, Contact, Street, City, Country, Account number, Product`,
                      )}
                    />
                  </div>
                  <div className="frc__template-search--button l-grid--w-100pc-s l-grid--w-10pc-m">
                    <button className={'base-button l-grid--w-100pc-s '} id="searchTemplatesBtn">
                      <span>{t('general|labels|Search')}</span>
                    </button>
                  </div>
                </form>
              </div>

              <div className="l-grid--center-s l-grid--w-100pc-s l-grid--w-45pc-m   frc__template-search--button">
                <button
                  className={`base-button  
                  ${!showAddTemplateBtn ? 'disabled' : ''} 
                  base-button--wide l-grid--w-100pc-s l-grid--w-100pc-s-s l-grid--w-40pc-m`}
                  id="addTemplateBtn"
                  disabled={!showAddTemplateBtn}
                  onClick={showAddTemplateBtn ? () => this.props.onShowTemplate() : null}
                >
                  <span>{t(`portalTemplates|Add New Template`)}</span>
                </button>
              </div>
            </div>
            <FilterBanner
              CONST={CONST}
              remoteSearchTerm={context.state.remoteSearchTerm}
              searchTerm={context.state.searchTerm.value}
              t={t}
            />
          </>
        )}
      </Translation>
    );
  }
}

import { globalConfig, GLOBAL_CONST } from 'globals/data-config-global';
import { ROUTES } from 'router';

const componentConfig = {
  maxSearchTermDigits: 35,
  startABookingOptionsSe: [
    { label: 'Start a Booking', value: '', name: 'Start a Booking' },
    {
      name: 'Create a Pickup',
      value: ROUTES.portalPickup,
      label: 'Create a Pickup',
    },
    { name: 'Create a Shipment', value: ROUTES.portalOrder, label: 'Create a Shipment' },
    { name: 'Get a Quote', value: ROUTES.quoteTool, label: 'Get a Quote' },
  ],
  startABookingOptionsGlobal: [
    { label: 'Start a Booking', value: '', name: 'Start a Booking' },
    { name: 'Create a Shipment', value: ROUTES.portalOrder, label: 'Create a Shipment' },
    { name: 'Get a Quote', value: ROUTES.quoteTool, label: 'Get a Quote' },
  ],
  // edit contact
  maxAddressIdLength: 20,
  maxNotesLength: 140,
  maxAllowedPhoneNumbers: 3,
  maxNosImportContact: 1000,
  maxAddressLimit: 5000,
};

const COMPONENT_CONST = {
  API_DOMAIN: 'portalAddressBook',
  PORTAL_ADDRESS_BOOK: 'PAB',
};

export const config = Object.assign({}, globalConfig, componentConfig);
export const CONST = Object.assign({}, GLOBAL_CONST, COMPONENT_CONST);

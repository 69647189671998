import { ReactComponent as Loader } from '../../loader.svg';
import { GlobalBox } from './GlobalBox';

export const GlobalLoader = () => {
  return (
    <GlobalBox>
      <Loader />
    </GlobalBox>
  );
};

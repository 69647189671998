import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';
import { Error } from '../../../../components';
import { config } from '../../../../portal-pickup/data-config';
import { ErrorTitleWrapper, SuccessTitleWrapper, WarningTitleWrapper } from '../TitleWrapper';
import { redirect } from '../ThankYou-helpers';
import { Box } from '@mui/material';
import { formatDate } from '../../../utils/formatting';
import { ChatWithUs } from '../../../../components/fcp-components/ChatWithUs';
import { Button } from '../../../../components/fcp-components/Button';

const ThankYouPage = props => {
  const { isRedThankYouPage, pickupDate, pickupNumber, pickupOrderMovedToNextDay } = props;

  useEffect(() => {
    let newurl;

    if (process.env.NODE_ENV !== 'test') window.scrollTo(0, 0);

    if (config.regEx.urlValidation.test(window.location.pathname.split('/')[1])) {
      newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '#thankYou';
      window.history.pushState({ path: newurl }, '', newurl);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Translation>
      {t => (
        <Box
          sx={{
            maxWidth: '1365px',
            margin: 'auto',
            boxSizing: 'border-box',
            marginBottom: '15rem',
            marginTop: '2.5rem',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              flex: 2,
            }}
          >
            <Box
              sx={{
                border: '1px solid #ccc',
                borderRadius: '3px',
              }}
            >
              <Box
                sx={{
                  padding: '2rem',
                }}
              >
                {!isRedThankYouPage && !pickupOrderMovedToNextDay && (
                  <SuccessTitleWrapper titleCode="general|Thank you for booking your pickup" />
                )}
                {!isRedThankYouPage && pickupOrderMovedToNextDay && <WarningTitleWrapper />}
                {isRedThankYouPage && <ErrorTitleWrapper />}

                {isRedThankYouPage && (
                  <Error
                    name="isRedThankYouPage"
                    message={
                      t('general|errors|For any questions please contact') +
                      ' ' +
                      t('general|customerServicePhoneNumber')
                    }
                  />
                )}
                {!isRedThankYouPage && (
                  <>
                    {pickupOrderMovedToNextDay && (
                      <p>
                        {t(
                          'general|Your order has been booked, but the date you selected was not available. The order has been moved to the',
                        )}
                        <strong> {t('general|NEXT BUSINESS DAY')}.</strong>
                      </p>
                    )}
                    <p>
                      {t('general|Your pickup order number is')}
                      <strong> {pickupNumber}</strong>
                    </p>
                    {!pickupOrderMovedToNextDay && pickupDate !== null && (
                      <p>
                        {t('general|Pickup scheduled for')}
                        <strong> {formatDate(new Date(pickupDate))}</strong>
                      </p>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>

          {!isRedThankYouPage && (
            <Box
              sx={{
                flex: '1',
                marginLeft: ' 2rem',
              }}
            >
              <Box
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  padding: '2rem',
                  backgroundColor: '#f2f2f2',
                }}
              >
                <h5>{t('general|Next Steps')}</h5>
                <p>
                  {t(`portalPickup|A summary will be sent to your provided e-mail address`) +
                    ' ' +
                    t('general|changeCancelBookingInfo') +
                    ' ' +
                    t('general|or') +
                    ' '}
                  <ChatWithUs />
                </p>
              </Box>
              <Box
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  marginTop: '1.5rem',
                  padding: '2rem',
                  backgroundColor: '#f2f2f2',
                }}
              >
                <h5>{t('general|shipment|createAnotherShipment')}</h5>
                <Button
                  sx={{
                    color: '#d40511',
                    border: '1px solid #d40511',
                    marginRight: '1rem',
                    borderRadius: '3px',
                    padding: '0.75rem 1.25rem',
                    fontWeight: 600,
                    flex: 'none',
                  }}
                  onClick={redirect}
                  dataTestId="create-new-shipment-btn"
                >
                  {t(`general|Create a New Shipment`)}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Translation>
  );
};

export default ThankYouPage;

import { apiRequest } from 'globals/utils/requests';
import { CONST } from '../../../data-config';
import { apiLinks } from 'config/api-config';
import { getCountryAndCriteria } from '../AddressPickup/AddressPickup-helpers';

export const addressSearchBackendCall = async (ctxState, state, searchTerm, party) => {
  const resultMap = {};
  const { countryCriteria, criteriaCountries } = getCountryAndCriteria(ctxState, party);

  const body = {
    countryCriteria,
    criteriaCountries,
    page: state.page,
    pageSize: 5,
    searchTerm: searchTerm,
  };

  await apiRequest(apiLinks.addressSearch, 'POST', {
    body: body,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

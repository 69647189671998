import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterDate from './FilterDate/FilterDate';
import FilterStatus from './FilterStatus/FilterStatus';
import FilterAccounts from './FilterAccounts/FilterAccounts';

import './FilterShipments.css';

export default function FilterShipments(props) {
  const { t } = useTranslation();

  return (
    <div className="frc__shipment-filter-component">
      <h4 className="margin-bottom-1">{t(`portalSavedShipments|Filter Shipments`)}</h4>

      <FilterDate context={props.context} />

      <div className="l-grid l-grid--w-100pc-s l-grid--w-100pc-m">
        <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-100pc-m margin-bottom-1">
          <FilterAccounts context={props.context} />
        </div>
        <div className="l-grid--w-50pc-s  l-grid--w-100pc-s-s l-grid--w-100pc-m ">
          <FilterStatus context={props.context} />
        </div>
      </div>
    </div>
  );
}

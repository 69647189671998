import React from 'react';
import { Translation } from 'react-i18next';
import { Checkbox, Error } from 'components';
import { config, CONST } from '../../data-config';
import { saveShipmentCall } from '../../helpers/savedShipments';

function saveEmailConfirmation(context) {
  saveShipmentCall(context).catch(e => this.setState({ emailConfirmationError: true }));
}

export default function BookingConfirmation({ context }) {
  return (
    <div className="frc__generic-section--wrapper">
      <Translation>
        {t => (
          <>
            <h4 className="margin-bottom-0">{t(`portalOrder|Confirmation email`)}</h4>

            <div className="l-grid--w-100pc-s l-grid--w-100pc-m l-grid--w-50pc-m-s frc__generic-row--wrapper ">
              {context.state.pickupEmail.value && (
                <Checkbox
                  context={context}
                  config={config}
                  CONST={CONST}
                  label={context.state.pickupEmail.value + ' (' + t('general|Sender') + ')'}
                  name="pickupEmailConfirmation"
                  isRequired={false}
                  checked={context.state.pickupEmailConfirmation.value}
                  afterUpdate={() => {
                    saveEmailConfirmation(context);
                  }}
                />
              )}
              {context.state.deliveryEmail.value && (
                <Checkbox
                  context={context}
                  config={config}
                  CONST={CONST}
                  label={context.state.deliveryEmail.value + ' (' + t('general|Receiver') + ')'}
                  name="deliveryEmailConfirmation"
                  isRequired={false}
                  checked={context.state.deliveryEmailConfirmation.value}
                  afterUpdate={() => {
                    saveEmailConfirmation(context);
                  }}
                />
              )}
              {context.state.differentPickupEmail.value && (
                <Checkbox
                  context={context}
                  config={config}
                  CONST={CONST}
                  label={context.state.differentPickupEmail.value + ' (' + t('general|Pickup') + ')'}
                  name="differentPickupEmailConfirmation"
                  isRequired={false}
                  checked={context.state.differentPickupEmailConfirmation.value}
                  afterUpdate={() => {
                    saveEmailConfirmation(context);
                  }}
                />
              )}
              {context.state.differentDeliveryEmail.value && (
                <Checkbox
                  context={context}
                  config={config}
                  CONST={CONST}
                  label={context.state.differentDeliveryEmail.value + ' (' + t(`portalOrder|Delivery`) + ')'}
                  name="differentDeliveryEmailConfirmation"
                  isRequired={false}
                  checked={context.state.differentDeliveryEmailConfirmation.value}
                  afterUpdate={() => {
                    saveEmailConfirmation(context);
                  }}
                />
              )}
              {context.state.emailConfirmationError && (
                <Error
                  className="frc__input--error"
                  message={t(`${CONST.API_DOMAIN}|Confirmation email not saved successfully`)}
                />
              )}
            </div>
          </>
        )}
      </Translation>
    </div>
  );
}

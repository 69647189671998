// Just helpful functionality
function message(message) {
  console.log(
    '%c' + message,
    'color: #00529B; background-color: #BDE5F8; padding: 3px; border-top: 1px solid #00529B; border-bottom: 1px solid #00529B;',
  );
}
Object.assign(console, message);
console.message = message;

function alert(alert) {
  console.log(
    '%c' + alert,
    'color: #D8000C; background-color: #FFD2D2; padding: 3px; border-top: 1px solid #D8000C; border-bottom: 1px solid #D8000C;',
  );
}
Object.assign(console, alert);
console.alert = alert;

function success(success) {
  console.log(
    '%c' + success,
    'color: #4F8A10; background-color: #DFF2BF; padding: 3px; border-top: 1px solid #4F8A10; border-bottom: 1px solid #4F8A10;',
  );
}
Object.assign(console, success);
console.success = success;

function warning(warning) {
  console.log(
    '%c' + warning,
    'color: #9F6000; background-color: #FEEFB3; padding: 3px; border-top: 1px solid #9F6000; border-bottom: 1px solid #9F6000;',
  );
}
Object.assign(console, warning);
console.warning = warning;

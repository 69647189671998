import React from 'react';
import { toCamelCase } from 'globals/utils/formatting';
import './Error.css';

export default function Error({ message, className, name }) {
  const errId = toCamelCase(name || 'generic') + 'ErrId';
  return (
    <div
      id={errId}
      data-testid={errId}
      className={`frc__error ${className ? className : ''}`}
      dangerouslySetInnerHTML={{
        __html: message,
      }}
    />
  );
}

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AUTHORIZATION } from '../../Login/constants';
import { envConfig } from '../../lib/envConfig/envConfig';
import { ROUTES } from '../../router/routes';
import { setCookie } from '../../utils/cookies';

const SOFT_LIMIT_DAYS = envConfig().REACT_APP_PWD_EXP_SOFT_LIMIT_DAYS;
const HARD_LIMIT_DAYS = envConfig().REACT_APP_PWD_EXP_HARD_LIMIT_DAYS;

export const PASSWORD_EXPIRY_COOKIE = 'passwordExpiryStatus';

const setPasswordExpiryCookie = () => {
  const expirationDate = new Date();
  expirationDate.setHours(23, 59, 59);
  setCookie(PASSWORD_EXPIRY_COOKIE, 'seen', expirationDate);
};

export const PasswordExpiry = () => {
  const passwordExpiryCookie = Cookies.get(PASSWORD_EXPIRY_COOKIE);
  if (passwordExpiryCookie === 'changed') return;

  const authorizationCookie = localStorage.getItem(AUTHORIZATION);
  if (!authorizationCookie) {
    console.alert('ALERT: Modal invoked in a route without authorization cookie');
    return;
  }

  const { passwordAge } = jwtDecode(authorizationCookie);
  if (!passwordAge) return;

  const passwordAgeDays = Math.ceil((new Date() - passwordAge) / (1000 * 3600 * 24));
  const hardLimitBroken = passwordAgeDays >= HARD_LIMIT_DAYS;
  const softLimitBroken = passwordAgeDays >= SOFT_LIMIT_DAYS;

  if (hardLimitBroken) {
    return <PasswordDialogForce />;
  }

  if (softLimitBroken && !passwordExpiryCookie) {
    return <PasswordDialogSoft passwordAgeDays={passwordAgeDays} />;
  }
};

const PasswordDialogSoft = ({ passwordAgeDays }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  const handleClose = () => {
    setPasswordExpiryCookie();
    setOpen(false);
  };

  const handleChangePassword = () => {
    setPasswordExpiryCookie();
    navigate(`./${ROUTES.changePassword}`);
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{t('general|passwordExpiry|soonExpireDialogTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('general|passwordExpiry|soonExpireDialogText', {
            remainingDays: (HARD_LIMIT_DAYS - passwordAgeDays).toFixed(0),
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large" onClick={handleClose} data-testid="close-modal-btn">
          {t('general|labels|buttons|Close')}
        </Button>
        <Button variant="contained" size="large" onClick={handleChangePassword} data-testid="change-password-btn">
          {t('general|Change Password')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PasswordDialogForce = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    navigate(`./${ROUTES.changePassword}`);
  }, [navigate]);

  const handleChangePassword = () => {
    setPasswordExpiryCookie();
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{t('general|passwordExpiry|expiredDialogTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('general|passwordExpiry|expiredDialogText')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large" onClick={handleChangePassword} data-testid="change-password-btn">
          {t('general|Change Password')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

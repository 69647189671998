import { config } from '../../../data-config';
import { checkFieldAndReturnState, checkGroupOfFieldsAndReturnState } from 'globals/utils/fieldValidations';

export const enterPickupLocationValidations = (context, stateToSet, hasErrors) => {
  const state = context.state;
  let getState = {};

  // Sender check
  getState = checkFieldAndReturnState({
    name: 'pickupSender',
    value: state['pickupSender'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{1,${config.maxDefaultInputLength}}$`)],
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Street check
  getState = checkFieldAndReturnState({
    name: 'pickupStreet',
    value: state['pickupStreet'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{1,${config.maxDefaultInputLength}}$`)],
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Postalcode check
  getState = checkFieldAndReturnState({
    name: 'pickupPostalCode',
    value: state['pickupPostalCode'].value,
    regEx: config.regEx.number,
    lengthCheck: [RegExp(`^.{1,${config.maxPostalCode}}$`)],
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // City check
  getState = checkFieldAndReturnState({
    name: 'pickupCity',
    value: state['pickupCity'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{1,${config.maxDefaultInputLength}}$`)],
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value.trim(), error: getState.error };

  // Country check
  getState = checkFieldAndReturnState({
    name: 'pickupCountry',
    value: state['pickupCountry'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{${config.minCountryLength},${config.maxCountryLength}}$`)],
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Name check
  getState = checkFieldAndReturnState({
    name: 'pickupName',
    value: state['pickupName'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{1,${config.maxDefaultInputLength}}$`)],
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // E-mail check
  getState = checkFieldAndReturnState({
    name: 'pickupEmail',
    value: state['pickupEmail'].value,
    regEx: config.regEx.email,
    lengthCheck: [RegExp(`^.{0,${config.maxEmailLength}}$`)],
    isRequired: false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Phone Numbers check
  getState = checkGroupOfFieldsAndReturnState({
    groupName: 'pickupPhoneNumbers',
    values: state['pickupPhoneNumbers'],
    fields: [
      {
        name: 'code',
        isRequired: true,
      },
      {
        name: 'number',
        regEx: config.regEx.number,
        lengthCheck: [RegExp(`^.{1,${config.maxPhoneNumberDigits}}$`)],
        isRequired: true,
        calculatePhoneLengthCheck: true,
      },
    ],
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.groupName] = [...getState.values];

  // Sender Reference check
  getState = checkFieldAndReturnState({
    name: 'pickupSenderReference',
    value: state['pickupSenderReference'].value,
    regEx: config.regEx.everything,
    isRequired: false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  return {
    stateToSet,
    hasErrors,
  };
};

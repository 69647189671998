import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../data-config';
import { Error, Select } from 'components';
import { showTermsOfDeliveryDropdown } from '../EnterPickupLocation/EnterPickupLocation-helpers';
import { getAdditionalServicesForOneSelectedProduct } from '../ServiceDetails/ServiceDetails';
import { useGetCountryConfig } from '../../../hooks/api/useGetCountryConfig';
import { getTermsOfDeliveryPossibleValues } from '../../../globals/helpers/delivery-options';
import { readState, payerCode } from '../EnterPickupLocation/EnterPickupLocation-helpers';

// also known as payerCode
export const TermsOfDelivery = ({ context, loaderRef, isPublicOrder }) => {
  const { t } = useTranslation();
  const { data: countryConfig } = useGetCountryConfig();

  const termsOfDelivery = getTermsOfDeliveryPossibleValues({
    casePayerCode: payerCode(readState(context.state)),
    productCode: context.state?.selectedProduct?.code,
  });

  const handleUpdate = (context, snapshot) => {
    loaderRef.current.classList.add('is-loading');
    context.updateState(
      { payerCode: snapshot.value, termsOfDelivery: { ...context.state.termsOfDelivery, value: snapshot.value } },
      async () => {
        // we need the new payerCode
        const newState = await getAdditionalServicesForOneSelectedProduct(
          context.state,
          isPublicOrder ? false : true,
          true,
          null,
          countryConfig,
        );
        context.updateState(newState);
        loaderRef.current.classList.remove('is-loading');
      },
    );
  };

  useEffect(() => {
    if (!termsOfDelivery) return;
    if (!showTermsOfDeliveryDropdown(context)) return;
    if (context.state.payerCode && !termsOfDelivery.map(option => option.code).includes(context.state.payerCode)) {
      context.updateState({
        payerCode: '',
        termsOfDelivery: { ...context.state.termsOfDelivery, value: '', error: true },
      });
    }
  }, [context, termsOfDelivery]);

  return (
    <>
      {context.state.deliveryCountry.value && showTermsOfDeliveryDropdown(context) && (
        <div className="has-top-margin-2">
          <h4 className="margin-bottom-0 frc__red-lined-title">{t(`incoterms|Terms of delivery`)}</h4>
          <div className="l-grid--w-80pc-s l-grid--w-60pc-m l-grid--left-s  l-grid--w-40pc-m-m">
            <Select
              disabled={!Boolean(context?.state?.selectedProduct?.code)}
              hideAsterisk
              name="termsOfDelivery"
              isRequired={true}
              values={termsOfDelivery}
              value={context.state.payerCode}
              context={context}
              config={config}
              CONST={{
                API_DOMAIN: 'incoterms',
              }}
              disableonBlur={true}
              afterUpdate={handleUpdate}
            />
            {context.state.termsOfDelivery.error && (
              <Error
                name="termsOfDelivery"
                className="frc__input--error"
                message={t('general|errors|Select Error Option')}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

import { globalConfig, GLOBAL_CONST } from 'globals/data-config-global';

const componentConfig = {};

const COMPONENT_CONST = {
  API_DOMAIN: 'forgotPassword',
  ADDRESS_ERROR_CODE: 422,
  PALLET_PICKUP_PREFIX: 'FPW',
  PLACEHOLDER_EMAIL: 'mail@domain.com',
};

export const config = Object.assign({}, globalConfig, componentConfig);
export const CONST = Object.assign({}, GLOBAL_CONST, COMPONENT_CONST);

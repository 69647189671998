import { formatDateTime } from 'globals/utils/formatting';
import { config } from '../../data-config';

export const getAdditionalServices = additionalServices => {
  const selectedAdditionalServices = {};
  const additionalServicesMap = {};

  if (additionalServices && additionalServices.length > 0) {
    additionalServices.forEach(additionalService => {
      if (additionalService.value) {
        if (
          additionalService.groups &&
          additionalService.groups.length > 0 &&
          !additionalService.canMakeQuotePriceCall
        ) {
          const services = {};
          additionalService.groups.forEach(group => {
            group.options.forEach(optionList => {
              optionList.forEach(option => {
                if (additionalService.optionNotToHide && option.optionCode) {
                  switch (option.type) {
                    case config.OptionFieldTypes.Telephone:
                      services[option.optionCode] = option.value[0].code + '-' + option.value[0].number;
                      break;
                    case config.OptionFieldTypes.DateTime:
                      services[option.optionCode] = formatDateTime(option.value);
                      break;
                    case config.OptionFieldTypes.TickBox:
                      services[option.optionCode] = option.selected;
                      break;
                    case config.OptionFieldTypes.Select:
                      if (
                        additionalService.code === config.OptionFieldTypes.collectionAtTerminal &&
                        option.optionCode === config.OptionFieldTypes.ListCOT
                      ) {
                        const collectionAtTerminal = option.values.find(value => value.value === option.value);

                        additionalServicesMap.collectionAtTerminal = collectionAtTerminal;
                        selectedAdditionalServices[additionalService.code] = true;
                      } else services[option.optionCode] = option.value;
                      break;
                    default:
                      services[option.optionCode] = option.value ? option.value.replace(',', '.') : null;
                  }
                } else if (!additionalService.optionNotToHide) {
                  services[option.optionCode] = option.value;
                }
              });
            });
          });
          if (additionalService.code !== config.OptionFieldTypes.collectionAtTerminal)
            selectedAdditionalServices[additionalService.code] = services;
        } else {
          selectedAdditionalServices[additionalService.code] = true;
        }
      }
    });
  }
  additionalServicesMap.selectedAdditionalServices = selectedAdditionalServices;

  return additionalServicesMap;
};

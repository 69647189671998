import React from 'react';
import { useTranslation } from 'react-i18next';
import { config, CONST } from '../../../data-config';
import { Checkbox } from 'components';
import { Input } from 'components';
import { Error } from 'components';
import { cutIntegerAndShowError, cutDecimalAndShowError } from 'globals/context/context-helpers';
import { checkEmptyOrMinMaxOrLength } from 'globals/utils/fieldValidations';
import { useGetCountryConfig } from '../../../../hooks/api/useGetCountryConfig';
import './Markup.css';

export default function Markup(props) {
  const { t } = useTranslation();
  const { context } = props;
  const { data: countryConfig } = useGetCountryConfig();

  const clearMarkupValue = context => {
    const markup = JSON.parse(JSON.stringify(context.state.markup));

    if (markup.percentage.value || markup.amount.value) {
      props.getQuoteForPriceWithAd();
    }
  };

  const update = (params, context, blur = false) => {
    const markupObj = JSON.parse(JSON.stringify(context.state.markup));
    let value = '';
    let error = false;

    if (params.value) {
      error = checkEmptyOrMinMaxOrLength(params);

      if (params.integerCutoff && !isNaN(params.integerCutoff) && params.integerCutoff > 0) {
        params = cutIntegerAndShowError(params);
      }
      error = params.error === true ? true : error;

      if (params.decimalCutoff && !isNaN(params.decimalCutoff) && params.decimalCutoff > 0) {
        params = cutDecimalAndShowError(params, blur);
      }
      error = params.error === true ? true : error;

      value = params.value.toString().replace(',', '.') * 1;

      if (value && !(value >= params.min && value <= params.max)) {
        error = true;
      } else error = false;
    }

    markupObj[params.name].value = params.value;
    markupObj[params.name].error = error;

    context.updateState({ markup: markupObj });
  };

  const error = context.state.markup.percentage.error || context.state.markup.amount.error;

  return (
    <div className="margin-bottom-0 frc__push-bottom">
      <h4 className="margin-bottom-1 frc__red-lined-title">{t(`quoteTool|Markup`)}</h4>

      <Checkbox
        label={t(`quoteTool|Add your own Markup to this quote`)}
        name={'markup'}
        isRequired={false}
        checked={context.state.markup.value}
        afterUpdate={clearMarkupValue}
        config={config}
        CONST={CONST}
        context={context}
      />
      {context.state.markup.value ? (
        <div className="l-grid l-grid--w-100pc-m">
          <div
            className="l-grid l-grid--w-100pc-s l-grid--w-30pc-m l-grid--has-gutter c-freightcalc--margin-bottom c-freightportal--surcharge-input "
            onBlur={error ? null : props.getQuoteForPriceWithAd}
          >
            <div className="l-grid l-grid--w-100pc-s">
              <Input
                context={context}
                config={config}
                CONST={CONST}
                label={t(`quoteTool|Percentage`)}
                className="l-grid--w-50pc-s l-grid--w-80pc-m frc__input--wrapper frc__generic--field-wrapper"
                name="percentage"
                dataTestId="percentageInp"
                regEx={config.regEx.float}
                value={context.state.markup.percentage.value}
                updateOnParent={(params, context) => update(params, context)}
                lengthCheck={[RegExp(`^.{1,${config.maxMarkupDigits}}$`)]}
                min={CONST.MIN_MARKUP_VALUE}
                max={CONST.MAX_MARKUP_PERCENTAGE}
                integerCutoff={2}
                decimalCutoff={1}
              />
              <h5 className="frc__markup-attribute"> %</h5>
            </div>
            {context.state.markup.percentage.error ? (
              <Error
                name="markupPercentage"
                className="frc__input--error"
                message={t(`quoteTool|Invalid Field`, {
                  name: t(`quoteTool|Percentage`),
                  max: context.state.markup.percentage.value.replace(',', '.').indexOf('.') !== -1 ? 4 : 2,
                })}
              />
            ) : (
              ''
            )}
          </div>
          <div className="l-grid l-grid--w-100pc-s l-grid--w-15pc-m l-grid--has-gutter c-freightportal--surcharge-input frc__markup-separator">
            <div className="l-grid--right-s ">
              <b>{t(`quoteTool|AND/OR`)}</b>
            </div>
          </div>
          <div
            className="l-grid l-grid--w-100pc-s l-grid--w-40pc-m l-grid--has-gutter c-freightportal--surcharge-input "
            onBlur={error ? null : props.getQuoteForPriceWithAd}
          >
            <div className="l-grid l-grid--w-100pc-s">
              <Input
                context={context}
                config={config}
                CONST={CONST}
                label={t(`general|Amount`)}
                className="l-grid--w-50pc-s l-grid--w-60pc-m frc__input--wrapper frc__generic--field-wrapper"
                name="amount"
                dataTestId="amountInp"
                regEx={config.regEx.currencyAmount}
                value={context.state.markup.amount.value}
                updateOnParent={(params, context) => update(params, context)}
                min={CONST.MIN_MARKUP_VALUE}
                max={CONST.MAX_MARKUP_AMOUNT}
                lengthCheck={[RegExp(`^.{1,${config.maxMarkupDigits}}$`)]}
                integerCutoff={4}
              />
              <h5 className="frc__markup-attribute">{countryConfig?.defaultCurrency}</h5>
            </div>
            {context.state.markup.amount.error ? (
              <Error
                name="markupAmount"
                className="frc__input--error"
                message={t('general|errors|Invalid Length Error', {
                  name: t(`general|Amount`),
                  max: 4,
                })}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

import React from 'react';
import { Checkbox } from '../../../components';

export const viewContact = (contactList, t, isGlobalCountry) => {
  return contactList.map(contact => {
    return (
      <div
        key={contact.id}
        className="frc__modal-padding 
        frc__modal-content l-grid--w-100pc-s l-grid--w-100pc-m-m l-grid--w-100pc-m-s"
      >
        <h5 className="frc__modal-header">{contact.companyName}</h5>

        <div className={'frc__modal--row l-grid--left-s'}>
          <div className="frc__modal-attribute-color">{t(`portalAddressBook|Address ID / Nick`)}: </div>
          <div>{contact.addressId}</div>
        </div>

        <div className={'frc__modal--row l-grid--left-s frc__generic-row--wrapper'}>
          <div className="frc__modal-attribute-color">{t(`portalAddressBook|Notes`)}: </div>
          <div>{contact.notes}</div>
        </div>

        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
          <div className=" l-grid--w-50pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m">
            <div className="frc__modal-attribute-color"> {t(`general|Contact Details`)}: </div>
            <div className="frc__modal-word-wrap">
              <div> {contact.name} </div>
              <div>{contact.phone}</div>
              {contact.email && (
                <div>
                  <a href={`mailto:${contact.email}`} className="frc__data-grid-email">
                    {contact.email}
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className=" l-grid--w-50pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m ">
            <div className="frc__modal-attribute-color"> {t(`general|Address`)}: </div>
            <div className="frc__modal-word-wrap">
              <div>{contact.street}</div>
              <div>
                {contact.postalCode}
                {', '} {contact.city}
              </div>
              <div>{contact.countryName}</div>
            </div>
          </div>
        </div>
        <div className="l-grid l-grid--w-100pc-s ">
          <div className=" l-grid--w-100pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m ">
            <div className="frc__modal-attribute-color">{t('general|labels|inputs|reference')}:</div>
            {contact.reference}
          </div>
          <div className=" l-grid--w-100pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m">
            <div className="frc__modal-attribute-color">{t(`general|Delivery Instructions`)}:</div>
            {contact.deliveryInstructions}
          </div>
        </div>
        {isGlobalCountry ? null : (
          <div className="l-grid l-grid--w-100pc-s " style={{ lineHeight: '25px' }}>
            {t('general|This is a residential address')}:
            <span style={{ marginLeft: '15px' }}>
              <Checkbox disabled checked={contact.residentialAddress} />
            </span>
          </div>
        )}
      </div>
    );
  });
};

export const deleteContact = (contactList, t) => {
  return contactList.map(contact => {
    return (
      <div
        key={contact.id}
        className={`frc__modal-padding  frc__modal-content l-grid--w-100pc-s l-grid--w-100pc-m-m l-grid--w-100pc-m-s 
        `}
      >
        <h5 className="frc__modal-header">{contact.companyName}</h5>

        <div className="l-grid l-grid--w-100pc-s  ">
          <div className=" l-grid--w-50pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m">
            <div className="frc__modal-word-wrap">
              <div> {contact.name} </div>
              <div>{contact.phone}</div>
              <div className="frc__data-grid-email">{contact.email}</div>
            </div>
          </div>
          <div className=" l-grid--w-50pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m ">
            <div className="frc__modal-word-wrap">
              <div>{contact.street}</div>
              <div>
                {contact.postalCode}
                {', '} {contact.city}
              </div>
              <div>{contact.countryName}</div>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

import React, { Component } from 'react';
import { state } from '../data-state';
import { config } from '../data-config';

export const MyContext = React.createContext();

export class MyProvider extends Component {
  state = JSON.parse(JSON.stringify(state));

  componentDidMount = () => {
    let language = localStorage.getItem(config.locale.localStorageVariable) || config.locale.defaultLanguage;

    this.setState({
      languageCode: language,
    });
  };

  render() {
    return (
      <MyContext.Provider
        value={{
          state: this.state,
        }}
        displayName="Portal Quote Context Default"
      >
        {this.props.children}
      </MyContext.Provider>
    );
  }
}

import { useQuery } from '@tanstack/react-query';
import { apiRequest } from '../../globals';
import { apiLinks } from '../../config/api-config';

export const productDisabledVasQueryKey = 'product-disabled-vas';

const fetchProductDisabledVases = async () => {
  const res = await apiRequest(apiLinks.getDisabledVasForProducts, 'GET');
  if (res.status !== 200) {
    throw new Error(`Error in fetching product disabled VASes, Status: ${res.status} Message: ${res.status}`);
  }
  return res.data;
};

export const useGetProductDisabledVas = ({ select, options } = {}) => {
  return useQuery({
    queryKey: [productDisabledVasQueryKey],
    queryFn: fetchProductDisabledVases,
    select: select,
    staleTime: Infinity,
    ...options,
  });
};

import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';
import { toCamelCase } from 'globals/utils/formatting';
import { afterUpdate } from './PhoneNumber-helpersV2';
import { Button, Error, Input } from 'components';
import PhoneCountryCode from '../fcp-components/PhoneCountryCode';
import { changeCountryCodePhonePrefix } from '../../globals/helpers/country-helpers';
import { COUNTRY_SELECT_OPTIONS } from '../../locale/config';
import './PhoneNumber.css';

const PhoneNumberV2 = props => {
  const update = (params, context) => {
    params.groupName = props.groupName || 'phoneNumbers';

    if (props.updateOnParent) {
      props.updateOnParent(params, context);
    } else {
      context.updateGroupOfFields(params);
    }
  };

  const digits = props.config.maxPhoneNumberDigits - props.code.length - 1;
  const value = props && props.groupName && props.context ? props.context.state[props.groupName][0].code : '';

  useEffect(() => {
    const accountCountryCode = props.context?.state?.user?.accountCountryCode;
    const option = COUNTRY_SELECT_OPTIONS.find(
      countrySelectOption => countrySelectOption.code?.toLowerCase() === accountCountryCode?.toLowerCase(),
    );
    if (option && accountCountryCode && `+${option?.phone?.toLowerCase()}` !== value?.toLowerCase() && !value) {
      const phoneNumbersKey = props.groupName || 'phoneNumbers';
      changeCountryCodePhonePrefix(props.context, accountCountryCode, phoneNumbersKey);
    }
  }, [value, props.context, props.groupName]);

  return (
    <>
      <Translation>
        {t => (
          <>
            <div className="l-grid l-grid--w-100pc-s  frc__phone-number frc__generic-row--wrapper">
              <div
                className={
                  props.selectClassName ||
                  'l-grid--w-100pc-s l-grid--w-40pc-s-m l-grid--w-40pc-s-l l-grid--w-100pc-m-s l-grid--w-40pc-m-m'
                }
              >
                {props.groupName && (
                  <PhoneCountryCode
                    key={value}
                    required={props.isRequired ?? true}
                    label={t('general|Country code')}
                    data-testid={`${props.groupName}Slt`}
                    value={value}
                    onChange={(e, newValue) => {
                      props.context.updateState({
                        [props.groupName]: [
                          {
                            code: newValue && newValue.phone,
                            number: '',
                            error: '',
                            isRequired: props.isRequired ?? true,
                          },
                        ],
                      });
                      return afterUpdate;
                    }}
                    error={false}
                    helperText={''}
                    sx={{
                      marginTop: '35px',
                      '& .MuiInputBase-root': {
                        height: '48px',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#949494',
                      },
                      '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
                        // Default transform is "translate(14px, 20px) scale(1)""
                        // This lines up the label with the initial cursor position in the input
                        // after changing its padding-left.
                        transform: 'translate(34px, 20px) scale(1);',
                      },
                    }}
                    inputLabelProps={{
                      marginTop: '-40px',
                      marginLeft: '-40px',
                      paddingLeft: '6px',
                      fontSize: '15px',
                      fontFamily:
                        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                    }}
                  />
                )}
              </div>
              <div
                className={
                  props.inputClassName ||
                  'l-grid--w-100pc-s l-grid--w-60pc-s-m l-grid--w-60pc-s-l l-grid--w-100pc-m-s l-grid--w-60pc-m-m'
                }
              >
                <Input
                  id={toCamelCase(props.id ? props.id + 'Number' : props.groupName + 'Number')}
                  dataTestId={`${props.groupName}Number`}
                  context={props.context}
                  regEx={props.config.regEx.phoneNumber}
                  label={t('general|labels|inputs|Phone number')}
                  className="l-grid--w-100pc-s frc__input--wrapper frc__generic--field-wrapper"
                  index={props.index}
                  name={'number'}
                  // eslint-disable-next-line
                  isRequired={typeof props.isRequired === undefined ? true : props.isRequired}
                  hideAsterisk={false}
                  value={props.number}
                  updateOnParent={(params, context) => update(params, context)}
                  disabled={props.disabled}
                  lengthCheck={[new RegExp(`^.{0,${digits}}$`, 'g')]}
                  cutTextLimit={digits}
                  isPhoneNumber
                />
                {props.deleteButton ? (
                  <div className="fcp__phone-number-delete-button--wrapper">
                    <Button
                      submit={() => props.removeOnParent(props.index)}
                      className="frc__button--delete"
                      label="X"
                      dataTestId={`${props.groupName}DeleteBtn`}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {props.error ? (
              <Error
                name={props.errorId ? props.errorId : 'phone' + props.index}
                className="frc__input--error"
                data-testid={`${props.groupName}PhoneError`}
                message={t('general|errors|Phone Error', { digits: digits })}
              />
            ) : (
              ''
            )}
          </>
        )}
      </Translation>
    </>
  );
};
export default PhoneNumberV2;

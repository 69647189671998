import { createTheme } from '@mui/material/styles';

export const MAX_APP_WIDTH = '1365px';
const TEXT_FIELDS_BORDER_COLOR = '#949494';

export const muiTheme = createTheme({
  typography: palette => ({
    fontFamily: 'Delivery, Verdana, sans-serif',
    h1: {
      fontSize: '40px',
      fontWeight: 900,
      lineHeight: '4.9rem',
      color: palette.primary.main,
      marginBottom: '30px',
      textAlign: 'center',
    },
    h2: {
      fontSize: '28px',
      fontWeight: 900,
      marginBottom: '18px',
      color: palette.text.primary,
    },
    h3: {
      fontSize: '24px',
      fontWeight: 900,
      color: palette.text.primary,
    },
    h4: {
      fontSize: '16px',
      fontWeight: 600,
      color: palette.text.primary,
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      color: palette.text.primary,
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      color: palette.text.primary,
    },
  }),
  palette: {
    primary: {
      main: '#D40511', // red
      dark: '#BA0B1A',
      light: '#FAE6E7',
    },
    secondary: {
      main: '#333333', // dark grey
    },
    info: {
      main: '#FFCC00',
    },
    background: {
      section: '#f2f2f2', // grey
      block: '#e8e8e8', // grey
    },
    text: {
      primary: '#333333',
      secondary: '#D40511',
    },
    error: {
      main: '#d40511',
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '14px',
          textTransform: 'none',
          borderRadius: '3px',
        }),
        text: ({ theme }) => ({
          '&:hover': {
            textDecoration: 'underline',
          },
        }),
        sizeLarge: ({ theme }) => ({
          fontSize: '14px',
          paddingLeft: '70px',
          paddingRight: '70px',
        }),
        containedPrimary: ({ theme }) => ({
          fontWeight: 600,
        }),
        outlinedPrimary: ({ theme }) => ({
          fontWeight: 600,
          backgroundColor: theme.palette.background.default,
        }),
        outlinedSecondary: ({ theme }) => ({
          fontWeight: 600,
          backgroundColor: theme.palette.background.default,
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.background.default,
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '14px',
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '18px',
          fontWeight: 800,
          textAlign: 'center',
        }),
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          justifyContent: 'center',
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: ({ theme }) => ({
          '& > .MuiPaper-root': {
            padding: '35px',
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: TEXT_FIELDS_BORDER_COLOR,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: TEXT_FIELDS_BORDER_COLOR,
        }),
      },
    },
    MuiRadio: {
      variants: [
        {
          props: { color: 'default' },
          style: {
            color: '#000',
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          fontSize: '15px',
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiSvgIcon-root': { fontSize: '20px' },
        }),
      },
    },
  },
});

import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextWithBoldPart } from '../TextWithBoldPart';

export type DgItemDetailLabelProps = {
  dgmId: string;
  dgItemDetail: {
    unNumber: string;
    psn: string;
    comment?: string;
    class: string;
    packingGroup: string;
    tunnelCode: string;
  };
}

export function DgItemDetailLabel({ dgmId, dgItemDetail }: DgItemDetailLabelProps) {
  const { t } = useTranslation();
  return (
    <>
      <Box display="flex" flexDirection="column" gap={2}>
        <Stack>
          <Typography variant="h4" fontSize="18px">
            <span id={`${dgmId}-dgItemDetailUnNumber`}>{`UN ${dgItemDetail?.unNumber}`}</span>{' '}
            <span id={`${dgmId}-dgItemDetailPsn`}>{`${dgItemDetail?.psn}`}</span>
          </Typography>
          <Typography variant="body1">{dgItemDetail?.comment}</Typography>
        </Stack>
        <Stack direction="row" spacing={3}>
          <TextWithBoldPart
            normalText={`${t('portalOrder|ADR Class')}: `}
            boldText={<span id={`${dgmId}-dgItemDetailClass`}>{dgItemDetail?.class || '-'}</span>}
          />
          <TextWithBoldPart
            normalText={`${t('general|labels|inputs|Packaging Group')}: `}
            boldText={<span id={`${dgmId}-dgItemDetailPackingGroup`}>{dgItemDetail?.packingGroup || '-'}</span>}
          />
          <TextWithBoldPart
            normalText={`${t('general|Tunnel Description Code')}: `}
            boldText={<span id={`${dgmId}-dgItemDetailTunnelCode`}>{dgItemDetail?.tunnelCode || '-'}</span>}
          />
        </Stack>
      </Box>
    </>
  );
}

import React, { Component } from 'react';
import { state } from '../state/shipmentDetails-state';
import { checkEmptyOrMinMaxOrLength } from 'globals/utils/fieldValidations';
import { config } from '../data-config';
import {
  prepareFieldInStateForUpdate,
  cutTextAndShowError,
  cutDecimalAndShowError,
  cutIntegerAndShowError,
} from 'globals/context/context-helpers';

export const ShipmentDetailsContext = React.createContext();

export class ShipmentDetailsProvider extends Component {
  state = JSON.parse(JSON.stringify(state));

  componentDidMount = () => {
    let language = localStorage.getItem(config.locale.localStorageVariable) || config.locale.defaultLanguage;

    this.setState({
      languageCode: language,
    });
  };

  extendedSetState = (stateObject, params) => {
    let beforeUpdate = {};
    let afterUpdate = {};

    // Executes additional logic before update the state
    if (params !== undefined) {
      if (params.beforeUpdate && typeof params.beforeUpdate === 'function') {
        beforeUpdate = params.beforeUpdate(this, params, stateObject);
        if (typeof beforeUpdate === 'object' && beforeUpdate !== null) {
          beforeUpdate = JSON.parse(JSON.stringify(beforeUpdate));
          Object.assign(stateObject, beforeUpdate);
        }
      }
    }
    // Setting the new state and performs additional tasks on callback (if any)
    this.setState(stateObject, () => {
      const callbackState = {};

      // Executes additional logic after update the state
      if (params !== undefined) {
        if (params.afterUpdate && typeof params.afterUpdate === 'function') {
          afterUpdate = params.afterUpdate(this, params);
          if (typeof afterUpdate === 'object' && afterUpdate !== null) {
            afterUpdate = JSON.parse(JSON.stringify(afterUpdate));
            Object.assign(callbackState, afterUpdate);
          }
        }
      }

      if (Object.keys(callbackState).length > 0 && callbackState.constructor === Object) {
        this.setState(callbackState);
      }
    });
  };

  updateField = params => {
    let error = checkEmptyOrMinMaxOrLength(params);
    let stateObject = {};

    // Integer cutoff
    if (params.integerCutoff && !isNaN(params.integerCutoff) && params.integerCutoff > 0) {
      params = cutIntegerAndShowError(params);
    }
    error = params.error === true ? true : error;

    // Decimal cutoff
    if (params.decimalCutoff && !isNaN(params.decimalCutoff) && params.decimalCutoff > 0) {
      params = cutDecimalAndShowError(params, false);
    }
    error = params.error === true ? true : error;

    // Text cut
    if (params.cutTextLimit && !isNaN(params.cutTextLimit) && params.cutTextLimit > 0) {
      params = cutTextAndShowError(params);
    }
    error = params.error === true ? true : error;

    // Update the current field
    Object.assign(stateObject, prepareFieldInStateForUpdate(this.state, params.name, params.value, error));

    // Overides the value of related field
    if (params.override && this.state[params.override.name]) {
      Object.assign(stateObject, prepareFieldInStateForUpdate(this.state, params.override.name, params.value, error));
    }

    // Deselecting related fields
    if (params.deselect) {
      params.deselect.forEach(deselect => {
        Object.assign(stateObject, prepareFieldInStateForUpdate(this.state, deselect, false));
      });
    }

    // Set state
    this.extendedSetState(stateObject, params);
  };

  summGroupColumn = (group, column) => {
    let total = 0;
    if (group.length > 0) {
      group.forEach((elm, ind) => {
        if (elm[column] && elm[column].value && !isNaN(elm[column].value)) {
          total += elm[column].value * 1;
        } else if (!isNaN(elm[column])) {
          total += elm[column] * 1;
        }
      });
    }
    return total;
  };

  updateGroupOfFields = params => {
    let error = checkEmptyOrMinMaxOrLength(params);
    const duplicationError = false;
    const group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    let stateObject = {};

    // Integer cutoff
    if (params.integerCutoff && !isNaN(params.integerCutoff) && params.integerCutoff > 0) {
      params = cutIntegerAndShowError(params);
    }
    error = params.error === true ? true : error;

    // Decimal cutoff
    if (params.decimalCutoff && !isNaN(params.decimalCutoff) && params.decimalCutoff > 0) {
      params = cutDecimalAndShowError(params, false);
    }
    error = params.error === true ? true : error;

    // Text cut
    if (params.cutTextLimit && !isNaN(params.cutTextLimit) && params.cutTextLimit > 0) {
      params = cutTextAndShowError(params);
    }
    error = params.error === true ? true : error;

    if (group[params.index].code && group[params.index].code === params.name) {
      group[params.index].selectedStatus = params.value;
    } else if (typeof group[params.index][params.name] === 'object') {
      group[params.index][params.name].value = params.value;
      group[params.index][params.name].error = false;

      if (!params.skipError) {
        group[params.index][params.name].error = error;
      }
    } else {
      group[params.index][params.name] = params.value;

      if (!params.skipError) {
        group[params.index].error = error;
        group[params.index].duplicationError = duplicationError;
      }
    }

    stateObject = {
      [params.groupName]: group,
      dimensions: JSON.parse(JSON.stringify(this.state.dimensions)),
    };

    // Update min loading meter value based on whether `non stackable` is ticked
    if (group[params.index].nonStackable.value) {
      stateObject.dimensions.min_item_loading_meter = config.min_nonstackable_loading_meter;
    } else {
      stateObject.dimensions.min_item_loading_meter = config.dimensions.min_item_loading_meter;
    }

    // Set state
    this.extendedSetState(stateObject, params);
  };

  addAnotherGroupField = params => {
    const group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    let stateObject = {};

    group.push(params.item);
    stateObject[params.groupName] = group;

    // Set state
    this.extendedSetState(stateObject, params);
  };

  removeGroupField = params => {
    const group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    let stateObject = {};

    group.splice(params.index, 1);
    stateObject[params.groupName] = group;

    // Set state
    this.extendedSetState(stateObject, params);
  };

  updateState = (newState, callback) => {
    this.setState(
      {
        ...newState,
      },
      callback,
    );
  };

  render() {
    return (
      <ShipmentDetailsContext.Provider
        value={{
          state: this.state,
          extendedSetState: this.extendedSetState,
          updateField: this.updateField,
          updateGroupOfFields: this.updateGroupOfFields,
          addAnotherGroupField: this.addAnotherGroupField,
          removeGroupField: this.removeGroupField,
          updateState: this.updateState,
        }}
        displayName="Portal Quote Shipment Details Context"
      >
        {this.props.children}
      </ShipmentDetailsContext.Provider>
    );
  }
}

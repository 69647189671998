import {apiRequest} from './requests';
import {apiLinks} from '../../config/api-config';

const UserService = {

    async getUserPrintSettings() {
        return await apiRequest(apiLinks.getUserPrintSettings, 'GET')
            .then(response => {
                if (response && response.data) {
                    return response.data;
                }
            })
            .catch(e => {
                console.error('user print settings call error: ' + e);
            });
    },
};
export default UserService;

import { Box, Typography } from '@mui/material';
import { Button } from '../Button';
import { Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { printOptions } from '../registration/PrintSettingsForm';
import { PROFILE_SECTIONS } from './EditProfile';
import { PRINTER_CONSTANTS } from './data-config';

export const PrintSettings = ({ userData, setReadOnly }) => {
  const { t } = useTranslation();

  const printerOption =
    printOptions.find(item => item.value === userData?.user?.printDefaultPrinter) || printOptions[0];

  const defaultPrinter = t(printerOption.label);

  const showMargins =
    printerOption.value !== PRINTER_CONSTANTS.LABEL_PRINTER_VALUE &&
    printerOption.value !== PRINTER_CONSTANTS.LABEL_COMPACT_PRINTER_VALUE;

  return (
    <Box>
      <Typography variant="body1">
        {t('editProfile|Default printer')}: <strong>{defaultPrinter}</strong>
      </Typography>
      <Typography variant="body1" fontSize="14px">
        <em>
          {t('editProfile|labelOptionsInclude')}: {printOptions.map(item => t(item.label)).join(` ${t('general|or')} `)}
        </em>
      </Typography>
      {showMargins ? (
        <Box
          component="ul"
          sx={{
            my: '16px',
            '& li': {
              listStyle: 'none',
              lineHeight: '21.6px',
            },
          }}
        >
          <Box component="li">
            {t('editProfile|Top Margin')}: <strong>{userData?.user?.printTopMargin ?? 0}</strong>
          </Box>
          <Box component="li">
            {t('editProfile|Left Margin')}: <strong>{userData?.user?.printLeftMargin ?? 0}</strong>
          </Box>
          <Box component="li">
            {t('editProfile|Padding')}: <strong>{userData?.user?.printPadding ?? 0}</strong>
          </Box>
        </Box>
      ) : null}
      <Button
        variant="contained"
        color="primary"
        startIcon={<Edit />}
        sx={{
          mt: '16px',
        }}
        onClick={() => {
          setReadOnly({
            section: PROFILE_SECTIONS.printSettings,
            readOnly: false,
          });
        }}
        dataTestId="editPrintSettingsBtn"
      >
        {t('editProfile|editPrintSettings')}
      </Button>
    </Box>
  );
};

import { useNavigate, useParams } from 'react-router-dom';
import { isValidCountryLanguage } from '../utils/urls';
import { ROUTES } from './routes';

export const useRouting = () => {
  const navigateRouter = useNavigate();
  const { lang } = useParams();

  const navigate = (route, options) => {
    navigateRouter(linkTo(route), options);
  };

  const linkTo = route => {
    if (lang && isValidCountryLanguage(lang)) {
      return `/${lang}/${route}`;
    } else if (Object.values(ROUTES).includes(route)) {
      return `/${route}`;
    }
    return '/';
  };

  return {
    navigate,
    navigateRouter,
    linkTo,
  };
};

import { pickupRouteValidation } from './PickupRoute-validation';
import { receiverRouteValidation } from './ReceiverRoute-validation';
import { shipmentDetailsValidation } from './ShipmentDetails-validation';
import { schedulePickupValidations } from './SchedulePickup-validation';
import { additionalServicesOptionsValidations } from './AdditionalServices-validation';
import { validateTemplateName } from './TemplateName-validation';
import { validateAccountNumber } from './AccountNumber-validations';

export const validateTemplate = state => {
  let validate = {
    stateToSet: {},
    hasErrors: false,
    atLeastOneFieldFilled: false,
  };

  validate = validateTemplateName(state, validate.stateToSet, validate.hasErrors);

  validate = validateAccountNumber(state, validate.stateToSet, validate.hasErrors);

  validate.atLeastOneFieldFilled =
    validate.atLeastOneFieldFilled ||
    (state.accountNumber.display && state.accountNumber.value) ||
    (state.receiverNumber.display && state.receiverNumber.value) ||
    (state.thirdPartyNumber.display && state.thirdPartyNumber.value);

  if (state.pickupFromDifferentAddress.value && !state.pickupAddressResidential.value) {
    validate = pickupRouteValidation(
      state,
      validate.stateToSet,
      validate.hasErrors,
      validate.atLeastOneFieldFilled,
      'differentPickup',
    );
  }

  if (state.deliverToDifferentAddress.value && !state.deliveryAddressResidential.value) {
    validate = receiverRouteValidation(
      state,
      validate.stateToSet,
      validate.hasErrors,
      validate.atLeastOneFieldFilled,
      'differentDelivery',
    );
  }

  validate = pickupRouteValidation(
    state,
    validate.stateToSet,
    validate.hasErrors,
    validate.atLeastOneFieldFilled,
    'pickup',
  );

  validate = receiverRouteValidation(
    state,
    validate.stateToSet,
    validate.hasErrors,
    validate.atLeastOneFieldFilled,
    'delivery',
  );

  if (!validate.atLeastOneFieldFilled) {
    validate.atLeastOneFieldFilled = state.product.value ? true : false;
  }

  validate = shipmentDetailsValidation(state, validate.stateToSet, validate.hasErrors, validate.atLeastOneFieldFilled);

  validate = schedulePickupValidations(state, validate.stateToSet, validate.hasErrors, validate.atLeastOneFieldFilled);

  [validate.hasErrors, validate.stateToSet.additionalServices] = additionalServicesOptionsValidations(
    validate.hasErrors,
    JSON.parse(JSON.stringify(state.additionalServices)),
  );

  return validate;
};

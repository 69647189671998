import { config, CONST } from '../../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import {
  getOrderPhoneNumber,
  setCustomsFlag,
  fillDeliveryInstruction,
  fillSenderReceiverReference,
  setAddressReferenceInstructions,
} from '../../EnterPickupLocation/EnterPickupLocation-helpers';
import { INTERNATIONAL_SHIPMENT_RANGE } from '../../../../globals/constants';
import { getDefaultCountry } from '../../../../globals/helpers/country-helpers';

export const setSearchResultsAndPagination = (searchResults, state, pagination) => {
  const stateToSet = {};
  stateToSet.pagination = JSON.parse(JSON.stringify(state.paginationMaster));

  stateToSet.searchResults = searchResults.error ? [] : searchResults.data.addressBookList;
  stateToSet.searchResultsMaster = searchResults.error ? [] : searchResults.data.addressBookList;
  stateToSet.data = searchResults.error ? [] : searchResults.data.addressBookList;
  stateToSet.apiError = searchResults.error;
  stateToSet.loader = false;

  stateToSet.pagination.total = searchResults.error ? 0 : searchResults.data.total;
  stateToSet.pagination.pageSize = pagination.pageSize;
  stateToSet.pagination.lastPage = searchResults.error ? 0 : searchResults.data.lastPage;
  stateToSet.pagination.page = searchResults.error ? 1 : searchResults.data.page;

  stateToSet.paginationMaster = JSON.parse(JSON.stringify(stateToSet.pagination));

  return stateToSet;
};

export const resetPagination = () => {
  return {
    page: 1,
    total: 0,
    pageSize: config.paginationInitialNumberOfItems,
    lastPage: 1,
  };
};

export const getCountryList = async () => {
  const resultMap = {};

  await apiRequest(apiLinks.getCountryList)
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const getCountryAndCriteria = (ctxState, party) => {
  const defaultCountry = getDefaultCountry()?.toUpperCase();
  const partyIsReceiver = party === CONST.RECEIVER || party === CONST.DIFFERENT_RECEIVER;
  const criteriaCountry = partyIsReceiver ? ctxState.deliveryCountry.value : ctxState.pickupCountry.value;

  return {
    criteriaCountries: criteriaCountry ? [criteriaCountry] : [defaultCountry],
    countryCriteria: criteriaCountry ? 'INCLUDE' : 'EXCLUDE',
  };
};

export const postSearchResults = async (ctxState, pagination, party) => {
  const resultMap = {};
  const { countryCriteria, criteriaCountries } = getCountryAndCriteria(ctxState, party);

  const body = {
    countryCriteria,
    criteriaCountries,
    page: pagination && pagination.page ? pagination.page : 1,
    pageSize: pagination && pagination.pageSize ? pagination.pageSize : config.paginationInitialNumberOfItems,
    searchTerm: pagination ? ctxState.searchTerm.value : null,
  };

  await apiRequest(apiLinks.addressSearch, 'POST', {
    body: body,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const fillAddress = (context, address, party, checkAppropriateCity) => {
  const phoneNumber = getOrderPhoneNumber(address.phone);
  let stateToSet = {};
  let cityCheckElements = [];

  if (party === CONST.SENDER) {
    stateToSet = {
      ...context.state,
      pickupCity: { error: false, value: address.city || '', isRequired: true },
      pickupCountry: {
        error: false,
        value: address.countryCode || '',
        comparisonError: false,
        isRequired: true,
      },
      pickupEmail: {
        error: false,
        value: address.email || '',
        isRequired: address.residentialAddress ? address.residentialAddress : context.state.pickupEmail.isRequired,
      },
      pickupName: { error: false, value: address.name || '', isRequired: true },
      pickupPostalCode: { error: false, value: address.postalCode || '', isRequired: true },
      pickupPhoneNumbers: phoneNumber,
      pickupSender: { error: false, value: address.companyName || '', isRequired: true },
      pickupStreet: { error: false, value: address.street || '', isRequired: true },
      pickupAddressResidential: { value: address.residentialAddress },
      senderPickedFromAddressBook: true,
      senderAddressID: { value: address.id },
      senderAddressAddressID: { value: address.addressId },
      differentPickupCountry: {
        error: false,
        value: address.countryCode || '',
        comparisonError: false,
        isRequired: true,
      },
    };
    cityCheckElements = ['pickupCountry', 'pickupPostalCode', 'pickupCity', 'sourcePostalCodeError', 'sourceCityError'];
  } else if (party === CONST.RECEIVER) {
    stateToSet = {
      ...context.state,
      deliveryCity: { error: false, value: address.city || '', isRequired: true },
      deliveryCountry: {
        error: false,
        value: address.countryCode || '',
        comparisonError: false,
        isRequired: true,
      },
      deliveryEmail: {
        error: false,
        value: address.email || '',
        isRequired: address.residentialAddress ? address.residentialAddress : context.state.deliveryEmail.isRequired,
      },
      deliveryName: { error: false, value: address.name || '', isRequired: true },
      deliveryPostalCode: { error: false, value: address.postalCode || '', isRequired: true },
      deliveryPhoneNumbers: phoneNumber,
      deliveryReceiver: { error: false, value: address.companyName || '', isRequired: true },
      deliveryStreet: { error: false, value: address.street || '', isRequired: true },
      deliveryAddressResidential: { value: address.residentialAddress },
      receiverPickedFromAddressBook: true,
      receiverAddressID: { value: address.id },
      receiverAddressAddressID: { value: address.addressId },

      differentDeliveryCountry: {
        error: false,
        value: address.countryCode || '',
        comparisonError: false,
        isRequired: true,
      },
    };
    cityCheckElements = [
      'deliveryCountry',
      'deliveryPostalCode',
      'deliveryCity',
      'destinationPostalCodeError',
      'destinationCityError',
    ];
  } else if (party === CONST.DIFFERENT_SENDER) {
    stateToSet = {
      ...context.state,
      differentPickupCity: { error: false, value: address.city || '', isRequired: true },
      differentPickupCountry: {
        error: false,
        value: address.countryCode || '',
        comparisonError: false,
        isRequired: true,
      },
      differentPickupEmail: {
        error: false,
        value: address.email || '',
        isRequired: address.residentialAddress
          ? address.residentialAddress
          : context.state.differentPickupEmail.isRequired,
      },
      differentPickupName: { error: false, value: address.name || '', isRequired: true },
      differentPickupPostalCode: { error: false, value: address.postalCode || '', isRequired: true },
      differentPickupPhoneNumbers: phoneNumber,
      differentPickupSender: { error: false, value: address.companyName || '', isRequired: true },
      differentPickupStreet: { error: false, value: address.street || '', isRequired: true },
      differentPickupAddressResidential: { value: address.residentialAddress },
      differentSenderPickedFromAddressBook: true,
      differentSenderAddressID: { value: address.id },
      differentSenderAddressAddressID: { value: address.addressId },
    };
    cityCheckElements = [
      'differentPickupCountry',
      'differentPickupPostalCode',
      'differentPickupCity',
      'sourcePostalCodeError',
      'sourceCityError',
    ];
  } else if (party === CONST.DIFFERENT_RECEIVER) {
    stateToSet = {
      ...context.state,
      differentDeliveryCity: { error: false, value: address.city || '', isRequired: true },
      differentDeliveryCountry: {
        error: false,
        value: address.countryCode || '',
        comparisonError: false,
        isRequired: true,
      },
      differentDeliveryEmail: {
        error: false,
        value: address.email || '',
        isRequired: address.residentialAddress
          ? address.residentialAddress
          : context.state.differentDeliveryEmail.isRequired,
      },
      differentDeliveryName: { error: false, value: address.name || '', isRequired: true },
      differentDeliveryPostalCode: { error: false, value: address.postalCode || '', isRequired: true },
      differentDeliveryPhoneNumbers: phoneNumber,
      differentDeliveryReceiver: { error: false, value: address.companyName || '', isRequired: true },
      differentDeliveryStreet: { error: false, value: address.street || '', isRequired: true },
      differentDeliveryAddressResidential: { value: address.residentialAddress },
      differentConfirmationEmail: { error: false, value: address.email || '', isRequired: true },
      differentReceiverPickedFromAddressBook: true,
      differentReceiverAddressID: { value: address.id },
      differentReceiverAddressAddressID: { value: address.addressId },
    };
    cityCheckElements = [
      'differentDeliveryCountry',
      'differentDeliveryPostalCode',
      'differentDeliveryCity',
      'destinationPostalCodeError',
      'destinationCityError',
    ];
  }

  stateToSet.showCompanySearchMainSender = false;
  stateToSet.showCompanySearchDifferentSender = false;
  stateToSet.showCompanySearchMainReceiver = false;
  stateToSet.showCompanySearchDifferentReceiver = false;

  setCustomsFlag(stateToSet, context.state, context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE);
  fillDeliveryInstruction(stateToSet, party, context.state, address);
  fillSenderReceiverReference(stateToSet, party, address);
  setAddressReferenceInstructions(stateToSet, party, context.state, address);

  context.updateState(stateToSet, () => {
    // Additional check for city postal code/name after applying the data
    if (cityCheckElements.length > 0) {
      checkAppropriateCity(...cityCheckElements);
    }
  });
};

export const shipmentRangeIsAllowed = (selectedAddress, context, party) => {
  if (party === CONST.RECEIVER && context.state.internationalProductsSelected && selectedAddress.countryCode === 'SE')
    return false;
  return true;
};

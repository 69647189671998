import { Radio } from 'components';
import { hidePricesDueToPayerType } from 'globals/utils/filtering';
import { toCamelCase } from 'globals/utils/formatting';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductCardDeliveryDate } from '../../../../../components/fcp-components/ProductCardDeliveryDate';
import { PRODUCTS_RANGE } from '../../../../../globals/constants';
import { getDescBulletPoints, getReadMoreLink } from '../../../../../globals/helpers/product';
import { useGetCountryConfig } from '../../../../../hooks/api/useGetCountryConfig';
import { CONST, config } from '../../../../data-config';
import { patchPALLPriceToFreightCost } from '../../../EnterShipmentDetails/EnterShipmentDetails-helpers';
import { afterCheck, beforeCheck } from '../../../ServiceDetails/ServiceDetails';
import './SelectService.css';
import { useSelector } from 'react-redux';
import { productPickupDatesSelector, selectedPickupDateSelector } from '../../../../../store/portalOrderSlice';

export default function SelectService({ context, product, index }) {
  const { t, i18n } = useTranslation();
  const { data: countryConfig } = useGetCountryConfig();
  const { selectedPickupDate } = useSelector(selectedPickupDateSelector);
  const { productPickupDates } = useSelector(productPickupDatesSelector);

  const selectService = (params, countryConfig) => {
    const ctxParams = {};
    const stateObj = {
      additionalServices: [],
      dangerousGoodsLinkedIfNeeded: true,
      additionalServicesError: false,
      additionalServicesAPIError: false,
      selectedProduct: { value: '' },
      selectedServicePoint: {
        id: '',
        street: '',
        name: '',
        cityName: '',
        postalCode: '',
        countryCode: null,
        distance: 0,
        routeDistance: 0,
        featureCodes: [],
        lat: 0,
        lng: 0,
      },
    };

    // Update `customs` to decide whether to display the "Upload Customs Documents" dialog on the last page
    stateObj.customs =
      config.productsWithUploadCustomsDocumentsForAllCountries.indexOf(params.name) !== -1
        ? true
        : context.state.countryBasedCustoms;

    stateObj.selectedProduct = context.state.matchedProducts.find(
      chosenProduct => chosenProduct.code === params.value,
    ) ?? { code: '' };
    stateObj.selectedProduct.error = params.value === '';
    stateObj.additionalServicesGeneralError = stateObj.selectedProduct.error;
    stateObj.preSelectedTemplateNotAvailable = '';

    if (stateObj.selectedProduct.code) {
      ctxParams.beforeUpdate = beforeCheck;
      // ctxParams.afterUpdate = afterCheck;

      ctxParams.afterUpdate = afterCheck(context, true, stateObj.selectedProduct, countryConfig);
    }

    context.extendedSetState(stateObj, ctxParams);

    if (document.getElementById('frc_additional-services_delivery_monitoring')) {
      document.getElementById('frc_additional-services_delivery_monitoring').classList.remove('expand');
      document.getElementById('frc__special_case').classList.add('frc__special_case');
    }
    if (document.getElementById('fcp-product-container'))
      document.getElementById('fcp-product-container').classList.remove('has-pricelist-error');
  };

  const getProperPriceFromQuoteMap = state => {
    let price = product.price;
    let priceUnit = product.priceUnit ? product.priceUnit : countryConfig?.defaultCurrency;

    if (!context.state.loaders.additionalServices.value) {
      const checkForSelectProduct =
        state.selectedProduct && state.selectedProduct.code && state.selectedProduct.code === product.code;
      const checkInFreightCost =
        state.quotePriceMap &&
        state.quotePriceMap.FreightCost &&
        state.quotePriceMap.FreightCost.value &&
        state.quotePriceMap.FreightCost.value.length > 0;
      const checkForPall = !!(
        state.quotePriceMap &&
        !state.quotePriceMap.hasOwnProperty('FreightCost') &&
        (state.quotePriceMap.hasOwnProperty('PALL') || state.quotePriceMap.hasOwnProperty('HALV'))
      );

      // Price for all products except DHL PALL
      if (checkForSelectProduct && checkInFreightCost) {
        price = state.quotePriceMap.FreightCost.value;
        priceUnit = state.quotePriceMap.FreightCost.unit;
      }
      // Price in case of DHL PALL
      else if (checkForSelectProduct && !!checkForPall) {
        price = patchPALLPriceToFreightCost(JSON.parse(JSON.stringify(state.quotePriceMap)));
      }
    }

    if (price === 'null' || price === 0) {
      return '';
    }

    return { productPrice: price, productPriceUnit: priceUnit };
  };

  const accountNumber = context.state.payingAccountNumber.value;
  const showPrices = !hidePricesDueToPayerType(context.state);
  const { productPrice, productPriceUnit } = getProperPriceFromQuoteMap(context.state);

  const productRangeSingle =
    context.state.productRange !== PRODUCTS_RANGE.ACCOUNT && context.state.productRange !== PRODUCTS_RANGE.COUNTRY;

  const isProductDisabled = () => {
    if (product.disabled && !config.ltcEurapidProductCodes.includes(product.code.toString())) {
      return true;
    }
    // TODO think about ltc_error, when it does not exist in BE response
    if (config.ltcEurapidProductCodes.includes(product.code.toString()) && product.deliveryDate === 'ltc_error') {
      return true;
    }
    if (productRangeSingle && context.state.productRange !== product?.code) {
      return true;
    }

    return false;
  };

  const productDisabled = isProductDisabled();

  return (
    <>
      <div className={`c-product-width  c-product ${index}`} id={index}>
        <div
          className={
            'l-grid--w-100pc-s  c-freightcalc--pricelist-box ' +
            (context?.state?.selectedProduct?.code === product?.code
              ? 'fcp__is-selected'
              : 'c-swe--freightcalc--result-wrapper')
          }
        >
          <div className="l-grid--center-s c-swe-freightcalc--pricelist-top">
            <span className="color-DHL-red" id={`${product.code}_name`}>
              {t(`products|${product.code}|name`)}
            </span>
          </div>

          <div className="l-grid--center-s c-freightcalc--pricelist-amount-display">
            <h4
              className="h4 color-DHL-red margin-bottom-0"
              data-currency={productPriceUnit}
              id={toCamelCase(product.name + 'PriceTag')}
            >
              {productPrice && showPrices
                ? productPrice + ' ' + productPriceUnit
                : t('general|errors|No Price Available')}
            </h4>
            <div className="c-swe-freightcalc--margin-bottom-x2">
              <span className="js--freight-price-disclaimer" id="product_price_disclaimer">
                {t('general|Product Price Disclaimer')}
              </span>
              <br />
            </div>
            <div>
              <br />
              {accountNumber && t(`general|For account`) + ': ' + accountNumber}
              <br />
            </div>
          </div>

          <div className="l-grid--center-s l-grid--w-100pc-s c-freightcalc--pricelist-delivery-date">
            <ProductCardDeliveryDate
              productCode={product.code}
              deliveryDate={product.deliveryDate}
              additionalServices={context.state?.additionalServices}
              selectedProductCode={context.state?.selectedProduct?.code}
              selectedPickupDate={selectedPickupDate}
              productPickupDates={productPickupDates}
            />
          </div>
          <div className="l-grid--left-s c-freight-pricelist-details">
            <div className="l-grid--center-s l-grid--w-100pc-s c-freightcalc--pricelist-seedetails">
              &nbsp;{t('general|See more details')}
            </div>
            <div className="c-freightcalc--pricelist-details-display list is-left c-freightcalc--list">
              <div className="c-fcp-product-description">{t(`products|${product.code}|description`)}</div>
              <ul>
                {getDescBulletPoints(product.code, i18n, t).map((bulletPoint, index) => (
                  <li key={index}>
                    <span> {bulletPoint} </span>
                  </li>
                ))}
                {getReadMoreLink(product.code, i18n, t) ? (
                  <li>
                    <span className="c-swe-freightcalc--productinfo-link">
                      <a
                        href={getReadMoreLink(product.code, i18n, t)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="c-swe-freightcalc-price-quote-error link color-DHL-red has-icon link-external"
                      >
                        {t('general|Read More')}...
                      </a>
                    </span>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
          <div
            className={`l-grid--center-s c-freightcalc--pricelist-checkbox
           ${productDisabled ? 'c-freightcalc--pricelist-checkbox--disabled' : ''}`}
          >
            <Radio
              label={t('general|labels|inputs|Select')}
              id={product.name + 'Select'}
              data-testid={`${product.code}SelectRdBtn`}
              isBooleanValueRequired
              name={product.name}
              disabled={productDisabled}
              className={'l-grid frc__radio--wrapper frc__service-radio'}
              isRequired={false}
              checked={context?.state?.selectedProduct?.code === product?.code}
              value={product.code}
              context={context}
              config={config}
              CONST={CONST}
              updateOnParent={params => selectService(params, countryConfig)}
              checkGroupIndex={1}
            />
          </div>
        </div>
      </div>
      <br />
    </>
  );
}

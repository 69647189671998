import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import EnterPickupLocation from '../EnterPickupLocation/EnterPickupLocation';
import ServiceDetails from '../ServiceDetails/ServiceDetails';
import EnterShipmentDetails from '../EnterShipmentDetails/EnterShipmentDetails';
import AdditionalServices from '../AdditionalServices/AdditionalServices';
import PickupDate from '../PickupDate/PickupDate';
import BookingConfirmation from '../BookingConfirmation/BookingConfirmation';
import TermsAndSubmit from '../TermsAndSubmit/TermsAndSubmit';
import ThankYouPage from '../../../globals/components/ThankYou/pickup/ThankYouPage';
import SideSummaryPanel from '../SideSummaryPanel/SideSummaryPanel';
import './PortalPickup.css';
import 'globals/utils/debugHelpers';

export default class PortalPickup extends Component {
  render() {
    const context = this.props.context;
    return (
      <>
        {context.state.publicPickupBookingSubmited || context.state.redThankYouPage ? (
          <ThankYouPage
            pickupOrderResult={context.state.pickupOrderResult}
            isRedThankYouPage={context.state.redThankYouPage}
            redThankYouPageErrorMessage={context.state.redThankYouErrorMessage}
            pickupDate={context.state.pickupOrderResult.pickupDateTime}
            pickupNumber={context.state.pickupOrderResult.bookingNumber}
            pickupOrderMovedToNextDay={context.state.pickupOrderMovedToNextDay}
          />
        ) : (
          <div className="l-grid">
            <Translation>
              {t => (
                <>
                  <div className="l-grid--w-100pc-s l-grid--w-75pc-m">
                    <ServiceDetails context={context} />
                    <EnterPickupLocation context={context} />
                    <EnterShipmentDetails context={context} />
                    <AdditionalServices context={context} />
                    <PickupDate context={context} />
                    <BookingConfirmation />
                    <TermsAndSubmit hideCaptcha={true} />
                  </div>
                  <div className="l-grid--w-100pc-s l-grid--w-25pc-m">
                    <SideSummaryPanel context={context} />
                  </div>
                </>
              )}
            </Translation>
          </div>
        )}
      </>
    );
  }
}

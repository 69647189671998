import React from 'react';
import { Translation } from 'react-i18next';
import TemplateCard from '../TemplateCard/TemplateCard';

import '../../Template-Address.css';

export default function SavedShipmentResultGrid(props) {
  const renderData = (templates, showFavorites = true) => {
    return templates.map(data => {
      return (
        <TemplateCard
          key={data.id}
          showFavorites={showFavorites}
          isReceiver={props.isReceiver}
          context={props.context}
          template={data}
          handleClick={props.handleClick}
        />
      );
    });
  };

  return (
    <Translation>
      {t => (
        <>
          {props.showFavorites ? (
            <div className="l-grid">
              <div className="l-grid--w-50pc-s frc__address-book-col">
                {t(`portalOrder|Newest Template`)}
                <div className="frc__address-book-grid frc__generic-section--wrapper">
                  {renderData(props.newestTemplates)}
                </div>
              </div>
              <div className="l-grid--w-50pc-s frc__address-book-col">
                {t(`portalOrder|Most Popular Template`)}
                <div className=" frc__address-book-grid frc__generic-section--wrapper">
                  {renderData(props.popularTemplates)}
                </div>
              </div>
            </div>
          ) : (
            <div className="l-grid frc__address-book-grid frc__generic-section--wrapper">
              {renderData(props.searchResults, false)}
            </div>
          )}
        </>
      )}
    </Translation>
  );
}

import { CONST } from '../../portal-order/data-config';
import { apiRequest } from '../utils/requests';
import { apiLinks } from '../../config/api-config';
import { caseInsensitiveEquals } from '../utils/strings';
import { getDefaultCountry } from './country-helpers';

export const getQuoteForPriceAPICall = async quoteForPriceData => {
  const quoteForPrice = {};
  quoteForPrice.error = false;

  await apiRequest(apiLinks.pricequote, 'POST', {
    body: quoteForPriceData,
  })
    .then(response => {
      if (response.status === CONST.STATUS_OK) {
        quoteForPrice.data = response.data;
      } else {
        quoteForPrice.error = true;
      }
    })
    .catch(() => {
      quoteForPrice.error = true;
    });

  return quoteForPrice;
};

export const getTermsOfDeliveryPossibleValues = ({ casePayerCode, productCode }) => {
  const FCA_PRODUCTS = ['202', '205', '232', '233'];
  const isSweden = caseInsensitiveEquals(getDefaultCountry(), 'se');
  const addFca = isSweden && FCA_PRODUCTS.includes(productCode);

  let list = [];

  const case_1 = [{ code: 'EXW', name: 'Ex Works' }];
  if (addFca) {
    case_1.push({ code: 'FCA', name: 'Free Carrier' });
  }

  const case_2_3 = [
    { code: 'DAP', name: 'Delivered At Place' },
    { code: 'DDP', name: 'Delivered Duty Paid' },
  ];

  const case_4_5 = [{ code: 'EXW', name: 'Ex Works' }];
  if (addFca) {
    case_4_5.push({ code: 'FCA', name: 'Free Carrier' });
  }

  if (casePayerCode === 'case1') {
    list = case_1;
  }

  if (casePayerCode === 'case2' || casePayerCode === 'case3') {
    // DPP is only for customs country
    // if (!context.state.countryBasedCustoms) case_2_3.splice(1, 1);

    list = case_2_3;
  }

  if (casePayerCode === 'case4' || casePayerCode === 'case5') {
    list = case_4_5;
  }

  if (casePayerCode === 'case9') list = [{ code: '000', name: 'Not Available' }];

  // helper for special case scenarios
  list.unshift({ code: '', name: 'Please select terms of delivery', value: '' });

  return list;
};

import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config } from '../../../../data-config';
import { Button } from 'components';
import OptionInput from './OptionInput';
import OptionDateTime from './OptionDateTime';
import OptionDate from './OptionDate';
import OptionPhoneNumber from './OptionPhoneNumber';
import OptionCheckbox from './OptionCheckbox';
import OptionSelect from './OptionSelect';
import OptionLTCDate from './OptionLTCDate';
import ConditionalOptionGroupError from './ConditionalOptionGroupError';
import { getTimeTable } from '../../../DeliveryOptions/DeliveryOptions-helpers';
import { OptionSubHeading } from '../../../../../components/fcp-components/OptionSubHeading';

import './AdditionalServicesOptions.css';
import { setSelectedPickupDate } from '../../../../../store/portalOrderSlice';

export default class AdditionalServicesOptions extends Component {
  resetOptionValues = option => {
    const newOption = JSON.parse(JSON.stringify(option));
    newOption.map(element => {
      element.value = null;
      delete element.error;
      delete element.errorMessage;

      return element;
    });

    return newOption;
  };

  addNewOptionsSchemaRow = (groupIndex, maxRepeat) => {
    const additionalServices = JSON.parse(JSON.stringify(this.props.context.state.additionalServices));
    const noOfRepeats = additionalServices[this.props.serviceIndex].groups[groupIndex].options.length;

    if (maxRepeat >= noOfRepeats) {
      const optionSchemaToBeAdded = this.resetOptionValues(
        additionalServices[this.props.serviceIndex].groups[groupIndex].options[0],
      );
      additionalServices[this.props.serviceIndex].groups[groupIndex].options.push(optionSchemaToBeAdded);
    }

    this.props.context.updateState({
      additionalServices,
    });
  };

  deleteOptionsSchema = (groupIndex, optionParentIndex) => {
    const additionalServices = JSON.parse(JSON.stringify(this.props.context.state.additionalServices));
    additionalServices[this.props.serviceIndex].groups[groupIndex].options.splice(optionParentIndex, 1);

    this.props.context.updateState({
      additionalServices,
    });
  };

  checkDisableOnBlur = code => {
    return code === config.OptionFieldTypes.collectionAtTerminal;
  };

  afterUpdate = (params, context) => {
    // Quote For Price and Time Table call if Collection at Terminal
    if (params.name === config.OptionFieldTypes.ListCOT) {
      // Time Table
      getTimeTable(context.state.pickupDate.value, context, false, true, true, setSelectedPickupDate);

      // Quote For Price
      this.props.getQuoteForPriceWithAd();
    }
  };

  render() {
    return (
      <>
        <Translation>
          {t => (
            <div onBlur={this.props.disableOptionsSchemCall ? null : this.props.getQuoteForPriceWithAd}>
              {this.props.groups.map((group, groupIndex) => (
                <React.Fragment key={groupIndex}>
                  {group.options.map((optionList, optionParentIndex) => (
                    <div
                      key={optionParentIndex}
                      className="frc__as-options--wrapper l-grid l-grid--w-100pc-s l-grid--w-90pc-m frc__options-schema-bg-color"
                    >
                      {group.maxRepeat > 1 && group.options.length > 1 && (
                        <Button
                          id={'deleteOptionsSchemaRow' + optionParentIndex}
                          submit={() => this.deleteOptionsSchema(groupIndex, optionParentIndex)}
                          className="frc__button--delete"
                          label="X"
                        />
                      )}
                      {optionList.map((option, optionIndex) => (
                        <React.Fragment key={optionIndex}>
                          {config.OptionFieldTypes.inputTypes[option.type] ? (
                            <OptionInput
                              deliveryMontoringClass={this.props.deliveryMontoringClass}
                              option={option}
                              inputType={option.inputType}
                              context={this.props.context}
                              config={this.props.config}
                              CONST={this.props.CONST}
                              code={this.props.code}
                              groupIndex={groupIndex}
                              optionParentIndex={optionParentIndex}
                              index={optionIndex}
                              serviceIndex={this.props.serviceIndex}
                              errorId={this.props.code + option.optionCode + 'Opt'}
                            />
                          ) : (
                            ''
                          )}

                          {option.type === config.OptionFieldTypes.SubHeading ? (
                            <OptionSubHeading
                              option={option}
                              code={this.props.code}
                              groupIndex={groupIndex}
                              optionParentIndex={optionParentIndex}
                              index={optionIndex}
                            />
                          ) : (
                            ''
                          )}

                          {option.type === config.OptionFieldTypes.DateTime ? (
                            <OptionDateTime
                              option={option}
                              deliveryMontoringClass={this.props.deliveryMontoringClass}
                              context={this.props.context}
                              routeContext={this.props.routeContext}
                              config={this.props.config}
                              CONST={this.props.CONST}
                              code={this.props.code}
                              groupIndex={groupIndex}
                              optionParentIndex={optionParentIndex}
                              index={optionIndex}
                              serviceIndex={this.props.serviceIndex}
                              errorId={this.props.code + option.optionCode + 'Opt'}
                              loaderRef={this.props.loaderRef}
                            />
                          ) : (
                            ''
                          )}

                          {option.type === config.OptionFieldTypes.Date ? (
                            <OptionDate
                              option={option}
                              deliveryMontoringClass={this.props.deliveryMontoringClass}
                              context={this.props.context}
                              routeContext={this.props.routeContext}
                              config={this.props.config}
                              CONST={this.props.CONST}
                              code={this.props.code}
                              groupIndex={groupIndex}
                              optionParentIndex={optionParentIndex}
                              index={optionIndex}
                              serviceIndex={this.props.serviceIndex}
                              errorId={this.props.code + option.optionCode + 'Opt'}
                              loaderRef={this.props.loaderRef}
                            />
                          ) : (
                            ''
                          )}

                          {option.type === config.OptionFieldTypes.Telephone ? (
                            <OptionPhoneNumber
                              option={option}
                              context={this.props.context}
                              config={this.props.config}
                              CONST={this.props.CONST}
                              code={this.props.code}
                              groupIndex={groupIndex}
                              optionParentIndex={optionParentIndex}
                              index={optionIndex}
                              serviceIndex={this.props.serviceIndex}
                              errorId={this.props.code + option.optionCode + 'Opt'}
                            />
                          ) : (
                            ''
                          )}

                          {option.type === config.OptionFieldTypes.TickBox ? (
                            <OptionCheckbox
                              option={option}
                              context={this.props.context}
                              config={this.props.config}
                              CONST={this.props.CONST}
                              code={this.props.code}
                              groupIndex={groupIndex}
                              optionParentIndex={optionParentIndex}
                              index={optionIndex}
                              serviceIndex={this.props.serviceIndex}
                              errorId={this.props.code + option.optionCode + 'Opt'}
                            />
                          ) : (
                            ''
                          )}

                          {option.type === config.OptionFieldTypes.Select ? (
                            <OptionSelect
                              option={option}
                              context={this.props.context}
                              config={this.props.config}
                              CONST={this.props.CONST}
                              code={this.props.code}
                              groupIndex={groupIndex}
                              optionParentIndex={optionParentIndex}
                              index={optionIndex}
                              serviceIndex={this.props.serviceIndex}
                              errorId={this.props.code + option.optionCode + 'Opt'}
                              afterUpdate={(params, ctx) => this.afterUpdate(params, ctx)}
                              disableonBlur={this.checkDisableOnBlur(this.props.code)}
                            />
                          ) : (
                            ''
                          )}

                          {option.type === config.OptionFieldTypes.Currency ? (
                            //   when there are more values to currency => select box
                            option.values ? (
                              <OptionSelect
                                option={option}
                                context={this.props.context}
                                config={this.props.config}
                                CONST={this.props.CONST}
                                code={this.props.code}
                                groupIndex={groupIndex}
                                optionParentIndex={optionParentIndex}
                                index={optionIndex}
                                serviceIndex={this.props.serviceIndex}
                                errorId={this.props.code + option.optionCode + 'Opt'}
                                afterUpdate={(params, ctx) => this.afterUpdate(params, ctx)}
                                disableonBlur={this.checkDisableOnBlur(this.props.code)}
                              />
                            ) : (
                              //   show currency in input only otherwise
                              <OptionInput
                                deliveryMontoringClass={this.props.deliveryMontoringClass}
                                option={option}
                                inputType={option.inputType}
                                context={this.props.context}
                                config={this.props.config}
                                CONST={this.props.CONST}
                                code={this.props.code}
                                groupIndex={groupIndex}
                                optionParentIndex={optionParentIndex}
                                index={optionIndex}
                                serviceIndex={this.props.serviceIndex}
                                errorId={this.props.code + option.optionCode + 'Opt'}
                              />
                            )
                          ) : (
                            ''
                          )}
                          {option.type === config.OptionFieldTypes.LtcDate ? (
                            <OptionLTCDate
                              option={option}
                              context={this.props.context}
                              config={this.props.config}
                              CONST={this.props.CONST}
                              code={this.props.code}
                              groupIndex={groupIndex}
                              optionParentIndex={optionParentIndex}
                              index={optionIndex}
                              serviceIndex={this.props.serviceIndex}
                              errorId={this.props.code + option.optionCode + 'Opt'}
                            />
                          ) : (
                            ''
                          )}
                          <ConditionalOptionGroupError
                            context={this.props.context}
                            config={this.props.config}
                            CONST={this.props.CONST}
                            option={option}
                            errors={this.props.errors}
                            code={this.props.code}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                  ))}

                  {group.maxRepeat && group.maxRepeat > 1 && (
                    <button
                      className={`base-button ${group.maxRepeat >= group.options.length ? '' : 'disabled'}`}
                      onClick={() =>
                        group.maxRepeat >= group.options.length
                          ? this.addNewOptionsSchemaRow(groupIndex, group.maxRepeat)
                          : null
                      }
                      id={`addNewOptionsSchemaRow${groupIndex}`}
                    >
                      <span>{t(`general|Add New Row`)}</span>
                    </button>
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
        </Translation>
      </>
    );
  }
}

import { config } from '../data-config';
import { DOMESTIC_SHIPMENT_RANGE, EXPORT_SHIPMENT_TYPE, SENDER_PAYER } from '../../globals/constants';

export const state = {
  templateData: [],

  //  Template search
  searchTerm: { value: '', error: false, isRequired: false },
  remoteSearchTerm: '',
  pagination: {
    page: 1,
    total: 0,
    pageSize: config.paginationInitialNumberOfItems,
    lastPage: 0,
  },
  loader: false,
  templateList: [],
  templateListMaster: [],
  manualSearch: false,

  //actions
  deleteList: [],

  //user data
  accounts: [],
  userData: {},
  originalAccounts: [],

  // loaders
  loaders: {
    products: { value: false },
    additionalServices: { value: false },
    shipmentDetails: { value: false },
  },

  /*Template*/

  //Template Name
  templateName: { value: '', error: false, isRequired: false },

  //Import Export Section
  accountNumber: { value: '', error: false, isRequired: false, display: true, isSelect: true },
  receiverNumber: { value: '', error: false, isSelect: false },
  thirdPartyNumber: { value: '', error: false },
  customs: false,
  countryBasedCustoms: false,
  shipmentRange: { value: DOMESTIC_SHIPMENT_RANGE },
  shipmentTypesImpOrExp: { value: EXPORT_SHIPMENT_TYPE },
  prevShipmentTypesImpOrExp: { value: EXPORT_SHIPMENT_TYPE },
  shipmentPayer: { value: SENDER_PAYER },

  //Route
  pickupFromDifferentAddress: { value: false },
  deliverToDifferentAddress: { value: false },
  pickupAddressResidential: { value: false },
  differentPickupAddressResidential: { value: false },
  deliveryAddressResidential: { value: false },
  differentDeliveryAddressResidential: { value: false },

  pickupId: '',
  pickupSender: { value: '', error: false, isRequired: false },
  pickupEmail: { value: '', error: false, isRequired: false },
  pickupEmailConfirmation: { value: false, error: false },
  // default value is handled in Template.js componentDidMount
  pickupCountry: { value: null, error: false, comparisonError: false, isRequired: false },
  pickupVatNumber: { value: '', error: false },
  pickupName: { value: '', error: false, isRequired: false },
  pickupStreet: { value: '', error: false, isRequired: false },
  pickupPostalCode: { value: '', error: false, isRequired: false },
  pickupCity: { value: '', error: false, isRequired: false },
  pickupPhoneNumbers: [{ code: '', number: '', error: '', isRequired: false }],
  pickupSenderReference: { value: '', error: false },

  differentPickup: '',
  differentPickupSender: { value: '', error: false, isRequired: false },
  differentPickupEmail: { value: '', error: false, isRequired: false },
  differentPickupEmailConfirmation: { value: false, error: false },
  // default value is handled in Template.js componentDidMount
  differentPickupCountry: { value: null, error: false, comparisonError: false, isRequired: false },
  differentPickupVatNumber: { value: '', error: false },
  differentPickupName: { value: '', error: false, isRequired: false },
  differentPickupStreet: { value: '', error: false, isRequired: false },
  differentPickupPostalCode: { value: '', error: false, isRequired: false },
  differentPickupCity: { value: '', error: false, isRequired: false },
  differentPickupPhoneNumbers: [{ code: '', number: '', error: '', isRequired: false }],

  deliveryId: '',
  deliveryReceiver: { value: '', error: false, isRequired: false },
  deliveryEmail: { value: '', error: false, isRequired: false },
  deliveryEmailConfirmation: { value: false, error: false },
  // default value is handled in Template.js componentDidMount
  deliveryCountry: { value: null, error: false, comparisonError: false, isRequired: false },
  deliveryVatNumber: { value: '', error: false },
  deliveryName: { value: '', error: false, isRequired: false },
  deliveryStreet: { value: '', error: false, isRequired: false },
  deliveryPostalCode: { value: '', error: false, isRequired: false },
  deliveryCity: { value: '', error: false, isRequired: false },
  deliveryPhoneNumbers: [{ code: '', number: '', error: '', isRequired: false }],
  deliveryReceiverReference: { value: '', error: false },

  differentDeliveryId: '',
  differentDeliveryReceiver: { value: '', error: false, isRequired: false },
  differentDeliveryEmail: { value: '', error: false, isRequired: false },
  differentDeliveryEmailConfirmation: { value: false, error: false },
  // default value is handled in Template.js componentDidMount
  differentDeliveryCountry: { value: null, error: false, comparisonError: false, isRequired: false },
  differentDeliveryVatNumber: { value: '', error: false },
  differentDeliveryName: { value: '', error: false, isRequired: false },
  differentDeliveryStreet: { value: '', error: false, isRequired: false },
  differentDeliveryPostalCode: { value: '', error: false, isRequired: false },
  differentDeliveryCity: { value: '', error: false, isRequired: false },
  differentDeliveryPhoneNumbers: [{ code: '', number: '', error: '', isRequired: false }],

  termsOfDelivery: { value: '', error: false, isRequired: false, possibleValues: ['val1', 'val2'] },
  languageCode: config.locale.defaultLanguage,
  productsCatalog: [],
  products: [], // fcp data api call data
  internationalShippingCountries: [],

  // Address book
  addToAddressBookSender: { value: false, error: false },
  addToAddressBookReceiver: { value: false, error: false },
  updateInAddressBookSender: { value: false, error: false },
  updateInAddressBookReceiver: { value: false, error: false },

  senderPickedFromAddressBook: false,
  receiverPickedFromAddressBook: false,
  senderAddressId: { value: '', error: false },
  receiverAddressId: { value: '', error: false },
  showCompanySearch: false,
  addressPickupSearch: { value: '', error: false },

  // Delivery Options
  product: { value: '', error: false },
  additionalServices: [],
  additionalServicesError: false,
  additionalServicesAPIError: false,
  geoLocationError: false,

  // Shipment details
  payer: { value: SENDER_PAYER, error: false },
  shipmentRowsNumberMax: 0,
  shipmentDetailsRows: [],
  numberOfPallets: { value: 0, error: false },

  totals: {
    shipmentDetailsRows: {
      quantity: { value: 1 },
      volume: { value: 0 },
      weight: { value: 0 },
      loadingMeter: { value: '' },
      chargeableWeight: { value: null, error: false },
    },
  },

  //Schedule Pickup
  pickupOption: { value: '', error: false, isRequired: false },
  deliveryInstructions: { value: '', error: false, isRequired: false },
  pickupInstructions: { value: '', error: false, isRequired: false },

  //transactionId
  transactionId: '',
  apiError: false,

  //postal code validation
  postalCodeState: {
    apiError: false,
    submitError: false,
    sourcePostalCodeError: false,
    destinationPostalCodeError: false,
    sourceCityError: false,
    destinationCityError: false,
  },
};

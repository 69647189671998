import { globalConfig, GLOBAL_CONST } from 'globals/data-config-global';
import {
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  SENDER_PAYER,
  SERVICE_POINT_PRODUCT_CODE,
  THIRD_PARTY_PAYER,
} from '../globals/constants';

const componentConfig = {
  PayerCodeBasedAdditionalServicesFiltering: ['3', '4'],
  homeDeliveryMapping: {
    401: {
      code: '401',
      secondaryCode: '501',
      deliveryType: 'Delivery',
      name: 'dhlHomeDelivery',
    },
    402: {
      code: '402',
      secondaryCode: '502',
      deliveryType: 'Return',
      name: 'dhlHomeDeliveryReturn',
    },
  },
  OptionFieldTypes: {
    inputTypes: {
      Number: 'Number',
      Decimal: 'Decimal',
      NumericText: 'NumericText',
      Alphanumerical: 'Alphanumerical',
      Email: 'Email',
      Currency: 'Currency',
    },
    Select: 'Select',
    LtcDate: 'LTCdate',
    collectionAtTerminal: 'collectionAtTerminal',
    Telephone: 'Telephone',
    SubHeading: 'SubHeading',
    ListCOT: 'ListCOT',
    DateTime: 'DateTime',
    TickBox: 'TickBox',
    Hidden: 'Hidden',
  },
  templateType: 3,
  maxSearchTermDigits: 35,
  homeDeliveryProductCodes: ['401', '402'],
  pickupOptions: [
    { value: 'schedulePickup', label: 'Schedule Pickup' },
    { value: 'dropOffPackage', label: 'Drop off a package at a DHL location' },
    { value: 'useAlreadyScheduled', label: 'Use an already scheduled pickup at my location' },
  ],
  minLengthToPerformCompanySearch: 3,
  maxAllowedShipmentDetailsRows: 40,
  paginationItemsPerRow: [10, 25, 50, 100],
  shipmentRanges: [INTERNATIONAL_SHIPMENT_RANGE, DOMESTIC_SHIPMENT_RANGE],
  shipmentTypesImpOrExp: [IMPORT_SHIPMENT_TYPE, EXPORT_SHIPMENT_TYPE],
  timeRelatedVasesWithNoOptionSchema: ['timeDefiniteLoading', 'timeDefiniteUnloading'],

  shipmentTypesFields: {
    'full pallet': {
      shipmentType: { value: 'full pallet', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '120', error: false, disabled: true, required: true, hidden: false },
      width: { value: '80', error: false, disabled: true, required: true, hidden: false },
      height: { value: '', error: false, required: true, hidden: false },
      weight: { value: '', error: false, required: true },
      volume: { value: '', error: false, disabled: true, hidden: true },
      loadingMeter: { value: '', error: false, hidden: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'half pallet': {
      shipmentType: { value: 'half pallet', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '60', error: false, disabled: true, required: true, hidden: false },
      width: { value: '80', error: false, disabled: true, required: true, hidden: false },
      height: { value: '', error: false, required: true, hidden: false },
      weight: { value: '', error: false, required: true },
      volume: { value: '', error: false, hidden: true },
      loadingMeter: {
        value: '',
        error: false,
        hidden: false,
      },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    parcel: {
      shipmentType: { value: 'parcel', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false, hidden: false },
      width: { value: '', error: false, hidden: false },
      height: { value: '', error: false, hidden: false },
      volume: { value: '', error: false },

      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false, hidden: true },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: true },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    pallet: {
      shipmentType: { value: 'pallet', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    unspecified: {
      shipmentType: { value: 'unspecified', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    tanktainer: {
      shipmentType: { value: 'tanktainer', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'ro/bobbin': {
      shipmentType: { value: 'ro/bobbin', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    bundle: {
      shipmentType: { value: 'bundle', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'bag/sack': {
      shipmentType: { value: 'bag/sack', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    bale: {
      shipmentType: { value: 'bale', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'box/case': {
      shipmentType: { value: 'box/case', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    can: {
      shipmentType: { value: 'can', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    cage: {
      shipmentType: { value: 'cage', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    crate: {
      shipmentType: { value: 'crate', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'carton/package': {
      shipmentType: { value: 'carton/package', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    dolly: {
      shipmentType: { value: 'dolly', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'drum/barrel': {
      shipmentType: { value: 'drum/barrel', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    ibc: {
      shipmentType: { value: 'ibc', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    octabin: {
      shipmentType: { value: 'octabin', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'pallet gl': {
      shipmentType: { value: 'pallet gl', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'pallet - double extra large': {
      shipmentType: { value: 'pallet - double extra large', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'euro-pallet': {
      shipmentType: { value: 'euro-pallet', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '120', error: false, disabled: false, required: true, hidden: false },
      width: { value: '80', error: false, disabled: false, required: true, hidden: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'half pallet gl': {
      shipmentType: { value: 'half pallet gl', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '60', error: false, disabled: false, required: true, hidden: false },
      width: { value: '80', error: false, disabled: false, required: true, hidden: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'pallet - large': {
      shipmentType: { value: 'pallet - large', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'pallet - oversized': {
      shipmentType: { value: 'pallet - oversized', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'pallet - extra large': {
      shipmentType: { value: 'pallet - extra large', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'pallet euro size': {
      shipmentType: { value: 'pallet euro size', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'skeleton box': {
      shipmentType: { value: 'skeleton box', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'garment roller': {
      shipmentType: { value: 'garment roller', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },

      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
  },

  productRules: [
    {
      value: false,
      allowed: false,
      code: '212',
      description: 'DHL Parti Tooltip',
      packageTypes: [
        { code: 'PK', name: 'Package' },
        { code: 'PL', name: 'Pallet' },
        { code: 'CLL', name: 'Unspecified Colli' },
      ],
      domestic: true,
      international: false,
    },
    {
      value: false,
      allowed: false,
      code: '210',
      name: 'DHL Pall',
      description: 'DHL Pall Tooltip',
      packageTypes: [
        { code: '701', name: 'DHL Helpall' },
        { code: '702', name: 'DHL Halvpall' },
      ],
      domestic: true,
      international: false,
    },
    {
      value: false,
      allowed: false,
      code: '211',
      description: 'DHL Stycke Tooltip',
      packageTypes: [
        { code: 'PK', name: 'Package' },
        { code: 'PL', name: 'Pallet' },
        { code: 'CLL', name: 'Unspecified Colli' },
      ],
      domestic: true,
      international: false,
    },
    {
      value: false,
      allowed: false,
      code: '102',
      description: 'DHL Paket Tooltip',
      packageTypes: [{ code: 'PK', name: 'Package' }],
      domestic: true,
      international: false,
    },
    {
      value: false,
      allowed: false,
      code: '112',
      description: 'DHL Parcel Connect Plus Tooltip',
      packageTypes: [{ code: 'PK', name: 'Package' }],
      domestic: false,
      international: true,
    },
    {
      value: false,
      allowed: false,
      code: SERVICE_POINT_PRODUCT_CODE,
      description: 'DHL Service Point Tooltip',
      packageTypes: [{ code: 'PK', name: 'Package' }],
      domestic: true,
      international: true,
    },
    {
      value: false,
      allowed: false,
      code: '104',
      description: 'DHL Service Point Retur Tooltip',
      packageTypes: [
        { code: 'CLL', name: 'Unspecified Colli' },
        { code: 'PK', name: 'Package' },
      ],
      domestic: true,
      international: true,
    },
    {
      value: false,
      allowed: false,
      code: '109',
      description: 'DHL Parcel Connect Tooltip',
      packageTypes: [{ code: 'PK', name: 'Package' }],
      domestic: false,
      international: true,
    },
    {
      value: false,
      allowed: false,
      code: '107',
      description: 'DHL Parcel Return Connect Tooltip',
      packageTypes: [
        { code: 'CLL', name: 'Unspecified Colli' },
        { code: 'PK', name: 'Package' },
      ],
      domestic: false,
      international: true,
    },
    {
      value: false,
      allowed: false,
      code: '401',
      description: 'DHL Home Delivery Tooltip',
      packageTypes: [
        { code: 'PK', name: 'Package' },
        { code: 'PL', name: 'Pallet' },
        { code: 'CLL', name: 'Unspecified Colli' },
      ],
      domestic: true,
      international: false,
    },
    {
      value: false,
      allowed: false,
      code: '402',
      description: 'DHL Home Delivery Return Tooltip',
      packageTypes: [
        { code: 'PK', name: 'Package' },
        { code: 'PL', name: 'Pallet' },
        { code: 'CLL', name: 'Unspecified Colli' },
      ],
      domestic: true,
      international: false,
    },
    {
      value: false,
      allowed: false,
      code: '202',
      description: 'DHL Euroconnect Tooltip',
      packageTypes: [
        { code: 'PK', name: 'Package' },
        { code: 'PL', name: 'Pallet' },
        { code: 'CLL', name: 'Unspecified Colli' },
      ],
      domestic: false,
      international: true,
    },
    {
      value: false,
      allowed: false,
      code: '205',
      description: 'DHL Euroline Tooltip',
      packageTypes: [
        { code: 'PK', name: 'Package' },
        { code: 'PL', name: 'Pallet' },
        { code: 'CLL', name: 'Unspecified Colli' },
      ],
      domestic: false,
      international: true,
    },
    {
      value: false,
      allowed: false,
      code: '232',
      description: 'DHL Euroconnect Plus Tooltip',
      packageTypes: [
        { code: 'PK', name: 'Package' },
        { code: 'PL', name: 'Pallet' },
        { code: 'CLL', name: 'Unspecified Colli' },
      ],
      domestic: false,
      international: true,
    },
    {
      value: false,
      allowed: false,
      code: '233',
      description: 'DHL Eurapid Tooltip',
      packageTypes: [
        { code: 'PK', name: 'Package' },
        { code: 'PL', name: 'Pallet' },
        { code: 'CLL', name: 'Unspecified Colli' },
      ],
      domestic: false,
      international: true,
    },
  ],

  dimensions: {
    min_chargeable_weight_check_product_codes: ['212'],
    insurance_enabled: 'yes',
    min_insurance_limit: 1,
    max_insurance_limit: 999999,
    max_insurance_limit_PE: 100000,
    max_item_weight: 38000,
    min_item_weight: 0.1,
    max_shipment_weight: 38000,
    min_shipment_weight: 0.1,
    max_item_quantity: 999,
    min_item_quantity: 1,
    max_total_quantity: 999,
    min_total_quantity: 1,
    max_item_length: 1360,
    min_item_length: 15,
    max_item_width: 240,
    min_item_width: 11,
    max_item_height: 290,
    min_item_height: 1,
    max_item_volume: 150,
    min_item_volume: 0.001,
    min_item_loading_meter: 0.01,
    max_item_loading_meter: 20.8,
    max_item_height_stack_limit: 120,
    min_total_volume: 0,
    max_total_volume: 150,
    min_total_loading_meter: 0,
    max_total_loading_meter: 20.8,
    min_total_palletPlace: 0,
    max_total_palletPlace: 3,
    min_item_palletPlace: 0,
    max_item_palletPlace: 3,
    non_stackable_height: 125,
    loading_meter_const: 2.4,
    min_item_chargeableWeight: 0,
    max_item_chargeableWeight: 999999,
    min_total_chargeableWeight: 0,
    max_total_chargeableWeight: 999999,
    shipmentMark_length: 17,
    goodsDesc_length: 70,
  },

  payers: [SENDER_PAYER, RECEIVER_PAYER, THIRD_PARTY_PAYER],
  dangerousGoodGroup: [
    {
      ...globalConfig.dangerousGoodGroup[0],
      marinePollutantName: {
        ...globalConfig.dangerousGoodGroup[0].marinePollutantName,
        required: false,
      },
    },
  ],
};

const COMPONENT_CONST = {
  API_DOMAIN: 'portalTemplates',
  PORTAL_TEMPLATES: 'PTM',
  EDIT: 'edit',
  DELETE: 'delete',
  VIEW: 'view',
  CANCEL: 'cancel',
  SAVE: 'save',
  SAVE_NEW: 'saveNew',
  HOURS_PER_MONTH: 720,
  SECONDS_PER_MINUTE: 60,
  SENDER: 'sender',
  RECEIVER: 'receiver',
  DIFFERENT_SENDER: 'differentSender',
  DIFFERENT_RECEIVER: 'differentReceiver',
};

export const config = Object.assign({}, globalConfig, componentConfig);
export const CONST = Object.assign({}, GLOBAL_CONST, COMPONENT_CONST);

import { HUNGARY_CODE, POLAND_CODE } from '../constants';

export const SENT_REF = 'SENT_REF';
export const SENT_CARKEY = 'SENT_CARKEY';
export const EKAER_FREE = 'EKAER_FREE';
export const EKAER_NUMBER = 'EKAER_NUMBER';

export const generateAdditionalInformationList = state => {
  const additionalInformationList = [];
  if (
    state.pickupCountry.value.toLowerCase() === POLAND_CODE ||
    state.deliveryCountry.value.toLowerCase() === POLAND_CODE
  ) {
    additionalInformationList.push({
      code: SENT_REF,
      stringValue: state.sentReference.value,
    });
    additionalInformationList.push({
      code: SENT_CARKEY,
      stringValue: state.sentCarrierKey.value,
    });
  }
  if (
    state.pickupCountry.value.toLowerCase() === HUNGARY_CODE ||
    state.deliveryCountry.value.toLowerCase() === HUNGARY_CODE
  ) {
    if (state.ekaerFree.value !== null) {
      additionalInformationList.push({
        code: EKAER_FREE,
        stringValue: state.ekaerFree.value.toString(),
      });
    }
    if (state.ekaerFree.value === false) {
      additionalInformationList.push({
        code: EKAER_NUMBER,
        stringValue: state.ekaerNumber.value,
      });
    }
  }
  return additionalInformationList;
};

/* Poland*/
export const getSentReference = storedShipment => {
  const sentReferenceObj = storedShipment.savedShipmentAdditionalInformationList.find(i => i.code === SENT_REF);
  return sentReferenceObj !== undefined ? sentReferenceObj.stringValue : '';
};
export const getSentCarrierKey = storedShipment => {
  const sentCarrierKeyObj = storedShipment.savedShipmentAdditionalInformationList.find(i => i.code === SENT_CARKEY);
  return sentCarrierKeyObj !== undefined ? sentCarrierKeyObj.stringValue : '';
};

/*Hungary*/
export const isEkaerFree = storedShipment => {
  const ekaerFreeObj = storedShipment.savedShipmentAdditionalInformationList.find(i => i.code === EKAER_FREE);
  return ekaerFreeObj !== undefined ? ekaerFreeObj.stringValue === 'true' : null;
};
export const getEkaerNumber = storedShipment => {
  const ekaerNumberObj = storedShipment.savedShipmentAdditionalInformationList.find(i => i.code === EKAER_NUMBER);
  return ekaerNumberObj !== undefined ? ekaerNumberObj.stringValue : '';
};

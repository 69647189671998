import { config, CONST } from '../../../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest, getPropperAPIData } from 'globals/utils/requests';
import { getOptionSchema } from '../ServiceDetails';
import { getValidationResults } from '../ServiceDetails';

export const validateGeoLocation = async (state, additionalServices, product, getVas = false) => {
  let url = apiLinks.postGeoLocationValidator;
  const selectedServices = [];
  let resultMap = {};
  const body = {};

  const properState = getPropperAPIData(state);

  if (config.homeDeliveryMapping[product].deliveryType === 'Return') {
    body.cityName = properState.pickupCity ? properState.pickupCity.value : '';
    body.postalCode = properState.pickupPostalCode ? properState.pickupPostalCode.value : '';
  } else {
    body.cityName = properState.deliveryCity ? properState.deliveryCity.value : '';
    body.postalCode = properState.deliveryPostalCode ? properState.deliveryPostalCode.value : '';
  }

  body.deliveryType = config.homeDeliveryMapping[product].deliveryType;

  if (additionalServices && additionalServices.length > 0) {
    additionalServices.forEach(additionalService => {
      if (additionalService.value) selectedServices.push(additionalService.code);
    });
  }

  // getVas is get Home Delivery validator VAS.
  if (getVas) {
    url = apiLinks.postGeoLocation;
    body.AgreementNo = properState.payerAccount;
  } else {
    body.AgreementNo =
      properState.thirdPartyNumber.value || properState.receiverNumber.value || properState.accountNumber.value;

    body.additionalServices = selectedServices;
  }

  await apiRequest(url, 'POST', {
    body: body,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK && !(result.data.status && result.data.status === 'error')) {
        resultMap = result;
        resultMap.error = false;
      } else if (result.status === CONST.BAD_REQUEST_ERROR) {
        resultMap.status = false;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const homeDeliveryValidations = async (product, state) => {
  let secondaryCodeVasObj = {};
  let geoLocationValidation = {};

  const secondaryCode = config.homeDeliveryMapping[product].secondaryCode;

  const vasSpecialCase = getVasForSecondaryCode(product, state.countryBasedCustoms, state.shipmentRange.value);
  const geoLocationMap = validateGeoLocation(state, [], product, true);
  await vasSpecialCase.then(result => (secondaryCodeVasObj = result));
  await geoLocationMap.then(result => (geoLocationValidation = result));
  await getOptionSchema(secondaryCodeVasObj, secondaryCode);

  secondaryCodeVasObj.geoLocationError =
    geoLocationValidation.error || geoLocationValidation?.data?.replyStatus !== 'Ok';

  if (!secondaryCodeVasObj.error && !secondaryCodeVasObj.geoLocationError) {
    secondaryCodeVasObj.geoLocationAdditionalServices = geoLocationValidation.data?.additionalServices;
  }

  return secondaryCodeVasObj;
};

// Home Delivery VAS
const getVasForSecondaryCode = async product => {
  const stateObj = {};
  const productCode = config.homeDeliveryMapping[product].secondaryCode;
  const urlAdditionalServices = apiLinks.getAdditionalServices.replace('{productCode}', productCode);

  await apiRequest(urlAdditionalServices, 'GET')
    .then(async result => {
      if (result.status === CONST.STATUS_OK) {
        result.data.forEach(element => {
          element.value = false;
          element.productCode = productCode;
        });

        stateObj.additionalServices = result.data;

        // Functionality to apply validation results:
        await getValidationResults(JSON.parse(JSON.stringify(stateObj.additionalServices)), productCode).then(
          validationResult => {
            if (validationResult.data) {
              stateObj.additionalServices = validationResult.data;
            } else if (validationResult.error) {
              stateObj.error = true;
            }
          },
        );
      } else {
        stateObj.error = true;
      }
    })
    .catch(() => {
      stateObj.error = true;
    });

  return stateObj;
};

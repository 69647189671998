import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { Checkbox, Error } from 'components';
import PayingAccountNumber from './PayingAccountNumber/PayingAccountNumber';
import { apiRequest } from 'globals/utils/requests';
import { filterArrayOfObjectsByValuesInArray } from 'globals/utils/filtering';
import { afterCheck, beforeCheck } from './ServiceDetails-helpers';
import { setPickupAddressFromCookie } from '../EnterPickupLocation/EnterPickupLocation-helpers';
import './ServiceDetails.css';
import { ChatWithUs } from '../../../components/fcp-components/ChatWithUs';

const ENV_DEV = process.env.NODE_ENV === 'development';

export default class ServiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      apiError: false,
    };
  }

  getInternationalCountries = products => {
    const internationalShippingCountriesMap = {};

    products.forEach(product => {
      if (!product.isDomestic && product.toCountries && config.internationalProducts.indexOf(product.code) !== -1) {
        if (product.toCountries) {
          internationalShippingCountriesMap[product.code] = product.toCountries;
        }
      }
    });
    return internationalShippingCountriesMap;
  };

  componentDidMount = () => {
    const stateObject = {};
    const url = apiLinks.getFCPAPIProducts;
    const params = {
      headers: {
        Accept: 'application/json',
      },
    };

    ENV_DEV && console.message('API Request started ...');
    this.setState({ isLoading: true });

    apiRequest(url, 'GET', params).then(result => {
      if (result.status === CONST.STATUS_OK) {
        let products = [];
        let allowedProducts = [];
        const contextStateObject = {};
        const pickupAddressFromCookie = setPickupAddressFromCookie();

        if (pickupAddressFromCookie) {
          Object.assign(contextStateObject, pickupAddressFromCookie);
        }

        if (config.allowedProducts && result.data) {
          allowedProducts = config.allowedProducts.map(prod => {
            this.props.context.state[prod.mapName].allowed = true;
            return prod.code;
          });
          products = filterArrayOfObjectsByValuesInArray(result.data, allowedProducts);
        }

        if (result.data) {
          result.data.forEach(res => {
            if (res.code === '9999') {
              config.maxAllowedShipmentDetailsRows = res.shipmentRowsNumberMax;
            }
          });
        }

        contextStateObject.products = products;
        contextStateObject.minMaxDimensions = {};
        contextStateObject.toCountryMap = this.getInternationalCountries(result.data);

        // migrated missing property from config
        contextStateObject.minMaxDimensions.min_chargeable_weight_check_product_codes = ['212'];
        contextStateObject.minMaxDimensions.non_stackable_height = config.dimensions.non_stackable_height;
        contextStateObject.minMaxDimensions.loading_meter_const = config.dimensions.loading_meter_const;

        contextStateObject.products.forEach((product, ind) => {
          let mapCode = '';

          mapCode = config.allowedProducts.find(prod => {
            return product.code === prod.code;
          });

          contextStateObject.products[ind].description = this.props.context.state[mapCode.mapName].description;

          if (product.code === '109') {
            contextStateObject.minMaxDimensions.min_item_weight = product.piece.actualWeightMin;
            contextStateObject.minMaxDimensions.min_item_quantity = product.shipment.numberOfPiecesMin;
            contextStateObject.minMaxDimensions.min_total_quantity = product.shipment.numberOfPiecesMin;
            contextStateObject.minMaxDimensions.min_item_length = product.piece.lengthMin;
            contextStateObject.minMaxDimensions.min_item_width = product.piece.widthMin;
            contextStateObject.minMaxDimensions.min_item_height = product.piece.heightMin;
            contextStateObject.minMaxDimensions.min_item_volume = product.piece.volumeMin;
            contextStateObject.minMaxDimensions.min_item_loading_meter = product.shipment.loadingMetreMax;
            contextStateObject.minMaxDimensions.min_total_volume = product.piece.volumeMin;
            contextStateObject.minMaxDimensions.min_total_loading_meter = product.shipment.loadingMetreMax;
            contextStateObject.minMaxDimensions.min_shipment_weight = product.shipment.actualWeightMin;
            contextStateObject.minMaxDimensions.min_total_palletPlace = product.shipment.palletPlaceMin;
            contextStateObject.minMaxDimensions.min_item_palletPlace = product.shipment.palletPlaceMin;
          } else if (product.code === '212') {
            contextStateObject.minMaxDimensions.max_item_weight = product.piece.actualWeightMax;
            contextStateObject.minMaxDimensions.max_item_quantity = product.shipment.numberOfPiecesMax;
            contextStateObject.minMaxDimensions.max_total_quantity = product.shipment.numberOfPiecesMax;
            contextStateObject.minMaxDimensions.max_item_length = product.piece.lengthMax;
            contextStateObject.minMaxDimensions.max_item_width = product.piece.widthMax;
            contextStateObject.minMaxDimensions.max_item_height = product.piece.heightMax;
            contextStateObject.minMaxDimensions.max_item_volume = product.piece.volumeMax;
            contextStateObject.minMaxDimensions.max_item_loading_meter = product.shipment.loadingMetreMax;
            contextStateObject.minMaxDimensions.max_total_volume = product.shipment.volumeMax;
            contextStateObject.minMaxDimensions.max_total_loading_meter = product.shipment.loadingMetreMax;
            contextStateObject.minMaxDimensions.max_shipment_weight = product.shipment.actualWeightMax;
            contextStateObject.minMaxDimensions.max_total_palletPlace = product.shipment.palletPlaceMax;
            contextStateObject.minMaxDimensions.max_item_palletPlace = product.shipment.palletPlaceMax;

            contextStateObject.minMaxDimensions.max_item_chargeableWeight = product.shipment.chargeableWeightMax;
            contextStateObject.minMaxDimensions.max_total_chargeableWeight = product.shipment.chargeableWeightMax;
          }
        });

        this.props.context.updateState(contextStateObject);
        ENV_DEV && console.success('API Request successful ...');
      } else {
        ENV_DEV && console.alert('API Request failed ...');
        stateObject.apiError = true;
      }
      stateObject.isLoading = false;
      this.setState(stateObject);
    });
  };

  render() {
    return (
      <div className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <>
              {this.state.apiError ? (
                <Error
                  name="serviceDetailsApiError"
                  className="text-center"
                  message={
                    t(`general|Booking failed. Try again or call technical support.`) +
                    ' ' +
                    t('general|or') +
                    ' ' +
                    <ChatWithUs />
                  }
                />
              ) : (
                ''
              )}
              <h4 className="margin-bottom-1">{t('general|Service Details')}</h4>
              <div className="frc__service-details-section--wrapper">
                <div>
                  <p>{t('portalPickup|Please select the service(s) used for the shipment(s) in this pickup')}</p>
                  <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                    {this.props.context.state.dhlParti.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|212|name')}
                          name="dhlParti"
                          isRequired={false}
                          checked={this.props.context.state.dhlParti.value}
                          deselect={[
                            'dhlPall',
                            'dhlStycke',
                            'dhlPaket',
                            'dhlParcelConnectPlus',
                            'dhlServicePoint',
                            'dhlServicePointRetur',
                            'dhlParcelConnect',
                            'dhlParcelReturnConnect',
                            'dhlHomeDelivery',
                            'dhlHomeDeliveryReturn',
                            'dhlEuroconnect',
                            'dhlEuroconnectPlus',
                            'dhlEurapid',
                          ]}
                          tooltip={t('products|212|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlPall.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|210|name')}
                          name="dhlPall"
                          isRequired={false}
                          checked={this.props.context.state.dhlPall.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|210|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlStycke.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|211|name')}
                          name="dhlStycke"
                          isRequired={false}
                          checked={this.props.context.state.dhlStycke.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|211|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlPaket.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|102|name')}
                          name="dhlPaket"
                          isRequired={false}
                          checked={this.props.context.state.dhlPaket.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|102|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlParcelConnectPlus.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|112|name')}
                          name="dhlParcelConnectPlus"
                          isRequired={false}
                          checked={this.props.context.state.dhlParcelConnectPlus.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|112|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t, false)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlServicePoint.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|103|name')}
                          name="dhlServicePoint"
                          isRequired={false}
                          checked={this.props.context.state.dhlServicePoint.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|103|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlParcelConnect.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|109|name')}
                          name="dhlParcelConnect"
                          isRequired={false}
                          checked={this.props.context.state.dhlParcelConnect.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|109|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t, false)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlServicePointRetur.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|104|name')}
                          name="dhlServicePointRetur"
                          isRequired={false}
                          checked={this.props.context.state.dhlServicePointRetur.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|104|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlParcelReturnConnect.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|107|name')}
                          name="dhlParcelReturnConnect"
                          isRequired={false}
                          checked={this.props.context.state.dhlParcelReturnConnect.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|107|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlHomeDelivery.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|401|name')}
                          name="dhlHomeDelivery"
                          isRequired={false}
                          checked={this.props.context.state.dhlHomeDelivery.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|401|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlHomeDeliveryReturn.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|402|name')}
                          name="dhlHomeDeliveryReturn"
                          isRequired={false}
                          checked={this.props.context.state.dhlHomeDeliveryReturn.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|402|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlEuroconnect.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|202|name')}
                          name="dhlEuroconnect"
                          isRequired={false}
                          checked={this.props.context.state.dhlEuroconnect.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|202|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlEuroconnectPlus.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|232|name')}
                          name="dhlEuroconnectPlus"
                          isRequired={false}
                          checked={this.props.context.state.dhlEuroconnectPlus.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|232|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.props.context.state.dhlEurapid.allowed ? (
                      <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s l-grid--w-30pc-m l-grid--w-50pc-m-s">
                        <Checkbox
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          label={t('products|233|name')}
                          name="dhlEurapid"
                          isRequired={false}
                          checked={this.props.context.state.dhlEurapid.value}
                          disabled={this.props.context.state.dhlParti.value}
                          tooltip={t('products|233|tooltip')}
                          checkGroupIndex={1}
                          beforeUpdate={beforeCheck}
                          afterUpdate={(context, params) => afterCheck(context, params, t)}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  {this.props.context.state.productSelectionError ? (
                    <Error
                      name="serviceDetailsproductSelectionError"
                      className="margin-bottom-2"
                      message={t(`general|At least one product should be selected`)}
                    />
                  ) : (
                    ''
                  )}

                  <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                    <PayingAccountNumber context={this.props.context} />
                  </div>
                </div>
                <div
                  className={
                    'frc__generic--loader-overlay' +
                    (this.state.isLoading || this.props.context.state.loaders.products.value ? ' is-loading ' : '')
                  }
                />
              </div>
            </>
          )}
        </Translation>
      </div>
    );
  }
}

import { config } from '../data-config';

export const state = {
  //  shipment search
  searchTerm: { value: '', error: false, isRequired: true },
  remoteSearchTerm: '',
  pagination: {
    page: 1,
    total: 5, //0
    pageSize: config.paginationInitialNumberOfItems,
    lastPage: 1, //0
  },

  loader: false,
  savedShipments: [],
  savedShipmentsMaster: [],
  manualSearch: false,

  // shipment transation
  trackingNumber: { value: '', error: false },
  viewAllShipments: false,

  //Filter Shipments
  filterShipmentFromDate: { value: '', error: false },
  filterShipmentToDate: { value: new Date().setHours(23, 59, 59, 999), error: false },
  filterByUserTMSAccount: [],
  filterByShipmentStatus: config.shipmentStatus,
  filterByShipmentDates: { value: '', error: false },

  //transactionId
  transactionId: '',
  apiError: false,

  template0Id: '',
};

// import React, { useContext } from "react";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../data-config';
import SelectService from './SelectService/SelectService';
import SelectServiceCarousel from './SelectServiceCarousel/SelectServiceCarousel';
import Warning from '../Warning/Warning';

import './SelectServices.css';

export default function SelectServices(props) {
  const { t } = useTranslation();
  const totalLength = props.context.state.matchedProducts.length;
  const selectServices = [];
  let startIndex = 0;
  let endIndex = 0;
  let slideIndexLocal = 1;
  const allowedServicesPerPage = config.maxServicesPerPage;
  const selectProductError = props.context.state.selectProductError;

  const selectService = (product, ind) => {
    return <SelectService context={props.context} product={product} key={ind} index={slideIndexLocal} />;
  };

  for (let i = 0; i <= totalLength; ) {
    let newArray = props.context.state.matchedProducts;

    endIndex = i + allowedServicesPerPage;

    // Select previously selected product if available
    if (props?.context?.state?.selectedProduct && Object.keys(props.context.state.selectedProduct).length > 0) {
      newArray = newArray.map(prod =>
        prod.code === props.context.state.selectedProduct.code ? { ...prod, value: true } : { ...prod, value: false },
      );
      // props.context.updateState({matchedProducts: newArray});
    }

    newArray = newArray.slice(startIndex, endIndex).map((product, ind) => {
      return selectService(product, ind);
    });

    slideIndexLocal += 1;
    startIndex = i + allowedServicesPerPage;
    i += allowedServicesPerPage;
    selectServices.push(<>{newArray}</>);
  }

  return (
    <div className="margin-bottom-0 frc__push-bottom">
      <h4 className="margin-bottom-1 frc__red-lined-title">{t('general|Select a Service')}</h4>

      {props.context.state.preSelectedTemplateNotAvailable && (
        <Warning service={props.context.state.preSelectedTemplateNotAvailable} />
      )}

      <div className="c-producttable c-swe-freightcalc--producttable">
        <div className="c-swe-freightcalc--products-overlay" />
        <div
          id="fcp-product-container"
          className="c-producttablecontainer component-wide l-grid--w-100pc-s l-grid--center-m fade-in visible"
        >
          <div className="c-productcontainer js-productcontainer">
            <div className="c-productcontainer--inner">
              {selectServices.map((product, ind) => (
                <React.Fragment key={ind}> {product} </React.Fragment>
              ))}
            </div>
            {totalLength > allowedServicesPerPage ? (
              <SelectServiceCarousel slideLength={Math.ceil(totalLength / allowedServicesPerPage)} />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {selectProductError ? (
        <p className="l-grid--center-m has-error-message">{t('general|errors|Please Select one product')}</p>
      ) : (
        ''
      )}
    </div>
  );
}

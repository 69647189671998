import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { cutTextAndShowError, cutDecimalAndShowError } from 'globals/context/context-helpers';
import { config } from '../../../../data-config';
import { Input } from 'components';
import { Error } from 'components';
import { getLengthForVAS, getRegEx } from './AdditionalService-helpers';
import { checkEmptyOrMinMaxOrLength } from 'globals/utils/fieldValidations';
import { handleExcludingConditionalGroup } from './AdditionalService-helpers';
export default class OptionInput extends Component {
  update = (params, context, blur = false) => {
    let stateObject = {};
    let additionalService = {};
    let error = checkEmptyOrMinMaxOrLength(params);
    // Text cut
    if (params.cutTextLimit && !isNaN(params.cutTextLimit) && params.cutTextLimit > 0) {
      params = cutTextAndShowError(params);
    }
    error = params.error === true ? true : error;

    if (params.decimalCutoff && !isNaN(params.decimalCutoff) && params.decimalCutoff > 0) {
      params = cutDecimalAndShowError(params, blur);
    }
    error = params.error === true ? true : error;

    stateObject = context.state.additionalServices;
    additionalService = JSON.parse(JSON.stringify(stateObject[this.props.serviceIndex]));

    const additionalServiceParentOption =
      additionalService.groups[this.props.groupIndex].options[this.props.optionParentIndex];

    const additionalServiceOption = additionalServiceParentOption[this.props.index];
    additionalServiceOption.value = params.value;
    additionalServiceOption.error = error;

    if (!additionalServiceOption.errorMessage) {
      additionalServiceOption.errorMessage = this.props.code + additionalServiceOption.optionCode + 'Error';
    }

    // Conditional group 2 handling
    if (additionalServiceOption.conditionalGroupType === 2) {
      const disable = additionalServiceOption.value.length > 0;
      handleExcludingConditionalGroup(
        additionalService.groups[this.props.groupIndex].options[this.props.optionParentIndex],
        this.props.index,
        disable,
      );
    }

    stateObject[this.props.serviceIndex] = additionalService;
    context.updateState({ additionalServices: stateObject });
  };

  getErrorMessage = t => {
    let errorMessage = '';

    if (this.props.option.error) {
      if (this.props.option.type !== config.OptionFieldTypes.inputTypes.Email) {
        errorMessage = t('general|errors|Please fill this field');
        errorMessage += ' ' + t('general|errors|maxLength', { max: this.getLength() });
      } else {
        errorMessage = t('general|errors|E-mail Error', {
          chars: this.getLength(),
        });
      }

      if (this.props.option.min && this.props.option.max && !isNaN(this.props.option.max)) {
        errorMessage +=
          '.  ' +
          t('general|errors|invalidValue', {
            name: t(`additionalServices|${this.props.option.optionCode}`),
            min: this.props.option.min,
            max: this.props.option.max,
          });
      }

      if (this.props.option.type === config.OptionFieldTypes.inputTypes.Decimal) {
        errorMessage +=
          '.  ' +
          t('general|errors|Invalid Decimal', {
            decimal: this.props.option.decimals,
          });
      }

      if (
        this.props.option.minLength &&
        this.props.option.maxLength &&
        this.props.option.minLength === this.props.option.maxLength
      ) {
        errorMessage = t('general|errors|Please fill this field');
        errorMessage += ' ' + t(`general|equalLength`, { length: this.props.option.minLength });
      } else if (
        this.props.option.minLength &&
        this.props.option.maxLength &&
        this.props.option.minLength !== this.props.option.maxLength
      ) {
        errorMessage = t('general|errors|Please fill this field');
        errorMessage +=
          ' ' +
          t(`general|betweenLength`, {
            min: this.props.option.minLength,
            max: this.props.option.maxLength,
          });
      }

      if (this.props.option.optionCode === 'bankGiroNumber' && this.props.option.value) {
        errorMessage = t('general|errors|Invalid Length Error', {
          name: t(`general|bankGiroNumber`),
          max: this.props.option.maxLength,
        });
      }

      if (this.props.option.optionCode === 'plusGiroNumber' && this.props.option.value) {
        errorMessage = t('general|errors|Invalid Length Error', {
          name: t('general|plusGiroNumber'),
          max: this.props.option.maxLength,
        });
      }
    }

    return (
      <Error
        name={this.props.errorId ? this.props.errorId : 'OptionInput'}
        className="frc__input--error"
        message={errorMessage}
      />
    );
  };

  getLength = () => {
    return getLengthForVAS(this.props.option.type, this.props.option.maxLength);
  };
  render() {
    return (
      <>
        <Translation>
          {t => (
            <>
              <div
                className={`l-grid--w-100pc-s frc__as-option--wrapper ${
                  this.props.deliveryMontoringClass ? this.props.deliveryMontoringClass : ''
                }`}
              >
                <Input
                  id={this.props.code + this.props.option.optionCode}
                  regEx={getRegEx(this.props.option.type)}
                  index={this.props.index}
                  min={this.props.option.min}
                  max={this.props.option.max}
                  decimalCutoff={this.props.option.decimals * 1}
                  className="frc__input--wrapper"
                  lengthCheck={[
                    new RegExp(
                      `^.{${this.props.option.minLength ? this.props.option.minLength : 0},${this.getLength()}}$`,
                    ),
                  ]}
                  label={t(`additionalServices|` + this.props.option.optionCode)}
                  name={this.props.option.optionCode}
                  isRequired={this.props.option.mandatory}
                  value={this.props.option.value === null ? '' : this.props.option.value}
                  updateOnParent={(params, context, blur) => this.update(params, context, blur)}
                  cutTextLimit={this.getLength()}
                  disabled={this.props.option.disabled || false}
                  context={this.props.context}
                  config={config}
                  CONST={{
                    API_DOMAIN: 'additionalServices',
                  }}
                />
                {this.props.option.error && this.props.option.errorMessage ? this.getErrorMessage(t) : ''}
              </div>
            </>
          )}
        </Translation>
      </>
    );
  }
}

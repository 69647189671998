import { Error, Input, Select } from 'components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DOMESTIC_SHIPMENT_RANGE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  THIRD_PARTY_PAYER,
} from '../../../../globals/constants';
import {
  getWhoPaysOptions,
  reconcilePayerWithRange,
  getShipmentFlags,
  getAccountInputFlags,
} from '../../../../globals/helpers/shipment';
import { validateGlobalAccount } from '../../../../utils/accounts';
import { config, CONST } from '../../../data-config';
import { readState, setDefaultSenderReceiverAccountNumber, setPayerCodeCheck } from '../EnterPickupLocation-helpers';
import { filterAccountsBasedOnShipmentRange, unselectIfDomesticReceiver } from './ImportExportSection-helpers';
import { ProductRange } from './ProductRange';
import { useDispatch } from 'react-redux';
import { setAccountNumber } from '../../../../store/portalOrderSlice';
import { SelectAccount } from './SelectAccount';
import { Box } from '@mui/material';
import { useGetUser } from '../../../../hooks/api/useGetUser';

export const ImportExportSection = ({ context, resetCityValidationError }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = context;
  const localStorageCountry = localStorage.getItem('country');
  const whoPaysOptions = getWhoPaysOptions(localStorageCountry, readState(state));
  const { data: userAccountCountryCode } = useGetUser({ select: data => data?.user?.accountCountryCode });
  const isResidentialFrom = state.pickupAddressResidential?.value || state.differentPickupAddressResidential?.value;

  const shouldDisplayReceiver = () => {
    const ctxState = context.state;

    return (
      (ctxState.shipmentPayer.value === RECEIVER_PAYER &&
        ctxState.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE &&
        ctxState.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) ||
      (ctxState.shipmentPayer.value === THIRD_PARTY_PAYER &&
        ctxState.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE &&
        ctxState.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) ||
      (ctxState.shipmentPayer.value === RECEIVER_PAYER && ctxState.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE)
    );
  };

  const afterUpdate = (context, params) => {
    const stateToSet = {};
    setPayerCodeCheck(context, params, resetCityValidationError);

    stateToSet.accounts = filterAccountsBasedOnShipmentRange(
      context.state.originalAccounts,
      context.state.shipmentRange.value,
    );

    setDefaultSenderReceiverAccountNumber(context, stateToSet.accounts);

    context.updateState(stateToSet);
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    const accountNumber = state?.receiverNumber?.isSelect ? state?.receiverNumber?.value : state?.accountNumber?.value;
    dispatch(setAccountNumber(accountNumber || undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.receiverNumber?.value, state?.accountNumber?.value]);

  useEffect(() => {
    if (isResidentialFrom && state.shipmentPayer.value === THIRD_PARTY_PAYER && !state.accountNumber.isSelect) {
      context.updateState({
        accountNumber: {
          ...state.accountNumber,
          isSelect: true,
        },
      });
    }
  }, [isResidentialFrom, state.shipmentPayer.value, context, state.accountNumber]);

  return (
    <div className=" frc__generic--field-wrapper ">
      <h4 className="margin-bottom-0 frc__red-lined-title">{t('general|Route Type & Payment')}</h4>
      <div className="l-grid l-grid--between-s margin-bottom-2">
        <div className="l-grid--w-100pc-s l-grid--w-48pc-m">
          <Select
            context={context}
            config={config}
            CONST={{ API_DOMAIN: 'general' }}
            selectClass="l-grid--w-100pc-s"
            label={t('general|labels|inputs|Is this a domestic or international shipment?')}
            name="shipmentRange"
            isRequired={true}
            values={config.shipmentRanges}
            value={state.shipmentRange.value}
            afterUpdate={afterUpdate}
            disableonBlur
          />

          {state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE && (
            <Select
              context={context}
              config={config}
              CONST={{ API_DOMAIN: 'general' }}
              selectClass="l-grid--w-100pc-s"
              label={t(`general|Are you importing or exporting this shipment?`)}
              name="shipmentTypesImpOrExp"
              isRequired={true}
              values={config.shipmentTypesImpOrExp}
              value={state.shipmentTypesImpOrExp.value}
              beforeUpdate={(context, params) => reconcilePayerWithRange(context, params, localStorageCountry)}
              afterUpdate={(context, params) => setPayerCodeCheck(context, params, resetCityValidationError)}
            />
          )}
        </div>
        <div className="l-grid--w-100pc-s l-grid--w-48pc-m" translate="no">
          <Select
            context={context}
            config={config}
            CONST={{ API_DOMAIN: 'general' }}
            selectClass="l-grid--w-100pc-s"
            label={t('general|Who will pay for this shipment?')}
            name="shipmentPayer"
            isRequired={true}
            values={whoPaysOptions}
            value={state.shipmentPayer.value}
            afterUpdate={(context, params) => {
              setPayerCodeCheck(context, params, resetCityValidationError);
              unselectIfDomesticReceiver(params, context);
            }}
          />
          <div className="l-grid l-grid--between-s">
            {state.accountNumber.display && (
              <div className={'l-grid--w-100pc-s l-grid--w-48pc-m-m'}>
                {state.accountNumber.isSelect ? (
                  <Box pl="4.2px" pr="4.2px" pt="14px">
                    <SelectAccount
                      preventAccountPreSelection={state.receiverNumber.isSelect && state.accountNumber.isSelect}
                      id="accountNumber"
                      contextAdapter={value =>
                        context.updateState({
                          accountNumber: {
                            ...context.state.accountNumber,
                            value,
                          },
                        })
                      }
                      placeholder={t('general|Select Account Number')}
                      label={
                        isResidentialFrom && state.shipmentPayer.value === THIRD_PARTY_PAYER
                          ? t('general|labels|inputs|Receiver Account Number')
                          : t('general|labels|inputs|Sender account number')
                      }
                      value={context.state.accountNumber.value}
                      onChange={accountNumber => {
                        const newState = structuredClone(context.state);
                        const newAccountNumber = {
                          ...context.state.accountNumber,
                          value: accountNumber,
                        };
                        newState.accountNumber = newAccountNumber;
                        context.state = newState;
                        context.updateState({
                          accountNumber: newAccountNumber,
                        });
                        setPayerCodeCheck(context, {}, resetCityValidationError);
                      }}
                      required={state.accountNumber.isRequired || state.accountNumber.disabled}
                      error={state.accountNumber.error && state.accountNumber.isSelect}
                    />
                  </Box>
                ) : (
                  <Input
                    customValidation={({ value }) => validateGlobalAccount({ account: value })}
                    label={t('general|labels|inputs|Sender account number')}
                    name="accountNumber"
                    dataTestId="accountNumberInp"
                    isRequired={state.accountNumber.isRequired}
                    value={state.accountNumber.value}
                    lengthCheck={
                      state.accountNumber.isRequired
                        ? [RegExp(`^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$`)]
                        : [RegExp(`(^$)|(^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$)`)]
                    }
                    cutTextLimit={config.maxAccountNumberLength}
                    context={context}
                    disabled={state.accountNumber.disabled}
                    config={config}
                    CONST={CONST}
                  />
                )}
                {state.accountNumber.error && (
                  <Error
                    name="accountNumber"
                    className="frc__input--error"
                    message={
                      state.accountNumber.isSelect
                        ? t('general|Select Account Number')
                        : t(`general|Enter proper account number`)
                    }
                  />
                )}
              </div>
            )}

            {state.receiverNumber.display && (
              <div className={'l-grid--w-100pc-s l-grid--w-48pc-m-m'}>
                {state.receiverNumber.isSelect ? (
                  <Box pl="4.2px" pr="4.2px" pt="14px">
                    <SelectAccount
                      preventAccountPreSelection={state.receiverNumber.isSelect && state.accountNumber.isSelect}
                      id="receiverNumber"
                      contextAdapter={value =>
                        context.updateState({
                          receiverNumber: {
                            ...context.state.receiverNumber,
                            value,
                          },
                        })
                      }
                      placeholder={t('general|Select Receiver Number')}
                      label={t('general|labels|inputs|Receiver Account Number')}
                      value={context.state.receiverNumber.value}
                      onChange={receiverNumber => {
                        const newState = structuredClone(context.state);
                        const newReceiverNumber = {
                          ...context.state.receiverNumber,
                          value: receiverNumber,
                        };
                        newState.receiverNumber = newReceiverNumber;
                        context.state = newState;
                        context.updateState({
                          receiverNumber: newReceiverNumber,
                        });
                        setPayerCodeCheck(context, {}, resetCityValidationError);
                      }}
                      disabled={state.receiverNumber.disabled}
                      required={state.receiverNumber.isRequired}
                      error={state.receiverNumber.error && state.receiverNumber.isSelect}
                    />
                  </Box>
                ) : (
                  <Input
                    customValidation={({ value }) => validateGlobalAccount({ account: value })}
                    label={t('general|labels|inputs|Receiver Account Number')}
                    name="receiverNumber"
                    dataTestId="receiverNumberInp"
                    isRequired={state.receiverNumber.isRequired}
                    value={state.receiverNumber.value}
                    lengthCheck={
                      state.receiverNumber.isRequired
                        ? [RegExp(`^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$`)]
                        : [RegExp(`(^$)|(^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$)`)]
                    }
                    cutTextLimit={config.maxAccountNumberLength}
                    context={context}
                    config={config}
                    CONST={CONST}
                  />
                )}

                {state.receiverNumber.error && (
                  <Error
                    name="accountNumber"
                    className="frc__input--error"
                    message={
                      state.receiverNumber.isSelect
                        ? t('general|Select Receiver Number')
                        : t(`general|Enter proper account number`)
                    }
                  />
                )}
              </div>
            )}

            {state.thirdPartyNumber.display && (
              <div
                className={
                  shouldDisplayReceiver() || state.shipmentPayer.value === THIRD_PARTY_PAYER
                    ? 'l-grid--w-100pc-s l-grid--w-48pc-m-m'
                    : 'l-grid--w-100pc-s'
                }
              >
                <>
                  {state.thirdPartyNumber.isSelect ? (
                    <Select
                      selectClass="l-grid--w-100pc-s"
                      label={t('general|labels|inputs|Sender Third Party Number')}
                      name="thirdPartyNumber"
                      isRequired={true}
                      disabled={state.accounts.length === 0}
                      values={state.accounts}
                      value={state.thirdPartyNumber.value}
                      context={context}
                      config={config}
                      CONST={CONST}
                      skipTranslation={true}
                      defaultOption={{ name: t('general|Select Account Number'), value: '' }}
                    />
                  ) : (
                    <Input
                      regEx={config.regEx.alphaNumericWithDot}
                      label={t('general|labels|inputs|Third Party Account Number')}
                      name="thirdPartyNumber"
                      dataTestId="thirdPartyNumberInp"
                      isRequired={state.thirdPartyNumber.isRequired}
                      value={state.thirdPartyNumber.value}
                      lengthCheck={
                        state.thirdPartyNumber.isRequired
                          ? [RegExp(`^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$`)]
                          : [RegExp(`(^$)|(^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$)`)]
                      }
                      cutTextLimit={config.maxAccountNumberLength}
                      context={context}
                      config={config}
                      CONST={CONST}
                    />
                  )}

                  {state.thirdPartyNumber.error && (
                    <Error
                      name="accountNumber"
                      className="frc__input--error"
                      message={t(`general|Enter proper account number`)}
                    />
                  )}
                </>
              </div>
            )}
            {userAccountCountryCode ? (
              <ProductRange
                products={state.products}
                initialValue={state?.productRange || state?.templateData?.productID}
                disabled={
                  (state?.accountNumber?.isSelect && !state?.accountNumber?.value) ||
                  (state?.receiverNumber?.isSelect && !state?.receiverNumber?.value)
                }
                contextAdapter={value => context.updateState({ productRange: value })}
                setSelectedProduct={value => context.updateState({ selectedProduct: value })}
                shipmentFlags={getShipmentFlags(state)}
                accountInputFlags={getAccountInputFlags(state)}
                isResidentialTo={
                  state.deliveryAddressResidential?.value || state.differentDeliveryAddressResidential?.value
                }
                isResidentialFrom={
                  state.pickupAddressResidential?.value || state.differentPickupAddressResidential?.value
                }
                userAccountCountryCode={userAccountCountryCode}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

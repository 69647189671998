import React, { Component } from 'react';

import { state } from '../data-state';
import { checkEmptyOrMinMaxOrLength } from 'globals/utils/fieldValidations';

import { CONST } from '../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import { formatUserData } from './context-helpers';
import { cutTextAndShowError } from 'globals/context/context-helpers';

export const MyContext = React.createContext();

export class MyProvider extends Component {
  state = state;

  componentDidMount() {
    // Get the user date from the API and set Context state ... ???

    apiRequest(apiLinks.getBasicUserData, 'GET').then(result => {
      if (result.status === CONST.STATUS_OK) {
        this.setState(formatUserData(result.data));
      } else {
        this.setState({ generalError: true });
      }
    });
  }

  updateField = params => {
    let error = checkEmptyOrMinMaxOrLength(params);
    let stateObject = {};

    // Text cut
    if (params.cutTextLimit && !isNaN(params.cutTextLimit) && params.cutTextLimit > 0) {
      params = cutTextAndShowError(params);
    }
    error = params.error === true ? true : error;

    stateObject = {
      [params.name]: { value: params.value, error: error },
    };

    if (params.override) {
      stateObject[params.override.name] = { value: params.value, error: error };
    }
    this.setState(stateObject);
  };

  updateGroupOfFields = params => {
    let error = checkEmptyOrMinMaxOrLength(params);
    const duplicationError = false;
    const group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];

    // Text cut
    if (params.cutTextLimit && !isNaN(params.cutTextLimit) && params.cutTextLimit > 0) {
      params = cutTextAndShowError(params);
    }
    error = params.error === true ? true : error;

    group[params.index][params.name] = params.value;
    if (!params.skipError) {
      group[params.index].error = error;
      group[params.index].duplicationError = duplicationError;
    }

    this.setState(
      {
        [params.groupName]: group,
        indexForCallingTMSAPI: null,
        hasTMSAccountError: false,
        generalTMSAccountErrorMessage: '',
        indexForCallingPalletAPI: null,
        hasPalletAccountError: false,
        generalPalletAccountErrorMessage: '',
      },
      () => {
        let afterUpdate = {};
        const callbackState = {};

        // Executes additional logic after update the state
        if (params.afterUpdate && typeof params.afterUpdate === 'function') {
          afterUpdate = params.afterUpdate(this, params);
          if (typeof afterUpdate === 'object' && afterUpdate !== null) {
            afterUpdate = JSON.parse(JSON.stringify(afterUpdate));
            Object.assign(callbackState, afterUpdate);
          }
        }

        if (Object.keys(callbackState).length > 0 && callbackState.constructor === Object) {
          this.setState(callbackState);
        }
      },
    );
  };

  addAnotherGroupField = params => {
    const group = [...this.state[params.groupName]];

    group.push(params.item);
    this.setState({
      [params.groupName]: group,
    });
  };

  removeGroupField = params => {
    const group = [...this.state[params.groupName]];

    group.splice(params.index, 1);
    this.setState({
      [params.groupName]: group,
    });
  };

  updateState = (newState, callback) => {
    this.setState(
      {
        ...newState,
      },
      callback,
    );
  };

  render() {
    return (
      <MyContext.Provider
        value={{
          state: this.state,

          updateField: this.updateField,
          updateGroupOfFields: this.updateGroupOfFields,
          addAnotherGroupField: this.addAnotherGroupField,
          removeGroupField: this.removeGroupField,
          updateState: this.updateState,
        }}
      >
        {this.props.children}
      </MyContext.Provider>
    );
  }
}

export const setLoadersState = (state, loaderState) => {
  const stateObject = {};
  stateObject.loaders = JSON.parse(JSON.stringify(state.loaders));
  stateObject.loaders.products.value = loaderState;
  stateObject.loaders.additionalServices.value = loaderState;

  return stateObject;
};

export const getTranslatedVASName = (VAS, languageCode) => {
  VAS.forEach(AD => {
    if (AD.translations) {
      let obj = AD.translations.find(
        eachObj => typeof eachObj.languageCode === 'string' && eachObj.languageCode.toLowerCase() === languageCode,
      );

      if (typeof obj === 'object') {
        AD.translatedName = obj['name'];
        AD.translatedToolTip = obj['information'];
      } else {
        AD.translatedName = AD.name;
        AD.translatedToolTip = AD.information;
      }
    }
  });

  return VAS;
};

export const arrangeVASBasedOnSelectedLanguage = (additionalServices, prop) => {
  const deliveryMonitoringList = [];

  additionalServices.sort(function (a, b) {
    const prop1 = a[prop].toLowerCase();
    const prop2 = b[prop].toLowerCase();

    if (prop1 < prop2) return -1;
    if (prop1 > prop2) return 1;
    return 0;
  });

  const result = additionalServices.filter(AD => {
    if (AD.code.indexOf('deliveryMonitoring') === -1) return true;
    else {
      AD = cutOffDash(AD);
      deliveryMonitoringList.push(AD);
      return false;
    }
  });

  return result.concat(deliveryMonitoringList);
};

export const arrangeVASAlphabetically = (additionalServices, language) => {
  additionalServices.forEach(AD => {
    const lanSpecificObj = AD.translations.find(translationPart => translationPart.languageCode === language);
    AD.translatedName = lanSpecificObj && lanSpecificObj.name ? lanSpecificObj.name : AD.name;
  });

  return additionalServices;
};

export const getArrayIntersectionValues = (array1, array2) =>
  array2.filter(item => {
    return array1.indexOf(item.code) !== -1;
  });

export const cutOffDash = additionalService => {
  additionalService.translations &&
    additionalService.translations.forEach(translation => {
      translation.name = translation.name.replace('-', ':::').split(':::')[1];
    });

  return additionalService;
};

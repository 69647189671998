import { Box } from '@mui/material';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { getTranslationPhrasesByPageCode } from '../../../globals/utils/translationHelpers';
import { GLOBAL_CONST } from '../../../globals/data-config-global';
import { useSearchParams } from 'react-router-dom';
import { useCountryLanguage } from '../../../hooks/useCountryLanguage';

const ENV_DEV = process.env.NODE_ENV === 'development';

export const RegistrationThankYouPage = () => {
  const countryLanguage = useCountryLanguage();
  const [richText, setRichText] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const preview = searchParams.get('preview');
    getTranslationPhrasesByPageCode(
      GLOBAL_CONST.PAGE_CODES.REGISTRATION,
      countryLanguage.language,
      countryLanguage.country,
      preview,
    ).then(cmsData => {
      if (cmsData != null) {
        cmsData.forEach(data => {
          if (data.code === 'registration|thankYouPage') {
            setRichText(data.translationValue);
          } else {
            ENV_DEV && console.error('missing text for the phrase');
          }
        });
      } else {
        ENV_DEV && console.error('failed to fetch CMS data');
      }
    });
    // eslint-disable-next-line
  }, [countryLanguage]);

  return (
    <Box
      sx={{
        textAlign: 'center',
        my: '64px',
      }}
    >
      {richText && parse(richText)}
    </Box>
  );
};

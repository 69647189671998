import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import i18next from 'i18next';

const ENV_DEV = process.env.NODE_ENV === 'development';

export default function CountryLanguageLoader() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    ENV_DEV && console.log('i18n -- redirecting to lang URL', i18next.language);

    const url = location.pathname.split('/');
    url[1] = i18next.language;
    navigate(encodeURI(`${url.join('/')}${location.search ?? ''}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  return null;
}

import { Box, Stack, Checkbox } from '@mui/material';
import { FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Cell from '../../../../components/DataTable/Cell';
import PiecesCustomCell from './PiecesCustomCell/PiecesCustomCell';
import './PiecesDetails.css';
import { TextArea } from '../../../../components';
import {
  DgItemDetailLabel,
  DgItemDetailLabelProps,
} from '../../../../components/fcp-components/dangerous-goods/DgItemDetailLabel';
import {useGetPackageTypesQuery} from "../../../../store/api/fcpShipment";
import {useCountryLanguage} from "../../../../hooks/useCountryLanguage";

const ReadOnlyTextArea = (props: { label: string; value: string; tiny?: boolean }) => {
  const className = props.tiny ? 'frc__textarea--tiny instructions' : 'frc__textarea--small instructions';
  return (
    <TextArea
      label={props.label}
      textAreaClassName={className}
      disabled={true}
      value={props.value}
    />
  );
};

const LabeledCheckbox = (props: {label: string, checked: boolean}) => (
  <FormControlLabel
    control={<Checkbox checked={props.checked} disabled={true}/>}
    label={
      <Typography variant="body2" fontSize="15px">
        {props.label}
      </Typography>
    }
  />
);

export function PiecesDetails(props: any) {
  const { t } = useTranslation();
  const { language: languageCode } = useCountryLanguage();
  const { data: packageTypesResponse } = useGetPackageTypesQuery({
    languageCode: languageCode,
  });


  const renderPiece = (row: any, index: number) => {
    function renderGenericData() {
      const columns = [
        <PiecesCustomCell cellValue={row} type="StatusWithID" />,
        <PiecesCustomCell cellValue={row} type="Dimension" />,
        <PiecesCustomCell cellValue={row} type="Weight" />,
        <PiecesCustomCell cellValue={row} type="Status" />,
      ];

      return columns.map((content, cellIndex) => {
        // @ts-ignore
        return <Cell id={`${index}-${cellIndex}`} key={`${index}-${cellIndex}`} index={index} content={content} />;
      });
    }

    function renderTextAreas() {
      const content = (
        <div className="l-grid l-grid--w-100pc-s">
          <div className="frc__column-wrapper l-grid--w-100pc-s-all l-grid--w-33pc-s-m l-grid--w-33pc-m-m">
            <ReadOnlyTextArea label={t(`general|labels|inputs|Goods Description`)} value={row.goodsDescription} />
          </div>
          <div className="frc__column-wrapper l-grid--w-100pc-s-all l-grid--w-33pc-s-m l-grid--w-33pc-m-m ">
            <ReadOnlyTextArea label={t(`general|Shipping Mark`)} value={row.shipmentMark} tiny={true} />
          </div>
        </div>
      );
      // @ts-ignore
      return <Cell content={content} colSpan={5} />;
    }

    function renderDangerousGoods() {
      const content = !!row.dangerousGoods?.length && (
        <>
          <div>{t('general|Dangerous Goods')}</div>
          <Stack spacing={2}>
            {row.dangerousGoods.map((dg: any) => {
              const dgItemDetail: DgItemDetailLabelProps['dgItemDetail'] = {
                class: dg.adrClass,
                unNumber: dg.unNumber,
                psn: dg.properShippingName,
                packingGroup: dg.packagingGroup,
                tunnelCode: dg.tunnelDescriptionCode,
              };
              const packageType = packageTypesResponse?.packageTypes?.find(p => p.unCode === dg.packageCode);
              const packageDescription = packageType ? `${dg.packageCode} (${packageType.description})` : '';
              return (
                <Stack>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    gap={2}
                    p={1}
                    sx={{ border: '.1rem solid #949494', borderRadius: '.4rem', backgroundColor: 'background.section' }}
                  >
                    <DgItemDetailLabel dgmId={dg.dgmId} dgItemDetail={dgItemDetail} />
                  </Box>
                  <div className="l-grid l-grid--w-100pc-s">
                    <div className="frc__column-wrapper l-grid--w-100pc-s-all l-grid--w-33pc-s-m l-grid--w-33pc-m-m">
                      <ReadOnlyTextArea label={t(`general|Technical Description`)} value={dg.technicalDescription} />
                    </div>
                    <div className="frc__column-wrapper l-grid--w-100pc-s-all l-grid--w-33pc-s-m l-grid--w-33pc-m-m ">
                      <ReadOnlyTextArea label={t(`general|Flame Point`)} value={dg.flamePoint} tiny={true} />
                    </div>
                  </div>
                  <div className="l-grid l-grid--w-100pc-s">
                    <LabeledCheckbox
                      label={t('general|labels|inputs|environmentHazardous')}
                      checked={dg.environmentHazardous}
                    />
                    <LabeledCheckbox label={t('general|labels|inputs|Limited Quantity')} checked={dg.limitedQuantity} />
                    <LabeledCheckbox
                      label={t('general|labels|inputs|exceptedQuantity')}
                      checked={dg.exceptedQuantity}
                    />
                    <LabeledCheckbox label={t('general|labels|inputs|emptyContainer')} checked={dg.emptyContainer} />
                    <LabeledCheckbox label={t('general|labels|inputs|waste')} checked={dg.waste} />
                    <LabeledCheckbox label={t('general|labels|inputs|marinePollutant')} checked={dg.marinePollutant} />
                    {dg.marinePollutant && (
                      <ReadOnlyTextArea
                        label={t('general|labels|inputs|marinePollutantName')}
                        value={dg.marinePollutantName}
                        tiny={true}
                      />
                    )}
                  </div>
                  <div className="l-grid l-grid--w-100pc-s">
                    <ReadOnlyTextArea
                      label={`${t('general|labels|metrics|Gross Weight')} (kg)`}
                      value={dg.grossWeight}
                      tiny={true}
                    />
                    <ReadOnlyTextArea
                      label={`${t('general|labels|metrics|Net Quantity')} (${dg.quantityMeasurementUnitQualifier})`}
                      value={dg.netQuantity}
                      tiny={true}
                    />
                    <ReadOnlyTextArea
                      label={t('general|labels|inputs|Number Of Units')}
                      value={dg.numberOfUnits}
                      tiny={true}
                    />
                    <ReadOnlyTextArea
                      label={t('general|labels|inputs|Package Code')}
                      value={packageDescription}
                      tiny={true}
                    />
                  </div>
                </Stack>
              );
            })}
          </Stack>
        </>
      );
      // @ts-ignore
      return <Cell content={content} colSpan={5} />;
    }

    return (
      <>
        <tr key={`row-${index}-1`}>{renderGenericData()}</tr>
        <tr key={`row-${index}-2`}>{renderTextAreas()}</tr>
        <tr key={`row-${index}-3`}>{renderDangerousGoods()}</tr>
      </>
    );
  };

  function renderPieces() {
    return props.data.map(renderPiece);
  }

  function renderHeader() {
    const columns = [
      t(`general|ID`),
      t(`general|Dimension`),
      t(`general|labels|metrics|Weight`),
    ];
    return columns.map((cell, cellIndex) => {
      // @ts-ignore
      return <Cell content={cell} index={cellIndex} column={cell} header={true} />;
    });
  }

  return (
    <div data-testid="DataTable" className="frc__dataTable_container">
      <div className="frc__scrollable-table">
        <table id="dataGrid" className="frc__table  frc__table-layout">
          <thead>
            <tr className="dataTable-color" key="heading">
              {renderHeader()}
            </tr>
          </thead>
          <tbody>{renderPieces()}</tbody>
        </table>
      </div>
    </div>
  );
}

import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { getDisplayedPrice } from 'globals/utils/formatting';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { CONST } from '../../../dashboard/data-config';
import { useGetCountryConfig } from '../../../hooks/api/useGetCountryConfig';
import { TextWithBoldPart } from '../TextWithBoldPart';
import { ROUTES, useRouting } from "../../../router";

export const AdressCell = ({ companyName, street, postalCode, city, countryCode, reference }) => {
  const { t } = useTranslation();

  return (
    <Stack sx={{ wordBreak: 'break-word' }}>
      <Typography variant="body2">{companyName ?? ''}</Typography>
      <Typography variant="body2">{street ?? ''}</Typography>
      <Typography variant="body2">{`${postalCode ?? ''} ${city ?? ''}`}</Typography>
      <Typography variant="body2">{t(`countries|${countryCode}`)}</Typography>
      <Typography variant="body2">{reference ?? ''}</Typography>
    </Stack>
  );
};

export const SummaryCell = ({
  productId,
  price,
  currency,
  quantity,
  weight,
  volume,
  loadingMeter,
  sentReference,
  sentCarrierKey,
  ekaerFree,
  ekaerNumber,
}) => {
  const { t } = useTranslation();
  const { data: defaultCurrency } = useGetCountryConfig({ select: data => data.defaultCurrency });

  return (
    <Stack>
      <TextWithBoldPart
        normalText={`${t('products|common|productLabel')}: `}
        boldText={productId ? t(`products|${productId}|name`) : '-'}
      />
      <TextWithBoldPart
        normalText={`${t('general|Estimated Price')}: `}
        boldText={getDisplayedPrice(t, price, {
          CURRENCY_CODE: currency || defaultCurrency,
        })}
      />
      <TextWithBoldPart
        normalText={`${t('general|labels|metrics|quantity')}: `}
        boldText={`${quantity ?? 0} ${t('general|pcs')}`}
      />
      <TextWithBoldPart
        normalText={`${t('general|labels|metrics|Weight')}: `}
        boldText={`${weight ?? 0} ${t('general|kg')}`}
      />
      <Typography variant="body2">
        {`${t('general|labels|metrics|Volume')}: `}
        <Box component="span" fontWeight="bold">
          {volume ?? 0} {t('general|m')}
          <sup>3</sup>
        </Box>
      </Typography>
      <TextWithBoldPart normalText={`${t('general|labels|metrics|Loading Meter')}: `} boldText={loadingMeter ?? 0} />
      {/* Poland  */}
      {!!sentReference && (
        <TextWithBoldPart normalText={`${t('general|labels|sentReference')}: `} boldText={sentReference} />
      )}
      {!!sentCarrierKey && (
        <TextWithBoldPart normalText={`${t('general|labels|sentCarrierKey')}: `} boldText={sentCarrierKey} />
      )}
      {/* Hungary */}
      {!!ekaerFree && <Typography variant="body2">{t('general|labels|ekaerFree')}</Typography>}
      {!ekaerFree && !!ekaerNumber && (
        <TextWithBoldPart normalText={`${t('general|labels|ekaerNumber')}: `} boldText={ekaerNumber} />
      )}
    </Stack>
  );
};

export const StatusCell = ({ status, loading }) => {
  const { t } = useTranslation();
  const getTranslation = status => {
    switch (status) {
      case CONST.ORDER_SENT:
        return t('general|Order Sent');
      case CONST.BOOKED:
        return t('general|Booked');
      case CONST.IN_TRANSIT:
        return t('general|InTransit');
      case CONST.DELIVERED:
        return t(`general|Delivered`);
      case CONST.IN_COMPLETE:
        return t('general|Incomplete');
      default:
        return '-';
    }
  };

  const renderIcon = status => {
    switch (status) {
      case CONST.ORDER_SENT:
        return <CircleIcon color="info" />;
      case CONST.BOOKED:
        return <CircleOutlinedIcon color="info" />;
      case CONST.IN_TRANSIT:
        return <CircleOutlinedIcon color="success" />;
      case CONST.DELIVERED:
        return <CircleIcon color="success" />;
      case CONST.IN_COMPLETE:
        return <CircleOutlinedIcon sx={{ color: 'rgb(204, 204, 204)' }} />;
      default:
        return null;
    }
  };

  return loading ? (
    <Skeleton variant="rounded" width={80} height={25} />
  ) : (
    <Stack direction="row" alignItems="center" gap={1}>
      {renderIcon(status)}
      <Typography variant="body1">{getTranslation(status)}</Typography>
    </Stack>
  );
};

export const ShipmentIdCell = ({ shipmentNumber, userEmail, id }) => {
  const navigate = useNavigate();
  const { linkTo } = useRouting();

  return (
    <Stack maxWidth="100%">
      <Button
        variant="text"
        onClick={() => {
          navigate({
            pathname: linkTo(ROUTES.savedShipments),
            search: `?${createSearchParams({ shipmentId: [id] })}`,
          });
        }}
        sx={{ fontWeight: 'bold', p: 0, maxWidth: 'fit-content' }}
        data-testid={`${id}-shipment-number`}
      >
        {shipmentNumber}
      </Button>
      <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
        {userEmail}
      </Typography>
    </Stack>
  );
};

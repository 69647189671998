import { config, CONST } from '../../data-config';
import {
  commonFarmApiItemDimensionsLegacy,
  commonFarmApiTotalDimensions,
  dimensionParams,
  fillMissingParams,
} from '../../../globals/helpers/farmapi-validations';

export const resetShipmentType = (selectedProducts, isMorethanTwoProductsSelectedOrMultipleReceiver = false) => {
  let shipmentType = {};

  if (isMorethanTwoProductsSelectedOrMultipleReceiver) {
    return JSON.parse(JSON.stringify(config.shipmentTypesFields['unspecified']));
  }

  if (selectedProducts && selectedProducts[0] === 'dhlPall') {
    shipmentType = JSON.parse(JSON.stringify(config.shipmentTypesFields['full pallet']));
  } else {
    shipmentType = JSON.parse(JSON.stringify(config.shipmentTypesFields['parcel']));
  }

  return shipmentType;
};

export const setShipmentRowsNumberMax = (productCode, context) => {
  if (context.state.products && context.state.products.length > 0) {
    const product = context.state.products.find(element => {
      return parseInt(element.code) === parseInt(productCode);
    });

    return product.shipmentRowsNumberMax ? product.shipmentRowsNumberMax : config.maxAllowedShipmentDetailsRows;
  }

  return config.maxAllowedShipmentDetailsRows;
};

// prettier-ignore
export const setShipmentDimensionsForProduct = (productCode, context) => {
  const stateObject = JSON.parse(JSON.stringify(context.state.minMaxDimensions));
  let product = {};

  if (context.state.products && context.state.products.length > 0) {
    product = context.state.products.find(element => {
      return parseInt(element.code) === parseInt(productCode);
    });

    if (product) {
      if (product.piece.actualWeightMin) {
        stateObject.min_item_weight = product.piece.actualWeightMin;
      } else if (product.piece.actualWeightMin === null) {
        stateObject.min_item_weight = 0;
      }
      if (product.piece.actualWeightMax) {
        stateObject.max_item_weight = product.piece.actualWeightMax;
      } else if (product.piece.actualWeightMax === null) {
        stateObject.max_item_weight = context.state.minMaxDimensions.max_item_weight;
      }

      if (product.shipment.numberOfPiecesMin) {
        stateObject.min_item_quantity = product.shipment.numberOfPiecesMin;
      } else if (product.piece.numberOfPiecesMin === null) {
        stateObject.min_item_quantity = 0;
      }
      if (product.shipment.numberOfPiecesMax) {
        stateObject.max_item_quantity = product.shipment.numberOfPiecesMax;
      } else if (product.piece.actualWeightMax === null) {
        stateObject.max_item_weight = context.state.minMaxDimensions.max_item_weight;
      }

      if (product.shipment.numberOfPiecesMin) {
        stateObject.min_total_quantity = product.shipment.numberOfPiecesMin;
      } else if (product.piece.numberOfPiecesMin === null) {
        stateObject.min_total_quantity = 0;
      }
      if (product.shipment.numberOfPiecesMax) {
        stateObject.max_total_quantity = product.shipment.numberOfPiecesMax;
      } else if (product.piece.numberOfPiecesMax === null) {
        stateObject.max_total_quantity = context.state.minMaxDimensions.max_total_quantity;
      }

      if (product.piece.lengthMin) {
        stateObject.min_item_length = product.piece.lengthMin;
      } else if (product.piece.lengthMin === null) {
        stateObject.min_item_length = 0;
      }

      if (product.piece.lengthMax) {
        stateObject.max_item_length = product.piece.lengthMax;
      } else if (product.piece.lengthMax === null) {
        stateObject.max_item_length = context.state.minMaxDimensions.max_item_length;
      }

      if (product.piece.widthMin) {
        stateObject.min_item_width = product.piece.widthMin;
      } else if (product.piece.widthMin === null) {
        stateObject.min_item_width = 0;
      }

      if (product.piece.widthMax) {
        stateObject.max_item_width = product.piece.widthMax;
      } else if (product.piece.widthMax === null) {
        stateObject.max_item_width = context.state.minMaxDimensions.max_item_width;
      }

      if (product.piece.heightMin) {
        stateObject.min_item_height = product.piece.heightMin;
      } else if (product.piece.heightMin === null) {
        stateObject.min_item_height = 0;
      }

      if (product.piece.heightMax) {
        stateObject.max_item_height = product.piece.heightMax;
      } else if (product.piece.heightMax === null) {
        stateObject.max_item_height = context.state.minMaxDimensions.max_item_height;
      }

      if (product.piece.volumeMin) {
        stateObject.min_item_volume = product.piece.volumeMin;
      } else if (product.piece.volumeMin === null) {
        stateObject.min_item_volume = 0;
      }

      if (product.piece.volumeMax) {
        stateObject.max_item_volume = product.piece.volumeMax;
      } else if (product.piece.volumeMax === null) {
        stateObject.max_item_volume = context.state.minMaxDimensions.max_item_volume;
      }

      if (product.shipment.loadingMetreMin) {
        stateObject.min_item_loading_meter = product.shipment.loadingMetreMin;
      } else if (product.shipment.loadingMetreMin === null) {
        stateObject.min_item_loading_meter = 0;
      }

      if (product.shipment.loadingMetreMax) {
        stateObject.max_item_loading_meter = product.shipment.loadingMetreMax;
      } else if (product.shipment.loadingMetreMax === null) {
        stateObject.max_item_loading_meter = context.state.minMaxDimensions.max_item_loading_meter;
      }

      if (product.shipment.volumeMin) {
        stateObject.min_total_volume = product.shipment.volumeMin;
      } else if (product.shipment.volumeMin === null) {
        stateObject.min_total_volume = 0;
      }

      if (product.shipment.volumeMax) {
        stateObject.max_total_volume = product.shipment.volumeMax;
      } else if (product.shipment.volumeMax === null) {
        stateObject.max_total_volume = context.state.minMaxDimensions.max_total_volume;
      }

      if (product.shipment.loadingMetreMin) {
        stateObject.min_total_loading_meter = product.shipment.loadingMetreMin;
      } else if (product.shipment.loadingMetreMin === null) {
        stateObject.min_total_loading_meter = 0;
      }

      if (product.shipment.loadingMetreMax) {
        stateObject.max_item_loading_meter = product.shipment.loadingMetreMax;
      } else if (product.shipment.loadingMetreMax === null) {
        stateObject.max_item_loading_meter = context.state.minMaxDimensions.max_item_loading_meter;
      }

      if (product.shipment.actualWeightMin) {
        stateObject.min_shipment_weight = product.shipment.actualWeightMin;
      } else if (product.shipment.actualWeightMin === null) {
        stateObject.min_shipment_weight = 0;
      }

      if (product.shipment.actualWeightMax) {
        stateObject.max_shipment_weight = product.shipment.actualWeightMax;
      } else if (product.shipment.actualWeightMax === null) {
        stateObject.max_shipment_weight = context.state.minMaxDimensions.max_shipment_weight;
      }

      if (product.shipment.palletPlaceMin) {
        stateObject.min_total_palletPlace = product.shipment.palletPlaceMin;
      } else if (product.shipment.palletPlaceMin === null) {
        stateObject.min_total_palletPlace = 0;
      }

      if (product.shipment.palletPlaceMax) {
        stateObject.max_total_palletPlace = product.shipment.palletPlaceMax;
      } else if (product.shipment.palletPlaceMax === null) {
        stateObject.max_total_palletPlace = context.state.minMaxDimensions.max_total_palletPlace;
      }

      if (product.shipment.palletPlaceMin) {
        stateObject.min_item_palletPlace = product.shipment.palletPlaceMin;
      } else if (product.shipment.palletPlaceMin === null) {
        stateObject.min_item_palletPlace = 0;
      }

      if (product.shipment.palletPlaceMax) {
        stateObject.max_item_palletPlace = product.shipment.palletPlaceMax;
      } else if (product.shipment.palletPlaceMax === null) {
        stateObject.max_item_palletPlace = context.state.minMaxDimensions.max_item_palletPlace;
      }

      if (product.shipment.chargeableWeightMin) {
        stateObject.min_item_chargeableWeight = product.shipment.chargeableWeightMin;
      } else if (product.shipment.chargeableWeightMin === null) {
        stateObject.min_item_chargeableWeight = 0;
      }

      if (product.shipment.chargeableWeightMax) {
        stateObject.max_item_chargeableWeight = product.shipment.chargeableWeightMax;
      } else if (product.shipment.chargeableWeightMax === null) {
        stateObject.max_item_chargeableWeight = context.state.minMaxDimensions.max_item_chargeableWeight;
      }

      if (product.shipment.chargeableWeightMin) {
        stateObject.min_total_chargeableWeight = product.shipment.chargeableWeightMin;
      } else if (product.shipment.chargeableWeightMin === null) {
        stateObject.min_total_chargeableWeight = 0;
      }
      if (product.shipment.chargeableWeightMax) {
        stateObject.max_total_chargeableWeight = product.shipment.chargeableWeightMax;
      } else if (product.shipment.chargeableWeightMax === null) {
        stateObject.max_total_chargeableWeight = context.state.minMaxDimensions.max_total_chargeableWeight;
      }

      // DIMENSIONS PER ITEM:
      commonFarmApiItemDimensionsLegacy(stateObject, product);
      if (config.products_require_ppl_calculation.indexOf(product.code) !== -1 &&
        stateObject.max_item_chargeableWeight > 0) {
        stateObject.max_item_palletPlace = (
          stateObject.max_item_chargeableWeight / CONST.PER_PALLET_PLACE_DIVISOR
        ).toFixed(config.shipmentInputs.palletPlace.maxDecimalsMax);
      }
      if (config.products_require_ldm_calculation.indexOf(product.code) !== -1 && stateObject.max_item_chargeableWeight > 0) {
        stateObject.max_item_loading_meter = (
          stateObject.max_item_chargeableWeight / CONST.CHARGEABLE_WEIGHT_DIVISOR
        ).toFixed(config.shipmentInputs.loadingMeter.maxDecimals);
      }

      // TOTAL DIMENSIONS:
      commonFarmApiTotalDimensions(stateObject, product);
    }
    fillMissingParams(stateObject, dimensionParams, config)
  }

  return stateObject;
};

import { config, CONST } from '../../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest, getPropperAPIData } from 'globals/utils/requests';

export const loadCollectionAtTerminalValueModification = collectionAtTerminalValues => {
  const values = [];
  let value = {};
  const optionMap = {};

  collectionAtTerminalValues.forEach(terminalVal => {
    const option = {
      value: terminalVal.id,
      name:
        terminalVal.address && terminalVal.name
          ? terminalVal.name +
            '/' +
            terminalVal.address.street +
            '/' +
            terminalVal.address.postalCode +
            ' ' +
            terminalVal.address.cityName
          : '',
      skipTranslation: true,
      terminal: terminalVal,
    };

    value = terminalVal.defaultTerminal && option ? option.value : value;

    if (option) values.push(option);
  });

  optionMap.values = values;
  optionMap.value = value;

  return optionMap;
};

export const loadCollectionAtTerminal = async (stateObject, collectionAtTerminalIndex, routeState) => {
  const propperRouteState = getPropperAPIData(routeState);

  const collectionAtTerminalUrl =
    apiLinks.collectionAtTerminal +
    '?countryCode=' +
    propperRouteState.deliveryCountry.value +
    '&postalCode=' +
    propperRouteState.deliveryPostalCode.value;

  // Put terminals list in the 'values' field of the option for later render
  await apiRequest(collectionAtTerminalUrl, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        stateObject.additionalServices[collectionAtTerminalIndex].groups.forEach(group => {
          group.options.forEach(optionsList => {
            optionsList.forEach(option => {
              const terminalValues = loadCollectionAtTerminalValueModification(
                result.data.terminals ? result.data.terminals : [],
              );
              if (option.optionCode === config.OptionFieldTypes.ListCOT) {
                option.values = terminalValues.values;
                option.value = terminalValues.value;
                option.defaultValueAvailable = true;
              }
            });
          });
        });

        stateObject.error = false;
      } else {
        stateObject.error = true;
      }
    })
    .catch(() => {
      stateObject.error = true;
    });
};

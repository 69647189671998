import React from 'react';
import { Error } from 'components';
import { CONST } from '../../../data-config';
import { useTranslation } from 'react-i18next';

import './ModalView.css';

export const ModalView = ({
  error,
  transactionId,
  onCloseModalAction,
  actionClick,
  template,
  isViewTemplate,
  loader,
}) => {
  const { t } = useTranslation();

  return (
    <div className="frc__dialog" key={template.id}>
      <div className="frc__dialog-overlay" onClick={loader ? null : () => onCloseModalAction()}></div>
      <div className="frc__modal-dialog-width  frc__dialog-wrapper">
        <div className=" frc__dialog-container ">
          <div id="frc_dialog-close-btn" className="frc__dialog-close-button">
            <button className="frc__button--delete--darker" onClick={() => onCloseModalAction()}>
              X
            </button>
          </div>

          <h4 className="frc__modal-view-edit-center">
            {isViewTemplate
              ? t(`portalTemplates|View More Details`)
              : t(`portalTemplates|Would you like to delete this template`)}
          </h4>

          <h5 className="frc__modal-header">{template.templateName}</h5>

          <div className="margin-bottom-1 frc__modal-view-edit-list ">
            <div className="frc__modal-padding frc__modal-content l-grid--w-100pc-s l-grid--w-100pc-m-m l-grid--w-100pc-m-s">
              <div className="l-grid l-grid--w-100pc-s margin-bottom-1">
                <div className=" l-grid--w-50pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m">
                  <div className="frc__modal-attribute-color">{t('general|From')}</div>
                  <div className="frc__modal-word-wrap margin-bottom-1">
                    <strong> {template.sender.companyName} </strong>
                    <div>{template.sender.street}</div>
                    <div>
                      {template.sender.postalCode} {template.sender.city}
                    </div>
                    {template.sender.country}
                  </div>

                  <div className="frc__modal-word-wrap ">
                    <div> {template.sender.name} </div>
                    <div>{template.sender.email}</div>
                    <div>
                      {template.sender.phone && !template.sender.phone.endsWith('-') ? template.sender.phone : ''}
                    </div>
                  </div>
                </div>

                <div className=" l-grid--w-50pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m ">
                  <div className="frc__modal-attribute-color">{t('general|To')}</div>
                  <div className="frc__modal-word-wrap margin-bottom-1">
                    <strong> {template.receiver.companyName} </strong>
                    <div>{template.receiver.street}</div>
                    <div>
                      {template.receiver.postalCode} {template.receiver.city}
                    </div>
                    {template.receiver.country}
                  </div>
                  <div className="frc__modal-word-wrap margin-bottom-1">
                    <div> {template.receiver.name} </div>
                    <div>{template.receiver.email}</div>
                    <div>
                      {template.receiver.phone && !template.receiver.phone.endsWith('-') ? template.receiver.phone : ''}
                    </div>
                  </div>
                </div>
              </div>
              <div className="l-grid l-grid--w-100pc-s ">
                <div className=" l-grid--w-50pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m">
                  <div className="frc__modal-attribute-color">{t(`portalTemplates|Shipment`)}</div>

                  <div className="frc__summary--row l-grid--left-s">
                    <div>{t('general|labels|metrics|Total Quantity')}: </div>
                    <div>{template.totalQuantity}</div>
                  </div>
                  <div className="frc__summary--row l-grid--left-s">
                    <div>{t('general|labels|metrics|Total Weight')}: </div>
                    <div>{template.totalWeight ? template.totalWeight + ` ${t('general|kg')}` : '---'}</div>
                  </div>
                </div>

                <div className=" l-grid--w-50pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m ">
                  <div className="frc__modal-attribute-color">{t('products|common|productLabel')}</div>
                  <strong>{template.productName}</strong>
                </div>
              </div>
            </div>
          </div>

          <div className="l-grid">
            <div className={` l-grid l-grid--w-100pc-s  `}>
              <div className=" frc__modal-center l-grid--w-100pc-s l-grid--w-100pc-m-s l-grid--w-100pc-m-m">
                <button
                  className={'base-button base-button--white l-grid--w-20pc-s frc__btn-margin-right '}
                  id="cancelTemplateBtn"
                  onClick={() => actionClick(CONST.CANCEL)}
                >
                  <span>{t('general|labels|buttons|Cancel')}</span>
                </button>

                <button
                  className={'base-button  l-grid--w-30pc-s '}
                  onClick={() => actionClick(isViewTemplate ? CONST.EDIT : CONST.DELETE)}
                  id={`${isViewTemplate ? CONST.VIEW : CONST.DELETE}TemplateBtn`}
                >
                  <span>{isViewTemplate ? t('general|labels|buttons|Edit') : t('general|labels|buttons|Delete')}</span>
                </button>
              </div>
            </div>
          </div>
          {error && (
            <div className=" l-grid--w-100pc-s  ">
              <Error
                name="templateModalActionResultError"
                className="frc__input--error"
                message={
                  t('general|errors|General error') +
                  '<br>' +
                  t('general|Try again or call technical support.') +
                  ' ' +
                  t('general|or') +
                  ' ' +
                  `<strong><a href="${t('pageLinks|chat')}" 
                                    class ="frc__chat-with-us"
                                    target="_blank" rel="noopener noreferrer">${t(
                                      `general|Chat with us`,
                                    )}</a></strong>` +
                  ' <br> ' +
                  t('general|ID') +
                  ': ' +
                  transactionId
                }
              />
            </div>
          )}
          <div className={`frc__generic--loader-overlay ${loader ? 'is-loading' : ''}`} />
        </div>
      </div>
    </div>
  );
};

import { sortCountriesAlpha } from '../data-sorted-countries';
import { COUNTRY_SELECT_OPTIONS } from '../../locale/config';

export const getDefaultCountry = () => localStorage.getItem('country');

export const getCountryDropdownValues = ({ forceUserCountry, filterCountries = [] }) => {
  const userCountry = getDefaultCountry();

  if (forceUserCountry) {
    return sortCountriesAlpha({ ignoreSelectCountry: true, selectCountries: [userCountry] });
  }

  return sortCountriesAlpha({ ignoreSelectCountry: false, filterCountries });
};

export const changeCountryCodePhonePrefix = (context, newCountryCode, phoneNumbersKey = 'phoneNumbers') => {
  const phoneNumbers = context.state[phoneNumbersKey];
  const countryCodeOption = COUNTRY_SELECT_OPTIONS.find(option => option.code === newCountryCode.toUpperCase());
  if (countryCodeOption) {
    context.updateState({
      [phoneNumbersKey]: [
        {
          code: `+${countryCodeOption.phone}`,
          number: phoneNumbers && phoneNumbers[0].number ? phoneNumbers[0].number : '',
          error: phoneNumbers && phoneNumbers[0].error ? phoneNumbers[0].error : '',
          isRequired: phoneNumbers && phoneNumbers[0].isRequired ? phoneNumbers[0].isRequired : false,
        },
      ],
    });
  }
};

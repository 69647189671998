import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Error } from 'components';
import { apiRequest } from 'globals/utils/requests';
import { config, CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { formatDate } from 'globals/utils/formatting';
import SaveAsTemplate from './SaveAsTemplate/SaveAsTemplate';

import { Box } from '@mui/material';
import { TitleParagraph } from '../../../globals/components/ThankYou/TitleParagraph';
import {
  ErrorTitleWrapper,
  SuccessTitleWrapper,
  WarningTitleWrapper,
} from '../../../globals/components/ThankYou/TitleWrapper';
import { downloadApiRequest } from '../../../globals';
import ImportantRequirement from '../../../globals/components/ThankYou/ImportantRequirement';
import { composeFileName, redirect } from '../../../globals/components/ThankYou/ThankYou-helpers';
import { ChatWithUs } from '../../../components/fcp-components/ChatWithUs';
import { Button } from '../../../components/fcp-components/Button';
import { thankYouPageDatePresenter, toDateComponent } from '../../../globals/helpers/side-summary-helpers';

class ThankYouPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentList: [],
      PDFdownloadError: false,
      makeShipmentTemplateClicked: false,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (config.regEx.urlValidation.test(window.location.pathname.split('/')[1])) {
      let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '#thankYou';
      window.history.pushState({ path: newurl }, '', newurl);
    }
  };

  async UNSAFE_componentWillMount() {
    const stateObj = {};
    const { shipmentStatusData } = this.props;

    if (shipmentStatusData) {
      stateObj.bookingOrderResult = shipmentStatusData.bookingOrderResult;
      stateObj.deliveryDate = shipmentStatusData.deliveryDate;
      stateObj.fixedDate = shipmentStatusData.fixedDate;
      stateObj.customs = shipmentStatusData.customs;
      stateObj.isRedThankYouPage = shipmentStatusData.redThankYouPage;
      stateObj.redThankYouPageErrorMessage = shipmentStatusData.redThankYouErrorMessage;
      stateObj.pickupOrderMovedToNextDay = shipmentStatusData.pickupOrderMovedToNextDay;
      stateObj.priorityServiceError = shipmentStatusData.priorityServiceError;
      stateObj.shouldPickupRequestCondition = shipmentStatusData.shouldPickupRequestCondition;
      stateObj.documentList =
        shipmentStatusData.bookingOrderResult.reports && shipmentStatusData.bookingOrderResult.reports.length >= 1
          ? this.getDocumentList(shipmentStatusData.bookingOrderResult.reports)
          : false;
    }

    this.setState(stateObj);

    if (!shipmentStatusData.redThankYouPage && this.props.context.state.isSavedTemplate) {
      this.updateTemplateStatistics(this.props.context.state);
    }
  }

  updateTemplateStatistics = async ({ id }) => {
    const url = apiLinks.incrementTemplateStatictics.replace('{id}', id);
    const resultMap = {};

    await apiRequest(url, 'PUT')
      .then(result => {
        if (result.status === CONST.STATUS_OK) {
          resultMap.data = result.data;
        } else {
          resultMap.error = true;
        }
      })
      .catch(() => {
        resultMap.error = true;
      });

    return resultMap;
  };

  getDocumentList = reports => {
    const documentList = [];

    reports.forEach(report => {
      documentList.push(report.name);
    });

    return documentList;
  };

  onTemplateBtnClick = () => {
    this.setState({ makeShipmentTemplateClicked: true });
  };

  downloadPrivateFile = async (fileName, index) => {
    if (!this.state.documentList.includes(fileName)) {
      return;
    }
    let url = '';
    if (fileName.includes(this.state.bookingOrderResult.returnShipmentNumber)) {
      url = apiLinks.downloadDocumentPortal.replace(
        '{UniqueCode}',
        this.state.bookingOrderResult.returnShipmentUniqueCode,
      );
    } else {
      url = apiLinks.downloadDocumentPortal.replace('{UniqueCode}', this.state.bookingOrderResult.uniqueCode);
    }
    url = url.replace('{fileName}', fileName);

    await downloadApiRequest(url.toString(), 'GET').then(blob => {
      var file = new Blob([blob], {
        type: blob.type,
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
        return;
      }

      const urlU = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = urlU;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  render() {
    window.onhashchange = function () {
      window.location.reload();
    };
    const pickupDateCondition =
      this.state.bookingOrderResult?.pickupDateTime && this.state.bookingOrderResult.pickupDateTime.indexOf('T') !== -1;
    return (
      <Translation>
        {t => (
          <Box
            sx={{
              maxWidth: '1365px',
              margin: 'auto',
              boxSizing: 'border-box',
              marginBottom: '15rem',
              marginTop: '2.5rem',
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box
              sx={{
                flex: 2,
              }}
            >
              <Box
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                }}
              >
                <Box
                  sx={{
                    padding: '2rem',
                  }}
                >
                  {!this.state.isRedThankYouPage && !this.state.pickupOrderMovedToNextDay && (
                    <SuccessTitleWrapper titleCode="general|Thank you for booking" />
                  )}
                  {!this.state.isRedThankYouPage && this.state.pickupOrderMovedToNextDay && <WarningTitleWrapper />}
                  {this.state.isRedThankYouPage && <ErrorTitleWrapper />}

                  <Box>
                    {this.state.pickupOrderMovedToNextDay && (
                      <p>
                        {t(
                          'general|Your order has been booked, but the date you selected was not available. The order has been moved to the',
                        )}
                        <strong> {t('general|NEXT BUSINESS DAY')}.</strong>
                      </p>
                    )}
                    {this.state.pickupOrderMovedToNextDay && (
                      <p>
                        {t(
                          'general|If there are any questions, please contact Customer Service for further information.',
                        )}
                      </p>
                    )}
                    {this.state.isRedThankYouPage && (
                      <>
                        <Error
                          name="thankyouPageError"
                          message={t(
                            'general|If there are any questions, please contact Customer Service for further information.',
                          )}
                        />

                        {this.state.priorityServiceError && (
                          <Error name="priorityServiceError" message={this.state.redThankYouPageErrorMessage} />
                        )}
                      </>
                    )}

                    {!this.state.isRedThankYouPage && (
                      <>
                        <TitleParagraph
                          label={t('general|Your shipment number is')}
                          value={this.state.bookingOrderResult && this.state.bookingOrderResult.shipmentNumber}
                          valueId={'thankYouPageShipmentNumber'}
                        />
                        {this.state.bookingOrderResult?.returnShipmentNumber && (
                          <>
                            <TitleParagraph
                              label={t('general|Your return shipment number is')}
                              value={
                                this.state.bookingOrderResult && this.state.bookingOrderResult.returnShipmentNumber
                              }
                              valueId={'thankYouPageReturnShipmentNumber'}
                              content={
                                <span style={{ fontSize: '12px' }}>
                                  *
                                  {t(
                                    'general|Return Label can be printed by the Parcel Shop at drop off by the customer',
                                  )}
                                </span>
                              }
                            />
                          </>
                        )}
                        {this.state.shouldPickupRequestCondition && this.state.bookingOrderResult.bookingNumber && (
                          <TitleParagraph
                            label={t('general|Your pickup order number is')}
                            value={this.state.bookingOrderResult.bookingNumber}
                            valueId={'thankYouPagePickupOrderNumber'}
                          />
                        )}
                        <>
                          {this.state.bookingOrderResult && pickupDateCondition && (
                            <TitleParagraph
                              label={t('general|Pickup scheduled for')}
                              value={
                                this.state.bookingOrderResult
                                  ? formatDate(this.state.bookingOrderResult.pickupDateTime.split('T')[0])
                                  : t(`general|Not Available`)
                              }
                              valueId={'thankYouPagePickupScheduledAt'}
                            />
                          )}
                          {toDateComponent(this.props.context.state, thankYouPageDatePresenter)}
                        </>
                      </>
                    )}
                  </Box>
                </Box>

                {!this.state.isRedThankYouPage && (
                  <ImportantRequirement
                    state={this.state}
                    options={this.state.documentList}
                    fileNameComposer={composeFileName}
                    errorMessageCode="portalOrder|Something has gone wrong with the download. Try again later."
                    downloadFile={this.downloadPrivateFile}
                  />
                )}
              </Box>

              {!this.state.isRedThankYouPage &&
                !this.props.context.state.isSavedTemplate &&
                !this.state.makeShipmentTemplateClicked && (
                  <Box
                    sx={{
                      border: '1px solid #ccc',
                      borderRadius: '3px',
                      marginTop: ' 1.5rem',
                      alignItems: 'center',
                      display: 'flex',
                      backgroundColor: '#f2f2f2',
                      padding: '2rem',
                      '& *': {
                        flex: 1,
                        maxWidth: '100%',
                      },
                    }}
                  >
                    <h5 className="m-0">{t(`portalOrder|Send this shipment often?`)}</h5>
                    <Button
                      sx={{
                        color: '#d40511',
                        border: '1px solid #d40511',
                        marginRight: '1rem',
                        borderRadius: '3px',
                        padding: '0.75rem 1.25rem',
                        fontWeight: 600,
                        flex: 'none',
                      }}
                      onClick={this.onTemplateBtnClick}
                      dataTestId="make-this-shipment-template-btn"
                    >
                      {t(`portalOrder|Make this shipment a Template`)}
                    </Button>
                  </Box>
                )}

              {this.state.isRedThankYouPage && (
                <Box
                  sx={{
                    marginTop: ' 1.5rem',
                    alignItems: 'center',
                    display: 'flex',
                    backgroundColor: '#f2f2f2',
                    padding: '2rem',
                  }}
                >
                  <Button
                    id="backToReviewAndBook"
                    sx={{
                      color: '#d40511',
                      border: '1px solid #d40511',

                      marginRight: '1rem',
                      borderRadius: '3px',
                      padding: '0.75rem 1.25rem',
                      fontWeight: 600,
                      flex: 'none',
                      width: 'auto',
                    }}
                    onClick={() => {
                      this.props.enableOtherTabs();
                      this.props.thankYouPage();
                    }}
                  >
                    {t(`general|Back to Review & Book`)}
                  </Button>
                </Box>
              )}

              {!this.state.isRedThankYouPage && (
                <>
                  {!this.state.isRedThankYouPage && this.state.makeShipmentTemplateClicked && (
                    <Box
                      sx={{
                        marginTop: ' 1.5rem',
                        alignItems: 'center',
                        display: 'block',
                        flexDirection: 'row',
                        backgroundColor: '#f2f2f2',
                        padding: '2rem',
                      }}
                    >
                      <SaveAsTemplate t={t} context={this.props.context} />
                    </Box>
                  )}
                </>
              )}
            </Box>

            <Box
              sx={{
                flex: '1',
                marginLeft: ' 2rem',
              }}
            >
              {!this.state.isRedThankYouPage && (
                <Box
                  sx={{
                    border: '1px solid #ccc',
                    borderRadius: '3px',
                    padding: '2rem',
                    backgroundColor: '#f2f2f2',
                  }}
                >
                  <h5>{t('general|Next Steps')}</h5>
                  <p>
                    {t(
                      `portalOrder|A summary and your shipment documents will be sent to your provided e-mail address. If you need to change or cancel your booking, call Customer Service on`,
                    )}{' '}
                    {t('general|customerServicePhoneNumber')} {t('general|or')} <ChatWithUs />
                  </p>
                </Box>
              )}
              <Box
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  padding: '2rem',
                  backgroundColor: '#f2f2f2',
                }}
              >
                <h5>{t('general|shipment|createAnotherShipment')}</h5>
                <Button
                  sx={{
                    color: '#d40511',
                    border: '1px solid #d40511',
                    marginRight: '1rem',
                    borderRadius: '3px',
                    padding: '0.75rem 1.25rem',
                    fontWeight: 600,
                    flex: 'none',
                  }}
                  onClick={redirect}
                  dataTestId="create-new-shipment-btn"
                >
                  {t(`general|Create a New Shipment`)}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Translation>
    );
  }
}

export default ThankYouPage;

import { globalConfig } from '../data-config-global';

export const checkFixedDateSelection = (context, checkForFixedDate) => {
  if (checkForFixedDate) {
    const vasFixedDeliveryDateOption = context.state.additionalServices.filter(vas => {
      return globalConfig.VASfixedDateOption.some(optionCode => vas.code === optionCode && vas.value);
    });

    return vasFixedDeliveryDateOption && vasFixedDeliveryDateOption.length > 0;
  } else {
    return false;
  }
};

import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { CONST, config } from '../../data-config';
import { Pagination } from 'components';
import { DataTable } from 'components';
import AddressCustomCell from './CustomCell/AddressCustomCell';
import SummaryCustomCell from './CustomCell/SummaryCustomCell';
import { fetchTemplateOnSearchOrPageChangeOrReset } from '../CommonUtils';

import './TemplatesResultGrid.css';

export default class TemplatesResultGrid extends Component {
  getCustomCell = () => {
    return [
      {
        name: this.props.translation('general|Template Name'),
        selector: 'templateName',
        sortable: true,
      },
      {
        name: this.props.translation('general|Sender'),
        selector: 'senderCompanyName',
        sortable: true,
        cell: row => <AddressCustomCell cellValue={row} isSender={true} />,
      },
      {
        name: this.props.translation('general|Receiver'),
        selector: 'receiverCompanyName',
        sortable: true,
        cell: row => <AddressCustomCell cellValue={row} />,
      },
      {
        name: this.props.translation('general|Summary'),
        selector: 'productName',
        sortable: true,
        cell: row => (
          <SummaryCustomCell
            action={{ edit: this.props.onShowTemplate }}
            baseUrl={this.props.baseUrl}
            cellValue={row}
            context={this.props.context}
            t={this.props.translation}
            onShowSuccessToaster={this.props.onShowSuccessToaster}
          />
        ),
      },
    ];
  };

  selectedRows = rows => {
    this.props.onDeleteContact(rows);
  };

  handlePageChange = async page => {
    await fetchTemplateOnSearchOrPageChangeOrReset(this.props.context, {
      pageSize: false,
      page: page,
    });
  };

  handleDisplayRowChange = async pageSize => {
    await fetchTemplateOnSearchOrPageChangeOrReset(this.props.context, {
      pageSize: pageSize,
      page: false,
    });
  };

  render() {
    const { context } = this.props;

    return (
      <Translation>
        {t => (
          <>
            {context.state.templateList.length > 0 && (
              <DataTable
                selectedRows={this.selectedRows}
                columns={this.getCustomCell()}
                data={context.state.templateList}
              />
            )}
            {!context.state.manualSearch && context.state.templateList.length > 0 ? (
              <Pagination
                t={t}
                CONST={CONST}
                paginationItemsPerRow={config.paginationItemsPerRow}
                onHandleDisplayRowChange={this.handleDisplayRowChange}
                context={context}
                pagination={context.state.pagination}
                onHandlePageChange={this.handlePageChange}
              />
            ) : (
              <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                <div className="frc__pagination-magin-top  l-grid--w-100pc-s l-grid--w-30pc-m-s l-grid--w-30pc-m-m">
                  {context.state.templateList.length > 0
                    ? t('general|Total Results Found', {
                        nos: context.state.templateList.length,
                      })
                    : t('general|errors|No Results Found')}
                </div>
              </div>
            )}
          </>
        )}
      </Translation>
    );
  }
}

import { config } from '../../data-config';
import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';

export const schedulePickupValidations = (state, stateToSet, hasErrors) => {
  if (!state.pickupOption.value) {
    hasErrors = true;
    stateToSet.pickupOption = { value: '', error: true };
  }

  return {
    stateToSet,
    hasErrors,
  };
};

export const onTogglePickupOptions = props => {
  const selectedProduct = props.context.state.selectedProduct?.code;
  let schedulePickupOnly = false;
  let pickupOption = '';

  // Toggle pickup options
  if (selectedProduct) {
    let condition = config.schedulePickup.find(item => item === selectedProduct);

    if (condition) {
      schedulePickupOnly = true;
      pickupOption = 'Schedule Pickup';
    } else {
      schedulePickupOnly = false;
      pickupOption = props.context.state.pickupOption.value;
    }

    // Set context state specia lcase
    props.context.updateState({
      pickupOption: { value: pickupOption, error: false },
    });
  }

  return schedulePickupOnly;
};

export const validateAllInputs = context => {
  let validate = {
    stateToSet: {},
    hasErrors: false,
  };

  let getState = {};

  validate = schedulePickupValidations(context.state, validate.stateToSet, validate.hasErrors);

  if (context.state.pickupOption.value === 'Schedule Pickup') {
    validate = schedulePickupValidations(context.state, validate.stateToSet, validate.hasErrors);

    // Check Pickup date
    if (!context.state.pickupDate.value) {
      validate.hasErrors = true;
    }

    // Pickup Instructions
    getState = checkFieldAndReturnState({
      name: 'pickupInstructions',
      value: context.state.pickupInstructions.value,
      regEx: config.regEx.everything,
      lengthCheck: [RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)],
      isRequired: false,
    });

    validate.hasErrors = getState.error || validate.hasErrors;
    validate.stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: false };
  }

  getState = checkFieldAndReturnState({
    name: 'deliveryInstructions',
    value: context.state.deliveryInstructions.value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)],
    isRequired: false,
  });

  validate.hasErrors = getState.error || validate.hasErrors;
  validate.stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: false };

  return {
    stateToSet: validate.stateToSet,
    hasErrors: validate.hasErrors,
  };
};

export const checkPickupOptions = context => {
  const schedulePickupOption = context.state.pickupOption.value === 'Schedule Pickup' ? true : false;

  if (!schedulePickupOption) {
    const pickupInstructions = { value: '', error: false };
    context.updateState({
      pickupInstructions,
    });
  }
};

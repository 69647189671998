import i18n from 'i18next';
import {
  RECEIVER_PAYER,
  SENDER_PAYER,
  THIRD_PARTY_PAYER,
  DOMESTIC_SHIPMENT_RANGE,
  INTERNATIONAL_SHIPMENT_RANGE,
  IMPORT_SHIPMENT_TYPE,
  EXPORT_SHIPMENT_TYPE,
} from '../constants';
import { getDefaultCountry } from './country-helpers';
import { caseInsensitiveEquals } from '../utils/strings';
import { config } from '../utils/data-config';

/**
 * Represents a product specific colliType.
 *
 * @typedef {Object} ProductColliType
 * @property {string} name - The name of the colliType.
 * @property {string} code - The code of the colliType.
 */

/**
 * Filters and sorts an array of all colliTypes based on country (sweden vs global),
 * user preferences (favourite colliTypes), product (allowed collitypes for product)
 *
 * @param {Object} options - Object containing options for filtering colliTypes.
 * @param {Array<Object>} options.colliTypes - Array of colliTypes to filter.
 * @param {string} options.countryCode - Country code.
 * @param {Array<string>} [options.favouriteColliTypes] - Array of favorite colliType names used for filtering.
 * @param {Array<ProductColliType>} [options.productWhitelist] - Array of product whitelisted colliTypes.
 * @returns {Array<Object>} - An array of filtered sorted colliTypes based on the specified criteria.
 */
export const getColliTypes = ({ colliTypes, favouriteColliTypes, countryCode, productWhitelist }) => {
  let filteredColliTypes = getCountryColliTypes(colliTypes, countryCode);

  if (Array.isArray(favouriteColliTypes) && favouriteColliTypes.length) {
    filteredColliTypes = getFavouriteColliTypes(filteredColliTypes, favouriteColliTypes);
  }

  if (Array.isArray(productWhitelist) && productWhitelist.length) {
    filteredColliTypes = getWhitelistColliTypes(filteredColliTypes, productWhitelist);
  }

  return filteredColliTypes;
};

/**
 * Filters and sorts a list of colliTypes (shipment types) based on
 * profile settings (favourite shipment types)
 *
 * @param {Array<Object>} colliTypes - The array of colliTypes to filter and sort.
 * @param {Array<string>} favouriteColliTypes - An optional array of favorite colliType names used for sorting.
 * @returns {Array<Object>} - An array of colliTypes that meet the filtering and sorting criteria.
 */
export const getFavouriteColliTypes = (colliTypes, favouriteColliTypes) => {
  let filteredColliTypes = structuredClone(colliTypes);

  if (Array.isArray(favouriteColliTypes) && favouriteColliTypes.length) {
    filteredColliTypes.sort((typeA, typeB) => {
      const containsA = favouriteColliTypes.includes(typeA.name);
      const containsB = favouriteColliTypes.includes(typeB.name);
      if (containsA === containsB) return 0;
      if (containsA) return -1;
      if (containsB) return 1;
      return 0;
    });

    filteredColliTypes.forEach(type => {
      if (favouriteColliTypes.includes(type.name)) {
        type.bold = true;
      }
    });
  }

  return filteredColliTypes;
};

/**
 * Filters list of colliTypes (shipment types) based on country
 *
 * @param {Array<Object>} colliTypes - Array of colliTypes to filter and sort.
 * @param {boolean} countryCode - Country code
 * @returns {Array<Object>} - Array of colliTypes that meet the filtering and sorting criteria.
 */
export const getCountryColliTypes = (colliTypes, countryCode) => {
  if (!countryCode) {
    throw new Error('getCountryColliTypes: countryCode is mandatory', countryCode);
  }

  let filteredColliTypes = structuredClone(colliTypes);
  const isSweden = countryCode?.toLowerCase() === 'se';

  if (Array.isArray(colliTypes) && colliTypes.length > 0) {
    filteredColliTypes = filteredColliTypes.filter(type => type.swedenOnly === isSweden);
  }

  filteredColliTypes.sort((a, b) => {
    return i18n
      .t(`general|labels|shipmentType|${a.name}`)
      ?.localeCompare(i18n.t(`general|labels|shipmentType|${b.name}`));
  });
  return filteredColliTypes;
};

/**
 * Filters an array of colliTypes based on a whitelist of allowed codes.
 *
 * @param {Array<Object>} colliTypes - Array of colliTypes to filter.
 * @param {Array<ProductColliType>} whitelist - Array of product colliTypes to whitelist.
 * @param {boolean} [disable=true] - Flag indicating whether to disable colliTypes not in the whitelist (default: true).
 * @returns {Array<Object>} - Array of filtered product colliTypes.
 */
export const getWhitelistColliTypes = (colliTypes, whitelist, disable = true) => {
  let filteredColliTypes = structuredClone(colliTypes);

  filteredColliTypes.forEach(colliType => {
    if (whitelist.some(whitelistedType => whitelistedType.code === colliType.dataRel)) {
      colliType.disabled = false;
    } else {
      colliType.disabled = true;
    }
  });

  // disabled last
  filteredColliTypes.sort((typeA, typeB) => Number(typeA.disabled) - Number(typeB.disabled));

  //  TODO: filter out if disable = false

  return filteredColliTypes;
};

/**
 * Shipment flags object contains flags defining
 * the basic shipment properties.
 *
 * @typedef {Object} ShipmentFlags
 * @property {boolean} isDomestic
 * @property {boolean} isInternational
 * @property {boolean} isImport
 * @property {boolean} isExport
 * @property {boolean} [isSenderPayer]
 * @property {boolean} [isReceiverPayer]
 * @property {boolean} [isThirdPartyPayer]
 */

/**
 * Get options for the Who will pay for shipment dropdown
 *
 * @param {string} countryCode - Country code
 * @param {ShipmentFlags} shipmentFlags - Flags defining the basic shipment properties
 * @returns {Array<String>} - Array of filtered whoPays options
 */
export const getWhoPaysOptions = (countryCode, shipmentFlags) => {
  const baseOptions = [SENDER_PAYER, RECEIVER_PAYER, THIRD_PARTY_PAYER];
  let filteredOptions = [...baseOptions];

  const isSweden = countryCode?.toLowerCase() === 'se';
  const { isDomestic, isExport, isImport, isInternational } = shipmentFlags ?? {};

  // import can't offer sender
  if (isInternational && isImport) {
    filteredOptions = filteredOptions.filter(option => option?.toLowerCase() !== 'sender');
  }

  // global domestic can't offer third party
  if (!isSweden && isDomestic) {
    filteredOptions = filteredOptions.filter(option => option?.toLowerCase() !== 'third party');
  }

  // global import can't offer third party
  if (isInternational && isImport && !isSweden) {
    filteredOptions = filteredOptions.filter(option => option?.toLowerCase() !== 'third party');
  }

  // global export can offer only sender
  if (isInternational && isExport && !isSweden) {
    filteredOptions = filteredOptions.filter(option => option?.toLowerCase() === 'sender');
  }

  return filteredOptions;
};

/**
 * Clone a shipment row (=piece) and directly update the context
 *
 * @param {number} rowIndex - Row index to copy
 * @param {object} context - The Context
 * @param {Function} calculateInputs - function to recalculate the shipment totals
 * @returns {void}
 */
export const cloneShipmentRow = ({ rowIndex, context, maxRows, calculateInputs }) => {
  const params = {};
  const rowToClone = context?.state?.shipmentDetailsRows?.[rowIndex];

  if (rowToClone && context.state.shipmentDetailsRows.length < maxRows) {
    params.groupName = 'shipmentDetailsRows';
    params.item = structuredClone(rowToClone);
    params.afterUpdate = calculateInputs;

    context.addAnotherGroupField(params);
  }
};

/**
 * Calculate shipment flags from context state
 *
 * @param {object} state - Context state
 * @returns {ShipmentFlags} shipmentFlags - Flags defining the basic shipment properties
 */
export const getShipmentFlags = state => {
  return {
    isDomestic: state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE,
    isInternational: state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE,
    isImport: state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE,
    isExport: state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE,
    isSenderPayer: state.shipmentPayer.value === SENDER_PAYER,
    isReceiverPayer: state.shipmentPayer.value === RECEIVER_PAYER,
    isThirdPartyPayer: state.shipmentPayer.value === THIRD_PARTY_PAYER,
    senderAccountShown: Boolean(state?.accountNumber?.display),
    receiverAccountShown: Boolean(state?.receiverNumber?.display),
    thirdPartyAccountShown: Boolean(state?.thirdPartyNumber?.display),
  };
};

/**
 * Calculate account inputs flags from context state
 *
 * @param {object} state - Context state
 * @returns {object} accountInputFlags - Flags defining the basic shipment properties
 */
export const getAccountInputFlags = state => {
  return {
    senderShown: Boolean(state?.accountNumber?.display),
    receiverShown: Boolean(state?.receiverNumber?.display),
    thirdPartyShown: Boolean(state?.thirdPartyNumber?.display),
    senderSelect: Boolean(state?.accountNumber?.isSelect),
    receiverSelect: Boolean(state?.receiverNumber?.isSelect),
    thirdPartySelect: Boolean(state?.thirdPartyNumber?.isSelect),
  };
};

// Context adapter function to reconcile shipment payer (who pays) with the shipment range (export import)
// because not all payer options are available for all shipmetn ranges
export const reconcilePayerWithRange = (context, params, countryCode) => {
  // For comparison with current value, to know when to swap sender and receiver address data
  context.updateState({ prevShipmentTypesImpOrExp: { value: context.state.shipmentTypesImpOrExp.value } });

  const futureState = structuredClone(context.state);
  futureState.shipmentTypesImpOrExp.value = params.value;

  const shipmentFlags = getShipmentFlags(futureState);
  const futureWhoPaysOptions = getWhoPaysOptions(countryCode, shipmentFlags);

  // there was no discrepancy so we can return early
  if (futureWhoPaysOptions.includes(context.state.shipmentPayer.value)) return;

  // current payer is not available anymore so we have to update the state correspondingly
  if (context.state.shipmentPayer.value === SENDER_PAYER) {
    const updatedState = {
      deliveryAddressResidential: { value: false },
      shipmentPayer: {
        value: RECEIVER_PAYER,
      },
    };
    context.updateState(updatedState);
  }

  if (context.state.shipmentPayer.value === RECEIVER_PAYER) {
    const updatedState = {
      pickupAddressResidential: { value: false },
      shipmentPayer: {
        value: SENDER_PAYER,
      },
    };
    context.updateState(updatedState);
  }
};

/**
 * Calculate account inputs flags from context state
 *
 * @param {object} shipmentRow - shipment row
 * @param {object} propertyName - property name, eg. width, height
 * @param {object} updatedValues  - object with updated values which should be merged with current
 * @returns {object} updatedRow - updated shioment row
 */
export const updateShipmentRow = ({ shipmentRow, propertyName, updatedValues }) => {
  const clonedRow = structuredClone(shipmentRow);
  if (clonedRow.hasOwnProperty(propertyName)) {
    return {
      ...clonedRow,
      [propertyName]: { ...clonedRow[propertyName], ...updatedValues },
    };
  }

  return clonedRow;
};

export const calculateLoadingMeter = ({ length, width }) => {
  const LOADING_METER_DENOMINATOR = 24000;
  const parsedLength = parseInt(length);
  const parsedWidth = parseInt(width);

  const loadingMeter = (parsedLength * parsedWidth) / LOADING_METER_DENOMINATOR;

  // returns NaN on purpose to not hide errors
  return Number.isNaN(loadingMeter) ? loadingMeter : parseFloat(loadingMeter.toFixed(2));
};

export const calculateVolume = ({ length, width, height }) => {
  const VOLUME_DENOMINATOR = 100 * 100 * 100;
  const parsedLength = parseInt(length);
  const parsedWidth = parseInt(width);
  const parsedHeight = parseInt(height);

  const volume = (parsedLength * parsedWidth * parsedHeight) / VOLUME_DENOMINATOR;

  // can return NaN
  return volume.toFixedUp(3);
};

export const validateLoadingMeter = ({ loadingMeter, height, nonStackable, width, length, required, dimensions }) => {
  const { min_item_loading_meter, max_item_loading_meter } = dimensions;
  const ldmParsed =
    typeof loadingMeter === 'string' ? parseFloat(loadingMeter?.replace(',', '.')) : parseFloat(loadingMeter);

  const automaticLdm = shouldUseAutomaticLdm({ height, nonStackable, dimensions, width, length });
  const calculatedLoadingMeter = calculateLoadingMeter({ width, length });

  const automaticLdmError = automaticLdm && ldmParsed < calculatedLoadingMeter;
  const dimensionError = ldmParsed < min_item_loading_meter || ldmParsed > max_item_loading_meter;
  const requiredError = required && Number.isNaN(ldmParsed);
  const error = dimensionError || requiredError || automaticLdmError;

  // we must never break the product limits
  const automaticLdmMin = Math.min(Math.max(calculatedLoadingMeter, min_item_loading_meter), max_item_loading_meter);

  return {
    error,
    loadingMeterMin: automaticLdm ? automaticLdmMin : min_item_loading_meter,
    loadingMeterMax: max_item_loading_meter,
  };
};

export const handleLoadingMeterUpdate = ({ shipmentRow, dimensions }) => {
  const automaticLdm = shouldUseAutomaticLdm({
    height: shipmentRow.height.value,
    nonStackable: shipmentRow.nonStackable.value,
    dimensions,
    width: shipmentRow.width.value,
    length: shipmentRow.length.value,
  });

  const calculatedLoadingMeter = calculateLoadingMeter({
    width: shipmentRow.width.value,
    length: shipmentRow.length.value,
  });

  const isLdmRequired =
    Boolean(automaticLdm) || (Boolean(shipmentRow.nonStackable.value) && !Boolean(shipmentRow.loadingMeter.disabled));

  let updatedRow = shipmentRow;

  updatedRow = updateShipmentRow({
    shipmentRow: updatedRow,
    propertyName: 'loadingMeter',
    updatedValues: {
      ...(automaticLdm && { value: calculatedLoadingMeter.toFixed(2) }),
      required: isLdmRequired,
    },
  });

  const { error, loadingMeterMin } = validateLoadingMeter({
    loadingMeter: updatedRow.loadingMeter.value,
    dimensions,
    required: updatedRow.loadingMeter.required,
    nonStackable: updatedRow.nonStackable.value,
    width: updatedRow.width.value,
    length: updatedRow.length.value,
    height: updatedRow.height.value,
  });

  updatedRow = updateShipmentRow({
    shipmentRow: updatedRow,
    propertyName: 'loadingMeter',
    updatedValues: {
      min: loadingMeterMin,
      error: error,
    },
  });

  return updatedRow;
};

export const handleNonStackableUpdate = ({ shipmentRow, dimensions, checked }) => {
  if (checked) {
    return handleLoadingMeterUpdate({
      shipmentRow,
      dimensions,
    });
  } else {
    const isHalfOrFullPallet =
      shipmentRow.shipmentType.value === 'full pallet' || shipmentRow.shipmentType.value === 'half pallet';

    const { error, loadingMeterMin } = validateLoadingMeter({
      loadingMeter: shipmentRow.loadingMeter.value,
      dimensions,
      required: false,
      nonStackable: shipmentRow.nonStackable.value,
      width: shipmentRow.width.value,
      length: shipmentRow.length.value,
      height: shipmentRow.height.value,
    });

    return updateShipmentRow({
      shipmentRow,
      propertyName: 'loadingMeter',
      updatedValues: {
        required: false,
        disabled: false,
        min: loadingMeterMin,
        error: error,
        // for sweden LDM is disabled for pallets, so we must clear it automatically when nonStackable is unchecked
        value: isHalfOrFullPallet ? '' : shipmentRow.loadingMeter.value,
      },
    });
  }
};

export const getTotalLoadingMeterFields = ({ state }) => {
  const userCountry = getDefaultCountry();
  const useModifiedLdmLogic = config.ldm_modified_calculation_countries.some(countryCode =>
    caseInsensitiveEquals(countryCode, userCountry),
  );

  const totals = state.totals.shipmentDetailsRows;
  const { min_total_loading_meter, max_total_loading_meter } = state.dimensions ?? {};

  const someHaveLoadingMeter = state.shipmentDetailsRows?.some(
    shipmentRow => !Number.isNaN(parseFloat(shipmentRow?.loadingMeter?.value)),
  );

  const allHaveLoadingMeter = state.shipmentDetailsRows?.every(
    shipmentRow => !Number.isNaN(parseFloat(shipmentRow?.loadingMeter?.value)),
  );

  const allRowsDisabled =
    state.shipmentDetailsRows?.every(shipmentRow => shipmentRow?.loadingMeter?.disabled) &&
    Boolean(state.shipmentDetailsRows?.length);

  const disabled = allRowsDisabled || (useModifiedLdmLogic && !someHaveLoadingMeter);
  const loadingMeterSum = calculateTotalLdm({ shipmentDetailsRows: state.shipmentDetailsRows });

  const requiredError = someHaveLoadingMeter && Number.isNaN(parseFloat(totals.loadingMeter.value));
  const productDimensionError =
    parseFloat(totals.loadingMeter.value) < min_total_loading_meter ||
    parseFloat(totals.loadingMeter.value) > max_total_loading_meter;

  // total ldm is smaller than sum of piece ldms
  const sumError = someHaveLoadingMeter && parseFloat(totals.loadingMeter.value) < loadingMeterSum;

  // sum is bigger than max total ldm
  const sumMaxError = someHaveLoadingMeter && loadingMeterSum > max_total_loading_meter;

  // MODIFIED LDM LOGIC: when one LDM filled all are required
  const pieceLdmError = useModifiedLdmLogic && someHaveLoadingMeter && !allHaveLoadingMeter;

  const loadingMeterError =
    (productDimensionError || sumError || requiredError || sumMaxError || pieceLdmError) && !disabled;

  return {
    someHaveLoadingMeter,
    loadingMeterSum,
    loadingMeterMin: someHaveLoadingMeter ? loadingMeterSum : min_total_loading_meter,
    loadingMeterMax: max_total_loading_meter,
    loadingMeterError,
    sumError,
    sumMaxError,
    pieceLdmError,
    disabled,
  };
};

export const shouldForceNonStackable = ({ height, dimensions }) => {
  const parsedHeight = parseInt(height);
  const forceNoneStackableHeight = parseFloat(dimensions?.force_none_stackable_height);
  const productMaxHeight = parseFloat(dimensions?.max_item_height);

  if (parsedHeight > forceNoneStackableHeight && parsedHeight <= productMaxHeight) {
    return true;
  }
  return false;
};

export const shouldDisableLoadingMeter = ({ height, dimensions }) => {
  const parsedHeight = parseInt(height);
  const noneStackableCWFromVolumeHeight = parseFloat(dimensions?.none_stackable_cw_from_volume_height);

  return parsedHeight <= noneStackableCWFromVolumeHeight;
};

export const volumeDimensionsFilled = ({ shipmentRow }) => {
  const parsedHeight = parseInt(shipmentRow.height.value);
  const parsedLength = parseInt(shipmentRow.length.value);
  const parsedWidth = parseInt(shipmentRow.width.value);

  const dimensionMissing = Number.isNaN(parsedHeight) || Number.isNaN(parsedLength) || Number.isNaN(parsedWidth);

  return !dimensionMissing;
};

export const volumeDimensionsEmpty = ({ shipmentRow }) => {
  const parsedHeight = parseInt(shipmentRow.height.value);
  const parsedLength = parseInt(shipmentRow.length.value);
  const parsedWidth = parseInt(shipmentRow.width.value);

  return Number.isNaN(parsedHeight) && Number.isNaN(parsedLength) && Number.isNaN(parsedWidth);
};

export const shouldUseAutomaticLdm = ({ height, width, length, dimensions, nonStackable }) => {
  const disableLoadingMeter = shouldDisableLoadingMeter({ height, dimensions });
  const dimensionsFilled = canCalculateLdm({ width, length });

  return !disableLoadingMeter && nonStackable && dimensionsFilled;
};

export const canCalculateLdm = ({ width, length }) => !Number.isNaN(parseInt(width)) && !Number.isNaN(parseInt(length));

export const calculateTotalLdm = ({ shipmentDetailsRows }) => {
  if (!shipmentDetailsRows) {
    return NaN;
  }

  if (shipmentDetailsRows.every(shipmentRow => Number.isNaN(parseFloat(shipmentRow?.loadingMeter?.value)))) {
    return NaN;
  }

  return parseFloat(
    shipmentDetailsRows
      ?.reduce((acc, shipmentRow) => {
        const parsedLoadingMeter = parseFloat(shipmentRow?.loadingMeter?.value?.replace(',', '.'));
        const parsedQuantity = parseInt(shipmentRow?.quantity?.value);

        if (!Number.isNaN(parsedLoadingMeter) && !Number.isNaN(parsedQuantity)) {
          acc += parsedLoadingMeter * parsedQuantity;
        }
        return acc;
      }, 0)
      .toFixed(2),
  );
};

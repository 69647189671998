import React from 'react';
import { locales } from 'locale/datePicker';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from 'globals/utils/formatting';
import DatePicker from 'react-datepicker';
import { Error } from 'components';
import { resetDateForLoadingUnloadingTimeDefiniteVas } from '../../ServiceDetails/ServiceDetails-helpers';

const CheckboxDateTime = ({
  option,
  context,
  index,
  groupIndex,
  errorId,
  serviceIndex,
  optionParentIndex,
  config,
  CONST,
}) => {
  const { t } = useTranslation();

  const handleChange = (date, time = false) => {
    const stateObj = {};
    const additionalServices = context.state.additionalServices;
    let additionalService = {};

    additionalService = JSON.parse(JSON.stringify(additionalServices[serviceIndex]));
    const additionalServiceParentOption = additionalService.groups[groupIndex].options[optionParentIndex];
    const additionalServiceOption = additionalServiceParentOption[index];

    if (time && date && additionalServiceOption.value) {
      const newDate = new Date(additionalServiceOption.value);
      newDate.setHours(date.getHours(), date.getMinutes(), 0);
      date = new Date(newDate);
    }

    if (config.vasSpecialCaseForDeliveryDate.indexOf(additionalService.code) !== -1) {
      date = resetUnloadingDateIfNotLogicalToLoadingDate(date);
    }

    if (date) {
      additionalServiceOption.value = date;
    } else {
      additionalServiceOption.value = new Date();
    }

    additionalServices[serviceIndex] = additionalService;
    resetPickupOnLoadingDateChange(date, additionalService, stateObj);

    context.updateState(stateObj);
  };

  const resetUnloadingDateIfNotLogicalToLoadingDate = date => {
    let newDate = date;
    const pickupDateVas = context.state.additionalServices.find(
      vas => config.vasSpecialCaseForPickupDate.indexOf(vas.code) !== -1,
    );

    if (pickupDateVas && pickupDateVas.groups && pickupDateVas.groups.length > 0) {
      pickupDateVas.groups.forEach(group => {
        if (group && group.options) {
          group.options.forEach(optionList => {
            optionList.forEach(optionS => {
              if (optionS.type === config.OptionFieldTypes.DateTime) {
                const loadingDate = new Date(optionS.value);
                const newUnloadingDate = new Date(date);

                if (loadingDate.getTime() > newUnloadingDate.getTime()) {
                  newDate = loadingDate;
                }
              }
            });
          });
        }
      });
    }

    return newDate;
  };

  const resetPickupOnLoadingDateChange = (date, additionalService, stateObj) => {
    if (config.vasSpecialCaseForPickupDate.indexOf(additionalService.code) !== -1) {
      stateObj.additionalServices = resetDateForLoadingUnloadingTimeDefiniteVas(context.state.additionalServices, date);
      stateObj.pickupDate = { value: new Date(date), error: false };
    }
  };

  const getErrorMessage = () => {
    let errorMessage = '';

    if (option.error) {
      errorMessage = t('general|errors|Please select date');
    }

    return <Error name={errorId ? errorId : 'OptionDateTime'} className="frc__input--error" message={errorMessage} />;
  };
  const language = localStorage.getItem(config.locale.localStorageVariable) || config.locale.defaultLanguage;
  const minDate = new Date();
  const maxDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
  const options = context.state.additionalServices[serviceIndex].groups[groupIndex].options[optionParentIndex];

  return (
    <div className="l-grid--w-100pc-s frc__as-option--wrapper">
      {t(`additionalServices|${option.optionCode}`)}
      <div className="l-grid frc__as-option--wrapper l-grid--middle-s l-grid--between-s">
        <div className="l-grid l-grid--w-100pc-s l-grid--w-60pc-s-m l-grid--w-50pc-s-l l-grid--w-70pc-m l-grid--w-100pc-m-s">
          <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s frc__datepicker">
            <DatePicker
              selected={new Date(options[index].value)}
              className="c-form--element-base c-form--element-input c-calculator--input-field"
              showDisabledMonthNavigation
              minDate={minDate}
              maxDate={maxDate}
              onChange={date => handleChange(date)}
              locale={locales[language]}
              dateFormat={DATE_FORMAT}
            />
          </div>
          <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s">
            <DatePicker
              selected={new Date(options[index].value)}
              onChange={date => handleChange(date, true)}
              className="c-form--element-base c-form--element-input c-calculator--input-field"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              locale={locales[language]}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              timeCaption={t('general|labels|inputs|Time')}
            />
          </div>
        </div>
      </div>
      {option.error && option.errorMessage ? getErrorMessage() : ''}
    </div>
  );
};

export default CheckboxDateTime;

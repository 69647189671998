import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { CONST, config } from '../../../../data-config';
import { Checkbox } from 'components';
import { updateFilterParamters } from '../FilterShipments-helpers';

function FilterStatus(props) {
  const { t } = useTranslation();

  const displayShipmentStatusCheckbox = () => {
    return props.context.state.filterByShipmentStatus.map((status, index) => {
      return (
        <div key={status.label}>
          <Checkbox
            index={index}
            label={t(`general|${status.label}`)}
            name={status.name}
            context={props.context}
            checked={status.value}
            isRequired={false}
            config={config}
            CONST={CONST}
            updateOnParent={(params, context) => updateFilterParamters(params, context, 'filterByShipmentStatus')}
          />
        </div>
      );
    });
  };

  return (
    <>
      <h4 className="margin-bottom-1 frc__filter_title">{t('general|Status')}</h4>
      {displayShipmentStatusCheckbox()}
    </>
  );
}

const areFunctionPropsEqual = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.context.state.filterByShipmentStatus) ===
    JSON.stringify(nextProps.context.state.filterByShipmentStatus)
  );
};

export default memo(FilterStatus, areFunctionPropsEqual);

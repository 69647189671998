import { config } from '../../data-config';

export const resetErrors = () => {
  return {
    oldPasswordDoesNotMatchAccountPasswordError: false,
    keycloakPasswordRulesDoesNotFitError: false,
    oldAndNewPasswordsMatchError: false,
    confirmationDoesNotMatchNewPasswordError: false,
    technicalError: false,
    success: false,
  };
};

export const canProceedToApiCall = params =>
  params.newPasswordIsValid && params.newPasswordsMatch && !params.newPasswordMatchesOld;

export const validatePasswords = context => {
  const { oldPassword, newPassword, newPasswordConfirmation } = context.state;
  const newPasswordIsValid = config.regEx.password.test(newPassword.value);
  const newPasswordConfirmationIsValid = config.regEx.password.test(newPasswordConfirmation.value);
  const newPasswordsMatch = newPassword.value === newPasswordConfirmation.value;
  const newPasswordMatchesOld = newPassword.value !== '' && newPassword.value === oldPassword.value;
  const stateToSet = {};
  const contextStateToSet = {};

  contextStateToSet.newPassword = {
    ...context.state.newPassword,
    error: !newPasswordIsValid,
  };

  contextStateToSet.newPasswordConfirmation = {
    ...context.state.newPasswordConfirmation,
    error: !newPasswordConfirmationIsValid,
  };

  context.updateState(contextStateToSet);

  // New passwords do not match
  if (!newPasswordsMatch && newPassword.value !== '' && newPasswordConfirmation.value !== '') {
    stateToSet.confirmationDoesNotMatchNewPasswordError = true;
  }

  // New password cannot be the same as old one
  if (newPasswordMatchesOld) {
    stateToSet.oldAndNewPasswordsMatchError = true;
  }

  return {
    stateToSet,
    validationParams: {
      oldPassword,
      newPassword,
      newPasswordIsValid,
      newPasswordsMatch,
      newPasswordMatchesOld,
    },
  };
};

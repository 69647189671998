import React, { Component } from 'react';
import { config, CONST } from '../../../data-config';
import { Select } from 'components';
import { Translation } from 'react-i18next';
import { EditIcon } from 'components';
import { ViewIcon } from 'components';
import { DeleteIcon } from 'components';
import { ModalView } from '../../ModalView/ModalView';
import { resetCountryPaginationCriteriasAndSearchAddressCall, deleteContactList } from '../../ActionUtils';
import { setCookie } from 'globals/utils/requests';

import './CustomCell.css';

export default class ActionCustomCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      modalError: false,
      loader: false,
      isViewContact: false,
    };
  }

  onModalActionClick = async (event, t) => {
    const { cellValue, action } = this.props;

    switch (event) {
      case CONST.EDIT:
        this.toggleModalView(action.edit(cellValue.id));
        break;

      case CONST.CANCEL:
        this.toggleModalView();
        break;

      case CONST.DELETE:
        this.setState({ loader: true }, async () => {
          const deleteActionError = await deleteContactList([this.props.cellValue.id]);

          if (!deleteActionError) {
            this.props.onShowSuccessToaster(`Contact Deleted Successfully`);
            await resetCountryPaginationCriteriasAndSearchAddressCall(this.props.context, false, t);
            this.toggleModalView();
          } else {
            this.setState({
              modalError: true,
              loader: false,
            });
          }
        });
        break;

      default:
        break;
    }
  };

  toggleModalView = (callback, isViewContact) => {
    this.setState(
      prevState => ({
        isModalOpen: !prevState.isModalOpen,
        modalError: false,
        loader: false,
        isViewContact: isViewContact || false,
      }),
      async () => {
        if (callback && typeof callback === 'function') {
          await callback();
        }
      },
    );
  };

  startBookingOptions = (params, t) => {
    if (params.value) {
      setCookie(config.bookingCookieName, this.props.cellValue.id, CONST.SECONDS_PER_MINUTE, 's');
      window.location = `${this.props.baseUrl}/${params.value}`;
    }
  };

  render() {
    return (
      <Translation>
        {t => (
          <>
            {this.state.isModalOpen && (
              <ModalView
                transactionId={this.props.context.state.transactionId}
                error={this.state.modalError}
                isViewContact={this.state.isViewContact}
                contactList={this.props.cellValue}
                onCloseModalAction={this.toggleModalView}
                actionClick={event => this.onModalActionClick(event, t)}
                loader={this.state.loader}
              />
            )}
            <div className="frc__column-wrapper l-grid--w-100pc-s-all l-grid--w-100pc-m margin-bottom-0 ">
              <Select
                id={this.props.cellValue.id + 'Action'}
                className="frc-select--wrapper"
                name="startABooking"
                selectClassName="c-form--element-select"
                values={
                  localStorage.getItem('country')?.toUpperCase() === 'SE'
                    ? config.startABookingOptionsSe
                    : config.startABookingOptionsGlobal
                }
                context={this.props.context}
                config={config}
                CONST={CONST}
                afterUpdate={(context, params) => this.startBookingOptions(params, t)}
              />
            </div>
            <div className="l-grid">
              <div
                className="frc__operation-icon frc__icon-pointer frc__operation-icon-width"
                onClick={() => this.toggleModalView(null, true)}
              >
                <ViewIcon />
                <span>{t('general|labels|buttons|View')}</span>
              </div>

              <div
                className="frc__operation-icon frc__icon-pointer frc__operation-icon-width "
                onClick={() => this.props.action.edit(this.props.cellValue.id)}
              >
                <EditIcon />
                <span>{t('general|labels|buttons|Edit')}</span>
              </div>

              <div
                className="frc__operation-icon frc__icon-pointer frc__operation-icon-width"
                onClick={() => this.toggleModalView()}
              >
                <DeleteIcon />
                <span>{t('general|labels|buttons|Delete')}</span>
              </div>
            </div>
          </>
        )}
      </Translation>
    );
  }
}

import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import getBaseUrl from 'globals/utils/getUrlBase';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { envConfig } from '../../lib/envConfig/envConfig';

export const useCaptcha = () => {
  const [img, setImg] = useState();
  const [captchaToken, setCaptchaToken] = useState();
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);

  const loadCaptcha = async () => {
    try {
      setLoadingCaptcha(true);
      const params = {
        headers: {
          Referer: getBaseUrl(),
        },
      };

      const cacheBreak = new Date().getTime().toString();

      const result = await apiRequest(apiLinks.getCaptcha + '?&_=' + cacheBreak, 'GET', params);
      if (result.status === 200) {
        const image = result.data.image;
        setImg(image);
        const captchaToken = result.data.token;
        setCaptchaToken(captchaToken);
      }
      setLoadingCaptcha(false);
    } catch (error) {
      setLoadingCaptcha(false);
    }
  };

  useEffect(() => {
    loadCaptcha();
  }, []);

  return { img, captchaToken, loadCaptcha, loadingCaptcha };
};

export const captchaTokenVisible = () => {
  const environment = envConfig().REACT_APP_ENV;
  const enabledEnvironments = ['LOCAL', 'DEV', 'SIT', 'UAT', 'TEST'];
  return environment && enabledEnvironments.includes(environment.toUpperCase());
};

export const Captcha = ({ img, captchaToken, loadCaptcha, loadingCaptcha }) => {
  const { t } = useTranslation();
  const captchaTokenEnv = captchaTokenVisible() ? captchaToken : null;

  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <Typography variant="body1" sx={{ fontSize: '24px', fontWeight: 900 }}>
        {t('general|To make sure you are not a robot please do the following')}
      </Typography>
      <Typography variant="body1" sx={{ fontSize: '24px', fontWeight: 900 }}>
        {t('general|Rewrite the code')}
      </Typography>
      <Box
        sx={{
          my: '16px',
        }}
      >
        <img src={'data:image/png;base64, ' + img} alt="Captcha" data-testid={captchaTokenEnv} />
      </Box>
      <Button
        variant="outlined"
        onClick={() => {
          loadCaptcha();
        }}
        disabled={loadingCaptcha}
        data-testid="reloadBtn"
      >
        {t('general|labels|buttons|Reload')}
      </Button>
    </Box>
  );
};

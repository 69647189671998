import { globalConfig, GLOBAL_CONST } from 'globals/data-config-global';

export const PRINTER_CONSTANTS = {
  API_DOMAIN: 'editProfile',
  LABEL_PRINTER_VALUE: 'Label',
  LABEL_COMPACT_PRINTER_VALUE: 'labelCompact',
  LABEL_2X_PORTRAIT_A4_PRINTER_VALUE: 'Label2xPortraitA4',
  LABEL_3X_LANDSCAPE_A4_PRINTER_VALUE: 'Label3xLandscapeA4',
};

const componentConfig = {
  maxAllowedPhoneNumbers: 3,
  maxPhoneNumberDigits: 17,
  maxAllowedAccountNumbers: 10,
  maxAllowedPalletAccountNumbers: 99999,
  urlRegexPattern: /^[A-Za-z]{2}/g,

  printerSettingsOptions: [
    { value: PRINTER_CONSTANTS.LABEL_PRINTER_VALUE,
      name: 'Label printer' },
    {
      value: 'labelCompact',
      label: 'editProfile|labelCompact',
      printTopMargin: 0,
      printLeftMargin: 0,
      printPadding: 0,
    },
    {
      value: PRINTER_CONSTANTS.LABEL_2X_PORTRAIT_A4_PRINTER_VALUE,
      name: 'A4 – Vertical, 2 labels/page',
      printerTopMargin: 50,
      printerLeftMargin: 6,
      printerPadding: 5,
    },
    {
      value: PRINTER_CONSTANTS.LABEL_3X_LANDSCAPE_A4_PRINTER_VALUE,
      name: 'A4 – Horizontal, 3 labels/page',
      printerTopMargin: 5,
      printerLeftMargin: 2,
      printerPadding: 2,
    },
  ],
};

export const config = Object.assign({}, globalConfig, componentConfig);
export const CONST = Object.assign({}, GLOBAL_CONST, PRINTER_CONSTANTS);

import React from 'react';
import { toCamelCase } from 'globals/utils/formatting';
import './Button.css';

export default function Button({ id, label, submit, disabled, className, dataTestId }) {
  return (
    <button id={toCamelCase(id || label) + 'Btn'} onClick={submit} disabled={disabled} className={className} data-testid={dataTestId}>
      {label}
    </button>
  );
}

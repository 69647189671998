import { config } from '../data-config';

export const formatTMSAccountData = data => {
  const formattedData = {
    tmsAccountsWaiting: [],
    tmsAccountsApproved: [],
  };

  if (data.tmsAccounts) {
    data.tmsAccounts.forEach(tmsAccount => {
      if (tmsAccount.status === 'WAITING') {
        formattedData.tmsAccountsWaiting.push({
          accountNumber: tmsAccount.accountNumber,
          accountReference: tmsAccount.accountReference,
          status: tmsAccount.status,
        });
      } else {
        formattedData.tmsAccountsApproved.push({
          accountNumber: tmsAccount.accountNumber,
          accountReference: tmsAccount.accountReference,
          status: tmsAccount.status,
        });
      }
    });
  }

  return formattedData;
};

export const getTMSAccountsData = data => {
  const formattedData = [];

  if (data.tmsAccounts) {
    data.tmsAccounts.forEach(tmsAccount => {
      if (tmsAccount.status === 'APPROVED') {
        formattedData.push({
          value: tmsAccount.accountNumber,
          name: tmsAccount.accountNumber + tmsAccount.accountReference ? ' ' + tmsAccount.accountNumber : '',
        });
      }
    });
  }

  return formattedData;
};

export const getPalletAccountsData = data => {
  const formattedData = [];

  if (data.palletAccounts) {
    data.palletAccounts.forEach(palletAccount => {
      if (palletAccount.status === 'APPROVED') {
        formattedData.push({
          value: palletAccount.accountNumber,
          name: palletAccount.accountNumber + palletAccount.accountReference ? ' ' + palletAccount.accountNumber : '',
        });
      }
    });
  }

  return formattedData;
};

export const formatPalletAccountData = data => {
  const formattedData = {
    palletAccountsWaiting: [],
    palletAccountsApproved: [],
  };

  if (data.palletAccounts) {
    data.palletAccounts.forEach(palletAccount => {
      if (palletAccount.status === 'WAITING') {
        formattedData.palletAccountsWaiting.push({
          accountNumber: palletAccount.accountNumber,
          palletBalance: palletAccount.palletBalance,
          status: palletAccount.status,
        });
      } else {
        formattedData.palletAccountsApproved.push({
          accountNumber: palletAccount.accountNumber,
          palletBalance: palletAccount.palletBalance || null,
          status: palletAccount.status,
        });
      }
    });
  }

  return formattedData;
};

const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const formatUserData = data => {
  const formattedData = {
    email: { value: data.user.email || '' },
    newsletter: { value: data.user.newsletter },
    country: { value: data.user.countryCode || '', error: false },
    company: { value: data.user.companyName || '', error: false },
    vatNumber: { value: data.user.vatNumber || '', error: false },
    name: { value: data.user.name || '', error: false },
    street: { value: data.user.street || '', error: false },
    postalCode: { value: data.user.postalCode || '', error: false },
    city: { value: data.user.city || '', error: false },
    phoneNumbers: [],
    tmsAccountsWaiting: [],
    tmsAccountsApproved: [],
    palletAccountsWaiting: [],
    palletAccountsApproved: [],
    instructions: { value: data.user.instructions || '', error: false },
    confirmationEmail: { value: data.user.email || '', error: false },
    numberOfPallets: { value: data.user.numberOfPallets || config.defaultNumberOfPallets, error: false },
    tmsAccounts: [],
    tmsAccountNumber: { value: '', error: false },
    palletAccountNumber: { value: '', error: false },
    palletAccounts: [],
  };
  let tempFormat = {};

  if (data.user.phoneNumbers) {
    data.user.phoneNumbers.forEach(phoneNumber => {
      const code = phoneNumber.number.split('-')[0];
      const number = phoneNumber.number.split('-')[1];

      formattedData.phoneNumbers.push({ code: code, number: number, error: false });
    });
  }

  tempFormat = formatTMSAccountData(data);

  tempFormat.tmsAccounts = getTMSAccountsData(data);
  formattedData.tmsAccounts = [...tempFormat.tmsAccounts];
  tempFormat.palletAccounts = getPalletAccountsData(data);
  formattedData.palletAccounts = [...tempFormat.palletAccounts];

  if (formattedData.tmsAccounts && formattedData.tmsAccounts[0]) {
    formattedData.tmsAccountNumber = { ...formattedData.tmsAccounts[0] };
  }

  if (formattedData.palletAccounts && formattedData.palletAccounts[0]) {
    formattedData.palletAccountNumber = { ...formattedData.palletAccounts[0] };
  }

  tempFormat.palletAccountNumber = getParameterByName('accountNumber');
  if (tempFormat.palletAccountNumber && tempFormat.palletAccountNumber.length === 6) {
    formattedData.palletAccountNumber = { name: tempFormat.palletAccountNumber, value: tempFormat.palletAccountNumber };
  }

  return formattedData;
};

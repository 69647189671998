import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { CONST } from '../../data-config';
import { Error } from 'components';
import TemplatesSearch from '../TemplatesSearch/TemplatesSearch';
import TemplatesResultGrid from '../TemplatesResultGrid/TemplatesResultGrid';
import { resetTemplate } from './TemplateList-helpers';
import { fetchProductsUserAccountTemplateList } from '../CommonUtils';
import { getTransactionId } from 'globals/utils/generators';
import getBaseUrl from 'globals/utils/getUrlBase';

import './TemplateList.css';
import { ROUTES } from 'router';

export default class TemplateList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
    };
  }

  showTemplate = id => {
    const { context } = this.props;
    let stateToSet = {};

    if (id) {
      // set when edit template no api error
      if (id) {
        stateToSet.templateData = context.state.templateList.find(template => template.id === id);
        stateToSet.id = id;
        stateToSet.editTemplateError = false;
      }
    } else {
      // add template
      stateToSet = resetTemplate(this.props.t);
    }

    //set appropriate state and navigate to Template
    context.updateState(stateToSet, this.props.onToggleTemplate());
  };

  componentDidMount = async () => {
    const { context } = this.props;
    // call template list
    if (context.state.transactionId === '') {
      this.setState({ loader: true }, async () => {
        const stateToSet = await fetchProductsUserAccountTemplateList(context.state);
        stateToSet.transactionId = getTransactionId(CONST.PORTAL_TEMPLATES);
        context.updateState(stateToSet);
        this.setState({ loader: false });
      });
    }
  };

  render() {
    const { context } = this.props;

    return (
      <div className="frc__template-wrapper">
        <Translation>
          {t => (
            <>
              <h3 className="margin-bottom-3">
                <span className="frc__app-header-saved-templates">{t(`portalTemplates|Saved Templates`)}</span>
              </h3>

              <div className="frc__templates-main">
                <TemplatesSearch
                  context={context}
                  onShowTemplate={() => this.showTemplate()}
                  onShowSuccessToaster={this.props.onShowSuccessToaster}
                />

                {context.state.apiError ? (
                  <Error
                    name={'templateSearchError'}
                    className="frc__input--error"
                    message={t('general|errors|General error')}
                  />
                ) : (
                  <TemplatesResultGrid
                    context={context}
                    baseUrl={getBaseUrl(ROUTES.portal)}
                    translation={t}
                    onToggleTemplate={this.toggleTemplate}
                    onShowTemplate={this.showTemplate}
                    onShowSuccessToaster={this.props.onShowSuccessToaster}
                  />
                )}
              </div>
            </>
          )}
        </Translation>
        <div
          id="frc_loader"
          className={`frc__generic--loader-overlay  ${context.state.loader || this.state.loader ? 'is-loading' : ''}`}
        />
      </div>
    );
  }
}

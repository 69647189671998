import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from './Accordion/Accordion';
import { CONST } from '../../data-config';
import { formatDate, formatBrowserDateTimeReadable } from 'globals/utils/formatting';
import { useNavigate } from 'react-router-dom';
import StatusCustomCell from '../ShipmentDataGrid/CustomCell/StatusCustomCell';
import AddressCustomCell from '../ShipmentDataGrid/CustomCell/AddressCustomCell';
import SummaryCustomCell from '../ShipmentDataGrid/CustomCell/SummaryCustomCell';
import { getAccountInfo, routeDetails, formatPieceForDisplay } from './ShipmentDetails-helpers';
import AdditionalServices from './AdditionalServices';
import {PiecesDetails}  from './PiecesDetails/PiecesDetails';

import './ShipmentDetails.css';
import { useCountryLanguage } from '../../../hooks/useCountryLanguage';
import { PriceBreakdownContent } from "../../../portal-order/components/ReviewAndBook/PriceBreakdown/PriceBreakdown";
import { priceBreakdownToQuoteSummary } from "../../../portal-order/helpers/priceBreakdown";
import { TextArea } from "../../../components";
import { shouldUseCommitedDelivery } from "../../../globals/helpers/product";

export default function ShipmentDetails(props) {
  const [pieces, setPieces] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language: languageCode } = useCountryLanguage();
  const { savedShipment } = props;
  const accountInfo = savedShipment && getAccountInfo(savedShipment);
  const {
    pickupAddress = false,
    deliveryAddress = false,
    differentPickupAddress = false,
    differentDeliveryAddress = false,
    servicePoint = false,
  } = routeDetails(savedShipment);
  const orderDate = savedShipment.createdAt ? formatDate(savedShipment.createdAt) : '';

  const isCommitedDelivery = shouldUseCommitedDelivery(savedShipment.productID);

  const navigateBackToSourcePage = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'test') window.scrollTo(0, 0);

    if (savedShipment.savedShipmentPieces && savedShipment.savedShipmentPieces.length > 0) {
      setPieces(formatPieceForDisplay(savedShipment.savedShipmentPieces));
    }
  }, [savedShipment.savedShipmentPieces]);

  return (
    <div className="frc__generic-section--wrapper frc__shipment-detail-close-btn">
      <h3 className="margin-bottom-1">{t('general|Shipment Details')}</h3>

      <div className="frc__dialog-close-button">
        <button className="frc__button--delete--darker" onClick={navigateBackToSourcePage}>
          X
        </button>
      </div>

      {/**section 1 */}
      <div className="l-grid l-grid--w-100pc-s frc__generic--background-white frc__generic-section--wrapper frc__generic-row--wrapper frc__shipment-details-sec margin-bottom-1">
        <div className=" l-grid--w-100pc-s  l-grid--w-50pc-m">
          {savedShipment.shipmentNumber && (
            <h5 className="margin-bottom-0">
              {t('general|Shipment ID')}: {savedShipment.shipmentNumber}
            </h5>
          )}
        </div>
        <div className=" l-grid--w-100pc-s  l-grid--w-50pc-m">
          <h5 className="margin-bottom-0">
            {t('general|Status')}
            {': '}
            <StatusCustomCell cellValue={{ status: savedShipment.status || '' }} t={t} />
          </h5>
        </div>

        <div className=" l-grid--w-100pc-s  l-grid--w-50pc-m">
          {savedShipment.schedulePickup && savedShipment.pickupID && (
            <div>
              {t('general|Pickup ID')}: {savedShipment.pickupID}
            </div>
          )}
          {orderDate && (
            <div>
              {t('general|Ordered Date')}: {orderDate}
            </div>
          )}
          <div>
            {`${t('general|Pickup Date')}: ${
              savedShipment?.pickupDate && savedShipment?.schedulePickup
                ? savedShipment.pickupDate
                : t('general|noPickupDateOrdered')
            }`}
          </div>

          {savedShipment.shipmentDescription && (
            <div className="">
              <br />
              <div>{t(`general|Actual Status`)}:</div>

              <div className="l-grid">
                <div>{t(`general|${savedShipment.shipmentDescription}`)} </div>

                {savedShipment.terminalCountry && savedShipment.terminalName && (
                  <div>
                    &nbsp;
                    {
                      <strong>
                        {savedShipment.terminalCountry}-{savedShipment.terminalName}
                      </strong>
                    }
                    &nbsp;
                    <i>
                      {formatBrowserDateTimeReadable(savedShipment.statusUpdated, savedShipment.statusUpdatedHasTime)}
                    </i>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className=" l-grid--w-100pc-s  l-grid--w-50pc-m">
          <div>
            {isCommitedDelivery ? t('general|committedDeliveryDate') : t('general|Estimated Delivery By')}:
            {' '}
            {savedShipment.deliveryDate === 'ltc_error' ? t('general|LTC Delivery date unavailable') : savedShipment.deliveryDate}
          </div>
          {savedShipment.status === CONST.DELIVERED && (
            <div>
              {t('general|Received By')}: {savedShipment.receivedBy || ''}
            </div>
          )}
        </div>

        <div className=" l-grid--w-100pc-s  l-grid--w-50pc-m">
          {savedShipment.shipmentNumber && (
            <TextArea
              label={t(`portalOrder|Pickup Instructions`)}
              textAreaClassName="frc__textarea--small instructions"
              name="pickupInstructions"
              disabled={true}
              value={savedShipment.pickupInstruction}
            />
          )}
        </div>
        <div className=" l-grid--w-100pc-s  l-grid--w-50pc-m">
          {savedShipment.shipmentNumber && (
            <TextArea
              label={t(`general|Delivery Instructions`)}
              textAreaClassName="frc__textarea--small instructions"
              name="deliveryInstructions"
              disabled={true}
              value={savedShipment.deliveryInstruction}
            />
          )}
        </div>
      </div>

      {/**section 2 */}
      <div className="l-grid l-grid--w-100pc-s  frc__generic--background-white frc__generic-section--wrapper frc__generic-row--wrapper  frc__shipment-details-sec margin-bottom-1">
        <div className=" l-grid--w-100pc-s  l-grid--w-50pc-m margin-bottom-1">
          <h4>{t('general|From')}</h4>
          {pickupAddress && (
            <AddressCustomCell isSender={true} cellValue={{ sender: pickupAddress }} isShipmentDetail={true} t={t} />
          )}
        </div>

        <div className=" l-grid--w-100pc-s  l-grid--w-50pc-m margin-bottom-1">
          <h4>{t('general|To')}</h4>
          {deliveryAddress && (
            <AddressCustomCell cellValue={{ receiver: deliveryAddress }} isShipmentDetail={true} t={t} />
          )}
        </div>

        <div className=" l-grid--w-100pc-s  l-grid--w-50pc-m margin-bottom-1">
          {differentPickupAddress && (
            <AddressCustomCell
              isSender={true}
              cellValue={{ sender: differentPickupAddress }}
              isShipmentDetail={true}
              t={t}
              diffSenderReceiver={true}
            />
          )}
        </div>

        {servicePoint ? (
          <div
            className={`l-grid--w-100pc-s  margin-bottom-1  
                   ${differentPickupAddress ? 'l-grid--w-50pc-m' : 'frc__shipment-details-service-point'}`}
          >
            <AddressCustomCell
              cellValue={{ receiver: servicePoint }}
              isShipmentDetail={true}
              t={t}
              servicePoint={true}
              diffSenderReceiver={true}
            />
          </div>
        ) : (
          differentDeliveryAddress && (
            <div className=" l-grid--w-100pc-s  l-grid--w-50pc-m margin-bottom-1">
              <AddressCustomCell
                cellValue={{ receiver: differentDeliveryAddress }}
                isShipmentDetail={true}
                t={t}
                diffSenderReceiver={true}
              />
            </div>
          )
        )}
      </div>

      {/**section 3 */}
      <div className="l-grid l-grid--w-100pc-s  frc__generic--background-white frc__generic-section--wrapper frc__generic-row--wrapper frc__shipment-details-sec margin-bottom-1">
        <div className=" l-grid--w-100pc-s  l-grid--w-50pc-m  margin-bottom-1">
          <h4>{t('general|Shipment Details')}</h4>
          {savedShipment.savedShipmentPieces.length > 0 && (
            <SummaryCustomCell cellValue={savedShipment} isShipmentDetail={true} t={t} />
          )}
        </div>

        <div className=" l-grid--w-100pc-s  l-grid--w-50pc-m">
          <h4>{t(`dashboard|Account Information`)}</h4>
          <strong>{`${t(`general|${savedShipment.whoPay}`)} `}</strong>
          {savedShipment && t('general|pay the shipment')}
          {accountInfo &&
            Object.keys(accountInfo).map(accountKey => (
              <div key={accountKey}>
                {t(`${CONST.API_DOMAIN}|${accountKey}`)} : {accountInfo[accountKey]}
              </div>
            ))}
        </div>
      </div>

      {/**section 4 */}
      <div className="l-grid l-grid--w-100pc-s  frc__generic--background-white frc__generic-section--wrapper frc__generic-row--wrapper frc__shipment-details-sec margin-bottom-1">
        <h4>{t(`general|Additional Services`)}</h4>
        {savedShipment.savedShipmentVass && savedShipment.savedShipmentVass.length > 0 && (
          <AdditionalServices
            t={t}
            deliveryAddress={differentDeliveryAddress || deliveryAddress}
            data={savedShipment.savedShipmentVass}
            languageCode={languageCode}
          />
        )}
      </div>

      {/**section 5 */}
      <div className=" frc__generic--background-white frc__generic-section--wrapper frc__shipment-details-sec margin-bottom-1">
        {pieces ? (
          <Accordion>
            <div
              className="l-grid l-grid--w-100pc-s  frc__generic-row--wrapper margin-bottom-1"
              showTitle={t('general|Show Piece Details')}
              hideTitle={t('general|Hide Piece Details')}
            >
              <PiecesDetails data={pieces} />
            </div>
          </Accordion>
        ) : (
          <h4 className="margin-bottom-0"> {t('general|No piece details to show')}</h4>
        )}
      </div>

      <div className="l-grid l-grid--w-100pc-s  frc__generic--background-white frc__generic-section--wrapper frc__generic-row--wrapper frc__shipment-details-sec margin-bottom-1">
        <div className="l-grid--w-100pc-s  l-grid--w-100pc-m  margin-bottom-1">
          <h4>{t(`general|Price Breakdown`)}</h4>
          {savedShipment.savedShipmentPriceBreakdown && (
            <PriceBreakdownContent
              quoteSummary={priceBreakdownToQuoteSummary(savedShipment.savedShipmentPriceBreakdown)}
              date={savedShipment.createdAt}
            />
          )}
        </div>
      </div>

      <div className="l-grid l-grid--w-100pc-s  frc__generic--background-white frc__generic-section--wrapper frc__generic-row--wrapper frc__shipment-details-sec">
        <div>
          <h4>{t(`general|Upload Documents`)}</h4>
            {savedShipment.savedShipmentUploadedFiles &&
              savedShipment.savedShipmentUploadedFiles.map(file => (
                  <div className={'frc__summary--row l-grid--left-s'}>
                    <div key={file.fileName}>{file.fileName}</div>
                    <div key={file.fileName}>({Math.ceil(file.fileSize / 1024)} kb)</div>
                  </div>
              ))}
        </div>
      </div>
    </div>
  );
}

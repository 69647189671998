import { config } from '../../../data-config';
import { checkFieldAndReturnState, checkGroupOfFieldsAndReturnState, matchRegEx } from 'globals/utils/fieldValidations';
import {
  getLengthForVAS,
  getRegEx,
  getRegexForDecimalNumber,
} from '../DeliveryOptions/AdditionalServices/AdditionalServicesOptions/AdditionalService-helpers';

export const additionalServicesOptionsValidations = (hasErrors, additionalServices) => {
  let getState = {};
  let adError = false;

  Array.isArray(additionalServices) &&
    additionalServices &&
    // Loop through all Additional Services
    additionalServices.map(service => {
      if (service.value && service.optionsSchema && service.optionsSchema.length) {
        // Loop through all options for the current Additional Service
        service.optionsSchema = service.optionsSchema.map(option => {
          let conditionalGroupError = true;

          if (!option.disabled && option.value) {
            if (config.OptionFieldTypes.inputTypes[option.type]) {
              let decimalValidation;
              getState = checkFieldAndReturnState({
                value: option.value || '',
                min: option.min,
                max: option.max,
                regEx:
                  option.type === config.OptionFieldTypes.inputTypes.Email ? config.regEx.email : getRegEx(option.type),
                lengthCheck: [
                  new RegExp(
                    `^.{${option.minLength ? option.minLength : 0},${getLengthForVAS(option.type, option.maxLength)}}$`,
                  ),
                ],
                isRequired: false,
              });

              adError = getState.error || adError || (decimalValidation && decimalValidation.error ? true : false);

              if (
                option.type === config.OptionFieldTypes.inputTypes.Decimal &&
                option.value &&
                option.value.indexOf('.') !== -1
              ) {
                const regexDecimalPattern = getRegexForDecimalNumber(option);
                const decimalValidationError = !matchRegEx(option.value, regexDecimalPattern);
                option.error = option.error || decimalValidationError;
              }
              getState.error ? (option.error = true) : (option.error = false);
              option.errorMessage = service.code + option.optionCode + 'Error';
            }

            if (option.type === config.OptionFieldTypes.Telephone) {
              getState = checkGroupOfFieldsAndReturnState({
                values: option.value,
                fields: [
                  {
                    name: 'code',
                    isRequired: false,
                  },
                  {
                    name: 'number',
                    regEx: config.regEx.number,
                    lengthCheck: [RegExp(`^.{1,${config.maxPhoneNumberDigits}}$`)],
                    isRequired: false,
                    calculatePhoneLengthCheck: true,
                  },
                ],
              });
              adError = getState.error || adError;
              option.value = [...getState.values];
              option.error = getState.error;
            }
          }

          // Conditional group check
          if (option.conditionalGroup && option.conditionalGroup.length > 0) {
            service.optionsSchema.forEach(opt => {
              if (option.conditionalGroup.indexOf(opt.optionCode) !== -1) {
                if (opt.value && !Array.isArray(opt.value)) conditionalGroupError = false;
                else if (
                  opt.value &&
                  Array.isArray(opt.value) &&
                  ((opt.value[0].code && opt.value[0].number) || opt.value[0].value)
                ) {
                  conditionalGroupError = false;
                }
              }
            });

            service.errors[option.conditionalGroup.join('')] = conditionalGroupError;
            if (conditionalGroupError) {
              adError = true;
            }
          }

          return option;
        });

        service.error =
          service.optionsSchema.some(optsSchema => optsSchema.error) ||
          (service.errors && Object.values(service.errors)
            ? Object.values(service.errors).indexOf(true) !== -1
            : false);
      }

      return service;
    });

  return [adError || hasErrors, additionalServices];
};

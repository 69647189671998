import { globalConfig, GLOBAL_CONST } from 'globals/data-config-global';
import { LANGUAGES } from '../locale/config';
import { RECEIVER_PAYER, SENDER_PAYER, SERVICE_POINT_PRODUCT_CODE, THIRD_PARTY_PAYER } from '../globals/constants';

const componentConfig = {
  // public pickup is SE only feature
  defaultCountry: 'SE',
  locale: {
    defaultLanguage: LANGUAGES.en,
    localStorageVariable: 'language',
  },
  domesticCountriesPublicPickup: [
    {
      value: '',
      name: 'Select country',
    },
    {
      value: 'SE',
      example: '26234',
    },
  ],
  optionSchemaToBeSentAsObject: true,
  optionsSchemaExpectedAsArray: ['customsHandlingStandard'], // this case is valid only optionSchemaToBeSentAsObject is true
  OptionFieldTypes: {
    inputTypes: {
      Number: 'Number',
      Decimal: 'Decimal',
      NumericText: 'NumericText',
      Alphanumerical: 'Alphanumerical',
      Email: 'Email',
      Currency: 'Currency',
    },
    Select: 'Select',
    LtcDate: 'LTCdate',
    Telephone: 'Telephone',
    SubHeading: 'SubHeading',
    DateTime: 'DateTime',
    TickBox: 'TickBox',
    Hidden: 'Hidden',
  },
  dhlPALL: 'dhlPall',
  debounceDelay: 750,
  internationalProducts: ['112', '109'],
  vasSpecialCaseForPickupDate: ['timeDefiniteLoading'],
  vasSpecialCaseForDeliveryDate: ['timeDefiniteUnloading'],
  loadingUnloadingDatesSpecificProduct: ['dhlParti'],
  pageLinks: {
    homeFooter: 'home/footer',
  },
  lengthCheck: {
    tenDigit: [/^.{1,10}$/],
  },

  maxAllowedPhoneNumbers: 1,
  maxAllowedAccountNumbers: 10,
  maxAllowedPalletAccountNumbers: 99999,
  maxAllowedShipmentDetailsRows: 40,
  maxPhoneNumberDigits: 17,
  maxInstructionsLength: 140,
  maxEmailLength: 64,
  maxDefaultInputLength: 35,
  dhlStyckeHomeDeliveryProduct: ['dhlHomeDelivery', 'dhlStycke'],
  checkGroups: {
    1: 'products',
  },

  allowedProducts: [
    // empty === all
    { code: '212', mapName: 'dhlParti' },
    { code: '210', mapName: 'dhlPall' },
    { code: '211', mapName: 'dhlStycke' },
    { code: '102', mapName: 'dhlPaket' },
    { code: '112', mapName: 'dhlParcelConnectPlus' },
    { code: SERVICE_POINT_PRODUCT_CODE, mapName: 'dhlServicePoint' },
    { code: '109', mapName: 'dhlParcelConnect' },
    { code: '401', mapName: 'dhlHomeDelivery' },
    // {code: "107",mapName: "dhlParcelReturnConnect"},
    // {code: "104",mapName: "dhlServicePointRetur"},
    // {code: "502",mapName: "dhlHomeDeliveryReturn"},
    // {code: "202",mapName: "dhlEuroConnect"},
    // {code: "232",mapName: "dhlEuroConnectPlus"},
    // {code: "233",mapName: "dhlEurapid"}
  ],

  shipmentTypesFields: {
    'full pallet': {
      shipmentType: { value: 'full pallet', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false, disabled: true, hidden: true },
      width: { value: '', error: false, disabled: true, hidden: true },
      // length: { value: '120', error: false, disabled: true, hidden: true },
      // width: { value: '80', error: false, disabled: true, hidden: true },
      height: { value: '', error: false, hidden: true },
      weight: { value: '', error: false, required: true },
      volume: { value: '', error: false, disabled: true, hidden: true },
      loadingMeter: { value: '', error: false, hidden: true },
      // palletPlace: { value: "", error: false, hidden: true },
      palletPlace: { value: 1, error: false, hidden: false, disabled: true },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: true },
    },
    'half pallet': {
      shipmentType: { value: 'half pallet', error: false },
      quantity: { value: 1, error: false, required: true },
      // length: { value: '60', error: false, disabled: true, required: false, hidden: true },
      // width: { value: '80', error: false, disabled: true, required: false, hidden: true },
      length: {
        value: '',
        error: false,
        disabled: true,
        required: false,
        hidden: true,
      },
      width: {
        value: '',
        error: false,
        disabled: true,
        required: false,
        hidden: true,
      },
      height: { value: '', error: false, required: false, hidden: true },
      weight: { value: '', error: false, required: true },
      volume: { value: '', error: false, hidden: true },
      loadingMeter: { value: '', error: false, hidden: true },
      // palletPlace: { value: "", error: false, hidden: true },
      palletPlace: { value: 0.5, error: false, hidden: false, disabled: true },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: true },
    },
    parcel: {
      shipmentType: { value: 'parcel', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false, hidden: true },
      width: { value: '', error: false, hidden: true },
      height: { value: '', error: false, hidden: true },
      volume: { value: '', error: false },
      palletPlace: { value: '', error: false, hidden: true },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false, hidden: true },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: true },
    },
    pallet: {
      shipmentType: { value: 'pallet', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    unspecified: {
      shipmentType: { value: 'unspecified', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: '', error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    tanktainer: {
      shipmentType: { value: 'tanktainer', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    'ro/bobbin': {
      shipmentType: { value: 'ro/bobbin', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    bundle: {
      shipmentType: { value: 'bundle', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    'bag/sack': {
      shipmentType: { value: 'bag/sack', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    bale: {
      shipmentType: { value: 'bale', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    'box/case': {
      shipmentType: { value: 'box/case', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    can: {
      shipmentType: { value: 'can', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    cage: {
      shipmentType: { value: 'cage', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    crate: {
      shipmentType: { value: 'crate', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    'carton/package': {
      shipmentType: { value: 'carton/package', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    dolly: {
      shipmentType: { value: 'dolly', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    'drum/barrel': {
      shipmentType: { value: 'drum/barrel', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    ibc: {
      shipmentType: { value: 'ibc', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    octabin: {
      shipmentType: { value: 'octabin', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    'pallet gl': {
      shipmentType: { value: 'pallet gl', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    'pallet - double extra large': {
      shipmentType: { value: 'pallet - double extra large', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    'euro-pallet': {
      shipmentType: { value: 'euro-pallet', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    'half pallet gl': {
      shipmentType: { value: 'half pallet gl', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    'pallet - large': {
      shipmentType: { value: 'pallet - large', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    'pallet - oversized': {
      shipmentType: { value: 'pallet - oversized', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    'pallet - extra large': {
      shipmentType: { value: 'pallet - extra large', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    'pallet euro size': {
      shipmentType: { value: 'pallet euro size', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    'skeleton box': {
      shipmentType: { value: 'skeleton box', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
    'garment roller': {
      shipmentType: { value: 'garment roller', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      palletPlace: { value: '', error: false },
      longShipment: { value: false, error: false },
      nonStackable: { value: false, error: false, hidden: true },
    },
  },

  getAdditionalServicesFromAPI: true,

  additionalServicesAllowed: {
    212: [
      // DHL PARTI
      'dangerousGoodsLimitedQuantity',
      'thermoWarm',
      'thermoCold',
      'tailLiftLoading',
      'tailLiftUnloading',
      'timeDefiniteLoading',
      'timeDefiniteUnloading',
      'deliveryWithoutProofOfDelivery',
      'preAdviceByDriver',
      'notification', // added according to CR-77 FCP-2139
      'dangerousGoods', // CR-93 FCP-3328
      'truck', // CR-93 FCP-3328
    ],
    210: [
      // DHL PALL
      'dangerousGoodsLimitedQuantity',
      'customsCustomersOwnDeclaration',
      'priorityServiceG7',
      'priorityServiceG10',
      'priorityServiceG12',
      'thermoWarm',
    ],
    211: [
      // DHL STYCKE
      'dangerousGoodsLimitedQuantity',
      'priorityServiceG7',
      'priorityServiceG10',
      'priorityServiceG12',
      'thermoWarm',
    ],
    102: [
      // DHL Paket
      'dangerousGoodsLimitedQuantity',
      'priorityServiceG7',
      'priorityServiceG10',
      'priorityServiceG12',
    ],
    112: [
      // DHL Parcel Connect Plus
      'dangerousGoodsLimitedQuantity',
    ],
    103: [
      // DHL Service Point
      'dangerousGoodsLimitedQuantity',
    ],
    109: [
      // DHL Parcel Connect
      'dangerousGoodsLimitedQuantity',
    ],
    107: [
      // DHL Parcel Return Connect
      'dangerousGoodsLimitedQuantity',
    ],
    401: [
      // DHL Home Delivery
      'dangerousGoodsLimitedQuantity',
    ],
    501: [
      // DHL Home Delivery
      'dangerousGoodsLimitedQuantity',
    ],
  },

  additionalServicesToBeFilteredOut: {
    401: ['onlineAppointmentBooking'], // Home Delivery B2C 401
    501: ['m20', 'm40', 'm60', 'weekendDelivery'], // Home Delivery B2C 501
    502: ['weekendDelivery'], // DHL HOME DELIVERY RETURN 502
    212: ['deliveryAtConstructionSite'], // DHL PARTI
    112: [
      'customsCustomersOwnDeclaration',
      'customsJointDeclaration',
      'customsHandlingFullService',
      'customsHandlingStandard',
    ], // DHL PAKET EXPORT
    109: [
      'customsCustomersOwnDeclaration',
      'customsJointDeclaration',
      'customsHandlingFullService',
      'customsHandlingStandard',
      'insurance',
    ], // DHL Parcel Connect
    107: ['insurance'], // DHL Parcel Return Connect
    202: ['nonStackable'], // DHL Euroconnect
    233: ['hardcopyProofOfDelivery'], // DHL Eurapid
  },

  predefinedOptionsMockup: [
    {
      code: 'cashOnDelivery',
      name: 'Cash on delivery',
      options: [
        {
          domain: ['publicPickup', 'publicOrder'],
          product: '',
          optionCode: 'Amount',
          type: 'numeric',
          mandatory: true,
          conditionalGroup: null,
          value: null,
          length: 13,
          decimals: null,
          min: 0,
          max: null,
          disabled: false,
        },
        {
          domain: ['publicPickup', 'publicOrder'],
          product: '',
          optionCode: 'BankGiroNumber',
          type: 'numeric',
          mandatory: true,
          conditionalGroup: ['BankGiroNumber', 'PlusGiroNumber'],
          value: null,
          length: 10,
          decimals: null,
          min: null,
          max: null,
          disabled: false,
        },
        {
          domain: ['publicPickup', 'publicOrder'],
          product: '',
          optionCode: 'PlusGiroNumber',
          type: 'numeric',
          mandatory: true,
          conditionalGroup: ['BankGiroNumber', 'PlusGiroNumber'],
          value: null,
          length: 10,
          decimals: null,
          min: null,
          max: null,
          disabled: false,
        },
        {
          domain: ['publicPickup', 'publicOrder'],
          product: '',
          optionCode: 'Reference',
          type: 'generalText',
          mandatory: false,
          conditionalGroup: null,
          value: null,
          length: 35,
          decimals: null,
          min: null,
          max: null,
          disabled: false,
        },
        {
          domain: ['publicPickup', 'publicOrder'],
          product: '',
          optionCode: 'Currency',
          type: 'currency',
          mandatory: true,
          conditionalGroup: null,
          value: 'SEK',
          length: 3,
          decimals: null,
          min: null,
          max: null,
          disabled: true,
        },
      ],
    },
    {
      code: 'customsCustomersOwnDeclaration',
      name: 'Customs, customers own declaration',
      options: [
        {
          domain: ['publicPickup', 'publicOrder'],
          product: '',
          optionCode: 'CustomsId',
          type: 'generalText',
          mandatory: true,
          conditionalGroup: null,
          value: null,
          length: 70,
          decimals: null,
          min: 0,
          max: null,
          disabled: false,
        },
        {
          domain: ['publicPickup', 'publicOrder'],
          product: '',
          optionCode: 'CustomsClearanceInstruction',
          type: 'generalText',
          mandatory: false,
          conditionalGroup: null,
          value: null,
          length: 70,
          decimals: null,
          min: 0,
          max: null,
          disabled: false,
        },
      ],
    },
  ],

  dimensions: {
    min_chargeable_weight_check_product_codes: ['212'],
    min_insurance_limit: 1,
    max_insurance_limit: 999999,
    max_insurance_limit_PE: 100000,
    min_item_weight: 0,
    max_item_weight: 21773,
    min_item_quantity: 1,
    max_item_quantity: 999,
    min_total_quantity: 1,
    max_total_quantity: 999,
    min_item_length: 15,
    max_item_length: 1200,
    min_item_width: 11,
    max_item_width: 240,
    min_item_height: 1,
    max_item_height: 270,
    min_item_volume: 0,
    max_item_volume: 77.76,
    min_item_loading_meter: 0,
    max_item_loading_meter: 19.2,
    max_item_height_stack_limit: 120,
    min_total_volume: 0,
    max_total_volume: 150,
    min_total_loading_meter: 0,
    max_total_loading_meter: 19.2,
    min_shipment_weight: 0,
    max_shipment_weight: 47000,
    min_total_palletPlace: 0,
    max_total_palletPlace: 48,
    min_item_palletPlace: 0,
    max_item_palletPlace: 48,
    non_stackable_height: 125,
    loading_meter_const: 2.4,
    length_with_weight_less_than_50: 299,
    min_item_chargeableWeight: 0,
    max_item_chargeableWeight: 47000,
    min_total_chargeableWeight: 0,
    max_total_chargeableWeight: 47000,
  },
  payers: [SENDER_PAYER, RECEIVER_PAYER, THIRD_PARTY_PAYER],
};

const COMPONENT_CONST = {
  API_DOMAIN: 'publicPickup',
  ANALYTICS: 'public booking pickup',
  MIN_POSTALCODE_NUMBER: 5,
  PALLET_PICKUP_PREFIX: 'PPR',
  PUBLIC_BOOKING_PICKUP: 'PBP',
  CITY_MULTIPLE_ADDRESS: 'VARIOUS',
  EMAIL_LENGTH: 65,
  CURRENCY_LENGTH: 3,
};

componentConfig.domesticCountriesPublicPickup.forEach(country => (country.name = country.name || country.value));

export const config = Object.assign({}, globalConfig, componentConfig);
export const CONST = Object.assign({}, GLOBAL_CONST, COMPONENT_CONST);

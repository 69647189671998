import { checkFieldAndReturnState, checkGroupOfFieldsAndReturnState } from 'globals/utils/fieldValidations';
import { RECEIVER_PAYER } from '../../../../globals/constants';
import { getPostalCodeRegex } from '../../../../globals/helpers/postalcode';
import { config } from '../../../data-config';

export const enterDeliveryLocationValidations = (
  state,
  stateToSet,
  hasErrors,
  prefix,
  readState,
  accountCountryCode,
) => {
  let getState = {};

  // Delivery receiver check
  getState = checkFieldAndReturnState({
    name: prefix + RECEIVER_PAYER,
    value: state[prefix + RECEIVER_PAYER].value,
    regEx: config.regEx.everything,
    isRequired: state[prefix + RECEIVER_PAYER].isRequired ? true : false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

  // Street check
  getState = checkFieldAndReturnState({
    name: prefix + 'Street',
    value: state[prefix + 'Street'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{0,${config.maxDefaultInputLength}}$`)],
    isRequired: state[prefix + 'Street'].isRequired ? true : false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = {
    value: getState.value,
    error: getState.error,
    isRequired: state[prefix + 'Street'].isRequired,
  };

  // Postalcode check
  getState = checkFieldAndReturnState({
    name: prefix + 'PostalCode',
    value: state[prefix + 'PostalCode'].value,
    regEx: getPostalCodeRegex({ countryCode: state.deliveryCountry.value, accountCountryCode, strict: true }),
    lengthCheck: [RegExp(`^.{0,${config.maxPostalCode}}$`)],
    isRequired: state[prefix + 'PostalCode'].isRequired ? true : false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = {
    value: getState.value ? getState.value.toUpperCase() : '',
    error: getState.error,
    isRequired: state[prefix + 'PostalCode'].isRequired,
  };

  // City check
  getState = checkFieldAndReturnState({
    name: prefix + 'City',
    value: state[prefix + 'City'].value.trim(),
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{0,${config.maxDefaultInputLength}}$`)],
    isRequired: state[prefix + 'City'].isRequired ? true : false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = {
    value: getState.value.trim(),
    error: getState.error,
    isRequired: state[prefix + 'City'].isRequired,
  };

  // Country check
  getState = checkFieldAndReturnState({
    name: prefix + 'Country',
    value: state[prefix + 'Country'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`$^|^.{${config.minCountryLength},${config.maxCountryLength}}$`)],
    isRequired: state[prefix + 'Country'].isRequired ? true : false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

  // Name check
  getState = checkFieldAndReturnState({
    name: prefix + 'Name',
    value: state[prefix + 'Name'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{0,${config.maxDefaultInputLength}}$`)],
    isRequired: state[prefix + 'Name'].isRequired ? true : false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

  // E-mail check
  getState = checkFieldAndReturnState({
    name: prefix + 'Email',
    value: state[prefix + 'Email'].value,
    regEx: config.regEx.email,
    lengthCheck: [RegExp(`^.{0,${config.maxEmailLength}}$`)],
    isRequired: state[prefix + 'Email'].isRequired ? true : false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

  // Phone Numbers check
  getState = checkGroupOfFieldsAndReturnState({
    groupName: prefix + 'PhoneNumbers',
    values: state[prefix + 'PhoneNumbers'],
    fields: [
      {
        name: 'code',
        isRequired: true,
      },
      {
        name: 'number',
        regEx: config.regEx.number,
        lengthCheck: [RegExp(`^.{1,${config.maxPhoneNumberDigits}}$`)],
        isRequired: true,
        calculatePhoneLengthCheck: true,
      },
    ],
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.groupName] = [...getState.values];

  if (!(prefix === 'differentDelivery')) {
    getState = checkFieldAndReturnState({
      name: prefix + 'ReceiverReference',
      value: state[prefix + 'ReceiverReference'].value,
      regEx: config.regEx.everything,
      isRequired: false,
    });
    hasErrors = getState.error || hasErrors;
    stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };
  }
  return {
    stateToSet,
    hasErrors,
  };
};

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import './SuccessMessage.css';

export default function SuccessMessage(props) {
  return (
    <div className="frc_success-msg-layer show" data-testid="success-message">
      <FontAwesomeIcon icon={faCheck} className="frc__success-icon" />
      {props.t(`${props.CONST.API_DOMAIN}|${props.message}`)}
    </div>
  );
}

import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';
import { getTotalLoadingMeterFields } from '../../../../globals/helpers/shipment';

export const Validation = (item, min, max, regex) => {
  let validation = {};

  validation = checkFieldAndReturnState({
    value: item.value,
    isRequired: item.required,
    regEx: regex,
    min: min,
    max: max,
  });

  return validation.error;
};

export const shipmentDetailsTotalsValidations = context => {
  const totals = context.state.totals.shipmentDetailsRows;
  const dimensions = context.state.dimensions || {};

  const { loadingMeterError } = getTotalLoadingMeterFields({ state: context.state });

  const quantityError =
    totals.quantity.value * 1 < dimensions.min_total_quantity ||
    totals.quantity.value * 1 > dimensions.max_total_quantity;
  const weightError =
    (totals.weight.value * 1 < dimensions.min_shipment_weight && totals.weight.value * 1 > 0) ||
    totals.weight.value * 1 > dimensions.max_shipment_weight;
  const volumeError =
    totals.volume.value * 1 < dimensions.min_total_volume || totals.volume.value * 1 > dimensions.max_total_volume;

  let chargeableWeightError;
  if (totals.chargeableWeight.value !== null) {
    chargeableWeightError =
      totals.chargeableWeight.value * 1 < dimensions.min_total_chargeableWeight ||
      totals.chargeableWeight.value * 1 > dimensions.max_total_chargeableWeight;
  }

  return {
    totals,
    dimensions,
    quantityError,
    weightError,
    volumeError,
    loadingMeterError,
    chargeableWeightError,
    hasTotalError:
      quantityError ||
      weightError ||
      volumeError ||
      loadingMeterError ||
      chargeableWeightError ||
      false,
  };
};

import React from 'react';
import { globalConfig } from '../data-config-global';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { Error, Input, Radio } from '../../components';
import { ekaerFreePresent } from './EkaerInformation-helpers';

/**
 * Hungary specific.
 **/
const EkaerInformation = props => {
  const { context, componentConfig, componentConstants } = props;
  const { t } = useTranslation();

  return (
    <div className="frc__generic--field-wrapper">
      <h4 className="margin-bottom-1 frc__red-lined-title ">
        {t(`general|titles|ekaer`)}
        <span className="has-tooltip-label">
          <div className="frc__tooltip-title-container has-icon icon-information color-DHL-red c-fcp-quote--info-container frc__tooltip_title">
            <div className="frc__tooltip-container-flyout">{t(`general|titles|tooltips|ekaer`)}</div>
          </div>
        </span>
      </h4>
      <div className="l-grid l-grid--w-100pc-s">
        <div className="frc__column-wrapper frc__shipment-row-margin-top l-grid--w-100pc-s-all l-grid--w-40pc-m-s l-grid--w-30pc-m-m ">
          <div>
            {t(`general|labels|ekaerFree`)}*
            <div className="l-grid l-grid--w-100pc-s">
              <div className="frc__column-wrapper frc__shipment-row-margin-top l-grid--w-100pc-s-all l-grid--w-40pc-m-s l-grid--w-30pc-m-m ">
                <Radio
                  id="ekaerFree-true"
                  name="ekaerFree"
                  isBooleanValueRequired
                  config={componentConfig}
                  CONST={componentConstants}
                  context={context}
                  label={t(`general|Yes`)}
                  isRequired={false}
                  value={true}
                  checked={props.context.state.ekaerFree.value !== null && props.context.state.ekaerFree.value}
                  afterUpdate={ekaerFreePresent}
                />
              </div>
              <div className="frc__column-wrapper frc__shipment-row-margin-top l-grid--w-100pc-s-all l-grid--w-40pc-m-s l-grid--w-30pc-m-m ">
                <Radio
                  id="ekaerFree-false"
                  name="ekaerFree"
                  isBooleanValueRequired
                  config={componentConfig}
                  CONST={componentConstants}
                  context={context}
                  label={t(`general|No`)}
                  isRequired={false}
                  value={false}
                  checked={props.context.state.ekaerFree.value !== null && !props.context.state.ekaerFree.value}
                />
                {props.context.state.ekaerFree.error && (
                  <Error
                    name={'ekaerFree' + props.index}
                    className="frc__input--error"
                    message={t('general|errors|ekaerFree')}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="frc__column-wrapper frc__shipment-row-margin-top l-grid--w-100pc-s-all l-grid--w-40pc-m-s l-grid--w-30pc-m-m ">
          <Input
            id={'ekaerNumber' + props.index}
            className=" frc__generic--field-wrapper--no-top "
            label={t('general|labels|ekaerNumber')}
            index={props.index}
            name="ekaerNumber"
            isRequired={false}
            lengthCheck={[RegExp(`^.{${1},${globalConfig.shipmentInputs.ekaerInformationLength}}$`)]}
            value={props.context.state.ekaerNumber.value}
            disabled={props.context.state.ekaerFree.value}
            context={props.context}
            config={componentConfig}
            CONST={componentConstants}
          />
          {!props.context.state.ekaerFree.value && props.context.state.ekaerNumber.error && (
            <Error
              name={'ekaerNumber' + props.index}
              className="frc__input--error"
              message={t('general|errors|ekaerNumber', {
                min: 1,
                max: globalConfig.shipmentInputs.ekaerInformationLength,
              })}
            />
          )}
        </div>
      </div>
    </div>
  );
};

EkaerInformation.propTypes = {
  context: PropTypes.any.isRequired,
  componentConfig: PropTypes.any.isRequired,
  componentConstants: PropTypes.any.isRequired,
};

export default EkaerInformation;

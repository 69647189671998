export const DATE_FORMAT = 'yyyy-MM-dd';

export const formatDate = dateObj => {
  let date = new Date(dateObj);
  return (
    date.getFullYear() +
    '-' +
    (date.getMonth() < 9 ? '0' : '') +
    (date.getMonth() + 1) +
    '-' +
    (date.getDate() <= 9 ? '0' : '') +
    date.getDate()
  );
};

export const formatDateTime = datum => {
  let date = new Date(datum);

  return (
    date.getFullYear() +
    '-' +
    (date.getMonth() < 9 ? '0' : '') +
    (date.getMonth() + 1) +
    '-' +
    (date.getDate() <= 9 ? '0' : '') +
    date.getDate() +
    'T' +
    (date.getHours() < 10 ? '0' : '') +
    date.getHours() +
    ':' +
    (date.getMinutes() < 10 ? '0' : '') +
    date.getMinutes() +
    ':' +
    (date.getSeconds() < 10 ? '0' : '') +
    date.getSeconds() +
    '.' +
    date.getMilliseconds() +
    'Z'
  );
};

export const formatDateTimeReadable = dateStr => {
  let date = new Date(dateStr);

  return (
    date.getUTCFullYear() +
    '-' +
    (date.getUTCMonth() < 9 ? '0' : '') +
    (date.getUTCMonth() + 1) +
    '-' +
    (date.getUTCDate() <= 9 ? '0' : '') +
    date.getUTCDate() +
    ' ' +
    (date.getUTCHours() < 10 ? '0' : '') +
    date.getUTCHours() +
    ':' +
    (date.getUTCMinutes() < 10 ? '0' : '') +
    date.getUTCMinutes() +
    ':' +
    (date.getUTCSeconds() < 10 ? '0' : '') +
    date.getUTCSeconds()
  );
};

export const formatDateTimeReadableLocale = (dateStr, showSeconds = true) => {
  let date = new Date(dateStr);

  const dateTime =
    date.getFullYear() +
    '-' +
    (date.getMonth() < 9 ? '0' : '') +
    (date.getMonth() + 1) +
    '-' +
    (date.getDate() <= 9 ? '0' : '') +
    date.getDate() +
    ' ' +
    (date.getHours() < 10 ? '0' : '') +
    date.getHours() +
    ':' +
    (date.getMinutes() < 10 ? '0' : '') +
    date.getMinutes();

  const dateTimeSeconds = dateTime + ':' + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();

  if (showSeconds) {
    return dateTimeSeconds;
  }
  return dateTime;
};

export const formatBrowserDateTimeReadable = (dateStr, hasTime = true) => {
  let date = new Date(dateStr);

  return (
    date.getFullYear() +
    '-' +
    (date.getMonth() < 9 ? '0' : '') +
    (date.getMonth() + 1) +
    '-' +
    (date.getDate() <= 9 ? '0' : '') +
    date.getDate() +
    (hasTime
      ? ' ' +
        (date.getHours() < 10 ? '0' : '') +
        date.getHours() +
        ':' +
        (date.getMinutes() < 10 ? '0' : '') +
        date.getMinutes() +
        ':' +
        (date.getSeconds() < 10 ? '0' : '') +
        date.getSeconds()
      : '')
  );
};

export const getDateFromDateTimeString = dateTimeString => {
  if (dateTimeString.indexOf('T') > 1) {
    return dateTimeString.split('T')[0];
  }
};

export const formatDateReadable = incomingDate => {
  let date = new Date(incomingDate);
  let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  let day = date.getDate();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();
  return monthNames[monthIndex] + ' ' + day + ', ' + year;
};

export const DeepMerge = (target, source) => {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object) Object.assign(source[key], DeepMerge(target[key], source[key]));
  }
  Object.assign(target || {}, source);
  return target;
};

export const getCurrentDateWithTime = () => {
  const today = new Date();
  const date = formatDate(today);
  const time =
    (today.getHours() < 10 ? '0' : '') +
    today.getHours() +
    ':' +
    (today.getMinutes() < 10 ? '0' : '') +
    today.getMinutes() +
    ':' +
    (today.getSeconds() < 10 ? '0' : '') +
    today.getSeconds();

  return date + ' ' + time;
};

export const toCamelCase = str =>
  str
    ? str
        .toString()
        .trim()
        .replace(/\s+/g, ' ')
        .split(' ')
        .map((word, i) => (i === 0 ? word[0].toLowerCase() : word[0].toUpperCase()) + word.slice(1))
        .join('')
    : '';

export const fixDecimalPointAndConvertToNumber = attributeObj => {
  const attribute = JSON.parse(JSON.stringify(attributeObj));

  attribute.value = attribute.value.toString().replace(',', '.') * 1;
  return attribute;
};

export const toFloatOrEmptyString = attributeObj => {
  const attribute = structuredClone(attributeObj);
  const parsedValue = parseFloat(attribute.value.toString().replace(',', '.'));

  attribute.value = Number.isNaN(parsedValue) ? '' : parsedValue;
  return attribute;
};

export const fixCommaInWeight = params => {
  const payload = JSON.parse(JSON.stringify(params));

  if (payload && payload.pieces && payload.pieces.length > 0) {
    payload.pieces = payload.pieces.map(piece => {
      piece.weight = piece.weight ? fixDecimalPointAndConvertToNumber({ value: piece.weight }).value : piece.weight;
      return piece;
    });
  }

  return payload;
};

export const getDisplayedPrice = (t, priceString = '0', CONST) => {
  return priceString.match(/^0(\.0*)?([^\d]|$)/)
    ? `${String.fromCharCode(8212)} ${CONST.CURRENCY_CODE}`
    : priceString.split(' ')[0] * 1 < 0
    ? `${t('general|N/A')}`
    : priceString.indexOf(CONST.CURRENCY_CODE) !== -1
    ? priceString
    : `${priceString} ${CONST.CURRENCY_CODE}`;
};

export const partyNameLengthLimit = (partiesList, config) => {
  const parties = JSON.parse(JSON.stringify(partiesList));

  if (parties && parties.length > 0) {
    return parties.map(party => {
      if (party.name && party.name.length > config.maxDefaultInputLength) {
        party.name = party.name.slice(0, config.maxDefaultInputLength);
      }
      return party;
    });
  }

  return parties;
};

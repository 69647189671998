import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DATE_FORMAT } from 'globals/utils/formatting';
// import { config } from "../../../../data-config";
import { locales } from 'locale/datePicker';
import { useTranslation } from 'react-i18next';

import DatePicker from 'react-datepicker';
import { Error } from 'components';
import {
  pickupDatesSelector,
  selectedPickupDateSelector,
  setSelectedPickupDate,
} from '../../../../../store/quoteSlice';
import { useDispatch, useSelector } from 'react-redux';

const CheckboxDateTime = ({
  option,
  context,
  routeContext,
  index,
  groupIndex,
  errorId,
  serviceIndex,
  optionParentIndex,
  config,
  CONST,
  loaderRef,
}) => {
  const { t } = useTranslation();
  const [selectTimeMinDate, setSelectTimeMinDate] = useState(new Date());

  const dispatch = useDispatch();
  const { pickupDates } = useSelector(pickupDatesSelector);
  const { selectedPickupDate } = useSelector(selectedPickupDateSelector);

  const isTimeDefiniteUnloading = context.state.additionalServices[serviceIndex].code === 'timeDefiniteUnloading';
  const isTimeDefiniteLoading = context.state.additionalServices[serviceIndex].code === 'timeDefiniteLoading';

  const timeDefiniteLoadingService = context.state.additionalServices.find(item => {
    return item.code === 'timeDefiniteLoading';
  });
  const timeDefiniteUnloadingService = context.state.additionalServices.find(item => {
    return item.code === 'timeDefiniteUnloading';
  });

  const timeDefiniteLoadingDate = useMemo(
    () => timeDefiniteLoadingService.value && new Date(timeDefiniteLoadingService.groups[0].options[0][0].value),
    [timeDefiniteLoadingService],
  );

  const unloadingDate =
    config.vasSpecialCaseForDeliveryDate.indexOf(context.state.additionalServices[serviceIndex].code) !== -1;
  const [minDate, setMinDate] = useState(new Date());

  useEffect(() => {
    if (unloadingDate) {
      const newUnloadingDate = unloadingDate ? new Date(context.state.pickupDate.value) : new Date();
      setMinDate(newUnloadingDate);
    }
  }, [unloadingDate, context.state.pickupDate.value]);

  useEffect(() => {
    if (isTimeDefiniteLoading && timeDefiniteLoadingService.value && context.state.pickupDate.value) {
      const newMinDate = new Date(pickupDates[0]);
      setMinDate(newMinDate);
    }
  }, [
    timeDefiniteLoadingService,
    timeDefiniteLoadingDate,
    context.state.pickupDate.value,
    isTimeDefiniteLoading,
    pickupDates,
  ]);

  useEffect(() => {
    /**
     * Set minDate for time-definite-unloading when time-definite-loading is set.
     */
    if (isTimeDefiniteUnloading && timeDefiniteLoadingService.value && timeDefiniteUnloadingService.value) {
      setMinDate(timeDefiniteLoadingDate);
    }
  }, [isTimeDefiniteUnloading, timeDefiniteLoadingService, timeDefiniteUnloadingService, timeDefiniteLoadingDate]);

  const resetUnloadingDateIfNotLogicalToLoadingDate = useCallback(
    date => {
      let newDate = date;
      const pickupDateVas = context.state.additionalServices.find(
        vas => config.vasSpecialCaseForPickupDate.indexOf(vas.code) !== -1,
      );

      if (pickupDateVas && pickupDateVas.groups && pickupDateVas.groups.length > 0) {
        pickupDateVas.groups.forEach(group => {
          if (group && group.options) {
            group.options.forEach(optionList => {
              optionList.forEach(optionS => {
                const optionSchema = optionS.type === config.OptionFieldTypes.DateTime;

                if (optionSchema && optionSchema.value) {
                  const loadingDate = new Date(optionSchema.value);
                  const newUnloadingDate = new Date(date);

                  if (loadingDate.getTime() > newUnloadingDate.getTime()) {
                    newDate = loadingDate;
                  }
                }
              });
            });
          }
        });
      }

      return newDate;
    },
    [config.OptionFieldTypes.DateTime, config.vasSpecialCaseForPickupDate, context.state.additionalServices],
  );

  const handleChange = useCallback(
    (date, time = false) => {
      // set Time Datepicker => same day => time is now, future day => time is 00.00
      if (!time) {
        const now = new Date();
        if (now.toDateString() === date.toDateString()) {
          // today
          setSelectTimeMinDate(new Date());
        } else {
          // future
          setSelectTimeMinDate(new Date(0, 0, 0, 0, 0));
        }
      }
      const stateObj = {};
      const additionalServices = context.state.additionalServices;
      let additionalService = {};

      additionalService = JSON.parse(JSON.stringify(additionalServices[serviceIndex]));
      const additionalServiceParentOption = additionalService.groups[groupIndex].options[optionParentIndex];
      const additionalServiceOption = additionalServiceParentOption[index];

      if (time && date && additionalServiceOption.value) {
        const newDate = new Date(additionalServiceOption.value);
        newDate.setHours(date.getHours(), date.getMinutes(), 0);
        date = new Date(newDate);
      }

      if (config.vasSpecialCaseForDeliveryDate.indexOf(additionalService.code) !== -1) {
        date = resetUnloadingDateIfNotLogicalToLoadingDate(date);
      }

      if (date) {
        additionalServiceOption.value = date;
      } else {
        additionalServiceOption.value = new Date();
      }

      additionalServices[serviceIndex] = additionalService;
      stateObj.additionalServices = additionalServices;

      if (isTimeDefiniteLoading) {
        const zeroHoursDate = new Date(date);
        zeroHoursDate.setUTCHours(0, 0, 0, 0);
        const value = zeroHoursDate.toISOString();

        dispatch(setSelectedPickupDate(value));
        stateObj.pickupDate = {
          error: false,
          value,
        };
      }

      if (isTimeDefiniteUnloading && new Date(context.state.pickupDate.value).getTime() > date.getTime()) {
        /**
         * Time-definite-unloading cannot happen after pickup,
         * so we need to change the pickup date to the
         * same value as time-definite-unloading.
         */
        const zeroHoursDate = new Date(date);
        zeroHoursDate.setUTCHours(0, 0, 0, 0);
        const value = zeroHoursDate.toISOString();
        dispatch(setSelectedPickupDate(value));
        stateObj.pickupDate = {
          error: false,
          value,
        };
      }

      context.updateState(stateObj);
    },
    [
      isTimeDefiniteUnloading,
      config.vasSpecialCaseForDeliveryDate,
      context,
      groupIndex,
      index,
      optionParentIndex,
      resetUnloadingDateIfNotLogicalToLoadingDate,
      serviceIndex,
      dispatch,
      isTimeDefiniteLoading,
    ],
  );

  const getErrorMessage = () => {
    let errorMessage = '';

    if (option.error) {
      errorMessage = t('general|errors|Please select date');
    }

    return <Error name={errorId ? errorId : 'OptionDateTime'} className="frc__input--error" message={errorMessage} />;
  };

  const language = localStorage.getItem(config.locale.localStorageVariable) || config.locale.defaultLanguage;
  const maxDate = new Date(pickupDates[pickupDates.length - 1]);
  const options = context.state.additionalServices[serviceIndex].groups[groupIndex].options[optionParentIndex];
  const newValue = options[index].value;

  const selected = useMemo(() => {
    const newSelected = new Date(newValue);
    return newSelected;
  }, [newValue]);

  /**
   * Compare time-definite-loading and time-definite-unloading dates
   * and set the correct time-definite-unloading
   */
  useEffect(() => {
    if (!timeDefiniteLoadingService || !timeDefiniteUnloadingService || !isTimeDefiniteUnloading) {
      return;
    }

    if (
      isTimeDefiniteUnloading &&
      timeDefiniteLoadingService.value &&
      timeDefiniteUnloadingService.value &&
      timeDefiniteLoadingDate.getTime() > selected.getTime()
    ) {
      handleChange(timeDefiniteLoadingDate);
    }
  }, [
    selected,
    context.state.additionalServices,
    groupIndex,
    optionParentIndex,
    serviceIndex,
    handleChange,
    isTimeDefiniteUnloading,
    timeDefiniteLoadingService,
    timeDefiniteUnloadingService,
    timeDefiniteLoadingDate,
  ]);

  /**
   * Compare time-definite-unloading and pickup date
   */
  useEffect(() => {
    if (!timeDefiniteUnloadingService || !isTimeDefiniteUnloading) {
      return;
    }

    if (
      isTimeDefiniteUnloading &&
      timeDefiniteUnloadingService.value &&
      new Date(context.state.pickupDate.value).getTime() > selected.getTime()
    ) {
      /**
       * Time definite unloading is in the past, the pickup date is in the future,
       * so set the time definite unloading to the same value as pickup date
       */
      handleChange(new Date(context.state.pickupDate.value));
    }
  }, [selected, handleChange, isTimeDefiniteUnloading, context.state.pickupDate.value, timeDefiniteUnloadingService]);

  /**
   * Compare time-definite-loading and pickup date
   */
  useEffect(() => {
    if (!timeDefiniteLoadingService || !isTimeDefiniteLoading) {
      return;
    }
    const zeroHoursDate = new Date(selected);
    zeroHoursDate.setUTCHours(0, 0, 0, 0);

    if (
      isTimeDefiniteLoading &&
      timeDefiniteLoadingService.value &&
      new Date(selectedPickupDate).getTime() !== zeroHoursDate.getTime()
    ) {
      /**
       * Time definite loading is in the past, the pickup date is in the future,
       * so set the time definite loading to the same value as pickup date
       */
      handleChange(new Date(selectedPickupDate));
    }
  }, [selected, handleChange, isTimeDefiniteLoading, timeDefiniteLoadingService, selectedPickupDate]);

  return (
    <div className="l-grid--w-100pc-s frc__as-option--wrapper">
      {t(`additionalServices|${option.optionCode}`)}
      <div className="l-grid frc__as-option--wrapper l-grid--middle-s l-grid--between-s">
        <div className="l-grid l-grid--w-100pc-s l-grid--w-60pc-s-m l-grid--w-50pc-s-l l-grid--w-70pc-m l-grid--w-100pc-m-s">
          <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s frc__datepicker">
            <DatePicker
              selected={selected}
              className="c-form--element-base c-form--element-input c-calculator--input-field"
              showDisabledMonthNavigation
              minDate={minDate}
              maxDate={maxDate}
              onChange={date => handleChange(date)}
              locale={locales[language]}
              dateFormat={DATE_FORMAT}
              includeDates={pickupDates.map(item => new Date(item))}
            />
          </div>
          <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s">
            <DatePicker
              selected={selected}
              onChange={date => handleChange(date, true)}
              className="c-form--element-base c-form--element-input c-calculator--input-field"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              locale={locales[language]}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              timeCaption={t('general|labels|inputs|Time')}
              minTime={selectTimeMinDate ?? new Date()}
              maxTime={new Date(0, 0, 0, 23, 59)}
            />
          </div>
        </div>
      </div>
      {option.error && option.errorMessage ? getErrorMessage() : ''}
    </div>
  );
};

export default CheckboxDateTime;

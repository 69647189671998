import { getTransactionId } from '../utils/generators';
import { CONST } from '../../portal-order/data-config';
import {
  getUserAccounts,
  productAPICall,
} from '../../portal-order/components/EnterPickupLocation/EnterPickupLocation-helpers';

export const fetchUserAccountsAndProductSpecifications = async (context, userAccountsAndProducts, products) => {
  const stateToSet = {};

  stateToSet.transactionId = getTransactionId(CONST.PORTAL_BOOKING_ORDER);
  const [accountsResultObj, productsObj] = await Promise.all([
    getUserAccounts(context.state, userAccountsAndProducts),
    productAPICall(products),
  ]);

  if (accountsResultObj.apiError || productsObj.apiError) {
    stateToSet.generalError = true;
  } else {
    stateToSet.accounts = accountsResultObj.accounts;
    stateToSet.originalAccounts = accountsResultObj.originalAccounts;
    stateToSet.productSpecifications = accountsResultObj.productSpecifications;
    Object.assign(stateToSet, productsObj);
  }

  context.updateState(stateToSet);
};

import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';

import { Error, Input, Select, TextArea } from 'components';
import { MyContext } from '../../context/context';
import PhoneNumbers from './PhoneNumbers/PhoneNumbers';

export default class EnterPickupLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingSaveDetails: false,
      accountDetailsResponseStatus: 0,
    };
  }

  render() {
    return (
      <>
        <div className="frc__generic-section--wrapper c-calculator--tabcontent">
          <Translation>
            {t => (
              <MyContext.Consumer>
                {context => (
                  <>
                    <h4 className="margin-bottom-1">{t(`palletPickup|Enter Pickup Location`)}</h4>
                    <div className="l-grid l-grid--between-s">
                      <div className="l-grid--w-100pc-s l-grid--w-48pc-m margin-bottom-1">
                        <p>{t(`general|Address Details`)}</p>

                        <div className="frc__generic--background-white frc__generic-section--wrapper">
                          <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
                            <Input
                              context={context}
                              regEx={config.regEx.everything}
                              label={t('general|Company')}
                              name="company"
                              isRequired={true}
                              value={context.state.company.value}
                              lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                              cutTextLimit={config.maxDefaultInputLength}
                            />
                            {context.state.company.error ? (
                              <Error
                                name="company"
                                className="frc__input--error"
                                message={t('general|errors|Company Error', {
                                  max: config.maxDefaultInputLength,
                                })}
                              />
                            ) : (
                              ''
                            )}
                          </div>

                          <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                            <div className=" l-grid--w-100pc-s">
                              <Input
                                context={context}
                                regEx={config.regEx.everything}
                                label={t('general|labels|inputs|Street')}
                                name="street"
                                isRequired={true}
                                value={context.state.street.value}
                                lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                                cutTextLimit={config.maxDefaultInputLength}
                              />
                              {context.state.street.error ? (
                                <Error
                                  name="street"
                                  className="frc__input--error"
                                  message={t('general|errors|Street Error', {
                                    max: config.maxDefaultInputLength,
                                  })}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          </div>

                          <div
                            className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper"
                            id="pp-form-city-and-postalcode"
                          >
                            <div className=" l-grid--w-100pc-s l-grid--w-50pc-m">
                              <Input
                                context={context}
                                regEx={config.regEx.number}
                                label={t('general|labels|inputs|Postalcode')}
                                name="postalCode"
                                isRequired={true}
                                value={context.state.postalCode.value}
                                lengthCheck={[RegExp(`^.{1,${config.maxPostalCode}}$`)]}
                                cutTextLimit={config.maxPostalCode}
                              />
                              {context.state.postalCode.error ? (
                                <Error
                                  name="postalCode"
                                  className="frc__input--error"
                                  message={t('general|errors|Postalcode Error', {
                                    max: config.maxPostalCode,
                                  })}
                                />
                              ) : (
                                ''
                              )}

                              {context.state.sourcePostalCodeError ? (
                                <Error
                                  name="sourcePostalCodeError"
                                  className="frc__input--error"
                                  message={t('general|errors|Postalcode API Error')}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
                              <Input
                                context={context}
                                regEx={config.regEx.everything}
                                label={t('general|labels|inputs|City')}
                                name="city"
                                isRequired={true}
                                value={context.state.city.value}
                                lengthCheck={[RegExp(`^.{1,${config.maxEmailLength}}$`)]}
                                cutTextLimit={config.maxDefaultInputLength}
                              />
                              {context.state.city.error ? (
                                <Error
                                  name="city"
                                  className="frc__input--error"
                                  message={t('general|errors|City Error', {
                                    max: config.maxDefaultInputLength,
                                  })}
                                />
                              ) : (
                                ''
                              )}
                            </div>

                            {context.state.cityOrZipCodeError && (
                              <Error
                                name="cityOrZipCodeError"
                                className="frc__input--error"
                                message={t(
                                  `palletPickup|Either zip code or combination of zip code and city was not recognized.`,
                                )}
                              />
                            )}
                          </div>

                          <div className=" l-grid--w-100pc-s frc__generic-row--wrapper ">
                            <Select
                              context={context}
                              config={config}
                              CONST={{
                                API_DOMAIN: 'countries',
                              }}
                              selectClass="l-grid--w-100pc-s"
                              label={t(`general|Country`)}
                              name="country"
                              isRequired={true}
                              values={config.domesticCountriesPalletPickup}
                              value={context.state.country.value}
                              specialCase
                            />
                            {context.state.country.error ? (
                              <Error
                                name="country"
                                className="frc__input--error"
                                message={t(`general|Country Error`)}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="l-grid--w-100pc-s l-grid--w-48pc-m">
                        <p>{t(`general|Contact Details`)}</p>

                        <div className="frc__generic--background-white frc__generic-section--wrapper">
                          <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                            <Input
                              context={context}
                              regEx={config.regEx.everything}
                              label={t(`general|Contact Name`)}
                              name="name"
                              isRequired={true}
                              value={context.state.name.value}
                              lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                              cutTextLimit={config.maxDefaultInputLength}
                            />
                            {context.state.name.error ? (
                              <Error
                                name="name"
                                className="frc__input--error"
                                message={t('general|errors|Contact Name Error', {
                                  max: config.maxDefaultInputLength,
                                })}
                              />
                            ) : (
                              ''
                            )}
                          </div>

                          <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                            <Input
                              context={context}
                              label={t(`general|Contact E-mail`)}
                              name="email"
                              isRequired={false}
                              value={context.state.email.value}
                              override={{ name: 'confirmationEmail' }}
                              lengthCheck={[RegExp(`^.{0,${config.maxEmailLength}}$`)]}
                              cutTextLimit={config.maxEmailLength}
                            />
                            {context.state.email.error ? (
                              <Error
                                name="email"
                                className="frc__input--error"
                                message={t('general|errors|E-mail Error', {
                                  chars: config.maxEmailLength,
                                })}
                              />
                            ) : (
                              ''
                            )}
                          </div>

                          <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
                            <PhoneNumbers config={config} CONST={CONST} context={context} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="l-grid--w-100pc-s l-grid--w-48pc-m frc__generic-row--wrapper margin-bottom-1">
                      <div className="has-top-margin-1">
                        <TextArea
                          context={context}
                          className=" frc__textarea--wrapper "
                          textAreaClassName="frc__textarea--tiny"
                          regEx={config.regEx.everything}
                          label={t(`general|Add transportation instructions for the driver`)}
                          name="instructions"
                          isRequired={false}
                          value={context.state.instructions.value}
                          lengthCheck={[RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)]}
                          cutTextLimit={config.maxInstructionsLength}
                        />
                        {context.state.instructions.error && (
                          <Error
                            name="instructions"
                            className="frc__input--error"
                            message={t(`general|errors|Driver Instructions Error`, {
                              chars: config.maxInstructionsLength,
                            })}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </MyContext.Consumer>
            )}
          </Translation>
        </div>
      </>
    );
  }
}

import React from 'react';
import { Error } from 'components';
import { useTranslation } from 'react-i18next';

export default function ConditionalOptionGroupError({ option, errors, context, config, CONST }) {
  const { t } = useTranslation();

  const getErrorMessage = () => {
    const error = option.conditionalGroup && errors && errors[option.conditionalGroup.join('')];
    let optionConditionalGroup;

    if (
      error &&
      option.conditionalGroup &&
      option.conditionalGroup.length > 0 &&
      option.optionCode === option.conditionalGroup[option.conditionalGroup.length - 1]
    ) {
      optionConditionalGroup = option.conditionalGroup.map(opt => t(`additionalServices|` + opt));

      let errorMsg = 'General error';
      if (option.conditionalGroupType === 1) errorMsg = 'At least one of the following fields must be entered';
      if (option.conditionalGroupType === 2) errorMsg = 'One of the following fields must be entered';

      return (
        <Error
          name="ConditionalOptionGroupError"
          className="frc__input--error margin-bottom-1 "
          message={t(`general|${errorMsg}`) + ': ' + optionConditionalGroup.join(', ')}
        />
      );
    } else {
      return '';
    }
  };

  return getErrorMessage();
}

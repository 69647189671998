import React, { Component } from 'react';
import { Checkbox } from 'components';
import AdditionalServicesOptions from '../AdditionalServicesOptions/AdditionalServicesOptions';
import './AdditionalServiceRow.css';
import { Translation } from 'react-i18next';

export default class AdditionalServiceRow extends Component {
  update = (params, context) => {
    params.groupName = 'additionalServices';
    context.updateGroupOfFields(params);
  };

  render() {
    return (
      <Translation>
        {t => (
          <div className="l-grid l-grid--w-100pc-s l-grid--w-50pc-m">
            <div className="frc__additional-services-row--wrapper frc__column-wrapper l-grid--w-100pc-s">
              <Checkbox
                context={this.props.context}
                config={this.props.config}
                CONST={this.props.CONST}
                label={t(`additionalServices|${this.props.additionalServiceRow.code}|name`)}
                name={this.props.additionalServiceRow.code}
                index={this.props.index}
                checked={this.props.additionalServiceRow.selectedStatus}
                isRequired={false}
                deselect={this.props.additionalServiceRow.notCompatibleWith}
                disabled={this.props.additionalServiceRow.disabled}
                tooltip={t(`additionalServices|${this.props.additionalServiceRow.code}|information`)}
                updateOnParent={(params, context) => this.update(params, context)}
              />
              {this.props.additionalServiceRow.selectedStatus &&
              this.props.additionalServiceRow.optionNotToHide &&
              this.props.additionalServiceRow.groups &&
              this.props.additionalServiceRow.groups.length > 0 ? (
                <AdditionalServicesOptions
                  serviceIndex={this.props.index}
                  code={this.props.additionalServiceRow.code}
                  groups={this.props.additionalServiceRow.groups}
                  context={this.props.context}
                  errors={this.props.additionalServiceRow.errors}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

import {
  AppBar,
  Box,
  Button,
  Link,
  Menu,
  MenuItem,
  Popover,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import HeaderLogo from '../../assets/header-logo.svg';
import { MAX_APP_WIDTH } from '../../styles/mui-theme';
import { cloneElement, useEffect, useState } from 'react';
import { DEFAULT_LANGUAGE } from '../../locale/config';
import i18next from 'i18next';
import { ROUTES, useRouting } from 'router';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useCountryLanguage } from '../../hooks/useCountryLanguage';
import { useGetUser, userQueryKey } from '../../hooks/api/useGetUser';
import AuthService from 'globals/utils/auth';

import { useUpdateUserLang } from '../../hooks/api/useUpdateUserLang';
import { RegistrationDialog } from './registration/RegistrationDialog';
import { AUTHENTICATED } from '../../Login/constants';
import { useQuote } from '../../quote/useQuote';
import {
  convertCountriesToFlatArray,
  getAvailableLanguages,
  getSupportedLanguages,
} from '../../globals/helpers/language-helpers';
import ConfigService from '../../globals/utils/configService';

const ORDER_MENU_NAVITEMS = [
  { to: ROUTES.portalOrder, translationKey: 'general|Create Shipment and Print' },
  { to: ROUTES.portalPickup, translationKey: 'mainMenu|Schedule a Pickup' },
  { to: ROUTES.palletPickup, translationKey: 'general|Empty Pallet Pickup' },
];

const TOOLS_MENU_NAVITEMS = [
  { to: ROUTES.savedShipments, translationKey: 'mainMenu|Shipment Manager' },
  { to: ROUTES.addressBook, translationKey: 'general|Address Book' },
  { to: ROUTES.templates, translationKey: 'mainMenu|Templates' },
  { to: ROUTES.changePassword, translationKey: 'general|Change Password' },
];

const selectUserData = ({ user: { name, language, accountCountryCode } }) => ({
  user: { name, language, accountCountryCode },
});

export const Header = ({ underMaintenance, readOnlyHeader }) => {
  const { t } = useTranslation();
  const { language: i18lang, country: i18country, countryLanguage: i18countryLang } = useCountryLanguage() || {};
  const { navigate } = useRouting();
  const location = useLocation();
  const queryClient = useQueryClient();
  const mutation = useUpdateUserLang();
  const isRefreshTokenExpired = AuthService.isRefreshTokenExpired();

  const [showRegistrationDialog, setShowRegistrationDialog] = useState(false);
  const [langAnchorEl, setLangAnchorEl] = useState(null);
  const [orderAnchorEl, setOrderAnchorEl] = useState(null);
  const [toolsAnchorEl, setToolsAnchorEl] = useState(null);
  const [filteredLanguageOptions, setFilteredLanguageOptions] = useState([]);
  const langSelectorOpen = Boolean(langAnchorEl);
  const orderMenuOpen = Boolean(orderAnchorEl);
  const toolsMenuOpen = Boolean(toolsAnchorEl);
  const [allLanguages, setAllLanguages] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const { isLoading, data: userData } = useGetUser({
    select: selectUserData,
  });

  const { enableQuote } = useQuote();
  const authenticated = localStorage.getItem(AUTHENTICATED)?.toLowerCase() === 'true';

  const { accountCountryCode, language: userLanguage, name: userName } = userData?.user || {};
  const isRegistrationPage = location.pathname.endsWith('/registration');
  const isSweden = accountCountryCode?.toLowerCase() === 'se';
  const showAuthenticatedNav = authenticated && userData && !isLoading && !underMaintenance;
  const fullWidthNav = !location?.pathname?.includes('login');

  useEffect(() => {
    (async function () {
      try {
        const configCountriesLanguages = await ConfigService.getCountries();
        const flatConfigCountries = convertCountriesToFlatArray(configCountriesLanguages);
        setAllLanguages(flatConfigCountries);
      } catch (e) {
        console.error('Config countries: ', e);
      }
    })();
  }, []);

  useEffect(() => {
    const data = getAvailableLanguages(allLanguages);
    setAvailableLanguages(data);
  }, [allLanguages]);

  useEffect(() => {
    if (authenticated) {
      const data = availableLanguages.filter(
        lang => accountCountryCode && lang.countryCode.toLowerCase() === accountCountryCode.toLowerCase(),
      );
      setFilteredLanguageOptions(data);
    } else {
      // public-order and public-pickup is only for SE
      const isPublic = location?.pathname?.endsWith('/public-order') || location?.pathname?.endsWith('/public-pickup');
      const data = availableLanguages.filter(lang => lang.countryCode === 'SE' || (!isPublic && lang.isMain === true));
      setFilteredLanguageOptions(data);
    }
  }, [availableLanguages, authenticated, accountCountryCode, location?.pathname]);

  const logout = async e => {
    queryClient.removeQueries(userQueryKey);
    await AuthService.logOut(e);
    // TODO -> when DE / FR and next default languages create a logic for that
    // on ladning page, there is only swedish english available
    const languageUrl = userLanguage.includes('en') ? DEFAULT_LANGUAGE : i18countryLang;
    window.location.href = encodeURI(`${window.origin}/${languageUrl}/${ROUTES.login}`);
    return false;
  };

  const changeLanguage = (country, lang) => {
    const newLang = `${country.toLowerCase()}-${lang.toLowerCase()}`;
    if (!getSupportedLanguages(allLanguages).includes(newLang)) {
      throw new Error(`Unsupported language: ${newLang}`);
    }
    i18next.changeLanguage(newLang);
  };

  const handleSelectLang = ({ countryCode, langCode }) => {
    setLangAnchorEl(null);
    if (!countryCode || !langCode) {
      console.alert('Empty params', countryCode, langCode);
      return;
    }
    if (countryCode.toLowerCase() === i18country && langCode.toLowerCase() === i18lang) return;
    changeLanguage(countryCode, langCode);
    if (authenticated) mutation.mutate(langCode);
  };

  // REFRESH TOKEN INTERVAL
  useEffect(() => {
    if (authenticated && !isRefreshTokenExpired) {
      AuthService.runRefreshTokensHandler();
    }
  }, [authenticated, isRefreshTokenExpired]);

  // Handle discrepancy landing page languages vs allowed languages for user
  useEffect(() => {
    if (!authenticated || isLoading || !accountCountryCode) return;

    if (accountCountryCode.toLowerCase() === i18country) {
      if (userLanguage.toLowerCase() === i18lang) return;
      // same country, different language selected on landing page -> update userLang in DB
      mutation.mutate(i18lang);
    } else {
      if (i18lang === 'en') {
        // different country, english selected on landing page -> switch to country english
        // because english shown on landing page is SE-EN (Sweden)
        changeLanguage(accountCountryCode, 'en');
        userLanguage.toLowerCase() !== 'en' && mutation.mutate('en');
      } else {
        // completely different language selected on landing page -> set to userLanguage
        changeLanguage(accountCountryCode, userLanguage || 'en');
      }
    }
    // to prevent loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, isLoading]);

  return (
    <>
      {showRegistrationDialog ? (
        <RegistrationDialog
          handleClose={() => {
            setShowRegistrationDialog(false);
          }}
          handleConfirm={() => {
            navigate(ROUTES.login);
          }}
        />
      ) : null}
      <ElevationScroll enabled={fullWidthNav}>
        <AppBar
          component="nav"
          color="transparent"
          elevation={0}
          position={fullWidthNav ? 'sticky' : 'static'}
          sx={{
            backgroundImage: fullWidthNav && 'linear-gradient(to right, #fecb2e, #fecb2e 48%, #ffe57f 70%, #fff0b2)',
          }}
        >
          <Toolbar
            sx={{
              width: '100%',
              maxWidth: MAX_APP_WIDTH,
              minHeight: '60px !important',
              mx: 'auto',
              backgroundImage: !fullWidthNav && 'linear-gradient(to right, #fecb2e, #fecb2e 48%, #ffe57f 70%, #fff0b2)',
            }}
          >
            <Box sx={{ flexGrow: 1, display: 'flex' }} gap={1}>
              <Button
                disabled={readOnlyHeader}
                sx={{
                  p: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
                onClick={() => {
                  if (isRegistrationPage) {
                    setShowRegistrationDialog(true);
                  } else {
                    if (authenticated) {
                      navigate(ROUTES.dashboard);
                    } else {
                      navigate(ROUTES.login);
                    }
                  }
                }}
                data-testid="headerLogoBtn"
              >
                <img src={HeaderLogo} alt="DHL Freight" width={144} />
              </Button>
            </Box>
            {readOnlyHeader ? null : (
              <Box sx={{ display: 'flex', alignItems: 'center' }} gap={3}>
                {showAuthenticatedNav ? (
                  <NavButton to={`/${i18countryLang}/${ROUTES.editProfile}`} title={t(`mainMenu|My Profile`)}>
                    <NavTypography>{userName ?? ''}</NavTypography>
                  </NavButton>
                ) : (
                  <>
                    <NavButton to={`/${i18countryLang}/${ROUTES.login}`}>
                      <NavTypography>{t(`mainMenu|Login`)}</NavTypography>
                    </NavButton>
                    <NavButton to={`/${i18countryLang}/${ROUTES.registration}`}>
                      <NavTypography>{t(`mainMenu|Register here`)}</NavTypography>
                    </NavButton>
                  </>
                )}
                <Link
                  href={encodeURI(t(`pageLinks|dhlContactUs`))}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="text.primary"
                  variant="body2"
                  sx={{
                    textDecoration: 'underline rgba(0, 0, 0, 0)',
                    transition: 'color 400ms  ease-in-out, text-decoration-color 300ms  ease-in-out',
                    ':hover': {
                      color: 'text.secondary',
                      textDecorationColor: theme => theme.palette.primary.main,
                    },
                  }}
                >
                  {t(`mainMenu|Contact Us`)}
                </Link>
                <NavButton onClick={e => setLangAnchorEl(e.currentTarget)} display="flex">
                  <NavTypography display="flex" alignItems="center" gap={0.5}>
                    <LanguageIcon />
                    {`${
                      accountCountryCode && authenticated
                        ? t(`countries|${accountCountryCode?.toUpperCase()}`) + ' - '
                        : ''
                    }${i18lang?.toUpperCase()}`}
                  </NavTypography>
                </NavButton>
                <Popover
                  open={langSelectorOpen}
                  anchorEl={langAnchorEl}
                  onClose={() => setLangAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Box sx={{ maxWidth: '250px' }} display="flex" flexDirection="column" gap={0.5} p={1}>
                    {filteredLanguageOptions?.map(lang => (
                      <Box
                        key={lang.label}
                        onClick={() =>
                          handleSelectLang({
                            countryCode: lang.countryCode,
                            langCode: lang.code,
                          })
                        }
                        sx={{
                          p: 1,
                          ':hover': {
                            cursor: 'pointer',
                          },
                        }}
                        alignItems="center"
                        display="flex"
                        gap={1}
                      >
                        <Box
                          flexShrink={0}
                          sx={{ mr: '10px', borderRadius: '20%' }}
                          className={`fi fi-${lang.countryFlagCode?.toLowerCase()}`}
                        />
                        <NavTypography variant="body1">{lang.label}</NavTypography>
                      </Box>
                    ))}
                  </Box>
                </Popover>
              </Box>
            )}
          </Toolbar>
          {showAuthenticatedNav && (
            <Toolbar
              sx={{
                width: '100%',
                maxWidth: MAX_APP_WIDTH,
                mx: 'auto',
                minHeight: '50px !important',
                backgroundImage:
                  !fullWidthNav && 'linear-gradient(to right, #fecb2e, #fecb2e 48%, #ffe57f 70%, #fff0b2)',
              }}
            >
              <Box sx={{ flexGrow: 1, display: 'flex' }} gap={4} alignItems="center">
                <NavButton to={`/${i18countryLang}/${ROUTES.dashboard}`}>
                  <NavTypography fontWeight={600}>{t(`mainMenu|My Dashboard`)}</NavTypography>
                </NavButton>
                {enableQuote ? (
                  <NavButton
                    to={`/${i18countryLang}/${ROUTES.quoteTool}`}
                    onClick={() => {
                      const pageName = location.pathname?.split('/');
                      if (pageName && pageName[2] === ROUTES.quoteTool) {
                        window.location.reload();
                      }
                    }}
                  >
                    <NavTypography fontWeight={600}>{t(`mainMenu|Get a Quote`)}</NavTypography>
                  </NavButton>
                ) : null}
                {isSweden ? (
                  <NavButton
                    onClick={e => setOrderAnchorEl(e.currentTarget)}
                    endIcon={
                      <ExpandMoreIcon
                        sx={{
                          color: 'text.primary',
                          transform: orderMenuOpen ? 'scaleY(-1)' : 'scaleY(1)',
                        }}
                      />
                    }
                  >
                    <NavTypography fontWeight={600}>{t(`mainMenu|Book`)}</NavTypography>
                  </NavButton>
                ) : (
                  <NavButton
                    to={`/${i18countryLang}/${ROUTES.portalOrder}`}
                    onClick={() => {
                      const pageName = location.pathname?.split('/');
                      if (pageName && pageName[2] === ROUTES.portalOrder) {
                        window.location.reload();
                      }
                    }}
                  >
                    <NavTypography fontWeight={600}>{t(`mainMenu|Book`)}</NavTypography>
                  </NavButton>
                )}
                <NavButton
                  onClick={e => setToolsAnchorEl(e.currentTarget)}
                  endIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: 'text.primary',
                        transform: toolsMenuOpen ? 'scaleY(-1)' : 'scaleY(1)',
                      }}
                    />
                  }
                >
                  <NavTypography fontWeight={600}>{t(`mainMenu|Tools`)}</NavTypography>
                </NavButton>
              </Box>
              <NavButton onClick={logout} id="headerLogoutButton">
                <NavTypography fontWeight={600}>{t(`mainMenu|Log out`)}</NavTypography>
              </NavButton>
              <NavMenu
                id={'menu-order'}
                anchorEl={orderAnchorEl}
                setAnchorEl={setOrderAnchorEl}
                open={orderMenuOpen}
                navItems={ORDER_MENU_NAVITEMS}
                lang={i18countryLang}
              />
              <NavMenu
                id={'menu-tools'}
                anchorEl={toolsAnchorEl}
                setAnchorEl={setToolsAnchorEl}
                open={toolsMenuOpen}
                navItems={TOOLS_MENU_NAVITEMS}
                lang={i18countryLang}
              />
            </Toolbar>
          )}
        </AppBar>
      </ElevationScroll>
    </>
  );
};

const NavMenu = ({ id, anchorEl, setAnchorEl, open, navItems, lang }) => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      slotProps={{
        paper: {
          sx: { borderRadius: 0 },
          style: {
            transform: 'translateY(5%)',
          },
        },
      }}
      MenuListProps={{
        sx: {
          width: 260,
          '& .MuiMenuItem-root': {
            whiteSpace: 'normal',
            minHeight: 44,
          },
        },
      }}
    >
      {navItems.map(item => (
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            const pageName = location.pathname?.split('/');
            if (pageName && pageName[2] === ROUTES.portalOrder && item.to === ROUTES.portalOrder) {
              window.location.reload();
            }
          }}
          key={item.translationKey}
          component={RouterLink}
          to={`/${lang}/${item.to}`}
          sx={{
            ':hover': {
              '& .MuiTypography-root': {
                color: 'text.secondary',
                textDecorationColor: theme => theme.palette.primary.main,
              },
            },
          }}
        >
          <NavTypography variant="body1">{t(item.translationKey)}</NavTypography>
        </MenuItem>
      ))}
    </Menu>
  );
};

const NavButton = ({ children, to, sx, ...rest }) => {
  return (
    <Button
      component={to && RouterLink}
      to={to}
      sx={{
        ':hover': {
          background: 'transparent',
          '& .MuiTypography-root': {
            color: 'text.secondary',
            textDecorationColor: theme => theme.palette.primary.main,
          },
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

const NavTypography = ({ children, variant = 'body2', ...rest }) => {
  return (
    <Typography
      variant={variant}
      sx={{
        textDecoration: 'underline rgba(0, 0, 0, 0)',
        transition: 'color 400ms  ease-in-out, text-decoration-color 300ms  ease-in-out',
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

const ElevationScroll = props => {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return props.enabled
    ? cloneElement(children, {
        elevation: trigger ? 4 : 0,
      })
    : children;
};

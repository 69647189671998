import React, { Component } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { CONST } from '../../data-config';
import {
  resetCountryPaginationCriteriasAndSearchAddressCall,
  getCountriesForFilter,
  deleteContactList,
  translateFilterCountryOptionsListLabels,
} from '../ActionUtils';
import { ModalView } from '../ModalView/ModalView';

import './AddressBookAction.css';
export default class AddressBookAction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      modalError: false,
      isViewContact: false,
    };
  }

  onModalActionClick = async (action, t) => {
    const { context } = this.props;

    switch (action) {
      case CONST.CANCEL:
        this.toggleModalView();

        break;
      case CONST.DELETE:
        this.setState(
          {
            loader: true,
            modalError: false,
          },
          async () => {
            const deleteResult = await deleteContactList(context.state.deleteList.map(address => address.id));

            if (!deleteResult) {
              this.props.onShowSuccessToaster(
                context.state.deleteList.length > 1 ? `Contacts Deleted Successfully` : `Contact Deleted Successfully`,
              );
              await resetCountryPaginationCriteriasAndSearchAddressCall(context, false, t);
              this.toggleModalView();
            } else {
              this.setState({
                modalError: true,
                loader: false,
              });
            }
          },
        );

        break;

      default:
        break;
    }
  };

  toggleModalView = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      modalError: false,
      loader: false,
    }));
  };

  componentDidMount = async () => {
    if (this.props.context.state.filterCountryOptionsList.length === 0) {
      const stateToSet = await getCountriesForFilter(this.props.t);
      this.props.context.updateState(stateToSet);
    }
  };

  shouldComponentUpdate = async nextProps => {
    return JSON.stringify(nextProps.context.state.deleteList) !== JSON.stringify(this.props.context.state.deleteList);
  };

  render() {
    const { context, t } = this.props;
    const disableDeleteButton = this.props.context.state.deleteList.length === 0;
    const filterCountryOptionsList = translateFilterCountryOptionsListLabels(context.state.filterCountryOptionsList, t);

    return (
      <>
        {this.state.isModalOpen && (
          <ModalView
            transactionId={context.state.transactionId}
            error={this.state.modalError}
            contactList={this.props.context.state.deleteList}
            onCloseModalAction={this.toggleModalView}
            actionClick={action => this.onModalActionClick(action, t)}
            loader={this.state.loader}
          />
        )}

        <div data-testid="AddressBookAction" className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
          <div className=" frc__margin-top ">
            <button
              className={'base-button base-button--wide l-grid--w-25pc-w ' + (disableDeleteButton ? 'disabled' : '')}
              id="deleteSelectedAddressBtn"
              onClick={this.toggleModalView}
              disabled={disableDeleteButton}
            >
              <span>{t(`portalAddressBook|Delete Selected`)}</span>
            </button>
          </div>

          <div className=" frc_country-select-margin l-grid--w-50pc-s l-grid--w-20pc-m-m l-grid--w-30pc-m-s frc__margin-sides-1">
            <label>{t(`portalAddressBook|Filter By`)}</label>
            <MultiSelect
              className="frc__multiselect_bottom"
              options={filterCountryOptionsList}
              value={context.state.filteredCountries}
              onChange={selectedCountry =>
                resetCountryPaginationCriteriasAndSearchAddressCall(context, selectedCountry, t)
              }
              overrideStrings={{
                selectSomeItems: t(`countries|Select country`),
                search: t('general|labels|Search'),
                allItemsAreSelected: t(`portalAddressBook|All Countries are Selected`),
                selectAll: t(`portalAddressBook|Select All`),
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

import { Box, Typography } from '@mui/material';
import React from 'react';

export const TitleParagraph = ({ label, value, content, valueId }) => {
  return (
    <Typography
      align="left"
      sx={{
        borderBottom: '1px solid #ccc',
        paddingBottom: '0.75rem',
        margin: '0.75rem',
      }}
    >
      {label}: <b id={valueId}>{value}</b>
      {content && <Box sx={{ marginTop: '1rem' }}>{content}</Box>}
    </Typography>
  );
};

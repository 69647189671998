import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';
import { TextArea } from 'components';
import { Radio } from 'components';
import { Error } from 'components';
import PickupDate from '../PickupDate/PickupDate';
import { getTimeTable } from '../DeliveryOptions/DeliveryOptions-helpers';
import { analytics } from 'globals/utils/analytics';
import { checkPickupOptions, validateAllInputs, onTogglePickupOptions } from './SchedulePickup-helpers';
import { saveShipmentCall } from './../../helpers/savedShipment';

const ENV_DEV = process.env.NODE_ENV === 'development';

class SchedulePickup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedulePickupOnly: false,
    };

    this.loaderRef = React.createRef();
  }

  togglePickupOptions = () => {
    this.setState({ schedulePickupOnly: onTogglePickupOptions(this.props) });
  };

  checkDateError = () => {
    return (
      this.props.context.state.pickupOption.value === 'Schedule Pickup' && !this.props.context.state.pickupDate.value
    );
  };

  componentDidMount() {
    this.togglePickupOptions();
    this.props.context.updateState({ tab4OrTab5Touched: true });

    if (process.env.NODE_ENV !== 'test') {
      window.scrollTo(0, 0);
    }
  }

  onGoClick = context => {
    // set loader
    this.setState({ nextBtnLoader: true }, () => {
      const validation = validateAllInputs(context);
      let error = false;
      const localStateToSet = {};

      context.updateState(validation.stateToSet, async () => {
        if (!validation.hasErrors) {
          const savedShipmentResult = await saveShipmentCall(this.props.context);
          if (!savedShipmentResult.error) {
            analytics('step 4', CONST.ANALYTICS);
            this.props.activateTab(config.tabs.tab5);
          } else {
            error = true;
            localStateToSet.saveShipmentsError = savedShipmentResult.error || false;
          }
        } else {
          ENV_DEV && console.alert('Can not submit - have errors');
          error = true;
        }

        if (error) {
          localStateToSet.nextBtnLoader = false;
          this.setState(localStateToSet);
        }
      });
    });
  };

  committedDeliveryNotAvail = () => {
    return this.props.context.state.matchedProducts.some(
      prd => prd.code === '233' && prd.value && (!prd.deliveryDate || prd.deliveryDate === 'ltc_error'),
    );
  };

  render() {
    const committedDeliveryDateNotAvailable = this.committedDeliveryNotAvail();
    const disableNextBtn = this.checkDateError() || committedDeliveryDateNotAvailable;

    return (
      <div className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <div className="l-grid l-grid--between-s frc__generic--field-wrapper">
              <div className="l-grid--w-100pc-s l-grid--w-48pc-m margin-bottom-1">
                <h4 className="frc__red-lined-title">{t('general|Pickup')}</h4>

                <div className="l-grid--w-100pc-s">
                  {!this.state.schedulePickupOnly && (
                    <div className="margin-bottom-1">
                      <Radio
                        id={'Schedule Pickup'}
                        label={t('general|Schedule Pickup')}
                        name={'pickupOption'}
                        disabled={false}
                        isRequired={false}
                        value={'Schedule Pickup'}
                        checked={this.props.context.state.pickupOption.value === 'Schedule Pickup'}
                        context={this.props.context}
                        config={config}
                        CONST={CONST}
                        afterUpdate={checkPickupOptions}
                      />
                    </div>
                  )}
                  {this.props.context.state.pickupOption.value === 'Schedule Pickup' && (
                    <div className="frc__generic--background-white frc__generic-section--wrapper margin-bottom-1">
                      <PickupDate
                        checkForFixedDate={true}
                        context={this.props.context}
                        onGetTimeTable={value => getTimeTable(value, this.props.context, this.loaderRef.current)}
                      />

                      <>
                        <TextArea
                          className="frc__textarea--wrapper frc__generic--field-wrapper-no-top"
                          textAreaClassName="frc__textarea--tiny"
                          regEx={config.regEx.everything}
                          label={t(`general|Add Pickup Instructions (Optional)`)}
                          smallLabel={t(
                            `general|Add instructions for driver <strong>picking up</strong> this shipment`,
                          )}
                          name="pickupInstructions"
                          isRequired={false}
                          value={this.props.context.state.pickupInstructions.value}
                          lengthCheck={[RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)]}
                          cutTextLimit={config.maxInstructionsLength}
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                        />
                        {this.props.context.state.pickupInstructions.error && (
                          <Error
                            name="pickupInstructions"
                            className="frc__input--error"
                            message={t(`general|errors|Driver Instructions Error`, {
                              chars: config.maxInstructionsLength,
                            })}
                          />
                        )}
                      </>
                    </div>
                  )}
                  {!this.state.schedulePickupOnly && (
                    <>
                      <div className="margin-bottom-1">
                        <Radio
                          id={'Drop off at DHL'}
                          label={t(`general|Drop off a package at a DHL location`)}
                          name={'pickupOption'}
                          isRequired={false}
                          value={'Drop off a package at a DHL location'}
                          checked={
                            this.props.context.state.pickupOption.value === 'Drop off a package at a DHL location'
                          }
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          afterUpdate={checkPickupOptions}
                        />
                      </div>
                      <div className="margin-bottom-1">
                        <Radio
                          id={'Use scheduled pickup'}
                          label={t('general|Use an already scheduled pickup at my location')}
                          name={'pickupOption'}
                          isRequired={false}
                          value={'Use an already scheduled pickup at my location'}
                          checked={
                            this.props.context.state.pickupOption.value ===
                            'Use an already scheduled pickup at my location'
                          }
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          afterUpdate={checkPickupOptions}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="l-grid--w-100pc-s">
                  {this.props.context.state.pickupOption.error && (
                    <Error
                      name={'pickupOption'}
                      className="frc__input--error"
                      message={t('general|errors|Please select one option')}
                    />
                  )}
                </div>
              </div>

              <div className="l-grid--w-100pc-s l-grid--w-48pc-m margin-bottom-1">
                <h4 className="frc__red-lined-title">{t(`general|Delivery Instructions`)}</h4>

                <TextArea
                  className="frc__textarea--wrapper frc__generic--field-wrapper-no-top"
                  textAreaClassName="frc__textarea--tiny"
                  regEx={config.regEx.everything}
                  label={t(`general|Add Delivery Instructions (Optional)`)}
                  smallLabel={t(`general|Add instructions for driver <strong>delivering</strong> this shipment`)}
                  name="deliveryInstructions"
                  isRequired={false}
                  value={this.props.context.state.deliveryInstructions.value}
                  lengthCheck={[RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)]}
                  cutTextLimit={config.maxInstructionsLength}
                  context={this.props.context}
                  config={config}
                  CONST={CONST}
                />
                {this.props.context.state.deliveryInstructions.error && (
                  <Error
                    name="deliveryInstructions"
                    className="frc__input--error"
                    message={t(`general|errors|Driver Instructions Error`, {
                      chars: config.maxInstructionsLength,
                    })}
                  />
                )}
              </div>

              <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper l-grid--between-s">
                <button
                  id="backToDeliveryOptionsBtn"
                  className={'base-button base-button--white l-grid--w-25pc-w'}
                  onClick={() => this.props.previousTab(config.tabs.tab3, 3)}
                >
                  <span>{t(`general|Back to Delivery Options`)}</span>
                </button>
                <button
                  id="goToReviewAndBookBtn"
                  data-testid="next-button"
                  className={'base-button base-button--wide l-grid--w-25pc-w' + (disableNextBtn && ' disabled ')}
                  onClick={disableNextBtn ? null : () => this.onGoClick(this.props.context)}
                >
                  <span>{t('general|labels|buttons|Next Step')}</span>
                </button>
              </div>
              <div className="l-grid--w-100pc-s l-grid--right-s">
                {committedDeliveryDateNotAvailable && (
                  <Error
                    name="SchedulePickupLtcFailedForEurapid"
                    message={t('general|errors|Selected date not available, please try again')}
                  />
                )}

                {this.props.context.state.pickupOption.error || this.checkDateError() ? (
                  <Error
                    name="SchedulePickuppickupOption"
                    message={t('general|errors|Please fill properly all necessary fields')}
                  />
                ) : (
                  ''
                )}

                <Error
                  name="saveForFutureBtn"
                  message={this.state.saveShipmentsError ? t(`publicOrder|Shipment could not be saved`) : ''}
                />
              </div>
            </div>
          )}
        </Translation>
        <div id="fcp-schedule-pickup-loader" ref={this.loaderRef} className="frc__generic--loader-overlay">
          {' '}
        </div>
      </div>
    );
  }
}

export default SchedulePickup;

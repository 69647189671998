import { useQuery } from '@tanstack/react-query';
import { apiLinks } from '../../config/api-config';
import { apiRequest } from '../../globals';

export const queryKey = 'delivery-dates';

const fetchDeliveryDates = async deliveryDatesPayload => {
  const res = await apiRequest(apiLinks.deliveryDates, 'POST', { body: deliveryDatesPayload });
  if (res.status !== 200) {
    throw new Error(`Error in fetching delivery dates, Status: ${res.status} Message: ${res.status}`);
  }
  return res.data.deliveryDates;
};

export const useGetDeliveryDates = ({ deliveryDatesPayload, options } = {}) => {
  return useQuery({
    queryKey: [queryKey, deliveryDatesPayload],
    queryFn: () => fetchDeliveryDates(deliveryDatesPayload),
    enabled: !!(
      deliveryDatesPayload?.pickupDate &&
      deliveryDatesPayload?.productCodes?.length &&
      deliveryDatesPayload?.receiver?.countryCode &&
      deliveryDatesPayload?.receiver?.postalCode &&
      deliveryDatesPayload?.sender?.countryCode &&
      deliveryDatesPayload?.sender?.postalCode
    ),
    staleTime: 1000 * 60 * 5,
    ...options,
  });
};

import { Alert, Box, Collapse, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useGetUser } from '../../../hooks/api/useGetUser';
import { useTranslation } from 'react-i18next';
import { ProfileSection } from './ProfileSection';
import { ContactDetails } from './ContactDetails';
import { ContactDetailsForm } from './ContactDetailsForm';
import { Loader } from '../Loader';
import { EmailPassword } from './EmailPassword';
import { PrintSettings } from './PrintSettings';
import { EmailPasswordForm } from './EmailPasswordForm';
import { PrintSettingsForm } from './PrintSettingsForm';
import { ShipmentTypes } from './ShipmentTypes';
import { SectionContent } from './SectionContent';
import { ShipmentTypesForm } from './ShipmentTypesForm';
import { PalletAccounts } from './PalletAccounts';
import { Accounts } from './Accounts';
import { UserStatictics } from './UserStatistics';

export const PROFILE_SECTIONS = {
  contactDetails: 'contactDetails',
  emailPassword: 'emailPassword',
  printSettings: 'printSettings',
  shipmentTypes: 'shipmentTypes',
};

const PROFILE_SECTION_KEYS = Object.values(PROFILE_SECTIONS);

export const EditProfile = () => {
  const { t } = useTranslation();

  const [state, setState] = useState(() => {
    const initialState = {};
    PROFILE_SECTION_KEYS.forEach(key => {
      initialState[key] = { readOnly: true };
    });
    return initialState;
  });

  const setReadOnly = ({ section, readOnly }) => {
    setState(prevState => {
      const newState = { ...prevState };
      PROFILE_SECTION_KEYS.forEach(key => {
        if (key === section) {
          newState[key].readOnly = readOnly;
        } else {
          newState[key].readOnly = true;
        }
      });
      return newState;
    });
  };

  const { isLoading: userLoading, data: userData, isError: userError } = useGetUser();

  useEffect(() => {
    // scroll to # in Link if provided
    const anchor = window.location.hash.split('#')[1];
    if (anchor) {
      setTimeout(() => {
        const element = document.getElementById(anchor);
        if (element) {
          element.scrollIntoView();
        }
      }, 600);
    }
  }, []);

  return (
    <Box>
      <Typography variant="h1" my="32px">
        {t('general|pageTitles|userProfile')}
      </Typography>

      {userLoading ? <Loader sx={{ my: '16px' }} /> : null}

      {!userData && !userLoading ? (
        <Box sx={{ maxWidth: 'sm', m: '32px auto' }}>
          <Alert variant="standard" severity="info">
            No data available.
          </Alert>
        </Box>
      ) : null}

      {userError ? (
        <Box
          sx={{
            maxWidth: '500px',
            mx: 'auto',
            my: '32px',
          }}
        >
          <Alert severity="error" variant="outlined">
            {t('general|errors|loadUserError')}
          </Alert>
        </Box>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ProfileSection title={t('registration|enterContactDetails')}>
                <Collapse mountOnEnter unmountOnExit in={state.contactDetails.readOnly}>
                  <SectionContent>
                    <ContactDetails setReadOnly={setReadOnly} userData={userData} />
                  </SectionContent>
                </Collapse>
                <Collapse mountOnEnter unmountOnExit in={!state.contactDetails.readOnly}>
                  <SectionContent>
                    <ContactDetailsForm setReadOnly={setReadOnly} userData={userData} />
                  </SectionContent>
                </Collapse>
              </ProfileSection>
            </Grid>
            {/* 
            // TODO: jm - to be implemented in the future
            */}
            {/* <Grid item xs={3}>
              <Box sx={{ bgcolor: 'background.section', p: '32px' }}>
                <Typography variant="body1" mb="16px">
                  <strong>Submit a request to close your DHL account</strong>
                </Typography>
                <Button variant="outlined" color="primary" disabled>
                  Start a Request
                </Button>
              </Box>
            </Grid> */}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ProfileSection title={t('editProfile|emailAndPassword')}>
                <Collapse mountOnEnter unmountOnExit in={state.emailPassword.readOnly}>
                  <SectionContent>
                    <EmailPassword setReadOnly={setReadOnly} userData={userData} />
                  </SectionContent>
                </Collapse>
                <Collapse mountOnEnter unmountOnExit in={!state.emailPassword.readOnly}>
                  <SectionContent>
                    <EmailPasswordForm setReadOnly={setReadOnly} userData={userData} />
                  </SectionContent>
                </Collapse>
              </ProfileSection>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ProfileSection title={t('editProfile|Print Settings')}>
                <Collapse mountOnEnter unmountOnExit in={state.printSettings.readOnly}>
                  <SectionContent>
                    <PrintSettings setReadOnly={setReadOnly} userData={userData} />
                  </SectionContent>
                </Collapse>
                <Collapse mountOnEnter unmountOnExit in={!state.printSettings.readOnly}>
                  <SectionContent>
                    <PrintSettingsForm setReadOnly={setReadOnly} userData={userData} />
                  </SectionContent>
                </Collapse>
              </ProfileSection>
            </Grid>
          </Grid>
          {userData?.user?.accountCountryCode?.toLowerCase() === 'se' ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ProfileSection title={t('dashboard|Pallet Accounts')}>
                  <SectionContent>
                    <PalletAccounts />
                  </SectionContent>
                </ProfileSection>
              </Grid>
            </Grid>
          ) : null}

          {userData?.user?.accountCountryCode?.toLowerCase() !== 'se' ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ProfileSection
                  title={t('editProfile|shipmentTypesFavourites')}
                  subtitle={
                    <Typography variant="body1" fontSize="14px" sx={{ mb: '16px', mt: '-8px' }}>
                      <em>{t('editProfile|shipmentTypesDescription')}</em>
                    </Typography>
                  }
                >
                  <Collapse mountOnEnter unmountOnExit in={state.shipmentTypes.readOnly}>
                    <SectionContent>
                      <ShipmentTypes setReadOnly={setReadOnly} userData={userData} />
                    </SectionContent>
                  </Collapse>
                  <Collapse mountOnEnter unmountOnExit in={!state.shipmentTypes.readOnly}>
                    <SectionContent>
                      <ShipmentTypesForm setReadOnly={setReadOnly} userData={userData} />
                    </SectionContent>
                  </Collapse>
                </ProfileSection>
              </Grid>
            </Grid>
          ) : null}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ProfileSection title={t('editProfile|accountsTitle')}>
                <SectionContent>
                  <Accounts userData={userData} />
                </SectionContent>
              </ProfileSection>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ProfileSection title={t('editProfile|User Statistics')}>
                <SectionContent>
                  <UserStatictics userData={userData} />
                </SectionContent>
              </ProfileSection>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

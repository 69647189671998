export const state = {
  email: { value: '', error: false },
  country: { value: 'SE', error: false },
  company: { value: '', error: false },
  vatNumber: { value: '', error: false },
  name: { value: '', error: false },
  street: { value: '', error: false },
  postalCode: { value: '', error: false },
  sourcePostalCodeError: false,
  city: { value: '', error: false },
  phoneNumbers: [],
  tmsAccountsWaiting: [],
  tmsAccountsApproved: [],
  palletAccountsWaiting: [],
  palletAccountsApproved: [],
  generalError: false,
  instructions: { value: '', error: false },
  confirmationEmail: { value: '', error: false },
  numberOfPallets: { value: '', error: false },
  pickupOrderSubmited: false,
  pickupOrderResult: {},

  tmsAccountNumber: { value: '', error: false },
  tmsAccounts: [],
  palletAccountNumber: { value: '', error: false },
  palletAccounts: [],

  termsAndConditions: { value: false, error: false },
};

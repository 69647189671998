import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AccountTable } from './AccountTable';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { ChatWithUs } from '../ChatWithUs';
import { apiRequest } from '../../../globals';
import { apiLinks } from '../../../config/api-config';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { Button } from '../Button';
import { Add } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';

export const Accounts = ({ userData }) => {
  const { t } = useTranslation();
  const [addingAccount, setAddingAccount] = useState(false);

  const [confirmationDialogData, setConfirmationDialogData] = useState({
    open: false,
  });

  const {
    formState: { errors },
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      accountList: userData.tmsAccounts.map(item => {
        return {
          ...item,
          id: item.accountNumber,
          accountReferenceName: item.accountReference || '',
        };
      }),
    },
  });

  const watchAccountList = watch('accountList');
  const approvedAccounts = useMemo(
    () => watchAccountList.filter(item => item.status === 'APPROVED'),
    [watchAccountList],
  );
  const waitingAccounts = useMemo(() => watchAccountList.filter(item => item.status === 'WAITING'), [watchAccountList]);

  const onDeleteAccount = async account => {
    const handleError = () => {
      const message =
        t(`editProfile|Account removal failed. Try again or call technical support`) +
        ' ' +
        t('general|or') +
        ' ' +
        <ChatWithUs />;

      toast(
        <Alert severity="error" variant="standard">
          {message}
        </Alert>,
      );
    };
    try {
      const params = {
        body: {
          tmsAccounts: [
            {
              accountNumber: account.accountNumber,
              accountReference: account.accountReferenceName,
            },
          ],
        },
      };

      const result = await apiRequest(apiLinks.deleteTMSAccount, 'DELETE', params);
      if (result.status === 200) {
        const message = t(`editProfile|TMS Account has been removed`);
        toast(
          <Alert severity="success" variant="standard">
            {message}
          </Alert>,
        );
      } else {
        handleError();
      }
    } catch (error) {
      console.error('Error during deleting account', error);
      handleError();
    }
  };

  const onAddAccount = async ({ account, onSuccess }) => {
    const handleError = result => {
      let message = (
        <>
          {t(`editProfile|Account adding failed. Try again or call technical support`)} {t('general|or')} <ChatWithUs />
        </>
      );
      if (result?.data?.message?.toLowerCase() === 'trying to add existing account') {
        message = t(`editProfile|existingAccountError`);
      }
      toast(
        <Alert severity="error" variant="filled">
          {message}
        </Alert>,
      );
      setAddingAccount(false);
    };
    try {
      setAddingAccount(true);
      const params = {
        body: {
          tmsAccounts: [
            {
              accountNumber: account.accountNumber,
              accountReference: account.accountReferenceName,
            },
          ],
        },
      };

      const result = await apiRequest(apiLinks.addTMSAccount, 'PUT', params);
      if (result.status === 200) {
        const message = t(`editProfile|TMS Account has been added`);
        toast(
          <Alert severity="success" variant="standard">
            {message}
          </Alert>,
        );

        onSuccess();
        setAddingAccount(false);
      } else {
        handleError(result);
      }
    } catch (error) {
      console.error('Error during deleting account', error);
      handleError();
    }
  };

  return (
    <>
      <Typography variant="body1" mb="16px">
        {t(`editProfile|DHL Freight account number(s) approved`)}
      </Typography>
      <AccountTable
        accounts={approvedAccounts}
        clearErrors={clearErrors}
        errors={errors}
        onDeleteAccount={onDeleteAccount}
        setConfirmationDialogData={setConfirmationDialogData}
        setValue={setValue}
        showProducts
        watchAccountList={watchAccountList}
      />
      <Typography variant="body1" mt="32px" mb="16px">
        {t(`editProfile|Account number(s) waiting for approval`)}
      </Typography>
      <AccountTable
        accounts={waitingAccounts}
        addingAccount={addingAccount}
        clearErrors={clearErrors}
        errors={errors}
        onAddAccount={onAddAccount}
        onDeleteAccount={onDeleteAccount}
        setConfirmationDialogData={setConfirmationDialogData}
        setError={setError}
        setValue={setValue}
        userData={userData}
        watchAccountList={watchAccountList}
        allowDeleteAccount={false}
      />
      <Button
        dataTestId="add-another-tms-account-button"
        variant="contained"
        color="primary"
        sx={{ mt: '32px' }}
        startIcon={<Add />}
        disabled={watchAccountList.filter(item => item.newlyAddedAccount).length > 0}
        onClick={() => {
          setValue('accountList', [
            ...watchAccountList,
            {
              accountNumber: '',
              accountReferenceName: '',
              status: 'WAITING',
              newlyAddedAccount: true,
              id: uuidv4(),
            },
          ]);
        }}
      >
        {t('general|Add another TMS account')}
      </Button>

      <ConfirmationDialog
        open={confirmationDialogData.open}
        title={t(`editProfile|You are about to remove account number`)}
        content={
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body1">
              <strong className="color-DHL-red">{confirmationDialogData.accountNumber}</strong>
            </Typography>
            <Typography variant="body1">{t('general|Are you sure?')}</Typography>
          </Box>
        }
        onConfirm={confirmationDialogData.onConfirm}
        confirmLabel={t('general|Yes')}
        cancelLabel={t('general|No')}
        onClose={() => {
          setConfirmationDialogData({ open: false });
        }}
      />
    </>
  );
};

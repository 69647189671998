import React, { useEffect, useState } from 'react';
import backgroundImage from './fcp-portal-start-page_v1.png';

import Login from '../Login/Login';
import { useAuth } from '../../../hooks/useAuth';
import { useCountryLanguage } from '../../../hooks/useCountryLanguage';
import parse from 'html-react-parser';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getTranslationPhrasesByPageCode } from 'globals/utils/translationHelpers';
import { GLOBAL_CONST } from 'globals/data-config-global';
import { Box } from '@mui/material';
import { ROUTES } from '../../../router';
import { DEFAULT_EN_LANGUAGE } from '../../../locale/config';
import { useDispatch } from 'react-redux';

const ENV_DEV = process.env.NODE_ENV === 'development';

export default function MarketingPage({ context }) {
  const auth = useAuth();
  const dispatch = useDispatch();

  const countryLanguage = useCountryLanguage();
  const [marketing, setMarketing] = useState(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const lang = countryLanguage.language;
    const country = countryLanguage.country;

    if (lang === 'en' && country !== 'se') {
      // login Page could not be in English in other countries, SE-EN only
      if (process.env.REACT_APP_VERBOSE_LOGS?.toLowerCase() === 'true' && ENV_DEV) {
        console.log('redirecting to English default login page');
      }
      navigate('../../' + DEFAULT_EN_LANGUAGE + '/' + ROUTES.login, { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const preview = searchParams.get('preview');
    const pageCode = GLOBAL_CONST.PAGE_CODES.LOGIN;
    const lang = countryLanguage.language;
    const country = countryLanguage.country;

    getTranslationPhrasesByPageCode(pageCode, lang, country, preview).then(loginPageCmsData => {
      if (loginPageCmsData != null) {
        let marketingDataTop = null;
        let marketingDataBottom = null;
        loginPageCmsData.forEach(data => {
          if (data.code === 'marketing_top') {
            marketingDataTop = data.translationValue;
          }
          if (data.code === 'marketing_bottom') {
            marketingDataBottom = data.translationValue;
          }
        });
        setMarketing({
          top: marketingDataTop,
          bottom: marketingDataBottom,
        });
      } else {
        ENV_DEV && console.error('failed to fetch CMS data');
      }
    });
    // eslint-disable-next-line
  }, [countryLanguage.language, countryLanguage.country]);

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          mb: '5rem',
          minWidth: '375px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <img src={backgroundImage} alt="background" />
        </Box>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            justifyContent: 'space-between',
            alignItems: 'center',
            py: {
              xs: '5rem',
              md: undefined,
            },
            px: {
              xs: '1rem',
              md: '5rem',
              lg: '18rem',
            },
            height: {
              xs: undefined,
              md: '655px',
            },
          }}
        >
          <Box
            sx={{
              display: { xs: 'none', md: 'block' },
              maxWidth: '460px',
            }}
          >
            {marketing?.top && parse(marketing.top)}
          </Box>
          <Login context={context} authContext={auth} countryLanguageContext={countryLanguage} dispatch={dispatch} />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          px: '15px',
          '& ul': {
            marginLeft: '0',
          },
          '& li': {
            marginBottom: '14px',
          },
          '& h1': {
            textAlign: 'center',
          },
          '& h2': {
            textAlign: 'center',
          },
          '& table': {
            width: '100%',
          },
          '& td': {
            minWidth: 'unset',
            width: 'auto',
            padding: '15px 60px',
          },
        }}
      >
        {marketing?.bottom && parse(marketing.bottom)}
      </Box>
    </>
  );
}

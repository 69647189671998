import { config, CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import { formQuoteResultMap } from '../DeliveryOptions/DeliveryOptions-helpers';
import { homeDeliveryValidations } from './ServiceDetails-helpers/ServiceDetails-Home-Delivery-helpers';
import getLanguage from 'globals/utils/getLanguage';
import {
  checkAndTranslateOptionSchemas,
  applyOptionSchemaResultToVAS,
  validateReturnProducts,
  filterServicesForGivenProduct,
  eleminateGServices,
  applyValidationRulesToASlist,
  checkCustomsVASandEleminateOptionsSchema,
} from './ServiceDetails-helpers/ServiceDetails-helpers';
import { getProductBasedPrice } from '../DeliveryOptions/DeliveryOptions-helpers';
import {
  arrangeVASAlphabetically,
  setLoadersState,
  getTranslatedVASName,
  arrangeVASBasedOnSelectedLanguage,
} from './ServiceDetails-helpers/ServiceDetails-General-helpers';
import { tailLiftServiceFilter } from './ServiceDetails-helpers/ServiceDetails-Filter-VAS';
import { loadCollectionAtTerminal } from '../../../portal-order/components/ServiceDetails/ServiceDetails-helpers/ServiceDetails-Collection-At-Terminal';
import { HEMLEVERANS_PRODUCT_CODE } from '../../../globals/constants';
import { getDoorStepDeliveryVasDefaultData } from '../../../globals/helpers/additionalServices';

export const beforeCheck = context => {
  return setLoadersState(context, true);
};

/**
 * afterCheck
 * performs main logic after ticking a checkbox
 */
export const afterCheck = (routeContext, shipmentContext, context, disabledVas = {}, countryConfig = {}) => {
  const callbackState = {};
  const selectedProducts = [];
  let selectedProduct;

  // Select product to be put in the context
  selectedProduct = context.state.matchedProducts.find(product => product.value);
  selectedProducts.push(selectedProduct.name);

  // Fetches the VASes for the that product
  getAdditionalServicesForOneSelectedProduct(
    selectedProduct,
    routeContext,
    shipmentContext,
    context,
    disabledVas,
    countryConfig,
    selectedProducts,
  );

  callbackState.selectedProducts = selectedProducts;

  return callbackState;
};

const additionalServiceAndValidationCall = async (product, stateObject, disabledVas) => {
  const productCode = product.code.toString();
  const urlAdditionalServices = apiLinks.getAdditionalServices.replace('{productCode}', productCode);

  // Get Additional Services list
  await apiRequest(urlAdditionalServices, 'GET')
    .then(async result => {
      if (result.status === CONST.STATUS_OK) {
        result.data.forEach(res => {
          res.value = false;
          res.productCode = product.code;
        });
        stateObject.additionalServices = filterServicesForGivenProduct(productCode, result.data, disabledVas);

        // Get Validations (exclusions) for the VASes
        await getValidationResults(JSON.parse(JSON.stringify(stateObject.additionalServices)), productCode).then(
          validationResult => {
            if (validationResult.data) {
              stateObject.additionalServices = validationResult.data;
            } else if (validationResult.error) {
              stateObject.error = true;
            }
          },
        );
      } else {
        stateObject.error = true;
      }
    })
    .catch(() => {
      stateObject.error = true;
    });
};

export const getOptionSchema = async (stateObject, { code, LTCDates = false }) => {
  const urlOptions = apiLinks.getPortalQuoteVAS.replace('{productCode}', code.toString());

  // API Call for optional schema
  await apiRequest(urlOptions, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        stateObject.additionalServices = applyOptionSchemaResultToVAS(stateObject.additionalServices, result.data);

        if (stateObject.additionalServices) {
          stateObject.additionalServices = checkAndTranslateOptionSchemas(stateObject.additionalServices, LTCDates);
        }
      } else {
        stateObject.error = true;
      }
    })
    .catch(() => {
      stateObject.error = true;
    });
};

export const euroconnectSelectedWithNoDeliveryDate = matchedProducts => {
  return matchedProducts.some(
    prd =>
      config.ltcNonEurapidProductCodes.includes(prd.code.toString()) &&
      prd.value &&
      (!prd.deliveryDate || prd.deliveryDate === 'ltc_error'),
  );
};

/**
 * getAdditionalServicesForOneSelectedProduct
 * main filtering functionality
 */
const getAdditionalServicesForOneSelectedProduct = async (
  product,
  routeContext,
  shipmentContext,
  context,
  disabledVas = {},
  countryConfig = {},
  selectedProducts,
) => {
  const stateObject = {};
  const routeState = routeContext.state;
  const language = getLanguage(config.regEx.urlValidation);
  const productCode = product.code.toString();
  const state = shipmentContext.state;
  const totalShipmentWeight = state.totals.shipmentDetailsRows.weight.value * 1;
  const isHomeDelivery = config.homeDeliveryProductCodes.indexOf(productCode) !== -1;

  stateObject.error = false;
  stateObject.selectedProduct = product;

  // call additional service and validations
  await additionalServiceAndValidationCall(product, stateObject, disabledVas);

  // !!!! important
  // is it Hemleverans product (118) => ADD doorStepDelivery VAS => it is done by HARDCODED VAS, because Farm API does not want to include it for 118
  // !!!! important
  if (productCode === HEMLEVERANS_PRODUCT_CODE) {
    stateObject.additionalServices.push(getDoorStepDeliveryVasDefaultData());
  }

  // get options schema
  if (!stateObject.error) {
    await getOptionSchema(stateObject, product);
  }

  if (!stateObject.error) {
    await collectionAtTerminal(stateObject, routeState);
  }

  // Home Delivery case
  if (isHomeDelivery && !stateObject.error) {
    await homeDeliveryValidations(product, routeContext, stateObject);
  }

  if (!stateObject.error) {
    // Check VAS, VAS option schema and G12 for international
    checkCustomsVASandEleminateOptionsSchema(stateObject, routeState, product);
  }

  if (!stateObject.error) {
    stateObject.additionalServicesGeneralError = false;
    stateObject.deliveryInstructions = { value: '', error: false };
    stateObject.returnProdStatus = validateReturnProducts(productCode);

    stateObject.additionalServices = arrangeVASAlphabetically(stateObject.additionalServices, language);
    stateObject.additionalServices = arrangeVASBasedOnSelectedLanguage(
      stateObject.additionalServices,
      'translatedName',
    );
    stateObject.additionalServices = getTranslatedVASName(stateObject.additionalServices, context.state.languageCode);

    if ((product && product.hasOwnProperty('priorityServices')) || routeContext.state.deviating) {
      stateObject.additionalServices = eleminateGServices(
        product,
        stateObject.additionalServices,
        routeContext.state.deviating,
      );
    }
  } else {
    stateObject.additionalServices = [];
  }

  // FCPG-44 tailLift service filtering based on weight
  stateObject.additionalServices = tailLiftServiceFilter({
    additionalServices: structuredClone(stateObject.additionalServices),
    totalShipmentWeight,
    shipmentRows: state.shipmentDetailsRows,
    tailLiftConfig: countryConfig?.vasFilters?.tailLiftLoading,
    serviceName: 'tailLiftLoading',
  });

  stateObject.additionalServices = tailLiftServiceFilter({
    additionalServices: structuredClone(stateObject.additionalServices),
    totalShipmentWeight,
    shipmentRows: state.shipmentDetailsRows,
    tailLiftConfig: countryConfig?.vasFilters?.tailLiftUnloading,
    serviceName: 'tailLiftUnloading',
  });

  if (!stateObject.error && context.state.markup.value) {
    const proxyDeliveryState = {
      ...JSON.parse(JSON.stringify(context.state)),
      ...JSON.parse(JSON.stringify(stateObject)),
    };

    const { additionalServicesError, quotePriceMap } = await getProductBasedPrice(
      routeState,
      shipmentContext.state,
      proxyDeliveryState,
      product.code,
    );

    if (additionalServicesError) {
      stateObject.error = true;
      stateObject.additionalServices = [];
    } else {
      stateObject.quotePriceMap = quotePriceMap;
    }
  } else {
    stateObject.quotePriceMap = formQuoteResultMap(context.state.allProductPrice[product.code]);
  }

  stateObject.additionalServicesError = stateObject.error;
  stateObject.goNextError = false;
  stateObject.selectProductError = false;

  // CR - 54
  if (
    config.ltcProductCodes.indexOf(stateObject.selectedProduct.code) > -1 &&
    stateObject.additionalServices &&
    stateObject.additionalServices.some(VAS => config.vasExcludedForFailedLTC.indexOf(VAS.code) !== -1) &&
    euroconnectSelectedWithNoDeliveryDate(context.state.matchedProducts)
  ) {
    stateObject.additionalServices.forEach(VAS => {
      if (config.vasExcludedForFailedLTC.indexOf(VAS.code) !== -1) {
        VAS.disabled = true;
      }
    });
  }

  Object.assign(stateObject, setLoadersState(context, false));

  if (context.setState) {
    context.setState(stateObject);
  } else {
    context.updateState({ ...stateObject, selectedProducts });
  }
};

/**
 * getValidationResults
 * secondary filtering from validations recieved by API call
 */
export const getValidationResults = async (additionalServices, productCode) => {
  const resultObj = {};
  const urlValudationResults = apiLinks.getValidationResults.replace('{productCode}', productCode);
  const payloadDataForValidationResults = {};

  // Prepare payload for the API call
  additionalServices.forEach(service => {
    payloadDataForValidationResults[service.code] = true;
  });

  // API Call for validations
  await apiRequest(urlValudationResults, 'POST', {
    body: payloadDataForValidationResults,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        additionalServices = applyValidationRulesToASlist(additionalServices, result.data);
        resultObj.data = JSON.parse(JSON.stringify(additionalServices));
      } else {
        resultObj.error = true;
      }
    })
    .catch(() => {
      resultObj.error = true;
    });

  return resultObj;
};

const collectionAtTerminal = async (stateObject, state) => {
  let collectionAtTerminalIndex = -1;
  stateObject.additionalServices.forEach(function (vas, i) {
    if (vas.code === config.OptionFieldTypes.collectionAtTerminal) {
      collectionAtTerminalIndex = i;
      return true;
    }
  });

  if (collectionAtTerminalIndex !== -1) {
    await loadCollectionAtTerminal(stateObject, collectionAtTerminalIndex, state);
  }
};

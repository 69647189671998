import { ROUTES } from 'router';

export const ANALYTICS_ROUTES = [
  { path: '/', analyticsId: 'fabd5f43a46be75dd990b7e7e1619f5c' },
  //   English
  { path: ROUTES.addressBook, analyticsId: '660a0b85210f28286951cbf365308171', title: 'addressBook' },
  { path: ROUTES.changePassword, analyticsId: '4fa9928efa1e319e34b9fb74ea9ffabc', title: 'changePassword' },
  { path: ROUTES.dashboard, analyticsId: '2ff93ff2c73c1bfab2e3bcd7d1484025', title: 'dashboard' },
  { path: ROUTES.editProfile, analyticsId: 'f0b3068a2cd7b26e580c3fcff9c6b5c9', title: 'editProfile' },
  // { path: ROUTES.error', analyticsId: '15efcd4d4124b7b06e2105cf4b71ce45', title: 'Error' },
  // { path: ROUTES.error/401', analyticsId: '086ca8803443ca8cba9f04bbb14c0d89', title: '401' },
  // { path: ROUTES.error/403', analyticsId: '068383a790b0373aa38f2c1ba4fb165a', title: '403' },
  // { path: ROUTES.error/404', analyticsId: '05375695d5b2b6f0a39b67cc4fa544d0', title: '404' },
  // { path: ROUTES.error/500', analyticsId: '3cae187eefd8d65b065513edb3baab2a', title: '500' },
  { path: ROUTES.forgotPassword, analyticsId: '58c4fc1c2940fbe8c19ca736a650bbaa', title: 'forgotPassword' },
  { path: ROUTES.login, analyticsId: '35c35a2863ede458fbb368c086e06ed9', title: 'login' },
  { path: ROUTES.palletHistory, analyticsId: 'c8856a333390c14d38d2aa8528515ec2', title: 'palletHistory' },
  { path: ROUTES.palletPickup, analyticsId: 'c11f9204ea75cd9404e2ab8b9a4e914c', title: 'palletPickup' },
  { path: ROUTES.portalOrder, analyticsId: '74cd0d9d88f41088bde70aeab8970276', title: 'portalOrder' },
  { path: ROUTES.portalPickup, analyticsId: 'e66d7a6d7ced93b3d6f1a680dee9119c', title: 'portalPickup' },
  { path: ROUTES.privacyPolicy, analyticsId: '', title: 'privacyPolicy' },
  { path: ROUTES.publicOrder, analyticsId: '0de63b3968aec3afe38682e9595d94e4', title: 'publicOrder' },
  { path: ROUTES.publicPickup, analyticsId: '1dded168df01bc404b2a4c2b69f3bc05', title: 'publicPickup' },
  { path: ROUTES.quoteTool, analyticsId: 'b5e1983cfbe400a5cf4c1a3bc5c73602', title: 'quoteTool' },
  { path: ROUTES.registration, analyticsId: 'f9a79b55dc057205cbaebf8a0237b080', title: 'registration' },
  {
    path: ROUTES.registrationThankYou,
    analyticsId: '0d7f8479bacf946448f1c1107feab03d',
    title: 'registrationThankYou',
  },
  { path: ROUTES.resetPassword, analyticsId: 'f775ba7f330113ee1110e5036b058c82', title: 'resetPassword' },
  { path: ROUTES.savedShipments, analyticsId: '716204c3ca459c287e19f757e595c41e', title: 'savedShipments' },
  { path: ROUTES.templates, analyticsId: '9c0723923ed81a98e050778037b388ea', title: 'templates' },
  { path: ROUTES.termsOfUse, analyticsId: '', title: 'termsOfUse' },
  { path: ROUTES.underMaintenance, analyticsId: '08e8857d0a89cb591774f79e6d38dffc', title: 'underMaintenance' },
];

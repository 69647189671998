import { config } from '../../../data-config';
import 'globals/utils/shipmentAttributesFormula';
import { calculateTotals } from '../../../../globals/helpers/shipment-details-row';

export const dhlPallEURHalfPalletDimensions = (routeState, shipmentDetailsState, shipmentRow) => {
  if (
    routeState.productsRangeOne.value &&
    routeState.selectedProduct.value === config.dhlPALL &&
    shipmentRow.shipmentType.value === 'half pallet'
  ) {
    return { maxWeight: config.dhlPallEURHalfPallet.maxWeight, maxHeight: config.dhlPallEURHalfPallet.maxHeight };
  } else {
    return {
      maxWeight: shipmentDetailsState.dimensions.max_item_weight,
      maxHeight: shipmentDetailsState.dimensions.max_item_height,
    };
  }
};

// validate length based on weight
export const validateShipmentDetailsIfStyckeHomeDelivery = (context, shipmentRow, callbackState, params) => {
  const dimensions = JSON.parse(JSON.stringify(context.state.dimensions));
  const length = shipmentRow.length.value.replace(',', '.') * 1;
  const ifWeightGreaterThan50 =
    shipmentRow.weight.value.replace(',', '.') * 1 >=
    config.shipmentHomeDeliveryStyckeValidation.max_weight_when_length_300_to_599;

  if (ifWeightGreaterThan50) {
    dimensions.min_item_length = context.state.dimensionsOriginal.min_item_length;
    dimensions.max_item_length = config.shipmentHomeDeliveryStyckeValidation.max_item_length;
  } else {
    dimensions.min_item_length = context.state.dimensionsOriginal.min_item_length;
    dimensions.max_item_length = context.state.dimensionsOriginal.max_item_length;
  }

  if (length && !(length >= dimensions.min_item_length && length <= dimensions.max_item_length)) {
    shipmentRow.length.error = true;
  } else shipmentRow.length.error = false;

  params.error = shipmentRow.length.error;
  callbackState.dimensions = dimensions;
};

export const shipmentEntityValidation = (context, params, stateObject) => {
  const callbackState = {};
  const group = JSON.parse(JSON.stringify(stateObject[params.groupName]));
  const shipmentRow = group[params.index];
  const entity = shipmentRow[params.name];
  const dimensions = context.state.dimensions;
  const dimensionParameter = params.name === 'loadingMeter' ? 'loading_meter' : params.name;
  const value = entity.value && parseFloat(String(entity.value).replace(',', '.'));

  if (
    value &&
    !(value >= dimensions['min_item_' + dimensionParameter] && value <= dimensions['max_item_' + dimensionParameter])
  ) {
    entity.error = true;
  } else {
    entity.error = false || entity.error;
  }

  callbackState[params.groupName] = group;

  return callbackState;
};

export const calculateInputs = (context, params) => {
  const callbackState = {};
  let totals = calculateTotals(context.state[params.groupName], context.state.totals);

  if (params.compare) {
    const group = JSON.parse(JSON.stringify(context.state[params.groupName]));
    validateShipmentDetailsIfStyckeHomeDelivery(context, group[params.index], callbackState, params);
    callbackState[params.groupName] = group;
  }

  if (typeof totals === 'object' && totals !== null) {
    totals = JSON.parse(JSON.stringify(totals));
    Object.assign(callbackState, totals);
  }

  return callbackState;
};

export const resetShipmentDetailsInputOnTypeChange = (context, params, stateObject, routeState) => {
  const callbackState = {};

  const reset = JSON.parse(JSON.stringify(config.shipmentTypesFields[params.value]));
  const group = JSON.parse(JSON.stringify(stateObject[params.groupName]));
  const quantity = JSON.parse(JSON.stringify(group[params.index].quantity));
  const shipmentType = JSON.parse(JSON.stringify(group[params.index].shipmentType));
  const weight = JSON.parse(JSON.stringify(group[params.index].weight));

  reset.quantity = quantity;
  reset.shipmentType = shipmentType;
  reset.weight = weight;

  group[params.index] = reset;
  callbackState[params.groupName] = group;

  return callbackState;
};

import { Box } from '@mui/material';

export const Section = ({ children, sx, ...rest }) => {
  return (
    <Box
      sx={{
        padding: '32px',
        bgcolor: theme => theme.palette.background.section,
        ...sx,
      }}
      component="section"
      {...rest}
    >
      {children}
    </Box>
  );
};

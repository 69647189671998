import { config } from '../../../data-config';
import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';

export const validateTemplateName = (state, stateToSet, hasErrors) => {
  const getState = checkFieldAndReturnState({
    name: 'templateName',
    value: state.templateName.value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{1,${config.maxDefaultInputLength}}$`)],
    isRequired: state.templateName.isRequired,
  });

  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

  return {
    stateToSet,
    hasErrors,
  };
};

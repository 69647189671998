import { Box } from '@mui/material';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCountryLanguage } from '../hooks/useCountryLanguage';
import { ROUTES } from '../router';
import { getPageContent } from './Pages-helpers';

const ENV_DEV = process.env.NODE_ENV === 'development';

export const LegalNotice = () => {
  const countryLanguage = useCountryLanguage();
  const [pageData, setPageData] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const preview = searchParams.get('preview');
    // legal notice is a phrase unded the privacy-policy code
    getPageContent('privacy-policy', countryLanguage.language, countryLanguage.country, preview).then(cmsData => {
      const cmsSlugData = cmsData?.find(data => data.code === ROUTES.legalNotice);
      if (cmsSlugData) {
        setPageData(cmsSlugData);
      } else {
        ENV_DEV && console.error('failed to fetch CMS data');
        navigate('../error/404');
      }
    });
  }, [countryLanguage.language, countryLanguage.country, searchParams, navigate]);

  return (
    <Box p={2} display="flex" justifyContent="center" textAlign="center">
      <div>{pageData?.translationValue && parse(pageData.translationValue)}</div>
    </Box>
  );
};

import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import Modal from 'react-modal';
import { Button } from 'components';
import './ErrorModal.css';

const customStyles = {
  content: {
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    padding: '1rem 2rem',
    position: 'relative',
  },
};

export default class ErrorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#rootError');

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal = () => {
    this.setState({ isOpen: true });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <div>
        <Translation>
          {t => (
            <Modal
              isOpen={this.state.isOpen}
              overlayClassName="frc-message-modal"
              style={customStyles}
              onRequestClose={this.closeModal}
              contentLabel="Modal"
            >
              <div>{t(`general|Address from country Sweden can be selected for domestic shipments only`)}</div>
              <Button
                id="closeErrorModal"
                submit={this.closeModal}
                className="base-button frc__addresspickup-errormodal-button"
                label={t(`general|OK`)}
              />
            </Modal>
          )}
        </Translation>
      </div>
    );
  }
}

import React from 'react';
import { default as PaginationComponent } from 'react-paginating';
import { globalConfig } from 'globals/data-config-global';
import { Select } from 'components';
import { DoubleLeftIcon } from 'components';
import { LeftIcon } from 'components';
import { DoubleRightIcon } from 'components';
import { RightIcon } from 'components';
import { CaretLeft } from 'components';
import { CaretRight } from 'components';

import './Pagination.css';

export default function Pagination(props) {
  const { pagination } = props;

  const lastIndex = pagination.page * pagination.pageSize;
  const firstIndex = lastIndex - pagination.pageSize + 1;
  const range =
    pagination.page === pagination.lastPage
      ? `${firstIndex}-${pagination.total} ${props.t('general|of')} ${pagination.total}`
      : `${firstIndex}-${lastIndex} ${props.t('general|of')} ${pagination.total}`;

  const onRowChange = params => {
    if (params.value * 1 !== props.pagination.pageSize) props.onHandleDisplayRowChange(params.value * 1);
  };

  const onPageChange = page => {
    if (props.pagination.page !== page) props.onHandlePageChange(page);
  };

  return (
    <div data-testid="pagination">
      {props.simplePagination ? (
        <PaginationComponent
          total={pagination.total}
          className="l-grid--visible-m l-grid l-grid--right-s"
          limit={pagination.pageSize}
          pageCount={globalConfig.paginationNumberOfButtons}
          currentPage={pagination.page}
        >
          {({ hasNextPage, hasPreviousPage, previousPage, nextPage, getPageItemProps }) => {
            return (
              <>
                {hasPreviousPage && (
                  <div
                    {...getPageItemProps({
                      pageValue: previousPage,
                      onPageChange: onPageChange,
                    })}
                  >
                    <CaretLeft />
                  </div>
                )}
                <span className="frc__simple-pagination-range">{range}</span>

                {hasNextPage && (
                  <div
                    {...getPageItemProps({
                      pageValue: nextPage,
                      onPageChange: onPageChange,
                    })}
                  >
                    <CaretRight />
                  </div>
                )}
              </>
            );
          }}
        </PaginationComponent>
      ) : (
        <div className="l-grid">
          <div className="frc__pagination-magin-top  l-grid--w-100pc-s-s l-grid--w-20pc-s l-grid--w-20pc-m-s l-grid--w-40pc-m-m">
            {range}
          </div>

          <div className={`l-grid  frc__pagination-btn-wrapper frc__pagination-magin-top `}>
            <div className="frc__pagination-select-display">
              <div className=" frc__pagination-display">
                <strong>{props.t(`general|Display`)}</strong>
              </div>

              <Select
                name="paginationSelect"
                className="frc-select--wrapper frc__pagination-select"
                skipTranslation={true}
                disabled={
                  props.paginationItemsPerRow
                    ? pagination.total <= props.paginationItemsPerRow
                    : pagination.total <= globalConfig.paginationInitialNumberOfItems
                }
                values={props.paginationItemsPerRow ? props.paginationItemsPerRow : globalConfig.paginationItemsPerRow}
                value={pagination.pageSize}
                context={props.context}
                config={globalConfig}
                CONST={props.CONST}
                updateOnParent={onRowChange}
              />
            </div>
            <div className="frc__pagination-btn-center">
              <PaginationComponent
                total={pagination.total}
                limit={pagination.pageSize}
                pageCount={globalConfig.paginationNumberOfButtons}
                currentPage={pagination.page}
              >
                {({
                  pages,
                  currentPage,
                  hasNextPage,
                  hasPreviousPage,
                  previousPage,
                  nextPage,
                  totalPages,
                  getPageItemProps,
                }) => {
                  return (
                    <>
                      <button
                        disabled={pages.length === 1 || pagination.page === 1 ? 1 : 0}
                        className={`frc__pagination-btn  frc__pagination-btn-margin 
                   frc__pagination-last-edge-btn ${
                     pages.length === 1 || pagination.page === 1 ? 'disabled' : 'frc__page-hover'
                   } `}
                        {...getPageItemProps({
                          pageValue: 1,
                          onPageChange: onPageChange,
                        })}
                      >
                        <DoubleLeftIcon />
                      </button>

                      {hasPreviousPage && (
                        <button
                          className="frc__pagination-btn  frc__pagination-btn-margin frc__page-hover"
                          {...getPageItemProps({
                            pageValue: previousPage,
                            onPageChange: onPageChange,
                          })}
                        >
                          <LeftIcon />
                        </button>
                      )}

                      {pages.map(page => {
                        let activePage = null;
                        if (currentPage === page) {
                          activePage = { backgroundColor: '#fff4f1' };
                        }
                        return (
                          <button
                            className={`frc__pagination-btn  frc__pagination-btn-margin  ${
                              pages.length === 1 ? 'disabled' : !activePage ? ' frc__page-hover' : ''
                            }`}
                            {...getPageItemProps({
                              pageValue: page,
                              key: page,
                              style: pages.length === 1 ? undefined : activePage,
                              onPageChange: onPageChange,
                            })}
                          >
                            {page}
                          </button>
                        );
                      })}

                      {hasNextPage && (
                        <button
                          className="frc__pagination-btn  frc__pagination-btn-margin frc__page-hover"
                          {...getPageItemProps({
                            pageValue: nextPage,
                            onPageChange: onPageChange,
                          })}
                        >
                          <RightIcon />
                        </button>
                      )}

                      <button
                        disabled={pagination.page === pagination.lastPage ? 1 : 0}
                        className={`frc__pagination-btn  frc__pagination-btn-margin 
                     frc__pagination-last-edge-btn ${
                       pagination.page === pagination.lastPage ? 'disabled' : 'frc__page-hover'
                     } `}
                        {...getPageItemProps({
                          pageValue: totalPages,
                          onPageChange: onPageChange,
                        })}
                      >
                        <DoubleRightIcon />
                      </button>
                    </>
                  );
                }}
              </PaginationComponent>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

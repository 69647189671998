import { config } from '../../../data-config';
import { checkFieldAndReturnState, checkGroupOfFieldsAndReturnState, matchRegEx } from 'globals/utils/fieldValidations';
import {
  getLengthForVAS,
  getRegEx,
  getRegexForDecimalNumber,
} from '../AdditionalServices/AdditionalServicesOptions/AdditionalService-helpers';
import { validateGiroNumber } from 'globals/utils/giroNumberValidations';

export const additionalServicesOptionsValidations = additionalServices => {
  let getState = {};
  let adError = false;

  Array.isArray(additionalServices) &&
    additionalServices &&
    // Loop through all Additional Services
    additionalServices.map(service => {
      if (service.value && service.groups && service.groups.length > 0) {
        // Loop through each group for the current Additional Service
        service.groups = service.groups.map(group => {
          group.options = group.options.map(optionList => {
            let conditionalGroupError = true;
            let conditionalGroupTouched = false;

            optionList = optionList.map(option => {
              if (!option.disabled) {
                if (config.OptionFieldTypes.inputTypes[option.type]) {
                  let decimalValidation;
                  getState = checkFieldAndReturnState({
                    value: option.value || '',
                    min: option.min,
                    max: option.max,
                    regEx:
                      option.type === config.OptionFieldTypes.inputTypes.Email
                        ? config.regEx.email
                        : getRegEx(option.type),
                    lengthCheck: [
                      new RegExp(
                        `^.{${option.minLength ? option.minLength : 0},${getLengthForVAS(
                          option.type,
                          option.maxLength,
                        )}}$`,
                      ),
                    ],
                    isRequired: option.mandatory,
                  });

                  adError = getState.error || adError || (decimalValidation && decimalValidation.error ? true : false);

                  if (
                    option.type === config.OptionFieldTypes.inputTypes.Decimal &&
                    option.value &&
                    option.value.indexOf('.') !== -1
                  ) {
                    let regexDecimalPattern = getRegexForDecimalNumber(option);
                    let decimalValidationError = !matchRegEx(option.value, regexDecimalPattern);
                    option.error = option.error || decimalValidationError;
                  }
                  getState.error ? (option.error = true) : (option.error = false);
                  option.errorMessage = service.code + option.optionCode + 'Error';
                }

                if (option.type === config.OptionFieldTypes.DateTime) {
                  if (!option.value && option.mandatory) {
                    option.error = true;
                    option.errorMessage = service.code + option.optionCode + 'Error';
                    adError = true;
                  }
                }

                if (option.type === config.OptionFieldTypes.Date) {
                  if (!option.value && option.mandatory) {
                    option.error = true;
                    option.errorMessage = service.code + option.optionCode + 'Error';
                    adError = true;
                  }
                }

                if (option.type === config.OptionFieldTypes.Select) {
                  let checkIfOptionsAvailable = option.values && option.values.length > 0;
                  if (checkIfOptionsAvailable && !option.value && option.mandatory) {
                    option.error = true;
                    option.errorMessage = service.code + option.optionCode + 'Error';
                    adError = true;
                  }
                }

                if (option.type === config.OptionFieldTypes.TickBox) {
                  if (!option.selected && option.mandatory) {
                    option.error = true;
                    option.errorMessage = service.code + option.optionCode + 'Error';
                    adError = true;
                  }
                }

                if (option.type === config.OptionFieldTypes.LtcDate) {
                  let checkIfOptionsAvailable = option.values && option.values.length > 0;
                  if (checkIfOptionsAvailable && !option.value && option.mandatory) {
                    option.error = true;
                    option.errorMessage = service.code + option.optionCode + 'Error';
                    adError = true;
                  }
                }

                if (option.type === config.OptionFieldTypes.Telephone) {
                  getState = checkGroupOfFieldsAndReturnState({
                    values: option.value,
                    fields: [
                      {
                        name: 'code',
                        isRequired: option.mandatory,
                      },
                      {
                        name: 'number',
                        regEx: config.regEx.number,
                        lengthCheck: [RegExp(`^.{1,${config.maxPhoneNumberDigits}}$`)],
                        isRequired: option.mandatory,
                        calculatePhoneLengthCheck: true,
                      },
                    ],
                  });
                  adError = getState.error || adError;
                  option.value = [...getState.values];
                  option.error = getState.error;
                }

                //Validate Giro numbers
                if (
                  option.value &&
                  (option.optionCode === 'bankGiroNumber' || option.optionCode === 'plusGiroNumber')
                ) {
                  const optionError = validateGiroNumber(option.value).isError;
                  adError = optionError || adError;
                  option.error = optionError;
                }
              }

              // Conditional group check
              if (option.conditionalGroup && option.conditionalGroup.length > 0 && !conditionalGroupTouched) {
                conditionalGroupTouched = true;

                optionList.forEach(opt => {
                  if (option.conditionalGroup.indexOf(opt.optionCode) !== -1) {
                    if (opt.value && !Array.isArray(opt.value)) conditionalGroupError = false;
                    else if (
                      opt.value &&
                      Array.isArray(opt.value) &&
                      ((opt.value[0].code && opt.value[0].number) || opt.value[0].value)
                    ) {
                      conditionalGroupError = false;
                    }
                  }
                });

                service.errors[option.conditionalGroup.join('')] = conditionalGroupError;
                if (conditionalGroupError) {
                  adError = true;
                }
              }

              service.error = option.error || service.error;

              return option;
            });
            return optionList;
          });
          return group;
        });

        service.error =
          service.error ||
          (service.errors && Object.values(service.errors)
            ? Object.values(service.errors).indexOf(true) !== -1
            : false);
      }

      return service;
    });

  return adError;
};

import { Box } from '@mui/material';
import { ReactComponent as LoaderIcon } from '../../loader.svg';

export const Loader = ({ sx, ...props }) => {
  return (
    <Box sx={{ justifyContent: 'center', display: 'flex', ...sx }} {...props}>
      <LoaderIcon />
    </Box>
  );
};

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'components';
import { Error } from 'components';

const OptionLTCDate = ({
  option,
  errorId,
  context,
  index,
  groupIndex,
  optionParentIndex,
  serviceIndex,
  CONST,
  config,
}) => {
  const { t } = useTranslation();
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    if (option.values && option.values.length > 0) {
      let options = [
        {
          value: '',
          name: 'Please select date',
          skipTranslation: false,
        },
      ];

      option.values.forEach(opt => {
        const o = { value: opt, name: opt, skipTranslation: true };
        options.push(o);
      });
      setSelectOptions(options);
    } else {
      setSelectOptions([
        {
          value: '',
          name: t('general|LTC Date not available'),
          skipTranslation: true,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [option.values]);

  const update = (params, ctx) => {
    let stateObject = {};
    let additionalService = {};
    let error = params.value === '' && params.isRequired;

    stateObject = ctx.state.additionalServices;
    additionalService = JSON.parse(JSON.stringify(stateObject[serviceIndex]));

    const additionalServiceParentOption = additionalService.groups[groupIndex].options[optionParentIndex];
    const additionalServiceOption = additionalServiceParentOption[index];

    if (error) {
      additionalServiceOption.errorMessage = additionalService.code + additionalServiceOption.optionCode + 'Error';
    } else {
      additionalServiceOption.errorMessage = '';
    }

    additionalServiceOption.value = params.value;
    additionalServiceOption.error = error;
    stateObject[serviceIndex] = additionalService;
    ctx.updateState({ additionalServices: stateObject });
  };

  return (
    <>
      <div className="l-grid--w-100pc-s frc__as-option--wrapper">
        <Select
          className="l-grid--w-100pc-s"
          label={t(`additionalServices|` + option.optionCode)}
          name={option.optionCode}
          context={context}
          config={config}
          CONST={CONST}
          isRequired={option.mandatory}
          values={selectOptions}
          value={option.value || ''}
          disabled={option.disabled || selectOptions.length <= 1 || false}
          updateOnParent={(params, ctx) => update(params, ctx)}
        />

        {option.error ? (
          <Error
            name={errorId ? errorId : 'OptionLTCDateErr'}
            className="frc__input--error"
            message={t('general|errors|Please select date')}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default OptionLTCDate;

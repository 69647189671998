import { Checkbox, FormControlLabel, InputLabel, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

export const ControlledTextField = ({
  control,
  label,
  fieldName,
  rules,
  TextFieldProps,
  onChangeCb,
  inputRegex,
  required,
  hideErrors = false,
}) => (
  <Controller
    control={control}
    name={fieldName}
    rules={rules}
    render={({ field: { value, onChange, onBlur }, fieldState: { invalid, error, isTouched } }) => (
      <Stack>
        <InputLabel required={required} sx={{ fontSize: '15px' }} error={invalid && (!hideErrors || isTouched)}>
          {label}
        </InputLabel>
        <TextField
          value={value}
          onChange={e => {
            if ((inputRegex && inputRegex.test(e.target.value)) || !inputRegex) {
              onChange(e);
              onChangeCb?.(e);
            }
          }}
          onBlur={onBlur}
          helperText={invalid && (!hideErrors || isTouched) && (error?.message || 'Invalid value')}
          {...TextFieldProps}
          inputProps={{
            style: {
              height: '15px',
            },
            ...TextFieldProps?.inputProps,
          }}
        />
      </Stack>
    )}
  />
);

export const ControlledCheckbox = ({ control, label, fieldName, disabled = false, CheckboxProps }) => (
  <FormControlLabel
    control={
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            checked={!!value}
            onChange={e => onChange(e.target.checked)}
            disabled={disabled}
            {...CheckboxProps}
          />
        )}
      />
    }
    label={
      <Typography variant="body2" fontSize="15px">
        {label}
      </Typography>
    }
  />
);

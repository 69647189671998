import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import {
  CONSIGNEE_CUSTOMER_TYPE,
  CONSIGNOR_CUSTOMER_TYPE,
  EXPORT_SHIPMENT_TYPE,
  EXPORT_SHIPMENT_TYPE_CODE,
  IMPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE_CODE,
} from '../../../globals/constants';
import { CONST } from '../../data-config';

const returnCorrectShipmentAttributeValues = value => {
  return value ? value.toString().replace(',', ',') * 1 : null;
};

const prepareRequestJSON = (shipmentState, routeState, productCode) => {
  const params = {};
  let accountNumber;
  const shipmentTypesImpOrExp = routeState.shipmentTypesImpOrExp.value;

  if (routeState.productsRangeOne.value) accountNumber = routeState.accountNumber.value;

  params.body = {
    productCode: productCode,
    weight: shipmentState.totals.shipmentDetailsRows.weight.value,
    volume: shipmentState.totals.shipmentDetailsRows.volume.value,
    loadingMetres: shipmentState.totals.shipmentDetailsRows.loadingMeter.value,
  };

  if (shipmentTypesImpOrExp !== '')
    params.body.importExport =
      shipmentTypesImpOrExp === IMPORT_SHIPMENT_TYPE ? IMPORT_SHIPMENT_TYPE_CODE : EXPORT_SHIPMENT_TYPE_CODE;

  params.body.pieces = [];

  shipmentState.shipmentDetailsRows.forEach(row => {
    const pieces = {
      length: row.length?.value || null,
      width: row.width?.value || null,
      height: row.height?.value || null,
      volume: returnCorrectShipmentAttributeValues(row.volume.value),
      weight: returnCorrectShipmentAttributeValues(row.weight.value),
      loadingMetres: returnCorrectShipmentAttributeValues(row.loadingMeter.value),
      numberOfPieces: row.quantity.value,
      stackable: !row.nonStackable.value,
    };

    params.body.pieces.push(pieces);
  });

  params.body.parties = [];

  const consignor = {
    type: CONSIGNOR_CUSTOMER_TYPE,
    address: {
      postalCode: routeState.pickupPostalCode.value,
      countryCode: routeState.pickupCountry.value,
    },
  };

  const consignee = {
    type: CONSIGNEE_CUSTOMER_TYPE,
    address: {
      postalCode: routeState.deliveryPostalCode?.value || null,
      countryCode: routeState.deliveryCountry.value,
    },
  };

  if (shipmentTypesImpOrExp === EXPORT_SHIPMENT_TYPE || shipmentTypesImpOrExp === '') consignor.id = accountNumber;
  else if (shipmentTypesImpOrExp === IMPORT_SHIPMENT_TYPE) consignee.id = accountNumber;

  params.body.parties.push(consignor);
  params.body.parties.push(consignee);

  return params.body;
};

export const chargeableWeight = async (shipmentState, routeState) => {
  const url = apiLinks.getChargeableWeight;
  const params = {};
  const stateToSet = {};

  params.body = prepareRequestJSON(shipmentState, routeState, routeState.selectedProduct.value);

  await apiRequest(url, 'POST', params)
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        stateToSet.value = result.data.chargeableWeight;
        stateToSet.error = false;
      } else {
        stateToSet.error = true;
      }
    })
    .catch(() => {
      stateToSet.error = true;
    });

  return stateToSet;
};

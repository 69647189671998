import React, { useContext } from 'react';
import { config, CONST } from '../../../data-config';
import { useTranslation } from 'react-i18next';
import { MyContext } from '../../../context/context';

import { Error, Input } from 'components';
import { RECEIVER_PAYER, THIRD_PARTY_PAYER } from '../../../../globals/constants';

const Payer = () => {
  const { t } = useTranslation();
  const context = useContext(MyContext);

  switch (context.state.payer.value) {
    case RECEIVER_PAYER:
      return (
        <>
          <div className="l-grid l-grid--w-100pc-s l-grid--middle-s c-fcp--paying-account-number">
            <Input
              context={context}
              config={config}
              CONST={CONST}
              regEx={config.regEx.alphaNumericWithDot}
              name="receiverNumber"
              isRequired={true}
              label={t('general|labels|inputs|Receiver Account Number')}
              className="l-grid--w-100pc-s l-grid--w-40pc-m frc__generic--field-wrapper"
              value={context.state.receiverNumber.value}
              lengthCheck={[RegExp(`^.{${config.defaultAccountNumberLength}}$`)]}
              cutTextLimit={config.defaultAccountNumberLength}
            />
          </div>
          {context.state.receiverNumber.error ? (
            <Error className="frc__input--error" message={t(`general|Account Number Error`)} />
          ) : (
            ''
          )}
        </>
      );
    case THIRD_PARTY_PAYER:
      return (
        <>
          <div className="l-grid l-grid--w-100pc-s l-grid--middle-s c-fcp--paying-account-number">
            <Input
              context={context}
              config={config}
              CONST={CONST}
              regEx={config.regEx.alphaNumericWithDot}
              name="thirdPartyNumber"
              isRequired={true}
              label={t('general|labels|inputs|Third Party Account Number')}
              className="l-grid--w-100pc-s l-grid--w-40pc-m frc__generic--field-wrapper"
              value={context.state.thirdPartyNumber.value}
              lengthCheck={[RegExp(`^.{${config.defaultAccountNumberLength}}$`)]}
              cutTextLimit={config.defaultAccountNumberLength}
            />
          </div>
          {context.state.thirdPartyNumber.error ? (
            <Error className="frc__input--error" message={t(`general|Account Number Error`)} />
          ) : (
            ''
          )}
        </>
      );
    default:
      return '';
  }
};

export default Payer;

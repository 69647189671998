import { ENABLED_COUNTRIES } from '../../locale/config';

export const getAvailableLanguages = languageSelectOptions => {
  const availableCountryCodes = ENABLED_COUNTRIES;
  return languageSelectOptions.filter(lang => {
    return availableCountryCodes.includes(lang.countryCode?.toLowerCase());
  });
};

export const getSupportedLanguages = languageSelectOptions => {
  return getAvailableLanguages(languageSelectOptions).map(
    option => `${option.countryCode.toLowerCase()}-${option.code.toLowerCase()}`,
  );
};

export const convertCountriesToFlatArray = configCountries => {
  let result = [];
  if (!configCountries) {
    return result;
  }
  configCountries.forEach(country => {
    country.languages.forEach(countryLanguage => {
      result.push({
        code: countryLanguage.code,
        label: countryLanguage.name,
        countryFlagCode: countryLanguage.flagCode,
        countryCode: country.code,
        isMain: countryLanguage.isMain,
      });
    });
  });
  return result;
};

import { isValidCountryLanguage } from '../../utils/urls';

const ENV_DEV = process.env.NODE_ENV === 'development';

const getBaseUrl = (path, pathPosition = null, returnPathPositionUrl) => {
  let pathName;
  let newURL;

  const urlRegexPattern = /^[A-Za-z]{2}/g;
  const language = localStorage.getItem('i18nextLng');

  if (ENV_DEV || (urlRegexPattern.test(language) && isValidCountryLanguage(language))) {
    pathName = window.location.pathname.split('/');

    newURL = window.location.protocol + '//' + window.location.host + '/';

    if (returnPathPositionUrl) {
      newURL += pathName[returnPathPositionUrl];

      return newURL;
    }

    if (pathPosition !== null && !isNaN(pathPosition)) {
      newURL = pathName[pathPosition];
    } else if (!path) {
      newURL += pathName[1] + '/' + pathName[2];
    } else {
      newURL += pathName[1];
    }
  }

  return newURL;
};

export default getBaseUrl;

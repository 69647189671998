import { Box } from '@mui/material';

export const GlobalBox = ({ children }) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 2000,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: '#fff',
      }}
    >
      {children}
    </Box>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Error } from 'components';

import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';

import './TransactionsTable.css';
import { ROUTES } from 'router';

export default function TransactionsTable({
  data,
  accountNumber,
  updateData,
  maxNoOfRows,
  displayValues,
  setCSVError,
  CSVError,
}) {
  const { t } = useTranslation();
  const handleChange = e => {
    updateData(e.target.value);
  };

  const enableSaveHistoryCSV = () => {
    let url = apiLinks.getHistoryCSV;
    url = url.replace('{accountNumber}', accountNumber);

    let params = {
      headers: {
        accept: 'text/csv',
        contentType: 'text/csv',
      },
    };

    apiRequest(url, 'GET', params).then(result => {
      if (result.status === 200) {
        let blob = new Blob([result.data], { type: 'text/csv; charset=utf-8;' });
        let filename = 'Pallets History.csv';

        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, filename);
        } else {
          let link = document.createElement('a');
          if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style = 'visibility:hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      } else {
        setCSVError();
      }
    });
  };

  return (
    <>
      <div className="l-grid frc__heading-spacer">
        <h4>
          {t(`palletTransactions|Pallet Account Transactions for`)} {accountNumber}
        </h4>
      </div>

      <div className="l-grid l-grid--between-s l-grid--middle-s frc__heading-spacer">
        {/*TODO - use Link component + Lang*/}
        <a
          id="orderPalletPickupBtn"
          href={`${ROUTES.palletPickup}?accountNumber=${accountNumber}`}
          className="base-button base-button--wide base-button--white frc__generic-button"
        >
          <span className="frc__generic-button__span">{t('general|labels|buttons|Order Pallet Pickup')}</span>
        </a>
        <div
          id="downloadTransactionHistoryBtn"
          className="link"
          onClick={() => enableSaveHistoryCSV()}
          data-tracking='{"component":"fcp/components/downloadtransactionshistory", "name":"Download Transaction History (CSV)"}'
        >
          {t(`palletTransactions|Download Transaction History (CSV)`)}&nbsp;
          <span className="has-icon icon-pdf frc__font-size--small" />
          <br />
          {CSVError ? (
            <Error
              name="CSVError"
              className="frc__input--error"
              message={t(`palletTransactions|Something went wrong. Try again later.`)}
            />
          ) : (
            ''
          )}
        </div>
      </div>

      <ul className="frc-transactions-table">
        <li className="l-grid l-grid--between-s frc-transactions-table__headers">
          <div className="l-grid--w-auto-s">
            <strong>{t(`general|date`)}</strong>
          </div>
          <div className="l-grid--w-auto-s">
            <strong>{t(`palletTransactions|Shipment Number`)}</strong>
          </div>
          <div className="l-grid--w-auto-s text-center">
            <strong>{t(`palletTransactions|Credit`)}</strong>
          </div>
          <div className="l-grid--w-auto-s text-center">
            <strong>{t(`palletTransactions|Debit`)}</strong>
          </div>
          <div className="l-grid--w-auto-s text-center">
            <strong>{t(`palletTransactions|Balance`)}</strong>
          </div>
        </li>
      </ul>
      <ul className="frc-transactions-table">
        {data.map((row, index) => (
          <li className="l-grid l-grid--between-s frc-transactions-table__item" key={index}>
            <div className="l-grid--w-auto-s">{row.date.split('T')[0]}</div>
            <div className="l-grid--w-auto-s">{row.voucher}</div>
            <div className="l-grid--w-auto-s text-center">{row.in !== '' ? row.in : ''}</div>
            <div className="l-grid--w-auto-s text-center">{row.out !== '' ? row.out : ''}</div>
            <div className="l-grid--w-auto-s text-center">{row.balance}</div>
          </li>
        ))}
      </ul>

      <div className="l-grid l-grid--w-100pc-s">
        <div className="l-grid--w-100pc-s l-grid--w-50pc-s-l l-grid--w-50pc-m">
          <div className="frc__heading-spacer">
            <strong>{t('general|Note')}:</strong>
            <br />
            {t('general|Negative balance')} = {t('general|Number of pallets in credit')}
            <br />
            {t('general|Positive balance')} = {t('general|Number of pallets in debt')}
          </div>
        </div>
        <div className="l-grid--w-100pc-s l-grid--w-50pc-s-l l-grid--w-50pc-m">
          <div className="l-grid l-grid--right-s">
            <div className="l-grid l-grid--w-30pc-s l-grid--w-20pc-m frc-transactions-table-display">
              <span className="frc-transactions-table-display__span">{t(`general|Display`)}</span>
              <select
                id="displaySelect"
                onChange={handleChange}
                value={maxNoOfRows}
                className="c-form--element-base c-form--element-select"
              >
                {displayValues.map(value => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

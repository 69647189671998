import React from 'react';
import { useTranslation } from 'react-i18next';

import './Warning.css';

export default function Warning({ service = false, vas = false }) {
  const { t } = useTranslation();

  return (
    <div className="frc__warning-overlay margin-bottom-1">
      <>
        {service && (
          <>
            <strong> {t(`portalOrder|Warning`)}</strong>:
            <span
              className="frc__Warning-msg"
              dangerouslySetInnerHTML={{
                __html: t(`portalOrder|Pre-selected service not available`, {
                  service: service,
                }),
              }}
            />
          </>
        )}

        {vas && (
          <>
            <strong> {t(`portalOrder|Warning`)}</strong> :
            <span
              className="frc__Warning-msg"
              dangerouslySetInnerHTML={{
                __html: t(`portalOrder|Pre-selected vas not available`, {
                  vas: vas,
                }),
              }}
            />
          </>
        )}
      </>
    </div>
  );
}

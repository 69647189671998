import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AddressCustomCell(props) {
  const { cellValue } = props;
  const { t } = useTranslation();

  return (
    <div className="frc__custom-cell-line-break">
      <div>{cellValue.street || ''}</div>
      <div>
        {cellValue.postalCode || ''} {cellValue.city || ''}
      </div>
      <div>{t(`countries|${cellValue.countryCode}`)}</div>
    </div>
  );
}

import { Box, Typography, Stack } from '@mui/material';
import parse from 'html-react-parser';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCountryLanguage } from '../hooks/useCountryLanguage';
import { useTranslation } from 'react-i18next';
import { useGetCmsPhrasesQuery } from '../store/api/fcpCms';
import { Loader } from '../components';

export const CookieList = () => {
  const countryLanguage = useCountryLanguage();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const { data, isLoading } = useGetCmsPhrasesQuery({
    pageCode: 'cookieDisclaimer',
    lang: countryLanguage.language,
    countryCode: countryLanguage.country,
    isPreview: !!searchParams.get('preview'),
  });

  const cookieList = data?.find(phrase => phrase.code.includes('cookie-list'))?.translationValue;

  useEffect(() => {
    if (isLoading || cookieList) return;
    navigate('../error/404');
  }, [isLoading, cookieList, navigate]);

  return (
    <Box p={2} display="flex" justifyContent="center" textAlign="center">
      <Stack alignItems="center">
        <Typography variant="h1">{t('cookieDisclaimer|firstPartyCookies')}</Typography>
        {isLoading && <Loader />}
        <Box
          sx={{
            '& table': {
              color: 'black',
              borderCollapse: 'collapse',
            },
            '& tr:nth-of-type(7n)': {
              backgroundColor: '#FFF',
              '& td': {
                backgroundColor: '#FFF',
              },
            },
            '& td': {
              p: '10px',
              backgroundColor: 'background.section',
            },
          }}
        >
          {cookieList && parse(cookieList)}
        </Box>
      </Stack>
    </Box>
  );
};

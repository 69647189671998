export const envConfig = () => {
  if (process.env.NODE_ENV === 'production') {
    return window && window._env_ ? window._env_ : {};
  } else {
    if (!process.env) {
      return {};
    }
    return process.env;
  }
};

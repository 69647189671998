import { Button, Checkbox, Error, Input, PhoneNumber, Select } from 'components';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { POSTALCODE_ERRORS, PostalCodeInput } from '../../../../components/fcp-components/PostalCodeInput';
import {
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  SENDER_PAYER,
} from '../../../../globals/constants';
import { CONST, config } from '../../../data-config';
import { returnPostalCodeState } from '../../CommonUtils';
import { toggleActiveTab } from '../Template-helpers';
import AddressPickup from './AddressPickup/AddressPickup';
import ImportExportSection from './ImportExportSection/ImportExportSection';
import {
  afterCountry,
  checkContactEmail,
  renderDifferentDeliveryAddress,
  renderDifferentPickUpAddress,
  showTermsOfDeliveryDropdown,
  payerCode,
  readState,
} from './Route-helpers';
import './Route.css';
import { getCountryDropdownValues } from '../../../../globals/helpers/country-helpers';
import { getTermsOfDeliveryPossibleValues } from '../../../../globals/helpers/delivery-options';

export default class Route extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shippingToMultipleAddresses: false,
      isLoading: false,
      addressPickupModal: React.createRef(),
      productAPIError: false,
    };

    this.currentTab = React.createRef();
  }

  hideResponseMessage = property => {
    const that = this;

    setTimeout(() => {
      that.setState({
        ...that.state,
        [property]: 0,
      });
    }, 2000);
  };

  resetPostalCityError = context => {
    const stateToSet = {};
    stateToSet.postalCodeState = returnPostalCodeState();

    context.updateState(stateToSet);
  };

  showAddressPickup = party => {
    this.state.addressPickupModal.current.openModal(party);
  };

  isDomesticReceiverSenderAccount = () => {
    const { context } = this.props;
    return (
      context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE &&
      context.state.shipmentPayer.value === RECEIVER_PAYER &&
      context.state.accountNumber.isSelect &&
      context.state.accountNumber.value !== ''
    );
  };

  render() {
    const { context } = this.props;
    const products = context.state?.products;
    const accounts = context.state?.accounts;

    const accountProducts = accounts?.flatMap(account =>
      account?.products?.map(product => {
        const spec = products?.find(spec => spec?.code === product?.productCode);
        return { ...product, ...spec };
      }),
    );

    const hasB2CProduct = accountProducts?.some(product =>
      product.marketAvailability?.some(market => market.name?.toUpperCase() === 'B2C'),
    );
    const hasC2BProduct = accountProducts?.some(product =>
      product.marketAvailability?.some(market => market.name?.toUpperCase() === 'C2B'),
    );

    const residentialToDisabled =
      context.state.shipmentPayer.value === RECEIVER_PAYER || this.isDomesticReceiverSenderAccount() || !hasB2CProduct;

    const residentialFromDisabled =
      context.state.shipmentPayer.value === SENDER_PAYER || this.isDomesticReceiverSenderAccount() || !hasC2BProduct;

    return (
      <Translation>
        {t => (
          <div
            className="l-grid--w-100pc-s"
            onMouseLeave={() => toggleActiveTab(this.currentTab, false)}
            onMouseEnter={() => toggleActiveTab(this.currentTab, true)}
          >
            <div className="frc__app-tab " ref={this.currentTab}>
              <h3 className="frc__app-tab__title frc__app-margin-bottom-0">{t('general|Route')}</h3>
            </div>

            <div className="l-grid--w-100pc-s frc__generic-section--wrapper margin-bottom-1">
              <div className="l-grid--w-100pc-s">
                <ImportExportSection context={context} />
              </div>

              <div className="l-grid l-grid--between-s">
                <div className="l-grid--w-100pc-s l-grid--w-48pc-m margin-bottom-1">
                  <h4 className="margin-bottom-1 frc__red-lined-title">{t('general|From')}</h4>

                  <div className="l-grid c-form-step--item c-freightcalc--item-checkbox">
                    <Checkbox
                      label={t('general|labels|inputs|Pick up from a different address')}
                      labelAfter={
                        '&nbsp;<span class="has-tooltip"> ' +
                        '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                        t('general|pickupFromDifferentAddressTooltip') +
                        '</div></div>' +
                        '</span>'
                      }
                      name="pickupFromDifferentAddress"
                      isRequired={false}
                      checked={context.state.pickupFromDifferentAddress.value}
                      context={context}
                      config={config}
                      CONST={CONST}
                      disabled={context.state.pickupAddressResidential.value}
                      afterUpdate={this.resetPostalCityError}
                    />
                  </div>

                  <div className=" frc__generic--background-white frc__generic-section--wrapper">
                    <Checkbox
                      labelAfter={
                        '&nbsp;<span class="has-tooltip"> ' +
                        '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                        t('general|Pickup residential address tooltip') +
                        '</div></div>' +
                        '</span>'
                      }
                      label={t('general|This is a residential address')}
                      name="pickupAddressResidential"
                      isRequired={false}
                      checked={context.state.pickupAddressResidential.value}
                      context={context}
                      config={config}
                      CONST={CONST}
                      disabled={context.state.pickupFromDifferentAddress.value || residentialFromDisabled}
                      afterUpdate={checkContactEmail}
                    />
                    <div className="frc__address-book-btn l-grid--w-100pc-s">
                      <Button
                        id="addressBook"
                        dataTestId="senderAddressBookBtn"
                        submit={() => this.showAddressPickup(CONST.SENDER)}
                        className="base-button"
                        label={t(`general|Address Book`)}
                      />
                    </div>
                    <AddressPickup
                      ref={this.state.addressPickupModal}
                      className="color-secondary-green-1"
                      context={context}
                      residentialToDisabled={residentialToDisabled}
                      residentialFromDisabled={residentialFromDisabled}
                    />
                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper frc__company-search-position">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        regEx={config.regEx.everything}
                        label={t('general|Sender')}
                        name="pickupSender"
                        dataTestId="pickupSenderInp"
                        isRequired={false}
                        value={context.state.pickupSender.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                        cutTextLimit={config.maxDefaultInputLength}
                        hideAutoComplete={true}
                      />
                      {context.state.pickupSender.error ? (
                        <Error
                          className="frc__input--error"
                          message={t('general|errors|Company Error', { max: config.maxDefaultInputLength })}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                      <div className=" l-grid--w-100pc-s">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t('general|labels|inputs|Street')}
                          name="pickupStreet"
                          dataTestId="pickupStreetInp"
                          isRequired={false}
                          value={context.state.pickupStreet.value}
                          lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                        />
                        {context.state.pickupStreet.error ? (
                          <Error
                            className="frc__input--error"
                            message={t('general|errors|Street Error', { max: config.maxDefaultInputLength })}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                      <div className=" l-grid--w-100pc-s l-grid--w-50pc-m">
                        <PostalCodeInput
                          value={context.state.pickupPostalCode.value}
                          name="pickupPostalCode"
                          countryCode={context.state.pickupCountry.value}
                          error={
                            context.state.pickupPostalCode.error
                              ? POSTALCODE_ERRORS.validationError
                              : !context.state.pickupFromDifferentAddress?.value && this.state?.sourcePostalCodeError
                              ? POSTALCODE_ERRORS.apiError
                              : undefined
                          }
                          context={context}
                          config={config}
                          CONST={CONST}
                        />
                      </div>
                      <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t('general|labels|inputs|City')}
                          name="pickupCity"
                          dataTestId="pickupCityInp"
                          isRequired={false}
                          value={context.state.pickupCity.value}
                          lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                        />
                        {context.state.pickupCity.error ? (
                          <Error
                            className="frc__input--error"
                            message={t('general|errors|City Error', { max: config.maxDefaultInputLength })}
                          />
                        ) : (
                          ''
                        )}
                        {!context.state.pickupFromDifferentAddress.value
                          ? context.state.postalCodeState.sourceCityError && (
                              <Error className="frc__input--error" message={t('general|errors|City API Error')} />
                            )
                          : ''}
                      </div>
                    </div>
                    <div className=" l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Select
                        selectClass="l-grid--w-100pc-s"
                        label={t(`general|Country`)}
                        name="pickupCountry"
                        isRequired={false}
                        values={getCountryDropdownValues({
                          forceUserCountry:
                            context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE ||
                            (context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
                              context.state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE),
                          filterCountries: [context.state.deliveryCountry.value],
                        })}
                        disabled={
                          context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE ||
                          (context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
                            context.state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE)
                        }
                        value={context.state.pickupCountry.value?.toUpperCase()}
                        camparisonValue={{
                          value: context.state.deliveryCountry.value,
                          field: 'deliveryCountry',
                        }}
                        compare={context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE ? false : true}
                        context={context}
                        config={config}
                        CONST={{
                          API_DOMAIN: 'countries',
                        }}
                        afterUpdate={afterCountry}
                      />
                      {context.state.pickupCountry.error ? (
                        <Error
                          name="pickupCountry"
                          className="frc__input--error"
                          message={t(`general|Country Error`)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <p className="frc__contact-details-label" />

                  <div className="frc__generic--background-white frc__generic-section--wrapper">
                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        regEx={config.regEx.everything}
                        label={t(`general|Contact Name`)}
                        name="pickupName"
                        dataTestId="pickupNameInp"
                        isRequired={false}
                        value={context.state.pickupName.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                        cutTextLimit={config.maxDefaultInputLength}
                      />
                      {context.state.pickupName.error ? (
                        <Error
                          className="frc__input--error"
                          message={t('general|errors|Contact Name Error', { max: config.maxDefaultInputLength })}
                        />
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper frc__generic-position-relative">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t(`general|Contact E-mail`)}
                        name="pickupEmail"
                        dataTestId="pickupEmailInp"
                        isRequired={false}
                        value={context.state.pickupEmail.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxEmailLength}}$`)]}
                        cutTextLimit={config.maxEmailLength}
                        override="confirmationEmail"
                      />
                      {context.state.pickupEmail.error ? (
                        <Error
                          className="frc__input--error"
                          message={t('general|errors|E-mail Error', { chars: config.maxEmailLength })}
                        />
                      ) : (
                        ''
                      )}
                      <Checkbox
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t('general|Send notification sender')}
                        name="pickupEmailConfirmation"
                        isRequired={false}
                        checked={context.state.pickupEmailConfirmation.value}
                        className="frc__email-checkbox-confirmation"
                      />
                    </div>

                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
                      {context.state.pickupPhoneNumbers.slice(0, 1).map((phoneNumber, ind) => (
                        <PhoneNumber
                          config={config}
                          CONST={CONST}
                          context={context}
                          key={ind}
                          index={ind}
                          number={phoneNumber.number}
                          code={phoneNumber.code}
                          error={phoneNumber.error}
                          groupName="pickupPhoneNumbers"
                          isRequired={false}
                          // override={'preAdvicePhoneNumbers'}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                    <Input
                      context={context}
                      config={config}
                      CONST={CONST}
                      label={t('general|labels|inputs|Sender Reference')}
                      name="pickupSenderReference"
                      dataTestId="pickupSenderReferenceInp"
                      isRequired={false}
                      value={context.state.pickupSenderReference.value}
                      lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                      cutTextLimit={config.maxDefaultInputLength}
                    />
                    {context.state.pickupSenderReference.error ? (
                      <Error
                        className="frc__input--error"
                        message={t('general|errors|Invalid maxchar error', {
                          name: t('general|labels|inputs|Sender Reference'),
                          max: config.maxDefaultInputLength,
                        })}
                      />
                    ) : (
                      ''
                    )}
                  </div>

                  {context.state.pickupFromDifferentAddress.value &&
                    !context.state.pickupAddressResidential.value &&
                    renderDifferentPickUpAddress({
                      context,
                      t,
                      showAddressPickup: this.showAddressPickup,
                      residentialFromDisabled,
                    })}
                </div>
                <div className="l-grid--w-100pc-s l-grid--w-48pc-m" id="fcp-priority-service-error-id">
                  <h4 className="margin-bottom-1 frc__red-lined-title">{t('general|To')}</h4>
                  <div className="l-grid--w-100pc-s">
                    <Checkbox
                      label={t(`general|Deliver to a different address`)}
                      labelAfter={
                        '&nbsp;<span class="has-tooltip"> ' +
                        '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                        t('general|deliverToDifferentAddressTooltip') +
                        '</div></div>' +
                        '</span>'
                      }
                      name="deliverToDifferentAddress"
                      isRequired={false}
                      checked={context.state.deliverToDifferentAddress.value}
                      context={context}
                      config={config}
                      CONST={CONST}
                      disabled={context.state.deliveryAddressResidential.value}
                      afterUpdate={this.resetPostalCityError}
                    />
                  </div>

                  <div className="frc__generic--background-white frc__generic-section--wrapper">
                    <Checkbox
                      labelAfter={
                        '&nbsp;<span class="has-tooltip"> ' +
                        '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                        t(`general|Delivery residential address tooltip`) +
                        '</div></div>' +
                        '</span>'
                      }
                      label={t('general|This is a residential address')}
                      name="deliveryAddressResidential"
                      isRequired={false}
                      checked={context.state.deliveryAddressResidential.value}
                      context={context}
                      config={config}
                      CONST={CONST}
                      disabled={context.state.deliverToDifferentAddress.value || residentialToDisabled}
                      afterUpdate={checkContactEmail}
                    />
                    <div className="frc__address-book-btn l-grid--w-100pc-s">
                      <Button
                        id="addressBook"
                        dataTestId="receiverAddressBookBtn"
                        submit={() => this.showAddressPickup(CONST.RECEIVER)}
                        className="base-button  "
                        label={t(`general|Address Book`)}
                      />
                    </div>
                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        regEx={config.regEx.everything}
                        label={t('general|Receiver')}
                        name="deliveryReceiver"
                        dataTestId="deliveryReceiverInp"
                        isRequired={false}
                        value={context.state.deliveryReceiver.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                        cutTextLimit={config.maxDefaultInputLength}
                      />
                      {context.state.deliveryReceiver.error ? (
                        <Error
                          className="frc__input--error"
                          message={t('general|errors|Company Error', { max: config.maxDefaultInputLength })}
                        />
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                      <div className=" l-grid--w-100pc-s">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t('general|labels|inputs|Street')}
                          name="deliveryStreet"
                          dataTestId="deliveryStreetInp"
                          isRequired={false}
                          value={context.state.deliveryStreet.value}
                          lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                        />
                        {context.state.deliveryStreet.error ? (
                          <Error
                            className="frc__input--error"
                            message={t('general|errors|Street Error', { max: config.maxDefaultInputLength })}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                      <div className=" l-grid--w-100pc-s l-grid--w-50pc-m">
                        <PostalCodeInput
                          value={context.state.deliveryPostalCode.value}
                          name="deliveryPostalCode"
                          countryCode={context.state.deliveryCountry.value}
                          error={
                            context.state.deliveryPostalCode.error
                              ? context.state.deliveryPostalCode.error === POSTALCODE_ERRORS.apiError
                                ? POSTALCODE_ERRORS.apiError
                                : POSTALCODE_ERRORS.validationError
                              : !context.state.deliverToDifferentAddress?.value &&
                                this.state?.destinationPostalCodeError
                              ? POSTALCODE_ERRORS.apiError
                              : undefined
                          }
                          context={context}
                          config={config}
                          CONST={CONST}
                        />
                      </div>
                      <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t('general|labels|inputs|City')}
                          name="deliveryCity"
                          dataTestId="deliveryCityInp"
                          isRequired={false}
                          value={context.state.deliveryCity.value}
                          lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                        />
                        {context.state.deliveryCity.error ? (
                          <Error
                            className="frc__input--error"
                            message={t('general|errors|City Error', { max: config.maxDefaultInputLength })}
                          />
                        ) : (
                          ''
                        )}
                        {!context.state.deliverToDifferentAddress.value
                          ? context.state.postalCodeState.destinationCityError && (
                              <Error
                                className="frc__input--error"
                                message={t('general|errors|City API Error', {
                                  max: config.maxDefaultInputLength,
                                })}
                              />
                            )
                          : ''}
                      </div>
                    </div>

                    <div className=" l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Select
                        selectClass="l-grid--w-100pc-s"
                        label={t(`general|Country`)}
                        name="deliveryCountry"
                        isRequired={false}
                        values={getCountryDropdownValues({
                          forceUserCountry:
                            context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE ||
                            (context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
                              context.state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE),
                          filterCountries: [context.state.pickupCountry.value],
                        })}
                        disabled={
                          context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE ||
                          (context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
                            context.state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE)
                        }
                        value={context.state.deliveryCountry.value?.toUpperCase()}
                        camparisonValue={{
                          value: context.state.pickupCountry.value,
                          field: 'pickupCountry',
                        }}
                        compare={context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE ? false : true}
                        context={context}
                        config={config}
                        CONST={{
                          API_DOMAIN: 'countries',
                        }}
                        afterUpdate={afterCountry}
                      />
                      {context.state.deliveryCountry.error ? (
                        <Error
                          name="deliveryCountryError"
                          className="frc__input--error"
                          message={t(`general|Country Error`)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <p className="frc__contact-details-label" />

                  <div className="frc__generic--background-white frc__generic-section--wrapper">
                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        regEx={config.regEx.everything}
                        label={t(`general|Contact Name`)}
                        name="deliveryName"
                        dataTestId="deliveryNameInp"
                        isRequired={false}
                        value={context.state.deliveryName.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                        cutTextLimit={config.maxDefaultInputLength}
                      />
                      {context.state.deliveryName.error ? (
                        <Error
                          className="frc__input--error"
                          message={t('general|errors|Contact Name Error', { max: config.maxDefaultInputLength })}
                        />
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper frc__generic-position-relative">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t(`general|Contact E-mail`)}
                        name="deliveryEmail"
                        dataTestId="deliveryEmailInp"
                        isRequired={false}
                        value={context.state.deliveryEmail.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxEmailLength}}$`)]}
                        cutTextLimit={config.maxEmailLength}
                      />
                      {context.state.deliveryEmail.error ? (
                        <Error
                          className="frc__input--error"
                          message={t('general|errors|E-mail Error', { chars: config.maxEmailLength })}
                        />
                      ) : (
                        ''
                      )}
                      <Checkbox
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t('general|Send notification receiver')}
                        name="deliveryEmailConfirmation"
                        isRequired={false}
                        checked={context.state.deliveryEmailConfirmation.value}
                        className="frc__email-checkbox-confirmation"
                      />
                    </div>

                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
                      {context.state.deliveryPhoneNumbers.slice(0, 1).map((phoneNumber, ind) => (
                        <PhoneNumber
                          config={config}
                          CONST={CONST}
                          context={context}
                          key={ind}
                          index={ind}
                          number={phoneNumber.number}
                          code={phoneNumber.code}
                          removeOnParent={index => this.removePhoneNumber(index, context)}
                          error={phoneNumber.error}
                          groupName="deliveryPhoneNumbers"
                          isRequired={false}
                          // override={'preAdvicePhoneNumbers'}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                    <Input
                      context={context}
                      config={config}
                      CONST={CONST}
                      label={t('general|labels|inputs|Receiver Reference')}
                      name="deliveryReceiverReference"
                      dataTestId="deliveryReceiverReferenceInp"
                      isRequired={false}
                      value={context.state.deliveryReceiverReference.value}
                      lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                      cutTextLimit={config.maxDefaultInputLength}
                    />
                    {context.state.deliveryReceiverReference.error ? (
                      <Error
                        className="frc__input--error"
                        message={t('general|errors|Invalid maxchar error', {
                          name: t('general|labels|inputs|Receiver Reference'),
                          max: config.maxDefaultInputLength,
                        })}
                      />
                    ) : (
                      ''
                    )}
                  </div>

                  {context.state.deliverToDifferentAddress.value &&
                    !context.state.deliveryAddressResidential.value &&
                    renderDifferentDeliveryAddress({
                      context,
                      t,
                      showAddressPickup: this.showAddressPickup,
                      residentialToDisabled,
                    })}
                </div>
                {context.state.deliveryCountry.value && (
                  <div className="l-grid l-grid--w-100pc-s l-grid--right-s">
                    <div className="l-grid--w-100pc-s l-grid--w-48pc-m l-grid--left-s">
                      <div className="margin-bottom-1">
                        {showTermsOfDeliveryDropdown(context) && (
                          <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
                            <Select
                              selectClass="l-grid--w-100pc-s"
                              label={t(`incoterms|Terms of delivery`)}
                              name="termsOfDelivery"
                              isRequired={false}
                              specialCase={true}
                              values={getTermsOfDeliveryPossibleValues({
                                casePayerCode: payerCode(readState(context.state)),
                                productCode: context.state?.product?.value,
                              })}
                              value={context.state.termsOfDelivery.value}
                              context={context}
                              config={config}
                              CONST={{
                                API_DOMAIN: 'incoterms',
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div
                className={
                  'frc__generic--loader-overlay frc__pickup-location' + (this.state.isLoading ? ' is-loading ' : '')
                }
              />
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';
import { Error, Radio, TextArea } from 'components';
import { getTimeTable } from '../DeliveryOptions/DeliveryOptions-helpers';
import { analytics } from 'globals/utils/analytics';
import { checkPickupOptions, onTogglePickupOptions, validateAllInputs } from './SchedulePickup-helpers';
import { onSaveForFutureClick, saveShipmentCall } from './../../helpers/savedShipments';
import { checkFixedDateSelection } from '../../../globals/helpers/deliveryOptions-helpers';
import PickupDate from '../PickupDate/PickupDate';
import {
  pickupDatesSelector,
  productPickupDatesSelector,
  selectedPickupDateSelector,
  setPickupDates,
  setSelectedPickupDate,
} from '../../../store/portalOrderSlice';

const ENV_DEV = process.env.NODE_ENV === 'development';

class SchedulePickup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedulePickupOnly: false,
      saveForFutureError: false,
      nextBtnLoader: false,
      saveShipmentsError: false,
      saveForFutureLoader: false,
    };

    this.loaderRef = React.createRef();
  }

  togglePickupOptions = () => {
    this.setState({ schedulePickupOnly: onTogglePickupOptions(this.props) });
  };

  checkDateError = () => {
    return (
      this.props.context.state.pickupOption.value === 'Schedule Pickup' && !this.props.context.state.pickupDate.value
    );
  };

  componentDidMount() {
    this.togglePickupOptions();
    // set tab 4 touched
    this.props.context.updateState({ tab4OrTab5Touched: true });
    if (process.env.NODE_ENV !== 'test') window.scrollTo(0, 0);
  }

  handleSaveForFutureClick = async t => {
    this.setState({ saveForFutureLoader: true }, async () => {
      const result = await onSaveForFutureClick(this.props.context, t);

      if (result.error) {
        this.setState({ saveForFutureError: true, saveForFutureLoader: false });
      }
    });
  };

  onGoClick = async context => {
    // set loader
    this.setState({ nextBtnLoader: true }, () => {
      const validation = validateAllInputs(context);
      let error = false;
      const localStateToSet = {};

      context.updateState(validation.stateToSet, async () => {
        if (!validation.hasErrors) {
          const savedShipmentResult = await saveShipmentCall(this.props.context);

          if (!savedShipmentResult.error) {
            analytics('step 4', CONST.ANALYTICS);
            this.props.activateTab(config.tabs.tab5);
          } else {
            error = true;
            localStateToSet.saveShipmentsError = savedShipmentResult.error || false;
          }
        } else {
          ENV_DEV && console.alert('Can not submit - have errors');
          error = true;
        }

        if (error) {
          localStateToSet.nextBtnLoader = false;
          this.setState(localStateToSet);
        }
      });
    });
  };

  render() {
    const { state } = this.props.context;
    const disableBtn = this.state.nextBtnLoader || this.state.saveForFutureLoader;
    const committedDeliveryDateNotAvailable =
      config.ltcEurapidProductCodes.includes(state.selectedProduct.code.toString()) &&
      (state.selectedProduct.deliveryDate === 'ltc_error' || !state.selectedProduct.deliveryDate);

    const dateError = this.checkDateError();

    return (
      <div className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <div className="l-grid l-grid--between-s frc__generic--field-wrapper">
              <div className="l-grid--w-100pc-s l-grid--w-48pc-m margin-bottom-1">
                <h4 className="frc__red-lined-title">{t('general|Pickup')}</h4>

                <div className="l-grid--w-100pc-s">
                  {!this.state.schedulePickupOnly && (
                    <div className="margin-bottom-1">
                      <Radio
                        id={'Schedule Pickup'}
                        label={t('general|Schedule Pickup')}
                        name={'pickupOption'}
                        disabled={false}
                        isRequired={false}
                        value={'Schedule Pickup'}
                        checked={state.pickupOption.value === 'Schedule Pickup'}
                        context={this.props.context}
                        config={config}
                        CONST={CONST}
                        afterUpdate={checkPickupOptions}
                      />
                    </div>
                  )}
                  {state.pickupOption.value === 'Schedule Pickup' && (
                    <div className="frc__generic--background-white frc__generic-section--wrapper margin-bottom-1">
                      <PickupDate
                        isReturnProduct={state.pickupAddressResidential.value}
                        onGetTimeTable={values =>
                          getTimeTable(
                            values,
                            this.props.context,
                            this.loaderRef.current,
                            false,
                            true,
                            setSelectedPickupDate,
                          )
                        }
                        disabled={checkFixedDateSelection(this.props.context, true)}
                        contextUpdateField={this.props.context.updateField}
                        contextUpdateState={this.props.context.updateState}
                        contextPickupDateError={this.props.context.state.pickupDate.error}
                        contextSelectedProduct={this.props.context.state.selectedProduct}
                        contextMatchedProducts={this.props.context.state.matchedProducts}
                        shipmentRange={this.props.context.state.shipmentRange?.value}
                        payingAccountNumber={this.props.context.state.payingAccountNumber?.value}
                        pickupMinDate={this.props.context.state.pickupMinDate}
                        pickupDatesSelector={pickupDatesSelector}
                        productPickupDatesSelector={productPickupDatesSelector}
                        selectedPickupDateSelector={selectedPickupDateSelector}
                        setPickupDates={setPickupDates}
                        setSelectedPickupDate={setSelectedPickupDate}
                        additionalServices={this.props.context.state.additionalServices}
                      />

                      <>
                        <TextArea
                          className="frc__textarea--wrapper frc__generic--field-wrapper-no-top"
                          textAreaClassName="frc__textarea--tiny"
                          regEx={config.regEx.everything}
                          label={t(`general|Add Pickup Instructions (Optional)`)}
                          smallLabel={t(
                            `general|Add instructions for driver <strong>picking up</strong> this shipment`,
                          )}
                          name="pickupInstructions"
                          isRequired={false}
                          value={state.pickupInstructions.value}
                          lengthCheck={[RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)]}
                          cutTextLimit={config.maxInstructionsLength}
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                        />
                        {state.pickupInstructions.error && (
                          <Error
                            name="pickupInstructions"
                            className="frc__input--error"
                            message={t(`general|errors|Driver Instructions Error`, {
                              chars: config.maxInstructionsLength,
                            })}
                          />
                        )}
                      </>
                    </div>
                  )}
                  {!this.state.schedulePickupOnly && (
                    <>
                      <div className="margin-bottom-1">
                        <Radio
                          id={'Drop off at DHL'}
                          label={t(`general|Drop off a package at a DHL location`)}
                          name={'pickupOption'}
                          isRequired={false}
                          value={'Drop off a package at a DHL location'}
                          checked={state.pickupOption.value === 'Drop off a package at a DHL location'}
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          afterUpdate={checkPickupOptions}
                        />
                      </div>
                      <div className="margin-bottom-1">
                        <Radio
                          id={'Use scheduled pickup'}
                          label={t('general|Use an already scheduled pickup at my location')}
                          name={'pickupOption'}
                          isRequired={false}
                          value={'Use an already scheduled pickup at my location'}
                          checked={state.pickupOption.value === 'Use an already scheduled pickup at my location'}
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          afterUpdate={checkPickupOptions}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="l-grid--w-100pc-s">
                  {state.pickupOption.error && (
                    <Error
                      name={'pickupOption'}
                      className="frc__input--error"
                      message={t('general|errors|Please select one option')}
                    />
                  )}
                </div>
              </div>

              <div className="l-grid--w-100pc-s l-grid--w-48pc-m margin-bottom-1">
                <h4 className="frc__red-lined-title">{t(`general|Delivery Instructions`)}</h4>

                <TextArea
                  className="frc__textarea--wrapper frc__generic--field-wrapper-no-top"
                  textAreaClassName="frc__textarea--tiny"
                  regEx={config.regEx.everything}
                  label={t(`general|Add Delivery Instructions (Optional)`)}
                  smallLabel={t(`general|Add instructions for driver <strong>delivering</strong> this shipment`)}
                  name="deliveryInstructions"
                  isRequired={false}
                  value={state.deliveryInstructions.value}
                  lengthCheck={[RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)]}
                  cutTextLimit={config.maxInstructionsLength}
                  context={this.props.context}
                  config={config}
                  CONST={CONST}
                />
                {state.deliveryInstructions.error && (
                  <Error
                    name="deliveryInstructions"
                    className="frc__input--error"
                    message={t(`general|errors|Driver Instructions Error`, {
                      chars: config.maxInstructionsLength,
                    })}
                  />
                )}
              </div>

              <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper frc__spacebetween-firstleft">
                <button
                  id="backToDeliveryOptionsBtn"
                  className={'base-button base-button--white l-grid--w-25pc-w' + (disableBtn ? ' disabled' : '')}
                  onClick={disableBtn ? null : () => this.props.previousTab(config.tabs.tab3, 3)}
                  disabled={disableBtn}
                >
                  <span>{t(`general|Back to Delivery Options`)}</span>
                </button>
                <button
                  id="saveForFutureBtn"
                  className={
                    'base-button base-button--white l-grid--w-25pc-w frc__saveforfuturebtn ' +
                    (disableBtn ? ' disabled' : '') +
                    (this.state.saveForFutureLoader ? ' is-loading--right ' : '')
                  }
                  disabled={disableBtn}
                  onClick={disableBtn ? null : () => this.handleSaveForFutureClick(t)}
                >
                  <span>{t(`portalOrder|Save for future`)}</span>
                </button>
                <button
                  id="goToReviewAndBookBtn"
                  data-testid="next-button"
                  className={
                    'base-button base-button--wide l-grid--w-25pc-w' +
                    (disableBtn || dateError || committedDeliveryDateNotAvailable ? ' disabled ' : '') +
                    (this.state.nextBtnLoader ? ' is-loading--right ' : '')
                  }
                  onClick={
                    disableBtn || dateError || committedDeliveryDateNotAvailable
                      ? null
                      : () => this.onGoClick(this.props.context)
                  }
                  disabled={disableBtn || dateError || committedDeliveryDateNotAvailable}
                >
                  <span>{t('general|labels|buttons|Next Step')}</span>
                </button>
              </div>

              <div className="l-grid--w-100pc-s l-grid--right-s">
                {committedDeliveryDateNotAvailable && (
                  <Error
                    name="SchedulePickupLtcFailedForEurapid"
                    message={t('general|errors|Selected date not available, please try again')}
                  />
                )}

                {state.pickupOption.error || dateError ? (
                  <Error
                    name="SchedulePickuppickupOption"
                    message={t('general|errors|Please fill properly all necessary fields')}
                  />
                ) : (
                  ''
                )}

                <Error
                  name="saveForFutureBtn"
                  message={
                    this.state.saveShipmentsError || this.state.saveForFutureError
                      ? t(`portalOrder|Shipment could not be saved`)
                      : ''
                  }
                />
              </div>
            </div>
          )}
        </Translation>
        <div id="fcp-schedule-pickup-loader" ref={this.loaderRef} className="frc__generic--loader-overlay">
          {' '}
        </div>
      </div>
    );
  }
}

export default SchedulePickup;

import { PROFILE_CONSTANTS } from '../components/fcp-components/edit-profile/constants';

/**
 * Validates and matches the following allowed formats of account numbers:
    const string1 = '12345678.CZ1234';
    const string2 = '12345678.se1234';
    const string3 = '12345678.Pl1234';
    const string4 = '12345678.uk1234';
    const string5 = '12345678.US1234';
    const string6 = '12345678.ua1234';
    const string7 = '12345678.fi1234';
 */
export const validateGlobalAccount = ({ account, validateCountry = true }) => {
  const regex = /^\d{8}\.[A-Za-z]{2}\d{4}$/;
  const validGlobalAccount = regex.test(account);

  if (!validateCountry) {
    return validGlobalAccount;
  }

  const userCountry = localStorage.getItem('country')?.toLowerCase();

  if (!validGlobalAccount && userCountry === 'se') {
    return validateSwedishAccount({ account });
  }

  const match = account.match(/[A-Za-z]{2}/);
  const extractedCountryCode = match ? match[0]?.toLowerCase() : undefined;
  const validCountry = extractedCountryCode === userCountry;
  return validCountry && validGlobalAccount;
};

/**
 * Account numbers for Sweden must be 5-6 or 8 characters long
 */
export const validateSwedishAccount = ({ account }) => {
  const swedishAccountRegex = /^(\d{5,6}|\d{8})$/;
  const valid = swedishAccountRegex.test(account);
  return valid;
};

export const shouldValidateGlobalAccount = localStorage.getItem('country')?.toLowerCase() !== 'se';

export const validateAccountNumber = ({
  accountList,
  clearErrors,
  fieldId,
  id,
  setError,
  setValue,
  t,
  value,
  accountCountryCode,
}) => {
  const newAccountList = [...accountList].map(account => {
    if (account.id === id) {
      return { ...account, accountNumber: value };
    }
    return account;
  });

  setValue('accountList', newAccountList, { shouldDirty: true });

  if (!value) {
    setError(fieldId, {
      type: 'pattern',
      message: t('general|labels|inputs|invalidFormat'),
    });
    return false;
  }

  const allAccounts = newAccountList.map(item => item.accountNumber);
  const uniqueAccounts = new Set(allAccounts);
  const hasDuplicates = allAccounts.length !== uniqueAccounts.size;

  if (hasDuplicates) {
    setError(fieldId, {
      type: 'pattern',
      message: t('general|TMS Account Duplicated'),
    });
    return false;
  }

  if (value.length > PROFILE_CONSTANTS.accountNumberCharacterLimit) {
    setError(fieldId, {
      type: 'value',
      message: t('general|errors|Invalid maxchar error', {
        name: t('registration|accountNumber'),
        max: PROFILE_CONSTANTS.accountNumberCharacterLimit,
      }),
    });
    return false;
  } else {
    const isValidGlobalAccount = validateGlobalAccount({ account: value, validateCountry: false });

    if (isValidGlobalAccount) {
      clearErrors(fieldId, { required: true });

      const match = value.match(/[A-Za-z]{2}/);
      const extractedCountryCode = match ? match[0]?.toLowerCase() : undefined;

      if (extractedCountryCode !== accountCountryCode) {
        /**
         * The account country code must match the country code entered in account number
         */
        setError(fieldId, {
          type: 'value',
          message: t('general|labels|inputs|invalidCountryCode'),
        });
        return false;
      } else {
        return true;
      }
    } else if (accountCountryCode !== 'se') {
      setError(fieldId, {
        type: 'pattern',
        message: t('general|labels|inputs|invalidFormat'),
      });
      return false;
    }

    if (!isValidGlobalAccount && accountCountryCode === 'se') {
      if (validateSwedishAccount({ account: value })) {
        clearErrors(fieldId, { required: true });
        return true;
      } else {
        setError(fieldId, {
          type: 'pattern',
          message: t('general|labels|inputs|invalidFormat'),
        });
        return false;
      }
    }
  }
};

export const ACCOUNT_STATUS = { APPROVED: 'APPROVED', WAITING: 'WAITING' };

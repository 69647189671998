import i18n from 'i18next';
import { config, CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest, eraseCookie, getCookie, setCookie } from 'globals/utils/requests';
import { internationalLTCDeliveryTime } from '../EnterPickupLocation/EnterPickupLocation-helpers';
import { formatDate } from 'globals/utils/formatting';
import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';
import { formPieces } from '../Form-helpers/Pieces-helpers';
import { formPartyData } from '../Form-helpers/Party-helpers';
import { getAdditionalServices } from '../Form-helpers/AdditionalServices-helpers';
import { additionalServicesOptionsValidations } from 'globals/validators/AdditionalServices-validations';
import { setLoadersState } from '../ServiceDetails/ServiceDetails-helpers/ServiceDetails-General-helpers';
import { isFreeVas } from '../../../portal-order/components/DeliveryOptions/DeliveryOptions-helpers';
import { getNextWorkingDay } from '../../../globals/helpers/date';
import {
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE_CODE,
  IMPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE_CODE,
  INTERNATIONAL_SHIPMENT_RANGE,
} from '../../../globals/constants';
import { getQuoteForPriceAPICall } from '../../../globals/helpers/delivery-options';
import { hemleveransDependentVasCheck } from '../../../globals/helpers/additionalServices';

export const fillMarkupPriceFromCookie = context => {
  if (getCookie('markup')) {
    try {
      const markup = JSON.parse(getCookie('markup'));
      context.updateState({
        markup: {
          value: true,
          error: false,
          percentage: { value: markup.percentage, error: false },
          amount: { value: markup.amount, error: false },
        },
      });
      return true;
    } catch {
      return null;
    }
  } else {
    return false;
  }
};

export const setMarkupCookie = state => {
  if (state.markup.value && (state.markup.percentage.value || state.markup.amount.value)) {
    setCookie(
      'markup',
      JSON.stringify({
        percentage: state.markup.percentage.value,
        amount: state.markup.amount.value,
      }),
      CONST.ONE_MONTH,
      'h',
    );
    return true;
  } else {
    eraseCookie('markup');
    return false;
  }
};

export const createQuoteForPricePayloadBody = (routeState, shipmentState, deliveryState, countryConfig) => {
  const quoteDataMap = {};
  const totals = shipmentState.totals.shipmentDetailsRows;
  const { numberOfFullPallets, numberOfHalfPallets } = calculateHalfPalletAndFullPallet(
    shipmentState.shipmentDetailsRows,
  );

  quoteDataMap.shipment = {};

  quoteDataMap.shipment.totalNumberOfPieces = totals.quantity.value;
  quoteDataMap.shipment.totalWeight = totals.weight.value;
  quoteDataMap.shipment.totalVolume = totals.volume.value;
  quoteDataMap.shipment.totalLoadingMeters = totals.loadingMeter.value * 1;
  quoteDataMap.shipment.payerCode = routeState.payerCode;
  quoteDataMap.shipment.piece = formPieces(shipmentState);
  quoteDataMap.shipment.dhlProductCode = routeState.selectedProduct.value;
  quoteDataMap.shipment.parties = formPartyData(routeState, deliveryState);

  if (routeState.shipmentTypesImpOrExp.value !== '')
    quoteDataMap.shipment.importExport =
      routeState.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE
        ? IMPORT_SHIPMENT_TYPE_CODE
        : EXPORT_SHIPMENT_TYPE_CODE;

  if (numberOfFullPallets) quoteDataMap.shipment.numberOfFullPallets = numberOfFullPallets;
  if (numberOfHalfPallets) quoteDataMap.shipment.numberOfHalfPallets = numberOfHalfPallets;

  if (deliveryState && deliveryState.selectedProduct) {
    const additionalServices = getAdditionalServices(deliveryState.additionalServices, deliveryState, countryConfig);

    quoteDataMap.shipment.additionalServices = additionalServices.selectedAdditionalServices || null;

    if (config.homeDeliveryMapping[deliveryState.selectedProduct.code]) {
      quoteDataMap.shipment.dhlProductCode = getProductCodeForHDBasedOnVAS(deliveryState);
    } else {
      quoteDataMap.shipment.dhlProductCode = deliveryState.selectedProduct.code;
    }

    if (deliveryState.markup.value) {
      quoteDataMap.ownSurCharge = { percentage: 0, value: 0 };

      if (deliveryState.markup.percentage.value && !deliveryState.markup.percentage.error) {
        quoteDataMap.ownSurCharge.percentage = getCorrectValuesForMarkup(deliveryState.markup.percentage);
      }
      if (deliveryState.markup.amount.value && !deliveryState.markup.amount.error) {
        quoteDataMap.ownSurCharge.value = getCorrectValuesForMarkup(deliveryState.markup.amount);
      }
    }
  }

  return quoteDataMap;
};

export const getCorrectValuesForMarkup = attribute => {
  return attribute.value.toString().replace(',', '.').endsWith('.')
    ? attribute.value.slice(0, -1)
    : attribute.value.toString().replace(',', '.');
};

export const calculateHalfPalletAndFullPallet = shipmentDetailsRows => {
  let numberOfFullPallets = 0;
  let numberOfHalfPallets = 0;

  shipmentDetailsRows.forEach(row => {
    switch (row.shipmentType.value) {
      case 'full pallet':
        numberOfFullPallets += row.quantity.value * 1;
        break;
      case 'half pallet':
        numberOfHalfPallets += row.quantity.value * 1;
        break;
      default:
    }
  });

  return {
    numberOfFullPallets,
    numberOfHalfPallets,
  };
};

export const getProductBasedPrice = async (
  routeState,
  shipmentState,
  deliveryState,
  selectedProductCode,
  countryConfig,
) => {
  try {
    const quoteForPriceData = createQuoteForPricePayloadBody(routeState, shipmentState, deliveryState, countryConfig);
    const quoteForPriceResult = await getQuoteForPriceAPICall(quoteForPriceData);
    let productCode = selectedProductCode;

    if (config.homeDeliveryMapping[selectedProductCode]) {
      productCode = getProductCodeForHDBasedOnVAS(deliveryState);
    }

    if (quoteForPriceResult.error) {
      return { additionalServicesError: true, quotePriceMap: {} };
    } else if (
      quoteForPriceResult.data &&
      quoteForPriceResult.data.prices &&
      quoteForPriceResult.data.prices[productCode] &&
      Array.isArray(quoteForPriceResult.data.prices[productCode])
    ) {
      return {
        quotePriceMap: formQuoteResultMap(quoteForPriceResult.data.prices[productCode]),
        additionalServicesError: false,
      };
    } else {
      return { additionalServicesError: true, quotePriceMap: {} };
    }
  } catch {
    return { additionalServicesError: true, quotePriceMap: {} };
  }
};

export const formQuoteResultMap = formQuoteResultPrices => {
  const priceMap = {};

  if (formQuoteResultPrices && !formQuoteResultPrices.error) {
    formQuoteResultPrices.forEach(price => {
      priceMap[price.id] = price;
      priceMap[price.id].calculatedResult = price.value
        ? price.value + ' ' + price.unit
        : i18n.t('general|errors|No price available online');
    });
  }

  return priceMap;
};

const getProductCodeForHDBasedOnVAS = ({ additionalServices, selectedProduct }) => {
  const productCodes = additionalServices
    .filter(vas => vas.value)
    .map(selectedVAS => selectedVAS.productCode)
    .filter((val, i, arr) => arr.indexOf(val) === i)
    .sort();

  // possible chances -> [401, 501] , [401], [501]
  return productCodes[0] || selectedProduct.code;
};

export const postTimeTable = async (state, dateTime, context) => {
  const resultMap = {};
  const body = {
    dateTime: dateTime,
    countryCode: state.pickupCountry.value,
    postalCode: state.pickupPostalCode.value,
    deliveryCountryCode: state.deliveryCountry.value,
    deliveryPostalCode: state.deliveryPostalCode.value,
    earliestSent: true,
  };

  // Get available products and the possible pickup and delivery dates for DOMESTIC only
  await apiRequest(apiLinks.postTimeTable, 'POST', {
    body: body,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.status = true;
        resultMap.data = result.data.status && result.data.status === 'error' ? [] : addProductCode(result.data);
      } else if (result.status === CONST.BAD_REQUEST_ERROR) {
        resultMap.status = false;
        resultMap.data = [];
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const addProductCode = products => {
  products.forEach(product => {
    const availableProduct = config.allowedProducts.find(
      allowedProduct => allowedProduct.product.toLowerCase() === product.product.toLowerCase(),
    );

    product.code = availableProduct ? availableProduct.code : '';
  });

  return products;
};

export const setDeliveryDateError = matchedProducts => {
  if (matchedProducts && matchedProducts.length > 0) {
    matchedProducts.forEach(prod => {
      if (config.ltcEurapidProductCodes.includes(prod.code.toString())) {
        prod.disabled = true;
        prod.value = false;
      }

      prod.deliveryDate = 'ltc_error';
    });
  }

  return matchedProducts;
};

const getLTCProd = ltcResponse => {
  const ltcProd = {};

  ltcResponse.forEach(ltc => (ltcProd[ltc.product_code] = !!ltc.delivery_date));

  return ltcProd;
};

const isEurapidAvail = matchedProducts => {
  return matchedProducts.some(
    product => config.ltcEurapidProductCodes.includes(product.code.toString()) && product.value,
  );
};

const resetProduct = matchedProducts => {
  const eurapid = matchedProducts.find(product => config.ltcEurapidProductCodes.includes(product.code.toString()));
  eurapid.value = false;
  eurapid.disabled = true;

  return { matchedProducts, additionalServices: [] };
};

export const getDeliveryDates = async (date, props, t, loaderRef, resetVas = true, countryConfig) => {
  loaderRef.classList.add('is-loading');
  const isSwedenCountry = localStorage.getItem('country')?.toUpperCase() === 'SE';

  const routeState = props.routeContext.state;
  const { context } = props;

  let productTypeName = '';
  let productDeliveryDt = null;
  let formURL = '';
  let modifiedMatchedProducts = [];
  const matchedProducts = JSON.parse(JSON.stringify(context.state.matchedProducts));
  const stateToSet = {};
  const params = {};
  const isDomestic = routeState.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE;

  // DOMESTIC Sweden = postTimeTable
  if (isDomestic && isSwedenCountry) {
    const dateTime = formatDate(date);
    productTypeName = 'product';

    productDeliveryDt = await postTimeTable(routeState, dateTime);
  }
  // INTERNATIONAL + Domestic global = LTC
  else {
    productTypeName = 'product_code';

    formURL =
      apiLinks.ltc.commitedLeadtimes +
      '?country_from=' +
      routeState.pickupCountry.value +
      '&zip_from=' +
      routeState.pickupPostalCode.value +
      '&country_to=' +
      routeState.deliveryCountry.value +
      '&zip_to=' +
      routeState.deliveryPostalCode.value +
      '&customer_pickup_date=' +
      formatDate(date) +
      '&_=' +
      new Date().getTime().toString();

    productDeliveryDt = await internationalLTCDeliveryTime(formURL);
  }
  // LTC Success
  if (productDeliveryDt && !productDeliveryDt.error && productDeliveryDt.status) {
    if (matchedProducts) {
      //matched product updated if eurapid is selected
      modifiedMatchedProducts = addDeliveryDate(
        matchedProducts,
        productDeliveryDt.data ? productDeliveryDt.data : [],
        productTypeName,
        productDeliveryDt.hasOwnProperty('status') && productDeliveryDt.status,
        isDomestic,
      );

      if (routeState.productsRangeOne.value) {
        modifiedMatchedProducts[0].value = true;
        modifiedMatchedProducts[0].disabled = false;
        stateToSet.selectedProduct = JSON.parse(JSON.stringify(modifiedMatchedProducts[0]));
      }

      stateToSet.matchedProducts = modifiedMatchedProducts;

      const ltcProd = getLTCProd(productDeliveryDt.data);

      // euroconnect + euroline + other
      if (
        resetVas &&
        matchedProducts.some(prod => config.ltcNonEurapidProductCodes.includes(prod.code.toString()) && prod.value)
      ) {
        stateToSet.additionalServices = resetVASIfFixedDeliveryPresent(
          context.state.additionalServices,
          context.state.ltcFailed || !ltcProd['ECE'],
        );
      }

      if (!ltcProd['ERA'] && isEurapidAvail(matchedProducts)) {
        Object.assign(stateToSet, resetProduct(stateToSet.matchedProducts));
      }
    }

    stateToSet.ltcFailed = false;
  } else {
    //LTC Failed
    stateToSet.ltcFailed = true;

    // set ltc date error
    stateToSet.matchedProducts = setDeliveryDateError(matchedProducts);

    // product eurapid is disabled
    if (isEurapidAvail(context.state.matchedProducts)) {
      Object.assign(stateToSet, resetProduct(stateToSet.matchedProducts));
    } else {
      // perform reset on vas only when PU date called from step 3
      stateToSet.additionalServices = resetVASIfFixedDeliveryPresent(context.state.additionalServices, true);
    }
  }

  //make quote call if markup is present and if vas contains fixed delivery date because, the price differs when VAS is available
  if (
    stateToSet.matchedProducts.some(product => product.value) &&
    stateToSet.additionalServices &&
    JSON.stringify(stateToSet.additionalServices) !== JSON.stringify(context.state.additionalServices) &&
    stateToSet.additionalServices.some(VAS => config.vasExcludedForFailedLTC.indexOf(VAS.code) !== -1) &&
    context.state.markup.value
  ) {
    // callGetQuoteIfFixedDeliveryPresent
    params.afterUpdate = async (context, params) => {
      const props = { context: context, shipmentContext: params.shipmentContext, routeContext: params.routeContext };

      await getQuoteForPriceWithAd(props, false, params.t, countryConfig);
    };
    params.shipmentContext = props.shipmentContext;
    params.routeContext = props.routeContext;
    params.t = t;
  }

  const productPresent = stateToSet.matchedProducts.some(prod => prod.value);
  if (!productPresent) {
    stateToSet.selectedProduct = { code: '' };
  }

  context.extendedSetState(stateToSet, params);

  loaderRef.classList.remove('is-loading');
  return { additionalServices: stateToSet.additionalServices };
};

export const resetOptionsSchemaOnVasUnCheck = groups => {
  groups.forEach(group => {
    if (group.options && group.options.length > 0) {
      group.options.forEach(optionList => {
        optionList.forEach(option => {
          const resetOptionsSchema = config.resetOptionSchemaFieldTypes.indexOf(option.type) !== -1;
          const isListCOT = option.optionCode === config.OptionFieldTypes.ListCOT;

          if (resetOptionsSchema && !isListCOT) {
            option.value = '';
            option.error = false;
          } else if (resetOptionsSchema && isListCOT) {
            // reset to default terminal
            const defaultTerminal = option.values.find(
              listCotValue => listCotValue.terminal && listCotValue.terminal.defaultTerminal,
            );

            if (defaultTerminal) {
              option.value = defaultTerminal.value;
            }
            option.error = false;
          }
        });
      });
    }
  });

  return groups;
};

export const resetVASIfFixedDeliveryPresent = (additionalServices, fixedDeliveryDate = false) => {
  const modifiedVAS = JSON.parse(JSON.stringify(additionalServices));
  const someExcludedVas = additionalServices.some(VAS => {
    return config.vasExcludedForFailedLTC.indexOf(VAS.code) !== -1;
  });
  if (someExcludedVas) {
    modifiedVAS.forEach(VAS => {
      const isExcludedVas = config.vasExcludedForFailedLTC.indexOf(VAS.code) !== -1;
      if (isExcludedVas) {
        // Only uncheck this VAS if it is explicitly present in the config for vasExcludedForFailedLTC
        VAS.value = false;
        VAS.calculatedResult = null;
      }
      VAS.disabled = false;
      if (VAS.optionsSchema) {
        VAS.optionsSchema = resetOptionsSchemaOnVasUnCheck(JSON.parse(JSON.stringify(VAS.optionsSchema)));
      }

      if (config.vasExcludedForFailedLTC.indexOf(VAS.code) !== -1) {
        VAS.disabled = fixedDeliveryDate;
      }
    });
  }

  return modifiedVAS;
};

export const getAppropriateAccountNumberIfNoneIsAssigned = route => {
  const alternativeAccount = route.accounts.find(acc => {
    const domesticAccount = acc.products.find(product => {
      return config.domesticProducts.indexOf(product.productCode) !== -1;
    });

    return (domesticAccount && acc.code) || false;
  });

  // if none found, the first one is returned
  return alternativeAccount ? alternativeAccount.code : route.accounts[0].code;
};

export const initializePayloadBodyForPostGeoLocationCall = (route, product) => {
  const body = {};
  const marketAvailability =
    route.marketAvailability && route.marketAvailability.value ? route.marketAvailability.value : 'B2B';

  // If no TMS account is assigned to the product, then first one with domestic products is used. If none, then first one from the list.
  body.agreementNo = product.account || getAppropriateAccountNumberIfNoneIsAssigned(route); // || config.agreementNo;
  body.postalCode = marketAvailability === 'B2C' ? route.deliveryPostalCode.value : route.pickupPostalCode.value;
  body.cityName = marketAvailability === 'B2C' ? route.deliveryCity.value : route.pickupCity.value;
  body.deliveryType = config.homeDeliveryMapping[product.code].deliveryType;

  return body;
};

export const validateGeoLocation = async (routeContext, additionalServices, product, getVas = false) => {
  const selectedServices = [];
  const body = initializePayloadBodyForPostGeoLocationCall(routeContext.state, product);
  let resultMap = {};
  let url = getVas ? apiLinks.postGeoLocation : apiLinks.postGeoLocationValidator;

  if (additionalServices && additionalServices.length > 0) {
    additionalServices.forEach(additionalService => {
      if (additionalService.value) selectedServices.push(additionalService.code);
    });
  }

  if (selectedServices && selectedServices.length > 0) {
    body.additionalServices = selectedServices;
  }

  await apiRequest(url, 'POST', {
    body: body,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK && !(result.data.status && result.data.status === 'error')) {
        resultMap = result;
        resultMap.error = false;
      } else if (result.status === CONST.BAD_REQUEST_ERROR) {
        resultMap.status = false;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const checkSubmit = async (context, routeContext) => {
  let stateToSet = {};
  const { state } = context;
  const selectedProduct = JSON.parse(JSON.stringify(state.selectedProduct));

  if (selectedProduct && selectedProduct.code) {
    let deliveryMonitoringExpanded = false;

    if (
      document.getElementById('frc_additional-services_delivery_monitoring') &&
      document.getElementById('frc_additional-services_delivery_monitoring').classList.contains('expand')
    ) {
      deliveryMonitoringExpanded = true;
    }

    stateToSet = {
      selectProductError: false,
      deliveryMonitoringExpanded,
    };

    // VAS Validations
    const { adError, additionalServices } = additionalServicesOptionsValidations(
      JSON.parse(JSON.stringify(state.additionalServices)),
    );

    // FCPG-2348 HEMLEVERANS && DangerousGoods Limited quantity check
    const hemError = hemleveransDependentVasCheck(selectedProduct.code, additionalServices);

    stateToSet.additionalServicesGeneralError = adError || hemError;
    stateToSet.additionalServices = additionalServices;

    // Mark up validation
    if (context.state.markup.value) {
      let getMarkupState = {};
      const markup = JSON.parse(JSON.stringify(context.state.markup));

      if (markup.percentage.value) {
        getMarkupState = checkFieldAndReturnState({
          value: markup.percentage.value.toString().replace(',', '.') * 1 || '',
          regEx: config.regEx.float,
          lengthCheck: [RegExp(`^.{0,${config.maxMarkupDigits}}$`)],
          min: CONST.MIN_MARKUP_VALUE,
          max: CONST.MAX_MARKUP_PERCENTAGE,
        });
        // stateToSet.additionalServicesGeneralError = getMarkupState.error || stateToSet.additionalServicesGeneralError;
        // markup - percentage value and error
        markup.percentage.value = getMarkupState.value;
        markup.percentage.error = getMarkupState.error;
      }

      if (markup.amount.value) {
        getMarkupState = checkFieldAndReturnState({
          value: markup.amount.value.toString().replace(',', '.') * 1 || '',
          regEx: config.regEx.number,
          lengthCheck: [RegExp(`^.{0,${config.maxMarkupDigits}}$`)],
          min: CONST.MIN_MARKUP_VALUE,
          max: CONST.MAX_MARKUP_AMOUNT,
        });
        // stateToSet.additionalServicesGeneralError = getMarkupState.error || stateToSet.additionalServicesGeneralError;
        // markup - amount value and error
        markup.amount.value = getMarkupState.value;
        markup.amount.error = getMarkupState.error;
      }
      // set to delivery state
      stateToSet.markup = markup;
    }

    // Perform homedelivery|homedeliveryReturn check to validate if VAS combinations are true
    if (config.homeDeliveryProductCodes.indexOf(selectedProduct.code) !== -1) {
      const validation = await validateGeoLocation(
        routeContext,
        JSON.parse(JSON.stringify(context.state.additionalServices)),
        selectedProduct,
      );

      // setting product code based on result
      if (validation.error) stateToSet.goNextError = true;
      else selectedProduct.code = validation.data.productCode;
    }
  } else {
    document.getElementById('fcp-product-container').classList.add('has-pricelist-error');

    stateToSet = {
      selectProductError: true,
      goNextError: true,
    };
  }
  stateToSet.selectedProduct = selectedProduct;
  context.updateState(stateToSet);

  return stateToSet.goNextError || stateToSet.selectProductError || stateToSet.additionalServicesGeneralError;
};

export const addDeliveryDate = (matchedProducts, productsWithDeliveryDate, productTypeName, status, isDomestic) => {
  if (matchedProducts && matchedProducts.length > 0) {
    matchedProducts.forEach(async prod => {
      if (status) {
        const item = productsWithDeliveryDate.find(pdWDd => {
          return (
            (pdWDd[productTypeName] && pdWDd[productTypeName].toLowerCase()) ===
            (prod.product_code && prod.product_code.toLowerCase())
          );
        });

        if (item) {
          // prod.deliveryDate = item.deliveryDate ? getDateFromDateTimeString(item.deliveryDate) : item.delivery_date;
          prod.product_features = item.product_features ? item.product_features : undefined;
          // prod.LTCDates = item.available_fixed_delivery_dates || [];
          prod.possible_pickups = item.possible_pickups;
          prod.possible_pickups_holidays = item.possible_pickups_holidays;

          if (config.ltcEurapidProductCodes.includes(prod.code.toString()) && prod.deliveryDate) {
            prod.disabled = false;
          }

          if (item.options !== undefined) {
            prod.priorityServices = item.options;
          }
          // TODO maybe here will be needed as well
          // const possiblePickupsWithCutOff = await removePickupDatesByCutOff(prod.code, prod.possible_pickups);
          // if (!possiblePickupsWithCutOff.error && possiblePickupsWithCutOff.data) {
          //   prod.possible_pickups = possiblePickupsWithCutOff.data?.pickupDates;
          // }
        } else {
          // prod.deliveryDate = 'ltc_error';
          prod.product_features = [];
          // prod.LTCDates = [];
          prod.possible_pickups = [];
          prod.possible_pickups_holidays = [];

          if (config.ltcEurapidProductCodes.includes(prod.code.toString)) {
            prod.disabled = true;
          }
        }
      } else {
        if (config.ltcProductCodes.indexOf(prod.code) !== -1) {
          // prod.LTCDates = [];
          // prod.deliveryDate = 'ltc_error';

          // CR-54 Ltc committed delivery date api failure
          if (config.ltcEurapidProductCodes.includes(prod.code.toString())) {
            prod.disabled = true;
          }
        }
      }
    });
  }

  return matchedProducts;
};

export const getDesiredPickDates = async (product, context, isSwedenCountry) => {
  const dates = [];

  if (product.possible_pickups && product.possible_pickups.length > 0) {
    const pickupDate = JSON.parse(JSON.stringify(context.state.pickupDate));
    const defaultDate = pickupDate.value.split('T');
    let getNextPossibleDate = product.possible_pickups.indexOf(defaultDate[0]) === -1;

    product.possible_pickups.forEach(date => {
      // Pushes date to the filter list
      dates.push(new Date(date));

      // check for next possible date if needed
      if (getNextPossibleDate && defaultDate[0] < date) {
        pickupDate.value = `${date}T${defaultDate[1]}`;
        getNextPossibleDate = false;
      }
    });

    context.updateState({ desiredPickupDates: dates, pickupDate });
  } else {
    if (!isSwedenCountry) {
      const pickupDate = {
        value: getNextWorkingDay(),
      };
      context.updateState({ pickupDate });
    }
  }
};

export const applyDesiredPickupDates = props => {
  const isSwedenCountry = localStorage.getItem('country')?.toUpperCase() === 'SE';
  let matchedProductForPickupDates = {};

  props.context.state.matchedProducts.forEach(prod => {
    if (config.desiredPickupDateMainProductCode.includes(prod.code.toString())) {
      matchedProductForPickupDates = prod;
    }
  });

  // Apply dates from LTC only on International case + Domestic Global
  if (
    props.routeContext.state.shipmentRange &&
    (props.routeContext.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE ||
      (props.routeContext.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE && !isSwedenCountry))
  ) {
    getDesiredPickDates(matchedProductForPickupDates, props.context, isSwedenCountry);
  }
};

export const getQuoteForPriceWithAd = async (props, additionalServiceRow, t, countryConfig) => {
  let adError = false;
  let stateObj = {};
  let additionalServices = [];
  let newAdditionalServices = [];
  const { context } = props;

  if (additionalServiceRow) {
    additionalServices = JSON.parse(JSON.stringify(context.state.additionalServices));
    const response = additionalServicesOptionsValidations(additionalServices);
    adError = response.adError;
    additionalServices = response.additionalServices;

    context.updateState({ additionalServices: additionalServices, additionalServicesGeneralError: adError });
  }

  if (!adError) {
    context.updateState(setLoadersState(context, true), async () => {
      stateObj = await getProductBasedPrice(
        props.routeContext.state,
        props.shipmentContext.state,
        context.state,
        context.state.selectedProduct.code,
        countryConfig,
      );

      if (!stateObj.additionalServicesError) {
        newAdditionalServices = additionalServices;

        newAdditionalServices.forEach(AD => {
          if (AD.value) {
            if (stateObj.quotePriceMap[AD.code]) {
              AD.calculatedResult = stateObj.quotePriceMap[AD.code].calculatedResult;
            } else {
              const freeVas = isFreeVas({
                freeVas: countryConfig?.freeVass,
                vasCode: AD.code,
                selectedProductCode: context.state.selectedProduct.code,
              });
              if (freeVas) {
                AD.calculatedResult = t(`general|0 (free of charge)`);
              } else {
                AD.calculatedResult = t('general|errors|No price available online');
              }
              AD.translationWhenPriceNotAvailable = true;
            }
          }
        });

        context.updateState({
          additionalServices: newAdditionalServices,
          ...stateObj,
          ...setLoadersState(context, false),
        });
      } else {
        context.updateState({ ...stateObj, ...setLoadersState(context, false) });
      }
    });
  }
};

export const applyCutOffOnPickupDates = async (props, loaderRef, countryConfig) => {
  const isSwedenCountry = localStorage.getItem('country')?.toUpperCase() === 'SE';
  if (isSwedenCountry) return;
  // applicable for Global countries only

  const { context, routeContext } = props;
  const matchedProducts = JSON.parse(JSON.stringify(context.state.matchedProducts));
  let selectedProduct = JSON.parse(JSON.stringify(context.state.selectedProduct));
  let pickupDate = props.context.state.pickupDate;
  let pickupMinDate = new Date();

  for await (const matchedProduct of matchedProducts) {
    if (routeContext.state.productsRangeOne.value) {
      matchedProduct.value = true;
      selectedProduct = JSON.parse(JSON.stringify(matchedProduct));
    }
  }
  context.updateState({ matchedProducts, selectedProduct, pickupDate, pickupMinDate });

  // Remove after refactoring
  // if (routeContext.state.ltcFailed) {
  //   await getDeliveryDates(newPickupDate, props, props.t, loaderRef, false, countryConfig);
  // }
};

import React, { useState, useEffect } from 'react';
import { Translation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { locales } from 'locale/datePicker';
import { TextArea } from 'components';
import { Error } from 'components';
import { config } from '../../data-config';
import { domesticHolidays } from 'globals/data-holidays';
import getLanguage from 'globals/utils/getLanguage';
import { formatDate, DATE_FORMAT } from 'globals/utils/formatting';

export default function PickupDate({ context }) {
  const [selectedDate, setSelectedDate] = useState(
    context.state.pickupDate.value ? new Date(context.state.pickupDate.value) : '',
  );

  const handleChange = (date, e, context) => {
    e.preventDefault();

    const params = {
      name: 'pickupDate',
      value: date,
      error: context.state.pickupDate.error,
      afterUpdate: () => {
        setSelectedDate(date ? new Date(date) : '');
      },
    };

    context.updateField(params);
  };

  useEffect(() => {
    if (formatDate(context.state.pickupDate.value) !== formatDate(selectedDate)) {
      setSelectedDate(new Date(context.state.pickupDate.value));
    }
    // eslint-disable-next-line
  }, [new Date(context.state.pickupDate.value)]);

  const language = getLanguage(config.regEx.urlValidation);
  const minDate = new Date();
  const maxDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
  const holidays = domesticHolidays || [];

  return (
    <Translation>
      {t => (
        <>
          <div className="margin-bottom-0 frc__generic-section--wrapper">
            <div>
              <h4 className="margin-bottom-0">{t('general|Pickup Date')}</h4>
              <DatePicker
                id="desiredPickupDate"
                autoComplete="off"
                selected={selectedDate}
                className="c-form--element-base c-form--element-input c-calculator--input-field"
                showDisabledMonthNavigation
                minDate={minDate}
                maxDate={maxDate}
                onChange={(date, e) => handleChange(date, e, context)}
                locale={locales[language]}
                dateFormat={DATE_FORMAT}
                dayClassName={date =>
                  holidays.indexOf(
                    new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0],
                  ) > -1
                    ? 'color-DHL-red'
                    : undefined
                }
              />

              {!context.state.pickupDate.value && (
                <Error
                  name="shippingOrPickupDateError"
                  className="frc__input--error"
                  message={t('general|errors|Please select date')}
                />
              )}
            </div>
          </div>
          <div className="margin-bottom-0 frc__generic-section--wrapper">
            <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
              <TextArea
                className=" frc__textarea--wrapper frc__generic--field-wrapper-no-top "
                textAreaClassName="frc__textarea--tiny"
                regEx={config.regEx.everything}
                label={t(`general|Add Pickup Instructions (Optional)`)}
                smallLabel={t(`general|Add instructions for driver <strong>picking up</strong> this shipment`)}
                name="instructions"
                isRequired={false}
                context={context}
                value={context.state.instructions.value}
                lengthCheck={[RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)]}
                cutTextLimit={config.maxInstructionsLength}
              />
              {context.state.instructions.error ? (
                <Error
                  name="instructions"
                  className="frc__input--error"
                  message={t(`general|errors|Driver Instructions Error`, {
                    chars: config.maxInstructionsLength,
                  })}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </>
      )}
    </Translation>
  );
}

import React, { Component } from 'react';
import { config, CONST } from '../../data-config';
import { Translation } from 'react-i18next';
import { Error, TextArea, Checkbox } from 'components';
import PickupDate from '../PickupDate/PickupDate';
import SelectServices from './SelectServices/SelectServices';
import AdditionalServices from './AdditionalServices/AdditionalServices';
import ServicePointLocatorDialog from './ServicePointLocatorDialog/ServicePointLocatorDialog';
import SelectServicePoint from './SelectServicePoint/SelectServicePoint';
import { analytics } from 'globals/utils/analytics';
import { disableOtherTabs } from '../AppTabs/AppTabs-helpers';
import { getAdditionalServicesForOneSelectedProduct } from '../ServiceDetails/ServiceDetails';
import {
  servicePointCallAndFormDesiredPickupDate,
  updateServicePointToReceiverAddress,
  checkSubmit,
  getTimeTable,
  isValidBankPlusGiroNumber,
} from './DeliveryOptions-helpers';
import { fillDeliveryOptionsData, servicePtResAddress } from '../EnterPickupLocation/SetTemplate-helpers';
import { saveShipmentCall } from './../../helpers/savedShipment';
import { TermsOfDelivery } from '../../../portal-order/components/TermsOfDelivery/TermsOfDelivery';
import { withQueryClient } from '../../../utils/withQueryClient';
import { countryConfigQueryKey } from '../../../hooks/api/useGetCountryConfig';
class DeliveryOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogVisible: false,
      additionalServicesGeneralError: false,
      nextBtnLoader: false,
      deliveryOptionsError: false,
      lastNonResidentialAddress: null,
    };

    this.loaderRef = React.createRef();
  }

  componentDidMount = async () => {
    const countryConfig = this.props.queryClient.getQueryData([countryConfigQueryKey], {
      type: 'active',
      exact: false,
    });

    if (process.env.NODE_ENV !== 'test') window.scrollTo(0, 0);

    // make call only when tab 4 or tab 5 are not touched
    if (!this.props.context.state.tab4OrTab5Touched) {
      this.loaderRef.current.classList.add('is-loading');
      const servicePtId =
        this.props.context.state.servicePointId ||
        (this.props.context.state.templateData && this.props.context.state.templateData.servicePoint);

      let stateToSet = await servicePointCallAndFormDesiredPickupDate(this.props.context, servicePtId);
      if (stateToSet?.selectedServicePoint && stateToSet?.servicePoints) {
        this.props.context.updateState({
          servicePoints: stateToSet.servicePoints,
          selectedServicePoint: stateToSet.selectedServicePoint,
        });
      }

      // template data - fill
      if (this.props.context.state.templateData) {
        stateToSet = Object.assign(await fillDeliveryOptionsData(this.props.context, countryConfig), stateToSet);
        Object.assign(stateToSet, servicePtResAddress(stateToSet));
      } else if (this.props.context.state.selectedProduct.code) {
        // make vas call - if product is previously selected - may change of country expected
        Object.assign(
          stateToSet,
          await getAdditionalServicesForOneSelectedProduct(
            this.props.context.state,
            true,
            this.props.context.state.selectedProduct,
            countryConfig,
          ),
        );
      }
      // | 1) no data about selected product initially| 2) postal codes change - service pt data changes - change the vas accordingly
      this.loaderRef.current.classList.remove('is-loading');
      this.props.context.updateState(stateToSet);
    }
  };

  handleDialog = dialogState => {
    this.setState({
      isDialogVisible: dialogState,
    });
    this.props.context.updateState({
      servicePointsError: false,
    });
  };

  enableOtherTabsOnError = localStateToSet => {
    disableOtherTabs(this.props, false, [3], config.tabs.tab3);
    localStateToSet.nextBtnLoader = false;

    this.setState(localStateToSet);
  };

  onGoClick = async () => {
    disableOtherTabs(this.props, true, [3], config.tabs.tab3);

    if (this.props.context.state.isb2cProduct) {
      if (!this.props.context.state.selectedServicePoint.cityName) {
        this.setState({ deliveryOptionsError: true });
        return;
      }
    }

    this.setState({ nextBtnLoader: true, deliveryOptionsError: false }, async () => {
      // Validation on submit
      const checkPerformedSuccessfully = !(await checkSubmit(this.props.context));
      const localStateToSet = {};
      //Validate Bank Giro or Plus Giro
      const isValidBankPlusGiro = await isValidBankPlusGiroNumber(this.props.context);

      if (checkPerformedSuccessfully && isValidBankPlusGiro) {
        const savedShipmentResult = await saveShipmentCall(this.props.context);

        if (!savedShipmentResult.error) {
          analytics('step 3', CONST.ANALYTICS);
          this.props.nextTab(this.props.context.state.returnProdStatus, config.tabs.tab4);
        } else {
          // set save shipment error
          localStateToSet.saveShipmentsError = savedShipmentResult.error || false;
          this.enableOtherTabsOnError(localStateToSet);
        }
      } else {
        this.enableOtherTabsOnError(localStateToSet);
      }
    });
  };

  render() {
    const { state } = this.props.context;
    let error = this.props.context.state.selectedServicePoint.cityName ? false : true;
    let disableNextButton =
      (this.props.context.state.isb2cProduct && error) ||
      this.props.context.state.additionalServicesError ||
      !this.props.context.state.pickupDate.value;

    const servicePoint = state.servicePointUseResidentialAddress.value
      ? state.selectedServicePoint
      : state.selectedServicePoint.cityName
      ? state.selectedServicePoint
      : state.servicePoints.length > 0
      ? state.servicePoints[0]
      : {};

    return (
      <div className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <>
              <PickupDate
                context={this.props.context}
                isReturnProduct={state.pickupAddressResidential.value}
                onGetTimeTable={value => getTimeTable(value, this.props.context, this.loaderRef.current, true)}
              />
              <SelectServices context={this.props.context} />
              {this.state.isDialogVisible ? (
                <ServicePointLocatorDialog context={this.props.context} closeDialog={() => this.handleDialog(false)} />
              ) : (
                ''
              )}
              {state.isb2cProduct ? (
                <>
                  <SelectServicePoint
                    isServicePointTitle={state.servicePointUseResidentialAddress.isProductServicePointDelivery}
                    servicePoint={servicePoint}
                    state={state}
                  />

                  {state.initialServicePointStatus ? (
                    <button
                      id="changeServicePointLocationBtn"
                      className="frc__red-text-button"
                      onClick={() => this.handleDialog(true)}
                    >
                      {t(`general|Change service point location`)}
                    </button>
                  ) : (
                    ''
                  )}
                  {!state.servicePointUseResidentialAddress.isProductServicePointDelivery && (
                    <Checkbox
                      inlineTooltip={t('general|Use residential address tooltip')}
                      label={t('general|Use residential address')}
                      name={'servicePointUseResidentialAddress'}
                      context={this.props.context}
                      checked={state.servicePointUseResidentialAddress.value}
                      isRequired={false}
                      config={config}
                      CONST={CONST}
                      disabled={state.servicePointUseResidentialAddress.disabled}
                      updateOnParent={params => {
                        if (params.value) {
                          this.setState({ lastNonResidentialAddress: servicePoint });
                        } else {
                          this.setState({ lastNonResidentialAddress: null });
                        }
                        updateServicePointToReceiverAddress(params, this.props, this.state.lastNonResidentialAddress);
                      }}
                    />
                  )}
                </>
              ) : (
                ''
              )}
              <TermsOfDelivery context={this.props.context} loaderRef={this.loaderRef} isPublicOrder />
              <AdditionalServices loaderRef={this.loaderRef} context={this.props.context} />
              {state.returnProdStatus && (
                <div className="l-grid--w-100pc-s l-grid--w-48pc-m margin-bottom-1">
                  <h4 className="frc__red-lined-title">{t(`general|Delivery Instructions`)}</h4>

                  <TextArea
                    className="frc__textarea--wrapper frc__generic--field-wrapper-no-top"
                    textAreaClassName="frc__textarea--tiny"
                    regEx={config.regEx.everything}
                    label={t(`general|Add Delivery Instructions (Optional)`)}
                    smallLabel={t(`general|Add instructions for driver <strong>delivering</strong> this shipment`)}
                    name="deliveryInstructions"
                    isRequired={false}
                    value={state.deliveryInstructions.value}
                    lengthCheck={[RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)]}
                    cutTextLimit={config.maxInstructionsLength}
                    context={this.props.context}
                  />
                  {state.deliveryInstructions.error && (
                    <Error
                      name="deliveryInstructions"
                      className="frc__input--error"
                      message={t(`general|errors|Driver Instructions Error`, {
                        chars: config.maxInstructionsLength,
                      })}
                    />
                  )}
                </div>
              )}
              <div className="frc__generic-row--wrapper">
                <div className="l-grid l-grid--w-100pc-s l-grid--between-s">
                  <button
                    id="backToShipmentDetailsBtn"
                    className={`base-button base-button--white l-grid--w-25pc-w ${
                      this.state.nextBtnLoader ? 'disabled' : ''
                    }`}
                    onClick={() => this.props.previousTab('tab2', 2)}
                  >
                    <span>{t(`general|Back to Shipment Details`)}</span>
                  </button>
                  <button
                    id="goToSchedulePickupBtn"
                    data-testid="next-button"
                    className={
                      'base-button base-button--wide l-grid--w-25pc-w ' +
                      (this.state.nextBtnLoader ? ' is-loading--right' : '') +
                      (disableNextButton ? 'disabled' : '')
                    }
                    disabled={disableNextButton}
                    onClick={disableNextButton ? null : () => this.onGoClick()}
                  >
                    <span>{t('general|labels|buttons|Next Step')}</span>
                  </button>
                </div>
                <div className="l-grid--right-s">
                  {state.additionalServicesError ||
                  this.state.saveShipmentsError ||
                  (state.isb2cProduct && error) ||
                  state.additionalServicesGeneralError ||
                  !state.pickupDate.value ? (
                    <Error
                      name="vasDifferentErrors"
                      message={
                        state.isb2cProduct && error
                          ? t(`general|Cannot continue without delivery options`)
                          : state.additionalServicesGeneralError || !state.pickupDate.value
                          ? t('general|errors|Please fill properly all necessary fields')
                          : this.state.saveShipmentsError
                          ? t(`publicOrder|Shipment could not be saved`)
                          : t('general|errors|General error') +
                            '. <br> ' +
                            t('general|Try again or call technical support.') +
                            ' ' +
                            t('general|or') +
                            ' ' +
                            `<strong><a href="${t('pageLinks|chat')}"
                                              class ="frc__chat-with-us"
                                              target="_blank" rel="noopener noreferrer">${t(
                                                `general|Chat with us`,
                                              )}</a></strong>` +
                            ' <br> ' +
                            t('general|ID') +
                            ': ' +
                            state.transactionId
                      }
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div
                id="fcp-delivery-options-loader"
                ref={this.loaderRef}
                className={
                  'frc__generic--loader-overlay' +
                  (this.props.context.state.loaders.additionalServices.value || this.props.context.state.hereLoading
                    ? ' is-loading '
                    : '')
                }
              >
                {' '}
              </div>
            </>
          )}
        </Translation>
      </div>
    );
  }
}

export default withQueryClient(DeliveryOptions);

import i18n from 'i18next';
import { allCountries } from './data-countries';

export const sortCountries = (removeDefaultOption = false) => {
  const topDisplayedCountries = [''];
  const bottomDisplayedCountries = [];

  // Arrange top display countries in Nordic order
  const sortedCountries = allCountries.filter(country => topDisplayedCountries.indexOf(country.code) !== -1);
  // Build bottomDisplayedCountries array
  allCountries.forEach(country => {
    if (topDisplayedCountries.indexOf(country.code) === -1) {
      bottomDisplayedCountries.push(country);
    }
  });

  bottomDisplayedCountries.sort((a, b) => {
    return i18n.t(`countries|${a.code}`)?.localeCompare(i18n.t(`countries|${b.code}`));
  });
  sortedCountries.push(...bottomDisplayedCountries);

  // remove default option
  if (removeDefaultOption) {
    sortedCountries.shift();
  }

  return sortedCountries;
};

export const sortCountriesAlpha = ({ ignoreSelectCountry = false, filterCountries = [], selectCountries = [] }) => {
  let result = structuredClone(allCountries);
  result.sort((a, b) => {
    if (a.code === '') {
      return -1;
    } else if (b.code === '') {
      return 1;
    } else {
      return i18n.t(`countries|${a.code}`)?.localeCompare(i18n.t(`countries|${b.code}`));
    }
  });

  if (ignoreSelectCountry) {
    result = result.filter(country => country.code.toLowerCase() !== '');
  }

  if (filterCountries?.length) {
    const filterCountryCodes = filterCountries.map(countryCode => countryCode.toLowerCase());
    result = result.filter(country => !filterCountryCodes.includes(country.code.toLowerCase()));
  }

  if (selectCountries?.length) {
    const selectCountryCodes = selectCountries.map(countryCode => countryCode.toLowerCase());
    result = result.filter(country => selectCountryCodes.includes(country.code.toLowerCase()));
  }

  return result;
};

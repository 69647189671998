import { useEffect } from 'react';

export const useLeavePage = (isUnsafeTabClose, confirmationMessage) => {
  useEffect(() => {
    const handleBeforeUnload = event => {
      if (isUnsafeTabClose) {
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isUnsafeTabClose, confirmationMessage]);
};

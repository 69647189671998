// Check for selected product
import { config } from '../../../data-config';
import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';
import { RECEIVER_PAYER, THIRD_PARTY_PAYER } from '../../../../globals/constants';

export const serviceDetailsValidations = (context, stateToSet, hasErrors) => {
  const state = context.state;
  let getState = {
    name: 'productSelectionError',
    error: !(context.state.selectedProducts && context.state.selectedProducts.length > 0),
  };

  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = getState.error;

  // TMS account check
  getState = checkFieldAndReturnState({
    name: 'accountNumber',
    value: state['accountNumber'].value,
    isRequired: true,
    regEx: config.regEx.alphaNumericWithDot,
    lengthCheck: [/^.{5,6}$/, /^.{8,15}$/],
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Receiver account check
  if (
    state.selectedProducts &&
    state.selectedProducts[0] === 'dhlParti' &&
    state.payer &&
    state.payer.value === RECEIVER_PAYER
  ) {
    getState = checkFieldAndReturnState({
      name: 'receiverNumber',
      value: state['receiverNumber'].value,
      isRequired: true,
      regEx: RegExp(`^\\d{${config.defaultAccountNumberLength}}$`),
    });
    hasErrors = getState.error || hasErrors;
    stateToSet[getState.name] = { value: getState.value, error: getState.error };
  }

  // Receiver account check
  if (
    state.selectedProducts &&
    state.selectedProducts[0] === 'dhlParti' &&
    state.payer &&
    state.payer.value === THIRD_PARTY_PAYER
  ) {
    getState = checkFieldAndReturnState({
      name: 'thirdPartyNumber',
      value: state['thirdPartyNumber'].value,
      isRequired: true,
      regEx: RegExp(`^\\d{${config.defaultAccountNumberLength}}$`),
    });
    hasErrors = getState.error || hasErrors;
    stateToSet[getState.name] = { value: getState.value, error: getState.error };
  }

  return {
    stateToSet,
    hasErrors,
  };
};

import { apiRequest } from 'globals/utils/requests';
import { debounce } from 'globals/utils/debounce';
import { config } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { matchSorter } from 'match-sorter';

export const filterMatchedAddressResults = debounce((context, t) => {
  let keys;
  const stateToSet = {};

  if (context.state.searchTerm.value.length !== 0 && context.state.addressBookListMaster.length > 0) {
    const addressBookWithCountryName = translateCountry(context.state.addressBookListMaster, t);
    // e.g. companyName, name, country etc
    keys = Object.keys(addressBookWithCountryName[0]);

    const filteredMatches = matchSorter(addressBookWithCountryName, context.state.searchTerm.value, {
      keys: keys,
      threshold: matchSorter.rankings.CONTAINS,
    });

    stateToSet.addressBookList = filteredMatches;
    stateToSet.manualSearch = true;
  } else {
    stateToSet.addressBookList = JSON.parse(JSON.stringify(context.state.addressBookListMaster));
    stateToSet.manualSearch = false;
  }

  context.updateState(stateToSet);
}, config.standardDebounceTimeout);

const translateCountry = (addressBookList, t) => {
  const gridList = [];

  addressBookList.forEach(address => {
    gridList.push({
      ...address,
      countryName: t(`countries|${address.countryCode}`),
    });
  });

  return gridList;
};

export const exportCSV = async () => {
  const url = apiLinks.exportCSV;
  let error = false;

  const params = {
    headers: {
      accept: 'application/octet-stream',
      responseType: 'blob',
    },
  };

  await apiRequest(url, 'GET', params).then(result => {
    if (result.size) {
      let blob = new Blob([result], {
        type: 'application/vnd.ms-excel',
      });

      const filename = result.filename;

      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        let link = document.createElement('a');

        if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);

          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style = 'visibility:hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } else {
      error = true;
    }
  });

  return error;
};

import React from 'react';
import { CONST } from '../../data-config';
import { Error } from 'components';
import { useTranslation } from 'react-i18next';
import { deleteContact, viewContact } from './ModalView-helpers';

import './ModalView.css';
import { useConfigCountries } from '../../../hooks/useConfigCoutries';

export const ModalView = ({
  error,
  transactionId,
  onCloseModalAction,
  contactList,
  actionClick,
  isViewContact,
  loader,
}) => {
  const { t } = useTranslation();
  const { isGlobalCountry } = useConfigCountries();

  if (!Array.isArray(contactList)) {
    contactList = [contactList];
  }

  return (
    <>
      <div className="frc__dialog">
        <div className="frc__dialog-overlay" onClick={loader ? null : () => onCloseModalAction()}></div>
        <div className="frc__modal-dialog-width  frc__dialog-wrapper">
          <div className=" frc__dialog-container ">
            <div id="frc_dialog-close-btn" className="frc__dialog-close-button">
              <button className="frc__button--delete--darker" onClick={() => onCloseModalAction()}>
                X
              </button>
            </div>

            <h4 className="frc__modal-view-edit-center">
              {isViewContact
                ? t(`portalAddressBook|View Address Details`)
                : contactList.length > 1
                ? t(`portalAddressBook|Delete Selected Contacts`)
                : t(`portalAddressBook|Delete Selected Contact`)}
            </h4>

            {contactList.length > 1 && (
              <h5 className="frc__modal-view-edit-center frc__delete-contact-msg">
                {!isViewContact &&
                  t(`portalAddressBook|Confirm Delete`, {
                    nos: contactList.length,
                  })}
              </h5>
            )}

            <div className="l-grid">
              <div
                className={`frc__modal-view-edit-list   margin-bottom-1
                ${contactList.length > 1 ? ' frc__modal-max-width' : ''}`}
              >
                {isViewContact ? viewContact(contactList, t, isGlobalCountry) : deleteContact(contactList, t)}
              </div>
            </div>

            <div className="l-grid">
              <div className={` l-grid l-grid--w-100pc-s  `}>
                <div className=" frc__modal-center l-grid--w-100pc-s l-grid--w-100pc-m-s l-grid--w-100pc-m-m">
                  <button
                    className={'base-button base-button--white l-grid--w-20pc-s frc__btn-margin-right '}
                    id="cancelContactBtn"
                    onClick={() => actionClick(CONST.CANCEL)}
                  >
                    <span>{t('general|labels|buttons|Cancel')}</span>
                  </button>

                  <button
                    className={'base-button  l-grid--w-30pc-s '}
                    onClick={() => actionClick(isViewContact ? CONST.EDIT : CONST.DELETE)}
                    id={`${isViewContact ? CONST.VIEW : CONST.DELETE}ContactBtn`}
                  >
                    <span>
                      {isViewContact
                        ? t('general|labels|buttons|Edit')
                        : contactList.length === 1
                        ? t('general|labels|buttons|Delete')
                        : t(`portalAddressBook|Delete Selected`)}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            {error && (
              <div className=" l-grid--w-100pc-s  ">
                <Error
                  name="contactModalActionResultError"
                  className="frc__input--error"
                  message={
                    t('general|errors|General error') +
                    '<br>' +
                    t('general|Try again or call technical support.') +
                    ' ' +
                    t('general|or') +
                    ' ' +
                    `<strong><a href="${t('pageLinks|chat')}" 
                                      class ="frc__chat-with-us"
                                      target="_blank" rel="noopener noreferrer">${t(
                                        `general|Chat with us`,
                                      )}</a></strong>` +
                    ' <br> ' +
                    t('general|ID') +
                    ': ' +
                    transactionId
                  }
                />
              </div>
            )}
            <div className={`frc__generic--loader-overlay ${loader ? 'is-loading' : ''}`} />
          </div>
        </div>
      </div>
    </>
  );
};

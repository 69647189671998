import { Checkbox, Error, Input, PhoneNumberV2, Select, TextArea } from 'components';
import { sortCountries } from 'globals/data-sorted-countries';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { POSTALCODE_ERRORS, PostalCodeInput } from '../../../components/fcp-components/PostalCodeInput';
import { canFetchCityForPostalcode } from '../../../globals/helpers/postalcode';
import { CONST, config } from '../../data-config';
import { resetCountryPaginationCriteriasAndSearchAddressCall } from '../ActionUtils';
import { getCityName, performActionEvent } from './Contact-helpers';

import './Contact.css';
import { apiRequest } from '../../../globals';
import { apiLinks } from '../../../config/api-config';
import { caseInsensitiveEquals } from '../../../globals/utils/strings';
import { changeCountryCodePhonePrefix } from '../../../globals/helpers/country-helpers';

export default class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      validationError: false,
      apiError: false,
      deleteDialog: false,
      city: '',
      cityNameMismatchError: false,
      cityPostalCodeError: false,
      conflictError: false,
      maxLimitReachedError: false,
    };
  }

  componentDidMount = async () => {
    if (!this.props.context.state.user) {
      await apiRequest(apiLinks.getUserData, 'GET').then(result => {
        if (result.status === CONST.STATUS_OK) {
          this.props.context.updateState({
            user: result.data.user,
          });
        } else {
          console.error('Fetch user failed.');
        }
      });
    }
    if (process.env.NODE_ENV !== 'test') window.scrollTo(0, 0);

    this.checkIfEnteredAndSuggestedCityIsValid = this.checkIfEnteredAndSuggestedCityIsValid.bind(this);
  };

  resetPostalCodeSuggestionError = () => {
    this.setState({ city: '', cityNameMismatchError: false, cityPostalCodeError: false });
  };

  checkAppropriateCity = async () => {
    const { state } = this.props.context;
    const shouldValidateCity = canFetchCityForPostalcode({
      accountCountryCode: state.user?.accountCountryCode,
      countryCode: state.countryCode.value,
      postalCode: state.postalCode.value,
      skipRegexValidation: true,
    });

    // for international contact with some value in city, we should not validate / overwrite again FCPG-1482
    const skipCityValidation =
      !caseInsensitiveEquals(state.user?.accountCountryCode, state.countryCode?.value) && !!state.city?.value;

    if (!shouldValidateCity || skipCityValidation) {
      this.resetPostalCodeSuggestionError();
    } else if (state.countryCode.value && state.postalCode.value) {
      this.setState({ loader: true });

      const fetchCityCall = await getCityName(state.countryCode.value, state.postalCode.value, state.city.value);

      if (!fetchCityCall.error && fetchCityCall.data && fetchCityCall.data?.city) {
        if (state.city.value) {
          this.setState({
            city: fetchCityCall.data.city,
            loader: false,
            cityNameMismatchError:
              fetchCityCall.data?.city?.toLowerCase() !== this.props.context.state.city.value?.toLowerCase(),
            cityPostalCodeError: false,
          });
        } else {
          this.setAppropriateCity(fetchCityCall.data.city);
        }
      } else if (!fetchCityCall.error && fetchCityCall.data && !fetchCityCall.data.validated) {
        this.setState({
          loader: false,
          city: '',
          cityNameMismatchError: false,
          cityPostalCodeError: true,
        });
      } else {
        this.setState({
          loader: false,
          cityNameMismatchError: false,
          cityPostalCodeError: false,
        });
      }
    }
  };

  checkIfEnteredAndSuggestedCityIsValid = context => {
    if (this.state.cityNameMismatchError && this.state.city) {
      const cityNameMismatchError = context.state.city.value.toLowerCase() !== this.state.city.toLowerCase();

      this.setState({
        cityNameMismatchError,
        city: !cityNameMismatchError ? '' : this.state.city,
      });
    }
  };

  setAppropriateCity = (suggestedCity = false) => {
    const city = JSON.parse(JSON.stringify(this.props.context.state.city));
    city.value = suggestedCity || this.state.city;
    city.error = false;

    this.props.context.updateState({ city }, () => {
      this.setState({
        city: '',
        loader: false,
        cityPostalCodeError: false,
        cityNameMismatchError: false,
        validationError: false,
      });
    });
  };

  onActionClick = async (action, t) => {
    this.setState(
      {
        deleteDialog: false,
        loader: true,
        conflictError: false,
        maxLimitReachedError: false,
        validationError: false,
        apiError: false,
      },
      async () => {
        const { validationError, apiError, maxLimitReachedError, conflictError, cityValidationObj, message } =
          await performActionEvent(action, this.props.context);

        if (!apiError && !validationError && !conflictError && !maxLimitReachedError) {
          this.props.onShowSuccessToaster(message);
          await resetCountryPaginationCriteriasAndSearchAddressCall(this.props.context, false, t);
          this.props.onShowContact();
        } else {
          const stateToSet = {
            apiError,
            validationError,
            conflictError,
            maxLimitReachedError,
            loader: false,
          };

          if (cityValidationObj) {
            stateToSet.cityNameMismatchError = cityValidationObj.cityNameMismatchError || false;
            stateToSet.city = cityValidationObj.city || '';
            stateToSet.cityPostalCodeError = cityValidationObj.cityPostalCodeError || '';
          }

          this.setState(stateToSet);
        }
      },
    );
  };

  toggleModelView = () => {
    this.setState(prevState => ({
      deleteDialog: !prevState.deleteDialog,
    }));
  };

  render() {
    const { context } = this.props;
    const isNewContact = !context.state.id;
    const cityPostalCodeValidationError = this.state.cityNameMismatchError || this.state.cityPostalCodeError || false;

    const getPostalCodeError = () => {
      if (this.state.cityPostalCodeError) {
        return POSTALCODE_ERRORS.cityPostalCodeError;
      } else {
        if (context.state.postalCode.error) {
          return POSTALCODE_ERRORS.validationError;
        } else {
          return null;
        }
      }
    };

    const postalCodeError = getPostalCodeError();

    return (
      <div className="l-grid l-grid--between-s frc__push-bottom  frc__generic--field-wrapper " data-testid="contact">
        <Translation>
          {t => (
            <>
              <div className="l-grid--w-100pc-s  margin-bottom-1">
                <h4 className="margin-bottom-1">{t(`portalAddressBook|${isNewContact ? 'Add' : 'Edit'} Contact`)}</h4>
              </div>

              {/**section 1 */}
              <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper margin-bottom-1">
                <div className=" l-grid--w-100pc-s l-grid--w-20pc-m-s l-grid--w-20pc-m-m">
                  <h5 className="margin-bottom-1">{t(`portalAddressBook|Contact Reference`)}</h5>
                  <div className="frc__wrap-contact-ref-detail"> {t(`portalAddressBook|Contact Reference Detail`)}</div>
                </div>
                <div className="l-grid l-grid--w-100pc-s l-grid--w-80pc-m-s l-grid--w-80pc-m-m frc__generic--background-white frc__generic-section--wrapper">
                  <div className=" l-grid--w-100pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m ">
                    <Input
                      context={context}
                      config={config}
                      CONST={CONST}
                      regEx={config.regEx.everything}
                      label={t(`portalAddressBook|Address ID / Nick`)}
                      name="addressId"
                      dataTestId="addressIdInp"
                      isRequired={context.state.addressId.isRequired}
                      value={context.state.addressId.value}
                      lengthCheck={[RegExp(`^.{0,${config.maxAddressIdLength}}$`)]}
                      cutTextLimit={config.maxAddressIdLength}
                    />
                    {context.state.addressId.error || this.state.conflictError ? (
                      <Error
                        name="addressId"
                        className="frc__input--error"
                        message={
                          context.state.addressId.error
                            ? t(`portalAddressBook|Address ID / Nick Error`, {
                                max: config.maxAddressIdLength,
                              })
                            : t(`portalAddressBook|Address ID / Nick Conflict Error`)
                        }
                      />
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="l-grid--w-100pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m ">
                    <TextArea
                      className="frc__textarea--wrapper margin-bottom-1 frc__generic--field-wrapper frc__generic--field-wrapper-no-top"
                      textAreaClassName="frc__textarea--tiny"
                      regEx={config.regEx.everything}
                      label={t(`portalAddressBook|Notes`)}
                      name="notes"
                      isRequired={context.state.notes.isRequired}
                      value={context.state.notes.value}
                      lengthCheck={[RegExp(`[\\r\\n]{0,${config.maxNotesLength}}$`)]}
                      cutTextLimit={config.maxNotesLength}
                      context={context}
                      config={config}
                      CONST={CONST}
                    />
                    {context.state.notes.error && (
                      <Error
                        name="notes"
                        className="frc__input--error"
                        message={t(`portalAddressBook|Notes Error`, {
                          max: config.maxNotesLength,
                        })}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/**section 2 */}
              <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper margin-bottom-1">
                <div className=" l-grid--w-100pc-s l-grid--w-20pc-m-s l-grid--w-20pc-m-m">
                  <h5 className="margin-bottom-1">{t(`general|Contact Details`)}</h5>
                </div>
                <div className="l-grid l-grid--w-100pc-s l-grid--w-80pc-m-s l-grid--w-80pc-m-m frc__generic--background-white frc__generic-section--wrapper">
                  <div className=" l-grid--w-100pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m ">
                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        regEx={config.regEx.everything}
                        label={t(`portalAddressBook|Company Name`)}
                        name="companyName"
                        dataTestId="companyNameInp"
                        isRequired={context.state.companyName.isRequired}
                        value={context.state.companyName.value}
                        lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                        cutTextLimit={config.maxDefaultInputLength}
                      />
                      {context.state.companyName.error ? (
                        <Error
                          name="companyName"
                          className="frc__input--error"
                          message={t(`portalAddressBook|Company Name Error`, {
                            max: config.maxDefaultInputLength,
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t('general|labels|inputs|Name')}
                        name="name"
                        dataTestId="nameInp"
                        regEx={config.regEx.everything}
                        isRequired={context.state.name.isRequired}
                        value={context.state.name.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                        cutTextLimit={config.maxDefaultInputLength}
                      />
                      {context.state.name.error ? (
                        <Error
                          name="name"
                          className="frc__input--error"
                          message={t(`general|errors|Name Error`, {
                            max: config.maxDefaultInputLength,
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t(`general|E-mail`)}
                        name="email"
                        dataTestId="emailInp"
                        isRequired={context.state.email.isRequired}
                        value={context.state.email.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxEmailLength}}$`)]}
                        cutTextLimit={config.maxEmailLength}
                      />
                      {context.state.email.error ? (
                        <Error
                          name="email"
                          className="frc__input--error"
                          message={t('general|errors|E-mail Error', {
                            chars: config.maxEmailLength,
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
                      {context.state.phoneNumbers.map((phoneNumber, ind) => (
                        <PhoneNumberV2
                          key={ind}
                          context={context}
                          config={config}
                          CONST={CONST}
                          index={ind}
                          number={phoneNumber.number}
                          code={phoneNumber.code}
                          error={phoneNumber.error}
                          groupName="phoneNumbers"
                          isRequired={false}
                        />
                      ))}
                    </div>
                  </div>

                  <div className="l-grid--w-100pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m ">
                    <Select
                      className="frc__generic-row--wrapper frc-select--wrapper frc__generic--field-wrapper"
                      selectClassName="c-form--element-base c-form--element-select no-margin-bottom"
                      label={t(`general|Country`)}
                      name="countryCode"
                      isRequired={context.state.countryCode.isRequired}
                      values={sortCountries()}
                      value={context.state.countryCode.value}
                      context={context}
                      config={config}
                      CONST={{
                        API_DOMAIN: 'countries',
                      }}
                      disableonBlur={true}
                      afterUpdate={(context, params) => {
                        this.checkAppropriateCity();
                        changeCountryCodePhonePrefix(context, params.value, 'phoneNumbers');
                      }}
                    />
                    {context.state.countryCode.error ? (
                      <Error name="country" className="frc__input--error" message={t(`general|Country Error`)} />
                    ) : (
                      ''
                    )}
                    <div className="l-grid l-grid--w-100pc-s ">
                      <div className="frc__generic-row--wrapper l-grid--w-100pc-s">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t('general|labels|inputs|Street')}
                          name="street"
                          dataTestId="streetInp"
                          isRequired={context.state.street.isRequired}
                          value={context.state.street.value}
                          lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                        />
                        {context.state.street.error ? (
                          <Error
                            name="pickupStreet"
                            className="frc__input--error"
                            message={t('general|errors|Street Error', {
                              max: config.maxDefaultInputLength,
                            })}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="l-grid l-grid--w-100pc-s ">
                      <div
                        className=" l-grid--w-100pc-s l-grid--w-50pc-m-m"
                        onBlur={
                          this.props.context.state.postalCode.value.length >= config.minPostalCodeToPerfomCitySearch
                            ? this.checkAppropriateCity
                            : null
                        }
                      >
                        <PostalCodeInput
                          value={context.state.postalCode.value}
                          name="postalCode"
                          isRequired={context.state.postalCode.isRequired}
                          countryCode={context.state.countryCode.value}
                          error={postalCodeError}
                          context={context}
                          config={config}
                          CONST={CONST}
                        />
                      </div>
                      <div className=" frc__generic-row--wrapper l-grid--w-100pc-s l-grid--w-50pc-m-m">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t('general|labels|inputs|City')}
                          name="city"
                          dataTestId="cityInp"
                          isRequired={context.state.city.isRequired}
                          value={context.state.city.value}
                          lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                          afterUpdate={this.checkIfEnteredAndSuggestedCityIsValid}
                          afterChangeOnBlur={
                            !this.state.cityNameMismatchError &&
                            this.props.context.state.postalCode.value.length >=
                              config.minPostalCodeToPerfomCitySearch &&
                            this.props.context.state?.countryCode?.value ===
                              this.props.context.state?.user?.accountCountryCode?.toUpperCase()
                              ? this.checkAppropriateCity
                              : null
                          }
                        />
                        {context.state.city.error ? (
                          <Error
                            name="cityError"
                            className="frc__input--error"
                            message={t('general|errors|City Error', {
                              max: config.maxDefaultInputLength,
                            })}
                          />
                        ) : (
                          this.state.cityNameMismatchError && (
                            <div className="l-grid">
                              <Error
                                name="cityMismtachError"
                                className="frc__input--error"
                                message={t('general|errors|invalidCity')}
                              />
                              <span className="frc__city-suggestion">
                                {'"'}
                                <span className="frc__city-underline" onClick={() => this.setAppropriateCity()}>
                                  {this.state.city}
                                </span>
                                {'" ?'}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/**section 3 */}
              <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper margin-bottom-1">
                <div className=" l-grid--w-100pc-s l-grid--w-20pc-m-s l-grid--w-20pc-m-m">
                  <h5 className="margin-bottom-1">{t(`general|Additional Details`)}</h5>
                </div>
                <div className="l-grid l-grid--w-100pc-s l-grid--w-80pc-m-s l-grid--w-80pc-m-m frc__generic--background-white frc__generic-section--wrapper">
                  <div className=" l-grid--w-100pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m ">
                    <div className="l-grid--w-100pc-s  frc__generic-row--wrapper ">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t('general|labels|inputs|reference')}
                        name="reference"
                        dataTestId="referenceInp"
                        isRequired={context.state.reference.isRequired}
                        value={context.state.reference.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                        cutTextLimit={config.maxDefaultInputLength}
                      />
                      {context.state.reference.error ? (
                        <Error
                          name="name"
                          className="frc__input--error"
                          message={t(`portalAddressBook|Reference Error`, {
                            chars: config.maxDefaultInputLength,
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="l-grid--w-100pc-s  frc__generic-row--wrapper ">
                      <Checkbox
                        label={t('general|This is a residential address')}
                        name="residentialAddress"
                        isRequired={false}
                        checked={context.state.residentialAddress.value}
                        context={context}
                        config={config}
                        CONST={CONST}
                      />
                    </div>
                  </div>

                  <div className="l-grid--w-100pc-s l-grid--w-50pc-m-s l-grid--w-50pc-m-m ">
                    <TextArea
                      className="frc__textarea--wrapper margin-bottom-1 frc__generic--field-wrapper frc__generic--field-wrapper-no-top"
                      textAreaClassName="frc__textarea--tiny"
                      regEx={config.regEx.everything}
                      label={t(`general|Delivery Instructions`)}
                      name="deliveryInstructions"
                      isRequired={context.state.deliveryInstructions.isRequired}
                      value={context.state.deliveryInstructions.value}
                      lengthCheck={[RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)]}
                      cutTextLimit={config.maxInstructionsLength}
                      context={context}
                      config={config}
                      CONST={CONST}
                    />
                    {context.state.deliveryInstructions.error && (
                      <Error
                        name="deliveryInstructions"
                        className="frc__input--error"
                        message={t(`general|Delivery Instructions Error`, {
                          chars: config.maxInstructionsLength,
                        })}
                      />
                    )}
                  </div>
                </div>
              </div>

              {/**Action button*/}
              <div className="l-grid l-grid--w-100pc-s f">
                <div className=" l-grid--w-100pc-s l-grid--w-20pc-m-s l-grid--w-20pc-m-m"></div>
                <div className="l-grid l-grid--w-100pc-s l-grid--w-80pc-m-s l-grid--w-80pc-m-m  ">
                  <div className="frc__edit-action-btn-left">
                    <button
                      className={'base-button l-grid--w-100pc-s l-grid--w-20pc-m frc__btn-margin-right'}
                      id="cancelContactBtn"
                      onClick={this.props.onShowContact}
                    >
                      <span>{t('general|labels|buttons|Cancel')}</span>
                    </button>

                    {!isNewContact && (
                      <>
                        <button
                          className={
                            'base-button frc_btn-divider l-grid--w-100pc-s l-grid--w-20pc-m frc__btn-margin-right'
                          }
                          onClick={this.toggleModelView}
                          id="deleteContactBtn"
                        >
                          <span>{t('general|labels|buttons|Delete')}</span>
                        </button>
                      </>
                    )}

                    <button
                      className={`base-button l-grid--w-100pc-s l-grid--w-20pc-m frc__btn-margin-right ${
                        cityPostalCodeValidationError ? 'disabled' : ''
                      }`}
                      id="saveContactBtn"
                      disabled={cityPostalCodeValidationError ? 1 : 0}
                      onClick={
                        cityPostalCodeValidationError
                          ? null
                          : () => this.onActionClick(isNewContact ? CONST.SAVE_NEW : CONST.SAVE, t)
                      }
                    >
                      <span>{t('general|labels|buttons|Save')}</span>
                    </button>

                    {!isNewContact && (
                      <>
                        <button
                          className={`base-button l-grid--w-100pc-s l-grid--w-20pc-m frc__btn-margin-right ${
                            cityPostalCodeValidationError ? 'disabled' : ''
                          }`}
                          id="saveNewContactBtn"
                          disabled={cityPostalCodeValidationError ? 1 : 0}
                          onClick={cityPostalCodeValidationError ? null : () => this.onActionClick(CONST.SAVE_NEW, t)}
                        >
                          <span>{t('general|labels|buttons|Save As New')}</span>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="l-grid--right-s l-grid--w-100pc-s frc__generic-row--wrapper margin-bottom-1">
                {this.state.apiError ||
                this.state.maxLimitReachedError ||
                this.state.cityPostalCodeError ||
                this.state.validationError ||
                this.state.conflictError ? (
                  <Error
                    name="contactActionResultError"
                    className="frc__input--error"
                    message={
                      this.state.validationError || this.state.conflictError || cityPostalCodeValidationError
                        ? t('general|errors|Please fill properly all necessary fields')
                        : this.state.maxLimitReachedError
                        ? t(`portalAddressBook|Address book save max limit error`, {
                            maxLimit: config.maxAddressLimit,
                          })
                        : t('general|errors|General error') +
                          '. <br> ' +
                          t('general|Try again or call technical support.') +
                          ' ' +
                          t('general|or') +
                          ' ' +
                          `<strong><a href="${t('pageLinks|chat')}" 
                                            class ="frc__chat-with-us"
                                            target="_blank" rel="noopener noreferrer">${t(
                                              `general|Chat with us`,
                                            )}</a></strong>` +
                          ' <br> ' +
                          t('general|ID') +
                          ': ' +
                          context.state.transactionId
                    }
                  />
                ) : (
                  ''
                )}
              </div>

              {/**Delete dialog*/}
              {this.state.deleteDialog && (
                <div className="frc__dialog">
                  <div className="frc__dialog-overlay" onClick={this.toggleModelView}></div>
                  <div className="frc__dialog-width frc__delete-dialog-wrapper">
                    <div className="l-grid l-grid--middle-s frc__dialog-holder">
                      <div className="frc__dialog-container">
                        <div id="frc_dialog-close-btn" className="frc__dialog-close-button">
                          <button className="frc__button--delete--darker" onClick={this.toggleModelView}>
                            X
                          </button>
                        </div>

                        <h5 className="frc__modal-center">{t(`general|Are you sure you want to delete`)}</h5>
                        <div className="l-grid l-grid--center-s">
                          <button
                            className={'base-button frc_btn-divider l-grid--w-20pc-s frc__btn-margin-right'}
                            onClick={() => this.onActionClick(CONST.DELETE, t)}
                            id="deleteContactBtn"
                          >
                            <span>{t('general|labels|buttons|Delete')}</span>
                          </button>

                          <button
                            className={'base-button  l-grid--w-20pc-s '}
                            id="saveContactBtn"
                            onClick={this.toggleModelView}
                          >
                            <span>{t('general|labels|buttons|Cancel')}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </Translation>
        <div className={`frc__generic--loader-overlay ${this.state.loader ? ' is-loading ' : ''}`} />
      </div>
    );
  }
}

import { useQuery } from '@tanstack/react-query';
import { apiLinks } from '../../config/api-config';
import { apiRequest } from '../../globals';

export const queryKey = 'active-tracing';

const fetchActiveTracing = async savedShipmentIds => {
  const res = await apiRequest(apiLinks.activeTracing, 'POST', { body: { savedShipmentIds: savedShipmentIds } });
  if (res.status !== 200) {
    throw new Error(`Error in fetching active tracing, Status: ${res.status} Message: ${res.status}`);
  }
  return res.data;
};

export const useGetActiveTracing = ({ savedShipmentIds, options } = {}) => {
  return useQuery({
    queryKey: [queryKey, savedShipmentIds],
    queryFn: () => fetchActiveTracing(savedShipmentIds),
    enabled: !!savedShipmentIds?.length,
    staleTime: 1000 * 60 * 5,
    keepPreviousData: true,
    ...options,
  });
};

import { globalConfig, GLOBAL_CONST } from 'globals/data-config-global';

const componentConfig = {
  displayValues: ['10', '50', '100', '150'],
  defaultNoOfRows: '10',
};

const COMPONENT_CONST = {
  API_DOMAIN: 'palletTransactions',
};

export const config = Object.assign({}, globalConfig, componentConfig);
export const CONST = Object.assign({}, GLOBAL_CONST, COMPONENT_CONST);

import { CONST } from '../globals';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';

export const getPageContent = async (pageCode, lang, country, preview) => {
  let url = apiLinks.getPageCmsData
    .replace('{pageCode}', pageCode)
    .replace('{lang}', lang)
    .replace('{country}', country);
  if (preview === 'true') {
    url = url + '&isPreview=true';
  }
  return await apiRequest(url, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        return result.data;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
};

import React from 'react';

export default function FilterBanner({ CONST, searchTerm, remoteSearchTerm, t }) {
  return searchTerm && remoteSearchTerm && remoteSearchTerm !== searchTerm ? (
    <>
      {t('general|labels|Results') + ' ' + t('general|for') + ' '}
      <strong>{remoteSearchTerm} </strong>
      {' ' + t(`general|filtered by`)}
      <strong>{` ${searchTerm} `} </strong>
    </>
  ) : (
    (searchTerm || remoteSearchTerm) && (
      <>
        {remoteSearchTerm ? t('general|labels|Search results') : t('general|labels|Results')}
        {' ' + t(`general|filtered by`) + ' '}
        <strong>{searchTerm || remoteSearchTerm}</strong>
      </>
    )
  );
}

import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Error } from '../../../components';
import PropTypes from 'prop-types';
import { FileDownload } from '@mui/icons-material';
import { Button } from '../../../components/fcp-components/Button';

const ImportantRequirement = props => {
  const { state, options, fileNameComposer, errorMessageCode, downloadFile } = props;

  const { t } = useTranslation();
  return (
    <Box
      sx={{
        padding: '2rem',
        borderTop: '1px solid #ccc',
        backgroundColor: '#fef3f4',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          maxWidth: '100%',
        }}
      >
        <i className="dhlicon-info fg-dhl-red flex-none m-right-1rem fs-1_25rem"></i>
        <h5 className="m-0">{t(`general|shipment|importantRequirement`)}</h5>
      </Box>
      <p>{t('general|shipment|importantRequirementDownload')}</p>
      {options && options.length >= 1 && (
        <Box
          sx={{
            maxWidth: '100%',
          }}
        >
          {options.map((documentFileName, index) => {
            let reportName = documentFileName;
            if (fileNameComposer) {
              reportName = fileNameComposer(state, documentFileName);
            }
            return (
              <Button
                startIcon={<FileDownload />}
                sx={{
                  color: '#d40511',
                  border: '1px solid #d40511',
                  marginRight: '1rem',
                  marginBottom: '1rem',
                  borderRadius: '3px',
                  padding: '0.75rem 1.25rem',
                  fontWeight: 600,
                  flex: 'none',
                }}
                key={index}
                onClick={event => {
                  downloadFile(documentFileName, index);
                }}
                dataTestId={`${index}-download-btn`}
              >
                {reportName}
              </Button>
            );
          })}
          {state.PDFdownloadError && (
            <Error name="PDFdownloadError" className="frc__input--error" message={t(errorMessageCode)} />
          )}
        </Box>
      )}
    </Box>
  );
};

ImportantRequirement.propTypes = {
  state: PropTypes.object.isRequired,
  options: PropTypes.any.isRequired,
  fileNameComposer: PropTypes.func,
  errorMessageCode: PropTypes.string.isRequired,
  downloadFile: PropTypes.func.isRequired,
};

export default ImportantRequirement;

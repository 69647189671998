import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import AdditionalServiceRow from './AdditionalServiceRow/AdditionalServiceRow';
import { config, CONST } from '../../../data-config';
import './AdditionalServices.css';

export default class AdditionalServices extends Component {
  constructor(props) {
    super(props);
    this.specialCase = React.createRef();
    this.expand = React.createRef();
  }

  componentDidMount() {
    if (this.props.context.state.deliveryMonitoringExpanded) {
      this.expand.current.classList.add('expand');
      this.specialCase.current.classList.remove('frc__special_case');
    }
  }

  expandDeliveryMonitoring = () => {
    this.expand.current.classList.toggle('expand');

    this.specialCase.current.classList.toggle('frc__special_case');
  };

  checkForPriorityService = additionalServiceRow => {
    if (additionalServiceRow.code.indexOf('priorityService') !== -1) {
      return !this.props.context.state.priorityServiceDisabled;
    }

    return true;
  };

  render() {
    let additionalServicesArrWithoutDlMontrg = [];
    let additionalServicesArrWithDlMontrg = [];

    this.props.context.state.additionalServices.forEach((additionalServiceRow, ind) => {
      if (additionalServiceRow.code.indexOf('deliveryMonitoring') === -1) {
        additionalServicesArrWithoutDlMontrg.push(
          this.checkForPriorityService(additionalServiceRow) && (
            <AdditionalServiceRow
              key={ind}
              className="l-grid--w-100pc-s l-grid--w-50pc-m"
              index={ind}
              additionalServiceRow={additionalServiceRow}
              error={additionalServiceRow.error}
              context={this.props.context}
              config={config}
              CONST={CONST}
              ltcFailed={this.props.context.state.ltcFailed}
              loaderRef={this.props.loaderRef}
            />
          ),
        );
      } else {
        additionalServicesArrWithDlMontrg.push(
          <AdditionalServiceRow
            key={ind}
            deliveryMontoringClass="frc__generic--field-wrapper"
            className="l-grid--w-100pc-s l-grid--w-100pc-m"
            specialCase="frc_deliveryMonitoring"
            index={ind}
            additionalServiceRow={additionalServiceRow}
            error={additionalServiceRow.error}
            context={this.props.context}
            config={config}
            CONST={CONST}
            ltcFailed={this.props.context.state.ltcFailed}
            loaderRef={this.props.loaderRef}
          />,
        );
      }
    });
    return (
      <>
        <Translation>
          {t => (
            <>
              {this.props.context.state.selectedProduct && this.props.context.state.selectedProduct.code ? (
                <div className="frc__additional-services--wrapper  ">
                  <div>
                    {this.props.context.state.additionalServices.length > 0 ? (
                      <h4
                        className="margin-bottom-1 frc__push-bottom frc__red-lined-title "
                        dangerouslySetInnerHTML={{
                          __html: t('general|Select any optional <strong>Additional Services</strong> that apply'),
                        }}
                      />
                    ) : (
                      ''
                    )}

                    <div className="l-grid l-grid--w-100pc-s">
                      {additionalServicesArrWithoutDlMontrg}
                      {additionalServicesArrWithDlMontrg.length > 0 && (
                        <div
                          id="frc_additional-services_delivery_monitoring"
                          ref={this.expand}
                          className="frc_additional-services--checkbxes-sub-group
                          l-grid--w-100pc-s l-grid--w-50pc-m l-grid--has-gutter "
                        >
                          <div
                            className="frc_additional-services--checkbxes-sub-group-title "
                            onClick={() => this.expandDeliveryMonitoring()}
                          >
                            {t(`general|Delivery monitoring`)}
                          </div>
                          <div id="frc__special_case" ref={this.specialCase} className="frc__special_case">
                            {additionalServicesArrWithDlMontrg}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </>
          )}
        </Translation>
      </>
    );
  }
}

import React, { useState } from 'react';
import { CaretDown } from 'components';
import { CaretUp } from 'components';

export default function Accordion(props) {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  return (
    <>
      <div className="l-grid" onClick={onClick} style={{ cursor: 'pointer' }}>
        <h4>{isOpen ? props.children.props.hideTitle : props.children.props.showTitle}</h4>
        <div className="frc__accordion-caret-end">{isOpen ? <CaretUp /> : <CaretDown />}</div>
      </div>
      {isOpen && props.children.props.children}
    </>
  );
}

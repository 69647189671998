import React, { Component } from 'react';
import { MyContext, MyProvider } from '../../context/context';
import { Typography } from '@mui/material';

import EnterPickupLocation from '../EnterPickupLocation/EnterPickupLocation';
import ServiceDetails from '../ServiceDetails/ServiceDetails';
import EnterShipmentDetails from '../EnterShipmentDetails/EnterShipmentDetails';
import AdditionalServices from '../AdditionalServices/AdditonalServices';
import PickupDate from '../PickupDate/PickupDate';
import BookingConfirmation from '../BookingConfirmation/BookingConfirmation';
import TermsAndSubmit from '../TermsAndSubmit/TermsAndSubmit';

import './../../css/global/_variables.css';

import './AppPublicPickup.css';
import 'globals/utils/debugHelpers';
import { GeneralError } from 'components';
import cssVars from 'css-vars-ponyfill';
import SideSummaryPanel from '../SideSummaryPanel/SideSummaryPanel';
import { Translation } from 'react-i18next';
import ThankYouPage from '../../../globals/components/ThankYou/pickup/ThankYouPage';

const ENV_DEV = process.env.NODE_ENV === 'development';

export default class AppPublicPickup extends Component {
  componentDidMount() {
    cssVars();
  }

  render() {
    return (
      <Translation>
        {t => (
          <div className={ENV_DEV ? 'l-view' : ''}>
            <div className="c-page-headline component-small has-rte component-margin frc__heading">
              <div className="c-page-headline--wrapper">
                <Typography variant="h1" mt={{ xs: 0, sm: 2 }}>
                  {t('general|pageTitles|schedulePickup')}
                </Typography>
              </div>
              <p className="has-rte">{t('portalPickup|Only pickup, no documents will be produced using this menu.')}</p>
            </div>

            <MyProvider>
              <div className="frc__app">
                <MyContext.Consumer>
                  {context => (
                    <>
                      {t => <GeneralError context={context} message={t('general|errors|General error')} />}
                      {context.state.publicPickupBookingSubmited || context.state.redThankYouPage ? (
                        <ThankYouPage
                          pickupOrderResult={context.state.pickupOrderResult}
                          isRedThankYouPage={context.state.redThankYouPage}
                          redThankYouPageErrorMessage={context.state.redThankYouErrorMessage}
                          pickupDate={context.state.pickupOrderResult.pickupDateTime}
                          pickupNumber={context.state.pickupOrderResult.bookingNumber}
                          pickupOrderMovedToNextDay={context.state.pickupOrderMovedToNextDay}
                        />
                      ) : (
                        <div className="l-grid">
                          <div className="l-grid--w-100pc-s l-grid--w-75pc-m">
                            <ServiceDetails context={context} />
                            <EnterPickupLocation context={context} />
                            <EnterShipmentDetails context={context} />
                            <AdditionalServices context={context} />
                            <PickupDate context={context} />
                            <BookingConfirmation />
                            <TermsAndSubmit hideCaptcha={false} />
                          </div>
                          <div className="l-grid--w-100pc-s l-grid--w-25pc-m">
                            <SideSummaryPanel context={context} />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </MyContext.Consumer>
              </div>
            </MyProvider>
          </div>
        )}
      </Translation>
    );
  }
}

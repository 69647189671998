import { Box, Grid } from '@mui/material';
import { Button } from '../Button';
import { Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { PROFILE_SECTIONS } from './EditProfile';

export const EmailPassword = ({ userData, setReadOnly }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box>{userData.user.email}</Box>
          <Box sx={{ mt: '16px' }}>*******</Box>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Edit />}
        sx={{
          mt: '16px',
        }}
        onClick={() => {
          setReadOnly({
            section: PROFILE_SECTIONS.emailPassword,
            readOnly: false,
          });
        }}
        dataTestId="updatePasswordBtn"
      >
        {t('editProfile|updatePassword')}
      </Button>
    </Box>
  );
};

import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer/Footer';
import AuthService from 'globals/utils/auth';
import { useAuth } from '../hooks/useAuth';
import { getPageContent } from './Pages-helpers';
import { useCountryLanguage } from '../hooks/useCountryLanguage';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { ErrorPageRedirections } from './ErrorPageContent/ErrorPageRedirections';
import { useSearchParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Header } from '../components/fcp-components/Header';

const UNDER_MAINTENANCE_KEY = 'under-maintenance';
const ENV_DEV = process.env.NODE_ENV === 'development';

export default function UnderMaintenancePage() {
  const auth = useAuth();
  const countryLanguage = useCountryLanguage();
  const [pageData, setPageData] = useState(null);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (auth.authenticated) {
      AuthService.logOut().then(() => {
        auth.signout(() => console.info('User has been logged out'));
      });
    }
    const preview = searchParams.get('preview');
    getPageContent(UNDER_MAINTENANCE_KEY, countryLanguage.language, countryLanguage.country, preview).then(cmsData => {
      if (cmsData !== null && cmsData.length === 1) {
        setPageData(cmsData[0]);
      } else {
        ENV_DEV && console.error('failed to fetch CMS data');
      }
    });
    // eslint-disable-next-line
  }, [auth, countryLanguage]);

  return (
    <div className="l-view has-no-stage">
      <Header underMaintenance />
      <main className="c-mf-container component-margin">
        <div className="c-page-headline component-small has-rte component-margin frc__heading">
          <div className="c-page-headline--wrapper">
            {pageData?.translationValue && parse(pageData.translationValue)}
            {!pageData && (
              <>
                <Typography variant="h1">{t('general|myDHLFreight is under maintenance')}</Typography>
                <p>{t('general|Unfortunately myDHLFreight is under maintenance now. Please try it later.')}</p>
              </>
            )}
          </div>
        </div>
        <ErrorPageRedirections />
      </main>
      <Footer />
    </div>
  );
}

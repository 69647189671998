import { Box } from '@mui/material';
import { Header } from './Header';

export const GlobalError = ({ children, readOnlyHeader }) => {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '1365px',
        mx: 'auto',
      }}
    >
      <Header
        readOnlyHeader
        sx={{
          height: '88px',
        }}
      />
      {children}
    </Box>
  );
};

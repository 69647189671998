import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import AddressCard from '../AddressCard/AddressCard';

import '../../Template-Address.css';

export default class AddressPickupResultGrid extends Component {
  renderData = () => {
    return this.props.data.map(data => {
      return (
        <AddressCard key={data.id} context={this.props.context} address={data} handleClick={this.props.handleClick} />
      );
    });
  };

  render() {
    return (
      <>
        <Translation>
          {t => (
            <>
              <div className="l-grid frc__address-book-grid frc__generic-section--wrapper">{this.renderData()}</div>
            </>
          )}
        </Translation>
      </>
    );
  }
}

import { Alert, Box, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { Close, Save } from '@mui/icons-material';
import { apiRequest, getBaseUrl, getTransactionId } from '../../../globals';
import { apiLinks } from '../../../config/api-config';
import { toast } from 'react-toastify';
import { globalConfig, GLOBAL_CONST } from '../../../globals/data-config-global';
import { PROFILE_SECTIONS } from './EditProfile';

export const EmailPasswordForm = ({ userData, setReadOnly }) => {
  const { t } = useTranslation();

  const switchToReadOnly = () => {
    setReadOnly({
      section: PROFILE_SECTIONS.emailPassword,
      readOnly: true,
    });
  };

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
  });

  const submitForm = data => {
    const submitData = async () => {
      const { oldPassword, newPassword } = data;

      try {
        const result = await apiRequest(apiLinks.putResetPassword, 'PUT', {
          body: {
            oldPassword,
            newPassword,
          },
          headers: {
            Referer: getBaseUrl(null, 1),
            transactionId: getTransactionId('REG'),
          },
        });

        if (result.status === 202) {
          toast(
            <Alert severity="success" variant="standard">
              {t('changePassword|Password has been successfully changed')}
            </Alert>,
          );
          switchToReadOnly();
        } else {
          console.error('Error on Edit profile - change password form - non-202 error', result);

          if (result.status === 401) {
            setError('oldPassword', t(`changePassword|Old password does not match`));
          } else {
            toast(
              <Alert severity="error" variant="standard">
                {t('general|errors|unexpectedErrorText')}
              </Alert>,
            );
          }
        }
      } catch (error) {
        console.error('Error on Edit profile - change password form - catch error', error);
        toast(
          <Alert severity="error" variant="standard">
            {t('general|errors|unexpectedErrorText')}
          </Alert>,
        );
      }
    };
    return submitData();
  };

  return (
    <form
      onSubmit={handleSubmit(data => {
        return submitForm(data);
      })}
    >
      <Box
        sx={{
          '& .MuiFormControl-root': {
            mb: '16px',
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={{ m: '0 0 16px 0' }}>{userData.user.email}</Box>

            <Controller
              name="oldPassword"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => {
                const getHelperText = () => {
                  if (!field.value && fieldState?.error) {
                    return t('general|labels|inputs|required');
                  }
                  return undefined;
                };
                return (
                  <TextField
                    {...field}
                    type="password"
                    required
                    label={t('changePassword|Old password')}
                    sx={{
                      width: '100%',
                    }}
                    onChange={event => {
                      const targetValue = event.target.value;
                      field.onChange(targetValue);
                    }}
                    error={!!fieldState.error}
                    helperText={getHelperText()}
                  />
                );
              }}
            />
            <Controller
              name="newPassword"
              control={control}
              rules={{
                required: true,
                pattern: globalConfig.regEx.password,
                validate: newPassword => {
                  const { oldPassword } = getValues();
                  if (oldPassword === newPassword) {
                    return t('changePassword|New password should be different');
                  }
                  return true;
                },
              }}
              render={({ field, fieldState }) => {
                const getHelperText = () => {
                  if (fieldState.error && fieldState.error?.type === 'pattern') {
                    return t('general|Password rules info', {
                      passwordMinLength: GLOBAL_CONST.PASSWORD_MIN_LENGTH,
                    });
                  }
                  if (!field.value && fieldState.error) {
                    return t('general|labels|inputs|required');
                  }
                  if (fieldState?.error?.message) {
                    return fieldState?.error?.message;
                  }
                  return undefined;
                };
                return (
                  <TextField
                    {...field}
                    type="password"
                    required
                    label={t('general|labels|inputs|New password')}
                    sx={{
                      width: '100%',
                    }}
                    onChange={event => {
                      const targetValue = event.target.value;
                      field.onChange(targetValue);
                    }}
                    error={!!fieldState.error}
                    helperText={getHelperText()}
                  />
                );
              }}
            />
            <Controller
              name="newPasswordConfirmation"
              control={control}
              rules={{
                required: true,
                validate: value => {
                  const { newPassword } = getValues();
                  return newPassword === value || t('registration|Passwords must match');
                },
              }}
              render={({ field, fieldState }) => {
                const getHelperText = () => {
                  if (!field.value && fieldState.error) {
                    return t('general|labels|inputs|required');
                  }
                  if (fieldState?.error?.message) {
                    return fieldState.error.message;
                  }
                  return undefined;
                };
                return (
                  <TextField
                    {...field}
                    type="password"
                    required
                    label={t('general|Confirm password')}
                    sx={{
                      width: '100%',
                    }}
                    onChange={event => {
                      const targetValue = event.target.value;
                      field.onChange(targetValue);
                    }}
                    error={!!fieldState.error}
                    helperText={getHelperText()}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: '16px',
            gap: '16px',
          }}
        >
          <Button type="submit" loading={isSubmitting} variant="contained" color="primary" startIcon={<Save />} dataTestId="saveBtn">
            {t('general|labels|buttons|Save')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              switchToReadOnly();
            }}
            startIcon={<Close />}
            dataTestId="cancelBtn"
          >
            {t('general|labels|buttons|Cancel')}
          </Button>
          {Object.keys(errors).length > 0 ? (
            <Alert severity="error" variant="standard" sx={{ p: '0 12px' }}>
              {t('registration|invalidData')}
            </Alert>
          ) : null}
        </Box>
      </Box>
    </form>
  );
};

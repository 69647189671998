import { config, CONST } from '../../data-config';
import { apiLinks } from '../../../config/api-config';

import { setToken, setCookie, eraseCookie, apiRequest, getCookie } from '../../../globals';
import AuthService from '../../../globals/utils/auth';

export const createInitialPayload = () => {
  return {
    headers: {
      Accept: 'application/json',
    },
  };
};

export const checkForSavedRememberMe = async ({ context }) => {
  const uuid = getCookie(config.uuidCookie);
  const params = createInitialPayload();
  const url = apiLinks.saveRememberme.replace('{uuid}', uuid);
  const stateObj = {};

  params.headers.accept = 'application/text';

  if (uuid) {
    await apiRequest(url, 'GET', params).then(result => {
      if (result.status === CONST.STATUS_OK && result.data.length > 0) {
        stateObj.rememberMe = { value: true };
        stateObj.email = JSON.parse(JSON.stringify(context.state.email));
        stateObj.email.value = result.data;

        context.updateState(stateObj);
      }
    });
  }
};

export const credentialsBackendValidation = async (state, csrfToken) => {
  const params = createInitialPayload();
  const body = {
    email: state.email.value,
    password: state.password.value,
    username: state.email.value,
    csrfToken: csrfToken
  };
  let resultData = {};

  params.body = body;
  params.headers = { 'x-csrf-token': csrfToken };

  await apiRequest(apiLinks.login, 'POST', params)
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultData = result.data;
      } else {
        resultData.error = true;
      }
    })
    .catch(() => {
      resultData.error = true;
    });

  return resultData;
};

export const createAccessAndRefreshToken = credentialsValidation => {
  // set token if access token presented in response
  if (credentialsValidation.access_token && credentialsValidation.access_token.length > 0) {
    setToken(credentialsValidation.access_token, credentialsValidation.expires_in, credentialsValidation.token_type);
  }

  // set refresh token if refresh token presented in response
  if (credentialsValidation.refresh_token && credentialsValidation.refresh_token.length > 0) {
    AuthService.setRefreshToken(credentialsValidation.refresh_token, credentialsValidation.refresh_expires_in);
  }
};

export const createOrDeleteRememberMeCookie = async state => {
  //set cookie if remember me checkbox is clicked
  if (state.rememberMe.value) {
    const rememberMe = await getLoginForRememberMe();
    if (rememberMe && !rememberMe.error && rememberMe.length > 0) {
      setCookie(config.uuidCookie, rememberMe);
    }
  } else {
    eraseCookie(config.uuidCookie);
  }
};

export const getLoginForRememberMe = async () => {
  const params = createInitialPayload();
  let remembermeData = {};

  params.headers.accept = 'application/text';

  await apiRequest(apiLinks.rememberme, 'POST', params)
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        remembermeData = result.data;
      } else {
        remembermeData.error = true;
      }
    })
    .catch(() => {
      remembermeData.error = true;
    });

  return remembermeData;
};

import { config } from '../../data-config';

export const resetErrors = context => {
  const contextStateToSet = {};
  const stateToSet = {
    requestCompleted: false,
    technicalError: false,
  };

  contextStateToSet.email = {
    ...context.state.email,
    error: false,
  };

  return {
    stateToSet,
    contextStateToSet,
  };
};

export const canProceedToApiCall = emailString => config.regEx.emailV2.test(emailString);

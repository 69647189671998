import { apiLinks } from 'config/api-config';
import { checkFieldAndReturnState, checkGroupOfFieldsAndReturnState } from 'globals/utils/fieldValidations';
import { apiRequest } from 'globals/utils/requests';
import { canFetchCityForPostalcode, getPostalCodeRegex } from '../../../globals/helpers/postalcode';
import { CONST, config } from '../../data-config';
import { deleteContactList, saveOrUpdateContact } from '../ActionUtils';
import { caseInsensitiveEquals } from '../../../globals/utils/strings';

export const performActionEvent = async (action, context) => {
  const { state } = context;

  switch (action) {
    case CONST.SAVE:
      const updateAction = await checkContactSubmit(context);
      updateAction.message = 'Contact Updated Successfully';
      return updateAction;

    case CONST.SAVE_NEW:
      const addNewAction = await checkContactSubmit(context, true);
      addNewAction.message = 'New Contact Added Successfully';
      return addNewAction;

    case CONST.DELETE:
      const deleteAction = { message: 'Contact Deleted Successfully' };
      const deleteCall = await deleteContactList([state.id]);
      deleteAction.apiError = deleteCall;
      return deleteAction;

    default:
      return;
  }
};

export const getCityName = async (countryCode, postalCode, city) => {
  const resultMap = {};

  let url = apiLinks.fetchCityName;
  url = url.replace('{countryCode}', countryCode);
  url = url.replace('{postalCode}', postalCode);
  if (city && countryCode.toLowerCase() === 'se') {
    url = `${url}/${city}`;
  } else {
    url = `${url}`;
  }

  await apiRequest(url, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

const checkContactSubmit = async (context, isSaveNew = false) => {
  const { hasErrors, stateToSet, cityValidationObj } = await validateContact(context.state);

  let saveOrUpdateStatusError = false;

  if (!hasErrors) {
    saveOrUpdateStatusError = await saveOrUpdateContact(context.state, isSaveNew);
  } else {
    context.updateState(stateToSet);
  }

  return {
    validationError: hasErrors,
    apiError: saveOrUpdateStatusError.error || cityValidationObj.apiError || false,
    conflictError: saveOrUpdateStatusError.conflictError || false,
    maxLimitReachedError: saveOrUpdateStatusError.maxLimitReachedError || false,
    cityValidationObj: cityValidationObj,
  };
};

const validateContact = async state => {
  let getState = {};

  const stateToSet = {};
  let hasErrors = false;
  const cityValidationObj = {};

  getState = checkFieldAndReturnState({
    name: 'addressId',
    value: state.addressId.value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{0,${config.maxAddressIdLength}}$`)],
    isRequired: state.addressId.isRequired,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = {
    value: getState.value,
    error: getState.error,
    isRequired: getState.isRequired,
  };

  getState = checkFieldAndReturnState({
    name: 'notes',
    value: state.notes.value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`[\\r\\n]{0,${config.maxNotesLength}}$`)],
    isRequired: state.notes.isRequired,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = {
    value: getState.value,
    error: getState.error,
    isRequired: getState.isRequired,
  };

  getState = checkFieldAndReturnState({
    name: 'companyName',
    value: state.companyName.value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{1,${config.maxDefaultInputLength}}$`)],
    isRequired: state.companyName.isRequired,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = {
    value: getState.value,
    error: getState.error,
    isRequired: getState.isRequired,
  };

  getState = checkFieldAndReturnState({
    name: 'name',
    value: state.name.value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{0,${config.maxDefaultInputLength}}$`)],
    isRequired: state.name.isRequired,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = {
    value: getState.value,
    error: getState.error,
    isRequired: getState.isRequired,
  };

  getState = checkFieldAndReturnState({
    name: 'email',
    value: state.email.value,
    regEx: config.regEx.email,
    lengthCheck: [RegExp(`^.{0,${config.maxEmailLength}}$`)],
    isRequired: state.email.isRequired,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = {
    value: getState.value,
    error: getState.error,
    isRequired: getState.isRequired,
  };

  getState = checkGroupOfFieldsAndReturnState({
    groupName: 'phoneNumbers',
    values: state.phoneNumbers,
    fields: [
      {
        name: 'code',
        isRequired: false,
      },
      {
        name: 'number',
        regEx: config.regEx.number,
        lengthCheck: [RegExp(`^.{1,${config.maxPhoneNumberDigits}}$`)],
        isRequired: false,
        calculatePhoneLengthCheck: true,
      },
    ],
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.groupName] = [...getState.values];

  getState = checkFieldAndReturnState({
    name: 'countryCode',
    value: state.countryCode.value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{${config.minCountryLength},${config.maxCountryLength}}$`)],
    isRequired: state.countryCode.isRequired,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

  getState = checkFieldAndReturnState({
    name: 'street',
    value: state.street.value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{1,${config.maxDefaultInputLength}}$`)],
    isRequired: state.street.isRequired || false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

  getState = checkFieldAndReturnState({
    name: 'postalCode',
    value: state.postalCode.value,
    regEx: getPostalCodeRegex({
      countryCode: state.countryCode.value,
      accountCountryCode: state?.reactQueryUser?.user?.accountCountryCode,
    }),
    lengthCheck: [RegExp(`^.{1,${config.maxPostalCode}}$`)],
    isRequired: state.postalCode.isRequired || false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

  getState = checkFieldAndReturnState({
    name: 'city',
    value: state.city.value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{1,${config.maxDefaultInputLength}}$`)],
    isRequired: state.city.isRequired || false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

  const shouldValidateCity = canFetchCityForPostalcode({
    accountCountryCode: state.user?.accountCountryCode,
    countryCode: state.countryCode.value,
    postalCode: state.postalCode.value,
  });

  if (
    !hasErrors &&
    !stateToSet.city.error &&
    !stateToSet.postalCode.error &&
    caseInsensitiveEquals(state.user?.accountCountryCode, state.countryCode?.value) &&
    caseInsensitiveEquals(state.user?.accountCountryCode, 'se')
  ) {
    const fetchCityCall = await getCityName(state.countryCode.value, state.postalCode.value, state.city.value);

    if (shouldValidateCity && !fetchCityCall.error && fetchCityCall.data && fetchCityCall.data.validated) {
      cityValidationObj.cityNameMismatchError = !(
        fetchCityCall.data.city?.toLowerCase() === state.city.value?.toLowerCase()
      );
      cityValidationObj.city = fetchCityCall.data.city;
    } else if (!fetchCityCall.error && fetchCityCall.data && !fetchCityCall.data.validated) {
      cityValidationObj.cityPostalCodeError = true;
    } else if (shouldValidateCity) {
      cityValidationObj.apiError = true;
    }
  }

  hasErrors = cityValidationObj.cityNameMismatchError || cityValidationObj.cityPostalCodeError || hasErrors;

  getState = checkFieldAndReturnState({
    name: 'reference',
    value: state.reference.value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{0,${config.maxDefaultInputLength}}$`)],
    isRequired: state.reference.isRequired,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

  getState = checkFieldAndReturnState({
    name: 'deliveryInstructions',
    value: state.deliveryInstructions.value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)],
    isRequired: state.deliveryInstructions.isRequired,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error, isRequired: getState.isRequired };

  return {
    stateToSet,
    hasErrors,
    cityValidationObj,
  };
};

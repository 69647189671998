import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button, Portal, Stack } from '@mui/material';
import { apiLinks } from 'config/api-config';
import { downloadApiRequest, setCookie } from 'globals/utils/requests';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { ROUTES, withRouter } from 'router';
import {
  createReturnShipmentAction,
  deleteSavedShipment,
  fetchIncompleteSavedShipmentsList,
  trackShipment,
} from '../../../dashboard/components/ActionUtils';
import { ModalView } from '../../../dashboard/components/RecentShipments/ModalView/ModalView';
import ReturnModalView from '../../../dashboard/components/RecentShipments/ModalView/ReturnModalView';
import { CONST, config } from '../../../dashboard/data-config';
import { FILE_NAME_RETURN_LABEL } from '../../../utils/files';

class ActionsCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalError: false,
      isReturnModalOpen: false,
      returnModalError: false,
      loader: false,
      returnShipment: null,
    };
  }

  filenameToButtonLabel = fileName => {
    const fileType = fileName?.split('_')?.[0];
    switch (fileType) {
      case 'label':
        return 'general|Label';
      case FILE_NAME_RETURN_LABEL:
        return 'general|returnLabel';
      case 'shipmentlist':
        return 'general|Shipment List';
      case 'waybill':
        return 'general|Waybill';
      default:
        return fileName;
    }
  };

  deleteShipment = t => {
    this.setState({ loader: true }, async () => {
      const deleteActionError = await deleteSavedShipment([this.props.cellValue.id]);

      if (!deleteActionError) {
        this.props.onShowSuccessToaster('Shipment Deleted Successfully');
        await fetchIncompleteSavedShipmentsList(this.props.context);
        this.toggleModalView();
      } else {
        this.setState({
          modalError: true,
          loader: false,
        });
      }
    });
  };

  createReturnShipment = t => {
    this.setState({ loader: true }, async () => {
      const returnShipment = await createReturnShipmentAction(this.props.cellValue.id);

      if (returnShipment != null) {
        this.props.onShowSuccessToaster('Return Shipment Created Successfully');
        await fetchIncompleteSavedShipmentsList(this.props.context);
        this.setState({
          returnModalError: false,
          loader: false,
          returnShipment: returnShipment,
        });
      } else {
        this.setState({
          returnModalError: true,
          loader: false,
        });
      }
    });
  };

  trackShipment = (t, shipmentNumber) => {
    let trackingUrl = t('pageLinks|trackShipment');
    trackingUrl = trackingUrl.replace('{shipmentNumber}', shipmentNumber);

    window.open(trackingUrl);
  };

  toggleModalView = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      modalError: false,
      loader: false,
    }));
  };

  toggleReturnModalView = () => {
    this.setState(prevState => ({
      isReturnModalOpen: !prevState.isReturnModalOpen,
      returnModalError: false,
      loader: false,
      returnShipment: null,
    }));
  };

  reprintDocs = async filename => {
    let url = apiLinks.downloadDocumentPortal.replace('{UniqueCode}', this.props.cellValue.uniqueCode);
    url = url.replace('{fileName}', filename);

    await downloadApiRequest(url.toString(), 'GET').then(blob => {
      var file = new Blob([blob], {
        type: blob.type,
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
        return;
      }

      const urlU = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = urlU;
      link.setAttribute('download', `${filename}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  onBookNowClicked = t => {
    setCookie(config.portalOrderCookie, this.props.cellValue.id, CONST.SECONDS_PER_MINUTE, 's');
    this.props.navigate(ROUTES.portalOrder);
  };

  sortDocs = docs => {
    if (!docs) return [];

    const prioritySort = (a, b) => {
      const priorityOrder = { label: 0, shipmentlist: 1, waybill: 2 };
      const defaultPriority = 42;

      const fileTypeA = a?.fileName?.split('_')?.[0];
      const fileTypeB = b?.fileName?.split('_')?.[0];

      const priorityA = priorityOrder?.[fileTypeA] ?? defaultPriority;
      const priorityB = priorityOrder?.[fileTypeB] ?? defaultPriority;

      if (priorityA === priorityB) {
        return a?.filename?.localeCompare(b?.filename);
      }
      return priorityA - priorityB;
    };

    const sortedDocs = structuredClone(docs);
    sortedDocs.sort(prioritySort);
    return sortedDocs;
  };

  render() {
    const { status, productID } = this.props.cellValue;
    const docs = this.sortDocs(this.props.cellValue.printDocumentDTOS);
    const createReturnShipmentEnabled = config.productsReturnShipmentAvailable.includes(productID);

    return (
      <Translation>
        {t => (
          <>
            {this.state.isModalOpen && (
              <Portal>
                <ModalView
                  transactionId={this.props.context.state.transactionId}
                  error={this.state.modalError}
                  shipment={this.props.cellValue}
                  onCloseModalAction={this.toggleModalView}
                  onDeleteShipment={() => this.deleteShipment(t)}
                  loader={this.state.loader}
                />
              </Portal>
            )}
            {this.state.isReturnModalOpen && (
              <Portal>
                <ReturnModalView
                  transactionId={this.props.context.state.transactionId}
                  error={this.state.returnModalError}
                  shipment={this.props.cellValue}
                  onCloseModalAction={this.toggleReturnModalView}
                  onCreateReturnShipment={() => this.createReturnShipment(t)}
                  loader={this.state.loader}
                  returnShipment={this.state.returnShipment}
                />
              </Portal>
            )}

            <Stack gap={1} width="100%">
              {status === CONST.IN_COMPLETE && (
                <>
                  <Button
                    id={`bookNow${this.props.cellValue.rowTableIndex}Btn`}
                    onClick={() => this.onBookNowClicked(t)}
                    variant="contained"
                    fullWidth
                    sx={{ minHeight: '40px' }}
                  >
                    {t('general|labels|buttons|Book now')}
                  </Button>
                  <Button
                    id={`deleteSavedShipment${this.props.cellValue.rowTableIndex}Btn`}
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    onClick={() => this.toggleModalView()}
                    color="secondary"
                    fullWidth
                    sx={{
                      minHeight: '40px',
                    }}
                  >
                    {t('general|labels|buttons|Delete')}
                  </Button>
                </>
              )}

              {status !== CONST.IN_COMPLETE && (
                <>
                  <Button
                    id={`trackSavedShipment${this.props.cellValue.rowTableIndex}Btn`}
                    onClick={() => trackShipment(t, this.props.cellValue.shipmentNumber)}
                    fullWidth
                    variant="contained"
                    sx={{ minHeight: '40px' }}
                  >
                    {t('general|labels|buttons|Track Shipment')}
                  </Button>
                </>
              )}

              {(status === CONST.BOOKED || status === CONST.IN_TRANSIT || status === CONST.ORDER_SENT) && (
                <>
                  {docs.map(({ id, filename }) => (
                    <Button
                      key={id}
                      startIcon={<FileDownloadOutlinedIcon />}
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      onClick={() => {
                        this.reprintDocs(filename);
                      }}
                      sx={{
                        minHeight: '40px',
                        lineHeight: 1.25,
                        wordBreak: 'break-word',
                      }}
                      data-testid={`${filename}-download-btn`}
                    >
                      {t(this.filenameToButtonLabel(filename))}
                    </Button>
                  ))}
                </>
              )}

              {status !== CONST.IN_COMPLETE && createReturnShipmentEnabled && (
                <Button
                  id={`trackSavedShipment${this.props.cellValue.rowTableIndex}Btn`}
                  onClick={() => this.toggleReturnModalView()}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  sx={{
                    minHeight: '40px',
                  }}
                >
                  {t(`general|Create return shipment button`)}
                </Button>
              )}
            </Stack>
          </>
        )}
      </Translation>
    );
  }
}

export default withRouter(ActionsCell);

import { Button } from '../Button';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Delete } from '@mui/icons-material';
import { Box, TextField } from '@mui/material';
import { validateAccountNumber } from '../../../utils/accounts';
import { PROFILE_CONSTANTS } from './constants';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#333333',
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#fff',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const AccountTable = ({
  accounts,
  clearErrors,
  onDeleteAccount,
  setConfirmationDialogData,
  setValue,
  showProducts,
  watchAccountList,
  onAddAccount,
  userData,
  errors,
  setError,
  addingAccount,
  allowDeleteAccount = true,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {accounts.length === 0 ? (
        <Box my="16px">---</Box>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700, tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ whiteSpace: 'nowrap' }} width={200}>
                  {t('registration|accountNumber')}
                </StyledTableCell>
                {showProducts ? (
                  <StyledTableCell align="left">{t('editProfile|Account products')}</StyledTableCell>
                ) : null}
                <StyledTableCell width={200}></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts.map((item, index) => {
                const fieldId = `accountNumber${item.id}`;
                const accountReferenceNameId = `accountReferenceName${item.id}`;
                return (
                  <StyledTableRow key={`${item.id}`}>
                    <StyledTableCell component="th" scope="row">
                      {item.newlyAddedAccount ? (
                        <Box
                          sx={{
                            display: 'flex',
                            gap: '16px',
                          }}
                        >
                          <TextField
                            size="medium"
                            error={errors[fieldId] ? true : false}
                            helperText={errors && errors[fieldId] ? errors[fieldId].message : undefined}
                            value={item.accountNumber ?? ''}
                            onChange={event => {
                              const value = event.target.value;
                              const cutOffValue =
                                value?.length > 0
                                  ? value.slice(0, PROFILE_CONSTANTS.accountNumberCharacterLimit)
                                  : value;
                              validateAccountNumber({
                                accountCountryCode: userData?.user?.accountCountryCode?.toLowerCase(),
                                accountList: watchAccountList,
                                clearErrors,
                                fieldId,
                                id: item.id,
                                setError,
                                setValue,
                                t,
                                value: cutOffValue,
                              });
                            }}
                            required
                            label={t('registration|accountNumber')}
                            sx={{
                              width: '100%',
                              maxWidth: '300px',
                            }}
                            disabled={addingAccount}
                            data-testid={`${index}-account-number`}
                          />
                          <TextField
                            size="medium"
                            error={errors[accountReferenceNameId] ? true : false}
                            helperText={
                              errors && errors[accountReferenceNameId]
                                ? errors[accountReferenceNameId].message
                                : undefined
                            }
                            value={item.accountReferenceName ?? ''}
                            onChange={event => {
                              const value = event.target.value;
                              const newValue =
                                value?.length > 0
                                  ? value.slice(0, PROFILE_CONSTANTS.accountReferenceNameCharacterLimit)
                                  : value;
                              const newWatchAccountList = [...watchAccountList].map(account => {
                                if (account.id === item.id) {
                                  return { ...account, accountReferenceName: newValue };
                                }
                                return account;
                              });
                              setValue('accountList', newWatchAccountList);
                              clearErrors(accountReferenceNameId, { required: false });
                              clearErrors(`accountNumber${item.id}`);

                              const valid = newValue.length <= PROFILE_CONSTANTS.accountReferenceNameCharacterLimit;
                              if (!valid) {
                                setError(accountReferenceNameId, {
                                  type: 'value',
                                  message: t('general|errors|Invalid maxchar error', {
                                    name: t('registration|accountReferenceNameOptional'),
                                    max: PROFILE_CONSTANTS.accountReferenceNameCharacterLimit,
                                  }),
                                });
                              }
                            }}
                            label={t('registration|accountReferenceNameOptional')}
                            sx={{
                              width: '100%',
                              maxWidth: '300px',
                            }}
                            disabled={addingAccount}
                            data-testid={`${index}-account-reference-name`}
                          />
                        </Box>
                      ) : (
                        <div>
                          <div>
                            <strong data-testid={`${index}-item-account-number`}>{item.accountNumber}</strong>
                          </div>
                          {item.accountReferenceName ? <>({item.accountReferenceName})</> : null}
                        </div>
                      )}
                    </StyledTableCell>
                    {showProducts ? (
                      <StyledTableCell align="left">
                        {item.products?.length ? (
                          <>
                            <Box
                              component="ul"
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                            >
                              {item.products.map(product => {
                                return (
                                  <Box
                                    key={product.productName}
                                    component="li"
                                    sx={{
                                      flexBasis: '250px',
                                      marginLeft: '20px',
                                    }}
                                    data-testid={`${product.productCode}-text`}
                                  >
                                    {t(`products|${product.productCode}|name`)}
                                  </Box>
                                );
                              })}
                            </Box>
                          </>
                        ) : null}
                      </StyledTableCell>
                    ) : null}

                    <StyledTableCell align="right">
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          gap: '16px',
                        }}
                      >
                        {allowDeleteAccount || item.newlyAddedAccount ? (
                          <Button
                            disabled={addingAccount}
                            variant="outlined"
                            startIcon={<Delete />}
                            color="primary"
                            sx={{
                              '& svg': {
                                fontSize: '26px',
                              },
                            }}
                            onClick={() => {
                              const deleteAction = () => {
                                if (watchAccountList) {
                                  setValue(
                                    'accountList',
                                    watchAccountList.filter(newItem => newItem.id !== item.id),
                                  );
                                }
                                clearErrors(`accountNumber${item.id}`);
                                if (onDeleteAccount && !item.newlyAddedAccount) {
                                  onDeleteAccount(item);
                                }
                              };
                              if (item.newlyAddedAccount) {
                                deleteAction();
                              } else {
                                setConfirmationDialogData({
                                  ...item,
                                  open: true,
                                  onConfirm: () => {
                                    deleteAction();
                                  },
                                });
                              }
                            }}
                            dataTestId={`${index}-delete-button`}
                          >
                            {t('general|labels|buttons|Delete')}
                          </Button>
                        ) : null}
                        {item.newlyAddedAccount ? (
                          <Button
                            disabled={Boolean(errors[fieldId])}
                            loading={addingAccount}
                            color="primary"
                            variant="contained"
                            sx={{
                              '& svg': {
                                fontSize: '26px',
                              },
                            }}
                            onClick={() => {
                              clearErrors(fieldId, { required: true });
                              const valid = validateAccountNumber({
                                accountCountryCode: userData?.user?.accountCountryCode?.toLowerCase(),
                                accountList: watchAccountList,
                                clearErrors,
                                fieldId,
                                id: item.id,
                                setError,
                                setValue,
                                t,
                                value: item.accountNumber,
                              });
                              if (valid) {
                                onAddAccount({
                                  account: item,
                                  onSuccess: () => {
                                    setValue(
                                      'accountList',
                                      watchAccountList.map(item => ({
                                        ...item,
                                        newlyAddedAccount: false,
                                      })),
                                    );
                                  },
                                });
                              }
                            }}
                            dataTestId={`${index}-submit-button`}
                          >
                            {t('general|Submit')}
                          </Button>
                        ) : null}
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

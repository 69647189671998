import { config, CONST } from '../../../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import { getPickupPhoneNumber } from '../Route-helpers';

export const setSearchResultsAndPagination = (searchResults, state, pagination) => {
  const stateToSet = {};
  stateToSet.pagination = JSON.parse(JSON.stringify(state.paginationMaster));
  const properAddressResults = searchResults.error
    ? []
    : addCountryNameToAddressBook(searchResults.data.addressBookList);

  stateToSet.searchResults = properAddressResults;
  stateToSet.searchResultsMaster = properAddressResults;
  stateToSet.data = properAddressResults;

  stateToSet.apiError = searchResults.error;
  stateToSet.unauthorizedError = state.unauthorizedError || searchResults.unauthorizedError;
  stateToSet.loader = false;

  stateToSet.pagination.total = searchResults.error ? 0 : searchResults.data.total;

  stateToSet.pagination.pageSize = pagination.pageSize;

  stateToSet.pagination.lastPage = searchResults.error ? 0 : searchResults.data.lastPage;
  stateToSet.pagination.page = searchResults.error ? 1 : searchResults.data.page;

  stateToSet.paginationMaster = JSON.parse(JSON.stringify(stateToSet.pagination));

  return stateToSet;
};

const addCountryNameToAddressBook = addresBookList => {
  addresBookList.forEach(addressBook => {
    addressBook.countryName = addressBook.countryCode;
  });

  return addresBookList;
};

export const resetPagination = () => {
  return {
    page: 1,
    total: 0,
    pageSize: config.paginationInitialNumberOfItems,
    lastPage: 1,
  };
};

export const postSearchResults = async (searchTerm, pagination, criteriaCountry, accountCountryCode) => {
  const body = {
    countryCriteria: criteriaCountry ? 'INCLUDE' : 'EXCLUDE',
    criteriaCountries: criteriaCountry ? [criteriaCountry] : [accountCountryCode],
    page: pagination && pagination.page ? pagination.page : 1,
    pageSize: pagination && pagination.pageSize ? pagination.pageSize : config.paginationInitialNumberOfItems,
    searchTerm: searchTerm,
  };

  return await addressSearchBackendCall(body);
};

export const addressSearchBackendCall = async body => {
  const resultMap = {};

  await apiRequest(apiLinks.addressSearch, 'POST', {
    body: body,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const fillAddress = ({ context, address, party, residentialToDisabled, residentialFromDisabled }) => {
  const phoneNumber = getPickupPhoneNumber(address.phone);
  let stateToSet;

  if (party === CONST.SENDER) {
    stateToSet = {
      ...context.state,
      pickupCity: { error: false, value: address.city || '', isRequired: true },
      pickupCountry: {
        error: false,
        value: address.countryCode || '',
        comparisonError: false,
        isRequired: true,
      },
      pickupEmail: { error: false, value: address.email || '', isRequired: false },
      pickupEmailConfirmation: { error: false, value: address.emailConfirmation || false, isRequired: false },
      pickupName: { error: false, value: address.name || '', isRequired: true },
      pickupPostalCode: { error: false, value: address.postalCode || '', isRequired: true },
      pickupPhoneNumbers: phoneNumber,
      pickupSender: { error: false, value: address.companyName || '', isRequired: true },
      pickupStreet: { error: false, value: address.street || '', isRequired: true },
      pickupAddressResidential: {
        value:
          residentialFromDisabled || context.state.pickupFromDifferentAddress.value
            ? false
            : address.residentialAddress,
      },
      confirmationEmail: { error: false, value: address.email || '', isRequired: true },
      senderPickedFromAddressBook: true,
      senderAddressID: { value: address.id },
    };
  } else if (party === CONST.RECEIVER) {
    stateToSet = {
      ...context.state,
      deliveryCity: { error: false, value: address.city || '', isRequired: true },
      deliveryCountry: {
        error: false,
        value: address.countryCode || '',
        comparisonError: false,
        isRequired: true,
      },
      deliveryEmail: { error: false, value: address.email || '', isRequired: false },
      deliveryEmailConfirmation: { error: false, value: address.emailConfirmation || false, isRequired: false },
      deliveryName: { error: false, value: address.name || '', isRequired: true },
      deliveryPostalCode: { error: false, value: address.postalCode || '', isRequired: true },
      deliveryPhoneNumbers: phoneNumber,
      deliveryReceiver: { error: false, value: address.companyName || '', isRequired: true },
      deliveryStreet: { error: false, value: address.street || '', isRequired: true },
      deliveryAddressResidential: {
        value:
          residentialToDisabled || context.state.deliverToDifferentAddress.value ? false : address.residentialAddress,
      },
      confirmationEmail: { error: false, value: address.email || '', isRequired: true },
      receiverPickedFromAddressBook: true,
      receiverAddressID: { value: address.id },
    };
  } else if (party === CONST.DIFFERENT_SENDER) {
    stateToSet = {
      ...context.state,
      differentPickupCity: { error: false, value: address.city || '', isRequired: true },
      differentPickupCountry: {
        error: false,
        value: address.countryCode || '',
        comparisonError: false,
        isRequired: true,
      },
      differentPickupEmail: { error: false, value: address.email || '', isRequired: false },
      differentPickupEmailConfirmation: { error: false, value: address.emailConfirmation || false, isRequired: false },
      differentPickupName: { error: false, value: address.name || '', isRequired: true },
      differentPickupPostalCode: { error: false, value: address.postalCode || '', isRequired: true },
      differentPickupPhoneNumbers: phoneNumber,
      differentPickupSender: { error: false, value: address.companyName || '', isRequired: true },
      differentPickupStreet: { error: false, value: address.street || '', isRequired: true },
      differentPickupAddressResidential: { value: residentialFromDisabled ? false : address.residentialAddress },
      confirmationEmail: { error: false, value: address.email || '', isRequired: true },
      differentSenderPickedFromAddressBook: true,
      differentSenderAddressID: { value: address.id },
    };
  } else if (party === CONST.DIFFERENT_RECEIVER) {
    stateToSet = {
      ...context.state,
      differentDeliveryCity: { error: false, value: address.city || '', isRequired: true },
      differentDeliveryCountry: {
        error: false,
        value: address.countryCode || '',
        comparisonError: false,
        isRequired: true,
      },
      differentDeliveryEmail: { error: false, value: address.email || '', isRequired: false },
      differentDeliveryEmailConfirmation: {
        error: false,
        value: address.emailConfirmation || false,
        isRequired: false,
      },
      differentDeliveryName: { error: false, value: address.name || '', isRequired: true },
      differentDeliveryPostalCode: { error: false, value: address.postalCode || '', isRequired: true },
      differentDeliveryPhoneNumbers: phoneNumber,
      differentDeliveryReceiver: { error: false, value: address.companyName || '', isRequired: true },
      differentDeliveryStreet: { error: false, value: address.street || '', isRequired: true },
      differentDeliveryAddressResidential: { value: residentialToDisabled ? false : address.residentialAddress },
      differentConfirmationEmail: { error: false, value: address.email || '', isRequired: true },
      differentReceiverPickedFromAddressBook: true,
      differentReceiverAddressID: { value: address.id },
    };
  }

  fillDeliveryInstruction(stateToSet, party, context.state, address);
  fillSenderReceiverReference(stateToSet, party, context.state, address);

  context.updateState(stateToSet);
};

const fillDeliveryInstruction = (stateToSet, party, ctxState, address) => {
  if (
    address.deliveryInstructions &&
    ((ctxState.deliverToDifferentAddress.value && party === CONST.DIFFERENT_RECEIVER) ||
      (!ctxState.deliverToDifferentAddress.value && party === CONST.RECEIVER))
  ) {
    stateToSet.deliveryInstructions = { value: address.deliveryInstructions, error: false };
  }
};

const fillSenderReceiverReference = (stateToSet, party, ctxState, address) => {
  if (
    address.reference &&
    ((ctxState.pickupFromDifferentAddress.value && party === CONST.DIFFERENT_SENDER) ||
      (!ctxState.pickupFromDifferentAddress.value && party === CONST.SENDER))
  ) {
    stateToSet.pickupSenderReference = { value: address.deliveryInstructions, error: false };
  } else if (
    address.reference &&
    ((ctxState.deliverToDifferentAddress.value && party === CONST.DIFFERENT_RECEIVER) ||
      (!ctxState.deliverToDifferentAddress.value && party === CONST.RECEIVER))
  ) {
    stateToSet.deliveryReceiverReference = { value: address.deliveryInstructions, error: false };
  }
};

export const shipmentRangeIsAllowed = (selectedAddress, context, party) => {
  if (party === 'receiver' && context.state.internationalProductsSelected && selectedAddress.countryCode === 'SE')
    return false;
  return true;
};

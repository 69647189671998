import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorPageContent401 } from './ErrorPageContent/ErrorPageContent401';
import { ErrorPageContent403 } from './ErrorPageContent/ErrorPageContent403';
import { ErrorPageContent404 } from './ErrorPageContent/ErrorPageContent404';
import { ErrorPageContent500 } from './ErrorPageContent/ErrorPageContent500';
import { Typography } from '@mui/material';

export function ErrorPage(props) {
  let { errorNumber } = useParams();
  if (!errorNumber) {
    errorNumber = props.errorNumber;
  }
  const { t } = useTranslation();
  return (
    <div>
      <div className="c-page-headline component-small has-rte component-margin frc__heading">
        <div className="c-page-headline--wrapper">
          <Typography variant="h1" mt={{ xs: 0, sm: 2 }}>
            {t(`errorPage|Sorry!`)}
          </Typography>
        </div>
      </div>
      {errorNumber === '401' && <ErrorPageContent401 />}
      {errorNumber === '403' && <ErrorPageContent403 />}
      {errorNumber === '404' && <ErrorPageContent404 />}
      {errorNumber === '500' && <ErrorPageContent500 />}
    </div>
  );
}

import { config } from '../../data-config';

const ENV_DEV = process.env.NODE_ENV === 'development';

export const resetErrors = () => {
  return {
    technicalError: false,
    passwordMismatchError: false,
    success: false,
  };
};

export const validatePasswordsAndResetCode = context => {
  const { newPassword, newPasswordConfirmation } = context.state;
  const newPasswordIsValid = config.regEx.password.test(newPassword.value);
  const passwordConfirmationIsValid = config.regEx.password.test(newPasswordConfirmation.value);
  const passwordsMatch = newPasswordConfirmation.value === newPassword.value;
  const resetCode = window.location.href.split('code=')[1];
  const contextStateToSet = {};
  const stateToSet = {};

  contextStateToSet.newPassword = {
    ...context.state.newPassword,
    error: !newPasswordIsValid,
  };

  contextStateToSet.newPasswordConfirmation = {
    ...context.state.newPasswordConfirmation,
    error: !passwordConfirmationIsValid || newPasswordConfirmation.value === '',
  };

  context.updateState(contextStateToSet);

  // New password and confirmation must match
  if (newPasswordIsValid && passwordConfirmationIsValid && !passwordsMatch) {
    stateToSet.passwordMismatchError = true;
  }

  // resetCode must exist
  if (!resetCode) {
    ENV_DEV &&
      console.error(
        'resetCode does not exist! The URL for the current page must contain a valid ?code={resetCode} parameter',
      );
    stateToSet.technicalError = true;
  }

  return {
    stateToSet,
    validationParams: {
      resetCode,
      newPasswordIsValid,
      passwordConfirmationIsValid,
      passwordsMatch,
    },
  };
};

export const canProceedToApiCall = params =>
  params.newPasswordIsValid && params.passwordConfirmationIsValid && params.passwordsMatch && params.resetCode;

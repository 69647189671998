import { useQuery } from '@tanstack/react-query';
import { apiRequest } from '../../globals';
import { apiLinks } from '../../config/api-config';
import { useCountryLanguage } from '../../hooks/useCountryLanguage';

export const countryConfigQueryKey = 'country-config';

const fetchCountryConfig = async () => {
  const res = await apiRequest(apiLinks.getCountryConfig, 'GET');
  if (res.status !== 200) {
    throw new Error(`Error in fetching country config, Status: ${res.status} Message: ${res.status}`);
  }
  return res.data;
};

export const useGetCountryConfig = ({ select, options } = {}) => {
  const { country } = useCountryLanguage();
  const countryCode = country?.toLowerCase();

  return useQuery({
    queryKey: [countryConfigQueryKey, countryCode],
    queryFn: fetchCountryConfig,
    select: select,
    staleTime: Infinity,
    enabled: Boolean(countryCode),
    ...options,
  });
};

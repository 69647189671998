import { useTranslation } from 'react-i18next';
import { ErrorPageRedirections } from './ErrorPageRedirections';

export function ErrorPageContent404() {
  const { t } = useTranslation();

  return (
    <>
      <div className="c-page-headline component-small has-rte component-margin">
        <p className="has-rte">
          {t('errorPage|Unfortunately the page you are looking for is unavailable or no longer exists.')}
        </p>
      </div>
      <ErrorPageRedirections />
    </>
  );
}

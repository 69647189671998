import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import { Error } from 'components';
import { AUTHORIZATION } from '../../../Login/constants';
import { ErrorTitleWrapper, SuccessTitleWrapper } from '../../../globals/components/ThankYou/TitleWrapper';
import { Box } from '@mui/material';
import ImportantRequirement from '../../../globals/components/ThankYou/ImportantRequirement';
import { composeFileName } from '../../../globals/components/ThankYou/ThankYou-helpers';

class ThankYouPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickupOrderResult: this.props.pickupOrderResult,
      PDFdownloadError: false,
      isRedThankYouPage: this.props.isRedThankYouPage,
      redThankYouPageErrorMessage: this.props.redThankYouPageErrorMessage,
      responseStatus: this.props.responseStatus,
      pickupDate: this.props.pickupDate,
      pickupNumber: this.props.pickupNumber,
      pickupOrderMovedToNextDay: this.props.pickupOrderMovedToNextDay,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  concatUint8Arrays(arrays) {
    // sum of individual array lengths
    let totalLength = arrays.reduce((acc, value) => acc + value.length, 0);

    if (!arrays.length) return null;

    let result = new Uint8Array(totalLength);

    // for each array - copy it over result
    // next array is copied right after the previous one
    let length = 0;
    for (let array of arrays) {
      result.set(array, length);
      length += array.length;
    }

    return result;
  }

  downloadWaybillPDFForIE(url, uniqueCode, filename) {
    // 'old way' xhr request necessary for IE, cause IE is not able to handle
    // modern (ES6+) JavaScript features for handling readable stream
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'arraybuffer';
    xhr.setRequestHeader(AUTHORIZATION, localStorage.getItem(AUTHORIZATION));
    xhr.setRequestHeader('UniqueCode', uniqueCode);

    xhr.onload = function () {
      if (this.status === 200) {
        let blob = new Blob([xhr.response], { type: 'application/pdf' });
        navigator.msSaveBlob(blob, filename);
      } else {
        this.setState({ PDFdownloadError: true });
      }
    };
    xhr.send();
  }

  downloadWaybillPDF(file, uniqueCode) {
    let url = apiLinks.downloadWaybillPDF,
      newWindowWithPDFSummary,
      fileURL,
      isIE = window.navigator && window.navigator.msSaveOrOpenBlob;

    let params = {
      headers: {
        type: 'pdf',
        UniqueCode: uniqueCode,
      },
    };

    if (!isIE) {
      newWindowWithPDFSummary = window.open();
    } else {
      this.downloadWaybillPDFForIE(url, uniqueCode, file.name);
      return;
    }

    apiRequest(url, 'GET', params).then(result => {
      if (result.status === 200) {
        let reader = result.data;
        let allUint8Arrays = [];
        let that = this;

        // read() returns a promise that resolves
        // when a value has been received
        reader.read().then(function processText({ done, value }) {
          // Result objects contain two properties:
          // done  - true if the stream has already given you all its data.
          // value - some data. Always undefined when done is true.
          if (done) {
            let contatedArrays = Array.from(that.concatUint8Arrays(allUint8Arrays));

            let blob = new Blob([new Uint8Array(contatedArrays)], { type: 'application/pdf' });

            fileURL = URL.createObjectURL(blob);
            newWindowWithPDFSummary.location = fileURL;

            return;
          }

          allUint8Arrays.push(value);

          // Read some more, and call this function again
          return reader.read().then(processText);
        });
      } else {
        this.setState({ PDFdownloadError: true });
      }
    });
  }

  downloadFile = (fileName, fileIndex) => {
    if (fileIndex === 'noIndex') {
      return;
    }

    let file = this.state.pickupOrderResult.reports[fileIndex];

    if (file.type === 'Waybill') {
      this.downloadWaybillPDF(file, this.state.pickupOrderResult.uniqueCode);
    }
  };

  getDocumentList = reports => {
    const documentList = [];

    reports.forEach(report => {
      documentList.push(report.name);
    });

    return documentList;
  };

  render() {
    return (
      <Translation>
        {t => (
          <Box
            sx={{
              maxWidth: '1365px',
              margin: 'auto',
              boxSizing: 'border-box',
              marginBottom: '15rem',
              marginTop: '2.5rem',
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box
              sx={{
                flex: 2,
              }}
            >
              <Box
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                }}
              >
                <Box
                  sx={{
                    padding: '2rem',
                  }}
                >
                  {this.state.isRedThankYouPage ? (
                    <ErrorTitleWrapper
                      titleCode={
                        this.state.responseStatus === 3
                          ? 'palletPickup|Sorry, your order could not be accepted! Your pallet balance is too low!'
                          : 'general|Sorry, your order could not be accepted!'
                      }
                    />
                  ) : (
                    <SuccessTitleWrapper titleCode="general|Thank you for booking your pickup" />
                  )}
                  {this.state.isRedThankYouPage && (
                    <Error
                      name="isRedThankYouPageError"
                      className=""
                      message={
                        t('general|errors|For any questions please contact') +
                        ' ' +
                        t('general|customerServicePhoneNumber')
                      }
                    />
                  )}
                  {!this.state.isRedThankYouPage && (
                    <>
                      <p>
                        {t('general|Your pickup order number is')}
                        <strong> {this.state.pickupNumber}</strong>
                      </p>
                      {this.state.pickupOrderMovedToNextDay && (
                        <p>{t('palletPickup|Your order has been moved to next working day.')}</p>
                      )}
                      <p>{t('palletPickup|We will pickup your order within five working days.')}</p>
                    </>
                  )}
                </Box>
                {!this.state.isRedThankYouPage && (
                  <ImportantRequirement
                    state={this.state}
                    options={
                      this.state.pickupOrderResult ? this.getDocumentList(this.state.pickupOrderResult.reports) : []
                    }
                    fileNameComposer={composeFileName}
                    errorMessageCode="palletPickup|Something has gone wrong with the download. Try again later."
                    downloadFile={this.downloadFile}
                  />
                )}
              </Box>
            </Box>

            {!this.state.isRedThankYouPage && (
              <Box
                sx={{
                  flex: '1',
                  marginLeft: ' 2rem',
                }}
              >
                <Box
                  sx={{
                    padding: '2rem',
                    backgroundColor: '#f2f2f2',
                  }}
                >
                  <h5>{t('general|Next Steps')}</h5>
                  <p>
                    {t(`portalPickup|A summary will be sent to your provided e-mail address`) +
                      ' ' +
                      t(
                        'portalOrder|A summary and your shipment documents will be sent to your provided e-mail address. If you need to change or cancel your booking, call Customer Service on',
                      ) +
                      ' ' +
                      t('general|customerServicePhoneNumber') +
                      ' '}
                  </p>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Translation>
    );
  }
}

export default ThankYouPage;

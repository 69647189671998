import { formatDateTime } from 'globals/utils/formatting';
import { config } from '../../data-config';
import { isFreeVas } from '../../../portal-order/components/DeliveryOptions/DeliveryOptions-helpers';

export const getAdditionalServices = (additionalServices, deliveryState, countryConfig) => {
  const selectedAdditionalServices = {};
  const additionalServicesMap = {};

  if (additionalServices && additionalServices.length > 0) {
    additionalServices.forEach(additionalService => {
      if (additionalService.value) {
        if (additionalService.groups && additionalService.groups.length > 0) {
          const services = {};

          additionalService.groups.forEach(group => {
            group.options.forEach(optionList => {
              if (additionalService.optionNotToHide && optionList && optionList.length > 0) {
                optionList.forEach(option => {
                  if (additionalService.optionNotToHide) {
                    switch (option.type) {
                      case config.OptionFieldTypes.Telephone:
                        services[option.optionCode] = option.value[0].code + '-' + option.value[0].number;
                        break;
                      case config.OptionFieldTypes.DateTime:
                        services[option.optionCode] = formatDateTime(option.value);
                        break;
                      case config.OptionFieldTypes.TickBox:
                        services[option.optionCode] = option.selected;
                        break;
                      case config.OptionFieldTypes.Select:
                        if (
                          additionalService.code === config.OptionFieldTypes.collectionAtTerminal &&
                          option.optionCode === config.OptionFieldTypes.ListCOT
                        ) {
                          const collectionAtTerminal = option.values.find(value => value.value === option.value);

                          additionalServicesMap.collectionAtTerminal = collectionAtTerminal;
                          selectedAdditionalServices[additionalService.code] = true;
                        } else services[option.optionCode] = option.value;
                        break;
                      default:
                        services[option.optionCode] = option.value ? option.value.replace(',', '.') : null;
                    }
                  } else if (!additionalService.optionNotToHide) {
                    services[option.optionCode] = option.value;
                  }
                });
              } else {
                selectedAdditionalServices[additionalService.code] = true;
              }
            });
          });

          if (additionalService.code !== 'collectionAtTerminal' && JSON.stringify(services) !== '{}')
            selectedAdditionalServices[additionalService.code] = services;
          else if (additionalService.code === 'fixedDeliveryDate') {
            // new requirement -> selected FDD -> send deliveryDate (estimated Delivery By value)
            const product = deliveryState.matchedProducts.find(product => {
              return product.code === additionalService.productCode;
            });
            selectedAdditionalServices[additionalService.code] = product ? { date: product.deliveryDate } : null;
          }
        } else {
          try {
            const freeVas = isFreeVas({
              freeVas: countryConfig?.freeVass,
              vasCode: additionalService.code,
              selectedProductCode: deliveryState.selectedProduct.code,
            });
            if (!freeVas) {
              selectedAdditionalServices[additionalService.code] = true;
            }
          } catch (error) {
            console.error('error', error);
          }
        }
      }
    });
  }
  additionalServicesMap.selectedAdditionalServices = selectedAdditionalServices;

  return additionalServicesMap;
};

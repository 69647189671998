import { config } from '../data-config';
import { DOMESTIC_SHIPMENT_RANGE, EXPORT_SHIPMENT_TYPE, SENDER_PAYER } from '../../globals/constants';

export const state = {
  languageCode: config.locale.defaultLanguage,
  transactionId: '',
  internationalShippingCountries: [],
  customs: false,
  countryBasedCustoms: false,

  templateLoader: false,
  almostThereLoader: false,
  addressFilledFromCookie: false,
  percentageText: '',
  progress: 0,

  accountNumber: { value: '', error: false, isRequired: true, display: true, isSelect: true },
  receiverNumber: { value: '', error: false, isSelect: false },
  thirdPartyNumber: { value: '', error: false },

  payingAccountNumber: { value: '', error: false },
  shipmentRange: { value: DOMESTIC_SHIPMENT_RANGE },
  shipmentTypesImpOrExp: { value: EXPORT_SHIPMENT_TYPE },
  prevShipmentTypesImpOrExp: { value: EXPORT_SHIPMENT_TYPE },
  shipmentPayer: { value: SENDER_PAYER },
  pickupFromDifferentAddress: { value: false },
  deliverToDifferentAddress: { value: false },
  pickupAddressResidential: { value: false },
  differentPickupAddressResidential: { value: false },
  deliveryAddressResidential: { value: false },
  differentDeliveryAddressResidential: { value: false },

  pickupSender: { value: '', error: false, isRequired: true },
  pickupEmail: { value: '', error: false, isRequired: false },
  pickupEmailConfirmation: { value: false, error: false },
  pickupCountry: {
    value: localStorage.getItem('country')?.toUpperCase(),
    error: false,
    comparisonError: false,
    isRequired: true,
  },
  pickupCompany: { value: '', error: false },
  pickupVatNumber: { value: '', error: false },
  pickupName: { value: '', error: false, isRequired: true },
  pickupStreet: { value: '', error: false, isRequired: true },
  pickupPostalCode: { value: '', error: false, isRequired: true },
  pickupCity: { value: '', error: false, isRequired: true },
  pickupPhoneNumbers: [{ code: '', number: '', error: '', isRequired: true }],
  pickupSenderReference: { value: '', error: false },

  differentPickupSender: { value: '', error: false, isRequired: true },
  differentPickupEmail: { value: '', error: false, isRequired: false },
  differentPickupEmailConfirmation: { value: false, error: false },
  differentPickupCountry: {
    value: localStorage.getItem('country')?.toUpperCase(),
    error: false,
    comparisonError: false,
    isRequired: true,
  },
  differentPickupCompany: { value: '', error: false },
  differentPickupVatNumber: { value: '', error: false },
  differentPickupName: { value: '', error: false, isRequired: true },
  differentPickupStreet: { value: '', error: false, isRequired: true },
  differentPickupPostalCode: { value: '', error: false, isRequired: true },
  differentPickupCity: { value: '', error: false, isRequired: true },
  differentPickupPhoneNumbers: [{ code: '', number: '', error: '', isRequired: true }],

  deliveryReceiver: { value: '', error: false, isRequired: true },
  deliveryEmail: { value: '', error: false, isRequired: false },
  deliveryEmailConfirmation: { value: false, error: false },
  deliveryCountry: {
    value: localStorage.getItem('country')?.toUpperCase(),
    error: false,
    comparisonError: false,
    isRequired: true,
  },
  deliveryCompany: { value: '', error: false },
  deliveryVatNumber: { value: '', error: false },
  deliveryName: { value: '', error: false, isRequired: true },
  deliveryStreet: { value: '', error: false, isRequired: true },
  deliveryPostalCode: { value: '', error: false, isRequired: true },
  deliveryCity: { value: '', error: false, isRequired: true },
  deliveryPhoneNumbers: [{ code: '', number: '', error: '', isRequired: true }],
  deliveryReceiverReference: { value: '', error: false },

  differentDeliveryReceiver: { value: '', error: false, isRequired: true },
  differentDeliveryEmail: { value: '', error: false, isRequired: false },
  differentDeliveryEmailConfirmation: { value: false, error: false },
  differentDeliveryCountry: {
    value: localStorage.getItem('country')?.toUpperCase(),
    error: false,
    comparisonError: false,
    isRequired: true,
  },
  differentDeliveryCompany: { value: '', error: false },
  differentDeliveryVatNumber: { value: '', error: false },
  differentDeliveryName: { value: '', error: false, isRequired: true },
  differentDeliveryStreet: { value: '', error: false, isRequired: true },
  differentDeliveryPostalCode: { value: '', error: false, isRequired: true },
  differentDeliveryCity: { value: '', error: false, isRequired: true },
  differentDeliveryPhoneNumbers: [{ code: '', number: '', error: '', isRequired: true }],
  termsOfDelivery: { value: null, error: false, isRequired: true, possibleValues: ['val1', 'val2'] },
  deliveryTypesForParcelConnect: {},

  // Address pickup
  searchTerm: { value: '', error: false },

  // Template
  templateSearch: { value: '', error: false },
  templateName: { value: '', error: false, isRequired: true },

  // Address Book Checkbox
  addToAddressBookSender: { value: false, error: false },
  addToAddressBookReceiver: { value: false, error: false },
  updateInAddressBookSender: { value: false, error: false },
  updateInAddressBookReceiver: { value: false, error: false },
  addToAddressBookDifferentSender: { value: false, error: false },
  addToAddressBookDifferentReceiver: { value: false, error: false },
  updateInAddressBookDifferentSender: { value: false, error: false },
  updateInAddressBookDifferentReceiver: { value: false, error: false },

  senderPickedFromAddressBook: false,
  receiverPickedFromAddressBook: false,
  differentSenderPickedFromAddressBook: false,
  differentReceiverPickedFromAddressBook: false,

  accounts: [],
  originalAccounts: [],
  userDataWasDownloaded: false,

  selectedProduct: { code: '' },
  templateType0SavedShipment: {},
  templateType1SavedShipment: {},

  //shipment details

  totals: {
    shipmentDetailsRows: {
      quantity: { value: 1 },
      volume: { value: 0 },
      weight: { value: 0 },
      loadingMeter: { value: '' },
      chargeableWeight: { value: null, error: false },
      goodsDescription: { value: '' },
      shippingMark: { value: '' },
    },
  },
  allowedShipmentTypes: [],
  shipmentDetailsRows: [],

  //Poland
  sentReference: { value: '', error: false },
  sentCarrierKey: { value: '', error: false },
  //Hungary
  ekaerFree: { value: null, error: false },
  ekaerNumber: { value: '', error: false },

  ltcFailed: false,

  // delivery options state
  priorityServiceDisabled: false,
  pickupDate: { value: new Date(), error: false },
  desiredPickupDates: [],
  additionalServices: [],
  servicePointUseResidentialAddress: { value: false, disabled: false, isProductServicePointDelivery: false },
  chargeableWeight: '',
  payerCode: '',
  isSavedTemplate: false,
  additionalServicesError: false,
  additionalServicesGeneralError: false,
  deliveryMonitoringExpanded: false,
  returnProdStatus: false,

  products: [],
  selectProductError: false,
  deliveryInstructions: { value: '', error: false },
  notificationsError: false,
  notificationEmail: {
    value: '',
    error: false,
  },
  isb2cProduct: false,
  notificationsForReceiver: [
    {
      type: 'sms',
      value: false,
      input: '',
      name: 'Notify by SMS',
    },
    {
      type: 'email',
      value: false,
      input: '',
      name: 'Notify by Email',
    },
  ],
  sourceDeviating: '1',
  destinationDeviating: '1',
  servicePointId: '',
  selectedServicePoint: {
    id: '',
    street: '',
    name: '',
    cityName: '',
    postalCode: '',
    countryCode: null,
    distance: 0,
    routeDistance: 0,
    featureCodes: [],
    lat: 0,
    lng: 0,
  },
  servicePointsError: false,
  initialServicePointStatus: false,
  markedServicePoint: {},
  matchedProducts: [],
  loaders: {
    products: { value: false },
    additionalServices: { value: false },
    shipmentDetails: { value: false },
  },
  212: {
    value: false,
    allowed: false,
    code: '212',
    name: 'DHL Parti',
    description: 'DHL Parti Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  210: {
    value: false,
    allowed: false,
    code: '210',
    name: 'DHL Pall',
    description: 'DHL Pall Tooltip',
    packageTypes: [
      { code: '701', name: 'DHL Helpall' },
      { code: '702', name: 'DHL Halvpall' },
    ],
  },
  211: {
    value: false,
    allowed: false,
    code: '211',
    name: 'DHL Stycke',
    description: 'DHL Stycke Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  102: {
    value: false,
    allowed: false,
    code: '102',
    name: 'DHL Paket',
    description: 'DHL Paket Tooltip',
    packageTypes: [
      { code: 'CLL', name: 'Unspecified Colli' },
      { code: 'PK', name: 'Package' },
    ],
  },
  112: {
    value: false,
    allowed: false,
    code: '112',
    name: 'DHL Parcel Connect Plus',
    description: 'DHL Parcel Connect Plus Tooltip',
    packageTypes: [
      { code: 'CLL', name: 'Unspecified Colli' },
      { code: 'PK', name: 'Package' },
    ],
  },
  103: {
    value: false,
    allowed: false,
    code: '103',
    name: 'DHL Service Point',
    description: 'DHL Service Point Tooltip',
    packageTypes: [
      { code: 'CLL', name: 'Unspecified Colli' },
      { code: 'PK', name: 'Package' },
    ],
  },
  104: {
    value: false,
    allowed: false,
    code: '104',
    name: 'DHL Service Point Retur',
    description: 'DHL Service Point Retur Tooltip',
    packageTypes: [
      { code: 'CLL', name: 'Unspecified Colli' },
      { code: 'PK', name: 'Package' },
    ],
  },
  109: {
    value: false,
    allowed: false,
    code: '109',
    name: 'DHL Parcel Connect',
    description: 'DHL Parcel Connect Tooltip',
    packageTypes: [
      { code: 'CLL', name: 'Unspecified Colli' },
      { code: 'PK', name: 'Package' },
    ],
  },
  107: {
    value: false,
    allowed: false,
    code: '107',
    name: 'DHL Parcel Return Connect',
    description: 'DHL Parcel Return Connect Tooltip',
    packageTypes: [
      { code: 'CLL', name: 'Unspecified Colli' },
      { code: 'PK', name: 'Package' },
    ],
  },
  401: {
    value: false,
    allowed: false,
    code: '401',
    name: 'DHL Home Delivery',
    description: 'DHL Home Delivery Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  402: {
    value: false,
    allowed: false,
    code: '402',
    name: 'DHL Home Delivery Return',
    description: 'DHL Home Delivery Return Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  202: {
    value: false,
    allowed: false,
    code: '202',
    name: 'DHL Euroconnect',
    description: 'DHL Euroconnect Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  205: {
    value: false,
    allowed: false,
    code: '205',
    name: 'DHL Euroline',
    description: 'DHL Euroline Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  232: {
    value: false,
    allowed: false,
    code: '232',
    name: 'DHL Euroconnect Plus',
    description: 'DHL Euroconnect Plus Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  233: {
    value: false,
    allowed: false,
    code: '233',
    name: 'DHL Eurapid',
    description: 'DHL Eurapid Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  ECD: {
    value: false,
    allowed: false,
    code: 'ECD',
    name: config.serviceDescriptions.ECD.header,
    description: config.serviceDescriptions.ECD,
  },
  ECI: {
    value: false,
    allowed: false,
    code: 'ECI',
    name: config.serviceDescriptions.ECI.header,
    description: config.serviceDescriptions.ECI,
  },
  ELD: {
    value: false,
    allowed: false,
    code: 'ELD',
    name: config.serviceDescriptions.ELD.header,
    description: config.serviceDescriptions.ELD,
  },
  ELI: {
    value: false,
    allowed: false,
    code: 'ELI',
    name: config.serviceDescriptions.ELI.header,
    description: config.serviceDescriptions.ELI,
  },
  ERI: {
    value: false,
    allowed: false,
    code: 'ERI',
    name: config.serviceDescriptions.ERI.header,
    description: config.serviceDescriptions.ERI,
  },
  HDI: {
    value: false,
    allowed: false,
    code: 'HDI',
    name: config.serviceDescriptions.HDI.header,
    description: config.serviceDescriptions.HDI,
  },
  HDL: {
    value: false,
    allowed: false,
    code: 'HDL',
    name: config.serviceDescriptions.HDL.header,
    description: config.serviceDescriptions.HDL,
  },
  SPD: {
    value: false,
    allowed: false,
    code: 'SPD',
    name: config.serviceDescriptions.SPD.header,
    description: config.serviceDescriptions.SPD,
  },
  PPD: {
    value: false,
    allowed: false,
    code: 'PPD',
    name: config.serviceDescriptions.PPD.header,
    description: config.serviceDescriptions.PPD,
  },

  servicePoints: [],
  productPrices: [],
  markup: {
    value: false,
    error: false,
    percentage: { value: '', error: false },
    amount: { value: '', error: false },
  },
  quotePriceMap: {},

  // schedule pickup

  pickupInstructions: { value: '', error: false },
  pickupOption: { value: '', error: false },
  instructions: { value: '', error: false },

  // review quote

  confirmationEmail: { value: '', error: false },
  returnShipmentRequested: { value: false },
  captcha: { value: '', error: false, token: '' },
  captchaImg: '',
  bookShipmentAPIError: false,

  tab4OrTab5Touched: false,
  quotePriceError: false,
  addressReferenceInstructions: {},
  dangerousGoodsLinkedIfNeeded: true,
};

import { apiRequest } from './requests';
import { apiLinks } from '../../config/api-config';

const ConfigService = {
  async getConfigValue(configKey) {
    const url = apiLinks.getApplicationConfigValue.replace('{key}', configKey);
    return await apiRequest(url, 'GET').then(response => {
      if (response && response.data && response.data.value) {
        return response.data.value;
      }
    });
  },

  async getSystemInMaintenance() {
    return await apiRequest(apiLinks.getSystemUnderMaintenance, 'GET').then(response => {
      if (response && response.data && response.data.value) {
        return response.data.value;
      }
    });
  },

  async getCountries() {
    return await apiRequest(apiLinks.getConfigCountries, 'GET')
      .then(response => {
        if (response && response.data) {
          return response.data;
        }
      })
      .catch(e => {
        console.error('config countries call error: ' + e);
      });
  },

  async getScheduledMaintenance() {
    return await apiRequest(apiLinks.getScheduledMaintenance, 'GET').then(response => {
      if (response && response.data && response.data.value) {
        return response.data.value;
      }
    });
  },
};
export default ConfigService;

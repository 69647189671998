import React, { Component } from 'react';
import { CONST } from '../../../data-config';
import { Translation } from 'react-i18next';
import { EditIcon } from 'components';
import { ViewIcon } from 'components';
import { DeleteIcon } from 'components';
import { ModalView } from '../ModalView/ModalView';

import { deleteTemplate, fetchTemplateOnSearchOrPageChangeOrReset } from '../../CommonUtils';

import './CustomCell.css';

export default class ActionCustomCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      modalError: false,
      loader: false,
      isViewTemplate: false,
    };
  }

  onModalActionClick = async (event, t) => {
    const { cellValue, action } = this.props;

    switch (event) {
      case CONST.EDIT:
        this.toggleModalView(action.edit(cellValue.id));
        break;

      case CONST.CANCEL:
        this.toggleModalView();
        break;

      case CONST.DELETE:
        this.setState({ loader: true }, async () => {
          const deleteTemplateCall = await deleteTemplate([cellValue.id]);

          if (!deleteTemplateCall) {
            this.props.onShowSuccessToaster(`Template Deleted Successfully`);
            await fetchTemplateOnSearchOrPageChangeOrReset(this.props.context);
            this.toggleModalView();
          } else {
            this.setState({
              modalError: true,
              loader: false,
            });
          }
        });
        break;

      default:
        break;
    }
  };

  toggleModalView = (callback, isViewTemplate) => {
    this.setState(
      prevState => ({
        isModalOpen: !prevState.isModalOpen,
        modalError: false,
        loader: false,
        isViewTemplate: isViewTemplate || false,
      }),
      async () => {
        if (callback && typeof callback === 'function') {
          await callback();
        }
      },
    );
  };

  render() {
    const { state } = this.props.context;
    const showEditViewBtn =
      state.accounts &&
      state.accounts.length > 0 &&
      JSON.stringify(state.userData) !== '{}' &&
      state.products.length > 0;
    const hideEditViewButtons = this.props.cellValue.status !== 'string' && this.props.cellValue.productID !== 'string';

    return (
      <Translation>
        {t => (
          <>
            {this.state.isModalOpen && (
              <ModalView
                template={this.props.cellValue}
                actionClick={event => this.onModalActionClick(event, t)}
                onCloseModalAction={this.toggleModalView}
                transactionId={state.transactionId}
                error={this.state.modalError}
                isViewTemplate={this.state.isViewTemplate}
                loader={this.state.loader}
              />
            )}
            <div className="l-grid frc__edit-action-btn-left">
              {hideEditViewButtons && (
                <div
                  className={`frc__operation-icon frc__icon-pointer frc__operation-icon-width  ${
                    !showEditViewBtn ? 'frc__diable-action-btn' : ''
                  }`}
                  onClick={showEditViewBtn ? () => this.toggleModalView(null, true) : null}
                >
                  <ViewIcon />
                  <span>{t('general|labels|buttons|View')}</span>
                </div>
              )}

              {hideEditViewButtons && (
                <div
                  className={`frc__operation-icon frc__icon-pointer frc__operation-icon-width  ${
                    !showEditViewBtn ? 'frc__diable-action-btn' : ''
                  }`}
                  onClick={showEditViewBtn ? () => this.props.action.edit(this.props.cellValue.id) : null}
                >
                  <EditIcon />
                  <span>{t('general|labels|buttons|Edit')}</span>
                </div>
              )}

              <div
                className="frc__operation-icon frc__icon-pointer frc__operation-icon-width"
                onClick={() => this.toggleModalView()}
              >
                <DeleteIcon />
                <span>{t('general|labels|buttons|Delete')}</span>
              </div>
            </div>
          </>
        )}
      </Translation>
    );
  }
}

import React, { Component } from 'react';
import { matchRegEx } from 'globals/utils/fieldValidations';
import { toCamelCase } from 'globals/utils/formatting';
import './Input.css';

class Input extends Component {
  constructor(props) {
    super(props);

    this.className = props.className || 'frc__input--wrapper frc__generic--field-wrapper';
    this.labelClass = props.labelClass || 'frc__label';
  }

  handleChange = (value, context) => {
    let newValue = value;
    if (this.props.isPhoneNumber) {
      newValue = value.replace(/\s+/g, '');
    }
    const params = {
      name: this.props.name,
      value: typeof value === 'string' ? newValue.replace(/^\s+/g, '') : newValue,
      isRequired: this.props.isRequired,
      min: this.props.min,
      max: this.props.max,
      compare: this.props.compare ? true : false,
      compareParameter: this.props.camparisonValue ? this.props.camparisonValue.field : undefined,
      compareValue: this.props.camparisonValue ? this.props.camparisonValue.value : undefined,
      index: this.props.index,
      disabled: this.props.disabled,
      grayedOut: this.props.grayedOut,
      skipError: this.props.skipError,
      beforeUpdate: this.props.beforeUpdate,
      afterUpdate: this.props.afterUpdate,
      lengthCheck: this.props.lengthCheck,
      cutTextLimit: this.props.cutTextLimit,
      type: this.props.type,
      inputType: this.props.inputType,
      decimalCutoff: this.props.decimalCutoff,
      integerCutoff: this.props.integerCutoff,
      decimalRegex: this.props.decimalRegex,
      callbackFunction: this.props.callback,
    };

    if (this.props.customValidation) {
      if (
        this.props.customValidation({
          value: params.value,
        })
      ) {
        context.updateField({ ...params, error: false });
      } else {
        context.updateField({ ...params, error: true });
      }
    } else if (matchRegEx(params.value, this.props.regEx)) {
      if (this.props.updateOnParent) {
        this.props.updateOnParent(params, context);
      } else {
        params.override = this.props.override ? this.props.override : undefined;
        context.updateField(params, this.props.camparisonValue ? this.props.camparisonValue.value : undefined);
      }
    }
  };

  handleKeyPress = key => {
    if (key === 'Enter' && typeof this.props.onKeyPress === 'function') {
      this.props.onKeyPress();
    }
  };

  render() {
    const inputId = toCamelCase(this.props.id || this.props.name) + 'Inp';
    const showAsterisk = this.props.isRequired && !this.props.hideAsterisk ? true : false;
    return (
      <>
        <span className={this.className} data-testid={`${this.props.name}InputId`}>
          <label
            htmlFor={inputId}
            className={this.labelClass + (this.props.grayedOut ? ' frc__input-label--grayed-out ' : '')}
            dangerouslySetInnerHTML={{
              __html:
                this.props.label + (showAsterisk ? '*' : '') + (this.props.labelAfter ? this.props.labelAfter : ''),
            }}
          />
          {this.props.tooltip ? (
            <div className="frc__tooltip-container frc__tooltip-container--input has-icon icon-information color-DHL-red c-fcp-quote--info-container">
              <div className="frc__tooltip-container-flyout">{this.props.tooltip}</div>
            </div>
          ) : (
            ''
          )}
          <input
            id={inputId}
            data-testid={`${this.props.dataTestId}`}
            type={this.props.inputType || 'text'}
            onBlur={
              this.props.disableonBlur
                ? null
                : typeof this.props.afterChangeOnBlur === 'function'
                ? () => this.props.afterChangeOnBlur(this)
                : () => this.handleChange(this.props.value, this.props.context)
            }
            onChange={e => this.handleChange(e.target.value, this.props.context)}
            placeholder={this.props.placeholder}
            className={
              'c-form--element-base c-form--element-input c-calculator--input-field ' +
              (this.props.onFocusTooltip ? 'frc__onFocusTooltip ' : '') +
              (this.props.inputClassName ? this.props.inputClassName : '') +
              (this.props.disabled ? ' disabled ' : '') +
              (this.props.grayedOut ? ' frc__input--grayed-out ' : '')
            }
            value={this.props.value}
            autoComplete={this.props.hideAutoComplete ? 'nope' : 'on'}
            disabled={this.props.disabled}
            onKeyPress={e => this.handleKeyPress(e.key)}
          />
          {this.props.onFocusTooltip ? (
            <div className="frc__onFocusTooltip-container l-grid--w-50pc-m">
              <div className="frc__onFocusTooltip-flyout">{this.props.onFocusTooltip}</div>
            </div>
          ) : (
            ''
          )}
        </span>
      </>
    );
  }
}

export default Input;

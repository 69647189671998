import React, { Component } from 'react';
import { MyProvider, MyContext } from '../../context/context';
import ChangePassword from '../ChangePassword/ChangePassword';
import { isValidCountryLanguage } from '../../../utils/urls';

export default class AppChangePassword extends Component {
  componentDidMount() {
    const lang = localStorage.getItem('i18nextLng');
    if (isValidCountryLanguage(lang)) {
      this.setState({ languageCode: lang });
    } else {
      console.error('Invalid country language');
    }
  }

  render() {
    return (
      <MyProvider>
        <MyContext.Consumer>{context => <ChangePassword context={context} />}</MyContext.Consumer>
      </MyProvider>
    );
  }
}

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toggleActiveTab } from '../Template-helpers';
import { Error } from 'components';
import SelectService from './SelectService/SelectService';
import AdditionalServices from './AdditionalServices/AdditionalServices';

import './DeliveryOptions.css';

export default function DeliveryOptions(props) {
  const { t } = useTranslation();
  const currentTab = useRef(null);
  const { context } = props;

  return (
    <div
      className="l-grid--w-100pc-s"
      onMouseLeave={() => toggleActiveTab(currentTab, false)}
      onMouseEnter={() => toggleActiveTab(currentTab, true)}
    >
      <div className="frc__app-tab" ref={currentTab}>
        <h3 className="frc__app-tab__title frc__app-margin-bottom-0">{t(`general|Delivery Options`)}</h3>
      </div>

      <div className="l-grid--w-100pc-s frc__generic-section--wrapper margin-bottom-1">
        <div className="frc__service-details-section--wrapper">
          <SelectService context={context} />
          <AdditionalServices context={context} />

          <div
            className={'frc__generic--loader-overlay' + (context.state.loaders.products.value ? ' is-loading ' : '')}
          />

          <div className="l-grid">
            {context.state.geoLocationError ? (
              <Error
                name="vasGeoLocationError"
                message={t(`portalTemplates|Home Delivery additional services not found for entered postal code`)}
              />
            ) : (
              (context.state.additionalServicesAPIError || context.state.additionalServicesGeneralError) && (
                <Error
                  name="vasDifferentErrors"
                  message={
                    context.state.additionalServicesGeneralError
                      ? t('general|errors|Please fill properly all necessary fields')
                      : t('general|errors|General error') +
                        '. <br> ' +
                        t('general|Try again or call technical support.') +
                        ' ' +
                        t('general|or') +
                        ' ' +
                        `<strong><a href="${t('pageLinks|chat')}" 
                                          class ="frc__chat-with-us"
                                          target="_blank" rel="noopener noreferrer">${t(
                                            `general|Chat with us`,
                                          )}</a></strong>` +
                        ' <br> ' +
                        t('general|ID') +
                        ': ' +
                        context.state.transactionId
                  }
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AddressCustomCell(props) {
  const { cellValue } = props;
  const { t } = useTranslation();
  const address = props.isSender ? cellValue.sender : cellValue.receiver;

  return (
    <div className="frc__custom-cell-line-break">
      <strong> {address.companyName || ''} </strong>
      <div>{address.street || ''}</div>
      <div>
        {address.postalCode || ''} {address.city || ''}
      </div>
      {address.country ? t(`countries|${address.country}`) : ''}
      <div> {props.isSender ? props.cellValue.sreference || '' : props.cellValue.rreference || ''}</div>
    </div>
  );
}

import { Radio } from 'components';
import { toCamelCase } from 'globals/utils/formatting';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductCardDeliveryDate } from '../../../../../components/fcp-components/ProductCardDeliveryDate';
import { getDescBulletPoints, getReadMoreLink } from '../../../../../globals/helpers/product';
import { useGetCountryConfig } from '../../../../../hooks/api/useGetCountryConfig';
import { CONST, config } from '../../../../data-config';
import { afterCheck, beforeCheck } from '../../../ServiceDetails/ServiceDetails';
import './SelectService.css';
import { useSelector } from 'react-redux';
import { productPickupDatesSelector, selectedPickupDateSelector } from '../../../../../store/quoteSlice';

export default function SelectService({ routeContext, shipmentContext, context, product, index, eurapidLtcError }) {
  const { t, i18n } = useTranslation();
  const selectProductRef = React.createRef();
  const { data: countryConfig } = useGetCountryConfig();
  const productDisabledVas = countryConfig?.disabledVass;
  const { selectedPickupDate } = useSelector(selectedPickupDateSelector);
  const { productPickupDates } = useSelector(productPickupDatesSelector);

  const selectService = params => {
    // in beforeUpdate blurs the screen
    // in afterUpdate is all the logic (selecting the product, VAS calls, etc.)
    params.groupName = 'matchedProducts';
    context.updateGroup(params);

    if (document.getElementById('frc_additional-services_delivery_monitoring')) {
      document.getElementById('frc_additional-services_delivery_monitoring').classList.remove('expand');
      document.getElementById('frc__special_case').classList.add('frc__special_case');
    }
    if (document.getElementById('fcp-product-container'))
      document.getElementById('fcp-product-container').classList.remove('has-pricelist-error');
  };

  useEffect(() => {
    // Selects the product on component mounting if it was chosen on step #1
    if (routeContext.state.selectedProduct.value) {
      selectProductRef.current.click();
    }
    // eslint-disable-next-line
  }, []);

  const accountNumber = product.account ? product.account : '';

  const checkValidPrice = price => {
    if (!price || price === 'null' || price === 0) {
      return '---';
    }
    return price;
  };

  const productDisabled =
    eurapidLtcError ||
    product.disabled ||
    (config.ltcEurapidProductCodes.includes(product.code.toString()) && !product.deliveryDate);

  const productPrice =
    product.price && product.price.FreightCost && product.price.FreightCost.unit
      ? product.price.FreightCost.unit
      : countryConfig?.defaultCurrency;

  const checked = product.value && !productDisabled ? product.value : false;

  return (
    <>
      <div className={`fcp__product-width  c-product ${index}`} id={index}>
        <div
          className={
            'l-grid--w-100pc-s  c-freightcalc--pricelist-box ' +
            (product.value === true ? 'fcp__is-selected' : 'c-swe--freightcalc--result-wrapper')
          }
        >
          <div className="l-grid--center-s c-swe-freightcalc--pricelist-top">
            <span className="color-DHL-red" id={`${product.code}_name`}>
              {t(`products|${product.code}|name`)}
            </span>
          </div>

          <div className="l-grid--center-s c-freightcalc--pricelist-amount-display">
            <h4
              className="h4 color-DHL-red fcp__freightcalc--margin-bottom-0"
              data-currency={productPrice}
              id={toCamelCase(product.name + 'PriceTag')}
            >
              {product.price && product.price.FreightCost
                ? checkValidPrice(product.price.FreightCost.value) + ' ' + productPrice
                : t('general|errors|No Price Available')}
            </h4>
            <div className="c-swe-freightcalc--margin-bottom-x2">
              <span className="js--freight-price-disclaimer" id="product_price_disclaimer">
                {t('general|Product Price Disclaimer')}
              </span>
              <br />
            </div>
            <div>
              <br />
              {accountNumber && t(`general|For account`) + ': ' + accountNumber}
              <br />
            </div>
          </div>
          <div className="l-grid--center-s l-grid--w-100pc-s c-freightcalc--pricelist-delivery-date">
            <ProductCardDeliveryDate
              productCode={product.code}
              deliveryDate={product.deliveryDate}
              additionalServices={context.state?.additionalServices}
              selectedProductCode={context.state?.selectedProduct?.code}
              selectedPickupDate={selectedPickupDate}
              productPickupDates={productPickupDates}
            />
          </div>
          <div className="l-grid--left-s c-freight-pricelist-details">
            <div className="l-grid--center-s l-grid--w-100pc-s c-freightcalc--pricelist-seedetails">
              &nbsp;{t('general|See more details')}
            </div>
            <div className="c-freightcalc--pricelist-details-display list is-left c-freightcalc--list">
              <div className="c-fcp-product-description">{t(`products|${product.code}|description`)}</div>
              <ul>
                {getDescBulletPoints(product.code, i18n, t).map((bulletPoint, index) => (
                  <li key={index}>
                    <span> {bulletPoint} </span>
                  </li>
                ))}
                {getReadMoreLink(product.code, i18n, t) ? (
                  <li>
                    <span className="c-swe-freightcalc--productinfo-link">
                      <a
                        href={getReadMoreLink(product.code, i18n, t)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="c-swe-freightcalc-price-quote-error link color-DHL-red has-icon link-external"
                      >
                        {t('general|Read More')}...
                      </a>
                    </span>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
          <div
            className={`l-grid--center-s c-freightcalc--pricelist-checkbox 
            ${productDisabled ? 'c-freightcalc--pricelist-checkbox--disabled' : ''}`}
          >
            <Radio
              label={t('general|labels|inputs|Select')}
              id={product.name + 'Select'}
              data-testid={`${product.code}SelectRdBtn`}
              name={product.name}
              radioRef={selectProductRef}
              disabled={productDisabled ? true : false}
              className={'l-grid frc__radio--wrapper frc__service-radio'}
              isRequired={false}
              checked={checked}
              context={context}
              config={config}
              CONST={CONST}
              updateOnParent={params => selectService(params)}
              checkGroupIndex={1}
              beforeUpdate={beforeCheck}
              afterUpdate={(context, params) =>
                afterCheck(routeContext, shipmentContext, context, productDisabledVas, countryConfig)
              }
            />
          </div>
        </div>
      </div>
      <br />
    </>
  );
}

import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import './PortalMarketingBanner.css';
import { useCountryLanguage } from '../../hooks/useCountryLanguage';
import { useSearchParams } from 'react-router-dom';
import { getTranslationPhrasesByPageCode } from 'globals/utils/translationHelpers';
import { GLOBAL_CONST } from 'globals/data-config-global';

const ENV_DEV = process.env.NODE_ENV === 'development';

export default function PortalMarketingBanner() {
  const countryLanguage = useCountryLanguage();
  const [marketingBannerData, setMarketingBannerData] = useState(null);
  const [searchParams] = useSearchParams();
  const [marketingBannerImage, setMarketingBannerImage] = useState(null);

  useEffect(() => {
    const preview = searchParams.get('preview');
    getTranslationPhrasesByPageCode(
      GLOBAL_CONST.PAGE_CODES.LAYOUT,
      countryLanguage.language,
      countryLanguage.country,
      preview,
    ).then(loginPageCmsData => {
      if (loginPageCmsData != null) {
        loginPageCmsData.forEach(data => {
          if (data.code === 'marketing_bottom_portal') {
            setMarketingBannerData(data.translationValue);
            setMarketingBannerImage(data.image);
          }
        });
      } else {
        ENV_DEV && console.error('failed to fetch CMS data');
      }
    });
    // eslint-disable-next-line
  }, [countryLanguage]);

  return (
    <div
      className={marketingBannerImage ? 'marketing-banner marketing-image ' + marketingBannerImage : 'marketing-banner'}
    >
      {marketingBannerData && parse(marketingBannerData)}
    </div>
  );
}

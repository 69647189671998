import { v4 as uuidv4 } from 'uuid';
import { config, CONST } from '../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import { formatDate } from 'globals/utils/formatting';
import { multiplyPrecisely } from 'globals/utils/shipmentAttributesFormula';

export const deleteTemplate = async ids => {
  let error = false;

  await apiRequest(apiLinks.deleteSavedShipment, 'DELETE', {
    body: ids,
  })
    .then(result => {
      if (result.status === CONST.STATUS_NO_CONTENT) {
        error = false;
      } else {
        error = true;
      }
    })
    .catch(() => {
      error = true;
    });

  return error;
};

const afterTemplateListReset = async context => {
  const stateToSet = await fetchTemplateList(context.state);
  context.updateState(stateToSet);
};

export const fetchTemplateOnSearchOrPageChangeOrReset = (context, { pageSize = false, page = false } = false) => {
  const stateToSet = { loader: true };

  if (pageSize || page) {
    stateToSet.manualSearch = false;
    stateToSet.pagination = JSON.parse(JSON.stringify(context.state.pagination));

    if (pageSize) {
      stateToSet.pagination.pageSize = pageSize;
      stateToSet.pagination.page = 1;
    }
    if (page) stateToSet.pagination.page = page;
  } else {
    stateToSet.manualSearch = false;
    stateToSet.pagination = resetPagination();
  }

  context.extendedSetState(stateToSet, { afterUpdate: afterTemplateListReset });
};

export const formRequestBodyForTemplateCall = state => {
  return {
    page: state.pagination.page,
    pageSize: state.pagination.pageSize,
    searchTerm: state.searchTerm.value,
    senderAccountFilter: [],
    sortOrder: 'createdAt',
    statusFilter: [],
    templateTypes: [config.templateType],
  };
};

const templateListBackendCall = async state => {
  const resultMap = {};

  await apiRequest(apiLinks.fetchTemplateList, 'POST', {
    body: formRequestBodyForTemplateCall(state),
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

const setSenderReceiverDetails = templateData => {
  const shipment = { sender: {}, receiver: {} };
  const { savedShipmentParties } = templateData;

  if (templateData.savedShipmentParties) {
    // diff sender address
    if (templateData.sdifferent) {
      shipment.sender = savedShipmentParties.find(party => party.type === 'differentSender');
    } else {
      shipment.sender = savedShipmentParties.find(party => party.type === 'sender');
    }

    // diff receiver address
    if (templateData.rdifferent) {
      shipment.receiver = savedShipmentParties.find(party => party.type === 'differentReceiver');
    } else {
      shipment.receiver = savedShipmentParties.find(party => party.type === 'receiver');
    }
  }

  return [shipment.sender, shipment.receiver];
};

const formatSavedShipmentData = savedShipments => {
  const modifiedSavedShipments = savedShipments.map((shipment, rowTableIndex) => {
    shipment.rowTableIndex = rowTableIndex;

    [shipment.sender, shipment.receiver] = setSenderReceiverDetails(shipment);

    shipment.totalQuantity =
      shipment.savedShipmentPieces && shipment.savedShipmentPieces.length > 0
        ? shipment.savedShipmentPieces.reduce((a, b) => a + (b.quantity * 1 || 0), 0)
        : 0;

    if (shipment.savedShipmentPieces) {
      shipment.totalWeight = shipment.savedShipmentPieces.reduce(
        (acc, curr) => acc + multiplyPrecisely(curr.weight, curr.quantity),
        0,
      );
    } else {
      shipment.totalWeight = 0;
    }

    shipment.savedShipmentPieces = shipment.savedShipmentPieces.map(shipmentPiece => {
      return {
        ...shipmentPiece,
        dangerousGoods: shipmentPiece.dangerousGoods.map(dangerousGood => {
          return {
            ...dangerousGood,
            id: uuidv4(),
          };
        }),
      };
    });

    shipment.formattedDate = formatDate(shipment.createdAt);

    if (typeof shipment.sender === 'undefined') shipment.sender = {};
    if (typeof shipment.receiver === 'undefined') shipment.receiver = {};

    shipment.sender.country = shipment.sender ? shipment.sender.countryCode : '';
    shipment.receiver.country = shipment.receiver ? shipment.receiver.countryCode : '';

    shipment.senderCompanyName = shipment.sender ? shipment.sender.companyName : '';
    shipment.receiverCompanyName = shipment.receiver ? shipment.receiver.companyName : '';

    return shipment;
  });

  return modifiedSavedShipments;
};

export const fetchTemplateList = async state => {
  let calculatePagination;
  let formattedTemplateData;
  const templateResult = await templateListBackendCall(state);

  if (!templateResult.error) {
    // set pagination

    formattedTemplateData = formatSavedShipmentData(templateResult.data.savedShipments);

    if (formattedTemplateData && formattedTemplateData.length > 0)
      formattedTemplateData = formattedTemplateData.sort((a, b) => b.createdAt - a.createdAt);

    calculatePagination = {
      page: templateResult.data.page,
      total: templateResult.data.total,
      lastPage: templateResult.data.lastPage,
    };

    calculatePagination.pageSize = state.pagination.pageSize;

    return {
      pagination: calculatePagination,
      templateList: formattedTemplateData,
      templateListMaster: formattedTemplateData,
      apiError: false,
      loader: false,
      manualSearch: false,
      remoteSearchTerm: state.searchTerm.value,
    };
  } else {
    return {
      apiError: true,
      loader: false,
      pagination: resetPagination(),
      templateList: [],
      templateListMaster: [],
      manualSearch: false,
      remoteSearchTerm: state.searchTerm.value,
    };
  }
};

export const resetPagination = () => {
  return {
    page: 1,
    total: 0,
    pageSize: config.paginationInitialNumberOfItems,
    lastPage: 0,
  };
};

export const productAPICall = async state => {
  const resultMap = {};

  if (state.products.length === 0) {
    const url = apiLinks.getFCPAPIProducts;

    const params = {
      headers: {
        Accept: 'application/json',
      },
    };

    await apiRequest(url, 'GET', params)
      .then(result => {
        if (result.status === CONST.STATUS_OK) {
          resultMap.products = result.data;
          resultMap.deliveryTypesForParcelConnect = getDeliveryTypes(result.data);
          resultMap.internationalShippingCountries = getInternationalCountries(result.data);
        } else {
          resultMap.apiError = true;
        }
      })
      .catch(() => {
        resultMap.apiError = true;
      });
  }

  return resultMap;
};

export const getInternationalCountries = products => {
  const internationalShippingCountries = [];

  products.forEach(product => {
    if (!product.isDomestic && product.toCountries) {
      product.toCountries.forEach(toCountry => {
        if (toCountry.country && toCountry.country.customs) {
          internationalShippingCountries.push(toCountry.country);
        }
      });
    }
  });

  return internationalShippingCountries;
};

export const getDeliveryTypes = productData => {
  const parcelConnect = productData.find(product => product.code === '109');
  const deliveryTypeBasedOnCountry = {};

  // TODO -> how will it be for global products?

  parcelConnect?.rulesForCountryAndDeliveryTypes.forEach(deliveryTypeVal => {
    if (deliveryTypeBasedOnCountry.hasOwnProperty([deliveryTypeVal.country.countryCode])) {
      const existingDeliveryTypes = deliveryTypeBasedOnCountry[deliveryTypeVal.country.countryCode];
      existingDeliveryTypes.push(deliveryTypeVal.deliveryType);
      deliveryTypeBasedOnCountry[deliveryTypeVal.country.countryCode] = existingDeliveryTypes;
    } else {
      deliveryTypeBasedOnCountry[deliveryTypeVal.country.countryCode] = Array.isArray(deliveryTypeVal.deliveryType)
        ? deliveryTypeVal.deliveryType
        : [deliveryTypeVal.deliveryType];
    }
  });

  return deliveryTypeBasedOnCountry;
};

export const fetchProductsUserAccountTemplateList = async ctxState => {
  let stateToSet = {};

  const [accountsResultObj, fetchTemplateObj, productsObj] = await Promise.all([
    getUsersAccounts(),
    fetchTemplateList(ctxState),
    productAPICall(ctxState),
  ]);

  if (accountsResultObj.apiError || fetchTemplateObj.apiError || productsObj.apiError) {
    stateToSet.apiError = true;
  } else {
    stateToSet = fetchTemplateObj;
    stateToSet.accounts = accountsResultObj.accounts;
    stateToSet.userData = accountsResultObj.userData;
    Object.assign(stateToSet, productsObj);
  }

  return stateToSet;
};

export const getUsersAccounts = async () => {
  const url = apiLinks.getUserData;
  const stateObject = {};

  await apiRequest(url, 'GET').then(result => {
    if (result.status === CONST.STATUS_OK) {
      stateObject.accounts = prepareAPIDataForAccountSelect(result);
      stateObject.userData = result.data.user;
    } else {
      stateObject.apiError = true;
    }
  });

  return stateObject;
};

export const prepareAPIDataForAccountSelect = result => {
  const accounts = [];

  if (result.data.tmsAccounts && result.data.tmsAccounts.length > 0) {
    result.data.tmsAccounts.forEach(account => {
      accounts.push({
        name: `${account.accountNumber} (${account.accountReference})`,
        code: account.accountNumber,
        products: account.products,
      });
    });
  }

  return accounts;
};

export const returnPostalCodeState = () => {
  return {
    apiError: false,
    sourcePostalCodeError: false,
    destinationPostalCodeError: false,
    sourceCityError: false,
    destinationCityError: false,
  };
};

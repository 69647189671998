import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'router';
import { isValidCountryLanguage } from '../../utils/urls';

export function ErrorPageRedirections() {
  const { lang } = useParams();
  const { t } = useTranslation();

  if (!isValidCountryLanguage(lang)) {
    return null;
  }

  return (
    <div className="component-margin c-icon-list l-grid l-grid l-grid--center-m">
      <h3 className="component-small l-grid--w-100pc-s">{t('errorPage|Maybe You Were Looking for One of These?')}</h3>
      <div className="c-icon-list--container l-grid l-grid--w-100pc-s l-grid--center-s l-grid--center-m">
        <Link
          to={'/' + lang}
          className="c-icon-list--item"
          title={t('errorPage|Home')}
          data-tracking='{"component":"fcp/components/elements/iconlist-element"}'
        >
          <div className="c-icon-list--icon">
            <img src="/assets/img/glo-icon-green.svg" alt="" />
          </div>
          <span className="c-icon-list--icon-headline ">{t('errorPage|Home')}</span>
        </Link>
        <Link
          to={'https://www.logistics.dhl/ ' + lang + '/' + ROUTES.tracking}
          className="c-icon-list--item"
          target="_blank"
          title={t('errorPage|Tracking')}
          data-tracking='{"component":"fcp/components/elements/iconlist-element"}'
          rel="noopener noreferrer"
        >
          <div className="c-icon-list--icon">
            <img src="/assets/img/glo-core-pictogr.svg" alt="" />
          </div>
          <span className="c-icon-list--icon-headline has-icon link-external">{t('errorPage|Tracking')}</span>
        </Link>

        <Link
          to={'/' + lang + '/' + ROUTES.quoteTool}
          className="c-icon-list--item"
          title={t('errorPage|Quote')}
          data-tracking='{"component":"fcp/components/elements/iconlist-element"}'
        >
          <div className="c-icon-list--icon">
            <img src="/assets/img/glo-icon-general-search.svg" alt="" />
          </div>
          <span className="c-icon-list--icon-headline ">{t('errorPage|Quote')}</span>
        </Link>
      </div>
    </div>
  );
}

import { Error as FcpError, Radio } from 'components';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { CONST, config } from '../../../../data-config';
import { updateFilterParamters } from '../FilterShipments-helpers';
import DateWidget from './DateWidget/DateWidget';

import '../FilterShipments.css';

function FilterDate(props) {
  const { t } = useTranslation();
  const { state } = props.context;

  const displayShipmentDatesRadioButton = () => {
    return config.shipmentDate.map(dateFilter => {
      return (
        <div key={dateFilter.label} className="margin-bottom-1">
          <Radio
            label={t(`${CONST.API_DOMAIN}|${dateFilter.label}`)}
            isBooleanValueRequired={true}
            id={dateFilter.value}
            name={'filterByShipmentDates'}
            checked={props.context.state.filterByShipmentDates.value === dateFilter.value}
            value={dateFilter.value}
            context={props.context}
            config={config}
            CONST={CONST}
            updateOnParent={(params, context) => updateFilterParamters(params, context, 'filterByShipmentDates')}
          />
        </div>
      );
    });
  };

  return (
    <div className="margin-bottom-2">
      <h4 className="margin-bottom-1 frc__filter_title">{t(`general|date`)}</h4>
      <div className="l-grid margin-bottom-1">
        <div className=" l-grid--w-100pc-m-s l-grid--w-80pc-m-m frc_from-date-margin">
          <DateWidget
            label={t('general|dateTimeFrom')}
            name="filterShipmentFromDate"
            compareTo="ToDate"
            context={props.context}
            filterDate={state.filterShipmentFromDate}
          />
          {state.filterShipmentFromDate.error && (
            <FcpError
              name={'filterShipmentFromDateError'}
              className="frc__input--error"
              message={t(`portalSavedShipments|From Date cannot be empty`)}
            />
          )}
        </div>

        <div className=" l-grid--w-100pc-m-s l-grid--w-80pc-m-m">
          <DateWidget
            label={t('general|dateTimeTo')}
            name="filterShipmentToDate"
            compareTo="FromDate"
            context={props.context}
            filterDate={state.filterShipmentToDate}
          />

          {state.filterShipmentToDate.error && (
            <FcpError
              name={'filterShipmentToDateError'}
              className="frc__input--error"
              message={t(`portalSavedShipments|To Date cannot be empty`)}
            />
          )}
        </div>
      </div>

      {displayShipmentDatesRadioButton()}
    </div>
  );
}

const areFunctionPropsEqual = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.context.state.filterByShipmentDates) ===
      JSON.stringify(nextProps.context.state.filterByShipmentDates) &&
    JSON.stringify(prevProps.context.state.filterShipmentFromDate) ===
      JSON.stringify(nextProps.context.state.filterShipmentFromDate) &&
    JSON.stringify(prevProps.context.state.filterShipmentToDate) ===
      JSON.stringify(nextProps.context.state.filterShipmentToDate)
  );
};

export default memo(FilterDate, areFunctionPropsEqual);

import React, { Component } from 'react';
import { config } from '../../../../data-config';
import { Translation } from 'react-i18next';
import { Button } from 'components';
import OptionInput from './OptionInput';
import OptionDateTime from './OptionDateTime';
import OptionPhoneNumber from './OptionPhoneNumber';
import OptionCheckbox from './OptionCheckbox';
import OptionSelect from './OptionSelect';
import OptionLTCDate from './OptionLTCDate';
import ConditionalOptionGroupError from './ConditionalOptionGroupError';
import { OptionSubHeading } from '../../../../../components/fcp-components/OptionSubHeading';

import './AdditionalServicesOptions.css';

export default class AdditionalServicesOptions extends Component {
  afterUpdate = (params, context) => {
    // Quote For Price if Collection at Terminal
    if (params.name === config.OptionFieldTypes.ListCOT) {
      // Quote For Price
      this.props.getQuoteForPriceWithAd();
    }
  };
  render() {
    return (
      <Translation>
        {t => (
          <div
            onBlur={() => {
              /**
               * !FIXME
               * TODO: jm - refactor and avoid using timeout
               * https://jira.dhl.com/browse/FCPG-1208
               */
              setTimeout(() => {
                this.props.getQuoteForPriceWithAd();
              }, 100);
            }}
          >
            {this.props.groups.map((group, groupIndex) => (
              <React.Fragment key={groupIndex}>
                {group.options.map((optionList, optionParentIndex) => (
                  <div
                    key={optionParentIndex}
                    className="frc__as-options--wrapper l-grid l-grid--w-100pc-s l-grid--w-90pc-m frc__options-schema-bg-color"
                  >
                    {group.maxRepeat > 1 && group.options.length > 1 && (
                      <Button
                        id={'deleteOptionsSchemaRow' + optionParentIndex}
                        submit={() => this.deleteOptionsSchema(groupIndex, optionParentIndex)}
                        className="frc__button--delete"
                        label="X"
                      />
                    )}
                    {optionList.map((option, optionIndex) => (
                      <React.Fragment key={optionIndex}>
                        {config.OptionFieldTypes.inputTypes[option.type] ? (
                          <OptionInput
                            deliveryMontoringClass={this.props.deliveryMontoringClass}
                            option={option}
                            inputType={option.inputType}
                            context={this.props.context}
                            config={this.props.config}
                            CONST={this.props.CONST}
                            code={this.props.code}
                            groupIndex={groupIndex}
                            optionParentIndex={optionParentIndex}
                            index={optionIndex}
                            serviceIndex={this.props.serviceIndex}
                            errorId={this.props.code + option.optionCode + 'Opt'}
                          />
                        ) : (
                          ''
                        )}

                        {option.type === config.OptionFieldTypes.SubHeading ? (
                          <OptionSubHeading
                            option={option}
                            code={this.props.code}
                            groupIndex={groupIndex}
                            optionParentIndex={optionParentIndex}
                            index={optionIndex}
                          />
                        ) : (
                          ''
                        )}

                        {option.type === config.OptionFieldTypes.DateTime ? (
                          <OptionDateTime
                            option={option}
                            deliveryMontoringClass={this.props.deliveryMontoringClass}
                            context={this.props.context}
                            config={this.props.config}
                            CONST={this.props.CONST}
                            code={this.props.code}
                            groupIndex={groupIndex}
                            optionParentIndex={optionParentIndex}
                            index={optionIndex}
                            serviceIndex={this.props.serviceIndex}
                            errorId={this.props.code + option.optionCode + 'Opt'}
                          />
                        ) : (
                          ''
                        )}

                        {option.type === config.OptionFieldTypes.Telephone ? (
                          <OptionPhoneNumber
                            option={option}
                            context={this.props.context}
                            config={this.props.config}
                            CONST={this.props.CONST}
                            code={this.props.code}
                            groupIndex={groupIndex}
                            optionParentIndex={optionParentIndex}
                            index={optionIndex}
                            serviceIndex={this.props.serviceIndex}
                            errorId={this.props.code + option.optionCode + 'Opt'}
                          />
                        ) : (
                          ''
                        )}

                        {option.type === config.OptionFieldTypes.TickBox ? (
                          <OptionCheckbox
                            option={option}
                            context={this.props.context}
                            config={this.props.config}
                            CONST={this.props.CONST}
                            code={this.props.code}
                            groupIndex={groupIndex}
                            optionParentIndex={optionParentIndex}
                            index={optionIndex}
                            serviceIndex={this.props.serviceIndex}
                            errorId={this.props.code + option.optionCode + 'Opt'}
                          />
                        ) : (
                          ''
                        )}

                        {option.type === config.OptionFieldTypes.Select ? (
                          <OptionSelect
                            option={option}
                            context={this.props.context}
                            config={this.props.config}
                            CONST={this.props.CONST}
                            code={this.props.code}
                            groupIndex={groupIndex}
                            optionParentIndex={optionParentIndex}
                            index={optionIndex}
                            serviceIndex={this.props.serviceIndex}
                            errorId={this.props.code + option.optionCode + 'Opt'}
                            afterUpdate={(params, ctx) => this.afterUpdate(params, ctx)}
                          />
                        ) : (
                          ''
                        )}

                        {option.type === config.OptionFieldTypes.Currency ? (
                          //   when there are more values to currency => select box
                          option.values ? (
                            <OptionSelect
                              option={option}
                              context={this.props.context}
                              config={this.props.config}
                              CONST={this.props.CONST}
                              code={this.props.code}
                              groupIndex={groupIndex}
                              optionParentIndex={optionParentIndex}
                              index={optionIndex}
                              serviceIndex={this.props.serviceIndex}
                              errorId={this.props.code + option.optionCode + 'Opt'}
                            />
                          ) : (
                            //   show currency in input only otherwise
                            <OptionInput
                              deliveryMontoringClass={this.props.deliveryMontoringClass}
                              option={option}
                              inputType={option.inputType}
                              context={this.props.context}
                              config={this.props.config}
                              CONST={this.props.CONST}
                              code={this.props.code}
                              groupIndex={groupIndex}
                              optionParentIndex={optionParentIndex}
                              index={optionIndex}
                              serviceIndex={this.props.serviceIndex}
                              errorId={this.props.code + option.optionCode + 'Opt'}
                            />
                          )
                        ) : (
                          ''
                        )}

                        {option.type === config.OptionFieldTypes.LtcDate ? (
                          <OptionLTCDate
                            option={option}
                            context={this.props.context}
                            config={this.props.config}
                            CONST={this.props.CONST}
                            code={this.props.code}
                            groupIndex={groupIndex}
                            optionParentIndex={optionParentIndex}
                            index={optionIndex}
                            serviceIndex={this.props.serviceIndex}
                            errorId={this.props.code + option.optionCode + 'Opt'}
                          />
                        ) : (
                          ''
                        )}

                        <ConditionalOptionGroupError
                          context={this.props.context}
                          config={this.props.config}
                          CONST={this.props.CONST}
                          option={option}
                          errors={this.props.errors}
                          code={this.props.code}
                        />
                      </React.Fragment>
                    ))}

                    {group.maxRepeat && group.maxRepeat > 1 && (
                      <button
                        className={`base-button ${group.maxRepeat >= group.options.length ? '' : 'disabled'}`}
                        onClick={() =>
                          group.maxRepeat >= group.options.length
                            ? this.addNewOptionsSchemaRow(groupIndex, group.maxRepeat)
                            : null
                        }
                        id={`addNewOptionsSchemaRow${groupIndex}`}
                      >
                        <span>{t(`general|Add New Row`)}</span>
                      </button>
                    )}
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        )}
      </Translation>
    );
  }
}

import { fetchSavedShipmentsList, resetPagination } from '../../ActionUtils';

export const updateFilterParamters = (params, context, paramGroupName) => {
  const stateToSet = {};
  const filterShipmentObject = JSON.parse(JSON.stringify(context.state[paramGroupName]));

  if (params.index !== undefined) filterShipmentObject[params.index].value = params.value;
  else filterShipmentObject.value = params.value;

  if (paramGroupName === 'filterByShipmentDates') {
    stateToSet.filterShipmentFromDate = JSON.parse(JSON.stringify(context.state.filterShipmentFromDate));
    stateToSet.filterShipmentToDate = { value: new Date().setHours(23, 59, 59, 999), error: false };

    if (params.value === 'last14Days')
      stateToSet.filterShipmentFromDate.value = new Date().setDate(new Date().getDate() - 14);
    else if (params.value === 'last30Days')
      stateToSet.filterShipmentFromDate.value = new Date().setDate(new Date().getDate() - 30);

    stateToSet.filterShipmentFromDate.value = new Date(stateToSet.filterShipmentFromDate.value).setHours(0, 0, 0, 0);
  }

  stateToSet[paramGroupName] = filterShipmentObject;
  stateToSet.loader = true;
  stateToSet.pagination = resetPagination();

  context.extendedSetState(stateToSet, { afterUpdate: fetchSavedShipmentsList });
};

import { Navigate } from 'react-router-dom';
import React from 'react';
import i18next from 'i18next';
import AuthService from 'globals/utils/auth';
import { useAuth } from '../hooks/useAuth';

const ENV_DEV = process.env.NODE_ENV === 'development';

export function HomePage() {
  const isRefreshTokenExpired = AuthService.isRefreshTokenExpired();
  const { authenticate } = useAuth();
  if (!isRefreshTokenExpired) {
    ENV_DEV && console.log('Home Page: Redirecting to language spec site lang:', i18next.language);
    authenticate(true);
    return <Navigate to={`/${i18next.language}/dashboard`} />;
  } else {
    ENV_DEV && console.log('Home Page: Refresh token expired -> redirecting to login');
    return <Navigate to={`/${i18next.language}/login`} replace={true} />;
  }
}

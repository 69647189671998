import React from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '../../data-config';

import './SideSummaryPanel.css';
import { RECEIVER_PAYER, SENDER_PAYER, THIRD_PARTY_PAYER } from '../../../globals/constants';
import { globalConfig } from '../../../globals/data-config-global';
import {
  fromDateComponent,
  sidePanelDatePresenter,
  toDateComponent,
} from '../../../globals/helpers/side-summary-helpers';

export default function SideSummaryPanel({ context }) {
  let vasList = [];
  const { t } = useTranslation();
  const pickupLocationEntered =
    context.state.pickupSender.value ||
    context.state.pickupStreet.value ||
    context.state.pickupPostalCode.value ||
    context.state.pickupCity.value;

  const deliveryLocationEntered =
    context.state.deliveryReceiver.value ||
    context.state.deliveryStreet.value ||
    context.state.deliveryPostalCode.value ||
    context.state.deliveryCity.value;

  const totals = context.state.totals.shipmentDetailsRows;
  let payerAccountNumber = '';

  switch (context.state.payer.value) {
    case SENDER_PAYER:
      payerAccountNumber = context.state.accountNumber.value;
      break;
    case RECEIVER_PAYER:
      payerAccountNumber = context.state.receiverNumber.value;
      break;
    case THIRD_PARTY_PAYER:
      payerAccountNumber = context.state.thirdPartyNumber.value;
      break;
    default:
      payerAccountNumber = t('general|No account number selected');
  }

  if (payerAccountNumber.length === 0) {
    payerAccountNumber = t('general|No account number selected');
  }

  context.state.additionalServices.forEach(additionalService => {
    if (additionalService.value) {
      vasList.push({ code: additionalService.code, value: additionalService.translatedName });
    }
  });

  return (
    <div className="frc__side-summary-panel--wrapper" translate="no">
      <div className="frc__side-summary-panel">
        <h3>{t('portalPickup|Pickup Summary')}</h3>

        <div className="frc__generic-row--wrapper">
          <h4 className="margin-bottom-0">{t('general|Payment Account')}</h4>
          <p className="frc__pickup-location">{payerAccountNumber}</p>
        </div>

        <div className="frc__generic-row--wrapper">
          <h4 className="margin-bottom-0">{t('general|Pickup from')}</h4>
          <p className="frc__pickup-location">{context.state.pickupSender.value || ''}</p>
          <p className="frc__pickup-location">{context.state.pickupStreet.value || ''}</p>
          <p className="frc__pickup-location">
            {context.state.pickupPostalCode.value || ''} {context.state.pickupCity.value || ''}
          </p>
          {pickupLocationEntered ? (
            <p className="frc__pickup-location">
              {config.domesticCountriesPortalPickup.map(country =>
                country.value === context.state.pickupCountry.value ? t(`countries|${country.name}`) : '',
              )}
            </p>
          ) : (
            t('general|Sender Details not entered')
          )}
        </div>

        <div className="frc__generic-row--wrapper">
          <h4 className="margin-bottom-0">{t(`general|Delivery to`)}</h4>
          <p className="frc__pickup-location">{context.state.deliveryReceiver.value || ''}</p>
          <p className="frc__pickup-location">{context.state.deliveryStreet.value || ''}</p>
          <p className="frc__pickup-location">
            {context.state.deliveryPostalCode.value || ''} {context.state.deliveryCity.value || ''}
          </p>
          {deliveryLocationEntered ? (
            <p className="frc__pickup-location">
              {context.state.toCountries.length > 0
                ? context.state.toCountries.map(
                    toCountry =>
                      context.state.deliveryCountry.value === toCountry.value && t(`countries|${toCountry.name}`),
                  )
                : config.domesticCountriesPortalPickup.map(country =>
                    country.value === context.state.pickupCountry.value ? t(`countries|${country.name}`) : '',
                  )}
            </p>
          ) : (
            t('general|Receiver Details not entered')
          )}
        </div>

        <div className="frc__generic-row--wrapper">
          <h4 className="margin-bottom-0">{t('general|Shipment Details')}</h4>

          {context.state.shipmentDetailsRows[0] &&
          context.state.shipmentDetailsRows[0].weight.value === '' &&
          context.state.shipmentDetailsRows.length === 1 ? (
            <em className="frc__shipment-details">{t('general|Select Shipment Details')}</em>
          ) : (
            <div>
              <div className={'frc__shipment-summary--row l-grid--left-s'}>
                <div>{t('general|labels|metrics|Total Quantity')}: </div>
                <div>{totals.quantity.value}</div>
              </div>
              <div className={'frc__shipment-summary--row l-grid--left-s'}>
                <div>{t('general|labels|metrics|Total Volume')}: </div>
                <div>
                  {totals.volume.value} {t('general|m')}
                  <sup>3</sup>
                </div>
              </div>
              <div className={'frc__shipment-summary--row l-grid--left-s'}>
                <div>{t('general|labels|metrics|Total Weight')}: </div>
                <div>
                  {totals.weight.value} {t('general|kg')}
                </div>
              </div>
              <div className={'frc__shipment-summary--row l-grid--left-s'}>
                <div>{t('general|labels|metrics|Total Loading Meter')}: </div>
                <div>{totals.loadingMeter.value}</div>
              </div>
            </div>
          )}
        </div>

        <div className="frc__generic-row--wrapper">
          <h4 className="margin-bottom-0">{t('general|Pickup Options')}</h4>
          <div>{fromDateComponent(context.state, sidePanelDatePresenter)}</div>
        </div>

        <div className="frc__generic-row--wrapper">
          <h4 className="margin-bottom-0">{t(`general|Delivery Options`)}</h4>
          <div>
            <em>
              <>
                {vasList &&
                  vasList
                    .filter(j => !globalConfig.VASfixedDateOption.includes(j.code))
                    .map((item, index) => {
                      return <div key={index}>{item.value}</div>;
                    })}
                {toDateComponent(context.state, sidePanelDatePresenter)}
              </>
            </em>
          </div>
        </div>
      </div>
    </div>
  );
}

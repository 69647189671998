import React from 'react';
import { Translation } from 'react-i18next';
import { Input } from 'components';
import { Error } from 'components';
import { config, CONST } from '../../data-config';
import { confirmationEmail } from '../ReviewAndBook/FormSubmitData/Party-helpers';

export default function BookingConfirmation({ context }) {
  const confirmationEmailFlag = confirmationEmail(context.state);
  const confirmationEmailObj = confirmationEmailFlag ? context.state.deliveryEmail : context.state.pickupEmail;

  return (
    <>
      <div className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <>
              <h4 className="margin-bottom-0">{t(`general|Booking Confirmation`)}</h4>

              <div className="l-grid--w-100pc-s l-grid--w-30pc-m l-grid--w-50pc-m-s frc__generic-row--wrapper ">
                <Input
                  label={t('general|Send email confirmation to') + ' :'}
                  name={confirmationEmailFlag ? 'deliveryEmail' : 'pickupEmail'}
                  dataTestId={confirmationEmailFlag ? 'deliveryEmailInp' : 'pickupEmailInp'}
                  isRequired={true}
                  value={confirmationEmailObj.value}
                  lengthCheck={[RegExp(`^.{1,${config.maxEmailLength}}$`)]}
                  cutTextLimit={config.maxEmailLength}
                  context={context}
                  config={config}
                  CONST={CONST}
                />
                {confirmationEmailObj.error ? (
                  <Error
                    name={confirmationEmailFlag ? 'deliveryEmail' : 'pickupEmail'}
                    className="frc__input--error"
                    message={t('general|errors|E-mail Error', {
                      chars: config.maxEmailLength,
                    })}
                  />
                ) : (
                  ''
                )}
              </div>
            </>
          )}
        </Translation>
      </div>
    </>
  );
}

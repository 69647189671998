import React from 'react';
import { MyContext } from '../../context/context';
import { Error } from 'components';
import { useTranslation } from 'react-i18next';

export default function GeneralError() {
  const { t } = useTranslation();
  return (
    <MyContext.Consumer>
      {context => <>{context.state.generalError ? <Error message={t('general|errors|General error')} /> : ''}</>}
    </MyContext.Consumer>
  );
}

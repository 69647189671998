import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'components';
import { Error } from 'components';

const OptionSelect = ({
  option,
  code,
  errorId,
  context,
  index,
  groupIndex,
  optionParentIndex,
  serviceIndex,
  config,
  CONST,
  disableonBlur,
  afterUpdate,
}) => {
  const { t } = useTranslation();
  const [selectOptions, setSelectOptions] = useState([
    {
      value: '',
      name: t('general|select'),
      skipTranslation: true,
    },
  ]);

  useEffect(() => {
    // option.values = option.value;
    if (option.values && option.values.length > 0) {
      if (option.defaultValueAvailable) {
        setSelectOptions(option.values);
      } else {
        let options = JSON.parse(JSON.stringify(selectOptions));

        option.values.split('|').forEach(opt => {
          const o = { value: '', name: opt, skipTranslation: false };

          if (o.name.indexOf('*') === -1) {
            o.skipTranslation = true;
          } else {
            o.name = o.name.replace('*', '');
          }
          o.value = o.name;
          options.push(o);
        });
        setSelectOptions(options);
      }
    } else {
      setSelectOptions([
        {
          value: '',
          name: t('general|Options not Available'),
          skipTranslation: true,
        },
      ]);
    }
    // eslint-disable-next-line
  }, []);

  const update = (params, ctx) => {
    // setSelected(prevSelected => !prevSelected);
    let stateObject = {};
    let additionalService = {};
    let error = params.value === '' && params.isRequired;

    stateObject = ctx.state.additionalServices;
    additionalService = JSON.parse(JSON.stringify(stateObject[serviceIndex]));

    const additionalServiceParentOption = additionalService.groups[groupIndex].options[optionParentIndex];
    const additionalServiceOption = additionalServiceParentOption[index];

    if (error) {
      additionalServiceOption.errorMessage = additionalService.code + additionalServiceOption.optionCode + 'Error';
    } else {
      additionalServiceOption.errorMessage = '';
    }

    additionalServiceOption.value = params.value;
    additionalServiceOption.error = error;
    stateObject[serviceIndex] = additionalService;
    ctx.updateState({ additionalServices: stateObject }, () => {
      params.afterUpdate && typeof params.afterUpdate === 'function' && params.afterUpdate(params, ctx);
    });
  };

  return (
    <>
      <div className="l-grid--w-100pc-s frc__as-option--wrapper">
        <Select
          id={code + option.optionCode}
          className="l-grid--w-100pc-s"
          label={t(`additionalServices|` + option.optionCode)}
          context={context}
          config={config}
          CONST={CONST}
          name={option.optionCode}
          isRequired={option.mandatory}
          values={selectOptions}
          value={option.value || ''}
          disabled={option.disabled || selectOptions.length === 1 || false}
          updateOnParent={(params, ctx) => update(params, ctx)}
          afterUpdate={afterUpdate}
          disableonBlur={disableonBlur}
        />

        {option.error ? (
          <Error
            name={errorId ? errorId : 'OptionSelect'}
            className="frc__input--error"
            message={t('general|errors|Select Error Option')}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default OptionSelect;

import { getPropperAPIData } from 'globals/utils/requests';
import {
  CONSIGNEE_CUSTOMER_TYPE,
  CONSIGNOR_CUSTOMER_TYPE,
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
} from '../../../globals/constants';

export const formAccountNumber = state => {
  if (state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE && state.shipmentPayer.value === RECEIVER_PAYER) {
    // special case: both sender/receiver can be dropdown.
    return state.accountNumber.isSelect ? state.accountNumber.value : state.receiverNumber.value;
  } else {
    return state.accountNumber.isRequired && state.accountNumber.display
      ? state.accountNumber.value
      : state.receiverNumber.isRequired && state.receiverNumber.display
      ? state.receiverNumber.value
      : state.thirdPartyNumber.isRequired && state.thirdPartyNumber.display
      ? state.thirdPartyNumber.value
      : '';
  }
};

export const addAccountNumberToParties = (state, parties) => {
  const shipmentTypesImpOrExp = state.shipmentTypesImpOrExp.value;
  const accountNumber = formAccountNumber(state);
  const newParties = JSON.parse(JSON.stringify(parties));

  if (accountNumber) {
    if (state.payerCode === '3') {
      newParties[1].id = accountNumber;
    } else if (
      // for domestic and international export
      (state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE && shipmentTypesImpOrExp === EXPORT_SHIPMENT_TYPE) ||
      state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE
    ) {
      newParties[0].id = accountNumber;
    } else if (shipmentTypesImpOrExp === IMPORT_SHIPMENT_TYPE) {
      newParties[1].id = accountNumber;
    }
  }

  return newParties;
};

export const formPartyData = (state, config) => {
  const parties = [];
  const propperRouteState = getPropperAPIData(state, state.selectedServicePoint && state.selectedServicePoint.cityName);

  parties.push(formData(propperRouteState, 'pickup', CONSIGNOR_CUSTOMER_TYPE, config));
  parties.push(formData(propperRouteState, 'delivery', CONSIGNEE_CUSTOMER_TYPE, config));

  return addAccountNumberToParties(state, parties);
};

const formData = (state, type, customerType, config) => {
  const partyObj = {};

  partyObj.address = {
    countryCode: state[type + 'Country'].value || null,
    postalCode: state[type + 'PostalCode'].value || null,
  };

  partyObj.type = customerType;

  // CR81 FCP-2696
  return customerType === CONSIGNEE_CUSTOMER_TYPE
    ? postalCodeReplacementIfCollectionAtTerminalSelected(state, partyObj, config)
    : partyObj;
};

const postalCodeReplacementIfCollectionAtTerminalSelected = (state, partyObject, config) => {
  const partyObj = JSON.parse(JSON.stringify(partyObject));
  const terminalPostalCode = cherryPickTerminalPostalCode(state, config, partyObj.address.postalCode);

  partyObj.address.postalCode = terminalPostalCode;

  return partyObj;
};

export const cherryPickTerminalPostalCode = (state, config, partyPostalCode) => {
  if (
    state.additionalServices &&
    config &&
    config.OptionFieldTypes &&
    Array.isArray(state.additionalServices) &&
    state.additionalServices.length > 0
  ) {
    const CaT = state.additionalServices.find(vas => vas.code === config.OptionFieldTypes.collectionAtTerminal);

    if (CaT && CaT.value) {
      const option = CaT.groups[0].options[0][0];

      if (option.optionCode === config.OptionFieldTypes.ListCOT) {
        const terminal = option.values.find(term => term.value === option.value);

        return terminal.terminal.address.postalCode;
      }
    }
  }

  return partyPostalCode;
};

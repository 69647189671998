import { apiLinks } from 'config/api-config';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { CONST } from '../../data-config';

import { Error } from 'components';
import { analytics } from 'globals/utils/analytics';
import { apiRequest } from 'globals/utils/requests';
import { withRouter } from 'router';
import { MyContext } from '../../context/context';
import {
  checkPickupDetails,
  postalCodeValidation,
  prepareUserData,
} from '../EnterPickupLocation/EnterPickupLocation-helpers';
import { caseInsensitiveEquals } from '../../../globals/utils/strings';

const ENV_DEV = process.env.NODE_ENV === 'development';
class TermsAndSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingSaveDetails: false,
      apiError: false,
      submitError: false,
    };
  }

  scrollToTheCityAndZipCodeSection = () => {
    const destination = document.querySelector('#pp-form-city-and-postalcode');
    const documentHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight,
    );
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName('body')[0].clientHeight;
    const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
    const destinationOffsetToScroll = Math.round(
      documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset,
    );
    window.scroll(0, destinationOffsetToScroll);
  };

  proceedToSubmit = context => {
    const params = {
      body: prepareUserData(context.state),
      headers: {
        Referer: 'se-en',
      },
    };

    apiRequest(apiLinks.postOrderPickup, 'POST', params)
      .then(result => {
        if (result.status === CONST.STATUS_OK) {
          ENV_DEV && console.success('API Request successful ...');

          analytics('order', CONST.ANALYTICS);

          if (result.data.status === 0 || result.data.status === 2) {
            if (result.data.status === 2) {
              context.updateState({
                pickupOrderSubmited: true,
                pickupOrderResult: result.data,
                pickupOrderMovedToNextDay: true,
              });
              analytics('Thank You Accepted', CONST.ANALYTICS);
            } else {
              context.updateState({
                pickupOrderSubmited: true,
                pickupOrderResult: result.data,
              });
              analytics('Thank You Accepted', CONST.ANALYTICS);
            }
          } else if (result.data.status === 1) {
            context.updateState({
              redThankYouPage: true,
              redThankYouErrorMessage: result.data.message,
            });
            analytics('Thank You Rejected', CONST.ANALYTICS);
          } else if (result.data.status === 3) {
            context.updateState({
              redThankYouPage: true,
              redThankYouErrorMessage: result.data.message,
              responseStatus: 3,
            });
            analytics('Thank You Rejected', CONST.ANALYTICS);
          }
        } else if (result.status === CONST.ADDRESS_ERROR_CODE) {
          this.setState({ isLoadingSaveDetails: false });

          context.updateState(
            {
              cityOrZipCodeError: true,
            },
            () => {
              this.setState({ submitError: true });
            },
          );
          this.scrollToTheCityAndZipCodeSection();
        } else {
          ENV_DEV && console.alert('API Request failed ...');
          this.setState({
            apiError: true,
            isLoadingSaveDetails: false,
          });
        }
        // this.setState({ isLoadingSaveDetails: false });
      })
      .catch(err => {
        this.setState({ isLoadingSaveDetails: false, apiError: true });
      });
  };

  checkAndSubmit = async context => {
    let { hasErrors, stateToSet } = checkPickupDetails(context);
    let newState = JSON.parse(JSON.stringify(this.state));

    stateToSet.sourcePostalCodeError = false;
    stateToSet.cityOrZipCodeError = false;
    newState.apiError = false;
    newState.submitError = false;
    newState.isLoadingSaveDetails = true;

    if (caseInsensitiveEquals(context.state.country.value, 'SE')) {
      let result = {};

      this.setState(newState, async () => {
        if (!hasErrors) {
          result = await postalCodeValidation(context.state.country.value, context.state.postalCode.value);

          if (result.hasOwnProperty('error') && result.error) {
            hasErrors = true;
            newState.apiError = true;
          } else if (!result.sourceBookable) {
            hasErrors = true;
            newState.submitError = true;
            stateToSet.sourcePostalCodeError = true;
            this.scrollToTheCityAndZipCodeSection();
          }
        } else {
          newState.submitError = true;
        }

        context.updateState(stateToSet, () => {
          if (!hasErrors) {
            this.proceedToSubmit(context);
          } else {
            newState.isLoadingSaveDetails = false;
          }
          this.setState(newState);
        });
      });
    }
  };

  render() {
    return (
      <div className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <MyContext.Consumer>
              {context => (
                <>
                  <div className="l-grid l-grid--right-s l-grid--bottom-s">
                    <div className="l-grid--w-100pc-s l-grid--w-40pc-s-l l-grid--w-40pc-m">
                      <div
                        className={' l-grid--w-100pc-s ' + (this.state.isLoadingSaveDetails ? ' is-loading ' : '')}
                      />
                    </div>
                    <div className="l-grid--w-100pc-s l-grid--w-40pc-s-l l-grid--w-30pc-m">
                      <button
                        id="placeOrderBtn"
                        className={
                          'base-button base-button--wide l-grid--w-100pc-s frc__generic-button' +
                          (this.state.isLoadingSaveDetails ? ' hidden ' : '')
                        }
                        onClick={() => this.checkAndSubmit(context)}
                        data-tracking="fcp-pallet-pickup-submit"
                      >
                        <span>{t('general|labels|buttons|Place Order')}</span>
                      </button>
                      {this.state.submitError && (
                        <Error
                          name="submitError"
                          className="text-center"
                          message={t('general|errors|Please fill properly all necessary fields')}
                        />
                      )}
                      {this.state.apiError && (
                        <Error
                          name="apiError"
                          className="text-center"
                          message={
                            t('palletPickup|Order failed. Try again or call technical support') +
                            ' ' +
                            t('general|or') +
                            ' ' +
                            `<strong><a href="${t('pageLinks|chat')}" 
                                        class ="frc__chat-with-us"
                                        target="_blank" rel="noopener noreferrer">${t(
                                          `general|Chat with us`,
                                        )}</a></strong>`
                          }
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            </MyContext.Consumer>
          )}
        </Translation>
      </div>
    );
  }
}

export default withRouter(TermsAndSubmit);

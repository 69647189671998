import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../../../data-config';
import { setPayerCodeAndProducts, readState } from '../Route-helpers';
import { Input } from 'components';
import { Select } from 'components';
import { Error } from 'components';
import { getWhoPaysOptions, reconcilePayerWithRange } from '../../../../../globals/helpers/shipment';
import {
  DOMESTIC_SHIPMENT_RANGE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  THIRD_PARTY_PAYER,
} from '../../../../../globals/constants';

class ImportExportSection extends Component {
  displayDropdownWithAllPayers = context => {
    let show = false;

    if (
      !(
        this.props.context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
        this.props.context.state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE
      )
    ) {
      show = true;
    }

    return show;
  };

  shouldDisplayReceiver = () => {
    const ctxState = this.props.context.state;

    return (
      (ctxState.shipmentPayer.value === RECEIVER_PAYER &&
        ctxState.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE &&
        ctxState.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) ||
      (ctxState.shipmentPayer.value === THIRD_PARTY_PAYER &&
        ctxState.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE &&
        ctxState.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) ||
      (ctxState.shipmentPayer.value === RECEIVER_PAYER && ctxState.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE)
    );
  };

  render() {
    const { state } = this.props.context;
    const localStorageCountry = localStorage.getItem('country');
    const whoPaysOptions = getWhoPaysOptions(localStorageCountry, readState(state));

    return (
      <Translation>
        {t => (
          <div className=" frc__generic--field-wrapper ">
            <h4 className="margin-bottom-0 frc__red-lined-title">{t('general|Route Type & Payment')}</h4>
            <div className="l-grid l-grid--between-s margin-bottom-2">
              <div className="l-grid--w-100pc-s l-grid--w-48pc-m">
                <Select
                  context={this.props.context}
                  config={config}
                  CONST={{ API_DOMAIN: 'general' }}
                  selectClass="l-grid--w-100pc-s"
                  label={t('general|labels|inputs|Is this a domestic or international shipment?')}
                  name="shipmentRange"
                  values={config.shipmentRanges}
                  value={state.shipmentRange.value}
                  afterUpdate={setPayerCodeAndProducts}
                />

                {state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE && (
                  <Select
                    context={this.props.context}
                    config={config}
                    CONST={{ API_DOMAIN: 'general' }}
                    selectClass="l-grid--w-100pc-s"
                    label={t(`general|Are you importing or exporting this shipment?`)}
                    name="shipmentTypesImpOrExp"
                    values={config.shipmentTypesImpOrExp}
                    value={state.shipmentTypesImpOrExp.value}
                    beforeUpdate={(context, params) => reconcilePayerWithRange(context, params, localStorageCountry)}
                    afterUpdate={setPayerCodeAndProducts}
                  />
                )}
              </div>

              <div className="l-grid--w-100pc-s l-grid--w-48pc-m">
                <Select
                  context={this.props.context}
                  config={config}
                  CONST={{ API_DOMAIN: 'general' }}
                  selectClass="l-grid--w-100pc-s"
                  label={t('general|Who will pay for this shipment?')}
                  name="shipmentPayer"
                  values={whoPaysOptions}
                  value={state.shipmentPayer.value}
                  afterUpdate={setPayerCodeAndProducts}
                />
                <div className="l-grid l-grid--between-s">
                  {state.accountNumber.display && (
                    <div className={'l-grid--w-100pc-s l-grid--w-48pc-m'}>
                      {state.accountNumber.isSelect ? (
                        <Select
                          selectClass="l-grid--w-100pc-s"
                          label={t('general|labels|inputs|Sender account number')}
                          name="accountNumber"
                          disabled={false}
                          values={state.accounts}
                          value={state.accountNumber.value}
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          skipTranslation
                          defaultOption={{ name: t('general|labels|inputs|Sender account number'), value: '' }}
                          afterUpdate={setPayerCodeAndProducts}
                        />
                      ) : (
                        <Input
                          regEx={config.regEx.alphaNumericWithDot}
                          label={t('general|labels|inputs|Sender account number')}
                          name="accountNumber"
                          dataTestId="accountNumberInp"
                          value={state.accountNumber.value}
                          lengthCheck={
                            state.accountNumber.isRequired
                              ? [RegExp(`^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$`)]
                              : [
                                  RegExp(
                                    `(^$)|(^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$)`,
                                  ),
                                ]
                          }
                          cutTextLimit={config.maxAccountNumberLength}
                          context={this.props.context}
                          disabled={state.accountNumber.disabled}
                          config={config}
                          CONST={CONST}
                        />
                      )}

                      {state.accountNumber.error && (
                        <Error
                          name="accountNumber"
                          className="frc__input--error"
                          message={
                            state.accountNumber.isSelect
                              ? t('general|Select Account Number')
                              : t(`general|Enter proper account number`)
                          }
                        />
                      )}
                    </div>
                  )}

                  {state.receiverNumber.display && (
                    <div className={'l-grid--w-100pc-s l-grid--w-48pc-m'}>
                      {state.receiverNumber.isSelect ? (
                        <Select
                          selectClass="l-grid--w-100pc-s"
                          label={t('general|labels|inputs|Receiver Account Number')}
                          name="receiverNumber"
                          disabled={state.receiverNumber.disabled}
                          values={state.accounts}
                          value={state.receiverNumber.value}
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          skipTranslation={true}
                          defaultOption={{ name: t('general|Select Receiver Number'), value: '' }}
                          afterUpdate={setPayerCodeAndProducts}
                        />
                      ) : (
                        <Input
                          regEx={config.regEx.alphaNumericWithDot}
                          label={t('general|labels|inputs|Receiver Account Number')}
                          name="receiverNumber"
                          dataTestId="receiverNumberInp"
                          value={state.receiverNumber.value}
                          lengthCheck={
                            state.receiverNumber.isRequired
                              ? [RegExp(`^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$`)]
                              : [
                                  RegExp(
                                    `(^$)|(^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$)`,
                                  ),
                                ]
                          }
                          cutTextLimit={config.maxAccountNumberLength}
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                        />
                      )}

                      {state.receiverNumber.error && (
                        <Error
                          name="accountNumber"
                          className="frc__input--error"
                          message={
                            state.receiverNumber.isSelect
                              ? t('general|Select Receiver Number')
                              : t(`general|Enter proper account number`)
                          }
                        />
                      )}
                    </div>
                  )}

                  {state.thirdPartyNumber.display && (
                    <div
                      className={
                        this.shouldDisplayReceiver() || state.shipmentPayer.value === THIRD_PARTY_PAYER
                          ? 'l-grid--w-100pc-s l-grid--w-48pc-m'
                          : 'l-grid--w-100pc-s'
                      }
                    >
                      <>
                        {state.thirdPartyNumber.isSelect ? (
                          <Select
                            selectClass="l-grid--w-100pc-s"
                            label={t(`${CONST.API_DOMAIN}|Sender Third Party Number`)}
                            name="thirdPartyNumber"
                            disabled={false}
                            values={state.accounts}
                            value={state.thirdPartyNumber.value}
                            context={this.props.context}
                            config={config}
                            CONST={CONST}
                            skipTranslation={true}
                            defaultOption={{ name: t('general|Select Account Number'), value: '' }}
                          />
                        ) : (
                          <Input
                            regEx={config.regEx.alphaNumericWithDot}
                            label={t('general|labels|inputs|Third Party Account Number')}
                            name="thirdPartyNumber"
                            dataTestId="thirdPartyNumberInp"
                            value={state.thirdPartyNumber.value}
                            lengthCheck={
                              state.thirdPartyNumber.isRequired
                                ? [RegExp(`^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$`)]
                                : [
                                    RegExp(
                                      `(^$)|(^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$)`,
                                    ),
                                  ]
                            }
                            cutTextLimit={config.maxAccountNumberLength}
                            context={this.props.context}
                            config={config}
                            CONST={CONST}
                          />
                        )}

                        {state.thirdPartyNumber.error && (
                          <Error
                            name="accountNumber"
                            className="frc__input--error"
                            message={t(`general|Enter proper account number`)}
                          />
                        )}
                      </>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default ImportExportSection;

import { globalConfig, GLOBAL_CONST } from 'globals/data-config-global';

const componentConfig = {};

const COMPONENT_CONST = {
  API_DOMAIN: 'resetPassword',
  ADDRESS_ERROR_CODE: 422,
  RESET_PASSWORD_PREFIX: 'RPW',
  STATUS_ACCEPTED: 202,
};

export const config = Object.assign({}, globalConfig, componentConfig);
export const CONST = Object.assign({}, GLOBAL_CONST, COMPONENT_CONST);

export const convertProductDatesToIsoStrings = productsObject => {
  if (!productsObject) {
    return {};
  }
  const result = {};
  for (const key in productsObject) {
    if (productsObject.hasOwnProperty(key)) {
      result[key] = productsObject[key].map(date => new Date(date).toISOString());
    }
  }
  return result;
};

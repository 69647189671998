import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../../data-config';
import {
  checkSenderPayer,
  displayDropdownWithPayersWithoutSender,
  getPayerCodeCheck,
} from '../EnterPickupLocation-helpers';
import { Error, Input, Select } from 'components';
import i18n from '../../../../locale/i18n';
import {
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  SENDER_PAYER,
  THIRD_PARTY_PAYER,
} from '../../../../globals/constants';

class ImportExportSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    if (process.env.NODE_ENV !== 'test') window.scrollTo(0, 0);
  };

  displayDropdownWithAllPayers = context => {
    let show = false;

    if (
      !(
        this.props.context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
        this.props.context.state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE
      )
    ) {
      show = true;
    }

    return show;
  };

  getPayersList = () => {
    let payers;

    if (this.props.context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) {
      this.props.context.state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE
        ? (payers = config.shipmentPayersImport)
        : (payers = config.shipmentPayers);
    } else {
      payers = config.shipmentPayers.filter(item => {
        if (i18n.language.split('-')[0]?.toLowerCase() === 'se') {
          return item;
        } else {
          return item.toLowerCase() !== 'third party';
        }
      });
    }

    return payers;
  };

  shouldDisplaySender = () => {
    const ctxState = this.props.context.state;

    return (
      ctxState.shipmentPayer.value === SENDER_PAYER ||
      (ctxState.shipmentPayer.value === RECEIVER_PAYER && ctxState.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE) ||
      (ctxState.shipmentPayer.value === THIRD_PARTY_PAYER &&
        ctxState.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE) ||
      (ctxState.shipmentPayer.value === RECEIVER_PAYER &&
        ctxState.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE &&
        ctxState.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) ||
      (ctxState.shipmentPayer.value === THIRD_PARTY_PAYER && ctxState.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE)
    );
  };

  shouldDisplayReceiver = () => {
    const ctxState = this.props.context.state;

    return (
      (ctxState.shipmentPayer.value === RECEIVER_PAYER &&
        ctxState.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE &&
        ctxState.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) ||
      (ctxState.shipmentPayer.value === THIRD_PARTY_PAYER &&
        ctxState.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE &&
        ctxState.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) ||
      (ctxState.shipmentPayer.value === RECEIVER_PAYER && ctxState.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE)
    );
  };

  afterUpdate = (context, params) => {
    this.props.fillReceiverData(
      this.props.context.state.shipmentTypesImpOrExp.value,
      context,
      this.props.resetCityValidationError,
    );
    getPayerCodeCheck(context, params);
  };

  render() {
    let mandatorySender = false;
    let mandatoryReceiver = false;
    let mandatoryThirdParty = false;

    mandatorySender =
      this.props.context.state.shipmentPayer.value === SENDER_PAYER ||
      this.props.context.state.shipmentPayer.value === THIRD_PARTY_PAYER ||
      (this.props.context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
        this.props.context.state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE);

    mandatoryReceiver =
      (this.props.context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
        this.props.context.state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE) ||
      (this.props.context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE &&
        this.props.context.state.shipmentPayer.value === RECEIVER_PAYER);

    mandatoryThirdParty = this.props.context.state.shipmentPayer.value === THIRD_PARTY_PAYER;

    return (
      <Translation>
        {t => (
          <div className=" frc__generic--field-wrapper ">
            <h4 className="margin-bottom-0 frc__red-lined-title">{t('general|Route Type & Payment')}</h4>
            <div>
              <div className="l-grid l-grid--between-s">
                <div className="l-grid--w-100pc-s l-grid--w-48pc-m">
                  <Select
                    context={this.props.context}
                    config={config}
                    CONST={{ API_DOMAIN: 'general' }}
                    selectClass="l-grid--w-100pc-s"
                    label={t('general|labels|inputs|Is this a domestic or international shipment?')}
                    name="shipmentRange"
                    isRequired={true}
                    values={config.shipmentRanges}
                    value={this.props.context.state.shipmentRange.value}
                    afterUpdate={this.afterUpdate}
                  />

                  {this.props.context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE && (
                    <Select
                      context={this.props.context}
                      config={config}
                      CONST={{ API_DOMAIN: 'general' }}
                      selectClass="l-grid--w-100pc-s"
                      label={t(`general|Are you importing or exporting this shipment?`)}
                      name="shipmentTypesImpOrExp"
                      isRequired={true}
                      values={config.shipmentTypesImpOrExp}
                      value={this.props.context.state.shipmentTypesImpOrExp.value}
                      beforeUpdate={checkSenderPayer}
                      afterUpdate={this.afterUpdate}
                    />
                  )}
                </div>

                <div className="l-grid--w-100pc-s l-grid--w-48pc-m">
                  {this.displayDropdownWithAllPayers(this.props.context) && (
                    <Select
                      context={this.props.context}
                      config={config}
                      CONST={{ API_DOMAIN: 'general' }}
                      selectClass="l-grid--w-100pc-s"
                      label={t('general|Who will pay for this shipment?')}
                      name="shipmentPayer"
                      isRequired={true}
                      values={config.shipmentPayers.filter(item => {
                        if (i18n.language.split('-')[0]?.toLowerCase() === 'se') {
                          return item;
                        } else {
                          return item.toLowerCase() !== 'third party';
                        }
                      })}
                      value={this.props.context.state.shipmentPayer.value}
                      afterUpdate={getPayerCodeCheck}
                    />
                  )}

                  {displayDropdownWithPayersWithoutSender(this.props.context) && (
                    <Select
                      context={this.props.context}
                      config={config}
                      CONST={{ API_DOMAIN: 'general' }}
                      selectClass="l-grid--w-100pc-s"
                      label={t('general|Who will pay for this shipment?')}
                      name="shipmentPayer"
                      isRequired={true}
                      values={config.shipmentPayersImport}
                      value={this.props.context.state.shipmentPayer.value}
                      beforeUpdate={checkSenderPayer}
                      afterUpdate={getPayerCodeCheck}
                    />
                  )}

                  <div className="l-grid l-grid--between-s">
                    {this.shouldDisplaySender() && !displayDropdownWithPayersWithoutSender(this.props.context) && (
                      <>
                        <div
                          className={
                            this.shouldDisplayReceiver() ||
                            this.props.context.state.shipmentPayer.value === THIRD_PARTY_PAYER
                              ? 'l-grid--w-100pc-s l-grid--w-48pc-m'
                              : 'l-grid--w-100pc-s'
                          }
                        >
                          <Input
                            regEx={config.regEx.alphaNumericWithDot}
                            label={t('general|labels|inputs|Sender account number')}
                            name="accountNumber"
                            dataTestId="accountNumberInp"
                            isRequired={mandatorySender}
                            value={this.props.context.state.accountNumber.value}
                            lengthCheck={
                              mandatorySender
                                ? [RegExp(`^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$`)]
                                : [
                                    RegExp(
                                      `(^$)|(^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$)`,
                                    ),
                                  ]
                            }
                            cutTextLimit={config.maxAccountNumberLength}
                            context={this.props.context}
                            config={config}
                            CONST={CONST}
                          />
                          {this.props.context.state.accountNumber.error && (
                            <Error
                              name="accountNumber"
                              className="frc__input--error"
                              message={t(`general|Enter proper account number`)}
                            />
                          )}
                        </div>
                      </>
                    )}

                    {(this.shouldDisplayReceiver() || displayDropdownWithPayersWithoutSender(this.props.context)) && (
                      <>
                        <div
                          className={
                            this.shouldDisplaySender() ||
                            this.props.context.state.shipmentPayer.value === THIRD_PARTY_PAYER
                              ? 'l-grid--w-100pc-s l-grid--w-48pc-m'
                              : 'l-grid--w-100pc-s'
                          }
                        >
                          <Input
                            regEx={config.regEx.alphaNumericWithDot}
                            label={t('general|labels|inputs|Receiver Account Number')}
                            name="receiverNumber"
                            isRequired={mandatoryReceiver}
                            value={this.props.context.state.receiverNumber.value}
                            lengthCheck={
                              mandatoryReceiver
                                ? [RegExp(`^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$`)]
                                : [
                                    RegExp(
                                      `(^$)|(^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$)`,
                                    ),
                                  ]
                            }
                            cutTextLimit={config.maxAccountNumberLength}
                            context={this.props.context}
                            config={config}
                            CONST={CONST}
                          />
                          {this.props.context.state.receiverNumber.error && (
                            <Error
                              name="receiverNumber"
                              className="frc__input--error"
                              message={t(`general|Enter proper account number`)}
                            />
                          )}
                        </div>
                      </>
                    )}

                    {this.props.context.state.shipmentPayer.value === THIRD_PARTY_PAYER && (
                      <div className={'l-grid--w-100pc-s l-grid--w-48pc-m'}>
                        <Input
                          regEx={config.regEx.alphaNumericWithDot}
                          label={t('general|labels|inputs|Third Party Account Number')}
                          name="thirdPartyNumber"
                          isRequired={mandatoryThirdParty}
                          value={this.props.context.state.thirdPartyNumber.value}
                          lengthCheck={
                            mandatoryThirdParty
                              ? [RegExp(`^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$`)]
                              : [
                                  RegExp(
                                    `(^$)|(^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$)`,
                                  ),
                                ]
                          }
                          cutTextLimit={config.maxAccountNumberLength}
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                        />
                        {this.props.context.state.thirdPartyNumber.error && (
                          <Error
                            name="thirdPartyNumber"
                            className="frc__input--error"
                            message={t(`general|Enter proper account number`)}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default ImportExportSection;

import { CONST, config } from '../../../data-config';
import 'globals/utils/shipmentAttributesFormula';

export const dhlPallEURHalfPalletDimensions = (state, shipmentRow) => {
  if (
    state.selectedProducts.length === 1 &&
    state.selectedProducts[0] === config.dhlPALL &&
    shipmentRow.shipmentType.value === 'half pallet'
  ) {
    return { maxWeight: config.dhlPallEURHalfPallet.maxWeight, maxHeight: config.dhlPallEURHalfPallet.maxHeight };
  } else {
    return {
      maxWeight: state.dimensions.max_item_weight,
      maxHeight: state.dimensions.max_item_height,
    };
  }
};

export const calculateVolume = (context, params) => {
  const callbackState = {};
  const volumeMetricDivider = 1000000;
  const loadingMeterMetricDivider = 10000;
  let totals = {};
  const group = JSON.parse(JSON.stringify(context.state[params.groupName]));
  const isMorethanTwoProductsSelectedOrMultipleReceiver = getMultipleProductSelectedOrMultipleReceiverFlag(context);
  const missingLWH =
    group[params.index].length.value.length === 0 ||
    group[params.index].width.value.length === 0 ||
    group[params.index].height.value.length === 0;

  if (
    !isNaN(group[params.index].length.value) &&
    !isNaN(group[params.index].width.value) &&
    !isNaN(group[params.index].height.value)
  ) {
    if (!missingLWH) {
      // volume value
      group[params.index].volume.value = (
        (group[params.index].length.value * group[params.index].width.value * group[params.index].height.value) /
        volumeMetricDivider
      ).toFixedUp(config.shipmentInputs.volume.maxDecimals);
    }

    // Volume error message
    if (
      group[params.index].volume.value * 1 < context.state.dimensions.min_item_volume ||
      group[params.index].volume.value * 1 > context.state.dimensions.max_item_volume
    ) {
      group[params.index].volume.error = true;
    } else {
      group[params.index].volume.error = false;
    }

    // Loading meter calc
    if (group[params.index].height.value * 1 > context.state.minMaxDimensions.non_stackable_height && !missingLWH) {
      group[params.index].loadingMeter.value = (
        (group[params.index].length.value * group[params.index].width.value) /
        (context.state.minMaxDimensions.loading_meter_const * loadingMeterMetricDivider)
      ).toFixedUp(config.shipmentInputs.loadingMeter.maxDecimals);
      group[params.index].loadingMeter.disabled = true;
    } else {
      group[params.index].loadingMeter.disabled = false;
    }

    // Loading Meter Error Message
    if (
      group[params.index].loadingMeter.value * 1 < context.state.dimensions.min_item_loading_meter ||
      group[params.index].loadingMeter.value * 1 > context.state.dimensions.max_item_loading_meter
    ) {
      group[params.index].loadingMeter.error = true;
    } else {
      group[params.index].loadingMeter.error = false;
    }

    callbackState[params.groupName] = group;
  }

  totals = !(Object.keys(callbackState).length === 0 && callbackState.constructor === Object)
    ? calculateTotals(
        callbackState[params.groupName],
        context.state.totals,
        isMorethanTwoProductsSelectedOrMultipleReceiver,
      )
    : calculateTotals(
        context.state[params.groupName],
        context.state.totals,
        isMorethanTwoProductsSelectedOrMultipleReceiver,
      );

  if (typeof totals === 'object' && totals !== null) {
    totals = JSON.parse(JSON.stringify(totals));
    Object.assign(callbackState, totals);
  }

  return callbackState;
};

export const resetLWHLoadingMeterAndCalculateInputs = (context, params) => {
  const callbackState = {};
  const group = JSON.parse(JSON.stringify(context.state[params.groupName]));
  const isMorethanTwoProductsSelectedOrMultipleReceiver = getMultipleProductSelectedOrMultipleReceiverFlag(context);
  let totals = {};

  totals = calculateTotals(group, context.state.totals, isMorethanTwoProductsSelectedOrMultipleReceiver);

  if (context.state.totals[params.groupName].volume.value * 1 !== totals.totals[params.groupName].volume.value * 1) {
    group[params.index].length.value = '';
    group[params.index].width.value = '';
    group[params.index].height.value = '';
    group[params.index].length.error = false;
    group[params.index].width.error = false;
    group[params.index].height.error = false;
    callbackState[params.groupName] = group;
  }

  if (typeof totals === 'object' && totals !== null) {
    totals = JSON.parse(JSON.stringify(totals));
    Object.assign(callbackState, totals);
  }

  return callbackState;
};

export const getMultipleProductSelectedOrMultipleReceiverFlag = context => {
  return context.state.selectedProducts.length > 1 || context.state.shippingToMultipleAddresses.value;
};

export const calculateInputs = (context, params) => {
  const isMorethanTwoProductsSelectedOrMultipleReceiver = getMultipleProductSelectedOrMultipleReceiverFlag(context);
  const callbackState = {};
  let totals = {};

  totals = calculateTotals(
    context.state[params.groupName],
    context.state.totals,
    isMorethanTwoProductsSelectedOrMultipleReceiver,
  );

  if (typeof totals === 'object' && totals !== null) {
    totals = JSON.parse(JSON.stringify(totals));
    Object.assign(callbackState, totals);
  }

  return callbackState;
};

export const calculateTotals = (
  callbackState,
  contextTotals,
  isMorethanTwoProductsSelectedOrMultipleReceiver = false,
) => {
  let totals = {};
  const groupName = 'shipmentDetailsRows';

  if (callbackState && callbackState.length > 0) {
    let quantity = 0;
    let volume = 0;
    let loadingMeter = 0;
    let weight = 0;
    let palletPlace = 0;

    totals = JSON.parse(JSON.stringify(contextTotals));

    Array.from(callbackState).forEach((element, index) => {
      let qty = element.quantity.value * 1;
      let rowVolume = element.volume.value.toString().replace(',', '.');
      let rowLoadingMeter = element.loadingMeter.value.toString().replace(',', '.');
      let rowWeight = element.weight.value.toString().replace(',', '.');
      let rowPalletPlace = element.palletPlace.value.toString().replace(',', '.');

      rowVolume = rowVolume === '.' ? 0 : rowVolume;
      rowLoadingMeter = rowLoadingMeter === '.' ? 0 : rowLoadingMeter;
      rowWeight = rowWeight === '.' ? 0 : rowWeight;
      rowPalletPlace = rowPalletPlace === '.' ? 0 : rowPalletPlace;
      quantity += qty;

      if (element.shipmentType.value === 'full pallet' || element.shipmentType.value === 'half pallet') {
        rowLoadingMeter = (rowLoadingMeter * 1 + rowPalletPlace * CONST.LOADING_METER_PER_PALLET_PLACE).toFixed(
          config.shipmentInputs.palletPlace.maxDecimals,
        );
      }

      if (isMorethanTwoProductsSelectedOrMultipleReceiver) {
        volume += rowVolume * 1;
        loadingMeter += rowLoadingMeter * 1;
        weight += rowWeight * 1;
        palletPlace += rowPalletPlace * 1;
      } else {
        volume += rowVolume * qty;
        loadingMeter += rowLoadingMeter * qty;
        weight += rowWeight * qty;
        palletPlace += rowPalletPlace * qty;
      }
    });

    totals[groupName].quantity.value = quantity;
    totals[groupName].volume.value = volume.toFixed(config.shipmentInputs.volume.maxDecimals);
    totals[groupName].loadingMeter.value = loadingMeter.toFixed(config.shipmentInputs.loadingMeter.maxDecimals);
    totals[groupName].weight.value = weight.toFixed(config.shipmentInputs.weight.maxDecimals);
    totals[groupName].palletPlace.value = palletPlace.toFixed(config.shipmentInputs.palletPlace.maxDecimals);
  }

  return { totals: { ...totals } };
};

export const resetShipmentDetailsInputOnTypeChange = (context, params, stateObject) => {
  const callbackState = {};
  const reset = JSON.parse(JSON.stringify(config.shipmentTypesFields[params.value]));
  const group = JSON.parse(JSON.stringify(stateObject[params.groupName]));
  const quantity = JSON.parse(JSON.stringify(group[params.index].quantity));
  const shipmentType = JSON.parse(JSON.stringify(group[params.index].shipmentType));
  const weight = JSON.parse(JSON.stringify(group[params.index].weight));
  // const palletPlace = JSON.parse(JSON.stringify(group[params.index].palletPlace));

  reset.quantity = quantity;
  reset.shipmentType = shipmentType;
  reset.weight = weight;
  // reset.palletPlace = palletPlace;

  group[params.index] = reset;
  callbackState[params.groupName] = group;

  return callbackState;
};

export const checkIfSelectedProductIsHomeOrStycke = state => {
  let returnValue = false;

  state.selectedProducts.forEach(product => {
    if (config.dhlStyckeHomeDeliveryProduct.indexOf(product) !== -1) returnValue = true;
  });

  return returnValue;
};

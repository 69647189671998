export const caseInsensitiveEquals = (a, b) => {
  if (typeof a !== 'string' || typeof b !== 'string') return a === b;
  return a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0;
};

export const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || "";

/**
 * Simplified implementation of Java's <code>String.split()</code>.
 * Last item in the result always contains whole remained of the input string.
 * This implementation does not support regex separator.
 */
export function javaSplit(string, separator, limit) {
  const split = string.split(separator);
  if (split.length <= limit) {
    return split;
  }
  const result = split.slice(0, limit - 1);
  result.push(split.slice(limit-1).join(separator));
  return result;
}

import React from 'react';

import './CustomCell.css';

export default function ContactCustomCell(props) {
  const { cellValue } = props;

  return (
    <div className="frc__custom-cell-line-break">
      <div> {cellValue.name || ''} </div>
      <div>{cellValue.phone && !cellValue.phone.endsWith('-') ? cellValue.phone : ''}</div>
      {cellValue.email && (
        <a href={`mailto:${cellValue.email}`} className="frc__data-grid-email">
          {cellValue.email}
        </a>
      )}
    </div>
  );
}

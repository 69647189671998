import { config } from '../../../data-config';
import { filterPriorityServices } from '../../../../globals/helpers/additionalServices';
import { INTERNATIONAL_SHIPMENT_RANGE } from '../../../../globals/constants';
import { globalConfig } from '../../../../globals/data-config-global';

export const combineADMainAndSecondaryList = (mainASList, secondaryASList) => {
  const mainList = [];
  const mainOnlyCodes = [];
  const specificVASForSecondaryList = [];
  const secondaryOnlyCodes = [];

  // Gets secondary list items only and their codes
  if (secondaryASList && secondaryASList.length > 0) {
    secondaryASList.forEach(itemSecondary => {
      const search = mainASList.find(itemMain => itemMain['code'] === itemSecondary['code']);
      if (!search) {
        specificVASForSecondaryList.push(JSON.parse(JSON.stringify(itemSecondary)));
        secondaryOnlyCodes.push(itemSecondary['code']);
      } else {
        // if vas present in both (401 and 501) or (402 and 502), secondary code will be given more priority.
        search.productCode = itemSecondary.productCode;
      }
    });
  }

  // Gets main list items, main list only codes and adds secondary list codes only to non compatibility to main list only items
  if (mainASList && mainASList.length > 0) {
    mainASList.forEach(itemMain => {
      const mainListItem = JSON.parse(JSON.stringify(itemMain));
      const search = secondaryASList.find(itemSecondary => itemSecondary['code'] === mainListItem['code']);

      if (!search) {
        if (!mainListItem.notCompatibleWith) mainListItem.notCompatibleWith = [];
        mainListItem.notCompatibleWith = mainListItem.notCompatibleWith.concat(secondaryOnlyCodes);
        mainOnlyCodes.push(mainListItem['code']);
      }

      mainList.push(mainListItem);
    });
  }

  // Puts main list only codes to non compatibility to secondary list only items
  specificVASForSecondaryList.forEach((itemSecondary, i) => {
    if (!itemSecondary.notCompatibleWith) itemSecondary.notCompatibleWith = [];
    specificVASForSecondaryList[i].notCompatibleWith = itemSecondary.notCompatibleWith.concat(mainOnlyCodes);
  });

  // Adds secondary only items to the main list
  return mainList.concat(specificVASForSecondaryList);
};

export const checkAndTranslateOptionSchemas = (additionalServices, LTCDates = false) => {
  const newVAS = additionalServices.map(additionalService => {
    let optionNotToHide = false;
    // Initialization of errors property for the conditional groups
    additionalService.errors = additionalService.errors || {};

    if (additionalService.groups) {
      additionalService.groups.map(group => {
        if (group.options) {
          group.options = group.options.map(optionList => {
            optionList &&
              optionList.map(option => {
                if (option.min === '0' && option.max === '0') {
                  option.min = null;
                  option.max = null;
                }

                if (option.type !== config.OptionFieldTypes.Hidden) {
                  optionNotToHide = true;
                } else {
                  optionNotToHide = false;
                }

                if (option.type === config.OptionFieldTypes.Telephone) {
                  if (option.value) {
                    const code = option.value.split('-')[0];
                    const number = option.value.split('-')[1];
                    option.value = [{ code: code, number: number, error: false }];
                  } else {
                    option.value = [{ code: config.defaultCountryPhoneCode, number: '', error: false }];
                  }
                } else if (option.type === config.OptionFieldTypes.DateTime) {
                  option.value = new Date();
                } else if (option.type === config.OptionFieldTypes.LtcDate) {
                  option.values = LTCDates ? LTCDates : [];
                }

                if (option.conditionalGroup && option.conditionalGroup.length > 0) {
                  let groupType = 1;
                  let groupDelimiter = ',';

                  config.conditionalGroupOptionsTypes.forEach(t => {
                    if (option.conditionalGroup.indexOf(t.delimiter) > -1) {
                      groupType = t.type;
                      groupDelimiter = t.delimiter;
                    }
                  });

                  option.conditionalGroupType = groupType;
                  option.conditionalGroupDelimiter = groupDelimiter;
                  option.conditionalGroup = option.conditionalGroup.split(groupDelimiter);
                }

                return option;
              });

            return optionList;
          });
        }

        return group;
      });
    }
    additionalService.optionNotToHide = optionNotToHide;

    return additionalService;
  });

  return newVAS;
};

export const applyOptionSchemaResultToVAS = (ASlist, optionsResults) => {
  ASlist = ASlist.map(AS => {
    const optionsToApply = optionsResults.find(osVAS => {
      return AS.code === osVAS.code;
    });

    if (optionsToApply && optionsToApply.groups) {
      AS.groups = transformOptionsToArrayOfOptions(optionsToApply.groups);
    }

    return AS;
  });

  return ASlist;
};

export const transformOptionsToArrayOfOptions = groups => {
  const newGroups = groups.map(group => {
    if (group.options) {
      group.options = [group.options];
    }

    return group;
  });

  return newGroups;
};

export const validateReturnProducts = productCode => {
  return globalConfig.returnProducts.some(product => productCode === product);
};

const getToCountryCustomFlag = (toCountryCustomFlag, product, routeState) => {
  toCountryCustomFlag = product.toCountries.filter(objCountry => {
    return objCountry.country.countryCode === routeState.deliveryCountry.value;
  });

  toCountryCustomFlag = toCountryCustomFlag[0].country.customs;

  return toCountryCustomFlag;
};

const getFromCountryCustomFlag = (fromCountryCustomFlag, product, routeState) => {
  fromCountryCustomFlag = product.fromCountries.filter(objCountry => {
    return objCountry.country.countryCode === routeState.pickupCountry.value;
  });

  fromCountryCustomFlag = fromCountryCustomFlag[0].country.customs;

  return fromCountryCustomFlag;
};

const isCustomCountryFlagTrue = (product, routeState) => {
  let toCountryCustomFlag;
  let fromCountryCustomFlag;
  let customCountryFlag;

  fromCountryCustomFlag = getFromCountryCustomFlag(fromCountryCustomFlag, product, routeState);
  toCountryCustomFlag = getToCountryCustomFlag(toCountryCustomFlag, product, routeState);

  customCountryFlag =
    toCountryCustomFlag === 'true' ||
    toCountryCustomFlag === true ||
    fromCountryCustomFlag === 'true' ||
    fromCountryCustomFlag === true;

  return customCountryFlag;
};

export const removeAdditionalServiceCustoms = additionalServices => {
  additionalServices = additionalServices.filter(AD => {
    return AD.code.indexOf('customs') === -1;
  });

  return additionalServices;
};

export const checkCustomsVASandEleminateOptionsSchema = async (stateObject, routeState, product) => {
  if (stateObject.additionalServices) {
    const shouldCustomASBeFilteredOut = !isCustomCountryFlagTrue(product, routeState);

    if (shouldCustomASBeFilteredOut) {
      stateObject.additionalServices = removeAdditionalServiceCustoms(stateObject.additionalServices);
    }

    // eleminate G10 / G12 even if LTC delivery API fails.
    if (routeState.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) {
      // filter G10 / G12
      if (product) {
        stateObject.additionalServices = filterPriorityServices(
          stateObject.additionalServices,
          product.product_features,
        );
      }
      // filter fixedDelivery
      if (routeState.ltcFailed) {
        stateObject.additionalServices = stateObject.additionalServices.filter(AD => {
          return AD.code === 'fixedDeliveryDate' ? false : true;
        });
      }
    }
  }
};

export const eleminateGServices = (product, additionalServices, deviating) => {
  let priorityService = [];
  const newAd = [];
  if (product && product.hasOwnProperty('priorityServices')) {
    priorityService = product.priorityServices.replace(new RegExp('G', 'g'), 'priorityServiceG').split(' ');
  }

  additionalServices.forEach(AD => {
    if (AD.code.indexOf('priorityServiceG') !== -1) {
      if (priorityService.indexOf(AD.code) !== -1 && !deviating) newAd.push(AD);
    } else {
      newAd.push(AD);
    }
  });

  return newAd;
};
/**
 * filterServicesForGivenProduct
 * initial filtering from the static config
 */
export const filterServicesForGivenProduct = (productCode, allAS, disabledVas) => {
  let filteredAS;
  const ASToFilterOut = disabledVas[productCode];

  if (!ASToFilterOut) {
    return allAS;
  }

  filteredAS = allAS.filter(additionalServices => {
    let AStoStay = true;

    ASToFilterOut.forEach(ASnameToRemove => {
      if (ASnameToRemove === additionalServices.code) {
        AStoStay = false;
      }
    });
    return AStoStay;
  });

  return filteredAS;
};

/**
 * applyValidationRulesToASlist
 * apply data from the API call for secondary filtering
 */
export const applyValidationRulesToASlist = (ASlist, validationResults) => {
  // apply notCompatible with list
  ASlist = ASlist.map(AS => {
    let validationResultsToApply = validationResults.find(ASwithValidationResults => {
      return ASwithValidationResults.additionalService === AS.code;
    });

    AS.notCompatibleWith = [];

    if (validationResultsToApply && validationResultsToApply.validationErrors.length > 0) {
      validationResultsToApply.validationErrors.forEach(validation => {
        if (validation.message && validation.message.indexOf('Not compatible') !== -1 && validation.fields) {
          AS.notCompatibleWith = AS.notCompatibleWith.concat(validation.fields);
        }
      });
    }

    return AS;
  });

  return ASlist;
};

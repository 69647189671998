import React, { Component } from 'react';
import './../../css/global/_variables.css';
// import './../../css/global/_icons.css';
// import './../../css/base/iconfont.css';
import 'globals/utils/debugHelpers';
import cssVars from 'css-vars-ponyfill';
import { MyProvider, MyContext } from '../../context/context';
import MarketingPage from '../MarketingPage/MarketingPage';
import '../../css/styles.css';

export default class AppPortalLogin extends Component {
  componentDidMount() {
    cssVars();
  }

  render() {
    return (
      <MyProvider>
        <MyContext.Consumer>{context => <MarketingPage context={context} />}</MyContext.Consumer>
      </MyProvider>
    );
  }
}

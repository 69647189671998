/**
 * Additional Services
 * Value Added Services
 *
 * AS, VAS, VASS
 *
 */

/**
 * Represents product feature object from leadtimes API.
 *
 * @typedef {Object} ProductFeatures
 * @property {boolean} pre10 - P10 allowed
 * @property {boolean} pre12 - P12 allowed
 * @property {boolean} same_day_pickup_12 - Same day pickup 12 allowed
 * @property {boolean} same_day_pickup_15 - Same day pickup 15 allowed
 */

import { HEMLEVERANS_PRODUCT_CODE } from '../constants';
import { toast } from 'react-toastify';
import { Alert } from '@mui/material';
import i18n from 'i18next';

/**
 * Filters out priority services P10, P12 (old names G10, G12) based
 * on the response from leadtimes-comitted API (triggered by desired pickup date)
 *
 * @param {Array} [additionalServices] - Additional services array to filter
 * @param {ProductFeatures} [productFeatures] - Product features object from leadtime API
 * @returns {Array} - Filtered additional services
 */
export const filterPriorityServices = (additionalServices, productFeatures) => {
  if (!Array.isArray(additionalServices)) return additionalServices;

  const pre10 = productFeatures?.pre10;
  const pre12 = productFeatures?.pre12;

  additionalServices = additionalServices.filter(service => {
    if ((service.code === 'priorityServiceG10' || service.code === 'priorityServiceP10') && !pre10) return false;
    if ((service.code === 'priorityServiceG12' || service.code === 'priorityServiceP12') && !pre12) return false;
    return true;
  });

  return additionalServices;
};

export const getDoorStepDeliveryVasDefaultData = () => {
  return {
    code: 'doorstepDelivery',
    name: 'Doorstep Delivery',
    translations: [
      {
        languageCode: 'sv',
        name: 'Hemleverans',
        information: 'Hem till dörren, kl 17-21',
      },
      {
        languageCode: 'en',
        name: 'Doorstep Delivery',
        information: 'Doorstep delivery,  5 pm – 9 pm',
      },
    ],
  };
};

/**
 * FCPG-2348 HEMLEVERANS && DangerousGoods Limited quantity check
 * @param context
 * @returns {boolean}
 */
export function hemleveransDependentVasCheck(selectedProductCode, additionalServices) {
  let hemError = false;
  const dangerousGoodsLimitedQuantityVASSelected = additionalServices.some(
    vas => vas.value && vas.code === 'dangerousGoodsLimitedQuantity',
  );

  if (selectedProductCode === HEMLEVERANS_PRODUCT_CODE && dangerousGoodsLimitedQuantityVASSelected) {
    const vasNeedsToBeChecked = ['deliveryWithIdControl', 'personalDelivery'];
    const checkedDependentVAS = additionalServices.some(vas => vas.value && vasNeedsToBeChecked.includes(vas.code));
    if (!checkedDependentVAS) {
      toast(
        <Alert severity="error" variant="standard">
          {i18n.t(
            `general|errors|Please select either 'Delivery with ID Control' or 'Personal Delivery' when choosing 'Dangerous Goods - Limited Quantity'.`,
          )}
        </Alert>,
      );
      hemError = true;
    }
  }
  return hemError;
}

import { config, CONST } from '../../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import { validateGeoLocation } from '../../DeliveryOptions/DeliveryOptions-helpers';
import { getOptionSchema } from '../ServiceDetails';
import { getValidationResults } from '../ServiceDetails';
import { getArrayIntersectionValues } from './ServiceDetails-General-helpers';
import { combineADMainAndSecondaryList } from './ServiceDetails-helpers';

export const homeDeliveryValidations = async (product, state, stateObject) => {
  let secondaryCodeVasObj = {};
  let geoLocationValidation = {};
  const secondaryCode = config.homeDeliveryMapping[product.code].secondaryCode;

  const vasSpecialCase = getVasForSecondaryCode(product, state);
  const geoLocationMap = validateGeoLocation(state, [], product.code, true);
  await vasSpecialCase.then(result => (secondaryCodeVasObj = result));
  await geoLocationMap.then(result => (geoLocationValidation = result));
  await getOptionSchema(secondaryCodeVasObj, { code: secondaryCode });

  if (
    secondaryCodeVasObj.error ||
    geoLocationValidation.error ||
    (geoLocationValidation.data && geoLocationValidation?.data?.replyStatus !== 'Ok')
  ) {
    stateObject.error = true;
  }

  if (!stateObject.error) {
    // combine main VAS and Home Delivery Vas
    const preFilteredList = combineADMainAndSecondaryList(
      stateObject.additionalServices,
      secondaryCodeVasObj.additionalServices,
    );
    // get intersection between VAS and Home Delivery Validators VAS
    if (geoLocationValidation.data && geoLocationValidation.data.additionalServices) {
      stateObject.additionalServices = getArrayIntersectionValues(
        geoLocationValidation.data.additionalServices,
        preFilteredList,
      );
    }
  }
};

// Home Delivery VAS
const getVasForSecondaryCode = async (product, state) => {
  const stateObj = {};
  const productCode = config.homeDeliveryMapping[product.code].secondaryCode;
  const pickupCode = state.pickupCountry.value;
  const deliveryCode = state.deliveryCountry.value;
  const payerCode = state.payerCode;
  const urlAdditionalServices = apiLinks.getAdditionalServicesExtended
    .replace('{productCode}', productCode)
    .replace('{pickupCode}', pickupCode)
    .replace('{deliveryCode}', deliveryCode)
    .replace('{payerCode}', payerCode);

  await apiRequest(urlAdditionalServices, 'GET')
    .then(async result => {
      if (result.status === CONST.STATUS_OK) {
        result.data.forEach(element => {
          element.value = false;
          element.productCode = productCode;
        });

        stateObj.additionalServices = result.data;
        // Functionality to apply validation results:
        await getValidationResults(JSON.parse(JSON.stringify(stateObj.additionalServices)), productCode).then(
          validationResult => {
            if (validationResult.data) {
              stateObj.additionalServices = validationResult.data;
            } else if (validationResult.error) {
              stateObj.error = true;
            }
          },
        );
      } else {
        stateObj.error = true;
      }
    })
    .catch(() => {
      stateObj.error = true;
    });

  return stateObj;
};

import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';
import { Input } from 'components';
import { Select } from 'components';
import { Error } from 'components';
import { Checkbox } from 'components';
import { afterCheck, callVASOnCountryOrPayerChange } from '../ServiceDetails/ServiceDetails-helpers';
import { getCityName } from './EnterPickupLocation-helpers';
import { canFetchCityForPostalcode } from '../../../globals/helpers/postalcode';

import './EnterPickupLocation.css';
import PhoneNumberV2 from '../../../components/PhoneNumberV2/PhoneNumberV2';
import { changeCountryCodePhonePrefix } from '../../../globals/helpers/country-helpers';

export default class EnterPickupLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shippingToMultipleAddresses: false,
    };

    this.loaderRef = React.createRef();
  }

  hideResponseMessage = property => {
    const that = this;

    setTimeout(() => {
      that.setState({
        ...that.state,
        [property]: 0,
      });
    }, 2000);
  };

  runPostalCodeValidationLogic = (params, context) => {
    if (context.state[params.name].value !== params.value) {
      if (params.value.length === CONST.MIN_POSTALCODE_NUMBER) {
        params.runPostalCodesValidationForGServices = true;
      }
    }

    context.updateField(params);
  };

  toggleShippingToMultipleAddresses = (params, context, t) => {
    this.setState(prevState => {
      return { shippingToMultipleAddresses: !prevState.shippingToMultipleAddresses };
    });

    context.updateState(afterCheck(context, params, t));
  };

  setAppropriateCity = (suggestedCity = false, cityName, cityError) => {
    const city = JSON.parse(JSON.stringify(this.props.context.state[cityName]));
    const cityValidationError = JSON.parse(JSON.stringify(this.props.context.state.cityValidationError));

    city.value = suggestedCity || this.props.context.state.cityValidationError[cityName].value;
    city.error = false;
    delete cityValidationError[cityName];

    this.props.context.updateState({ [cityName]: city, cityValidationError, [cityError]: false });

    if (suggestedCity) this.loaderRef.current.classList.remove('is-loading');
  };

  checkIfEnteredAndSuggestedCityIsValid = cityName => {
    const { cityValidationError } = this.props.context.state;

    if (
      cityValidationError[cityName] &&
      cityValidationError[cityName].mismatchError &&
      cityValidationError[cityName].value
    ) {
      const modifiedCityValidationError = JSON.parse(JSON.stringify(cityValidationError));

      modifiedCityValidationError[cityName].mismatchError =
        this.props.context.state[cityName].value.toLowerCase() !==
        modifiedCityValidationError[cityName].value.toLowerCase();

      if (!modifiedCityValidationError[cityName].mismatchError) {
        delete modifiedCityValidationError[cityName];
      }

      this.props.context.updateState({
        cityValidationError: modifiedCityValidationError,
      });
    }
  };

  resetPostalCodeSuggestionError = cityName => {
    const cityValidationError = JSON.parse(JSON.stringify(this.props.context.state.cityValidationError));
    delete cityValidationError[cityName];

    this.props.context.extendedSetState({ cityValidationError }, { afterUpdate: callVASOnCountryOrPayerChange });
  };

  checkAppropriateCity = async (coutryName, postalCodeName, cityName, postalCodeError, cityError) => {
    this.loaderRef.current.classList.add('is-loading');

    const { state } = this.props.context;
    const accountCountryCode = 'SE';
    const countryCode = state[coutryName].value;
    const postalCode = state[postalCodeName].value;

    const shouldValidateCity = canFetchCityForPostalcode({ accountCountryCode, countryCode, postalCode });

    if (shouldValidateCity) {
      const stateToSet = {};

      const fetchCityCall = await getCityName(countryCode, postalCode, state[cityName].value);
      stateToSet.loader = false;
      stateToSet.cityValidationError = JSON.parse(JSON.stringify(state.cityValidationError));
      stateToSet.cityValidationError[cityName] = {};

      // postal code correct
      if (!fetchCityCall.error && fetchCityCall.data && fetchCityCall.data.city) {
        // city name correct
        if (state[cityName].value) {
          stateToSet.cityValidationError[cityName] = {
            value: fetchCityCall.data.city,
            mismatchError: !fetchCityCall.data.validated,
          };

          if (fetchCityCall.data.validated) {
            delete stateToSet.cityValidationError[cityName];
          }
        } else if (!state[cityName].value) {
          // city name not entered
          this.setAppropriateCity(fetchCityCall.data.city, cityName, cityError);

          return;
        }
      } else if (!fetchCityCall.error && fetchCityCall.data && !fetchCityCall.data.validated) {
        stateToSet.cityValidationError[cityName] = { postalCodeError: true };
      }

      if (
        stateToSet.cityValidationError[cityName] &&
        Object.keys(stateToSet.cityValidationError[cityName]).length === 0
      ) {
        delete stateToSet.cityValidationError[cityName];
      }

      stateToSet[postalCodeError] = false;
      stateToSet[cityError] = false;
      this.props.context.updateState(stateToSet);
    }

    this.loaderRef.current.classList.remove('is-loading');
  };

  render() {
    const { context } = this.props;

    return (
      <div className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <>
              <div className="frc__generic-row--wrapper">
                <p className="color-dhl-red">* {t('general|Mandatory field')}</p>
              </div>

              <div className="l-grid l-grid--between-s">
                <div className="l-grid--w-100pc-s l-grid--w-48pc-m margin-bottom-1">
                  <h4 className="margin-bottom-1">{t(`general|Enter Pickup Information`)}</h4>
                  <div className="l-grid c-form-step--item c-freightcalc--item-checkbox l-invisible">
                    <input
                      id="invisibleChkbx"
                      className={'checkbox-pallet c-form--element-base c-form--element-input c-form--element-checkbox'}
                      type="checkbox"
                    />
                    <label className="c-form--element-base c-form--element-label c-form--element-label-checkbox c-freightcalc--checkbox c-fcp--checkbox-label">
                      &nbsp;
                    </label>
                  </div>
                  <div className="frc__generic--background-white frc__generic-section--wrapper">
                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        regEx={config.regEx.everything}
                        label={t('general|Sender')}
                        name="pickupSender"
                        isRequired={true}
                        value={context.state.pickupSender.value}
                        lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                        cutTextLimit={config.maxDefaultInputLength}
                      />
                      {context.state.pickupSender.error ? (
                        <Error
                          name="pickupSender"
                          className="frc__input--error"
                          message={t('general|errors|Company Error', { max: config.maxDefaultInputLength })}
                        />
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                      <div className=" l-grid--w-100pc-s">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t('general|labels|inputs|Street')}
                          name="pickupStreet"
                          isRequired={true}
                          value={context.state.pickupStreet.value}
                          lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                        />
                        {context.state.pickupStreet.error ? (
                          <Error
                            name="pickupStreet"
                            className="frc__input--error"
                            message={t('general|errors|Street Error', { max: config.maxDefaultInputLength })}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                      <div
                        className=" l-grid--w-100pc-s l-grid--w-50pc-m"
                        onBlur={
                          context.state.pickupPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch
                            ? () =>
                                this.checkAppropriateCity(
                                  'pickupCountry',
                                  'pickupPostalCode',
                                  'pickupCity',
                                  'sourcePostalCodeError',
                                  'sourceCityError',
                                )
                            : null
                        }
                      >
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.number}
                          label={t('general|labels|inputs|Postalcode')}
                          name="pickupPostalCode"
                          isRequired={true}
                          value={context.state.pickupPostalCode.value}
                          lengthCheck={[RegExp(`^.{1,${config.maxPostalCode}}$`)]}
                          cutTextLimit={config.maxPostalCode}
                          updateOnParent={(params, context) => {
                            this.runPostalCodeValidationLogic(params, context);
                          }}
                        />

                        {context.state.pickupPostalCode.error ? (
                          <Error
                            name="pickupPostalCode"
                            className="frc__input--error"
                            message={t('general|errors|Postalcode Error', { max: config.maxPostalCode })}
                          />
                        ) : (
                          ''
                        )}

                        {context.state.cityValidationError.pickupCity &&
                        context.state.cityValidationError.pickupCity.postalCodeError ? (
                          <Error name="cityNotPresentError" message={t('general|errors|Incorrect Postal Code')} />
                        ) : context.state.sourcePostalCodeError ? (
                          <Error
                            name="sourcePostalCodeError"
                            className="frc__input--error"
                            message={t('general|errors|Postalcode API Error')}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t('general|labels|inputs|City')}
                          name="pickupCity"
                          isRequired={true}
                          value={context.state.pickupCity.value}
                          lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                          afterUpdate={
                            context.state.cityValidationError.pickupCity
                              ? () => this.checkIfEnteredAndSuggestedCityIsValid('pickupCity')
                              : null
                          }
                          afterChangeOnBlur={
                            !(
                              context.state.cityValidationError.pickupCity &&
                              context.state.cityValidationError.pickupCity.mismatchError
                            ) &&
                            context.state.pickupPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch &&
                            context.state.pickupCountry.value === config.defaultCountry
                              ? () =>
                                  this.checkAppropriateCity(
                                    'pickupCountry',
                                    'pickupPostalCode',
                                    'pickupCity',
                                    'sourcePostalCodeError',
                                    'sourceCityError',
                                  )
                              : null
                          }
                        />
                        {context.state.pickupCity.error ? (
                          <Error
                            name="pickupCity"
                            className="frc__input--error"
                            message={t('general|errors|City Error', { max: config.maxDefaultInputLength })}
                          />
                        ) : context.state.cityValidationError.pickupCity &&
                          context.state.cityValidationError.pickupCity.mismatchError ? (
                          <div className="l-grid">
                            <Error
                              name="cityMismtachError"
                              className="frc__input--error"
                              message={t('general|errors|invalidCity')}
                            />
                            <span className="frc__city-suggestion">
                              {'"'}
                              <span
                                className="frc__city-underline"
                                onClick={() => this.setAppropriateCity(false, 'pickupCity', 'sourceCityError')}
                              >
                                {context.state.cityValidationError.pickupCity.value}
                              </span>
                              {'" ?'}
                            </span>
                          </div>
                        ) : context.state.sourceCityError ? (
                          <Error
                            name="sourceCityError"
                            className="frc__input--error"
                            message={t('general|errors|City API Error')}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className=" l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Select
                        context={context}
                        config={config}
                        CONST={{
                          API_DOMAIN: 'countries',
                        }}
                        selectClass="l-grid--w-100pc-s"
                        label={t(`general|Country`)}
                        name="pickupCountry"
                        isRequired={true}
                        values={config.domesticCountriesPublicPickup}
                        value={context.state.pickupCountry.value?.toUpperCase()}
                        disabled={true}
                        afterUpdate={(context, params) => {
                          this.resetPostalCodeSuggestionError('pickupCity', 'differentPickupCity');
                          changeCountryCodePhonePrefix(context, params.value, 'pickupPhoneNumbers');
                        }}
                      />
                      {context.state.pickupCountry.error ? (
                        <Error
                          name="pickupCountry"
                          className="frc__input--error"
                          message={t(`general|Country Error`)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <p className="frc__contact-details-label" />

                  <div className="frc__generic--background-white frc__generic-section--wrapper">
                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        regEx={config.regEx.everything}
                        label={t(`general|Contact Name`)}
                        name="pickupName"
                        isRequired={true}
                        value={context.state.pickupName.value}
                        lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                        cutTextLimit={config.maxDefaultInputLength}
                      />
                      {context.state.pickupName.error ? (
                        <Error
                          name="pickupName"
                          className="frc__input--error"
                          message={t('general|errors|Contact Name Error', { max: config.maxDefaultInputLength })}
                        />
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t(`general|Contact E-mail`)}
                        name="pickupEmail"
                        isRequired={false}
                        value={context.state.pickupEmail.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxEmailLength}}$`)]}
                        cutTextLimit={config.maxEmailLength}
                        override="confirmationEmail"
                      />
                      {context.state.pickupEmail.error ? (
                        <Error
                          name="pickupEmail"
                          className="frc__input--error"
                          message={t('general|errors|E-mail Error', { chars: config.maxEmailLength })}
                        />
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
                      {context.state.pickupPhoneNumbers.slice(0, 1).map((phoneNumber, ind) => (
                        <PhoneNumberV2
                          config={config}
                          CONST={CONST}
                          context={context}
                          key={ind}
                          index={ind}
                          number={phoneNumber.number}
                          code={phoneNumber.code}
                          error={phoneNumber.error}
                          groupName="pickupPhoneNumbers"
                          isRequired={true}
                          // override={'preAdvicePhoneNumbers'}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                    <Input
                      context={context}
                      config={config}
                      CONST={CONST}
                      label={t('general|labels|inputs|Sender Reference')}
                      name="pickupSenderReference"
                      isRequired={false}
                      value={context.state.pickupSenderReference.value}
                      lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                      cutTextLimit={config.maxDefaultInputLength}
                    />
                    {context.state.pickupSenderReference.error ? (
                      <Error
                        name="pickupSenderReference"
                        className="frc__input--error"
                        message={t('general|errors|Invalid maxchar error', {
                          name: t('general|labels|inputs|Sender Reference'),
                          max: config.maxDefaultInputLength,
                        })}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="l-grid--w-100pc-s l-grid--w-48pc-m" id="fcp-priority-service-error-id">
                  <h4 className="margin-bottom-1">{t(`general|Enter Delivery Information`)}</h4>
                  <div className="l-grid l-grid--w-100pc-s">
                    <div className="l-grid--w-100pc-s l-grid--hidden-m">
                      <p>{t(`general|Address Details`)}</p>
                    </div>
                    <div className="l-grid--w-100pc-s">
                      <Checkbox
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t('general|Shipping to multiple addresses')}
                        name="shippingToMultipleAddresses"
                        isRequired={false}
                        checked={context.state.shippingToMultipleAddresses.value}
                        updateOnParent={(params, context) => this.toggleShippingToMultipleAddresses(params, context, t)}
                        disabled={
                          context.state.dhlParti.value ||
                          (context.state.selectedProducts && context.state.selectedProducts.length > 1)
                        }
                      />
                    </div>
                  </div>

                  <div className="frc__generic--background-white frc__generic-section--wrapper">
                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        disabled={context.state.shippingToMultipleAddresses.value && !context.state.dhlParti.value}
                        regEx={config.regEx.everything}
                        label={t('general|Receiver')}
                        name="deliveryReceiver"
                        isRequired={context.state.dhlParti.value || context.state.deliveryMandatoryByPriorityService}
                        value={context.state.deliveryReceiver.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                        cutTextLimit={config.maxDefaultInputLength}
                      />
                      {context.state.deliveryReceiver.error ? (
                        <Error
                          name="deliveryReceiver"
                          className="frc__input--error"
                          message={t('general|errors|Company Error', { max: config.maxDefaultInputLength })}
                        />
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                      <div className=" l-grid--w-100pc-s">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          disabled={context.state.shippingToMultipleAddresses.value && !context.state.dhlParti.value}
                          regEx={config.regEx.everything}
                          label={t('general|labels|inputs|Street')}
                          name="deliveryStreet"
                          isRequired={context.state.dhlParti.value || context.state.deliveryMandatoryByPriorityService}
                          value={context.state.deliveryStreet.value}
                          lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                        />
                        {context.state.deliveryStreet.error ? (
                          <Error
                            name="deliveryStreet"
                            className="frc__input--error"
                            message={t('general|errors|Street Error', { max: config.maxDefaultInputLength })}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                      <div
                        className=" l-grid--w-100pc-s l-grid--w-50pc-m"
                        onBlur={
                          context.state.deliveryPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch
                            ? () =>
                                this.checkAppropriateCity(
                                  'deliveryCountry',
                                  'deliveryPostalCode',
                                  'deliveryCity',
                                  'destinationPostalCodeError',
                                  'destinationCityError',
                                )
                            : null
                        }
                      >
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          disabled={context.state.shippingToMultipleAddresses.value && !context.state.dhlParti.value}
                          regEx={
                            context.state.internationalProductsSelected ? config.regEx.textNumber : config.regEx.number
                          }
                          label={t('general|labels|inputs|Postalcode')}
                          name="deliveryPostalCode"
                          isRequired={!context.state.shippingToMultipleAddresses.value || context.state.dhlParti.value}
                          value={context.state.deliveryPostalCode.value}
                          lengthCheck={[RegExp(`^.{1,${config.maxPostalCode}}$`)]}
                          cutTextLimit={config.maxPostalCode}
                          updateOnParent={(params, context) => {
                            this.runPostalCodeValidationLogic(params, context);
                          }}
                        />

                        {context.state.deliveryPostalCode.error ? (
                          <Error
                            name="deliveryPostalCode"
                            className="frc__input--error"
                            message={t('general|errors|Postalcode Error', { max: config.maxPostalCode })}
                          />
                        ) : (
                          ''
                        )}

                        {context.state.cityValidationError.deliveryCity &&
                        context.state.cityValidationError.deliveryCity.postalCodeError ? (
                          <Error name="cityNotPresentError" message={t('general|errors|Incorrect Postal Code')} />
                        ) : context.state.destinationPostalCodeError ? (
                          <Error
                            name="destinationPostalCodeError"
                            className="frc__input--error"
                            message={t('general|errors|Postalcode API Error')}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          disabled={context.state.shippingToMultipleAddresses.value && !context.state.dhlParti.value}
                          regEx={config.regEx.everything}
                          label={t('general|labels|inputs|City')}
                          name="deliveryCity"
                          isRequired={
                            !context.state.shippingToMultipleAddresses.value ||
                            context.state.dhlParti.value ||
                            context.state.deliveryMandatoryByPriorityService
                          }
                          value={
                            context.state.shippingToMultipleAddresses.value && !context.state.dhlParti.value
                              ? 'VARIOUS'
                              : context.state.deliveryCity.value
                          }
                          lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                          afterUpdate={
                            context.state.cityValidationError.deliveryCity
                              ? () => this.checkIfEnteredAndSuggestedCityIsValid('deliveryCity')
                              : null
                          }
                          afterChangeOnBlur={
                            !(
                              context.state.cityValidationError.deliveryCity &&
                              context.state.cityValidationError.deliveryCity.mismatchError
                            ) &&
                            context.state.deliveryPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch &&
                            context.state.deliveryCountry.value === config.defaultCountry
                              ? () =>
                                  this.checkAppropriateCity(
                                    'deliveryCountry',
                                    'deliveryPostalCode',
                                    'deliveryCity',
                                    'destinationPostalCodeError',
                                    'destinationCityError',
                                  )
                              : null
                          }
                        />
                        {context.state.deliveryCity.error ? (
                          <Error
                            name="deliveryCity"
                            className="frc__input--error"
                            message={t('general|errors|City Error', { max: config.maxDefaultInputLength })}
                          />
                        ) : context.state.cityValidationError.deliveryCity &&
                          context.state.cityValidationError.deliveryCity.mismatchError ? (
                          <div className="l-grid">
                            <Error
                              name="cityMismtachError"
                              className="frc__input--error"
                              message={t('general|errors|invalidCity')}
                            />
                            <span className="frc__city-suggestion">
                              {'"'}
                              <span
                                className="frc__city-underline"
                                onClick={() => this.setAppropriateCity(false, 'deliveryCity', 'destinationCityError')}
                              >
                                {context.state.cityValidationError.deliveryCity.value}
                              </span>
                              {'" ?'}
                            </span>
                          </div>
                        ) : context.state.destinationCityError ? (
                          <Error
                            name="destinationCityError"
                            className="frc__input--error"
                            message={t('general|errors|City API Error', {
                              max: config.maxDefaultInputLength,
                            })}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className=" l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Select
                        context={context}
                        config={config}
                        CONST={{
                          API_DOMAIN: 'countries',
                        }}
                        selectClass="l-grid--w-100pc-s"
                        label={t(`general|Country`)}
                        name="deliveryCountry"
                        isRequired={!context.state.shippingToMultipleAddresses.value || context.state.dhlParti.value}
                        values={
                          context.state.internationalProductsSelected
                            ? context.state.toCountries.filter(item => item.value !== context.state.pickupCountry.value)
                            : config.domesticCountriesPublicPickup
                        }
                        value={context.state.deliveryCountry.value}
                        disableonBlur={true}
                        disabled={context.state.shippingToMultipleAddresses.value && !context.state.dhlParti.value}
                        afterUpdate={(context, params) => {
                          this.resetPostalCodeSuggestionError('deliveryCity', 'differentDeliveryCity');
                          changeCountryCodePhonePrefix(context, params.value, 'deliveryPhoneNumbers');
                        }}
                      />
                      {context.state.deliveryCountry.error ? (
                        <Error
                          name="deliveryCountry"
                          className="frc__input--error"
                          message={t(`general|Country Error`)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <p className="frc__contact-details-label" />

                  <div className="frc__generic--background-white frc__generic-section--wrapper">
                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        disabled={context.state.shippingToMultipleAddresses.value && !context.state.dhlParti.value}
                        regEx={config.regEx.everything}
                        label={t(`general|Contact Name`)}
                        name="deliveryName"
                        isRequired={context.state.dhlParti.value ? true : false}
                        value={context.state.deliveryName.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                        cutTextLimit={config.maxDefaultInputLength}
                      />
                      {context.state.deliveryName.error ? (
                        <Error
                          name="deliveryName"
                          className="frc__input--error"
                          message={t('general|errors|Contact Name Error', { max: config.maxDefaultInputLength })}
                        />
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        disabled={context.state.shippingToMultipleAddresses.value && !context.state.dhlParti.value}
                        label={t(`general|Contact E-mail`)}
                        name="deliveryEmail"
                        isRequired={false}
                        value={context.state.deliveryEmail.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxEmailLength}}$`)]}
                        cutTextLimit={config.maxEmailLength}
                      />
                      {context.state.deliveryEmail.error ? (
                        <Error
                          name="deliveryEmail"
                          className="frc__input--error"
                          message={t('general|errors|E-mail Error', { chars: config.maxEmailLength })}
                        />
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
                      {context.state.deliveryPhoneNumbers.slice(0, 1).map((phoneNumber, ind) => (
                        <PhoneNumberV2
                          config={config}
                          CONST={CONST}
                          context={context}
                          key={ind}
                          index={ind}
                          number={phoneNumber.number}
                          code={phoneNumber.code}
                          removeOnParent={index => this.removePhoneNumber(index, context)}
                          error={phoneNumber.error}
                          disabled={context.state.shippingToMultipleAddresses.value && !context.state.dhlParti.value}
                          groupName="deliveryPhoneNumbers"
                          isRequired={context.state.dhlParti.value ? true : false}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                    <Input
                      context={context}
                      config={config}
                      CONST={CONST}
                      label={t('general|labels|inputs|Receiver Reference')}
                      name="deliveryReceiverReference"
                      isRequired={false}
                      value={context.state.deliveryReceiverReference.value}
                      disabled={context.state.shippingToMultipleAddresses.value && !context.state.dhlParti.value}
                      lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                      cutTextLimit={config.maxDefaultInputLength}
                    />
                    {context.state.deliveryReceiverReference.error ? (
                      <Error
                        name="deliveryReceiverReference"
                        className="frc__input--error"
                        message={t('general|errors|Invalid maxchar error', {
                          name: t('general|labels|inputs|Receiver Reference'),
                          max: config.maxDefaultInputLength,
                        })}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </Translation>
        <div
          ref={this.loaderRef}
          className={
            'frc__generic--loader-overlay' +
            (this.props.context.state.loaders.locationDetails.value ? ' is-loading ' : '')
          }
        />
      </div>
    );
  }
}

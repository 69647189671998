import React from 'react';
// import { useTranslation } from "react-i18next";
import './SelectServiceCarousel.css';

export default function SelectServiceCarousel(props) {
  // const { t } = useTranslation();
  const plusSlides = n => {
    showSlides((slideIndex += n));
  };

  let slideIndex = 1;

  const showSlides = n => {
    const slides = document.getElementsByClassName('fcp__product-width  c-product');
    const slideLength = props.slideLength;

    if (n > slideLength || n === 0) {
      slideIndex = 1;
    }

    for (let i = 0; i < slides.length; i++) {
      slides[i].classList.add('c-displayNone');
      slides[i].classList.remove('c-displayBlock');
    }

    const activateSlides = document.getElementsByClassName(`fcp__product-width  c-product ${slideIndex}`);

    for (let i = 0; i < activateSlides.length; i++) {
      activateSlides[i].classList.add('c-displayBlock');
      activateSlides[i].classList.remove('c-displayNone');
    }
  };

  return (
    <>
      <div
        onClick={() => plusSlides(-1)}
        className="c-productcontainer--scrollarrow fcp__productcontainer--scrollarrow c-productcontainer--scrollarrow-left fcp__productcontainer--scrollarrow-left"
      >
        <span className="c-productcontainer--scrollarrow-icon fcp__productcontainer--scrollarrow-icon icon-arrow-link l-grid--hidden-s l-grid--hidden-m-s  l-grid--visible-m" />
      </div>
      <div
        onClick={() => plusSlides(1)}
        className="c-productcontainer--scrollarrow  fcp__productcontainer--scrollarrow c-productcontainer--scrollarrow-right fcp__productcontainer--scrollarrow-right"
      >
        <span className="c-productcontainer--scrollarrow-icon   fcp__productcontainer--scrollarrow-icon icon-arrow-link l-grid--hidden-s l-grid--hidden-m-s  l-grid--visible-m" />
      </div>
    </>
  );
}

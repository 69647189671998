import { state as shipmentDetailsState } from '../state/shipmentDetails-state';
import { state as deliveryOptionsState } from '../state/deliveryOptions-state';

export const resetShipmentDetails = shipmentDetailsContext => {
  const state = JSON.parse(JSON.stringify(shipmentDetailsState));
  shipmentDetailsContext.updateState(state);
};

export const resetDeliveryOptions = deliveryOptionsContext => {
  const state = JSON.parse(JSON.stringify(deliveryOptionsState));
  deliveryOptionsContext.updateState(state);
};

import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';
import { config } from '../../../data-config';

export const termsValidation = (context, stateToSet, hasErrors) => {
  const getState = {};
  const state = context.state;

  getState.error = !state.termsAndConditions.value;
  hasErrors = getState.error || hasErrors;
  stateToSet['termsAndConditions'] = { value: state.termsAndConditions.value, error: getState.error };

  return {
    stateToSet,
    hasErrors,
  };
};

export const instructionsValidation = (context, stateToSet, hasErrors) => {
  const state = context.state;
  const getState = checkFieldAndReturnState({
    name: 'instructions',
    value: state['instructions'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)],
    isRequired: false,
  });

  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  return {
    stateToSet,
    hasErrors,
  };
};

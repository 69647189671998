import { useTranslation } from 'react-i18next';
import { ErrorPageRedirections } from './ErrorPageRedirections';

export function ErrorPageContent403() {
  const { t } = useTranslation();

  return (
    <>
      <div className="c-page-headline component-small has-rte component-margin">
        <p className="has-rte">
          {t("errorPage|We're sorry that the page or resource you requested is forbidden due to security concerns.")}
        </p>
        <p className="has-rte">{t('errorPage|To continue browsing our website, please go to the Home Page.')}</p>
        <p className="has-rte">
          {t(
            'errorPage|If you keep being returned to this page, please feel free to contact us and we will try to find a solution for you.',
          )}
        </p>
        <p className="has-rte">{t('errorPage|(Error 403)')}</p>
      </div>
      <ErrorPageRedirections />
    </>
  );
}

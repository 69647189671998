import { config, CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { confirmationEmail } from './FormSubmitData/Party-helpers';
import { apiRequest, setCookie } from 'globals/utils/requests';
import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';
import { formatDate } from 'globals/utils/formatting';
import getBaseUrl from 'globals/utils/getUrlBase';
import { analytics } from 'globals/utils/analytics';
import { EXPORT_SHIPMENT_TYPE } from '../../../globals/constants';

export const getPickupDate = props => {
  const { state } = props.context;

  return !state.returnProdStatus && state.pickupOption.value === 'Schedule Pickup'
    ? formatDate(state.pickupDate.value)
    : null;
};

export const resetTermsAndConditions = props => {
  const termsAndConditions = { value: '', error: false };
  props.context.updateState({
    termsAndConditions,
    bookShipmentAPIError: false,
  });
};

export const setAddressCookie = shipmentData => {
  if (!shipmentData?.shipment?.importExport) return;
  const cookieContent =
    shipmentData.shipment.importExport === EXPORT_SHIPMENT_TYPE
      ? shipmentData.shipment.parties[0]
      : shipmentData.shipment.parties[1];
  try {
    setCookie(
      'addressOrder',
      Buffer.from(encodeURIComponent(JSON.stringify(cookieContent), CONST.HOURS_PER_MONTH, 'h')).toString('base64'),
    );
  } catch {
    console.error("Failed to set cookie 'addressOrder'");
  }
};

export const getThankYouStatus = (
  publicBookingOrderResult,
  shouldPickupRequestCondition,
  deliveryDate,
  props,
  state,
) => {
  const customs = props.context.state.customs;

  if (publicBookingOrderResult.status === 0 || publicBookingOrderResult.status === 2) {
    if (publicBookingOrderResult.status === 2) {
      props.thankYouPage({
        bookingOrderResult: publicBookingOrderResult,
        pickupOrderMovedToNextDay: true,
        deliveryDate,
        fixedDate: state.fixedDate,
        customs,
        shouldPickupRequestCondition,
      });
      analytics('Thank You Moved', CONST.ANALYTICS);
    } else {
      props.thankYouPage({
        bookingOrderResult: publicBookingOrderResult,
        deliveryDate,
        fixedDate: state.fixedDate,
        customs,
        shouldPickupRequestCondition,
      });
      analytics('Thank You Accepted', CONST.ANALYTICS);
    }
  } else if (publicBookingOrderResult.status === 1) {
    props.thankYouPage({
      redThankYouPage: true,
      redThankYouErrorMessage: publicBookingOrderResult.message,
      priorityServiceError: publicBookingOrderResult.message.indexOf('\nG') > -1 ? true : false,
      bookingOrderResult: publicBookingOrderResult,
      deliveryDate,
      fixedDate: state.fixedDate,
      customs,
    });
    analytics('Thank You Rejected', CONST.ANALYTICS);
  } else {
    analytics('Thank You UNKNOWN STATUS', CONST.ANALYTICS);
  }
};

export const validateAndSubmit = props => {
  let validate = {
    stateToSet: {},
    hasErrors: false,
  };

  validate = scheduleReviewValidations(props.context, validate.stateToSet, validate.hasErrors);

  return validate;
};

export const scheduleReviewValidations = (context, stateToSet, hasErrors) => {
  let getState = {};
  const state = JSON.parse(JSON.stringify(context.state));
  const confirmationEmailValue = confirmationEmail(context.state) ? 'deliveryEmail' : 'pickupEmail';

  getState = checkFieldAndReturnState({
    name: confirmationEmailValue,
    value: state[confirmationEmailValue].value,
    regEx: config.regEx.email,
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;

  stateToSet[getState.name] = {
    value: getState.value,
    error: getState.error,
  };

  if (!state.termsAndConditions.value) {
    hasErrors = true;
    stateToSet.termsAndConditions = { value: false, error: true };
  }

  getState = checkFieldAndReturnState({
    name: 'captcha',
    value: state.captcha.value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{${config.maxCaptcha},${config.maxCaptcha}}$`)],
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;

  if (getState.error) {
    stateToSet.captcha = {
      value: '',
      error: true,
      token: state.captcha.token,
    };
  }

  context.extendedSetState(stateToSet);

  return hasErrors;
};

export const getCaptcha = async (_isMounted, props) => {
  const params = {
    headers: {
      Referer: getBaseUrl(),
    },
  };
  const cacheBreak = new Date().getTime().toString();
  try {
    const captchaAPI = await apiRequest(apiLinks.getCaptcha + '?&_=' + cacheBreak, 'GET', params);

    if (captchaAPI.data && captchaAPI.status === CONST.STATUS_OK && _isMounted) {
      props.context.updateState({
        captcha: { token: captchaAPI.data.token, value: '', error: false },
        captchaImg: captchaAPI.data.image,
        bookShipmentAPIError: false,
      });
    } else if (_isMounted) {
      props.context.updateState({ bookShipmentAPIError: true });
    }
  } catch {
    if (_isMounted) props.context.updateState({ bookShipmentAPIError: true });
  }
};

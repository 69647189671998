import { config } from '../../../data-config';
import 'globals/utils/shipmentAttributesFormula';
import { calculateTotals } from '../../../../globals/helpers/shipment-details-row';

export const calculateInputs = (context, params) => {
  let callbackState = {};
  let totals = {};

  totals = calculateTotals(context.state[params.groupName], context.state.totals);

  if (typeof totals === 'object' && totals !== null) {
    totals = JSON.parse(JSON.stringify(totals));
    Object.assign(callbackState, totals);
  }

  return callbackState;
};

export const resetShipmentDetailsInputOnTypeChange = (context, params, stateObject) => {
  const callbackState = {};
  const reset = JSON.parse(JSON.stringify(config.shipmentTypesFields[params.value]));
  const group = JSON.parse(JSON.stringify(stateObject[params.groupName]));
  const quantity = JSON.parse(JSON.stringify(group[params.index].quantity));
  const shipmentType = JSON.parse(JSON.stringify(group[params.index].shipmentType));
  const weight = JSON.parse(JSON.stringify(group[params.index].weight));
  const dangerousGoodGroup = JSON.parse(JSON.stringify(group[params.index].dangerousGoodGroup));
  const goodsDescription = JSON.parse(JSON.stringify(group[params.index].goodsDescription));
  const shippingMark = JSON.parse(JSON.stringify(group[params.index].shippingMark));

  reset.quantity = quantity;
  reset.shipmentType = shipmentType;
  reset.weight = weight;
  reset.dangerousGoodGroup = dangerousGoodGroup;
  reset.goodsDescription = goodsDescription;
  reset.shippingMark = shippingMark;

  group[params.index] = reset;
  callbackState[params.groupName] = group;

  return callbackState;
};

import { useQuery } from '@tanstack/react-query';
import { apiRequest } from '../../globals';
import { apiLinks } from '../../config/api-config';
import { AUTHORIZATION } from '../../Login/constants';

export const userAccountsQueryKey = 'userAccounts';

export const fetchUserAccounts = async () => {
  const res = await apiRequest(apiLinks.getUsersAccountsAndProductsData, 'GET', null, null, false);
  if (res.status !== 200) {
    throw new Error(`Status: ${res.status} Message: ${res.status}`);
  }
  return res.data;
};

export const useGetUserAccounts = ({ select, options } = {}) => {
  const authenticated = localStorage.getItem('authenticated');
  const authorizationCookie = localStorage.getItem(AUTHORIZATION);

  return useQuery({
    queryKey: [userAccountsQueryKey],
    queryFn: fetchUserAccounts,
    select: select,
    enabled: Boolean(authenticated) && Boolean(authorizationCookie),
    ...options,
  });
};

import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Error } from 'components';
import { downloadApiRequest } from 'globals/utils/requests';
import { config } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { formatDate } from 'globals/utils/formatting';
import { Box } from '@mui/material';
import {
  ErrorTitleWrapper,
  SuccessTitleWrapper,
  WarningTitleWrapper,
} from '../../../globals/components/ThankYou/TitleWrapper';
import { TitleParagraph } from '../../../globals/components/ThankYou/TitleParagraph';
import ImportantRequirement from '../../../globals/components/ThankYou/ImportantRequirement';
import { composeFileName, redirect } from '../../../globals/components/ThankYou/ThankYou-helpers';
import { ChatWithUs } from '../../../components/fcp-components/ChatWithUs';
import { Button } from '../../../components/fcp-components/Button';
import { thankYouPageDatePresenter, toDateComponent } from '../../../globals/helpers/side-summary-helpers';

class ThankYouPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentList: [],
      PDFdownloadError: false,
    };
  }

  componentDidMount = () => {
    if (process.env.NODE_ENV !== 'test') window.scrollTo(0, 0);
    if (config.regEx.urlValidation.test(window.location.pathname.split('/')[1])) {
      let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '#thankYou';
      window.history.pushState({ path: newurl }, '', newurl);
    }
  };

  UNSAFE_componentWillMount() {
    const stateObj = {};

    let shipmentData = this.props.shipmentStatusData;
    if (shipmentData) {
      stateObj.bookingOrderResult = shipmentData.bookingOrderResult;
      stateObj.deliveryDate = shipmentData.deliveryDate;
      stateObj.fixedDate = shipmentData.fixedDate;
      stateObj.customs = shipmentData.customs;
      stateObj.isRedThankYouPage = shipmentData.redThankYouPage;
      stateObj.redThankYouPageErrorMessage = shipmentData.redThankYouErrorMessage;
      stateObj.pickupOrderMovedToNextDay = shipmentData.pickupOrderMovedToNextDay;
      stateObj.priorityServiceError = shipmentData.priorityServiceError;
      stateObj.shouldPickupRequestCondition = shipmentData.shouldPickupRequestCondition;
      stateObj.documentList =
        shipmentData.bookingOrderResult.reports.length >= 1
          ? this.getDocumentList(shipmentData.bookingOrderResult.reports)
          : false;
    }
    this.setState(stateObj);
  }

  getDocumentList = reports => {
    let documentList = [];
    reports.forEach(report => {
      documentList.push(report.name);
    });

    return documentList;
  };

  downloadPublicFile = async (fileName, index) => {
    if (!this.state.documentList.includes(fileName)) {
      return;
    }
    let url = apiLinks.downloadDocumentPublic.replace('{UniqueCode}', this.state.bookingOrderResult.uniqueCode);
    url = url.replace('{fileName}', fileName);

    await downloadApiRequest(url.toString(), 'GET').then(blob => {
      var file = new Blob([blob], {
        type: blob.type,
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
        return;
      }

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  render() {
    window.onhashchange = function () {
      window.location.reload();
    };
    let pickupDateCondition =
      this.state.bookingOrderResult.pickupDateTime && this.state.bookingOrderResult.pickupDateTime.indexOf('T') !== -1;
    return (
      <Translation>
        {t => (
          <Box
            sx={{
              maxWidth: '1365px',
              margin: 'auto',
              boxSizing: 'border-box',
              marginBottom: '15rem',
              marginTop: '2.5rem',
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box
              sx={{
                flex: 2,
              }}
            >
              <Box
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  padding: '2rem',
                }}
              >
                <Box
                  sx={{
                    padding: '2rem',
                  }}
                >
                  {!this.state.isRedThankYouPage && !this.state.pickupOrderMovedToNextDay && (
                    <SuccessTitleWrapper titleCode="general|Thank you for booking" />
                  )}
                  {!this.state.isRedThankYouPage && this.state.pickupOrderMovedToNextDay && <WarningTitleWrapper />}
                  {this.state.isRedThankYouPage && <ErrorTitleWrapper />}

                  <Box>
                    {this.state.pickupOrderMovedToNextDay && (
                      <p>
                        {t(
                          'general|Your order has been booked, but the date you selected was not available. The order has been moved to the',
                        )}
                        <strong> {t('general|NEXT BUSINESS DAY')}.</strong>
                      </p>
                    )}
                    {this.state.pickupOrderMovedToNextDay && (
                      <p>
                        {t(
                          'general|If there are any questions, please contact Customer Service for further information.',
                        )}
                      </p>
                    )}
                    {this.state.isRedThankYouPage && (
                      <>
                        <Error
                          name="thankyouPageError"
                          message={t(
                            'general|If there are any questions, please contact Customer Service for further information.',
                          )}
                        />

                        {this.state.priorityServiceError && (
                          <Error name="priorityServiceError" message={this.state.redThankYouPageErrorMessage} />
                        )}
                      </>
                    )}

                    {!this.state.isRedThankYouPage && (
                      <>
                        <TitleParagraph
                          label={t('general|Your shipment number is')}
                          value={this.state.bookingOrderResult && this.state.bookingOrderResult.shipmentNumber}
                          valueId={'thankYouPageShipmentNumber'}
                        />

                        {this.state.shouldPickupRequestCondition && this.state.bookingOrderResult.bookingNumber && (
                          <TitleParagraph
                            label={t('general|Your pickup order number is')}
                            value={this.state.bookingOrderResult.bookingNumber}
                            valueId={'thankYouPagePickupOrderNumber'}
                          />
                        )}

                        {this.state.shouldPickupRequestCondition && !this.state.pickupOrderMovedToNextDay && (
                          <>
                            {this.state.bookingOrderResult && pickupDateCondition && (
                              <TitleParagraph
                                label={t('general|Pickup scheduled for')}
                                value={formatDate(this.state.bookingOrderResult.pickupDateTime.split('T')[0])}
                                valueId={'thankYouPagePickupScheduledAt'}
                              />
                            )}
                            {toDateComponent(this.props.context.state, thankYouPageDatePresenter)}
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Box>

                {!this.state.isRedThankYouPage && (
                  <ImportantRequirement
                    state={this.state}
                    options={this.state.documentList}
                    fileNameComposer={composeFileName}
                    errorMessageCode="publicOrder|Something has gone wrong with the download. Try again later."
                    downloadFile={this.downloadPublicFile}
                  />
                )}
              </Box>

              {this.state.isRedThankYouPage && (
                <Box
                  sx={{
                    marginTop: ' 1.5rem',
                    alignItems: 'center',
                    display: 'flex',
                    backgroundColor: '#f2f2f2',
                    padding: '2rem',
                  }}
                >
                  <Button
                    id="backToReviewAndBook"
                    sx={{
                      color: '#d40511',
                      border: '1px solid #d40511',

                      marginRight: '1rem',
                      borderRadius: '3px',
                      padding: '0.75rem 1.25rem',
                      fontWeight: 600,
                      flex: 'none',
                      width: 'auto',
                    }}
                    onClick={() => {
                      this.props.enableOtherTabs();
                      this.props.thankYouPage();
                    }}
                  >
                    {t(`general|Back to Review & Book`)}
                  </Button>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                flex: '1',
                marginLeft: ' 2rem',
              }}
            >
              {!this.state.isRedThankYouPage && (
                <Box
                  sx={{
                    border: '1px solid #ccc',
                    borderRadius: '3px',
                    padding: '2rem',
                    backgroundColor: '#f2f2f2',
                  }}
                >
                  <h5>{t('general|Next Steps')}</h5>
                  <p>
                    {t(
                      `portalOrder|A summary and your shipment documents will be sent to your provided e-mail address. If you need to change or cancel your booking, call Customer Service on`,
                    )}{' '}
                    {t('general|customerServicePhoneNumber')} {t('general|or')} <ChatWithUs />
                  </p>
                </Box>
              )}
              <Box
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  padding: '2rem',
                  backgroundColor: '#f2f2f2',
                }}
              >
                <h5>{t('general|shipment|createAnotherShipment')}</h5>
                <Button
                  sx={{
                    color: '#d40511',
                    border: '1px solid #d40511',
                    marginRight: '1rem',
                    borderRadius: '3px',
                    padding: '0.75rem 1.25rem',
                    fontWeight: 600,
                    flex: 'none',
                  }}
                  onClick={redirect}
                  dataTestId="create-new-shipment-btn"
                >
                  {t(`general|Create a New Shipment`)}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Translation>
    );
  }
}

export default ThankYouPage;

import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DG_COLUMN_GAP, UN_NUMBER_WIDTH } from './DangerousGoods.constants';

const EMPTY_VALUE_PLACEHOLDER = '---';

export const DgRow = ({
  unNumber,
  psn,
  adrClass,
  packagingGroup,
  comment,
  preSelected,
  onPreSelect,
  sx,
  isExludedDangerousItem,
}) => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        ...sx,
      }}
      spacing={1}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: DG_COLUMN_GAP,
        }}
      >
        <Box sx={{ fontWeight: 'bold', width: UN_NUMBER_WIDTH }} data-testid="unNumber">
          {unNumber}
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ fontWeight: 'bold' }} data-testid="psn">
            {psn}
          </Box>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '32px' }}>
            <Box>
              {t('portalOrder|ADR Class')}: <strong>{adrClass || EMPTY_VALUE_PLACEHOLDER}</strong>
            </Box>
            <Box>
              {t('general|labels|inputs|Packaging Group')}: <strong>{packagingGroup || EMPTY_VALUE_PLACEHOLDER}</strong>
            </Box>
          </Box>
          <Box>{comment}</Box>
        </Box>
        <Button
          variant={preSelected ? 'contained' : 'outlined'}
          onClick={onPreSelect}
          sx={{ width: '120px', height: '48px', alignSelf: 'center' }}
          disabled={isExludedDangerousItem}
          data-testid="select-btn"
        >
          {t('general|labels|inputs|Select')}
        </Button>
      </Box>
      {isExludedDangerousItem ? (
        <Alert
          severity="error"
          variant="outlined"
          sx={{
            width: 'fit-content',
          }}
        >
          <Typography variant="body2" fontWeight="bold" color="error">
            {t('general|dangerousGoods|dangerousItemExlusionError')}
          </Typography>
        </Alert>
      ) : null}
    </Stack>
  );
};

import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { locales } from 'locale/datePicker';
import { config } from '../../data-config';
import { domesticHolidays } from 'globals/data-holidays';
import { DATE_FORMAT, formatDate } from 'globals/utils/formatting';
import getLanguage from 'globals/utils/getLanguage';
import { Error } from 'components';
import { checkFixedDateSelection } from '../../../globals/helpers/deliveryOptions-helpers';

export default function PickupDate({ context, onGetTimeTable, isReturnProduct = false, checkForFixedDate }) {
  const [selectedDate, setSelectedDate] = useState(
    context.state.pickupDate.value ? new Date(context.state.pickupDate.value) : '',
  );

  useEffect(() => {
    if (formatDate(context.state.pickupDate.value) !== formatDate(selectedDate)) {
      setSelectedDate(new Date(context.state.pickupDate.value));
    }
    // eslint-disable-next-line
  }, [new Date(context.state.pickupDate.value)]);

  const handleChange = (date, e) => {
    e.preventDefault();

    const params = {
      name: 'pickupDate',
      value: date || new Date(),
      error: context.state.pickupDate.error,
      afterUpdate: () => {
        setSelectedDate(date ? new Date(date) : new Date());
      },
    };

    context.updateField(params);

    if (date !== null) onGetTimeTable(date);
  };

  const makeTimeTableCallWhenDateIsRemovedManually = e => {
    if (selectedDate && !e.target.value) {
      onGetTimeTable(new Date(formatDate(selectedDate)));
    }
  };

  const language = getLanguage(config.regEx.urlValidation);
  const minDate = new Date();
  const maxDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
  const holidays = domesticHolidays || [];
  const disabled = checkFixedDateSelection(context, checkForFixedDate);

  return (
    <Translation>
      {t => (
        <>
          <div className="margin-bottom-0">
            <h4 className="margin-bottom-1 frc__red-lined-title">
              {isReturnProduct ? t('general|Shipping Date') : t('general|Pickup Date')}
            </h4>
            <DatePicker
              id={'shippingOrPickupDate'}
              autoComplete="off"
              disabled={disabled}
              selected={selectedDate}
              className="c-form--element-base c-form--element-input c-calculator--input-field"
              showDisabledMonthNavigation
              minDate={minDate}
              maxDate={context.state.desiredPickupDates.length > 0 ? null : maxDate}
              onChange={(date, e) => handleChange(date, e)}
              locale={locales[language]}
              dateFormat={DATE_FORMAT}
              onBlur={makeTimeTableCallWhenDateIsRemovedManually}
              dayClassName={date =>
                holidays.indexOf(
                  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0],
                ) > -1
                  ? 'color-DHL-red'
                  : undefined
              }
              includeDates={context.state.desiredPickupDates.length > 0 ? context.state.desiredPickupDates : null}
            />

            {!context.state.pickupDate.value && (
              <Error
                name="shippingOrPickupDateError"
                className="frc__input--error"
                message={t('general|errors|Please select date')}
              />
            )}
          </div>
        </>
      )}
    </Translation>
  );
}

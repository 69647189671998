import { useTranslation } from 'react-i18next';
import { formatBrowserDateTimeReadable } from 'globals/utils/formatting';
import { LoginOutlined, RunningWithErrorsOutlined, WarningOutlined } from '@mui/icons-material';

import { Box } from '@mui/material';

export const UserStatictics = ({ userData }) => {
  const { t } = useTranslation();
  if (!userData) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: '10px',
        }}
      >
        <RunningWithErrorsOutlined sx={{ mr: '10px', fontSize: '25px' }} />
        <strong>{t(`editProfile|Number of failed logins`)}</strong>: {userData.user.failedLoginCount ?? '-'}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: '10px',
        }}
      >
        <LoginOutlined sx={{ mr: '10px', fontSize: '25px' }} />
        <strong>{t(`editProfile|Last Login`)}</strong>:{' '}
        {userData.user.lastLogin ? formatBrowserDateTimeReadable(userData.user.lastLogin) : '-'}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <WarningOutlined sx={{ mr: '10px', fontSize: '25px' }} />
        <strong>{t(`editProfile|Last Unsuccessful Login`)}</strong>:{' '}
        {userData.user.lastFailedLogin ? formatBrowserDateTimeReadable(userData.user.lastFailedLogin) : '-'}
      </Box>
    </>
  );
};

import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Input } from 'components';
import { config, CONST } from '../../../data-config';
import { Error } from 'components';
import ServicePointCard from './ServicePointCard';
import { HEREMap } from '../../HEREMap/HEREMap';
import { getServicePoints, getTimeTable } from '../DeliveryOptions-helpers';
import { setDisabledAndResetVas } from '../../../../portal-order/components/DeliveryOptions/DeliveryOptions-helpers';

import './ServicePointLocatorDialog.css';
import {
  CONSIGNEE_CUSTOMER_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  SERVICE_POINT_PRODUCT_CODE,
} from '../../../../globals/constants';

export default class ServicePointLocatorDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      postalCode: { value: '', name: 'postalCode', error: false },
      street: { value: '', name: 'street', error: false },
      countryCode: this.props.context.state.deliveryCountry.value,
      mapCenter: { lat: CONST.DEFAULT_MAP_LAT, lng: CONST.DEFAULT_MAP_LNG },
    };
  }

  componentDidMount() {
    this.centerMap();
    this.props.context.updateState({ markedServicePoint: { ...this.props.context.state.selectedServicePoint } });
  }

  update = params => {
    let stateToSet = {};

    stateToSet[params.name] = {
      value: params.value,
      name: params.name,
    };

    this.setState(stateToSet);
  };

  centerMap = () => {
    let location = {};

    if (
      this.props.context.state.selectedServicePoint &&
      this.props.context.state.selectedServicePoint.geo &&
      !this.props.context.state.servicePointsError &&
      !this.props.context.state.servicePointUseResidentialAddress.value
    ) {
      location.lat = this.props.context.state.selectedServicePoint.geo.latitude;
      location.lng = this.props.context.state.selectedServicePoint.geo.longitude;
      this.setState({ mapCenter: location });
    } else if (
      this.props.context.state.servicePoints &&
      this.props.context.state.servicePoints[0] &&
      !this.props.context.state.servicePointsError
    ) {
      location.lat = this.props.context.state.servicePoints[0].geo.latitude;
      location.lng = this.props.context.state.servicePoints[0].geo.longitude;
      this.setState({ mapCenter: location });
    }
  };

  findServicePoints = async () => {
    const pieces = [];

    this.props.context.state.shipmentDetailsRows.forEach(row => {
      const obj = {
        length: row.length?.value || null,
        height: row.height.value || null,
        weight: row.weight.value || null,
        width: row.width.value || null,
        numberOfPieces: row.quantity.value || null,
      };
      pieces.push(obj);
    });

    const params = {
      dhlProductCode:
        this.props.context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE
          ? '109'
          : SERVICE_POINT_PRODUCT_CODE,
      pieces: pieces,
      parties: [
        {
          address: {
            cityName: null,
            countryCode: this.state.countryCode,
            postalCode: this.state.postalCode.value || null,
            street: this.state.street.value || null,
            streetNumber: null,
            locationType: null,
            additionalAddressInfo: null,
          },
          type: CONSIGNEE_CUSTOMER_TYPE,
        },
      ],
    };

    const stateToSet = await getServicePoints(params, this.props.context, false, false);

    this.props.context.updateState(stateToSet, () => {
      if (!stateToSet.servicePointsError && stateToSet.servicePoints && stateToSet.servicePoints.length > 0) {
        this.centerMap();
      }
    });
  };

  markServicePoint = params => {
    const stateToSet = {};

    stateToSet.mapZoom = CONST.DEFAULT_MAP_ZOOM;
    stateToSet.mapCenter = {};
    stateToSet.mapCenter.lat = this.props.context.state.servicePoints[params.index].geo.latitude;
    stateToSet.mapCenter.lng = this.props.context.state.servicePoints[params.index].geo.longitude;

    this.setState(stateToSet);
    this.props.context.updateState({
      markedServicePoint: { ...this.props.context.state.servicePoints[params.index], index: params.index },
    });
  };

  selectServicePoint = params => {
    const servicePointUseResidentialAddress = JSON.parse(
      JSON.stringify(this.props.context.state.servicePointUseResidentialAddress),
    );
    servicePointUseResidentialAddress.value = false;

    if (params.servicePoint) {
      const spIndex =
        params.index || this.props.context.state.servicePoints.findIndex(sp => sp.id === params.servicePoint.id);
      const selectedServicePoint = JSON.parse(JSON.stringify(this.props.context.state.servicePoints[spIndex]));
      const { additionalServices } = this.props.context.state;
      let modifiedVas = structuredClone(additionalServices);

      if (params?.servicePoint?.locationType === 'locker') {
        modifiedVas = setDisabledAndResetVas(additionalServices, config.servicePointLockerDisabledVas);
      } else {
        modifiedVas = setDisabledAndResetVas(additionalServices, config.servicePointLockerDisabledVas, false);
      }

      this.props.context.updateState(
        {
          selectedServicePoint: selectedServicePoint,
          servicePointUseResidentialAddress,
          hereLoading: true,
          additionalServices: modifiedVas,
        },

        async () => {
          this.centerMap();
          this.props.closeDialog();
          const stateObj = { hereLoading: false };

          Object.assign(
            stateObj,
            await getTimeTable(this.props.context.state.pickupDate.value, this.props.context, false, false, false),
          );

          this.props.context.updateState(stateObj);
        },
      );
    } else {
      this.props.context.updateState({ selectedServicePoint: params, servicePointUseResidentialAddress }, () => {
        this.props.closeDialog();
      });
    }
  };

  onBackgroundClick = e => {
    if (e.target.classList.contains('frc__dialog-holder')) {
      this.props.closeDialog();
    }
  };

  // Returns array of objects containing service points lat and lang
  getMarkers = () => {
    return this.props.context.state.servicePoints.map(servicePoint => {
      return { lat: servicePoint.geo.latitude, lng: servicePoint.geo.longitude };
    });
  };

  render() {
    return (
      <>
        <Translation>
          {t => (
            <>
              <div className="frc__dialog">
                <div className="frc__dialog-overlay" onClick={this.props.closeDialog}></div>
                <div className="frc__dialog-wrapper">
                  <div
                    className="l-grid l-grid--center-s l-grid--middle-s frc__dialog-holder"
                    onClick={this.onBackgroundClick}
                  >
                    <div className="frc__dialog-container l-grid--w-100pc-s l-grid--w-70pc-m l-grid--left-s">
                      <div className="frc__dialog-close-button">
                        <button className="frc__button--delete--darker" onClick={this.props.closeDialog}>
                          X
                        </button>
                      </div>
                      <div className="frc__dialog-content">
                        <h2>{t(`general|Choose a new Service Point`)}</h2>
                        <p>{t(`general|Enter postal code and street to search for another Service Point`)}:</p>

                        <div className="l-grid">
                          <div className=" l-grid--w-100pc-s l-grid--w-50pc-m">
                            <Input
                              context={this.props.context}
                              config={config}
                              CONST={CONST}
                              regEx={config.regEx.number}
                              label={t('general|labels|inputs|Postalcode')}
                              name="postalCode"
                              dataTestId="postalCodeInp"
                              isRequired={false}
                              value={this.state.postalCode.value}
                              lengthCheck={[RegExp(`^.{1,${config.maxPostalCode}}$`)]}
                              cutTextLimit={config.maxPostalCode}
                              updateOnParent={params => this.update(params)}
                              onKeyPress={this.findServicePoints}
                            />
                            {this.state.postalCode.error ? (
                              <Error
                                name="postalCode"
                                className="frc__input--error"
                                message={t('general|errors|Postalcode Error', { max: config.maxPostalCode })}
                              />
                            ) : (
                              ''
                            )}
                          </div>

                          <div className=" l-grid--w-100pc-s l-grid--w-50pc-m">
                            <Input
                              context={this.props.context}
                              config={config}
                              CONST={CONST}
                              regEx={config.regEx.everything}
                              label={t('general|labels|inputs|Street')}
                              name="street"
                              isRequired={false}
                              value={this.state.street.value}
                              lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                              cutTextLimit={config.maxDefaultInputLength}
                              updateOnParent={params => this.update(params)}
                              onKeyPress={this.findServicePoints}
                            />
                            {this.state.street.error ? (
                              <Error
                                name="street"
                                className="frc__input--error"
                                message={t('general|errors|Street Error', { max: config.maxDefaultInputLength })}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        {this.props.context.state.servicePointsError ? (
                          <div className="l-grid--w-100pc">
                            <Error
                              name="streetError"
                              className="frc__input--error"
                              message={t('general|Sorry, no service points for this location')}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="l-grid l-grid--right-s frc__generic--field-wrapper">
                          <button
                            className={`base-button base-button--wide frc__generic-button frc__add-button ${
                              this.props.context.state.hereLoading ? 'is-loading--right' : ''
                            }`}
                            onClick={() => this.findServicePoints()}
                          >
                            {t('general|labels|Search')}
                          </button>
                        </div>

                        <div className="l-grid frc__generic--field-wrapper">
                          <div className="frc__card-list-container l-grid--w-40pc-m">
                            {this.props.context.state.servicePoints.map((servicePoint, ind) => (
                              <ServicePointCard
                                index={ind}
                                key={ind}
                                servicePoint={servicePoint}
                                selectServicePoint={this.selectServicePoint}
                                markServicePoint={this.markServicePoint}
                              />
                            ))}
                          </div>

                          <div className="here-maps-container l-grid--w-60pc-m">
                            <HEREMap mapCenter={this.state.mapCenter} markers={this.getMarkers()} />

                            {this.props.context.state.markedServicePoint ? (
                              <div className="frc__marker-card-wrapper">
                                <div className="frc__marker-card-container">
                                  <h5 className="margin-bottom-0">
                                    {this.props.context.state.markedServicePoint.name}
                                  </h5>
                                  {this.props.context.state.markedServicePoint.partyName ? (
                                    <div className="frc__uppercase">
                                      {this.props.context.state.markedServicePoint.partyName}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div>{this.props.context.state.markedServicePoint.street}</div>
                                  <div className="margin-bottom-0">
                                    {this.props.context.state.markedServicePoint.countryCode
                                      ? this.props.context.state.markedServicePoint.countryCode + '-'
                                      : ''}
                                    {this.props.context.state.markedServicePoint.postalCode},{' '}
                                    {this.props.context.state.markedServicePoint.cityName}
                                  </div>
                                  <button
                                    className="base-button base-button--white base-button--wide frc__narrow-button"
                                    onClick={() =>
                                      this.selectServicePoint({
                                        servicePoint: this.props.context.state.markedServicePoint,
                                        index: this.props.context.state.markedServicePoint?.index,
                                      })
                                    }
                                  >
                                    <span>{t('general|labels|inputs|Select')}</span>
                                  </button>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Translation>
      </>
    );
  }
}

import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';
import { exportCSV, filterMatchedAddressResults } from './AddressBookSearch-helpers';
import { onAddressSearch } from '../ActionUtils';
import { Input } from 'components';
import { Error } from 'components';
import ImportContactModal from '../ImportContactModal/ImportContactModal';

import './AddressBookSearch.css';

export default class AddressBookSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      downloadCSVError: false,
      isModalOpen: false,
      modalError: false,
      isExportDownloadLoading: false,
    };
  }

  toggleImportContact = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      modalError: false,
    }));
  };

  onExportCSV = async t => {
    this.setState({ isExportDownloadLoading: true }, async () => {
      const csvError = await exportCSV();

      if (csvError) {
        this.setState(
          {
            downloadCSVError: true,
            isExportDownloadLoading: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                downloadCSVError: false,
              });
            }, 5000);
          },
        );
      } else {
        this.props.onShowSuccessToaster('Contacts Downloaded Successfully');
        this.setState({ isExportDownloadLoading: false });
      }
    });
  };

  searchAddress = e => {
    e.preventDefault();

    setTimeout(() => {
      onAddressSearch(this.props.context);
    }, 200);
  };

  render() {
    const { context } = this.props;

    return (
      <Translation>
        {t => (
          <>
            <div data-testid="AddressBookSearch" className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
              <div className=" l-grid--w-100pc-s l-grid--w-55pc-m l-grid--w-100pc-m-s">
                <form
                  onSubmit={this.searchAddress}
                  className="l-grid l-grid--center-s l-grid--center-m-s l-grid--left-m-m l-grid--bottom-s l-grid--w-100pc-s "
                >
                  <div className="l-grid--w-100pc-s l-grid--w-70pc-m l-grid--left-s margin-bottom-1">
                    <Input
                      context={context}
                      disableonBlur={true}
                      config={config}
                      CONST={CONST}
                      tooltip={t('general|labels|Search by ID, Company Name, Name, Street, City, Note')}
                      regEx={config.regEx.everything}
                      name="searchTerm"
                      dataTestId="searchAddressBookInp"
                      hideAutoComplete={true}
                      isRequired={context.state.searchTerm.isRequired}
                      label={t('general|labels|Search')}
                      className="frc__input--wrapper frc__generic--field-wrapper"
                      value={context.state.searchTerm.value}
                      lengthCheck={[RegExp(`^.{${0},${config.maxSearchTermDigits}}$`)]}
                      cutTextLimit={config.maxSearchTermDigits}
                      afterUpdate={context => filterMatchedAddressResults(context, t)}
                      placeholder={t('general|labels|Search by ID, Company Name, Name, Street, City, Note')}
                    />
                  </div>
                  <div className="frc__address-search--button l-grid--w-100pc-s l-grid--w-10pc-m">
                    <button className={'base-button  l-grid--w-100pc-s '} id="searchAddressBtn">
                      <span>{t('general|labels|Search')}</span>
                    </button>
                  </div>
                </form>
              </div>

              <div className="l-grid--center-s l-grid--w-100pc-s l-grid--w-45pc-m l-grid--w-100pc-m-s frc__address-search--button">
                <button
                  className="base-button  l-grid--w-100pc-s l-grid--w-30pc-m l-grid--w-30pc-m-s has-right-margin-1"
                  id="importContactsBtn"
                  onClick={this.toggleImportContact}
                >
                  <span>{t(`portalAddressBook|Import Contacts`)}</span>
                </button>

                <button
                  className={
                    'base-button base-button--wide l-grid--w-100pc-s l-grid--w-30pc-m l-grid--w-30pc-m-s has-right-margin-1' +
                    (this.state.isExportDownloadLoading ? ' disabled is-loading--right ' : '')
                  }
                  id="exportCSVBtn"
                  onClick={() => this.onExportCSV(t)}
                  disabled={this.state.isExportDownloadLoading}
                >
                  <span>{t(`portalAddressBook|Export Contacts`)}</span>
                </button>

                <button
                  className={'base-button base-button--wide l-grid--w-100pc-s l-grid--w-30pc-m l-grid--w-30pc-m-s '}
                  id="addNewContactBtn"
                  onClick={this.props.onShowContact}
                >
                  <span>{t(`portalAddressBook|Add New Contact`)}</span>
                </button>

                {this.state.downloadCSVError && (
                  <Error
                    name={'csvDownloadError'}
                    className="frc__input--error"
                    message={t('general|Some error occured. Try later.')}
                  />
                )}
              </div>
            </div>

            {this.state.isModalOpen && (
              <ImportContactModal
                context={context}
                error={this.state.modalError}
                onCloseModalAction={this.toggleImportContact}
              />
            )}
          </>
        )}
      </Translation>
    );
  }
}

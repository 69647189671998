import { t } from 'i18next';
import { capitalize } from '../../utils/strings';
import { FILE_NAME_RETURN_LABEL } from '../../../utils/files';

/**
 * Redirect to a common url.
 * */
export const redirect = () => {
  const urlRegexPattern = /^[A-Za-z]{2}/g;

  if (urlRegexPattern.test(window.location.pathname.split('/')[1])) {
    window.location.href = window.location.href.split('#')[0]
      ? encodeURI(window.location.href.split('#')[0])
      : encodeURI(window.location.href);
  }
};

export const composeFileName = (state, documentFileName) => {
  const shortDocumentFileName = documentFileName.substring(0, documentFileName.lastIndexOf('_'));
  if (
    state.bookingOrderResult &&
    state.bookingOrderResult.returnShipmentNumber &&
    documentFileName.includes(state.bookingOrderResult.returnShipmentNumber)
  ) {
    return capitalize(shortDocumentFileName) + ' (' + t(`portalOrder|Return shipment`) + ')';
  }
  if (shortDocumentFileName?.toLowerCase() === FILE_NAME_RETURN_LABEL) {
    return t('general|returnLabel');
  }
  return capitalize(shortDocumentFileName);
};

import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import AdditionalServiceRow from './AdditionalServiceRow/AdditionalServiceRow';
import { config, CONST } from '../../data-config';
import { scrollupToShowError } from './AdditionalServices-helpers';

import './AdditionalServices.css';

export default class AdditionalServices extends Component {
  checkForPriorityService = additionalServiceRow => {
    if (additionalServiceRow.code.indexOf('priorityService') !== -1) {
      return (
        !this.props.context.state.priorityServiceDisabled &&
        this.doBothPostalCodesHaveRequiredLength() &&
        this.getMatchesForGServices(additionalServiceRow.code, this.props.context.state)
      );
    }

    return true;
  };

  getMatchesForGServices = (priorityService, ctxState) => {
    if (priorityService.indexOf('G7') !== -1) {
      return ctxState.G7Matches;
    } else if (priorityService.indexOf('G10') !== -1) {
      return ctxState.G10Matches;
    } else if (priorityService.indexOf('G12') !== -1) {
      return ctxState.G12Matches;
    } else {
      return true;
    }
  };

  doBothPostalCodesHaveRequiredLength = () =>
    this.props.context.state.pickupPostalCode.value.length >= CONST.MIN_POSTALCODE_NUMBER &&
    this.props.context.state.deliveryPostalCode.value.length >= CONST.MIN_POSTALCODE_NUMBER;

  checkIfAllPriorityServicesFieldsAvailable = () =>
    !this.props.context.state.deliveryReceiver.value ||
    !this.props.context.state.deliveryStreet.value ||
    !this.props.context.state.deliveryCity.value;

  componentDidUpdate = () => {
    const context = this.props.context;
    let mandatoryFields = false;

    if (context.state.additionalServices && context.state.additionalServices.length > 0) {
      context.state.additionalServices.forEach(additionalService => {
        if (
          additionalService.code.indexOf('priorityService') !== -1 &&
          additionalService.value &&
          this.doBothPostalCodesHaveRequiredLength()
        ) {
          mandatoryFields = true;
        }
      });

      if (mandatoryFields !== context.state.deliveryMandatoryByPriorityService) {
        context.updateState({
          deliveryMandatoryByPriorityService: mandatoryFields,
        });
      }
    }
  };

  render() {
    const doBothPostalCodesHaveRequiredLength = this.doBothPostalCodesHaveRequiredLength();
    const checkIfAllPriorityServicesFieldsAvailable = this.checkIfAllPriorityServicesFieldsAvailable();

    return (
      <Translation>
        {t => (
          <>
            {!this.props.context.state.shippingToMultipleAddresses.value &&
            this.props.context.state.selectedProducts &&
            this.props.context.state.selectedProducts.length === 1 ? (
              <div className="frc__additional-services--wrapper frc__generic-section--wrapper ">
                <div>
                  {this.props.context.state.additionalServices.length > 0 ? (
                    <>
                      <p
                        className="margin-bottom-1"
                        dangerouslySetInnerHTML={{
                          __html: t('general|Select any optional <strong>Additional Services</strong> that apply'),
                        }}
                      />
                      {doBothPostalCodesHaveRequiredLength && this.props.context.state.priorityServiceDisabled && (
                        <p>
                          <strong>{t('general|Note')}: </strong>
                          {t('portalPickup|Priority Service is not available for chosen postal codes')}
                        </p>
                      )}
                      {this.props.context.state.deliveryMandatoryByPriorityService &&
                        doBothPostalCodesHaveRequiredLength &&
                        checkIfAllPriorityServicesFieldsAvailable && (
                          <p onClick={() => scrollupToShowError()}>
                            <strong>{t('general|Note')}: </strong>
                            <span className="frc__button_underline">
                              {' '}
                              {t('portalPickup|Please enter full receiver details to be able to use Priority Service')}
                            </span>
                          </p>
                        )}
                      {!doBothPostalCodesHaveRequiredLength && (
                        <p>
                          <strong>{t('general|Note')}: </strong>
                          {t(
                            `general|Fill properly postal codes inputs to see whether you can use Priority Service or not`,
                          )}
                        </p>
                      )}
                    </>
                  ) : (
                    ''
                  )}

                  <div className="l-grid l-grid--w-100pc-s">
                    {Array.isArray(this.props.context.state.additionalServices) &&
                      this.props.context.state.additionalServices &&
                      this.props.context.state.additionalServices.map((additionalServiceRow, ind) => (
                        <React.Fragment key={ind}>
                          {this.checkForPriorityService(additionalServiceRow) && (
                            <AdditionalServiceRow
                              key={ind}
                              index={ind}
                              additionalServiceRow={additionalServiceRow}
                              error={additionalServiceRow.error}
                              context={this.props.context}
                              config={config}
                              CONST={CONST}
                            />
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                </div>
                <div
                  className={
                    'frc__generic--loader-overlay' +
                    (this.props.context.state.loaders.additionalServices.value ? ' is-loading ' : '')
                  }
                />
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </Translation>
    );
  }
}

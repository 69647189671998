import React, { Component } from 'react';
import { PublicOrderContext, PublicOrderProvider } from '../../context/context';
import AppTabs from '../AppTabs/AppTabs';
import { Typography } from '@mui/material';

import './../../css/global/_variables.css';
// import './../../css/global/_icons.css';
// import './../../css/base/iconfont.css';
// import './../../css/base/react-datepicker.css';

import './AppPublicOrder.css';
import 'globals/utils/debugHelpers';
import { GeneralError } from 'components';
import cssVars from 'css-vars-ponyfill';
import { Translation } from 'react-i18next';

const ENV_DEV = process.env.NODE_ENV === 'development';
export default class AppPublicOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
      shipmentStatusData: {},
    };
  }
  componentDidMount() {
    cssVars();
  }

  onThankYouPage = result => {
    if (result !== undefined) {
      this.setState({
        status: true,
        shipmentStatusData: result,
      });
    }
  };

  render() {
    return (
      <Translation>
        {t => (
          <div className={ENV_DEV ? 'l-view' : ''}>
            <div className="c-page-headline component-small has-rte component-margin frc__heading">
              <div className="c-page-headline--wrapper">
                <Typography variant="h1" mt={{ xs: 0, sm: 2 }}>
                  {t('general|pageTitles|orderBooking')}
                </Typography>
              </div>
            </div>
            <PublicOrderProvider>
              <div className="frc__app">
                <PublicOrderContext.Consumer>
                  {context => (
                    <>
                      {t => <GeneralError context={context} message={t('general|errors|General error')} />}
                      <AppTabs thankYouPage={this.onThankYouPage} context={context} />
                    </>
                  )}
                </PublicOrderContext.Consumer>
              </div>
            </PublicOrderProvider>
          </div>
        )}
      </Translation>
    );
  }
}

import { OpenInNew } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/material';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'router';

import { getBaseUrl, getTransactionId } from '../../../globals';
import { useCountryLanguage } from '../../../hooks/useCountryLanguage';
import { DEFAULT_EN_LANGUAGE } from '../../../locale/config';
import { useRouting } from '../../../router/useRouting';
import { useCaptcha } from '../Captcha';
import { ProfileForm } from './RegistrationForm';

const ENV_DEV = process.env.NODE_ENV === 'development';

export const RegistrationPage = () => {
  const { t } = useTranslation();
  const { navigate } = useRouting();
  const countryLanguage = useCountryLanguage();

  const [submitError, setSubmitError] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const captcha = useCaptcha();

  useEffect(() => {
    const lang = countryLanguage.language;
    const country = countryLanguage.country;

    if (lang === 'en' && country !== 'se') {
      // registration Page could not be in English in other countries, SE-EN only
      if (process.env.REACT_APP_VERBOSE_LOGS?.toLowerCase() === 'true' && ENV_DEV) {
        console.log('redirecting to English default registration page');
      }
      navigate('../../' + DEFAULT_EN_LANGUAGE + '/' + ROUTES.registration, { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const handleCaptchaError = setValue => {
    const resolveCaptcha = async () => {
      setSubmitError(t('general|errors|Please enter captcha code'));
      await captcha.loadCaptcha();
      setSubmitInProgress(false);
      setValue('captchaToken', '');
    };
    resolveCaptcha();
  };

  const submitRegistration = (data, setValue) => {
    const submitData = async () => {
      try {
        setSubmitInProgress(true);
        setSubmitError(false);
        const phoneNumbers = (data.phoneNumbers = [
          { number: `${data.companyPhoneCountryCode.phone}-${data.companyPhoneNumber}` },
        ]);
        const tmsAccounts = data.accountList.map(acc => ({
          accountNumber: acc.accountNumber,
          accountReference: acc.accountReferenceName,
        }));

        const getLanguage = () => {
          try {
            const lsLanguage = localStorage.getItem('language') || 'en';
            return lsLanguage;
          } catch (error) {
            return 'en';
          }
        };

        const requestData = {
          tmsAccounts,
          user: {
            accountCountryCode: data.accountCountry.value?.toLowerCase(),
            city: data.companyCity,
            companyName: data.company,
            country: data.companyCountry.label,
            countryCode: data.companyCountry.value?.toLowerCase(),
            email: data.contactEmail,
            name: data.contactName,
            language: getLanguage(),
            phoneNumbers,
            postalCode: data.companyPostCode,
            street: data.companyStreetAddress,
            vatNumber: data.companyVatNumber,
          },
          captcha: {
            answer: data.captchaCode,
            token: data.captchaToken,
          },
        };

        const result = await apiRequest(apiLinks.postUser, 'POST', {
          body: requestData,
          headers: {
            Referer: getBaseUrl(null, 1),
            transactionId: getTransactionId('REG'),
          },
        });

        if (result.status === 403) {
          handleCaptchaError(setValue);
        } else if (result.status === 200 || result.status === 201) {
          setSubmitInProgress(false);
          navigate(ROUTES.registrationThankYou);
        } else {
          console.error('Registration submit error - result:', result);
          setSubmitError(t('registration|errorOccurred'));
          setSubmitInProgress(false);
        }
      } catch (error) {
        if (error?.status === 403) {
          handleCaptchaError(setValue);
        } else {
          console.error('Registration caught - error:', error);
          setSubmitError(t('registration|errorOccurred'));
          setSubmitInProgress(false);
        }
      }
    };
    submitData();
  };

  return (
    <>
      <Box
        sx={{
          paddingTop: '74px',
        }}
      >
        <Typography variant="h1">{t('registration|title')}</Typography>
        <Typography variant="body1" textAlign="center">
          {t(
            `registration|Enable your access to MyDHL Freight. Access our online portal with your DHL Freight Account Number`,
          )}
          {t(`registration|Don’t have an account?`)}{' '}
          <Link
            href={t('pageLinks|becomeACustomerUrl')}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ display: 'inline-flex', alignItems: 'center' }}
          >
            {t(`registration|Become a customer`)}
            <OpenInNew fontSize="16px" sx={{ ml: '0.75rem' }} />
          </Link>
        </Typography>
        <ProfileForm
          captcha={captcha}
          enableAccountCountryEdit
          enableAccountEditing
          enableEmailEdit
          onSubmit={submitRegistration}
          showApprovedAccounts={false}
          showForgotAccountNumber
          showRegistrationHints
          showTermsAndCaptcha
          submitError={submitError}
          submitInProgress={submitInProgress}
          submitLabel={t('general|Submit')}
          headerCountryCode="se"
        />
      </Box>
    </>
  );
};

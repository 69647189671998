import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPropperAPIData } from 'globals/utils/requests';
import { config, CONST } from '../../../../data-config';
import { beforeCheck, afterCheck } from '../ServiceDetails';
import { Radio, Loader } from 'components';
import { useGetCountryConfig } from '../../../../../hooks/api/useGetCountryConfig';
import { useGetUserAccountsAndProductsQuery } from '../../../../../store/api/fcpUser';
import { useGetProductsQuery } from '../../../../../store/api/fcpProduct';
import { DOMESTIC_SHIPMENT_RANGE } from '../../../../../globals/constants';
import { getOptionalTranslation } from '../../../../../globals/utils/translations';

export default function SelectService({ context }) {
  const { t } = useTranslation();
  const { data: countryConfig, isLoading: countryConfigLoading } = useGetCountryConfig();
  const { data: accountsWithProductsAndSpecs, isLoading: accountsLoading } = useGetUserAccountsAndProductsQuery();
  const { data: products, isLoading: productsLoading } = useGetProductsQuery({
    countryCode: context.state.userData?.accountCountryCode?.toLowerCase(),
  });

  const isLoading = accountsLoading || productsLoading || countryConfigLoading;

  const updateProduct = (params, context, paramGroupName, countryConfig) => {
    const ctxParams = {};
    const homeDeliveryStateObj = {};
    const stateObj = {
      additionalServices: [],
      additionalServicesError: false,
      additionalServicesAPIError: false,
    };

    const product = JSON.parse(JSON.stringify(context.state[paramGroupName]));

    product.value = params.value;
    product.error = params.value === '';

    if (product.value) {
      if (config.homeDeliveryProductCodes.indexOf(params.value) > -1) {
        const propperObj = getPropperAPIData(context.state);

        if (config.homeDeliveryMapping[params.value].deliveryType === 'Return') {
          homeDeliveryStateObj.cityName = propperObj.pickupCity ? propperObj.pickupCity.value : '';
          homeDeliveryStateObj.postalCode = propperObj.pickupPostalCode ? propperObj.pickupPostalCode.value : '';
        } else {
          homeDeliveryStateObj.cityName = propperObj.deliveryCity ? propperObj.deliveryCity.value : '';
          homeDeliveryStateObj.postalCode = propperObj.deliveryPostalCode ? propperObj.deliveryPostalCode.value : '';
        }

        if (propperObj.payerAccount && homeDeliveryStateObj.cityName && homeDeliveryStateObj.postalCode) {
          ctxParams.beforeUpdate = beforeCheck;
          ctxParams.afterUpdate = afterCheck(context, product.value, countryConfig);
        }
      } else {
        ctxParams.beforeUpdate = beforeCheck;
        ctxParams.afterUpdate = afterCheck(context, product.value, countryConfig);
      }
    }

    stateObj[paramGroupName] = product;

    context.extendedSetState(stateObj, ctxParams);
  };

  const isDomesticShipment = context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE;

  const getProductDisabled = ({ product: { isDomestic, code }, account }) => {
    if (isDomestic !== isDomesticShipment) return true;

    const accountHasProduct = accountsWithProductsAndSpecs?.accounts
      ?.find(acc => acc.accountNumber === account)
      ?.products?.some(accountProduct => accountProduct.productCode === code);

    if (account && !accountHasProduct) return true;

    return false;
  };

  const getProductTooltip = productCode => {
    const tooltipKey = t(`products|${productCode}|tooltip`);
    const resolvedTranslation = getOptionalTranslation(tooltipKey);

    return resolvedTranslation || t(`products|${productCode}|name`);
  };

  return (
    <div className="frc__generic-row--wrapper">
      <h4 className="frc__red-lined-title">{t('general|Select a Service')}</h4>
      <div className="l-grid">
        {isLoading && <Loader />}
        {!isLoading &&
          products &&
          products
            ?.filter(product => !config.productsFilteredAtProductSelection.includes(product.code))
            .map(product => (
              <div
                key={product.code}
                className="frc__label margin-bottom-1 l-grid--w-100pc-s  l-grid--w-50pc-m-s l-grid--w-33pc-m-m"
              >
                <Radio
                  label={t(`products|${product.code}|name`)}
                  isBooleanValueRequired
                  id={product.code}
                  name={'product'}
                  tooltip={getProductTooltip(product.code)}
                  checkBox
                  compareValue={context.state.product.value}
                  disabled={getProductDisabled({
                    product,
                    account: context.state.accountNumber.value,
                  })}
                  checked={context.state.product.value === product.code}
                  value={product.code}
                  context={context}
                  config={config}
                  CONST={CONST}
                  updateOnParent={(params, context) => updateProduct(params, context, 'product', countryConfig)}
                />
              </div>
            ))}
      </div>
    </div>
  );
}

import { apiLinks } from 'config/api-config';
import { returnProperValueOrNull } from 'globals/utils/fieldValidations';
import { fixDecimalPointAndConvertToNumber, formatDate, toFloatOrEmptyString } from 'globals/utils/formatting';
import { apiRequest, getCookie } from 'globals/utils/requests';
import { CONSIGNEE_CUSTOMER_TYPE, CONSIGNOR_CUSTOMER_TYPE, DOMESTIC_SHIPMENT_RANGE } from '../../../globals/constants';
import { getQuoteForPriceAPICall } from '../../../globals/helpers/delivery-options';
import { hasGlobalColliError } from '../../../globals/helpers/shipment-validations';
import { config, CONST } from '../../data-config';
import { createQuoteForPricePayloadBody, formQuoteResultMap } from '../DeliveryOptions/DeliveryOptions-helpers';
import { chargeableWeight } from '../EnterShipmentDetails/Chargeable-weight';
import { calculateInputs, dhlPallEURHalfPalletDimensions } from './ShipmentDetailsRow/ShipmentDetailsRow-helpers';
import { shipmentDetailsTotalsValidations, Validation } from './Validations/ShipmentDetails-validation';
import { validateLoadingMeter } from '../../../globals/helpers/shipment';

export const setDeliveryDateAndPrice = (productWithDeliveryDate, productsWithPrice, routeState) => {
  let productsMatch = [];
  const isSwedenCountry = localStorage.getItem('country')?.toUpperCase() === 'SE';

  productsWithPrice.products &&
    productsWithPrice.products.forEach(productWithPrice => {
      const productMatch =
        productWithDeliveryDate &&
        productWithDeliveryDate.find(productDeliveryDate => {
          if (routeState.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE && isSwedenCountry) {
            return productWithPrice.product.code === productDeliveryDate.code;
          } else {
            return productDeliveryDate.code?.join('-').split('-').includes(productWithPrice.product.code);
          }
        });

      const productCode = productWithPrice.product.code;
      const product = config.allowedProducts.find(p => p.code === productCode);

      let finalProduct;

      if (product) {
        const productDeliveryDate = productsWithPrice.deliveryDates.deliveryDates[productWithPrice.product.code];
        if (productMatch) {
          finalProduct = {
            ...JSON.parse(JSON.stringify(productWithPrice.product)),
            ...productMatch,
            deliveryDate: productDeliveryDate?.deliveryDate,
            LTCDates: productDeliveryDate?.fixedDeliveryDates || [],
          };
          const deliveryDate = finalProduct.delivery_date || finalProduct.deliveryDate;

          finalProduct.deliveryDate = deliveryDate ? formatDate(deliveryDate) : '';

          // deleting delivery_date key to avoid confusion
          delete finalProduct.delivery_date;
        } else {
          finalProduct = {
            ...JSON.parse(JSON.stringify(productWithPrice.product)),
            deliveryDate: productDeliveryDate?.deliveryDate,
            LTCDates: productDeliveryDate?.fixedDeliveryDates,
          };
        }

        finalProduct.priorityServices = finalProduct.options;
        finalProduct.price = Array.isArray(productsWithPrice.prices[productCode])
          ? setPriceForProduct(productsWithPrice.prices[productCode], productCode)
          : '';
        finalProduct.account = productsWithPrice.account[productCode];
        finalProduct.code = productCode;
        finalProduct.product_code =
          (routeState.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE && isSwedenCountry
            ? product.product
            : product.alias) || null;

        productsMatch.push(finalProduct);
      }
    });

  if (
    productsMatch.some(
      product => config.ltcEurapidProductCodes.includes(product.code.toString()) && !product.deliveryDate,
    )
  ) {
    productsMatch.find(product => config.ltcEurapidProductCodes.includes(product.code.toString())).disabled = true;
  }

  return productsMatch;
};

export const setDeliveryDateAndPriceForPreSelectedProduct = (routeState, productsWithPrice) => {
  const isSwedenCountry = localStorage.getItem('country')?.toUpperCase() === 'SE';
  const productsWithDeliveryDate = routeState.products;
  const selectedProductCode = routeState.selectedProduct.value;
  const productName = config.serviceDescriptions[selectedProductCode].product;

  const selectedProductDeliveryDate = productsWithDeliveryDate.find(productWithDeliveryDate => {
    if (routeState.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE && isSwedenCountry) {
      return selectedProductCode === productWithDeliveryDate.code;
    } else {
      return productWithDeliveryDate.code.join('-').split('-').includes(selectedProductCode);
    }
  });

  const result = {
    ...routeState.productSpecifications[selectedProductCode],
    code: selectedProductCode,
    name: productName,
    deliveryDate:
      selectedProductDeliveryDate &&
      (selectedProductDeliveryDate.delivery_date || selectedProductDeliveryDate.deliveryDate)
        ? formatDate(selectedProductDeliveryDate.delivery_date || selectedProductDeliveryDate.deliveryDate)
        : null,
    account: productsWithPrice.account[selectedProductCode],
    price: Array.isArray(productsWithPrice.prices[selectedProductCode])
      ? setPriceForProduct(productsWithPrice.prices[selectedProductCode], selectedProductCode)
      : '',
  };

  if (selectedProductDeliveryDate && selectedProductDeliveryDate.product) {
    result.product_code = selectedProductDeliveryDate.product;
  }

  // Domestic
  if (
    selectedProductDeliveryDate &&
    selectedProductDeliveryDate.hasOwnProperty('options') &&
    typeof selectedProductDeliveryDate.options === 'string'
  ) {
    result.priorityServices = selectedProductDeliveryDate.options;
  }
  // International
  else if (selectedProductDeliveryDate && selectedProductDeliveryDate.product_features) {
    result.product_features = JSON.parse(JSON.stringify(selectedProductDeliveryDate.product_features));
  }

  if (config.ltcEurapidProductCodes.includes(selectedProductCode.toString()) && !result.deliveryDate) {
    result.disabled = true;
  }

  return [result];
};

export const patchPALLPriceToFreightCost = price => {
  let pallTotalPrice = 0;

  if (price.PALL) {
    pallTotalPrice += parseFloat(price.PALL.value.replace(',', '.'));
    delete price.PALL;
  }

  if (price.HALV) {
    pallTotalPrice += parseFloat(price.HALV.value.replace(',', '.'));
    delete price.HALV;
  }

  return pallTotalPrice.toFixed(config.price.maxDecimals).toString().replace('.', ',');
};

const setPriceForProduct = (prices, selectedProductCode) => {
  const priceMap = {};

  prices &&
    prices.forEach(price => {
      priceMap[price.id] = price;
    });

  // DHL PALL product price special case
  if (selectedProductCode === config.dhlPALL) {
    priceMap.FreightCost = {}; //Pascal code since following API naming key
    priceMap.FreightCost.value = patchPALLPriceToFreightCost(priceMap);
  }

  return priceMap;
};

export const chargeableWeightValidation = async (shipmentState, routeState) => {
  const chargeableWeightResult = await chargeableWeight(shipmentState, routeState);

  const productChargeableWeightMin =
    routeState.productSpecifications[routeState.selectedProduct.value] &&
    routeState.productSpecifications[routeState.selectedProduct.value].shipment.chargeableWeightMin;

  return Boolean(
    chargeableWeightResult.value &&
      productChargeableWeightMin &&
      chargeableWeightResult.value < productChargeableWeightMin,
  );
};

export const checkSubmit = (context, routeContext) => {
  const dimensions = context.state.dimensions;
  const shipmentDetailsRows = JSON.parse(JSON.stringify(context.state.shipmentDetailsRows));
  const { hasTotalError } = shipmentDetailsTotalsValidations(context);

  const globalColliError = hasGlobalColliError(shipmentDetailsRows, routeContext.state.userAccountCountry);

  // Check all rows
  shipmentDetailsRows.forEach((shipmentRow, index) => {
    // Quantity
    if (shipmentRow.quantity && !shipmentRow.quantity.hidden && !shipmentRow.quantity.disabled) {
      shipmentRow.quantity.error = Validation(
        shipmentRow.quantity,
        dimensions.min_item_quantity,
        dimensions.max_item_quantity,
        config.regEx.float,
      );
    }

    // Weight
    if (shipmentRow.weight && !shipmentRow.weight.hidden && !shipmentRow.weight.disabled) {
      const weight = fixDecimalPointAndConvertToNumber(shipmentRow.weight);
      const { maxWeight } = dhlPallEURHalfPalletDimensions(routeContext.state, context.state, shipmentRow);
      shipmentRow.weight.error = Validation(weight, dimensions.min_item_weight, maxWeight, config.regEx.float);
    }

    // Volume
    if (shipmentRow.volume && !shipmentRow.volume.hidden && !shipmentRow.volume.disabled) {
      const volume = toFloatOrEmptyString(shipmentRow.volume);

      shipmentRow.volume.error = Validation(
        volume,
        dimensions.min_item_volume,
        dimensions.max_item_volume,
        config.regEx.float,
      );
    }

    // Loading Meter
    if (
      (shipmentRow.loadingMeter && !shipmentRow.loadingMeter.hidden && !shipmentRow.loadingMeter.disabled) ||
      shipmentRow.loadingMeter.required
    ) {
      const { error } = validateLoadingMeter({
        loadingMeter: shipmentRow.loadingMeter.value,
        dimensions,
        required: shipmentRow.loadingMeter.required,
        nonStackable: shipmentRow.nonStackable.value,
        width: shipmentRow.width.value,
        length: shipmentRow.length.value,
        height: shipmentRow.height.value,
      });
      shipmentRow.loadingMeter.error = error;
    }

    // Length
    if (shipmentRow.length && !shipmentRow.length.hidden && !shipmentRow.length.disabled) {
      shipmentRow.length.error = Validation(
        shipmentRow.length,
        dimensions.min_item_length,
        dimensions.max_item_length,
        config.regEx.float,
      );
    }

    // Width
    if (shipmentRow.width && !shipmentRow.width.hidden && !shipmentRow.width.disabled) {
      shipmentRow.width.error = Validation(
        shipmentRow.width,
        dimensions.min_item_width,
        dimensions.max_item_width,
        config.regEx.float,
      );
    }

    // Height
    if (shipmentRow.height && !shipmentRow.height.hidden && !shipmentRow.height.disabled) {
      const { maxHeight } = dhlPallEURHalfPalletDimensions(routeContext.state, context.state, shipmentRow);

      shipmentRow.height.error = Validation(
        shipmentRow.height,
        dimensions.min_item_height,
        maxHeight,
        config.regEx.float,
      );
    }

    // Non-Stackable
    if (shipmentRow.nonStackable && !shipmentRow.nonStackable.hidden && !shipmentRow.nonStackable.disabled) {
      shipmentRow.nonStackable.error =
        shipmentRow.nonStackable.required && !shipmentRow.nonStackable.value ? true : false;
    }

    // Long-shipment
    if (shipmentRow.longShipment && !shipmentRow.longShipment.hidden && !shipmentRow.longShipment.disabled) {
      shipmentRow.longShipment.error =
        shipmentRow.longShipment.required && !shipmentRow.longShipment.value ? true : false;
    }
  });

  context.extendedSetState({ shipmentDetailsRows });

  return {
    hasError: checkError(shipmentDetailsRows) || hasTotalError || globalColliError,
    isGlobalColliError: globalColliError,
  };
};

const checkError = shipmentDetailsRows => {
  let error = false;

  shipmentDetailsRows.forEach(shipment => {
    let keys = Object.keys(shipment);
    keys.forEach(key => {
      if (shipment[key].error) error = true;
    });
  });

  return error;
};

export const checkIfProductsWithSpecialInputValidation = routeState => {
  return (
    routeState.productsRangeOne.value &&
    config.productsWithLocalValidationPerInput.some(product => product === routeState.selectedProduct.value)
  );
};

export const prepareUserData = data => {
  let pieces = [];
  let numberOfFullPallets = 0;
  let numberOfHalfPallets = 0;
  let marketAvailability = '';
  let isHalfPalletStyke = false;
  let isHalfPalletAlone = true;

  data.shipmentDetailsRows.forEach(row => {
    const obj = {
      numberOfPieces: row.quantity?.value || null,
      weight: returnProperValueOrNull(row.weight.value),
      length: row.length?.value || null,
      width: row.width?.value || null,
      height: row.height?.value || null,
      volume: returnProperValueOrNull(row.volume.value) || null,
      loadingMeters: returnProperValueOrNull(row.loadingMeter.value) || null,
      loadingMetres: returnProperValueOrNull(row.loadingMeter.value) || null,
      stackable: !row.nonStackable.value,
      packageType: config.packageType[row.shipmentType.value] || null,
    };

    switch (row.shipmentType.value) {
      case 'full pallet':
        numberOfFullPallets += row.quantity.value * 1;
        isHalfPalletAlone = false;
        break;
      case 'half pallet':
        numberOfHalfPallets += row.quantity.value * 1;
        if (row.height.value && isHalfPalletAlone && !isHalfPalletStyke) {
          isHalfPalletStyke = parseInt(row.height.value) > 150;
        }
        break;
      default:
        isHalfPalletAlone = false;
    }

    pieces.push(obj);
  });

  marketAvailability =
    (data.pickupAddressResidential.value ? 'C' : 'B') + '2' + (data.deliveryAddressResidential.value ? 'C' : 'B');

  const requestData = {
    pieces: pieces,
    totalNumberOfPieces: data.totals.shipmentDetailsRows.quantity.value || null,
    totalWeight: data.totals.shipmentDetailsRows.weight.value || '0.0',
    totalVolume: data.totals.shipmentDetailsRows.volume.value || '0.000',
    totalLoadingMetres: data.totals.shipmentDetailsRows.loadingMeter.value || '0.00',
    numberOfFullPallets: numberOfFullPallets || null,
    numberOfHalfPallets: numberOfHalfPallets || null,
    marketAvailability: marketAvailability,
    productCode: data.productCode
  };

  let cosignorAccount = {
    type: CONSIGNOR_CUSTOMER_TYPE,
    address: {
      postalCode: data.pickupPostalCode.value || null,
      countryCode: data.pickupCountry.value || null,
    },
  };

  if (!data.productsRangeAll?.value && data.accountNumber) {
    cosignorAccount.id = data.accountNumber;
  }

  const parties = [
    cosignorAccount,
    {
      type: CONSIGNEE_CUSTOMER_TYPE,
      address: {
        postalCode: data.deliveryPostalCode.value || null,
        countryCode: data.deliveryCountry.value || null,
      },
    },
  ];

  requestData.parties = parties;

  return { shipmentDetails: requestData, hideDHLPallForHalfPallet: isHalfPalletAlone && isHalfPalletStyke };
};

export const postMatchedProducts = async params => {
  let resultMap = {};

  await apiRequest(apiLinks.postQuoteToolProductMatches, 'POST', params)
    .then(result => {
      if (!result.data || (result.data && result.data.length === 0)) {
        result.status = CONST.STATUS_NO_CONTENT;
      }
      resultMap = result;
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const setInitialShipmentTypeOfNewRow = (
  selectedProduct,
  productRangeAll,
  userAccountCountry,
  favouriteColliTypes,
) => {
  const cookieData = getCookie('quoteShipmentType');
  const isSweden = userAccountCountry?.toLowerCase() === 'se';
  let shipmentType = {};
  let cookieTypeAllowed = false;

  if (cookieData) {
    const cookieShipmentType = config.shipmentTypes.find(type => type.value === cookieData);
    cookieTypeAllowed = cookieShipmentType && cookieShipmentType.swedenOnly === isSweden;
  }

  switch (true) {
    // DHL Pall product
    case selectedProduct === '210':
      shipmentType = JSON.parse(JSON.stringify(config.shipmentTypesFields['full pallet']));
      break;
    // saved cookie
    case productRangeAll && cookieData && cookieTypeAllowed:
      shipmentType = JSON.parse(JSON.stringify(config.shipmentTypesFields[cookieData]));
      break;
    // sweden default
    case isSweden:
      shipmentType = JSON.parse(JSON.stringify(config.shipmentTypesFields['unspecified']));
      break;
    // favourites default
    case Array.isArray(favouriteColliTypes) && favouriteColliTypes.length:
      const favouriteShipmentName = config.shipmentTypes.find(colli => colli.dataRel === favouriteColliTypes[0])?.value;
      shipmentType = JSON.parse(
        JSON.stringify(
          config.shipmentTypesFields[favouriteShipmentName] || config.shipmentTypesFields['carton/package'],
        ),
      );
      break;
    // global default
    default:
      shipmentType = JSON.parse(JSON.stringify(config.shipmentTypesFields['carton/package']));
  }

  return shipmentType;
};

export const addShipmentDetailsRow = (context, routeContext, favouriteColliTypes) => {
  const params = {};
  const colliTypeData = routeContext.state.productsRangeOne.value ? routeContext.state.selectedProduct.value : false;

  if (
    context.state.shipmentDetailsRows &&
    context.state.shipmentDetailsRows.length < context.state.shipmentRowsNumberMax
  ) {
    params.groupName = 'shipmentDetailsRows';
    params.item = setInitialShipmentTypeOfNewRow(
      colliTypeData,
      routeContext.state.productsRangeAll.value,
      routeContext.state.userAccountCountry,
      favouriteColliTypes,
    );
    params.afterUpdate = calculateInputs;

    context.addAnotherGroupField(params);
  }
};

export const removeShipmentDetailsRow = (index, context) => {
  const params = {};

  if (context.state.shipmentDetailsRows && context.state.shipmentDetailsRows.length > 1) {
    params.groupName = 'shipmentDetailsRows';
    params.index = index;
    params.afterUpdate = calculateInputs;

    context.removeGroupField(params);
  }
};

export const getQuotePriceForPreSelectedProduct = async (shipmentState, routeState, stateToSet) => {
  const quoteForPriceData = createQuoteForPricePayloadBody(routeState, shipmentState, false);

  /**
   * TODO: jm - This call is now redundant, it should be replaced by an already
   * existing productmatches call which also returns prices.
   */
  const quoteForPriceResult = await getQuoteForPriceAPICall(quoteForPriceData);

  if (quoteForPriceResult.error) {
    stateToSet.goNextError = true;
  } else if (
    quoteForPriceResult.data &&
    quoteForPriceResult.data.prices &&
    quoteForPriceResult.data.prices[routeState.selectedProduct.value] &&
    Array.isArray(quoteForPriceResult.data.prices[routeState.selectedProduct.value])
  ) {
    return {
      quotePriceMap:
        quoteForPriceResult.data && quoteForPriceResult.data.prices
          ? formQuoteResultMap(quoteForPriceResult.data.prices[routeState.selectedProduct.value])
          : {},
      allProductPrice: {},
      additionalServicesError: false,
      matchedProducts: setDeliveryDateAndPriceForPreSelectedProduct(routeState, quoteForPriceResult.data),
    };
  } else {
    stateToSet.goNextError = true;
  }
};

import React from 'react';
import { CONST } from '../../../data-config';
import { Error } from 'components';
import { useTranslation } from 'react-i18next';
import { ModalViewEditList } from './ModalViewEditList';

import './ModalView.css';

export const ModalView = ({
  unfinishedShipment,
  error,
  transactionId,
  continueToPortalOrder,
  onCloseModalAction,
  shipment,
  onDeleteShipment,
  loader,
}) => {
  const { t } = useTranslation();

  return (
    <div className="frc__dialog">
      <div
        className="frc__dialog-overlay"
        onClick={loader || unfinishedShipment ? null : () => onCloseModalAction()}
      ></div>

      {unfinishedShipment ? (
        <div className="frc__modal-dialog-width-small frc__dialog-wrapper">
          <div className=" frc__dialog-container frc__modal-center">
            {/**Unfinished Shipment Modal */}
            <h5 className="frc__modal-view-edit-center">{t(`dashboard|${'Incomplete Shipment Found'}`)}</h5>
            <strong>{t(`dashboard|${'Would you like to continue where you left off ?'}`)}</strong>

            <div className="l-grid has-top-margin-1 ">
              <div className={` l-grid l-grid--w-100pc-s  `}>
                <div className=" frc__modal-center l-grid--w-100pc-s l-grid--w-100pc-m-s l-grid--w-100pc-m-m">
                  <button
                    className={'base-button base-button--white l-grid--w-40pc-s frc__btn-margin-right '}
                    id={`${CONST.CONTINUE}template0Btn`}
                    onClick={() => continueToPortalOrder(true, t)}
                  >
                    {t(`dashboard|Yes, continue`)}
                  </button>

                  <button
                    className={'base-button  l-grid--w-30pc-s '}
                    onClick={() => continueToPortalOrder(false, t)}
                    id={`${CONST.CANCEL}template0Btn`}
                  >
                    {t(`dashboard|${'No, cancel'}`)}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="frc__modal-dialog-width frc__dialog-wrapper">
          <div className=" frc__dialog-container">
            {/**Delete Shipment Modal */}
            <div id="frc_dialog-close-btn" className="frc__dialog-close-button">
              <button className="frc__button--delete--darker" onClick={() => onCloseModalAction()}>
                X
              </button>
            </div>
            <h4 className="frc__modal-view-edit-center">{t(`general|Delete Shipment`) + ' ?'}</h4>

            <ModalViewEditList shipment={shipment} />

            <div className={` l-grid l-grid--w-100pc-s  `}>
              <div className=" frc__modal-center l-grid--w-100pc-s l-grid--w-100pc-m-s l-grid--w-100pc-m-m">
                <button
                  className={'base-button base-button--white l-grid--w-20pc-s frc__btn-margin-right '}
                  id="closeModalShipmentBtn"
                  onClick={onCloseModalAction}
                >
                  {t('general|labels|buttons|Cancel')}
                </button>

                <button
                  className={'base-button  l-grid--w-30pc-s '}
                  onClick={onDeleteShipment}
                  id={`${CONST.DELETE}ShipmentBtn`}
                >
                  {t(`general|labels|buttons|Delete`)}
                </button>
              </div>
            </div>

            {error && (
              <div className=" l-grid--w-100pc-s  ">
                <Error
                  name="shipmentModalActionResultError"
                  className="frc__input--error"
                  message={
                    t('general|errors|General error') +
                    '<br>' +
                    t('general|Try again or call technical support.') +
                    ' ' +
                    t('general|or') +
                    ' ' +
                    `<strong><a href="${t('pageLinks|chat')}" 
                                        class ="frc__chat-with-us"
                                        target="_blank" rel="noopener noreferrer">${t(
                                          `general|Chat with us`,
                                        )}</a></strong>` +
                    ' <br> ' +
                    t('general|ID') +
                    ': ' +
                    transactionId
                  }
                />
              </div>
            )}
            <div className={`frc__generic--loader-overlay   ${loader ? 'is-loading' : ''}`} />
          </div>
        </div>
      )}
    </div>
  );
};

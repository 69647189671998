import { Box, Button, ThemeProvider, Typography } from '@mui/material';
import React from 'react';
import { muiTheme } from '../../styles/mui-theme';
import { GlobalError } from './GlobalError';
import { RefreshRounded } from '@mui/icons-material';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, details: undefined };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // TODO: jm - maybe log it to backend?
    console.error('ErrorBoundary -- error', error, 'info', info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ThemeProvider theme={muiTheme}>
          <GlobalError readOnlyHeader>
            <Box
              sx={{
                textAlign: 'center',
                m: '3rem',
              }}
            >
              <Typography variant="h1">Sorry!</Typography>
              <Typography variant="body1" sx={{ m: '3rem' }}>
                Something went wrong.
              </Typography>
              <Button
                startIcon={<RefreshRounded />}
                onClick={() => {
                  window.location.reload();
                }}
                variant="contained"
                data-testid="refreshPageBtn"
              >
                Refresh the page
              </Button>
            </Box>
          </GlobalError>
        </ThemeProvider>
      );
    }

    return this.props.children;
  }
}

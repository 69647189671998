import { config } from '../../../data-config';
import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';
import { payerCode, readState } from '../EnterPickupLocation-helpers';
import { DOMESTIC_SHIPMENT_RANGE } from '../../../../globals/constants';

const CheckInternational = (state, stateToSet, hasErrors, casePayerCode) => {
  let getState = {};

  if (casePayerCode === 'case2' || casePayerCode === 'case1') {
    getState = getAccountNumber('accountNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet);

    stateToSet.receiverNumber = { value: '', error: false };
    stateToSet.thirdPartyNumber = { value: '', error: false };
  } else if (casePayerCode === 'case3') {
    getState = getAccountNumber('accountNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet);

    getState = getAccountNumber('thirdPartyNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet);

    stateToSet.receiverNumber = { value: '', error: false };
  } else if (casePayerCode === 'case4') {
    getState = getAccountNumber('receiverNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet);

    stateToSet.accountNumber = { value: '', error: false };
    stateToSet.thirdPartyNumber = { value: '', error: false };
  } else if (casePayerCode === 'case5') {
    getState = getAccountNumber('receiverNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet);

    getState = getAccountNumber('thirdPartyNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet);

    stateToSet.accountNumber = { value: '', error: false };
  }
  return { stateToSet: stateToSet, hasErrors: hasErrors };
};

const getStateValue = (getState, state) => {
  let stateToSet = JSON.parse(JSON.stringify(state));
  stateToSet[getState.name] = { value: getState.value, error: getState.error };
  return stateToSet;
};
const CheckDomestic = (state, stateToSet, hasErrors, casePayerCode) => {
  let getState = {};

  if (casePayerCode === 'case6') {
    getState = getAccountNumber('accountNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet);

    stateToSet.thirdPartyNumber = { value: '', error: false };
    stateToSet.receiverNumber = { value: '', error: false };
  } else if (casePayerCode === 'case7') {
    getState = getAccountNumber('accountNumber', state, false);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet);

    getState = getAccountNumber('receiverNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet);

    stateToSet.thirdPartyNumber = { value: '', error: false };
  } else if (casePayerCode === 'case8') {
    getState = getAccountNumber('accountNumber', state, state.accountNumber.isRequired);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet);

    getState = getAccountNumber('thirdPartyNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet);

    stateToSet.receiverNumber = { value: '', error: false };
  }
  return { stateToSet: stateToSet, hasErrors: hasErrors };
};
export const ValidateAccountNumber = (state, stateToSet, hasErrors) => {
  let opts = readState(state);
  const casePayerCode = payerCode(opts);
  let finalMap = {};

  if (state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE) {
    finalMap = CheckDomestic(state, stateToSet, hasErrors, casePayerCode);
  } else {
    finalMap = CheckInternational(state, stateToSet, hasErrors, casePayerCode);
  }

  return finalMap;
};

const getAccountNumber = (name, state, isRequired = true) => {
  let getState = checkFieldAndReturnState({
    name: name,
    value: state[name].value,
    regEx: config.regEx.alphaNumericWithDot,
    lengthCheck: isRequired ? [RegExp(`^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$`)] : null,
    isRequired: isRequired,
  });

  return getState;
};

export const PayerCodeValidation = (state, stateToSet, hasErrors) => {
  let getState = {};
  getState = checkFieldAndReturnState({
    name: 'termsOfDelivery',
    value: state['termsOfDelivery'].value,
    isRequired: state['termsOfDelivery'].isRequired ? true : false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = {
    value: getState.value,
    error: getState.error,
    isRequired: state['termsOfDelivery'].isRequired,
  };
  return {
    stateToSet,
    hasErrors,
  };
};

import { javaSplit  } from "../../../globals/utils/strings";

export const setContactDetail = (id, state) => {
  const contactToEdit = state.addressBookList.find(address => address.id === id);
  state.id = contactToEdit.id;
  state.addressId.value = contactToEdit.addressId;

  state.companyName.value = contactToEdit.companyName;
  state.street.value = contactToEdit.street;
  state.postalCode.value = contactToEdit.postalCode;
  state.city.value = contactToEdit.city;
  state.countryCode.value = contactToEdit.countryCode;

  state.deliveryInstructions.value = contactToEdit.deliveryInstructions || '';
  state.email.value = contactToEdit.email || '';
  state.name.value = contactToEdit.name || '';
  state.notes.value = contactToEdit.notes || '';
  state.reference.value = contactToEdit.reference || '';
  state.residentialAddress.value = contactToEdit.residentialAddress;
  if (contactToEdit.phone) {
    const phoneParts = javaSplit(contactToEdit.phone, '-', 2);
    state.phoneNumbers = [
      {
        code: phoneParts[0],
        error: false,
        isRequired: false,
        number: phoneParts[1] || '',
      },
    ];
  }

  return state;
};

import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { CONST, config } from '../../data-config';
import { debounce } from 'globals/utils/debounce';
import getBaseUrl from 'globals/utils/getUrlBase';
import { FilterBanner } from 'components';
import { Error } from 'components';
import AddressBookIcon from '../svgIcons/addressBookIcon';
import AddressBookSearch from '../AddressBookSearch/AddressBookSearch';
import AddressBookAction from '../AddressBookAction/AddressBookAction';
import AddressBookResultGrid from '../AddressBookResultGrid/AddressBookResultGrid';
import { setContactDetail } from './AddressBook-helpers';
import { onAddressSearch, resetContact } from '../ActionUtils';
import { getTransactionId } from 'globals/utils/generators';
import { ROUTES } from 'router';
import { withCountryData } from '../../../hooks/useConfigCoutries';

class AddressBookComponent extends Component {
  showContact = id => {
    const { context } = this.props;
    const state = JSON.parse(JSON.stringify(context.state));
    const cleanContact = resetContact({ countryData: this.props.countryData });
    const cleanState = { ...state, ...cleanContact };

    if (id) {
      const contactObjState = setContactDetail(id, cleanState);
      context.updateState(contactObjState, this.props.onShowContact());
    } else {
      context.updateState(cleanState, this.props.onShowContact());
    }
  };

  contactDeleted = debounce(addressListToDelete => {
    this.props.context.updateState({
      deleteList: addressListToDelete,
    });
  }, config.standardDebounceTimeout);

  componentDidMount = () => {
    const { context } = this.props;

    if (context.state.transactionId === '' && context.state.addressBookListMaster.length === 0) {
      context.updateState({
        transactionId: getTransactionId(CONST.PORTAL_ADDRESS_BOOK),
      });

      onAddressSearch(context);
    }
  };

  render() {
    const { context } = this.props;

    return (
      <div data-testid="FilterBanner" className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <>
              <h3 className="l-grid l-grid--middle-s margin-bottom-1">
                <AddressBookIcon />
                <span className="frc__address-book-header">{t(`general|Address Book`)}</span>
              </h3>

              <AddressBookSearch
                context={context}
                onShowContact={() => this.showContact()}
                onShowSuccessToaster={this.props.onShowSuccessToaster}
              />

              <AddressBookAction context={context} t={t} onShowSuccessToaster={this.props.onShowSuccessToaster} />
              <FilterBanner
                CONST={CONST}
                remoteSearchTerm={context.state.remoteSearchTerm}
                searchTerm={context.state.searchTerm.value}
                t={t}
              />
              {context.state.apiError ? (
                <Error
                  name={'addressSearchError'}
                  className="frc__input--error"
                  message={t('general|errors|General error')}
                />
              ) : (
                <AddressBookResultGrid
                  context={context}
                  baseUrl={getBaseUrl(ROUTES.portal)}
                  translation={t}
                  onDeleteContact={this.contactDeleted}
                  onShowContact={this.showContact}
                  onShowSuccessToaster={this.props.onShowSuccessToaster}
                />
              )}
            </>
          )}
        </Translation>
        <div id="frc_loader" className={`frc__generic--loader-overlay  ${context.state.loader ? 'is-loading' : ''}`} />
      </div>
    );
  }
}

export default withCountryData(AddressBookComponent);

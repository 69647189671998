import { Box } from '@mui/material';
import { Error, Input } from 'components';
import { useTranslation } from 'react-i18next';
import { getPostalCodeRegex } from '../../globals/helpers/postalcode';
import { caseInsensitiveEquals } from '../../globals/utils/strings';
import { useGetUser } from '../../hooks/api/useGetUser';
import { PROFILE_CONSTANTS } from './edit-profile/constants';

export const POSTALCODE_ERRORS = {
  validationError: 'validationError',
  apiError: 'apiError',
  cityPostalCodeError: 'cityPostalCodeError',
};

export const PostalCodeInput = ({
  value,
  name,
  placeholder,
  isRequired = false,
  countryCode,
  error,
  afterChangeOnBlur,
  context,
  config,
  CONST,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { data: accountCountryCode } = useGetUser({ select: data => data?.user?.accountCountryCode });

  const isIreland = caseInsensitiveEquals(countryCode, 'IE');
  const maxLength = isIreland ? PROFILE_CONSTANTS.irelandCountyCharacterLimit : config.maxPostalCode;

  return (
    <Box>
      <Input
        context={context}
        config={config}
        CONST={CONST}
        regEx={getPostalCodeRegex({ countryCode, accountCountryCode, strict: false })}
        label={isIreland ? t('general|labels|inputs|County') : t('general|labels|inputs|Postalcode')}
        name={name}
        dataTestId={name+'Inp'}
        isRequired={isRequired}
        value={value}
        lengthCheck={[RegExp(`^.{1,${maxLength}}$`)]}
        cutTextLimit={maxLength}
        placeholder={placeholder}
        afterChangeOnBlur={afterChangeOnBlur}
        disabled={disabled}
      />

      {error === POSTALCODE_ERRORS.validationError && (
        <Error
          name={`${name}_${error}`}
          className="frc__input--error"
          message={t('general|errors|Postalcode Error', {
            max: maxLength,
          })}
        />
      )}
      {error === POSTALCODE_ERRORS.apiError && (
        <Error
          name={`${name}_${error}`}
          className="frc__input--error"
          message={t('general|errors|Postalcode API Error')}
        />
      )}
      {error === POSTALCODE_ERRORS.cityPostalCodeError && (
        <Error name={`${name}_${error}`} message={t('general|errors|Incorrect Postal Code')} />
      )}
    </Box>
  );
};

import React, { useState, useEffect, useRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { CONST, config } from '../../../../data-config';
import { Checkbox } from 'components';
import { Error } from 'components';
import { getUserDetails } from '../../MyOnlineShipments-helpers';
import { updateFilterParamters } from '../FilterShipments-helpers';

function FilterAccounts(props) {
  const _isMounted = useRef(true);
  const { t } = useTranslation();
  const [apiError, setApiError] = useState(null);
  const [loader, setLoader] = useState(null);

  const displayAccountsCheckbox = () => {
    return props.context.state.filterByUserTMSAccount.map((account, index) => {
      return (
        <div key={account.label}>
          <Checkbox
            index={index}
            label={account.label}
            name={account.label}
            disabled={props.context.state.filterByUserTMSAccount.length === 1}
            context={props.context}
            checked={account.value}
            isRequired={false}
            config={config}
            CONST={CONST}
            updateOnParent={(params, context) => updateFilterParamters(params, context, 'filterByUserTMSAccount')}
          />
        </div>
      );
    });
  };

  const processTMSAccounts = accounts => {
    if (accounts.length > 0) {
      return accounts.map(account => {
        return {
          label: account.accountReference
            ? `${account.accountNumber} (${account.accountReference})`
            : account.accountNumber,
          accountNumber: account.accountNumber?.toString(),
          value: false,
        };
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    // make api call only once
    if (props.context.state.filterByUserTMSAccount.length === 0 && _isMounted.current) {
      async function getUserDetailsCall() {
        setLoader(true);
        const userData = await getUserDetails();

        if (_isMounted.current) {
          if (!userData.error) {
            props.context.updateState({
              filterByUserTMSAccount: processTMSAccounts(userData.data.tmsAccounts),
            });
          } else {
            setApiError(true);
          }
          setLoader(false);
        }
      }

      getUserDetailsCall();
    }

    // returned function will be called on component unmount
    return () => {
      _isMounted.current = false;
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="l-grid--w-100pc-s margin-bottom-1">
        <h4 className="margin-bottom-1 frc__filter_title">{t(`portalSavedShipments|Account`)}</h4>
      </div>
      {loader ? <div id="frc_loader" className={`  ${loader ? 'is-loading' : ''}`} /> : displayAccountsCheckbox()}
      {apiError ? <Error name="filterAccountApiError" message={t('general|errors|General error')} /> : ''}
    </>
  );
}

const areFunctionPropsEqual = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.context.state.filterByUserTMSAccount) ===
    JSON.stringify(nextProps.context.state.filterByUserTMSAccount)
  );
};

export default memo(FilterAccounts, areFunctionPropsEqual);

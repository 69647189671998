import React, { Component } from 'react';
import Cell from './Cell';

import './DataTable.css';

export default class DataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortConfig: this.props.sortConfig ? this.props.sortConfig : null,
      allRowsSelected: false,
      data: this.props.hasCheckBox ? this.addCheckBoxIfRequested(this.props.data) : this.props.data,
    };

    this.tableRef = React.createRef();
  }

  addCheckBoxIfRequested = data => {
    data.forEach(row => {
      row.value = false;
    });

    return data;
  };

  sortedItems = (data, sortConfigCheck = false) => {
    let sortableItems = [...data];
    let sortConfig = sortConfigCheck;

    if (!sortConfig) sortConfig = this.state.sortConfig;

    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const itemA = a[sortConfig.key] ? a[sortConfig.key].toLowerCase() : '';
        const itemB = b[sortConfig.key] ? b[sortConfig.key].toLowerCase() : '';

        if (itemA < itemB) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (itemA > itemB) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }

        return 0;
      });
    }
    return sortableItems;
  };

  requestSort = key => {
    let direction = 'asc';
    const { sortConfig } = this.state;

    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    this.setState({ sortConfig: { key, direction }, data: this.sortedItems(this.state.data, { key, direction }) });
  };

  updateCheckbox = (params, isSelectAll) => {
    const newData = JSON.parse(JSON.stringify(this.state.data));

    if (!isSelectAll) {
      newData[params.index].value = params.value;
    } else {
      newData.forEach(data => {
        data.value = params.value;
      });
    }

    this.setState({
      data: newData,
      allRowsSelected: params.value && isSelectAll,
    });

    this.props.selectedRows(newData.filter(data => data.value));
  };

  renderHeader = (cell, cellIndex) => {
    return (
      <Cell
        key={`heading-${cellIndex}`}
        id={`heading-${cellIndex}`}
        content={cell.name}
        context={this.props.context}
        hasCheckBox={this.props.hasCheckBox && cellIndex === 0 ? true : false}
        index={cellIndex}
        column={cell}
        header={true}
        sortConfig={this.state.sortConfig}
        updateCheckbox={this.updateCheckbox}
        requestSort={this.requestSort}
        fixed={cellIndex === 0 && this.props.fixedTable}
        allRowsSelected={this.state.allRowsSelected}
      />
    );
  };

  renderData = (row, rowIndex) => {
    const suppressCheckbox = this.props.suppressCheckBoxOnRowProperty?.find(item => {
      return row[item.property] === item.value;
    });

    return (
      <tr key={`row-${rowIndex}`}>
        {this.props.columns.map((column, cellIndex) => {
          const content = column.cell ? column.cell(row) : row[column.selector];

          return (
            <Cell
              key={`${rowIndex}-${cellIndex}`}
              index={rowIndex}
              context={this.props.context}
              id={`${rowIndex}-${cellIndex}`}
              content={content}
              column={row}
              width={column.width ? column.width : null}
              hasCheckBox={this.props.hasCheckBox && cellIndex === 0 && !suppressCheckbox ? true : false}
              fixed={cellIndex === 0 && this.props.fixedTable}
              updateCheckbox={this.updateCheckbox}
              fixedTable={this.props.fixedTable ? true : false}
              selector={column.selector || ''}
            />
          );
        })}
      </tr>
    );
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      JSON.stringify(this.state.data) !== JSON.stringify(nextState.data) ||
      JSON.stringify(this.state.sortConfig) !== JSON.stringify(nextState.sortConfig) ||
      JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data) ||
      nextProps.columns !== this.props.columns
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.hasCheckBox
          ? this.sortedItems(this.addCheckBoxIfRequested(this.props.data))
          : this.sortedItems(this.props.data),
        allRowsSelected: false,
      });
    }
  }

  render() {
    const { columns } = this.props;

    const theadMarkup = (
      <tr className="dataTable-color" key="heading">
        {columns.map(this.renderHeader)}
      </tr>
    );

    const tbodyMarkup = this.state.data.map(this.renderData);

    return (
      <div data-testid="DataTable" className="frc__dataTable_container">
        <div className={`${this.props.fixedTable ? 'frc__fixed-first-column' : 'frc__scrollable-table'}`}>
          <table
            key="table"
            id="dataGrid"
            className={`frc__table  ${!this.props.fixedTable ? 'frc__table-layout ' : ' '} ${
              this.props.tableClassName ? this.props.tableClassName : ''
            } `}
            ref={this.tableRef}
          >
            <thead>{theadMarkup}</thead>
            <tbody>{tbodyMarkup}</tbody>
          </table>
        </div>

        {this.state.data.length === 0 && this.props.noResultMessge && (
          <div className="l-grid">
            <strong className="frc__dataTable-no-result">
              {this.props.noResultMessge ? this.props.noResultMessge : ''}
            </strong>
          </div>
        )}
      </div>
    );
  }
}

export const state = {
  newPassword: {
    value: '',
    error: false,
  },
  newPasswordConfirmation: {
    value: '',
    error: false,
  },
};

import React, { Component } from 'react';
import { PhoneNumber } from 'components';
import { checkEmptyOrMinMaxOrLength } from 'globals/utils/fieldValidations';
import { cutTextAndShowError } from 'globals/context/context-helpers';

export default class OptionPhoneNumber extends Component {
  updateNestedGroupOfFields = params => {
    let error = checkEmptyOrMinMaxOrLength(params);
    let group = [...params.groupData];

    // Text cut
    if (params.cutTextLimit && !isNaN(params.cutTextLimit) && params.cutTextLimit > 0) {
      params = cutTextAndShowError(params);
    }
    error = params.error === true ? true : error;

    group[params.index][params.name] = params.value;

    if (!params.skipError) {
      group[params.index].error = error;
    }

    return group;
  };

  update = (params, context) => {
    let stateObject = {};
    let additionalService = {};
    let option = {};
    let afterUpdate;
    let additionalServices = {};
    let CONTEXT = context;
    let PROPS = this.props;

    stateObject = context.state.additionalServices;
    additionalService = JSON.parse(JSON.stringify(stateObject[this.props.serviceIndex]));

    const additionalServiceParentOption =
      additionalService.groups[this.props.groupIndex].options[this.props.optionParentIndex];

    const additionalServiceOption = additionalServiceParentOption[this.props.index];
    params.groupData = additionalServiceOption.value;
    option = this.updateNestedGroupOfFields(params);
    additionalServiceOption.value[params.index] = option[params.index];

    stateObject[this.props.serviceIndex] = additionalService;
    context.updateState({ additionalServices: stateObject }, () => {
      if (params.afterUpdate && typeof params.afterUpdate === 'function') {
        params.groupData = option;
        afterUpdate = params.afterUpdate(CONTEXT, params);

        if (typeof afterUpdate === 'object' && afterUpdate !== null) {
          afterUpdate = JSON.parse(JSON.stringify(afterUpdate));
          additionalServices = CONTEXT.state.additionalServices;
          additionalService = JSON.parse(JSON.stringify(additionalServices[PROPS.serviceIndex]));

          const newAdditionalServiceOption =
            additionalService.groups[this.props.groupIndex].options[this.props.optionParentIndex];
          newAdditionalServiceOption[PROPS.index].value = afterUpdate;
          additionalServices[PROPS.serviceIndex] = additionalService;

          CONTEXT.updateState({ additionalServices: additionalServices });
        }
      }
    });
  };

  render() {
    return (
      <div className="l-grid--w-100pc-s frc__as-option--wrapper">
        {this.props.option.value.slice(0, 1).map((phoneNumber, ind) => (
          <PhoneNumber
            id={this.props.code + this.props.option.optionCode + ind}
            context={this.props.context}
            config={this.props.config}
            CONST={this.props.CONST}
            key={ind}
            index={ind}
            number={phoneNumber.number}
            code={phoneNumber.code}
            removeOnParent={index => this.removePhoneNumber(index, this.props.context)}
            error={phoneNumber.error}
            errorId={this.props.errorId}
            groupName={this.props.groupName}
            isRequired={this.props.option.mandatory}
            updateOnParent={(params, context) => this.update(params, context)}
          />
        ))}
      </div>
    );
  }
}

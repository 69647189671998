import { RECEIVER_PAYER, THIRD_PARTY_PAYER } from '../constants';

export const filterArrayOfObjectsByValuesInArray = (arrayOfObjects, filterArray) => {
  let result = [];

  if (!Array.isArray(arrayOfObjects)) return result;

  if (Array.isArray(filterArray) && filterArray.length > 0) {
    result = arrayOfObjects.filter(obj => {
      return filterArray.indexOf(obj.code) !== -1;
    });
  } else {
    result = JSON.parse(JSON.stringify(arrayOfObjects));
  }

  return result;
};

export const IntersectionInArrays = (
  primaryList,
  secondarList,
  isUnion = false,
  primaryAttribute,
  secondaryAttribute,
) =>
  primaryList.filter(
    primaryObj =>
      isUnion === secondarList.some(secondaryObj => primaryObj[primaryAttribute] === secondaryObj[secondaryAttribute]),
  );

export const hidePricesDueToPayerType = state => {
  // Hide prices in case of Receiver or Third Party pays and account number doesn't come from select but from input
  if (state.shipmentPayer.value === RECEIVER_PAYER && !state.receiverNumber.isSelect) return true;
  if (state.shipmentPayer.value === THIRD_PARTY_PAYER && !state.thirdPartyNumber.isSelect) return true;

  return false;
};

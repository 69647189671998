const isValidUrl = url => {
  const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return pattern.test(url);
};

export const sanitizeUrl = url => {
  if (isValidUrl(url)) {
    const lowercasedUrl = url.toLowerCase();
    if (!lowercasedUrl.startsWith('http://') && !lowercasedUrl.startsWith('https://')) {
      return false;
    }
    return url;
  }
  return false;
};

/**
 * Check if lang is in this format: se-en
 */
export const isValidCountryLanguage = lang => {
  if (!lang) {
    return false;
  }
  const containsDash = lang.includes('-');
  const containsTwoPairs = lang.split('-').length === 2;
  const eachPartContainsTwoChars = lang.split('-').every(part => part.length === 2);
  return containsDash && containsTwoPairs && eachPartContainsTwoChars;
};

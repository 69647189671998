import { getPostalCodePlaceholder } from '../components/EnterPickupLocation/EnterPickupLocation-helpers';
import { config } from '../data-config';
import { DOMESTIC_SHIPMENT_RANGE } from '../../globals/constants';

export const state = {
  languageCode: config.locale.defaultLanguage,
  transactionId: '',
  accounts: null,
  productSpecifications: [],
  internationalShippingCountries: [],
  shipmentRange: { value: DOMESTIC_SHIPMENT_RANGE },
  shipmentTypesImpOrExp: { value: '' },

  pickupCountry: { value: '', error: false, comparisonError: false, isRequired: true },
  pickupPostalCode: {
    value: '',
    error: false,
    isRequired: true,
    placeholder: getPostalCodePlaceholder(localStorage.getItem('country')),
  },
  pickupAddressResidential: { value: false },
  pickupCity: { value: '', error: false },

  deliveryCountry: { value: '', error: false, comparisonError: false, isRequired: true },
  deliveryPostalCode: {
    value: '',
    error: false,
    isRequired: true,
    placeholder: getPostalCodePlaceholder(localStorage.getItem('country')),
  },
  deliveryAddressResidential: { value: false },
  deliveryCity: { value: '', error: false },

  marketAvailability: { value: 'B2B' },
  productsRangeAll: { value: false, disabled: false },
  productsRangeOne: { value: false },
  products: [],
  payerCode: '',
  productsRangeError: { value: false, error: false },
  productsCircumferrence: {},

  accountNumber: { value: '', error: false, isRequired: true },
  productsPerAccount: [],
  selectedProduct: { value: '', error: false, isRequired: true },
  ltcFailed: false,
};

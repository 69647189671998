export const getInputsToGrayOut = paramName => {
  if (paramName === 'length' || paramName === 'width' || paramName === 'height') {
    return {
      lengthWidthHeight: false,
      volume: true,
      loadingMeter: true,
    };
  } else if (paramName === 'volume') {
    return {
      lengthWidthHeight: true,
      volume: false,
      loadingMeter: true,
    };
  } else if (paramName === 'loadingMeter') {
    return {
      lengthWidthHeight: true,
      volume: true,
      loadingMeter: false,
    };
  } else return {};
};

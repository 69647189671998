import { Button, Error, FilterBanner, Input, Pagination } from 'components';
import { debounce } from 'globals/utils/debounce';
import { matchSorter } from 'match-sorter';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { CONST, config } from '../../../data-config';
import {
  fillAddress,
  postSearchResults,
  resetPagination,
  setSearchResultsAndPagination,
  shipmentRangeIsAllowed,
} from './AddressPickup-helpers';
import AddressPickupResultGrid from './AddressPickupResultGrid/AddressPickupResultGrid';
import ErrorModal from './ErrorModal/ErrorModal';

import '../Template-Address.css';

export default class AddressPickup extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      className: props.className,
      party: '',
      showLastModified: true,
      countryList: [],
      searchResults: [],
      searchResultsMaster: [],
      pagination: {
        page: 1,
        total: 0,
        pageSize: config.paginationInitialNumberOfItems,
        lastPage: 1,
      },
      paginationMaster: {},
      data: [],
      manualSearch: false,
      total: 0,
      apiError: false,
      pageSize: config.paginationInitialNumberOfItems,
      loader: false,
      remoteSearchTerm: '',
      errorModal: React.createRef(),
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal = async party => {
    this.setState({ loader: true, isOpen: true }, async () => {
      const searchResults = await postSearchResults(this.props.context.state, false, party);

      const stateToSet = setSearchResultsAndPagination(searchResults, this.state, this.state.pagination);
      stateToSet.party = party;

      this.setState(stateToSet);
    });
  };

  closeModal() {
    this.setState({ isOpen: false, showLastModified: true, remoteSearchTerm: '', manualSearch: false });
    this.props.context.updateState({ searchTerm: { value: '' } });
  }

  filterMatchedAddressResults = debounce(context => {
    let keys;
    const stateToSet = {};

    if (context.state.searchTerm.value.length !== 0 && this.state.searchResultsMaster.length > 0) {
      // e.g. companyName, name, country etc
      keys = Object.keys(this.state.searchResultsMaster[0]);

      const filteredMatches = matchSorter(this.state.searchResultsMaster, context.state.searchTerm.value, {
        keys: keys,
        threshold: matchSorter.rankings.WORD_STARTS_WITH,
      });

      stateToSet.pagination = resetPagination();
      stateToSet.searchResults = filteredMatches;
      stateToSet.data = filteredMatches.length > 0 ? filteredMatches : [];
      stateToSet.manualSearch = true;
    } else {
      stateToSet.pagination = JSON.parse(JSON.stringify(this.state.paginationMaster));
      stateToSet.searchResults = JSON.parse(JSON.stringify(this.state.searchResultsMaster));
      stateToSet.data = this.state.searchResultsMaster.length > 0 ? this.state.searchResultsMaster : [];
      stateToSet.manualSearch = false;
    }

    this.setState(stateToSet);
  }, config.standardSearchDebounceTimeout);

  onSearchAddressBook = e => {
    e.preventDefault();

    setTimeout(() => {
      this.searchAddressBook();
    }, 200);
  };

  searchAddressBook = async ({ pageSize = false, page = false } = false) => {
    this.setState({ loader: true }, async () => {
      const { state } = this.props.context;
      let stateToSet = this.resetState();

      stateToSet.pagination = JSON.parse(JSON.stringify(this.state.pagination));

      if (pageSize || page) {
        stateToSet.manualSearch = false;

        if (pageSize) {
          stateToSet.pagination.pageSize = pageSize;
          stateToSet.pagination.page = 1;
        }
        if (page) stateToSet.pagination.page = page;
      }

      const searchResults = await postSearchResults(this.props.context.state, stateToSet.pagination, this.state.party);

      stateToSet = {
        ...stateToSet,
        ...setSearchResultsAndPagination(searchResults, this.state, stateToSet.pagination),
      };

      stateToSet.showLastModified = false;
      stateToSet.remoteSearchTerm = state.searchTerm.value;
      stateToSet.manualSearch = false;

      this.setState(stateToSet);
    });
  };

  handlePageChange = page => {
    this.searchAddressBook({ pageSize: false, page: page });
  };

  handleDisplayRowChange = pageSize => {
    this.searchAddressBook({ pageSize: pageSize, page: false });
  };

  resetState = () => {
    return {
      apiError: false,
    };
  };

  onAddressSelect = (addressId, context) => {
    if (this._isMounted) {
      const selectedAddress = this.state.searchResults.find(address => address.id === addressId);

      if (shipmentRangeIsAllowed(selectedAddress, context, this.state.party)) {
        fillAddress(this.props.context, selectedAddress, this.state.party, this.props.checkAppropriateCity);
        this.closeModal();
      } else {
        this.state.errorModal.current.openModal();
      }
    }
  };

  closeOnEsc = event => {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  };

  shouldComponentUpdate = async (nextProps, nextState) => {
    return JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data);
  };

  componentDidMount() {
    this._isMounted = true;
    document.addEventListener('keydown', this.closeOnEsc);
  }
  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('keydown', this.closeOnEsc);
  }

  render() {
    const { context } = this.props;
    const showNoResultError =
      !this.state.loader &&
      !this.state.apiError &&
      this.state.showLastModified &&
      !this.state.manualSearch &&
      this.state.searchResults.length === 0;

    return (
      <>
        {this.state.isOpen ? (
          <Translation>
            {t => (
              <div className="frc__dialog">
                <div className="frc__dialog-overlay" onClick={() => this.closeModal()}></div>
                <div className="frc__dialog-wrapper l-grid--w-100pc-s l-grid--w-80pc-m">
                  <div className="frc__dialog-container l-grid--w-100pc-s l-grid--w-80pc-m ">
                    <div id="frc_dialog-close-btn" className="frc__dialog-close-button">
                      <button className="frc__button--delete--darker" onClick={() => this.closeModal()}>
                        X
                      </button>
                    </div>

                    <h5 className="frc__address-pickup-header">{t('general|Search Address Book')}</h5>
                    <form onSubmit={e => this.onSearchAddressBook(e)}>
                      <div className="l-grid">
                        <div className="l-grid--w-100pc-s l-grid--w-60pc-m">
                          <Input
                            context={context}
                            config={config}
                            CONST={CONST}
                            regEx={config.regEx.everything}
                            label={t('general|labels|Search')}
                            tooltip={t('general|labels|Search Company Name, Contact Name, Address or Account Number')}
                            name="searchTerm"
                            dataTestId="searchInputAddressBookInp"
                            disableonBlur
                            inputClassName="frc__addressbook-searchinput"
                            value={context.state.searchTerm.value}
                            hideAutoComplete={true}
                            lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                            cutTextLimit={config.maxDefaultInputLength}
                            afterUpdate={this.filterMatchedAddressResults}
                            placeholder={t('general|labels|Search by ID, Company Name, Name, Street, City, Note')}
                          />
                        </div>
                        <div className="frc__search-btn-center l-grid--w-100pc-s l-grid--w-25pc-m frc__address-search--button">
                          <Button
                            id="addressBook"
                            dataTestId="addressBookSearchButton"
                            className="base-button frc__search-button l-grid--w-50pc-s-s l-grid--w-50pc-s-l l-grid--w-20pc-m"
                            label={t('general|labels|Search')}
                          />
                        </div>
                      </div>
                    </form>

                    <div className="frc__addresspickup-results">
                      <div className="frc__generic-row--wrapper">
                        <FilterBanner
                          CONST={CONST}
                          remoteSearchTerm={this.state.remoteSearchTerm}
                          searchTerm={context.state.searchTerm.value}
                          t={t}
                        />
                      </div>
                      {!this.state.loader ? (
                        this.state.showLastModified && this.state.data.length > 0 ? (
                          <div>
                            <div>{t('general|New Addresses')}</div>
                          </div>
                        ) : this.state.pagination.total > 0 && this.state.data.length > 0 ? (
                          this.state.manualSearch ? (
                            <div className="frc__address-no-results">
                              {this.state.data.length}{' '}
                              {this.state.data.length === 1
                                ? t(`portalOrder|result found`)
                                : t('general|results found')}
                            </div>
                          ) : (
                            <div className="frc__address-no-results">
                              {this.state.pagination.total}{' '}
                              {this.state.pagination.total === 1
                                ? t(`portalOrder|result found`)
                                : t('general|results found')}
                            </div>
                          )
                        ) : this.state.apiError ? (
                          <Error className="frc__input--error" message={t('general|errors|General error')} />
                        ) : (
                          !showNoResultError && (
                            <div>
                              {this.state.manualSearch
                                ? t('general|errors|noResultsCurrentPage')
                                : t('general|errors|No Results Found')}
                            </div>
                          )
                        )
                      ) : (
                        ''
                      )}
                      {this.state.searchResults.length > 0 && !this.state.loader && (
                        <>
                          <AddressPickupResultGrid
                            handleClick={this.onAddressSelect}
                            context={context}
                            data={this.state.data}
                          />
                          {!this.state.showLastModified && !this.state.manualSearch && (
                            <Pagination
                              t={t}
                              simplePagination
                              CONST={CONST}
                              onHandleDisplayRowChange={this.handleDisplayRowChange}
                              context={context}
                              pagination={this.state.pagination}
                              onHandlePageChange={this.handlePageChange}
                            />
                          )}
                        </>
                      )}

                      {showNoResultError && (
                        <Error
                          className="frc__input--error"
                          message={t(
                            'general|errors|No saved addresses were found in the address book for this account',
                          )}
                        />
                      )}
                    </div>
                    <div className={`frc__generic--loader-overlay   ${this.state.loader ? 'is-loading' : ''}`} />
                  </div>
                </div>

                <ErrorModal ref={this.state.errorModal} className="color-secondary-green-1" context={context} />
              </div>
            )}
          </Translation>
        ) : (
          ''
        )}
      </>
    );
  }
}

import { useMemo, useState } from 'react';
import { useGetUser } from '../hooks/api/useGetUser';
import { ACCOUNT_STATUS } from '../utils/accounts';

export const useQuote = () => {
  const { data: userData } = useGetUser();
  const [enabledAccounts, setEnabledAccounts] = useState([]);

  const enableQuote = useMemo(() => {
    const accounts = userData?.tmsAccounts;
    if (accounts) {
      setEnabledAccounts(() => {
        // Enabled account for Quote must be approved and have priceVisible set to true
        return accounts.filter(item => item.status === ACCOUNT_STATUS.APPROVED && item.priceVisible);
      });
      const approvedAccountsCount = accounts.filter(item => item.status === ACCOUNT_STATUS.APPROVED).length;
      const approvedPriceVisibleCount = accounts.filter(
        item => item.priceVisible && item.status === ACCOUNT_STATUS.APPROVED,
      ).length;
      if (approvedPriceVisibleCount > 0 || approvedAccountsCount === 0) {
        return true;
      }
      return false;
    }
    return false;
  }, [userData]);

  return { enableQuote, enabledAccounts };
};

export function withQuote(Component) {
  return function WrappedComponent(props) {
    const useQuoteData = useQuote();
    return <Component {...props} useQuoteData={useQuoteData} />;
  };
}

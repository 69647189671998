import { CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';

export const checkAndGetUnfinishedShipment = context => {
  const resultMap = {};

  apiRequest(apiLinks.getShipmentDataByTemplateId.replace('{templateType}', 0), 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        context.updateState({
          template0Id: result.data[0],
        });
      }
    })
    .catch(() => {
      resultMap.error = true;
    });
};

import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { PhoneNumber } from 'components';

export default class PhoneNumbers extends Component {
  addPhoneNumber = context => {
    const params = {};

    if (context.state.phoneNumbers && context.state.phoneNumbers.length < this.props.config.maxAllowedPhoneNumbers) {
      params.groupName = 'phoneNumbers';
      params.item = { code: this.props.config.defaultCountryPhoneCode, number: '', error: false };
      context.addAnotherGroupField(params);
    }
  };

  removePhoneNumber = (index, context) => {
    const params = {};

    if (context.state.phoneNumbers && context.state.phoneNumbers.length > 1) {
      params.groupName = 'phoneNumbers';
      params.index = index;
      context.removeGroupField(params);
    }
  };

  render() {
    const context = this.props.context;

    return (
      <>
        <Translation>
          {t => (
            // <MyContext.Consumer>
            //   {context => (
            <>
              {context.state.phoneNumbers.slice(0, 1).map((phoneNumber, ind) => (
                <PhoneNumber
                  groupName="phoneNumbers"
                  context={context}
                  config={this.props.config}
                  CONST={this.props.CONST}
                  key={ind}
                  index={ind}
                  number={phoneNumber.number}
                  code={phoneNumber.code}
                  removeOnParent={index => this.removePhoneNumber(index, context)}
                  error={phoneNumber.error}
                  disabled={this.props.disabled}
                  isRequired={true}
                  selectClassName="l-grid--w-100pc-s l-grid--w-40pc-s-m l-grid--w-40pc-s-l l-grid--w-40pc-m frc-select-phone-number--wrapper"
                  inputClassName="l-grid--w-100pc-s l-grid--w-60pc-s-m l-grid--w-60pc-s-l l-grid--w-60pc-m"
                />
              ))}
            </>
            //   )}
            // </MyContext.Consumer>
          )}
        </Translation>
      </>
    );
  }
}

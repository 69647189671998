import { config } from '../../../data-config';
import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';

export const captchaValidations = (context, stateToSet, hasErrors) => {
  const state = context.state;
  const getState = checkFieldAndReturnState({
    name: 'captcha',
    value: state['captcha'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{${config.maxCaptcha},${config.maxCaptcha}}$`)],
    isRequired: true,
  });

  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, token: state['captcha'].token, error: getState.error };

  return {
    stateToSet,
    hasErrors,
  };
};

import React from 'react';
import { formatDate, DATE_FORMAT } from 'globals/utils/formatting';
import { locales } from 'locale/datePicker';
import { useTranslation } from 'react-i18next';
import { getTimeTable } from '../../DeliveryOptions-helpers';
import DatePicker from 'react-datepicker';
import { Error } from 'components';
import { resetDateForLoadingUnloadingTimeDefiniteVas } from '../../../ServiceDetails/ServiceDetails-helpers/ServiceDetails-helpers';

const CheckboxDate = ({
  option,
  context,
  index,
  groupIndex,
  errorId,
  serviceIndex,
  optionParentIndex,
  config,
  loaderRef,
}) => {
  const { t } = useTranslation();

  const handleChange = (date, time = false) => {
    const stateObj = {};
    const additionalServices = context.state.additionalServices;
    let additionalService = {};

    additionalService = JSON.parse(JSON.stringify(additionalServices[serviceIndex]));
    const additionalServiceParentOption = additionalService.groups[groupIndex].options[optionParentIndex];
    const additionalServiceOption = additionalServiceParentOption[index];

    if (time && date && additionalServiceOption.value) {
      const newDate = new Date(additionalServiceOption.value);
      newDate.setHours(date.getHours(), date.getMinutes(), 0);
      date = new Date(newDate);
    }

    if (config.vasSpecialCaseForDeliveryDate.indexOf(additionalService.code) !== -1) {
      date = resetUnloadingDateIfNotLogicalToLoadingDate(date);
    }

    if (date) {
      additionalServiceOption.value = date;
    } else {
      additionalServiceOption.value = new Date();
    }

    additionalServices[serviceIndex] = additionalService;
    stateObj.additionalServices = additionalServices;
    makeTimeTableCallForSpecialCase(date, additionalService, stateObj);
    context.updateState(stateObj);
  };

  const resetUnloadingDateIfNotLogicalToLoadingDate = date => {
    let newDate = date;
    const pickupDateVas = context.state.additionalServices.find(
      vas => config.vasSpecialCaseForPickupDate.indexOf(vas.code) !== -1,
    );

    if (pickupDateVas && pickupDateVas.groups && pickupDateVas.groups.length > 0) {
      pickupDateVas.groups.forEach(group => {
        if (group && group.options) {
          group.options.forEach(optionList => {
            optionList.forEach(eachOption => {
              const optionSchema = eachOption.type === config.OptionFieldTypes.Date;

              if (optionSchema && optionSchema.value) {
                const loadingDate = new Date(optionSchema.value);
                const newUnloadingDate = new Date(date);

                if (loadingDate.getTime() > newUnloadingDate.getTime()) {
                  newDate = loadingDate;
                }
              }
            });
          });
        }
      });
    }

    return newDate;
  };

  const makeTimeTableCallForSpecialCase = (date, additionalService, stateObj) => {
    if (config.vasSpecialCaseForPickupDate.indexOf(additionalService.code) !== -1) {
      const pickupDate = formatDate(context.state.pickupDate.value);
      const newDate = formatDate(date);

      if (pickupDate !== newDate) {
        getTimeTable(date, context, loaderRef.current, true);
      } else {
        stateObj.additionalServices = resetDateForLoadingUnloadingTimeDefiniteVas(
          context.state.additionalServices,
          date,
        );
        stateObj.pickupDate = { value: new Date(date), error: false };
      }
    }
  };

  const getErrorMessage = () => {
    let errorMessage = '';

    if (option.error) {
      errorMessage = t('general|errors|Please select date');
    }

    return <Error name={errorId ? errorId : 'OptionDate'} className="frc__input--error" message={errorMessage} />;
  };

  const unrestrictedDateRange = option?.optionCode === 'invoiceDate';
  const unloadingDate =
    config.vasSpecialCaseForDeliveryDate.indexOf(context.state.additionalServices[serviceIndex].code) !== -1;
  const language = localStorage.getItem(config.locale.localStorageVariable) || config.locale.defaultLanguage;
  const minDate = unloadingDate ? new Date(context.state.pickupDate.value) : new Date();
  const maxDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
  const options = context.state.additionalServices[serviceIndex].groups[groupIndex].options[optionParentIndex];

  return (
    <div className="l-grid--w-100pc-s frc__as-option--wrapper">
      {t(`publicOrder|` + option.optionCode)}
      <div className="l-grid frc__as-option--wrapper l-grid--middle-s l-grid--between-s">
        <div className="l-grid l-grid--w-100pc-s l-grid--w-60pc-s-m l-grid--w-50pc-s-l l-grid--w-70pc-m l-grid--w-100pc-m-s">
          <div className="l-grid--w-50pc-s l-grid--w-100pc-s-s frc__datepicker">
            <DatePicker
              selected={new Date(options[index].value)}
              className="c-form--element-base c-form--element-input c-calculator--input-field"
              showDisabledMonthNavigation
              minDate={!unrestrictedDateRange && minDate}
              maxDate={!unrestrictedDateRange && maxDate}
              onChange={date => handleChange(date)}
              locale={locales[language]}
              dateFormat={DATE_FORMAT}
            />
          </div>
        </div>
      </div>
      {option.error && option.errorMessage ? getErrorMessage() : ''}
    </div>
  );
};

export default CheckboxDate;

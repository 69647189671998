import { config } from '../../../data-config';
import { returnProperValueOrNull } from 'globals/utils/fieldValidations';

export const formPieces = state => {
  const shipmentDetails = state.shipmentDetailsRows;
  const shipmentList = [];

  shipmentDetails.forEach(shipment => {
    shipmentList.push(shipmentData(shipment));
  });

  return shipmentList;
};

const shipmentData = shipmentRow => {
  const shipmentDataObj = {
    id: [''],
    height: shipmentRow.height.value,
    length: shipmentRow.length.value,
    loadingMeters: returnProperValueOrNull(shipmentRow.loadingMeter.value),
    loadingMetres: returnProperValueOrNull(shipmentRow.loadingMeter.value),
    numberOfPieces: shipmentRow.quantity?.value || null,
    packageType: config.packageType[shipmentRow.shipmentType.value],
    stackable: !shipmentRow.nonStackable.value,
    volume: returnProperValueOrNull(shipmentRow.volume.value),
    weight: returnProperValueOrNull(shipmentRow.weight.value) || null,
    width: shipmentRow.width?.value || null,
    goodsType: shipmentRow.goodsDescription?.value || null,
    marksAndNumbers: shipmentRow.shippingMark?.value || null,
  };

  return shipmentDataObj;
};

import { Box, Typography } from '@mui/material';

import { Link } from 'react-router-dom';

/**
 * Based on src/pages/ErrorPageContent/ErrorPageRedirections.js
 */

export const TranslationsError = () => {
  return (
    <Box textAlign="center">
      <Typography variant="h1" color="primary" fontWeight="bold" mt="80px" mb="20px">
        Sorry!
      </Typography>
      <Typography variant="body1" mb="80px">
        Unfortunately the translation file failed to load.
      </Typography>
      <Box>
        <div className="component-margin c-icon-list l-grid l-grid l-grid--center-m">
          <h3 className="component-small l-grid--w-100pc-s">Maybe You Were Looking for One of These?</h3>
          <div className="c-icon-list--container l-grid l-grid--w-100pc-s l-grid--center-s l-grid--center-m">
            <Link
              to={'/'}
              className="c-icon-list--item"
              title={'Home'}
              data-tracking='{"component":"fcp/components/elements/iconlist-element"}'
            >
              <div className="c-icon-list--icon">
                <img src="/assets/img/glo-icon-green.svg" alt="" />
              </div>
              <span className="c-icon-list--icon-headline ">Home</span>
            </Link>
            <Link
              to={'https://www.logistics.dhl/se-en/home/tracking.html'}
              className="c-icon-list--item"
              target="_blank"
              title={'Tracking'}
              data-tracking='{"component":"fcp/components/elements/iconlist-element"}'
              rel="noopener noreferrer"
            >
              <div className="c-icon-list--icon">
                <img src="/assets/img/glo-core-pictogr.svg" alt="" />
              </div>
              <span className="c-icon-list--icon-headline has-icon link-external">Tracking</span>
            </Link>

            <Link
              to={'https://www.dhl.com/se-en/home/get-a-quote.html'}
              className="c-icon-list--item"
              title={'Quote'}
              data-tracking='{"component":"fcp/components/elements/iconlist-element"}'
            >
              <div className="c-icon-list--icon">
                <img src="/assets/img/glo-icon-general-search.svg" alt="" />
              </div>
              <span className="c-icon-list--icon-headline ">Quote</span>
            </Link>
          </div>
        </div>
      </Box>
    </Box>
  );
};

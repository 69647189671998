import { Error } from 'components';
import { domesticHolidays } from 'globals/data-holidays';
import { DATE_FORMAT, formatDate } from 'globals/utils/formatting';
import getLanguage from 'globals/utils/getLanguage';
import { locales } from 'locale/datePicker';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useCountryLanguage } from '../../../hooks/useCountryLanguage';
import { config } from '../../data-config';
import { DOMESTIC_SHIPMENT_RANGE, INTERNATIONAL_SHIPMENT_RANGE } from '../../../globals/constants';
import { useGetUserAccounts } from '../../../hooks/api/useGetUserAccountsProducts';
import { useDispatch, useSelector } from 'react-redux';
import { getIntersectionOfDates } from '../../../utils/dates';

const PickupDate = ({
  onGetTimeTable,
  isReturnProduct = false,
  disabled,

  isPortalOrder,

  contextUpdateField,
  contextUpdateState,
  contextPickupDateError,
  contextSelectedProduct,
  contextMatchedProducts,
  additionalServices,

  shipmentRange,
  payingAccountNumber,
  pickupMinDate,

  // redux
  pickupDatesSelector,
  productPickupDatesSelector,
  selectedPickupDateSelector,
  setPickupDates,
  setSelectedPickupDate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { pickupDates } = useSelector(pickupDatesSelector);
  const { selectedPickupDate } = useSelector(selectedPickupDateSelector);
  const { productPickupDates, defaultPickupDate } = useSelector(productPickupDatesSelector);

  const { country } = useCountryLanguage();
  const isCountrySweden = country.toLowerCase() === 'se';

  const { data: userAccounts } = useGetUserAccounts({ select: data => data?.accounts });

  const minDate = pickupDates?.length ? pickupDates[0] : undefined;
  const maxDate = pickupDates?.length ? pickupDates[pickupDates.length - 1] : undefined;

  const language = getLanguage(config.regEx.urlValidation);
  const holidays = domesticHolidays || [];

  useEffect(() => {
    const selectedProductCode = contextSelectedProduct?.code;

    if (selectedProductCode && productPickupDates) {
      if (
        Object.keys(productPickupDates)
          .map(item => item.toLowerCase())
          .includes(selectedProductCode?.toLowerCase())
      ) {
        dispatch(setPickupDates(productPickupDates[selectedProductCode]));
        let dateNotExistInPickupDates = true;
        productPickupDates[selectedProductCode].forEach(date => {
          if (formatDate(date) === formatDate(selectedPickupDate)) {
            dateNotExistInPickupDates = false;
          }
        });
        if (dateNotExistInPickupDates) {
          dispatch(setSelectedPickupDate(productPickupDates[selectedProductCode][0]));
        }
      }
    } else if (productPickupDates) {
      const dateSet = new Set();
      for (const key in productPickupDates) {
        productPickupDates[key].forEach(date => dateSet.add());
      }
      const uniqueDatesIntersection = getIntersectionOfDates(productPickupDates).map(item =>
        new Date(item)?.toISOString(),
      );
      dispatch(setPickupDates(uniqueDatesIntersection));
      if (!selectedPickupDate) {
        dispatch(setSelectedPickupDate(defaultPickupDate || uniqueDatesIntersection[0]));
      }
    } else {
      dispatch(setPickupDates([]));
    }
  }, [
    productPickupDates,
    contextSelectedProduct,
    dispatch,
    defaultPickupDate,
    selectedPickupDate,
    setPickupDates,
    setSelectedPickupDate,
  ]);

  useEffect(() => {
    const minDateObj = new Date(minDate);
    const minDateTimestamp = minDateObj?.getTime();
    const selectedPickupDateObj = new Date(selectedPickupDate);
    const selectedDateTimestamp = selectedPickupDateObj?.getTime();
    const isSelectedDateBeforeMinDate = selectedDateTimestamp < minDateTimestamp;

    if (isSelectedDateBeforeMinDate && minDate && selectedPickupDateObj) {
      contextUpdateState({
        pickupDate: {
          value: minDateObj,
        },
      });
    }
  }, [selectedPickupDate, minDate, contextUpdateState]);

  useEffect(() => {
    if (pickupDates.length > 0) {
      contextUpdateState({
        pickupMinDate: new Date(minDate),
      });
    }
  }, [minDate, pickupDates, contextUpdateState]);

  const handleChange = (date, e) => {
    e.preventDefault();

    const getNewDate = () => {
      if (date) {
        return new Date(date).toISOString();
      }
      return pickupDates[0];
    };
    const newDate = getNewDate();
    dispatch(setSelectedPickupDate(newDate));

    const params = {
      name: 'pickupDate',
      value: date || formatDate(pickupDates[0]),
      error: contextPickupDateError,
    };

    contextUpdateField(params);
  };

  /**
   * Check cutoff dates where applicable
   * and adjust the pickup date if necessary
   */
  useEffect(() => {
    if (!contextSelectedProduct?.code) {
      return;
    }
    const matchedProductForPickupDates = contextMatchedProducts.find(prod => {
      return (
        config.desiredPickupDateMainProductCode.includes(contextSelectedProduct.code) &&
        prod.code === contextSelectedProduct.code
      );
    });
    if (
      matchedProductForPickupDates &&
      !matchedProductForPickupDates.possible_pickups &&
      shipmentRange &&
      (shipmentRange === INTERNATIONAL_SHIPMENT_RANGE ||
        (shipmentRange === DOMESTIC_SHIPMENT_RANGE && !isCountrySweden))
    ) {
      const loadPickupDate = async () => {
        let desiredPickupDate = matchedProductForPickupDates.desiredPickupDates;
        if (desiredPickupDate) {
          return desiredPickupDate;
        }
      };
      loadPickupDate().then(desiredPickupDate => {
        const formattedSelectedDate = formatDate(selectedPickupDate);
        const formattedDesiredPickupDate = formatDate(desiredPickupDate?.pickupDate?.value);

        const selectedDateObj = new Date(formattedSelectedDate);
        const desiredPickupDateObj = new Date(formattedDesiredPickupDate);

        if (desiredPickupDate && selectedDateObj < desiredPickupDateObj) {
          contextUpdateState({
            pickupDate: {
              value: desiredPickupDate.pickupDate.value,
            },
          });
        }
      });
    }
  }, [
    contextSelectedProduct?.code,
    isCountrySweden,
    selectedPickupDate,
    contextUpdateState,
    userAccounts,
    contextMatchedProducts,
    shipmentRange,
    payingAccountNumber,
    pickupMinDate,
  ]);

  useEffect(() => {
    if (onGetTimeTable && selectedPickupDate && additionalServices?.length && isPortalOrder) {
      onGetTimeTable(new Date(selectedPickupDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPickupDate, additionalServices?.length, isPortalOrder]);

  return (
    <div className="margin-bottom-0">
      <h4 className="margin-bottom-1 frc__red-lined-title">
        {isReturnProduct ? t('general|Shipping Date') : t('general|Pickup Date')}
      </h4>
      <DatePicker
        id={'shippingOrPickupDate'}
        autoComplete="off"
        disabled={disabled || !pickupDates?.length || !minDate || !maxDate}
        selected={selectedPickupDate ? new Date(selectedPickupDate) : null}
        className="c-form--element-base c-form--element-input c-calculator--input-field"
        showDisabledMonthNavigation
        minDate={new Date(minDate)}
        maxDate={new Date(maxDate)}
        onChange={(date, e) => handleChange(date, e)}
        locale={locales[language]}
        dateFormat={DATE_FORMAT}
        dayClassName={date =>
          holidays.indexOf(new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]) > -1
            ? 'color-DHL-red'
            : undefined
        }
        includeDates={pickupDates.map(item => new Date(item))}
      />

      {!pickupDates.length ? (
        <Error name="noPickupDatesError" className="frc__input--error" message="No pickup dates available." />
      ) : (
        <>
          {!selectedPickupDate && (
            <Error
              name="shippingOrPickupDateError"
              className="frc__input--error"
              message={t('general|errors|Please select date')}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PickupDate;

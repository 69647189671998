import { Error } from 'components';
import { debounce } from 'globals/utils/debounce';
import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { config } from '../../data-config';
import ShipmentDataGridPagination from '../ShipmentDataGrid/ShipmentDataGrid';
import ShipmentDetails from '../ShipmentDetails/ShipmentDetails';
import FilterShipments from './FilterShipments/FilterShipments';
import ShipmentSearch from './ShipmentSearch/ShipmentSearch';

import { useSearchParams } from 'react-router-dom';
import { ShipmentsTable } from '../../../components/fcp-components/shipment-table';
import './MyOnlineShipments.css';
import { getShipmentDetailById } from "./MyOnlineShipments-helpers";

export default function MyOnlineShipments(props) {
  const { context, url } = props;
  let [searchParams] = useSearchParams();
  const [savedShipment, setSavedShipment] = useState(null);
  const [loader, setLoader] = useState(null);

  const shipmentId = searchParams.get('shipmentId');

  const updatePrintList = debounce(shipmentsListToPrint => {
    props.context.updateState({
      shipmentsPrintList: shipmentsListToPrint,
    });
  }, config.standardDebounceTimeout);

  useEffect(() => {
    const ENV_DEV = process.env.NODE_ENV === 'development';
    const removeShipmentIdIfShipmentNotFound = () => {
      if (config.regEx.urlValidation.test(window.location.pathname.split('/')[1]) || ENV_DEV) {
        const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
        window.history.pushState({ path: newUrl }, '', newUrl);
      }
    };

    async function fetchShipmentDetails() {
      setLoader(true);
      const shipment = await getShipmentDetailById(shipmentId);
      if (shipment) {
        setSavedShipment(shipment);
      } else {
        removeShipmentIdIfShipmentNotFound()
      }
      setLoader(false);
    }

    if (shipmentId) {
      fetchShipmentDetails().then();
    } else {
      setSavedShipment(null);
    }
  }, [shipmentId]);

  if (savedShipment) {
    return <ShipmentDetails savedShipment={savedShipment} />;
  } else {
    return (
        <>
          <div className={`frc__generic--loader-overlay   ${loader ? 'is-loading' : ''}`} />
        <div className="frc__generic-section--wrapper">
          <Translation>
            {t => (
              <>
                <div className="l-grid">
                  <h3 className="margin-bottom-1">
                    <span className="frc__app-header">{t(`portalSavedShipments|My Online Shipments`)}</span>
                  </h3>

                  <div className="frc__tooltip-page-title frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container">
                    <div className="frc__tooltip-container-flyout">
                      {t(`portalSavedShipments|My Online Shipments info`)}
                    </div>
                  </div>
                </div>

                {t(`portalSavedShipments|Recent shipments booked using this portal. For other shipments, see`) + ' '}
                <a
                  className="link link-external link-red has-icon"
                  target="_blank"
                  id="activeTracingId"
                  rel="noopener noreferrer"
                  href={t('pageLinks|activeTracing')}
                >
                  {t(`portalSavedShipments|DHL Active Tracing`)}
                </a>

                <div className="l-grid l-grid--between-s">
                  <div className="l-grid--w-100pc-s l-grid--w-30pc-m-s  l-grid--w-20pc-m-m margin-bottom-1">
                    <FilterShipments context={context} />
                  </div>
                  <div className="frc__generic-section--wrapper l-grid--w-100pc-s l-grid--w-70pc-m l-grid--w-80pc-m-m margin-bottom-1">
                    <ShipmentSearch context={context} onShowSuccessToaster={props.onShowSuccessToaster} />
                    {context.state.manualSearch && (
                      <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper frc__pagination-magin-top  l-grid--w-100pc-s l-grid--w-30pc-m-s l-grid--w-30pc-m-m">
                        {context.state.savedShipments.length > 0
                          ? t('general|Total Results Found', {
                              nos: context.state.savedShipments.length,
                            })
                          : t('general|errors|No Results Found')}
                      </div>
                    )}
                    <ShipmentsTable
                      shipments={context.state.savedShipments}
                      loading={loader}
                      error={context.state.apiError}
                      tableProps={{
                        hideFooter: true,
                        pageSizeOptions: [10, 25, 50, 100],
                      }}
                      context={context}
                      showUserEmail
                    />
                    <ShipmentDataGridPagination
                      t={t}
                      url={url}
                      showPagination={true}
                      context={context}
                      onShowSuccessToaster={props.onShowSuccessToaster}
                      onPrintCheckBoxSelected={updatePrintList}
                    />
                    {context.state.apiError && (
                      <Error
                        name={'addressSearchError'}
                        className="frc__input--error"
                        message={t('general|errors|General error')}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <p className="frc__detailed-information">{t(`general|book price info`)}</p>
                </div>
              </>
            )}
          </Translation>
        </div>
      </>
    );
  }
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../Template-Address.css';

export default function AddressCard({ context, address, handleClick }) {
  const { t } = useTranslation();

  return (
    <div
      key={address.id}
      className="frc__addresspickup-card frc__addresspickup-card-width"
      onClick={() => handleClick(address.id, context)}
    >
      <strong>{address.companyName}</strong>
      <div>{address.name}</div>
      <div>{address.street}</div>
      <div>
        {address.city}, {t(`countries|${address.countryCode}`)}
      </div>
    </div>
  );
}

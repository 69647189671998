import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Error as FcpError } from 'components';
import './ShipmentRowsSummary.css';
import { Box, Stack, InputLabel, TextField } from '@mui/material';
import { TWO_DECIMALS_INPUT_REGEX } from '../../utils/regexes';
import { getTotalLoadingMeterFields } from '../../globals/helpers/shipment';

export const ShipmentRowsSummary = ({ context }) => {
  const { t } = useTranslation();

  const totals = context.state.totals.shipmentDetailsRows;
  const dimensions = context.state.dimensions || {};

  const {
    someHaveLoadingMeter,
    loadingMeterMin,
    loadingMeterMax,
    loadingMeterError,
    loadingMeterSum,
    sumError,
    sumMaxError,
    pieceLdmError,
    disabled,
  } = getTotalLoadingMeterFields({ state: context.state });

  const quantityError =
    totals.quantity.value * 1 < dimensions.min_total_quantity ||
    totals.quantity.value * 1 > dimensions.max_total_quantity;

  const weightError =
    (totals.weight.value * 1 < dimensions.min_shipment_weight && totals.weight.value * 1 > 0) ||
    totals.weight.value * 1 > dimensions.max_shipment_weight;

  const volumeError =
    totals.volume.value * 1 < dimensions.min_total_volume || totals.volume.value * 1 > dimensions.max_total_volume;

  let chargeableWeightError;
  if (totals.chargeableWeight.value !== null) {
    chargeableWeightError =
      totals.chargeableWeight.value * 1 < dimensions.min_total_chargeableWeight ||
      totals.chargeableWeight.value * 1 > dimensions.max_total_chargeableWeight;
  }

  const getLoadingMeterErrorMessage = ({ sumError, sumMaxError, pieceLdmError }) => {
    if (sumMaxError) {
      return t('general|errors|loadingMeterSumMaxError', {
        loadingMeterSum: loadingMeterSum,
        productMax: loadingMeterMax,
      });
    }

    if (sumError) {
      return t('general|errors|loadingMeterSumError', {
        loadingMeterSum: loadingMeterSum,
      });
    }

    if (pieceLdmError) {
      return t('general|errors|globalLoadingMeterError');
    }

    return t('general|errors|invalidValue', {
      name: t('general|labels|metrics|Total Loading Meter'),
      min: loadingMeterMin,
      max: loadingMeterMax,
    });
  };

  useEffect(() => {
    if (!disabled) return;
    if (context?.state?.totals?.shipmentDetailsRows?.loadingMeter?.value === '') {
      return;
    }

    const newState = structuredClone(context.state);
    newState.totals.shipmentDetailsRows.loadingMeter.value = '';
    context.updateState(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', md: 'row' }} gap={3}>
      <Stack className="frc__total-errors--wrapper">
        {quantityError ? (
          <FcpError
            name="invalidTotalQuantity"
            className="frc__input--error"
            message={t('general|errors|invalidTotalQuantity', {
              min: dimensions.min_total_quantity,
              max: dimensions.max_total_quantity,
            })}
          />
        ) : (
          ''
        )}

        {volumeError ? (
          <FcpError
            name="invalidTotalVolume"
            className="frc__input--error"
            message={t('general|errors|invalidValue', {
              name: t('general|labels|metrics|Total Volume'),
              min: dimensions.min_total_volume,
              max: dimensions.max_total_volume,
            })}
          />
        ) : (
          ''
        )}

        {weightError ? (
          <FcpError
            name="invalidTotalWeight"
            className="frc__input--error"
            message={t('general|errors|invalidValue', {
              name: t('general|labels|metrics|Total Weight'),
              min: dimensions.min_shipment_weight,
              max: dimensions.max_shipment_weight,
            })}
          />
        ) : (
          ''
        )}

        {!disabled && loadingMeterError && (
          <FcpError
            name="invalidTotalLoadingMeter"
            className="frc__input--error"
            message={getLoadingMeterErrorMessage({ sumError, sumMaxError, pieceLdmError })}
          />
        )}

        {chargeableWeightError ? (
          <FcpError
            name="invalidTotalChargeableWeight"
            className="frc__input--error"
            message={
              t('general|errors|invalidValue', {
                name: t(`general|Chargeable weight`),
                min: dimensions.min_total_chargeableWeight,
                max: dimensions.max_total_chargeableWeight,
              }) +
              '. ' +
              t('general|errors|Please try a different product or contact us.')
            }
          />
        ) : (
          ''
        )}
      </Stack>

      <Stack pb="32px" spacing={0.5}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <InputLabel
            required={someHaveLoadingMeter}
            sx={{ fontSize: '15px' }}
            className={loadingMeterError ? 'color-dhl-red' : ''}
            disabled={disabled}
          >
            {t('general|labels|metrics|Total Loading Meter')}:
          </InputLabel>
          <TextField
            id="miniSummaryTotalLoadingMeter"
            sx={{
              width: '100px',
              '& .Mui-disabled': {
                background: 'var(--color-secondary--grey-3)',
              },
            }}
            value={totals.loadingMeter.value}
            onChange={e => {
              if (TWO_DECIMALS_INPUT_REGEX.test(e.target.value)) {
                const newState = structuredClone(context.state);
                newState.totals.shipmentDetailsRows.loadingMeter.value = e.target.value.replace(',', '.');
                context.updateState(newState);
              }
            }}
            error={loadingMeterError}
            disabled={disabled}
            inputProps={{
              style: {
                height: '15px',
                textAlign: 'right',
              },
            }}
          />
        </Stack>
        <div className={'frc__shipment-summary--row' + (quantityError ? ' color-dhl-red ' : '')}>
          <div>{t('general|labels|metrics|Total Quantity')}: </div>
          <div id="miniSummaryTotalQuantity">{totals.quantity.value}</div>
        </div>
        <div className={'frc__shipment-summary--row' + (volumeError ? ' color-dhl-red ' : '')}>
          <div>{t('general|labels|metrics|Total Volume')}: </div>
          <div>
            <span id="miniSummaryTotalVolume">{totals.volume.value}</span> {t('general|m')}
            <sup>3</sup>
          </div>
        </div>
        <div className={'frc__shipment-summary--row' + (weightError ? ' color-dhl-red ' : '')}>
          <div>{t('general|labels|metrics|Total Weight')}: </div>
          <div>
            <span id="miniSummaryTotalWeight">{totals.weight.value}</span> {t('general|kg')}
          </div>
        </div>
      </Stack>
    </Box>
  );
};

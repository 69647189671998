import { LANGUAGES } from '../../locale/config';

const getLanguage = urlValidation => {
  let path = '';
  if (new RegExp(urlValidation).test(window.location.pathname.split('/')[1])) {
    path = window.location.pathname;
  }
  return path.split('/')[1] ? path.split('/')[1].split('-')[1] : LANGUAGES.en;
};

export default getLanguage;

import { CONST, config } from '../../../../data-config';

export const getRegexForDecimalNumber = option => {
  const regex = config.regEx.decimalPoint
    .toString()
    .replace('{decimalPoint}', option.decimals * 1)
    .replace('{minLength}', option.minLength * 1)
    .replace('{maxLength}', option.maxLength * 1 - option.decimals * 1);

  return new RegExp(regex);
};

export const getLengthForVAS = (optionType, optionLength) => {
  if (!optionLength) {
    switch (optionType) {
      case config.OptionFieldTypes.inputTypes.Email:
        optionLength = CONST.EMAIL_LENGTH;
        break;
      case config.OptionFieldTypes.inputTypes.Currency:
        optionLength = CONST.CURRENCY_LENGTH;
        break;

      default:
        optionLength = config.maxDefaultInputLength;
    }
  }
  return optionLength;
};

export const getRegEx = optionType => {
  let result = '';

  switch (optionType) {
    case config.OptionFieldTypes.inputTypes.Number:
      result = config.regEx.pureNumber;
      break;
    case config.OptionFieldTypes.inputTypes.NumericText:
      result = config.regEx.number;
      break;
    case config.OptionFieldTypes.inputTypes.Decimal:
      result = config.regEx.float;
      break;
    case config.OptionFieldTypes.inputTypes.Alphanumerical:
      result = config.regEx.everything;
      break;
    case config.OptionFieldTypes.inputTypes.Email:
      result = config.regEx.everything;
      break;
    case config.OptionFieldTypes.inputTypes.Currency:
      result = config.regEx.currency;
      break;

    default:
      result = config.regEx.everything;
  }

  return result;
};

export const handleExcludingConditionalGroup = (options, optionIndex, disabled) => {
  if (options[optionIndex].conditionalGroup && options[optionIndex].conditionalGroup.length > 0) {
    options.forEach((opt, ind) => {
      if (ind !== optionIndex && options[optionIndex].conditionalGroup.indexOf(opt.optionCode) !== -1) {
        options[ind].disabled = disabled;
        options[ind].value = '';
      }
    });
  }
};

import { createContext, useCallback, useContext, useMemo } from 'react';

let CountryLanguageContext = createContext();

export const CountryLanguageProvider = ({ children }) => {
  const countryLanguage = localStorage.getItem('i18nextLng');
  const country = localStorage.getItem('country');
  const language = localStorage.getItem('language');

  let clean = useCallback(callback => {
    localStorage.removeItem('country');
    localStorage.removeItem('language');
  }, []);

  let value = useMemo(
    () => ({
      country,
      language,
      countryLanguage,
      clean,
    }),
    [country, language, countryLanguage, clean],
  );

  return <CountryLanguageContext.Provider value={value}>{children}</CountryLanguageContext.Provider>;
};

export function useCountryLanguage() {
  return useContext(CountryLanguageContext);
}

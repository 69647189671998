import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import { CONSIGNEE_CUSTOMER_TYPE, CONSIGNOR_CUSTOMER_TYPE } from '../../../../globals/constants';
import { CONST } from '../../../data-config';

export const setLoadersState = (context, state) => {
  const stateObject = {};

  stateObject.loaders = JSON.parse(JSON.stringify(context.state.loaders));
  stateObject.loaders.shipmentDetails.value = state;

  return stateObject;
};

const prepareRequestJSON = (context, productCode) => {
  let params = {};

  params.body = {
    productCode: productCode,
    weight: context.state.totals.shipmentDetailsRows.weight.value,
    volume: context.state.totals.shipmentDetailsRows.volume.value,
    loadingMetres: context.state.totals.shipmentDetailsRows.loadingMeter.value,
  };

  params.body.pieces = [];

  context.state.shipmentDetailsRows.forEach(row => {
    let pieces = {
      length: row.length?.value || null,
      width: row.width?.value || null,
      height: row.height?.value || null,
      weight: row.weight.value,
      numberOfPieces: row.quantity.value,
      stackable: !row.nonStackable.value,
    };

    params.body.pieces.push(pieces);
  });

  params.body.parties = [];

  let Consignor = {
    type: CONSIGNOR_CUSTOMER_TYPE,
    address: {
      postalCode: context.state.pickupPostalCode.value,
      countryCode: context.state.pickupCountry.value,
    },
    id: context.state.accountNumber.value,
  };
  params.body.parties.push(Consignor);

  let Consignee = {
    type: CONSIGNEE_CUSTOMER_TYPE,
    address: {
      postalCode: context.state.deliveryPostalCode?.value || null,
      countryCode: context.state.deliveryCountry.value,
    },
  };
  params.body.parties.push(Consignee);

  return params.body;
};

export const chargeableWeightValidations = async (context, stateToSet, hasErrors) => {
  if (!hasErrors && context.state.selectedProducts.length === 1) {
    const productData = context.state[context.state.selectedProducts[0]];
    const url = apiLinks.getChargeableWeight;
    let totals;
    const params = {};
    let callbackState = {};
    const dimensions = context.state.dimensions || {};

    if (context.state.minMaxDimensions.min_chargeable_weight_check_product_codes.indexOf(productData.code) > -1) {
      // Show loader
      Object.assign(callbackState, setLoadersState(context, true));
      context.updateState(callbackState);

      params.body = prepareRequestJSON(context, productData.code);

      await apiRequest(url, 'POST', params)
        .then(result => {
          if (result.status === CONST.STATUS_OK) {
            totals = JSON.parse(JSON.stringify(context.state.totals));
            totals.shipmentDetailsRows.chargeableWeight.value = result.data.chargeableWeight;
            // callbackState.totals = totals;
            stateToSet.totals = totals;

            if (totals.shipmentDetailsRows.chargeableWeight !== null) {
              hasErrors =
                totals.shipmentDetailsRows.chargeableWeight.value * 1 < dimensions.min_total_chargeableWeight ||
                totals.shipmentDetailsRows.chargeableWeight.value * 1 > dimensions.max_total_chargeableWeight;
            }
          }
        })
        .catch(() => {
          // handle error
        });
      // Remove loader
      Object.assign(stateToSet, setLoadersState(context, false));
    }
  }

  return {
    stateToSet,
    hasErrors,
  };
};

import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ANALYTICS_ROUTES } from '../adobe-analytics/analytics-routes';
import { useCountryLanguage } from '../hooks/useCountryLanguage';

const ANALYTICS_META_NAME = 'analyticsId';

export const Analytics = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const countryLanguage = useCountryLanguage();
  const [analyticsRoute, setAnalyticsRoute] = useState(undefined);

  useEffect(() => {
    const currentPathName = location.pathname?.split('/');
    const routeMatch = ANALYTICS_ROUTES.find(item => {
      return currentPathName && item.path === currentPathName[2];
    });
    setAnalyticsRoute(routeMatch);
    return () => {
      const metaTag = document.querySelector(`meta[name="${ANALYTICS_META_NAME}"]`);
      if (metaTag) {
        metaTag.remove();
      }
    };
  }, [location]);

  return (
    <Helmet htmlAttributes={{ lang: countryLanguage?.language?.toLowerCase() || 'en' }}>
      <meta name={ANALYTICS_META_NAME} content={analyticsRoute ? analyticsRoute.analyticsId : ''} />
      <title>
        {analyticsRoute && analyticsRoute.title
          ? t(`general|pageBrowserTitles|${analyticsRoute.title}`)
          : 'myDHLFreight'}
      </title>
    </Helmet>
  );
};

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Collapse, Container, Dialog, Slide, Stack, Typography } from '@mui/material';
import parse from 'html-react-parser';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { GLOBAL_CONST } from '../../globals/data-config-global';
import { useCountryLanguage } from '../../hooks/useCountryLanguage';
import { useGetCmsPhrasesQuery } from '../../store/api/fcpCms';
import { setCookie } from '../../utils/cookies';

const COOKIE_DISCLAIMER_KEY = 'cookieDisclaimer';
const COOKIE_DISCLAIMER_VALUE = 'seen';
const COOKIE_PAGE_CODE = 'cookieDisclaimer';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SlideRight = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export const CookieDisclaimer = () => {
  const { t } = useTranslation();
  const countryLanguage = useCountryLanguage();
  const [searchParams] = useSearchParams();
  const [cookieListOpen, setCookieListOpen] = useState(false);
  const [seen, setSeen] = useState(Cookies.get(COOKIE_DISCLAIMER_KEY) === COOKIE_DISCLAIMER_VALUE);

  const { data } = useGetCmsPhrasesQuery({
    pageCode: GLOBAL_CONST.PAGE_CODES.LAYOUT,
    lang: countryLanguage.language,
    countryCode: countryLanguage.country,
    isPreview: !!searchParams.get('preview'),
  });

  const cookieDisclaimer = data?.find(phrase => phrase.code === 'cookie-disclaimer')?.translationValue;

  const acceptCookies = () => {
    setCookie(COOKIE_DISCLAIMER_KEY, COOKIE_DISCLAIMER_VALUE, 365);
    setSeen(true);
  };

  return (
    <>
      <Dialog
        open={Boolean(cookieDisclaimer) && !seen}
        TransitionComponent={Transition}
        fullWidth
        transitionDuration={2000}
        sx={{
          '& .MuiDialog-paperFullWidth': {
            minWidth: '100vw',
            position: 'absolute',
            m: 0,
            p: '20px !important',
            bottom: 0,
            left: 0,
            backgroundColor: '#333333',
          },
        }}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 3,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              color: 'white',
              fontSize: '14px',
              '& a': { color: 'white !important', textDecoration: 'underline', whiteSpace: 'nowrap' },
            }}
          >
            {cookieDisclaimer && parse(cookieDisclaimer)}
          </Box>
          <Stack direction="row" spacing={2}>
            <Button
              id="accept-cookie"
              data-testid="accept-cookie-close-btn"
              data-tracking='{"component":"fcp/components/global/cookie-disclaimer","name":"static.cookie-disclaimer.ok"}'
              onClick={acceptCookies}
              variant="contained"
              sx={{ flexShrink: 0, height: '42px', width: '120px' }}
            >
              {t('general|labels|buttons|Close')}
            </Button>
            <Button
              id="accept-cookie"
              data-testid="accept-cookie-cookie-list-btn"
              variant="contained"
              sx={{ flexShrink: 0, minHeight: '42px', height: 'fit-content', width: '120px' }}
              onClick={() => setCookieListOpen(true)}
            >
              {t('cookieDisclaimer|cookieList')}
            </Button>
          </Stack>
        </Container>
      </Dialog>
      <CookieListDialog open={cookieListOpen} onClose={() => setCookieListOpen(false)} />
    </>
  );
};

const CookieListDialog = ({ open, onClose }) => {
  const [expandList, setExpandList] = useState(false);
  const { t } = useTranslation();
  const countryLanguage = useCountryLanguage();
  const [searchParams] = useSearchParams();

  const { data } = useGetCmsPhrasesQuery({
    pageCode: COOKIE_PAGE_CODE,
    lang: countryLanguage.language,
    countryCode: countryLanguage.country,
    isPreview: !!searchParams.get('preview'),
  });

  const cookieList = data?.find(phrase => phrase.code === 'cookie-list')?.translationValue;

  return (
    <Dialog
      open={open}
      TransitionComponent={SlideRight}
      fullWidth
      transitionDuration={2000}
      sx={{
        '& .MuiDialog-paperFullWidth': {
          position: 'absolute',
          m: 0,
          p: '30px !important',
          minHeight: '20vh',
          bottom: 0,
          left: 0,
          backgroundColor: '#333333',
        },
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
        <Box display="flex" alignSelf="stretch" justifyContent="space-between">
          <Typography color="primary.light" variant="h4">
            {t('cookieDisclaimer|cookieListTitle')}
          </Typography>
          <Typography color="text.secondary">{t('cookieDisclaimer|alwaysActive')}</Typography>
        </Box>
        <Typography color="primary.light">{t('cookieDisclaimer|cookieListDescription')}</Typography>
        <Button
          variant="text"
          startIcon={expandList ? <RemoveIcon /> : <AddIcon />}
          data-testid="cookie-disclaimer-button"
          onClick={() => setExpandList(prev => !prev)}
        >
          <Stack alignItems="flex-start">
            <Typography variant="h4" color="primary.light">
              {t('cookieDisclaimer|firstPartyCookies')}
            </Typography>
            <Typography variant="body2" color="primary.light">
              {t('cookieDisclaimer|viewCookies')}
            </Typography>
          </Stack>
        </Button>
        <Collapse in={expandList} timeout={1500} sx={{ maxHeight: '30vwh' }}>
          <Box
            maxHeight="30vwh"
            sx={{
              '& table': { color: 'black', borderCollapse: 'collapse' },
              '& tr:nth-of-type(7n)': {
                backgroundColor: '#333333',
                '& td': {
                  backgroundColor: '#333333',
                },
              },
              '& td': {
                p: '10px',
                backgroundColor: 'background.section',
              },
            }}
          >
            {cookieList && parse(cookieList)}
          </Box>
        </Collapse>
        <Box display="flex" justifyContent="flex-end" alignSelf="stretch">
          <Button variant="contained" sx={{ height: '42px', width: '120px' }} onClick={onClose} data-testid="close-btn">
            {t('general|labels|buttons|Close')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

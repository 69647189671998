import * as React from 'react';
import { CONST } from '../../data-config';
import { envConfig } from '../../../lib/envConfig/envConfig';

// const ENV_DEV = process.env.NODE_ENV === 'development';

/**
 * HEREMap universal component
 *
 * @param mapCenter = {lat: xxx, lng:xxx}
 * @param markers = [{lat: xxx, lng:xxx}, {lat: xxx, lng:xxx}]
 */
export const HEREMap = ({ mapCenter, markers }) => {
  const mapRef = React.useRef(null);
  const [map, setMap] = React.useState(undefined);

  React.useLayoutEffect(() => {
    if (!mapRef.current) return;
    if (!map) {
      const H = window.H;
      const platform = new H.service.Platform({
        apikey: envConfig().REACT_APP_HERE_MAPS_API_KEY,
      });
      const defaultLayers = platform.createDefaultLayers();
      const newMap = new H.Map(mapRef.current, defaultLayers.raster.normal.map, {
        center: { lat: CONST.DEFAULT_MAP_LAT, lng: CONST.DEFAULT_MAP_LNG },
        zoom: CONST.DEFAULT_MAP_ZOOM,
        pixelRatio: window.devicePixelRatio || 1,
      });

      new H.mapevents.Behavior(new H.mapevents.MapEvents(newMap));

      H.ui.UI.createDefault(newMap, defaultLayers);
      setMap(newMap);
    }

    return () => {
      if (map) {
        map.dispose();
      }
    };
  }, [map, mapRef]);

  React.useEffect(() => {
    if (map && mapCenter) {
      map.setCenter(mapCenter);
    }
  }, [map, mapCenter]);

  React.useEffect(() => {
    if (map && markers) {
      const H = window.H;

      markers.forEach(marker => {
        map.addObject(new H.map.Marker(marker));
      });
    }
  }, [map, markers]);

  return <div ref={mapRef} style={{ height: '100%' }} />;
};

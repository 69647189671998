import React, { Component } from 'react';

import { state } from '../data-state';

import { config } from '../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';

export const MyContext = React.createContext();

export class MyProvider extends Component {
  state = state;

  componentDidMount() {
    this.getData();
  }

  getData() {
    const accountNumber = this.getAccountNumber();
    const maxNoOfRows = this.getMaxNoOfRows();

    apiRequest(this.prepareUrl(apiLinks.getTransactionsData, accountNumber, maxNoOfRows), 'GET').then(result => {
      if (result.status === 200 && result.data.palletTransactions) {
        this.setState({ data: result.data.palletTransactions, generalError: false, isLoaded: true });
      } else if (result.status === 404 && accountNumber.length > 0) {
        this.setState({ generalError: true });
      } else if (result.status === 400) {
        window.localStorage.setItem('maxNoOfRows', config.defaultNoOfRows);
        this.getData();
      } else {
        this.setState({ generalError: true });
      }
    });
  }

  prepareUrl(url, accountNumber, maxNoOfRows) {
    let link = url.replace('{accountNumber}', accountNumber);
    link = link.replace('{maxNoOfRows}', maxNoOfRows);

    return link;
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  getAccountNumber() {
    const accountNumber = this.getParameterByName('accountNumber');
    if (accountNumber !== null) {
      if (accountNumber.length === 6) {
        this.setState({ accountNumber });
      } else if (accountNumber.length === 0) {
        this.setState({ error: true });
      }
      return accountNumber;
    } else {
      return 0;
    }
  }

  getMaxNoOfRows() {
    let maxNoOfRows = window.localStorage.getItem('maxNoOfRows');

    if (maxNoOfRows !== null) {
      this.setState({ maxNoOfRows });
    } else {
      maxNoOfRows = config.defaultNoOfRows;
      this.setState({ maxNoOfRows });
    }

    return maxNoOfRows;
  }

  updateData = numberOfRows => {
    window.localStorage.setItem('maxNoOfRows', numberOfRows);

    this.setState(
      {
        maxNoOfRows: numberOfRows,
        isLoaded: false,
      },
      () => this.getData(),
    );
  };

  setCSVError = () => {
    this.setState({ CSVError: true });
  };

  render() {
    return (
      <MyContext.Provider
        value={{
          state: this.state,
          displayValues: config.displayValues,

          updateData: this.updateData,
          setCSVError: this.setCSVError,
        }}
      >
        {this.props.children}
      </MyContext.Provider>
    );
  }
}

import React, { useRef, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { config, CONST } from '../../../data-config';
import { TextArea } from 'components';
import { Radio } from 'components';
import { Error } from 'components';
import { toggleActiveTab } from '../Template-helpers';
import { useGetUser } from '../../../../hooks/api/useGetUser';

function SchedulePickup(props) {
  const { t } = useTranslation();
  const currentTab = useRef(null);
  const { context } = props;
  const { data: userAccountCountryCode } = useGetUser({ select: data => data?.user?.accountCountryCode });
  // FCPG-619 only allow schedulePickup in templates for global products
  const filteredOptions = config.pickupOptions.filter(
    option => userAccountCountryCode.toLowerCase() === 'se' || option.value === 'schedulePickup',
  );

  const updatePickup = (params, context, paramGroupName) => {
    const schedulePickupOption = JSON.parse(JSON.stringify(context.state[paramGroupName]));
    schedulePickupOption.value = params.value;
    schedulePickupOption.error = false;

    context.updateState({ [paramGroupName]: schedulePickupOption });
  };

  useEffect(() => {
    if (Array.isArray(filteredOptions) && filteredOptions.length === 1) {
      updatePickup({ name: 'pickupOption', value: filteredOptions[0].value }, context, 'pickupOption');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPickupOptionsList = () => {
    return filteredOptions.map(pickup => {
      return (
        <div key={pickup.label} className="frc__label l-grid--w-100pc-s margin-bottom-1">
          <Radio
            label={t(`general|${pickup.label}`)}
            isBooleanValueRequired
            id={pickup.value}
            name={'pickupOption'}
            checked={context.state.pickupOption.value === pickup.value}
            value={pickup.value}
            context={context}
            config={config}
            CONST={CONST}
            updateOnParent={(params, context) => updatePickup(params, context, 'pickupOption')}
          />
          {context.state.pickupOption.value === pickup.value &&
            context.state.pickupOption.value === 'schedulePickup' && (
              <div className="frc__generic--background-white frc__generic-section--wrapper has-top-margin-1 margin-bottom-1">
                <>
                  <TextArea
                    className="frc__textarea--wrapper frc__generic--field-wrapper-no-top"
                    textAreaClassName="frc__textarea--tiny"
                    regEx={config.regEx.everything}
                    label={t(`general|Add Pickup Instructions (Optional)`)}
                    smallLabel={t(`general|Add instructions for driver <strong>picking up</strong> this shipment`)}
                    name="pickupInstructions"
                    isRequired={false}
                    value={context.state.pickupInstructions.value}
                    lengthCheck={[RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)]}
                    cutTextLimit={config.maxInstructionsLength}
                    context={context}
                    config={config}
                    CONST={CONST}
                  />
                  {context.state.pickupInstructions.error && (
                    <Error
                      name="pickupInstructions"
                      className="frc__input--error"
                      message={t(`general|errors|Driver Instructions Error`, {
                        chars: config.maxInstructionsLength,
                      })}
                    />
                  )}
                </>
              </div>
            )}
        </div>
      );
    });
  };

  return (
    <div
      className="l-grid--w-100pc-s"
      onMouseLeave={() => toggleActiveTab(currentTab, false)}
      onMouseEnter={() => toggleActiveTab(currentTab, true)}
    >
      <div className="l-grid">
        <div className="frc__app-tab" ref={currentTab}>
          <h3 className="frc__app-tab__title frc__app-margin-bottom-0">{t('general|Schedule Pickup')}</h3>
        </div>
      </div>

      <div className="l-grid--w-100pc-s frc__generic-section--wrapper  margin-bottom-1">
        <div className="l-grid l-grid--between-s frc__generic--field-wrapper">
          <div className="l-grid--w-100pc-s l-grid--w-48pc-m margin-bottom-1">
            <h4 className="frc__red-lined-title">{t('general|Pickup')}</h4>
            {renderPickupOptionsList()}
            <div className="l-grid--w-100pc-s">
              {props.context.state.pickupOption.error && (
                <Error
                  name={'pickupOption'}
                  className="frc__input--error"
                  message={t('general|errors|Please select one option')}
                />
              )}
            </div>
          </div>

          <div className="l-grid--w-100pc-s l-grid--w-48pc-m margin-bottom-1">
            <h4 className="frc__red-lined-title">{t(`general|Delivery Instructions`)}</h4>

            <TextArea
              className="frc__textarea--wrapper frc__generic--field-wrapper-no-top"
              textAreaClassName="frc__textarea--tiny"
              regEx={config.regEx.everything}
              label={t(`general|Add Delivery Instructions (Optional)`)}
              smallLabel={t(`general|Add instructions for driver <strong>delivering</strong> this shipment`)}
              name="deliveryInstructions"
              isRequired={false}
              value={context.state.deliveryInstructions.value}
              lengthCheck={[RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)]}
              cutTextLimit={config.maxInstructionsLength}
              context={context}
              config={config}
              CONST={CONST}
            />
            {context.state.deliveryInstructions.error && (
              <Error
                name="deliveryInstructions"
                className="frc__input--error"
                message={t(`general|errors|Driver Instructions Error`, {
                  chars: config.maxInstructionsLength,
                })}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const areFunctionPropsEqual = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.context.state.deliveryInstructions) ===
      JSON.stringify(nextProps.context.state.deliveryInstructions) &&
    JSON.stringify(prevProps.context.state.pickupInstructions) ===
      JSON.stringify(nextProps.context.state.pickupInstructions) &&
    JSON.stringify(prevProps.context.state.pickupOption) === JSON.stringify(nextProps.context.state.pickupOption)
  );
};

export default memo(SchedulePickup, areFunctionPropsEqual);

import { matchSorter } from 'match-sorter';
import { debounce } from 'globals/utils/debounce';
import { config } from '../../data-config';

export const filterMatchedTemplateResults = debounce(context => {
  let keys;
  const stateToSet = {};

  if (context.state.searchTerm.value.length !== 0 && context.state.templateListMaster.length > 0) {
    // e.g. companyName, name, country etc
    keys = Object.keys(context.state.templateListMaster[0]);

    const filteredMatches = matchSorter(context.state.templateListMaster, context.state.searchTerm.value, {
      keys: keys.concat([
        'receiver.name',
        'receiver.companyName',
        'receiver.country',
        'receiver.postalCode',
        'receiver.city',
        'receiver.street',
        'sender.name',
        'sender.companyName',
        'sender.country',
        'sender.postalCode',
        'sender.city',
        'sender.street',
      ]),
      threshold: matchSorter.rankings.WORD_STARTS_WITH,
    });

    stateToSet.templateList = filteredMatches;
    stateToSet.manualSearch = true;
  } else {
    stateToSet.templateList = JSON.parse(JSON.stringify(context.state.templateListMaster));
    stateToSet.manualSearch = false;
  }

  context.updateState(stateToSet);
}, config.standardDebounceTimeout);

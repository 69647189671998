import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';
import { toCamelCase } from 'globals/utils/formatting';
import { afterUpdate } from './PhoneNumber-helpers';
import { Input, Select, Error, Button } from 'components';
import { changeCountryCodePhonePrefix } from '../../globals/helpers/country-helpers';
import { COUNTRY_SELECT_OPTIONS } from '../../locale/config';
import './PhoneNumber.css';
import { useGetUser } from '../../hooks/api/useGetUser';

const PhoneNumber = props => {
  const update = (params, context) => {
    params.groupName = props.groupName || 'phoneNumbers';

    if (props.updateOnParent) {
      props.updateOnParent(params, context);
    } else {
      context.updateGroupOfFields(params);
    }
  };

  const value = props.code;
  const accountCountryCode = props.context?.state?.user?.accountCountryCode;
  const { data: userAccountCountryCode } = useGetUser({
    select: data => data?.user?.accountCountryCode,
    enabled: !accountCountryCode,
  });

  useEffect(() => {
    const handlePhonePrefix = async () => {
      const option = COUNTRY_SELECT_OPTIONS.find(countrySelectOption => {
        const result = countrySelectOption.code?.toLowerCase() === accountCountryCode?.toLowerCase();
        if (!result && userAccountCountryCode) {
          return countrySelectOption.code?.toLowerCase() === userAccountCountryCode?.toLowerCase();
        }
        return result;
      });

      const phoneNumbersKey = props.groupName || 'phoneNumbers';
      if (option && accountCountryCode && `+${option?.phone?.toLowerCase()}` !== value?.toLowerCase() && !value) {
        changeCountryCodePhonePrefix(props.context, accountCountryCode, phoneNumbersKey);
      } else if (
        option &&
        !accountCountryCode &&
        userAccountCountryCode &&
        `+${option?.phone?.toLowerCase()}` !== value?.toLowerCase() &&
        !value
      ) {
        changeCountryCodePhonePrefix(props.context, userAccountCountryCode, phoneNumbersKey);
      }
    };
    handlePhonePrefix();
  }, [value, props.context, props.groupName, accountCountryCode, userAccountCountryCode]);

  const digits = props.config.maxPhoneNumberDigits - props.code?.length - 1;
  return (
    <>
      <Translation>
        {t => (
          <>
            <div className="l-grid l-grid--w-100pc-s  frc__phone-number frc__generic-row--wrapper">
              <div
                className={
                  props.selectClassName ||
                  'l-grid--w-100pc-s l-grid--w-40pc-s-m l-grid--w-40pc-s-l l-grid--w-100pc-m-s l-grid--w-40pc-m-m'
                }
              >
                <Select
                  id={toCamelCase(props.id ? props.id + 'Code' : props.groupName + 'Code')}
                  label={t(`general|Country code`)}
                  index={props.index}
                  name={'code'}
                  isRequired={typeof props.isRequired === 'undefined' ? true : props.isRequired}
                  values={props.config.phoneCountryCodes}
                  value={value}
                  updateOnParent={(params, context) => update(params, context)}
                  skipTranslation={true}
                  afterUpdate={afterUpdate}
                  disabled={props.disabled}
                  config={props.config}
                  CONST={props.CONST}
                  context={props.context}
                />
              </div>
              <div
                className={
                  props.inputClassName ||
                  'l-grid--w-100pc-s l-grid--w-60pc-s-m l-grid--w-60pc-s-l l-grid--w-100pc-m-s l-grid--w-60pc-m-m'
                }
              >
                <Input
                  id={toCamelCase(props.id ? props.id + 'Number' : props.groupName + 'Number')}
                  context={props.context}
                  regEx={props.config.regEx.pureNumber}
                  label={t('general|labels|inputs|Phone number')}
                  className="l-grid--w-100pc-s frc__input--wrapper frc__generic--field-wrapper"
                  index={props.index}
                  name={'number'}
                  dataTestId={'numberInp'}
                  // eslint-disable-next-line
                  isRequired={typeof props.isRequired === undefined ? true : props.isRequired}
                  hideAsterisk={false}
                  value={props.number}
                  updateOnParent={(params, context) => update(params, context)}
                  disabled={props.disabled}
                  lengthCheck={[new RegExp(`^.{0,${digits}}$`, 'g')]}
                  cutTextLimit={digits}
                />
                {props.deleteButton ? (
                  <div className="fcp__phone-number-delete-button--wrapper">
                    <Button
                      submit={() => props.removeOnParent(props.index)}
                      className="frc__button--delete"
                      dataTestId="deleteBtn"
                      label="X"
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {props.error ? (
              <Error
                name={props.errorId ? props.errorId : 'phone' + props.index}
                className="frc__input--error"
                message={t('general|errors|Phone Error', { digits: digits })}
              />
            ) : (
              ''
            )}
          </>
        )}
      </Translation>
    </>
  );
};

export default PhoneNumber;

import { Pagination } from 'components';
import { getTransactionId } from 'globals/utils/generators';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { CONST, config } from '../../data-config';
import { fetchSavedShipmentsList, onSavedShipmentSearch } from '../ActionUtils';
import './ShipmentDataGrid.css';

export default class ShipmentDataGridPagination extends Component {
  handlePageChange = page => {
    onSavedShipmentSearch(this.props.context, { pageSize: false, page: page });
    window.scrollTo(0, 0);
  };

  handleDisplayRowChange = pageSize => {
    onSavedShipmentSearch(this.props.context, { pageSize: pageSize, page: false });
    window.scrollTo(0, 0);
  };

  componentDidMount = () => {
    if (this.props.context.state.savedShipmentsMaster.length === 0) {
      const past14DaysFromToday = new Date();
      past14DaysFromToday.setDate(past14DaysFromToday.getDate() - config.fromNoOfDays);

      this.props.context.extendedSetState(
        {
          loader: true,
          transactionId: getTransactionId(CONST.PORTAL_SAVED_SHIPMENTS),
          filterByShipmentDates: { value: 'last14Days', error: false },
          filterShipmentFromDate: { value: new Date(past14DaysFromToday).setHours(0, 0, 0, 0), error: false },
        },
        //fetch list of saved shipment
        { afterUpdate: fetchSavedShipmentsList },
      );
    }
  };

  selectedRows = rows => {
    const notIncompleteRows = rows.filter(row => {
      return row.status !== CONST.IN_COMPLETE;
    });
    this.props.onPrintCheckBoxSelected(notIncompleteRows);
  };

  render() {
    const { context } = this.props;

    return (
      <Translation>
        {t => (
          <>
            {context.state.savedShipments.length > 0 && !context.state.manualSearch && this.props.showPagination && (
              <Pagination
                t={t}
                CONST={CONST}
                paginationItemsPerRow={config.paginationItemsPerRow}
                onHandleDisplayRowChange={this.handleDisplayRowChange}
                context={context}
                pagination={context.state.pagination}
                onHandlePageChange={this.handlePageChange}
              />
            )}
          </>
        )}
      </Translation>
    );
  }
}

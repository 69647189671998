/**
 * To Validate Bank Giro Number and Plus Giro Number using Modulus 10, as mentioned in below documents:
 * https://confluence.dhl.com/display/FCP/CR57+-+VAS+updates+and+clarifications
 *
 * @param {string} value
 *
 */
export const validateGiroNumber = value => {
  const validate = {
    isError: false,
  };
  const inputValue = String(value);

  if (inputValue.length >= 6 && inputValue.length <= 9) {
    let digitBank = [];
    if (inputValue.indexOf('-') !== -1) {
      digitBank = inputValue.split('-').join('').split('');
    } else {
      digitBank = inputValue.split('');
    }
    const checkDigit = digitBank[digitBank.length - 1];
    let weight = 2;
    const products = [];

    //Generate Products
    for (let i = digitBank.length - 2; i >= 0; i--) {
      let product = Number(digitBank[i]) * weight;
      const modifyProduct = String(product).split('');

      if (modifyProduct.length > 1) {
        product = modifyProduct.reduce((a, b) => Number(a) + Number(b));
      }

      products[i] = product;
      weight = weight === 2 ? 1 : 2;
    }

    const productsTotal = products.reduce((a, b) => a + b);
    const tempCheckDigit = Math.ceil(productsTotal / 10) * 10 - productsTotal;

    if (tempCheckDigit === Number(checkDigit)) {
      validate.isError = false;
    } else {
      validate.isError = true;
    }
  } else {
    validate.isError = true;
  }
  return validate;
};

import { Typography } from '@mui/material';
import { SuccessMessage } from 'components';
import cssVars from 'css-vars-ponyfill';
import 'globals/utils/debugHelpers';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { withQueryClient } from '../../../utils/withQueryClient';
import { AddressContext, AddressProvider } from '../../context/context';
import '../../css/styles.css';
import { CONST, config } from '../../data-config';
import AddressBook from '../AddressBook/AddressBook';
import Contact from '../Contact/Contact';
import './../../css/global/_variables.css';
import './AppPortalAddressBook.css';

const ENV_DEV = process.env.NODE_ENV === 'development';

class AppPortalAddressBook extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editContact: false,
      succesMessage: '',
    };
  }

  componentDidMount() {
    cssVars();
  }

  showContact = () => {
    this.setState(prevState => ({
      editContact: !prevState.editContact,
    }));
  };

  showSuccessToaster = message => {
    this.setState({
      message,
    });
    setTimeout(() => {
      this.setState({
        message: '',
      });
    }, config.toasterTimeoutInMilliSeconds);
  };

  render() {
    return (
      <Translation>
        {t => (
          <div className={ENV_DEV ? 'l-view' : ''} data-testid="address-book">
            <div className="c-page-headline component-small has-rte component-margin frc__heading">
              <div className="c-page-headline--wrapper">
                <Typography variant="h1" mt={{ xs: 0, sm: 2 }}>
                  {t('general|pageTitles|addressBook')}
                </Typography>
              </div>
            </div>
            <AddressProvider queryClient={this.props.queryClient}>
              <div className="frc__app">
                <AddressContext.Consumer>
                  {context => (
                    <>
                      {this.state.editContact ? (
                        <Contact
                          context={context}
                          onShowContact={this.showContact}
                          onShowSuccessToaster={this.showSuccessToaster}
                        />
                      ) : (
                        <AddressBook
                          context={context}
                          onShowSuccessToaster={this.showSuccessToaster}
                          onShowContact={this.showContact}
                        />
                      )}
                      {this.state.message && <SuccessMessage CONST={CONST} t={t} message={this.state.message} />}
                    </>
                  )}
                </AddressContext.Consumer>
              </div>
            </AddressProvider>
          </div>
        )}
      </Translation>
    );
  }
}

export default withQueryClient(AppPortalAddressBook);

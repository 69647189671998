import { confirmationEmail, formPartyData } from './Party-helpers';
import { formPieces } from './Pieces-helpers';
import { formatDate, formatDateTime } from 'globals/utils/formatting';
import { config } from '../../../data-config';
import { getPayerAccount } from 'globals/utils/requests';
import { generateAdditionalInformationList } from '../../../../globals/helpers/additionalInformation';
import { globalConfig } from '../../../../globals/data-config-global';

export const getAdditionalServices = additionalServices => {
  const selectedAdditionalServices = {};
  const additionalServicesMap = {};

  if (additionalServices && additionalServices.length > 0) {
    additionalServices.forEach(additionalService => {
      if (additionalService.value) {
        if (additionalService.groups && additionalService.groups.length > 0) {
          const isVASOptionAnArr =
            !config.optionSchemaToBeSentAsObject ||
            config.optionsSchemaExpectedAsArray.indexOf(additionalService.code) !== -1;
          const groupsMainArr = [];

          additionalService.groups.forEach(group => {
            let groupEachArr = [];
            let serviceMap;

            if (group.options) {
              group.options.forEach(optionList => {
                serviceMap = {};
                optionList.forEach(option => {
                  // option starts here
                  if (additionalService.optionNotToHide) {
                    switch (option.type) {
                      case config.OptionFieldTypes.Telephone:
                        serviceMap[option.optionCode] = option.value[0].code + '-' + option.value[0].number;
                        break;
                      case config.OptionFieldTypes.DateTime:
                        serviceMap[option.optionCode] = formatDateTime(option.value);
                        break;
                      case config.OptionFieldTypes.Date:
                        serviceMap[option.optionCode] = formatDate(option.value);
                        break;
                      case config.OptionFieldTypes.TickBox:
                        serviceMap[option.optionCode] = option.selected;
                        break;
                      case config.OptionFieldTypes.Select:
                        if (
                          additionalService.code === config.OptionFieldTypes.collectionAtTerminal &&
                          option.optionCode === config.OptionFieldTypes.ListCOT
                        ) {
                          let collectionAtTerminal = option.values.find(value => value.value === option.value);

                          additionalServicesMap.collectionAtTerminal = JSON.parse(JSON.stringify(collectionAtTerminal));
                          selectedAdditionalServices[additionalService.code] = true;
                        } else serviceMap[option.optionCode] = option.value;
                        break;
                      default:
                        serviceMap[option.optionCode] = option.value ? option.value.replace(',', '.') : null;
                    }
                  } else if (!additionalService.optionNotToHide) {
                    serviceMap[option.optionCode] = option.value;
                  }
                });

                if (isVASOptionAnArr) {
                  groupEachArr.push(serviceMap);
                }
              });
            }

            if (groupEachArr && groupEachArr.length > 0) {
              groupsMainArr.push(groupEachArr);
            }

            if (additionalService.code !== config.OptionFieldTypes.collectionAtTerminal)
              selectedAdditionalServices[additionalService.code] =
                groupsMainArr && groupsMainArr.length > 0 ? groupsMainArr : serviceMap;
          });
        } else {
          selectedAdditionalServices[additionalService.code] = true;
        }
      }
    });
  }
  additionalServicesMap.selectedAdditionalServices = selectedAdditionalServices;

  return additionalServicesMap;
};

export const formShipmentQuoteData = context => {
  const map = {};
  const { state } = context;

  const totals = state.totals.shipmentDetailsRows;
  const selectedProduct = state.selectedProduct;
  const payerCode = state.payerCode;
  const payerAccount = getPayerAccount(state);
  const additionalServices = getAdditionalServices(state.additionalServices);

  map.shipment = {};
  map.transactionId = state.transactionId;
  map.captcha = {
    answer: state.captcha.value,
    token: state.captcha.token,
  };
  map.shipment.additionalServices = additionalServices.selectedAdditionalServices || null;
  map.shipment.confirmationEmail = confirmationEmail(state) ? state.deliveryEmail.value : state.pickupEmail.value;

  map.shipment.pickupDate = formatDate(state.pickupDate.value);
  // get product code from party data for home delivery
  map.shipment.dhlProductCode = state.partyData !== null ? state.partyData.productCode : selectedProduct.code;
  map.shipment.isPickupRequired = !!(
    state.pickupOption.value === 'Schedule Pickup' &&
    map.shipment.pickupDate &&
    config.schedulePickup.indexOf(map.shipment.dhlProductCode) === -1 &&
    globalConfig.returnProducts.indexOf(map.shipment.dhlProductCode) === -1
  );

  map.shipment.consigneeDHLAccountNo = state.receiverNumber?.value || null;
  map.shipment.consignorDHLAccountNo = state.accountNumber.value || null;
  map.shipment.deliveryInstruction = state.deliveryInstructions.value;
  map.shipment.goodsValue = state.confirmationEmail.value;
  map.shipment.importExport = state.shipmentTypesImpOrExp.value;
  map.shipment.isAlredyScheduledLocation = true;
  map.shipment.isDHLLocationDropOff = true;
  map.shipment.parties = formPartyData(
    state,
    payerCode,
    additionalServices.collectionAtTerminal ? additionalServices.collectionAtTerminal : false,
  );
  map.shipment.payerCode = payerCode;
  map.shipment.payerModel = {
    agreementNo: payerAccount.number,
    senderPays: payerAccount.senderPays,
    receiverPays: payerAccount.receiverPays,
    thirdPartyPays: payerAccount.thirdPartyPays,
  };

  map.shipment.requestedDeliveryDate =
    selectedProduct.deliveryDate && selectedProduct.deliveryDate !== 'ltc_error' && selectedProduct.code === '233'
      ? selectedProduct.deliveryDate
      : null;

  map.shipment.pickupInstruction = state.pickupInstructions.value;
  map.shipment.pieces = formPieces(state);
  map.shipment.shippingDate =
    selectedProduct.deliveryDate && selectedProduct.deliveryDate !== 'ltc_error' ? selectedProduct.deliveryDate : '';
  map.shipment.totalLoadingMeters = totals.loadingMeter.value * 1;
  map.shipment.totalNumberOfPieces = totals.quantity.value;
  map.shipment.totalVolume = totals.volume.value;
  map.shipment.totalWeight = totals.weight.value;
  map.shipment.additionalInformationList = generateAdditionalInformationList(state);

  return map;
};

// import { config } from './data-config';

export const state = {
  trackingNumber: {
    value: '',
    error: false,
  },

  isLoading: true,
  savedShipments: [],
};

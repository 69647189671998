// Source of information: https://publicholidays.se/2021-dates/
export const domesticHolidays = [
  '2020-01-01',
  '2020-01-06',
  '2020-04-10',
  '2020-04-12',
  '2020-04-13',
  '2020-05-01',
  '2020-05-21',
  '2020-05-31',
  '2020-06-06',
  '2020-06-20',
  '2020-10-31',
  '2020-12-25',
  '2020-12-26',
  '2021-01-01',
  '2021-01-06',
  '2021-04-02',
  '2021-04-04',
  '2021-04-05',
  '2021-05-01',
  '2021-05-13',
  '2021-05-23',
  '2021-06-06',
  '2021-06-26',
  '2021-11-06',
  '2021-12-25',
  '2021-12-26',
];

export const THREE_DECIMALS_REGEX = /^\d+([.,]\d{1,3})?$/;

/**
    ^\d*
        This matches zero or more digits at the beginning.
    (?:[.,]\d{0,3})?
        This part matches an optional decimal point 
        or comma followed by zero to three digits. The (?: ... ) is 
        a non-capturing group, and the ? makes the whole group optional.
 */
export const THREE_DECIMALS_INPUT_REGEX = /^\d*(?:[.,]\d{0,3})?$/;

export const TWO_DECIMALS_REGEX = /^\d+([.,]\d{1,2})?$/;
export const TWO_DECIMALS_INPUT_REGEX = /^\d*(?:[.,]\d{0,2})?$/;

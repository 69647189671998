import { config } from '../../../data-config';
import { DOMESTIC_SHIPMENT_RANGE, RECEIVER_PAYER } from '../../../../globals/constants';

export const filterAccountsBasedOnShipmentRange = (originalAccounts, shipmentRange) => {
  return originalAccounts.filter(account => accountCanBookProductsForCorrectShipmentRange(account, shipmentRange));
};

const accountCanBookProductsForCorrectShipmentRange = (account, shipmentRange) =>
  account.products.some(product => {
    const p = config.allowedProducts.find(allowedProd => product.productCode === allowedProd.code);
    return p && p.range === shipmentRange;
  });

export const unselectIfDomesticReceiver = (params, context) => {
  if (
    params.name === 'shipmentPayer' &&
    params.value === RECEIVER_PAYER &&
    context.state.shipmentRange &&
    context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE
  ) {
    context.state.accountNumber = { value: '', error: false, display: true, isSelect: true };
    context.state.receiverNumber = { value: '', error: false, display: true, isSelect: true };
    context.state.thirdPartyNumber = { value: '', error: false, display: false, isRequired: false, disabled: false };
    context.updateState(context.state);
  }
};

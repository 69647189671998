export const ROUTES = {
  addressBook: 'address-book',
  changePassword: 'change-password',
  dashboard: 'dashboard',
  editProfile: 'edit-profile',
  error401: 'error/401',
  forgotPassword: 'forgot-password',
  login: 'login',
  palletHistory: 'pallet-history',
  palletPickup: 'pallet-pickup',
  portal: 'portal',
  portalOrder: 'portal-order',
  portalPickup: 'portal-pickup',
  privacyPolicy: 'privacy-policy',
  publicOrder: 'public-order',
  publicPickup: 'public-pickup',
  quoteTool: 'quote-tool',
  registration: 'registration',
  registrationThankYou: 'registration/thank-you-registering',
  resetPassword: 'reset-password',
  savedShipments: 'saved-shipments',
  templates: 'templates',
  termsOfUse: 'terms-of-use',
  tracking: 'home/tracking/tracking-freight.html',
  underMaintenance: 'under-maintenance',
  legalNotice: 'legal-notice',
  cookieList: 'cookie-list',
};

/**

presunout do pageLinks:

 "pageLinks": {
    "HomeDeliveryDomestic": "https://www.dhl.com/se-en/home/our-divisions/freight/domestic/home-delivery.html",
    "PalletDomestic": "https://www.dhl.com/se-en/home/our-divisions/freight/domestic/eur-pallet-company.html",
    "PalletDomesticParti": "https://www.dhl.com/se-en/home/our-divisions/freight/domestic/part-loads-company.html",
    "PalletDomesticStycke": "https://www.dhl.com/se-en/home/our-divisions/freight/domestic/groupage-company.html",
    "PalletEuroline": "https://www.dhl.com/se-en/home/our-divisions/freight/international/full-truck-load.html",
    "PalletInternational": "https://www.dhl.com/se-en/home/our-divisions/freight/international/pallets-groupage-and-full-truck-loads.html",
    "ParcelConnectInternational": "https://www.dhl.com/se-en/home/our-divisions/freight/international/parcels-to-consumers.html",
    "ParcelDomestic": "https://www.dhl.com/se-en/home/our-divisions/freight/domestic/parcel.html",
    "ParcelInternational": "https://www.dhl.com/se-en/home/our-divisions/freight/international/parcels-to-consumers.html",
    "ParcelInternationalPaketExport": "https://www.dhl.com/se-en/home/our-divisions/freight/international/parcels-to-companies.html",
    "activeTracing": "http://activetracing.dhl.com/",
    "becomeACustomerUrl": "https://www.logistics.dhl/se-en/home/our-divisions/freight/customer-service/become-a-customer.html",
    "chat": "https://www.logistics.dhl/se-en/home/our-divisions/freight/contact-us.html",
    "dhlBookOnline": "https://www.logistics.dhl/se-en/home/our-divisions/freight/book-online.html",
    "dhlContactUs": "https://www.dhl.com/se-en/home/our-divisions/freight/contact-us.html",
    "forgotten-account-page-link": "https://www.logistics.dhl/se-en/home/our-divisions/freight/contact-us/customer-service-inquiry.html",
    "terms-and-conditions": "https://www.logistics.dhl/se-en/home/our-divisions/freight/customer-service/useful-downloads.html#terms_conditions",
    "trackShipment": "https://www.dhl.com/se-en/home/tracking/tracking-freight.html?tracking-id={shipmentNumber}",

  },

presunout do general:

 "general": {
    "Unfortunately myDHLFreight is under maintenance now. Please try it later.": "Unfortunately myDHLFreight is under maintenance now. Please try it later.",
    "myDHLFreight is under maintenance": "myDHLFreight is under maintenance",
 }



smazat:

    "HomeDeliveryInternational": "#",




 */

import { getTransactionId } from 'globals/utils/generators';
import { setCookie } from 'globals/utils/requests';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { CONST, config } from '../../data-config';
import { deleteSavedShipment, fetchIncompleteSavedShipmentsList } from '../ActionUtils';
import { ModalView } from './ModalView/ModalView';
import { checkAndGetUnfinishedShipment } from './RecentShipments-helpers';

import { ROUTES } from 'router';
import './RecentShipments.css';

export default class ShipmentsLoader extends Component {
  unfinishedShipmentModalAction = (portalOrderFlag, t) => {
    if (portalOrderFlag) {
      setCookie(config.portalOrderCookie, this.props.context.state.template0Id);
      window.location = `${this.props.baseUrl}/${ROUTES.portalOrder}`;
    } else {
      //Delete the template0Id
      deleteSavedShipment([this.props.context.state.template0Id]);

      this.props.context.updateState({
        template0Id: '',
      });
    }
  };

  componentDidMount = () => {
    checkAndGetUnfinishedShipment(this.props.context);

    if (this.props.context.state.savedShipments.length === 0) {
      this.props.context.extendedSetState(
        {
          isLoading: true,
          transactionId: getTransactionId(CONST.DASHBOARD), // transaction id incase if delete fails
          baseUrl: this.props.baseUrl,
        },
        //fetch list of saved shipment
        { afterUpdate: fetchIncompleteSavedShipmentsList },
      );
    }
  };

  selectedRows = rows => {
    this.props.onPrintCheckBoxSelected(rows);
  };

  render() {
    const context = this.props.context;

    return (
      <Translation>
        {t => (
          <>
            {context.state.template0Id && (
              <ModalView
                transactionId={context.state.transactionId}
                unfinishedShipment={context.state.template0Id}
                continueToPortalOrder={this.unfinishedShipmentModalAction}
              />
            )}
            <div>
              <p className="frc__detailed-information">{t(`general|book price info`)}</p>
            </div>
          </>
        )}
      </Translation>
    );
  }
}

import { apiLinks } from '../../config/api-config';
import { GLOBAL_CONST } from '../data-config-global';
import { apiRequest } from '../utils/requests';
import { caseInsensitiveEquals } from '../utils/strings';
import { COUNTRY_SELECT_OPTIONS } from '../../locale/config';
import { allCountries } from '../data-countries';
import { getDefaultCountry } from './country-helpers';
import { DOMESTIC_SHIPMENT_RANGE, EXPORT_SHIPMENT_TYPE } from '../constants';

/**
 * Validates Post Code based on country for Source and Destination.
 */
export const postalCodeValidation = async (
  { pickupCountry, pickupPostalCode, pickupCity, deliveryCountry, deliveryPostalCode, deliveryCity },
  multipleProd = false,
) => {
  const validationArr = [];

  const resultMap = {
    destinationBookable: false,
    destinationDeviating: 1,
    sourceBookable: false,
    sourceDeviating: 1,
    internationalSourcePostalCode: false,
    internationalDestinationPostalCode: false,
    data: {
      destination: null,
      source: null,
    },
    status: null,
  };

  const doApiCall = (type, url) =>
    apiRequest(url)
      .then(result => {
        if (result.status === GLOBAL_CONST.STATUS_OK) {
          const data = result.data;

          if (data && type === 'destination') {
            resultMap.destinationBookable = data.bookable;
            resultMap.destinationDeviating = data.deviating;
            resultMap.internationalDestinationPostalCode = !data.postalCode;
            resultMap.data.destination = data;
          }

          if (data && type === 'source') {
            resultMap.sourceBookable = data.bookable;
            resultMap.sourceDeviating = data.deviating;
            resultMap.internationalSourcePostalCode = !data.postalCode;
            resultMap.data.source = data;
          }
        } else {
          resultMap.error = true;
        }
        return result;
      })
      .catch(() => {
        resultMap.error = true;
      });
  if (caseInsensitiveEquals(pickupCountry?.value, 'SE')) {
    let sourceURL = apiLinks.fetchCityName
      .replace('{countryCode}', pickupCountry.value)
      .replace('{postalCode}', pickupPostalCode.value);

    validationArr.push(doApiCall('source', sourceURL));
  }
  if (caseInsensitiveEquals(deliveryCountry?.value, 'SE')) {
    let destinationUrl = apiLinks.fetchCityName
      .replace('{countryCode}', deliveryCountry.value)
      .replace('{postalCode}', deliveryPostalCode.value);

    // send destination only when multiple prod is false
    if (!multipleProd) {
      validationArr.push(doApiCall('destination', destinationUrl));
    }
  }

  const [sourceValidation, destinationValidation] = await Promise.all(validationArr);

  if (
    sourceValidation &&
    sourceValidation.status === GLOBAL_CONST.STATUS_OK &&
    ((destinationValidation && destinationValidation.status === GLOBAL_CONST.STATUS_OK) || multipleProd)
  ) {
    resultMap.status = GLOBAL_CONST.STATUS_OK;
  }

  return resultMap;
};

export const resetPhoneNumberWhenSwitchedToDomestic = state => {
  const countryCode = state?.pickupCountry?.value;
  const option = COUNTRY_SELECT_OPTIONS.find(
    countrySelectOption => countrySelectOption.code?.toLowerCase() === countryCode?.toLowerCase(),
  );

  const phoneObject = {};

  if (!option) {
    return phoneObject;
  }

  if (state?.pickupPhoneNumbers?.length) {
    phoneObject.pickupPhoneNumbers = [
      {
        ...state.pickupPhoneNumbers[0],
        code: `+${option.phone}`,
      },
    ];
  }

  if (state?.deliveryPhoneNumbers?.length) {
    phoneObject.deliveryPhoneNumbers = [
      {
        ...state.deliveryPhoneNumbers[0],
        code: `+${option.phone}`,
      },
    ];
  }

  if (state?.differentPickupPhoneNumbers?.length) {
    phoneObject.differentPickupPhoneNumbers = [
      {
        ...state.differentPickupPhoneNumbers[0],
        code: `+${option.phone}`,
      },
    ];
  }

  if (state?.differentDeliveryPhoneNumbers?.length) {
    phoneObject.differentDeliveryPhoneNumbers = [
      {
        ...state.differentDeliveryPhoneNumbers[0],
        code: `+${option.phone}`,
      },
    ];
  }

  return phoneObject;
};

export const updateCountryValue = (state, shipmentRange, shipmentTypesImpOrExp, stateObj) => {
  const defaultCountry = getDefaultCountry()?.toUpperCase();
  const countries = allCountries.filter(item => item.code !== defaultCountry);

  stateObj['pickupCountry'] = JSON.parse(JSON.stringify(state.pickupCountry));
  stateObj['deliveryCountry'] = JSON.parse(JSON.stringify(state.deliveryCountry));
  stateObj['differentPickupCountry'] = JSON.parse(JSON.stringify(state.differentPickupCountry));
  stateObj['differentDeliveryCountry'] = JSON.parse(JSON.stringify(state.differentDeliveryCountry));

  if (shipmentRange === DOMESTIC_SHIPMENT_RANGE) {
    stateObj['pickupCountry'].value = defaultCountry;
    stateObj['pickupCountry'].error = false;
    stateObj['deliveryCountry'].value = defaultCountry;
    stateObj['deliveryCountry'].error = false;
    stateObj['customs'] = false;
    stateObj['countryBasedCustoms'] = false;
  } else {
    if (shipmentTypesImpOrExp === EXPORT_SHIPMENT_TYPE) {
      stateObj['pickupCountry'].value = defaultCountry;
      stateObj['pickupCountry'].error = false;
      stateObj['deliveryCountry'].value = countries[0].code;
    } else {
      stateObj['pickupCountry'].value = countries[0].code;
      stateObj['deliveryCountry'].value = defaultCountry;
      stateObj['deliveryCountry'].error = false;
    }
  }

  stateObj['differentPickupCountry'].value = stateObj['pickupCountry'].value;
  stateObj['differentPickupCountry'].error = false;
  stateObj['differentDeliveryCountry'].value = stateObj['deliveryCountry'].value;
  stateObj['differentDeliveryCountry'].error = false;
};

export const swapSenderAndReceiverAddress = context => {
  const stateObj = {
    ...context.state,
    pickupCity: { ...context.state.deliveryCity },
    pickupCountry: {
      ...context.state.deliveryCountry,
      value: context.state.deliveryCountry.value,
    },
    pickupEmail: { ...context.state.deliveryEmail },
    pickupName: { ...context.state.deliveryName },
    pickupPostalCode: { ...context.state.deliveryPostalCode },
    pickupPhoneNumbers: [
      {
        ...context.state.deliveryPhoneNumbers[0],
      },
    ],
    pickupSender: { ...context.state.deliveryReceiver },
    pickupStreet: { ...context.state.deliveryStreet },
    deliveryCity: {
      ...context.state.pickupCity,
    },
    deliveryCountry: {
      ...context.state.pickupCountry,
    },
    deliveryEmail: { ...context.state.pickupEmail },
    deliveryName: { ...context.state.pickupName },
    deliveryPostalCode: {
      ...context.state.pickupPostalCode,
    },
    deliveryPhoneNumbers: [
      {
        ...context.state.pickupPhoneNumbers[0],
      },
    ],
    deliveryReceiver: { ...context.state.pickupSender },
    deliveryStreet: { ...context.state.pickupStreet },
    differentPickupPhoneNumbers: [
      {
        ...context.state.differentDeliveryPhoneNumbers[0],
      },
    ],
    differentDeliveryPhoneNumbers: [
      {
        ...context.state.differentPickupPhoneNumbers[0],
      },
    ],
  };

  updateCountryValue(
    context.state,
    context.state.shipmentRange.value,
    context.state.shipmentTypesImpOrExp.value,
    stateObj,
  );

  return stateObj;
};

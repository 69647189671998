import { CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';

export const uploadAndProcess = async file => {
  const resultMap = {};
  const data = new FormData();
  const headers = {
    file: true,
  };

  data.append('file', file);

  try {
    await apiRequest(apiLinks.uploadExcel, 'POST', {
      body: data,
      headers: headers,
    })
      .then(result => {
        if (result.status === CONST.STATUS_OK) {
          resultMap.data = result.data;
        } else {
          resultMap.error = true;
          resultMap.data = result.data;
        }
      })
      .catch(() => {
        resultMap.error = true;
      });
  } catch {
    resultMap.error = true;
  }

  return resultMap;
};

export const downloadExampleTemplateExcel = async () => {
  const url = apiLinks.downloadTemplate;
  let error = false;

  const params = {
    headers: {
      accept: 'application/octet-stream',
      responseType: 'blob',
    },
  };

  await apiRequest(url, 'GET', params).then(result => {
    if (result.size) {
      let blob = new Blob([result], {
        type: 'application/vnd.ms-excel',
      });

      const filename = result.filename;

      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        let link = document.createElement('a');

        if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);

          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style = 'visibility:hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } else {
      error = true;
    }
  });

  return error;
};

/**
 * For global countries shipment rows must have either complete dimensions
 * filled (Weight, Height, Length, Width) or Weight + Loading meter or
 * Weught + Volume
 *
 * @param {Array<Object>} shipmentRows - Array of shipmentRows.
 * @param {string} countryCode - Country code.
 * @returns {boolean} - Has global colli error.
 */
export const hasGlobalColliError = (shipmentRows, countryCode) => {
  if (countryCode?.toLowerCase() === 'se') return false;

  const allRowsValid = shipmentRows.every(shipmentRow => {
    const volumeFilled = Boolean(shipmentRow?.volume?.value) && Boolean(shipmentRow?.weight?.value);
    const loadingMeterFilled = Boolean(shipmentRow?.loadingMeter?.value) && Boolean(shipmentRow?.weight?.value);
    const completeDimensionsFilled =
      Boolean(shipmentRow?.weight?.value) &&
      Boolean(shipmentRow?.length?.value) &&
      Boolean(shipmentRow?.width?.value) &&
      Boolean(shipmentRow?.height?.value);

    return volumeFilled || loadingMeterFilled || completeDimensionsFilled;
  });

  return !allRowsValid;
};

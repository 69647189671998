import { Typography } from '@mui/material';
import { GeneralError } from 'components';
import cssVars from 'css-vars-ponyfill';
import 'globals/utils/debugHelpers';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { withQueryClient } from '../../../utils/withQueryClient';
import { PortalOrderContext, PortalOrderProvider } from '../../context/context';
import '../../css/styles.css';
import AppTabs from '../AppTabs/AppTabs';
import './../../css/global/_variables.css';
import './AppPortalOrder.css';

const ENV_DEV = process.env.NODE_ENV === 'development';
class AppPortalOrder extends Component {
  componentDidMount() {
    cssVars();
  }

  render() {
    return (
      <Translation>
        {t => (
          <div className={ENV_DEV ? 'l-view' : ''}>
            <div className="c-page-headline component-small has-rte component-margin frc__heading">
              <div className="c-page-headline--wrapper">
                <Typography variant="h1" mt={{ xs: 0, sm: 2 }}>
                  {t('general|pageTitles|orderBooking')}
                </Typography>
              </div>
            </div>
            <PortalOrderProvider queryClient={this.props.queryClient}>
              <PortalOrderContext.Consumer>
                {context => {
                  return (
                    <div className="frc__app transparent-bg">
                      <>
                        <Translation>
                          {t => <GeneralError context={context} message={t('general|errors|General error')} />}
                        </Translation>
                        <AppTabs context={context} thankYouPage={this.onThankYouPage} />
                      </>
                    </div>
                  );
                }}
              </PortalOrderContext.Consumer>
            </PortalOrderProvider>
          </div>
        )}
      </Translation>
    );
  }
}

export default withQueryClient(AppPortalOrder);

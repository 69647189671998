import React, { Component } from 'react';
import { state } from '../data-state';
import { checkEmptyOrMinMaxOrLength } from 'globals/utils/fieldValidations';
import { config } from '../data-config';
import {
  prepareFieldInStateForUpdate,
  prepareDeselectGroupForUpdate,
  prepareGroupForUpdate,
  cutTextAndShowError,
  cutDecimalAndShowError,
  cutIntegerAndShowError,
} from 'globals/context/context-helpers';
import { runPostalCodesValidationForGServices } from './postal-codes-g-services-logic';
import { resetDateForLoadingUnloadingTimeDefiniteVas } from '../components/ServiceDetails/ServiceDetails-helpers';

export const MyContext = React.createContext();

export class MyProvider extends Component {
  state = state;

  componentDidMount = () => {
    const language = localStorage.getItem(config.locale.localStorageVariable) || config.locale.defaultLanguage;

    this.setState({
      languageCode: language,
    });
  };

  extendedSetState = (stateObject, params, callback) => {
    let beforeUpdate = {};
    let afterUpdate = {};

    // Executes additional logic before update the state
    if (params.beforeUpdate && typeof params.beforeUpdate === 'function') {
      beforeUpdate = params.beforeUpdate(this, params, stateObject);

      if (typeof beforeUpdate === 'object' && beforeUpdate !== null) {
        beforeUpdate = JSON.parse(JSON.stringify(beforeUpdate));
        Object.assign(stateObject, beforeUpdate);
      }
    }

    // Setting the new state and performs additional tasks on callback (if any)
    this.setState(stateObject, () => {
      const callbackState = {};

      // Executes additional logic after update the state
      if (params.afterUpdate && typeof params.afterUpdate === 'function') {
        afterUpdate = params.afterUpdate(this, params);

        if (typeof afterUpdate === 'object' && afterUpdate !== null) {
          afterUpdate = JSON.parse(JSON.stringify(afterUpdate));
          Object.assign(callbackState, afterUpdate);
        }
      }

      if (params.runPostalCodesValidationForGServices) {
        runPostalCodesValidationForGServices(this);
      }

      if (Object.keys(callbackState).length > 0 && callbackState.constructor === Object) {
        this.setState(callbackState, callback);
      } else if (typeof callback === 'function') {
        callback();
      }
    });
  };

  updateField = params => {
    let error = checkEmptyOrMinMaxOrLength(params);
    const stateObject = {};

    // Text cut
    if (params.cutTextLimit && !isNaN(params.cutTextLimit) && params.cutTextLimit > 0) {
      params = cutTextAndShowError(params);
    }
    error = params.error === true ? true : error;

    // Update the current field
    Object.assign(stateObject, prepareFieldInStateForUpdate(this.state, params.name, params.value, error));

    // Overrides the value of related field
    if (params.override && this.state[params.override]) {
      Object.assign(stateObject, prepareFieldInStateForUpdate(this.state, params.override, params.value, error));
    }

    // Deselect related fields
    if (params.deselect) {
      params.deselect.forEach(deselect => {
        Object.assign(stateObject, prepareFieldInStateForUpdate(this.state, deselect, false));
      });
    }

    if (params.name.toLowerCase().indexOf('postalcode') !== -1 && params.value === '') {
      this.checkForPriorityService(params, stateObject);
    }

    if (
      params.name === 'pickupDate' &&
      this.state.selectedProducts.some(
        sltdPrd => config.loadingUnloadingDatesSpecificProduct.indexOf(sltdPrd) !== -1,
      ) &&
      this.state.additionalServices &&
      this.state.additionalServices.length > 0
    ) {
      stateObject.additionalServices = resetDateForLoadingUnloadingTimeDefiniteVas(
        this.state.additionalServices,
        params.value,
      );
    }

    this.extendedSetState(stateObject, params);
  };

  sumGroupColumn = (group, column) => {
    let total = 0;

    if (group.length > 0) {
      group.forEach(elm => {
        if (elm[column] && elm[column].value && !isNaN(elm[column].value)) {
          total += elm[column].value * 1;
        } else if (!isNaN(elm[column])) {
          total += elm[column] * 1;
        }
      });
    }

    return total;
  };

  updateGroup = params => {
    let group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    let stateObject = {};

    if (params.deselect !== undefined) {
      group = prepareDeselectGroupForUpdate(group, params.deselect, params.selectedStatus, params.name);
    } else {
      group = prepareGroupForUpdate(group, params.selectedStatus, params.name);
    }

    stateObject = {
      [params.groupName]: group,
    };

    this.extendedSetState(stateObject, params);
  };

  checkForPriorityService = (params, stateObject) => {
    stateObject.deliveryReceiver = JSON.parse(JSON.stringify(this.state.deliveryReceiver));
    stateObject.deliveryStreet = JSON.parse(JSON.stringify(this.state.deliveryStreet));
    stateObject.deliveryCity = JSON.parse(JSON.stringify(this.state.deliveryCity));

    if (params.name.indexOf('priorityService') > -1 && params.value) {
      if (!stateObject.deliveryReceiver.value) stateObject.deliveryReceiver.error = true;
      if (!stateObject.deliveryStreet.value) stateObject.deliveryStreet.error = true;
      if (!stateObject.deliveryCity.value) stateObject.deliveryCity.error = true;
    } else {
      stateObject.deliveryReceiver.error = false;
      stateObject.deliveryStreet.error = false;
      stateObject.deliveryCity.error = false;

      if (params.name.toLowerCase().indexOf('postalcode') !== -1 && params.value === '') {
        stateObject.additionalServices = JSON.parse(JSON.stringify(this.state.additionalServices));
        stateObject.additionalServices &&
          stateObject.additionalServices.length > 0 &&
          stateObject.additionalServices.forEach(AD => {
            AD.value = false;
            AD.selectedStatus = false;
            AD.disabled = false;
          });
      }
    }
  };

  updateGroupOfFields = (params, callback) => {
    let error = checkEmptyOrMinMaxOrLength(params);
    const duplicationError = false;
    let group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    let stateObject = {};

    // Integer cutoff
    if (params.integerCutoff && !isNaN(params.integerCutoff) && params.integerCutoff > 0) {
      params = cutIntegerAndShowError(params);
    }
    error = params.error === true ? true : error;

    // Decimal cutoff
    if (params.decimalCutoff && !isNaN(params.decimalCutoff) && params.decimalCutoff > 0) {
      params = cutDecimalAndShowError(params, false);
    }
    error = params.error === true ? true : error;

    // Text cut
    if (params.cutTextLimit && !isNaN(params.cutTextLimit) && params.cutTextLimit > 0) {
      params = cutTextAndShowError(params);
    }
    error = params.error === true ? true : error;

    if (params.deselect !== undefined) {
      group = prepareDeselectGroupForUpdate(group, params.deselect, params.selectedStatus, params.name);
    }

    if (group[params.index].code && group[params.index].code === params.name) {
      group[params.index].selectedStatus = params.value;
    } else if (typeof group[params.index][params.name] === 'object') {
      group[params.index][params.name].value = params.value;
      group[params.index][params.name].error = false;

      if (!params.skipError) {
        group[params.index][params.name].error = error;
      }
    } else {
      group[params.index][params.name] = params.value;

      if (!params.skipError) {
        group[params.index].error = error;
        group[params.index].duplicationError = duplicationError;
      }
    }

    if (params.emptyDependentField && params.emptyDependentField.length > 0) {
      params.emptyDependentField.forEach(field => {
        if (field.criteriaValue >= params.value) group[params.index][field.param].value = '';
      });
    }

    if (params.override) {
      stateObject = {
        [params.groupName]: group,
        [params.override]: group,
      };
    } else {
      stateObject = {
        [params.groupName]: group,
      };
    }

    if (params.groupName === 'additionalServices') this.checkForPriorityService(params, stateObject);

    this.extendedSetState(stateObject, params, callback);
  };

  addAnotherGroupField = params => {
    const group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    const stateObject = {};

    group.push(params.item);
    stateObject[params.groupName] = group;

    this.extendedSetState(stateObject, params);
  };

  removeGroupField = params => {
    const group = [...JSON.parse(JSON.stringify(this.state[params.groupName]))];
    const stateObject = {};

    group.splice(params.index, 1);
    stateObject[params.groupName] = group;

    this.extendedSetState(stateObject, params);
  };

  updateState = (newState, callback) => {
    this.setState(
      {
        ...newState,
      },
      callback,
    );
  };

  render() {
    return (
      <MyContext.Provider
        value={{
          state: this.state,
          updateGroup: this.updateGroup,
          updateField: this.updateField,
          updateGroupOfFields: this.updateGroupOfFields,
          addAnotherGroupField: this.addAnotherGroupField,
          removeGroupField: this.removeGroupField,
          updateState: this.updateState,
          extendedSetState: this.extendedSetState,
        }}
      >
        {this.props.children}
      </MyContext.Provider>
    );
  }
}

import { DataTable, Pagination } from 'components';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { CONST } from '../../data-config';
import { onAddressSearch } from '../ActionUtils';
import ActionCustomCell from './CustomCell/ActionCustomCell';
import AddressCustomCell from './CustomCell/AddressCustomCell';
import ContactCustomCell from './CustomCell/ContactCustomCell';
import ReferenceCustomCell from './CustomCell/ReferenceCustomCell';

import './AddressBookResultGrid.css';

export default class AddressBookResultGrid extends Component {
  selectedRows = rows => {
    this.props.onDeleteContact(rows);
  };

  handlePageChange = page => {
    onAddressSearch(this.props.context, { pageSize: false, page: page });
  };

  handleDisplayRowChange = pageSize => {
    onAddressSearch(this.props.context, { pageSize: pageSize, page: false });
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      JSON.stringify(nextProps.context.state.addressBookList) !==
        JSON.stringify(this.props.context.state.addressBookList) ||
      JSON.stringify(nextProps.context.state.manualSearch) !== JSON.stringify(this.props.context.state.manualSearch) ||
      JSON.stringify(nextProps.context.state.pagination) !== JSON.stringify(this.props.context.state.pagination)
    );
  };

  render() {
    const { context } = this.props;

    return (
      <Translation>
        {t => {
          const columns = [
            {
              name: t(`portalAddressBook|Company Name`),
              selector: 'companyName',
              sortable: true,
            },
            {
              name: t(`portalAddressBook|Contact`),
              selector: 'name', //used for sorting
              sortable: true,
              cell: row => <ContactCustomCell cellValue={row} />,
            },
            {
              name: t(`general|Address`),
              selector: 'countryName',
              sortable: true,
              cell: row => <AddressCustomCell cellValue={row} />,
            },
            {
              name: t('general|labels|inputs|reference'),
              selector: 'reference',
              sortable: true,
              cell: row => <ReferenceCustomCell cellValue={row} />,
            },
            {
              cell: row => (
                <ActionCustomCell
                  baseUrl={this.props.baseUrl}
                  cellValue={row}
                  context={this.props.context}
                  action={{ edit: this.props.onShowContact }}
                  onShowSuccessToaster={this.props.onShowSuccessToaster}
                />
              ),
            },
          ];

          return (
            <div data-testid="AddressBookResultGrid">
              {context.state.addressBookList.length > 0 && (
                <DataTable
                  hasCheckBox
                  fixedTable={true}
                  selectedRows={this.selectedRows}
                  columns={columns}
                  data={context.state.addressBookList}
                />
              )}
              {!context.state.manualSearch && context.state.addressBookList.length > 0 ? (
                <Pagination
                  t={t}
                  CONST={CONST}
                  onHandleDisplayRowChange={this.handleDisplayRowChange}
                  context={context}
                  pagination={context.state.pagination}
                  onHandlePageChange={this.handlePageChange}
                />
              ) : (
                <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                  <div className="frc__pagination-magin-top  l-grid--w-100pc-s l-grid--w-30pc-m-s l-grid--w-30pc-m-m">
                    {context.state.addressBookList.length > 0
                      ? t('general|Total Results Found', {
                          nos: context.state.addressBookList.length,
                        })
                      : context.state.manualSearch
                      ? t('general|errors|noResultsCurrentPage')
                      : t('general|errors|No Results Found')}
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </Translation>
    );
  }
}

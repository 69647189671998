import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiRequest } from '../../globals';
import { apiLinks } from '../../config/api-config';

import { userQueryKey } from './useGetUser';

const updateUser = async lang => {
  const params = {
    body: {
      language: lang.toUpperCase(),
    },
  };
  const res = await apiRequest(apiLinks.updateUserLanguage, 'PUT', params);
  if (res.status !== 200) {
    throw new Error(`Status: ${res.status} Message: ${res.status}`);
  }
  return res.data;
};

export const useUpdateUserLang = options => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateUser,
    onError: e => console.alert('Failed to persist selected language', e),
    onSuccess: () => {
      queryClient.invalidateQueries(userQueryKey);
    },
    ...options,
  });
};

import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import React from 'react';

const TitleWrapper = props => {
  const { titleCode, titleId, content } = props;

  const { t } = useTranslation();

  return (
    <Box
      id={titleId}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '2rem',
      }}
    >
      <span className="check flex-none m-right-1rem">{content}</span>
      <Box
        sx={{
          marginTop: '2rem',
        }}
      >
        <h3>{t(titleCode)}</h3>
      </Box>
    </Box>
  );
};

export const SuccessTitleWrapper = props => {
  const { titleCode } = props;

  return TitleWrapper({
    titleCode: titleCode,
    titleId: 'successThankYouPage',
    content: (
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="check"
        role="img"
        viewBox="0 0 512 512"
        className="frc-thank-you__icon"
      >
        <path
          fill="currentColor"
          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
        />
      </svg>
    ),
  });
};
export const WarningTitleWrapper = () => {
  return TitleWrapper({
    titleCode: 'general|Important notice',
    titleId: 'warningThankYouPage',
    content: (
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="exclamation-triangle"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
        className="frc-thank-you__icon frc-thank-you__icon--yellow"
      >
        <path
          fill="currentColor"
          d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
        />
      </svg>
    ),
  });
};
export const ErrorTitleWrapper = props => {
  const { titleCode = 'general|Sorry, your order could not be accepted!' } = props;
  return TitleWrapper({
    titleCode: titleCode,
    titleId: 'errorThankYouPage',
    content: (
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="times"
        role="img"
        viewBox="0 0 352 512"
        className="frc-thank-you__icon frc-thank-you__icon--red"
      >
        <path
          fill="currentColor"
          d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
        />
      </svg>
    ),
  });
};

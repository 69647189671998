import { checkGroupOfFieldsAndReturnState } from 'globals/utils/fieldValidations';
import { globalConfig } from 'globals/data-config-global';

export const afterUpdate = (context, params) => {
  let state = context.state;
  let stateToSet = {};
  const lengthCheck = new RegExp(`^.{0,${globalConfig.maxPhoneNumberDigits}}$`, 'g');

  // Phone Numbers check
  let getState = checkGroupOfFieldsAndReturnState({
    groupName: params.groupName,
    values: params.groupData || state[params.groupName],
    fields: [
      {
        name: 'code',
        isRequired: params.isRequired,
      },
      {
        name: 'number',
        regEx: globalConfig.regEx.number,
        lengthCheck: [lengthCheck],
        isRequired: params.isRequired,
        calculatePhoneLengthCheck: true,
        allowZeroLength: true,
      },
    ],
  });

  if (!params.groupData) {
    stateToSet[getState.groupName] = [...getState.values];
    context.updateState(stateToSet);
  } else {
    stateToSet = getState.values;
    return stateToSet;
  }
};

import { useQuery } from '@tanstack/react-query';
import { getCityName } from '../../globals/helpers/postalcode';

export const cityQueryKey = 'city';

export const fetchCity = async ({ countryCode, postalCode, cityName, useCustomCountryCode, headerCountryCode }) => {
  const cityResponse = await getCityName({
    countryCode,
    postalCode,
    cityName,
    useCustomCountryCode,
    headerCountryCode,
  });
  if (cityResponse.error) {
    throw new Error(`Error fetching city`);
  } else {
    return cityResponse;
  }
};

export const useGetCity = ({ options, postalCode, countryCode, cityName, useCustomCountryCode }) => {
  return useQuery({
    ...options,
    queryKey: [cityQueryKey, postalCode, countryCode, cityName, useCustomCountryCode],
    queryFn: () => fetchCity({ countryCode, postalCode, cityName, useCustomCountryCode }),
    staleTime: Infinity, // We don't want to refetch city every time
  });
};

import React, { Component } from 'react';
import { Checkbox } from 'components';
import AdditionalServicesOptions from '../AdditionalServicesOptions/AdditionalServicesOptions';
import { additionalServicesOptionsValidations } from 'globals/validators/AdditionalServices-validations';
import { euroconnectSelectedWithNoDeliveryDate } from '../../../ServiceDetails/ServiceDetails';
import './AdditionalServiceRow.css';
import { Translation } from 'react-i18next';
import { config } from '../../../../data-config';

export default class AdditionalServiceRow extends Component {
  update = (params, context) => {
    const { adError, additionalServices } = additionalServicesOptionsValidations(
      JSON.parse(JSON.stringify(context.state.additionalServices)),
    );

    //update VAS only when all available VAS doesnt have any error as 1A
    if (!adError || (!params.value && additionalServices[params.index].error)) {
      params.groupName = 'additionalServices';
      params.error = false;
      context.updateGroup(params);
    } else {
      context.updateState({ additionalServices: additionalServices });
    }
  };

  updatePriceForQuote = (context, params, showOptionsSchema, additionalServiceRowCode) => {
    const additionalServices = JSON.parse(JSON.stringify(context.state.additionalServices));
    const additionalServiceRow = additionalServices[params.index];

    if (showOptionsSchema && additionalServiceRowCode !== 'collectionAtTerminal') {
      if (
        !additionalServiceRow.value ||
        config.vasQuoteForPriceCallIgnoringOptionsValue.includes(additionalServiceRowCode)
      ) {
        this.props.getQuoteForPriceWithAd(additionalServiceRow);
      }
    } else this.props.getQuoteForPriceWithAd(additionalServiceRow);
  };

  render() {
    const { additionalServiceRow, context } = this.props;
    const disabled =
      euroconnectSelectedWithNoDeliveryDate(context.state.matchedProducts) &&
      this.props.config.vasExcludedForFailedLTC.indexOf(this.props.additionalServiceRow.code) !== -1;

    const showOptionsSchema =
      additionalServiceRow.optionNotToHide && additionalServiceRow.groups && additionalServiceRow.groups.length > 0;

    return (
      <div className={this.props.className}>
        <div className="frc__additional-services-row--wrapper frc__column-wrapper l-grid--w-100pc-s">
          <Translation>
            {t => (
              <Checkbox
                label={t(`additionalServices|${additionalServiceRow.code}|name`)}
                name={additionalServiceRow.code}
                deselect={additionalServiceRow.notCompatibleWith}
                index={this.props.index}
                isRequired={false}
                disabled={additionalServiceRow.disabled || disabled || false}
                checked={additionalServiceRow.value}
                config={this.props.config}
                CONST={this.props.CONST}
                secondayText={additionalServiceRow.calculatedResult ? additionalServiceRow.calculatedResult : null}
                context={this.props.context}
                tooltip={t(`additionalServices|${additionalServiceRow.code}|information`)}
                updateOnParent={(params, context) => this.update(params, context)}
                afterUpdate={(context, params) =>
                  this.updatePriceForQuote(context, params, showOptionsSchema, additionalServiceRow.code)
                }
              />
            )}
          </Translation>
        </div>
        {additionalServiceRow.value && showOptionsSchema && (
          <AdditionalServicesOptions
            getQuoteForPriceWithAd={() => this.props.getQuoteForPriceWithAd(additionalServiceRow)}
            deliveryMontoringClass={this.props.deliveryMontoringClass}
            serviceIndex={this.props.index}
            groups={additionalServiceRow.groups}
            context={this.props.context}
            errors={additionalServiceRow.errors}
            config={this.props.config}
            CONST={{
              API_DOMAIN: 'additionalServices',
            }}
            code={this.props.additionalServiceRow.code}
          />
        )}
      </div>
    );
  }
}

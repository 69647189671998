import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { apiLinks } from '../config/api-config';
import { apiRequest } from '../globals';
import { allCountries } from '../globals/data-countries';
import { convertCountriesToFlatArray, getAvailableLanguages } from '../globals/helpers/language-helpers';
import { caseInsensitiveEquals } from '../globals/utils/strings';
import { COUNTRY_SELECT_OPTIONS } from '../locale/config';

let ConfigCountriesContext = createContext();

const countryCodes = allCountries.filter(country => country.code !== '').map(country => country.code);

const fetchConfigCountries = async () => {
  const res = await apiRequest(apiLinks.getConfigCountries, 'GET', null, null, false);
  if (res.status !== 200) {
    throw new Error(`Status: ${res.status} Message: ${res.status}`);
  }
  return res.data;
};

const useGetConfigCountries = ({ select, options } = {}) => {
  return useQuery({
    queryKey: ['config-countries'],
    queryFn: fetchConfigCountries,
    select: select,
    ...options,
  });
};
export const ConfigCountriesProvider = ({ children }) => {
  const configCountries = useGetConfigCountries();
  const configCountriesData = configCountries.data;
  const { t } = useTranslation();
  const accountCountries = useMemo(() => {
    const accountCountryCodes = [
      ...new Set(getAvailableLanguages(convertCountriesToFlatArray(configCountriesData)).map(item => item.countryCode)),
    ];
    return accountCountryCodes
      .map(code => {
        return {
          label: t(`countries|${code}`),
          value: code,
          translationKey: `countries|${code}`,
        };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  }, [t, configCountriesData]);

  const companyCountries = useMemo(
    () =>
      countryCodes
        .map(countryCode => ({
          label: t(`countries|${countryCode}`),
          value: countryCode,
          translationKey: `countries|${countryCode}`,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)),
    [t],
  );

  const companyPhoneCountryCodeOptions = useMemo(
    () =>
      COUNTRY_SELECT_OPTIONS.map(item => ({
        ...item,
        label: `+${item.phone}`,
        phone: `+${item.phone}`,
      })).sort((a, b) => (a.code > b.code ? 1 : -1)),
    [],
  );

  const urlCountry = localStorage.getItem('country')?.toLowerCase();
  const isGlobalCountry = useMemo(() => urlCountry !== 'se', [urlCountry]);
  const countryPhoneCode = useMemo(
    () =>
      companyPhoneCountryCodeOptions?.find(phoneOption => caseInsensitiveEquals(phoneOption?.code, urlCountry))?.phone,
    [urlCountry, companyPhoneCountryCodeOptions],
  );

  let value = {
    accountCountries,
    companyCountries,
    companyPhoneCountryCodeOptions,
    urlCountry,
    isGlobalCountry,
    countryPhoneCode,
  };
  return <ConfigCountriesContext.Provider value={value}>{children}</ConfigCountriesContext.Provider>;
};

export function useConfigCountries() {
  return useContext(ConfigCountriesContext);
}

export const getLanguage = () => {
  try {
    const lsLanguage = localStorage.getItem('i18nextLng')?.split('-')[1]?.toUpperCase() || 'EN';
    return lsLanguage;
  } catch (error) {
    return 'EN';
  }
};

export function withCountryData(Component) {
  return function WrappedComponent(props) {
    const useCountryData = useConfigCountries();
    return <Component {...props} countryData={useCountryData} />;
  };
}

import { Box, Typography } from '@mui/material';
import { Button } from '../Button';
import { Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { PROFILE_SECTIONS } from './EditProfile';

const COLUMN_WIDTH = '250px';

/**
 * Also known as Collitypes
 */
export const ShipmentTypes = ({ userData, setReadOnly }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="body1">
        <strong>{t('general|favorites')}</strong>
      </Typography>
      {userData.colliTypes.length >= 12 ? (
        <Box
          component="ul"
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            my: '16px',
            mx: '32px',
            '& li': {
              flex: `1 0 ${COLUMN_WIDTH}`,
              minWidth: COLUMN_WIDTH,
              maxWidth: COLUMN_WIDTH,
              listStyleType: 'disc',
              lineHeight: '21.6px',
              '&::marker': {
                color: '#666',
              },
            },
          }}
        >
          {userData.colliTypes.map(item => {
            return (
              <Box component="li" key={item}>
                {t(`general|labels|shipmentType|${item}`)}
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box
          component="ul"
          sx={{
            my: '16px',
            mx: '32px',
            '& li': {
              listStyleType: 'disc',
              lineHeight: '21.6px',
              '&::marker': {
                color: '#666',
              },
            },
          }}
        >
          {userData.colliTypes.map(item => {
            return (
              <Box component="li" key={item}>
                {t(`general|labels|shipmentType|${item}`)}
              </Box>
            );
          })}
        </Box>
      )}
      <Button
        variant="contained"
        color="primary"
        startIcon={<Edit />}
        sx={{
          mt: '16px',
        }}
        onClick={() => {
          setReadOnly({
            section: PROFILE_SECTIONS.shipmentTypes,
            readOnly: false,
          });
        }}
        dataTestId="editOptionsBtn"
      >
        {t('general|editOptions')}
      </Button>
    </Box>
  );
};

import { apiRequest } from 'globals/utils/requests';
import { formatDate } from 'globals/utils/formatting';

import { CONST } from '../data-config';
import { apiLinks } from 'config/api-config';

export const trackShipment = (t, shipmentNumber) => {
  let trackingUrl = t('pageLinks|trackShipment');
  trackingUrl = trackingUrl.replace('{shipmentNumber}', shipmentNumber);

  window.open(trackingUrl);
};

export const deleteSavedShipment = async id => {
  let error = false;

  await apiRequest(apiLinks.deleteSavedShipment, 'DELETE', {
    body: id,
  })
    .then(result => {
      if (result.status === CONST.STATUS_NO_CONTENT) {
        error = false;
      } else {
        error = true;
      }
    })
    .catch(() => {
      error = true;
    });

  return error;
};

export const createReturnShipmentAction = async id => {
  let returnShipment = null;

  await apiRequest(apiLinks.createReturnShipment, 'POST', {
    body: {
      shipment: id,
    },
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        returnShipment = result.data;
      } else {
        returnShipment = null;
      }
    })
    .catch(() => {
      returnShipment = null;
    });

  return returnShipment;
};

export const getDisplayedPrice = (t, priceString) =>
  priceString === `0 ${CONST.CURRENCY_CODE}`
    ? `${String.fromCharCode(8212)} ${CONST.CURRENCY_CODE}`
    : priceString.split(' ')[0] * 1 < 0
    ? `${t('general|N/A')}`
    : priceString;

const formatSavedShipmentData = savedShipments => {
  const modifiedSavedShipments = savedShipments.map((shipment, rowTableIndex) => {
    shipment.rowTableIndex = rowTableIndex;

    shipment.sender = shipment.savedShipmentParties
      ? shipment.savedShipmentParties.find(party => party.type.toLowerCase() === 'sender')
      : {};

    shipment.receiver = shipment.savedShipmentParties
      ? shipment.savedShipmentParties.find(party => party.type.toLowerCase() === 'receiver')
      : {};

    shipment.senderName = shipment.sender ? shipment.sender.companyName : '';
    shipment.receiverName = shipment.receiver ? shipment.receiver.companyName : '';

    shipment.formattedDate = shipment.createdAt ? formatDate(shipment.createdAt) : '';

    if (shipment.sender) {
      shipment.sender.country = shipment.sender.countryCode || '';
    }

    if (shipment.receiver) {
      shipment.receiver.country = shipment.receiver.countryCode || '';
    }

    return shipment;
  });

  return modifiedSavedShipments;
};

const shipmentListBackendCall = async () => {
  const resultMap = {};
  const requestBody = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    senderAccountFilter: [],
    sortOrder: 'createdAt',
    statusFilter: [],
    templateTypes: [1, 4],
  };

  await apiRequest(apiLinks.savedShipmentData, 'POST', {
    body: requestBody,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const fetchIncompleteSavedShipmentsList = async context => {
  let stateToSet = {};
  let formattedSavedShipmentData;

  const shipmentResult = await shipmentListBackendCall();

  if (!shipmentResult.error) {
    // Sorting results by date
    formattedSavedShipmentData = formatSavedShipmentData(shipmentResult.data.savedShipments);
    // formattedSavedShipmentData = formattedSavedShipmentData.sort((a, b) => b.createdAt - a.createdAt);

    stateToSet = {
      savedShipments: formattedSavedShipmentData,
      savedShipmentsMaster: formattedSavedShipmentData,
      apiError: false,
      isLoading: false,
    };
  } else {
    stateToSet = {
      apiError: true,
      isLoading: false,
      savedShipments: [],
      savedShipmentsMaster: [],
    };
  }

  context.updateState(stateToSet);
};

import { config } from '../../../data-config';
import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';
import { readState, payerCode } from '../EnterPickupLocation-helpers';
import { DOMESTIC_SHIPMENT_RANGE } from '../../../../globals/constants';

export const validateAccountNumber = (state, stateToSet, hasErrors) => {
  let opts = readState(state);
  const casePayerCode = payerCode(opts);

  let finalMap = {};

  if (state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE) {
    finalMap = checkDomestic(state, stateToSet, hasErrors, casePayerCode);
    const missingReceiverNumber = state.receiverNumber.isRequired && !state.receiverNumber.value;
    if (state.receiverNumber.error === true || missingReceiverNumber) {
      finalMap = {
        ...finalMap,
        hasErrors: true,
        stateToSet: {
          ...finalMap.stateToSet,
          receiverNumber: {
            ...finalMap.stateToSet.receiverNumber,
            error: true,
          },
        },
      };
    } else if (state.accountNumber.error === true) {
      finalMap = {
        ...finalMap,
        hasErrors: true,
        stateToSet: {
          ...finalMap.stateToSet,
          accountNumber: {
            ...finalMap.stateToSet.accountNumber,
            error: true,
          },
        },
      };
    }
  } else {
    finalMap = checkInternational(state, stateToSet, hasErrors, casePayerCode);
  }
  return finalMap;
};

const getAccountNumber = (name, state, isRequired = true) => {
  let getState = checkFieldAndReturnState({
    name: name,
    value: state[name].value,
    regEx: config.regEx.alphaNumericWithDot,
    lengthCheck: isRequired ? [RegExp(`^.{${config.minAccountNumberLength},${config.maxAccountNumberLength}}$`)] : null,
    isRequired: isRequired,
  });

  return getState;
};

const getStateValue = (getState, stateToSet, state) => {
  const newStateToSet = JSON.parse(JSON.stringify(stateToSet));

  newStateToSet[getState.name] = JSON.parse(JSON.stringify(state[getState.name]));
  newStateToSet[getState.name].value = getState.value;
  newStateToSet[getState.name].error = getState.error;

  return newStateToSet;
};

const getOriginalStateValue = (state, variableName) => {
  const accountOriginalState = JSON.parse(JSON.stringify(state[variableName]));
  accountOriginalState.value = '';
  accountOriginalState.error = false;

  return accountOriginalState;
};

const checkInternational = (state, stateToSet, hasErrors, casePayerCode) => {
  let getState = {};

  if (casePayerCode === 'case2' || casePayerCode === 'case1') {
    getState = getAccountNumber('accountNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet, state);

    stateToSet.receiverNumber = getOriginalStateValue(state, 'receiverNumber');
    stateToSet.thirdPartyNumber = getOriginalStateValue(state, 'thirdPartyNumber');
  } else if (casePayerCode === 'case3') {
    getState = getAccountNumber('accountNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet, state);

    getState = getAccountNumber('thirdPartyNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet, state);

    stateToSet.receiverNumber = getOriginalStateValue(state, 'receiverNumber');
  } else if (casePayerCode === 'case4') {
    getState = getAccountNumber('receiverNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet, state);

    stateToSet.accountNumber = getOriginalStateValue(state, 'accountNumber');
    stateToSet.thirdPartyNumber = getOriginalStateValue(state, 'thirdPartyNumber');
  } else if (casePayerCode === 'case5') {
    getState = getAccountNumber('receiverNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet, state);

    getState = getAccountNumber('thirdPartyNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet, state);

    stateToSet.accountNumber = getOriginalStateValue(state, 'accountNumber');
  }
  return { stateToSet: stateToSet, hasErrors: hasErrors };
};

const checkDomestic = (state, stateToSet, hasErrors, casePayerCode) => {
  let getState = {};

  if (casePayerCode === 'case6') {
    getState = getAccountNumber('accountNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet, state);

    stateToSet.thirdPartyNumber = getOriginalStateValue(state, 'thirdPartyNumber');
    stateToSet.receiverNumber = getOriginalStateValue(state, 'receiverNumber');
  } else if (casePayerCode === 'case7') {
    getState = getAccountNumber('accountNumber', state, state.accountNumber.isRequired);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet, state);

    getState = getAccountNumber('receiverNumber', state, state.accountNumber.isRequired);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet, state);

    stateToSet.thirdPartyNumber = getOriginalStateValue(state, 'thirdPartyNumber');
  } else if (casePayerCode === 'case8') {
    getState = getAccountNumber('accountNumber', state, state.accountNumber.isRequired);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet, state);

    getState = getAccountNumber('thirdPartyNumber', state);
    hasErrors = getState.error || hasErrors;
    stateToSet = getStateValue(getState, stateToSet, state);

    stateToSet.receiverNumber = getOriginalStateValue(state, 'receiverNumber');
  }
  return { stateToSet, hasErrors };
};

export const payerCodeValidation = (state, stateToSet, hasErrors) => {
  let getState = {};
  getState = checkFieldAndReturnState({
    name: 'termsOfDelivery',
    value: state.payerCode || state['termsOfDelivery'].value,
    isRequired: state['termsOfDelivery'].isRequired ? true : false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = {
    value: getState.value,
    error: getState.error,
    isRequired: state['termsOfDelivery'].isRequired,
  };
  return {
    stateToSet,
    hasErrors,
  };
};

import { Box, Typography } from '@mui/material';

export const ProfileSection = ({ title, subtitle, children }) => {
  return (
    <Box sx={{ bgcolor: 'background.section', p: '32px', mb: '32px' }}>
      <Typography variant="h2" fontSize="16px">
        {title}
      </Typography>
      {subtitle}
      <Box sx={{ bgcolor: '#fff', p: '16px' }}>{children}</Box>
    </Box>
  );
};

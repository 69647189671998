import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Typography } from '@mui/material';

import './../../css/global/_variables.css';
// import './../../css/global/_icons.css';
// import './../../css/base/iconfont.css';
// import './../../css/base/react-datepicker.css';

import 'globals/utils/debugHelpers';
import cssVars from 'css-vars-ponyfill';
import { TemplatesProvider, TemplatesContext } from '../../context/context';
import { SuccessMessage } from 'components';
import TemplateList from '../TemplateList/TemplateList';
import Template from '../Template/Template';
import { CONST, config } from '../../data-config';

import './AppPortalTemplates.css';
import '../../css/styles.css';

const ENV_DEV = process.env.NODE_ENV === 'development';

export default class AppPortalTemplates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addEditTemplate: false,
      succesMessage: '',
    };
  }

  componentDidMount() {
    cssVars();
  }

  toggleTemplate = () => {
    this.setState(prevState => ({
      addEditTemplate: !prevState.addEditTemplate,
    }));
  };

  showSuccessToaster = message => {
    this.setState({
      message,
    });
    setTimeout(() => {
      this.setState({
        message: '',
      });
    }, config.toasterTimeoutInMilliSeconds);
  };

  render() {
    return (
      <Translation>
        {t => (
          <div className={ENV_DEV ? 'l-view' : ''}>
            <div className="c-page-headline component-small has-rte component-margin frc__heading">
              <div className="c-page-headline--wrapper">
                <Typography variant="h1" mt={{ xs: 0, sm: 2 }}>
                  {t('general|pageTitles|templates')}
                </Typography>
              </div>
              <p className="has-rte">{t(`portalTemplates|pageSubtitle`)}</p>
            </div>
            <TemplatesProvider>
              <div className="frc__app frc__app-template__override">
                <TemplatesContext.Consumer>
                  {context => (
                    <>
                      {this.state.addEditTemplate ? (
                        <Template
                          t={t}
                          context={context}
                          onToggleTemplate={this.toggleTemplate}
                          onShowSuccessToaster={this.showSuccessToaster}
                        />
                      ) : (
                        <TemplateList
                          t={t}
                          context={context}
                          onShowSuccessToaster={this.showSuccessToaster}
                          onToggleTemplate={this.toggleTemplate}
                        />
                      )}
                      {this.state.message && <SuccessMessage CONST={CONST} t={t} message={this.state.message} />}
                    </>
                  )}
                </TemplatesContext.Consumer>
              </div>
            </TemplatesProvider>
          </div>
        )}
      </Translation>
    );
  }
}
